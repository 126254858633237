import React, { useState } from "react";
import { Spin } from 'antd'
import { Pie, Cell, PieChart, ResponsiveContainer, Sector } from "recharts";
import numeral from 'numeral'
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { FirebaseDAO } from 'firebase/firebase';
import data from "./piedata";
import { FaSpinner } from 'react-icons/fa';

const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;
const COLORS = ['#00C49F', '#FF8042', '#777', '#333'];

const renderActiveShape = (props) => {
    let { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload } = props;
    const nwColor = payload.nw > 0 ? COLORS[0] : COLORS[1]
    return (
        <g>
            <text x={cx} y={cy} dy={0} textAnchor="middle" fill={COLORS[2]}>{payload.name}</text>
            <path d={`M${cx - 25},${cy + 5}L${cx + 30},${cy + 5}`} stroke={COLORS[2]} fill="none" />
            <text x={cx} y={cy + 20} dy={0} textAnchor="middle" fontWeight="bold" fill={COLORS[3]}>{numeral(payload.value).format('$0,0')}</text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 3}
                outerRadius={outerRadius + 5}
                fill={fill}
            />
            <text x={cx} y={cy - 130} dy={0} textAnchor="middle" fill={COLORS[2]}>NET WORTH</text>
            {/* <text x={cx + 40} y={cy + 110} dy={0} textAnchor="middle" font-weight="bold" fill={nwColor}>{numeral(payload.nw).format('$0,0')}</text> */}
            <text x={cx} y={cy - 110} dy={0} textAnchor="middle" fontWeight="bold" fill={nwColor}>{numeral(payload.nw).format('$0,0')}</text>
        </g>
    );
};

const NetWorthDonutChart = () => {
    const [activeIndex, setActiveIndex] = useState(0)

    // Retrieve current user profile
    const [profile] = useDocumentData(
        FirebaseDAO.getFBCurrentUserDocumentData(),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        },
    );

    let chartData = [];
    let networthValue = 0;

    if (profile) {
        let a =
            profile.financial.networth && profile.financial.networth.assets
                ? parseFloat(profile.financial.networth.assets)
                : 0;
        let l =
            profile.financial.networth && profile.financial.networth.liabilities
                ? parseFloat(profile.financial.networth.liabilities)
                : 0;
        networthValue = a - l;
        chartData = [{ name: 'Assets', value: a, nw: networthValue }, { name: 'Liabilities', value: l, nw: networthValue }];
    }

    const onPieEnter = (data, index) => {
        setActiveIndex(index)
    }

    if (!profile && !chartData) {
        return (
            <Spin indicator={spinnerIcon} />
        );
    } else {
        return (
            <ResponsiveContainer height={280}>
                <PieChart>
                    <Pie dataKey="value"
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        onMouseEnter={onPieEnter}
                        data={chartData}
                        innerRadius={60}
                        outerRadius={80}
                        fill="#003366">
                        {
                            data.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]} />)
                        }
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        )
    }
}

export default NetWorthDonutChart

