import React, {useEffect, useState} from 'react';
import config from 'firebase/firebaseConfig';
import {history} from 'appRedux/store';
import {
  Avatar,
  Button,
  Spin,
  Card,
  Row,
  Col,
  Divider,
  Form,
  Input,
  message,
} from 'antd';
import Auxiliary from 'util/Auxiliary';
import FirebaseMX from 'firebase/firebaseMX';
import {FaSpinner} from 'react-icons/fa';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';

const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;

const MemberEdit = ({member, onCancel}) => {
  const userId = FirebaseMX.auth.currentUser.uid.toString();
  const [form] = Form.useForm();

  const intlMessages = useFormatMessage();

  const [credentials, setCredentials] = useState(null);
  const [institution, setInstitution] = useState(null);

  let credFields = [];
  useEffect(() => {
    if (member) {
      const credentials = `https://us-central1-${config.projectId}.cloudfunctions.net/mxInstitutionsCredentials?code=${member.code}`;
      fetch(credentials)
        .then(res => res.json())
        .then(res => setCredentials(res.body.credentials));

      const details = `https://us-central1-${config.projectId}.cloudfunctions.net/mxInstitutionsRead?code=${member.code}`;
      fetch(details)
        .then(res => res.json())
        .then(res => setInstitution(res.body.institution));
    }
  }, [member]);

  const onSubmit = async values => {
    const credAPI_URL = `https://us-central1-${config.projectId}.cloudfunctions.net/mxMembersUpdate?user=${userId}&guid=${member.guid}`;

    let credArray = [];
    for (let j = 0; j < credentials.length; j++) {
      credArray.push({
        guid: credentials[j].guid,
        value: Object.values(values)[j],
      });
    }

    let memberBody = {
      member: {
        institutionCode: member.code,
        credentials: credArray,
      },
    };

    await fetch(credAPI_URL, {
      method: 'POST',
      mode: 'cors',
      headers: {
        ContentType: 'application/json',
        Accept: 'application/vnd.mx.atrium.v1+json',
      },
      body: JSON.stringify(memberBody),
    })
      .then(res => res.json())
      .then(async res => {
        if (res.body.member) {
          if (values.name) {
            await FirebaseMX.updateCredentials(
              userId,
              member.id,
              res.body.member.guid,
              member.code,
              values.name,
            );
          } else {
            await FirebaseMX.updateCredentials(
              userId,
              member.id,
              res.body.member.guid,
              member.code,
              member.name,
            );
          }
        }
        onCancel();
        message.success(intlMessages('member.editMember.success'));
        history.push(`/app/mxconnect`);
      })
      .catch(err => console.log(err));
  };

  if (member) {
    if (!credentials) {
      return <Spin indicator={spinnerIcon} />;
    } else if (!institution) {
      return <Spin indicator={spinnerIcon} />;
    } else {
      for (let i = 0; i < credentials.length; i++) {
        let formElem =
          credentials[i].type.toLowerCase() === 'password' ? (
            <Input.Password
              placeholder={`Enter ${credentials[i].label.toLowerCase()}`}
            />
          ) : (
            <Input
              placeholder={`Enter ${credentials[i].label.toLowerCase()}`}
            />
          );

        credFields.push(
          <Form.Item name={credentials[i].label}>{formElem}</Form.Item>,
        );
      }
      return (
        <Auxiliary>
          <div className="gx-media gx-wall-user-info gx-flex-nowrap gx-align-items-center gx-mb-0 gx-mt-0">
            <Row justify="center">
              <Card>
                <Row>
                  <Col span={8} />
                  <Col span={8}>
                    <Avatar
                      src={institution.smallLogoUrl}
                      shape="square"
                      size={72}
                      className="gx-mr-3"
                    />
                  </Col>
                  <Col span={8} />
                </Row>
                <Row justify="center">
                  <h2>{institution.name}</h2>
                </Row>
                <Row justify="center">
                  <h4>{institution.url}</h4>
                </Row>
                <Form form={form} onFinish={onSubmit}>
                  <div className="gx-modal-box-row">
                    <div className="gx-modal-box-form-item">
                      {credFields}
                      <Form.Item name="name" label="Account Link Name">
                        <Input
                          addonBefore="Account Name"
                          placeholder={member.name}
                          style={{width: '100%'}}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Form>
                <Divider />
                <Button onClick={() => onCancel()}>Cancel</Button>
                <Button onClick={() => form.submit()} type="primary">
                  Submit
                </Button>
              </Card>
            </Row>
          </div>
        </Auxiliary>
      );
    }
  } else {
    return <div />;
  }
};

export default MemberEdit;
