export default [
  {
    value: 'auto',
    label: 'Auto & Transport',
    children: [
      {
        value: 'insurance',
        label: 'Auto Insurance',
      },
      {
        value: 'payment',
        label: 'Auto Payment',
      },
      {
        value: 'gas',
        label: 'Gasoline/Fuel',
      },
      {
        value: 'parking',
        label: 'Parking',
      },
      {
        value: 'transportation',
        label: 'Public Transportation',
      },
      {
        value: 'service',
        label: 'Service & Parts',
      },
    ],
  },
  {
    value: 'bills',
    label: 'Bills & Utilities',
    children: [
      {
        value: 'homephone',
        label: 'Home Phone',
      },
      {
        value: 'internet',
        label: 'Internet',
      },
      {
        value: 'mobilephone',
        label: 'Mobile Phone',
      },
      {
        value: 'television',
        label: 'Cable TV',
      },
      {
        value: 'utilities',
        label: 'Utilities',
      },
    ],
  },
  {
    value: 'businessservices',
    label: 'Business Services',
    children: [
      {
        value: 'advertising',
        label: 'Advertising',
      },
      {
        value: 'legal',
        label: 'Legal',
      },
      {
        value: 'officesupplies',
        label: 'Office Supplies',
      },
      {
        value: 'printing',
        label: 'Printing',
      },
      {
        value: 'shipping',
        label: 'Shipping',
      },
    ],
  },
  {
    value: 'education',
    label: 'Education',
    children: [
      {
        value: 'bookssupplies',
        label: 'Books & Supplies',
      },
      {
        value: 'studentloan',
        label: 'Student Loan',
      },
      {
        value: 'tuition',
        label: 'Tuition',
      },
    ],
  },
  {
    value: 'entertainment',
    label: 'Entertainment',
    children: [
      {
        value: 'amusement',
        label: 'Amusement',
      },
      {
        value: 'arts',
        label: 'Arts',
      },
      {
        value: 'movies',
        label: 'Movies & DVDs',
      },
      {
        value: 'music',
        label: 'Music',
      },
      {
        value: 'newspapers',
        label: 'Newspapers & Magazines',
      },
    ],
  },

  // Uncategorized
  {
    value: 'uncategorized',
    label: 'Uncategorized',
  },
];
