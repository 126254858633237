import React, { Fragment } from 'react'
import { Button } from 'antd'
const GoalFormButtons = ({
  handleCancel,
  currentStep,
  goalFormSteps,
  next,
  prev,
  saveGoal,
  saving
}) => {
  return (
    <Fragment>
      <Button className="ant-btn-link float-left" onClick={handleCancel}>
        Cancel
        </Button>
      {currentStep > 0 && (
        <Button style={{ marginLeft: 8 }} onClick={() => prev()}>
          Previous
        </Button>
      )}

      {currentStep < goalFormSteps.length - 1 && (
        <Button type="primary" onClick={() => next()}>
          Next
        </Button>
      )}

      {currentStep === goalFormSteps.length - 1 && (
        <Button type="primary" onClick={saveGoal}>
          {saving ? `Saving ` : 'Save '}
          Goal
        </Button>
      )}
    </Fragment>
  )
}
export default GoalFormButtons