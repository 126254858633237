import React, {Fragment, useState, useEffect} from 'react';
import {Radio, Form, Select, Col} from 'antd';
import GoalFormAddAccount from './GoalFormAddAccount';
import GoalFormField from './GoalFormField';

const GoalFormAccount = ({
  goalAccount,
  selectAccountFromNewAccount,
  setGoalAccountBalance,
  setGoalAccount,
  goalAccountOption,
  setGoalAccountOption,
  accountsList,
}) => {
  const [newAccountAdded, setNewAccountAdded] = useState();
  const changeAccountOption = e => {
    if (e.target.value === 'later') {
      setGoalAccount(null);
      setGoalAccountBalance(0);
    } else if (e.target.value === 'create') {
      setShowCreateAccountModal(true);
    }
    setGoalAccountOption(e.target.value);
  };

  const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);
  const cancelAddAccount = () => {
    setShowCreateAccountModal(false);
  };

  useEffect(() => {
    selectAccountFromNewAccount(newAccountAdded); // eslint-disable-next-line
  }, [newAccountAdded]);

  return (
    <Fragment>
      <div className="form-group">
        <GoalFormField
          field={
            <Fragment>
              <Form.Item
                rules={[
                  {
                    required: false,
                    message: 'Please select',
                  },
                ]}>
                <Radio.Group
                  defaultValue={goalAccountOption}
                  onChange={changeAccountOption}
                  buttonStyle="solid"
                  size="large">
                  <Radio.Button value="existing">
                    Use existing account
                  </Radio.Button>
                  <Radio.Button value="create">Create new account</Radio.Button>
                  <Radio.Button value="later">
                    Choose account later
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Fragment>
          }
        />
      </div>

      {goalAccountOption === 'existing' ? (
        <div className="form-group">
          <GoalFormField
            label={<h4>Select Account</h4>}
            field={
              <Col span={12}>
                <Form.Item
                  name="account"
                  rules={[
                    {
                      required: true,
                      type: 'any',
                      message: 'Please select an account',
                    },
                  ]}>
                  <Select
                    style={{width: '100%'}}
                    value={goalAccount}
                    onChange={value => {
                      setGoalAccount(value);
                    }}
                    placeholder="Please select an account">
                    {accountsList}
                  </Select>
                </Form.Item>
              </Col>
            }
          />
        </div>
      ) : (
        ''
      )}

      <GoalFormAddAccount
        showModal={showCreateAccountModal}
        setShowModal={setShowCreateAccountModal}
        cancelAddAccount={cancelAddAccount}
        setNewAccountAdded={setNewAccountAdded}
      />
    </Fragment>
  );
};
export default GoalFormAccount;
