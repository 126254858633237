import React, { useRef } from 'react';
import { Modal } from 'antd';

import IntlMessages from 'util/IntlMessages';
import LoanForm from './LoanForm';

const LoanModal = ({ showModal, showHideModal, loan }) => {
  const childRef = useRef();

  const handleResetFormModal = () => {
    showHideModal(false);
  };

  let mode = loan ? 'edit' : 'add';

  return (
    <>
      <Modal
        title={
          mode === 'add' ? (
            <IntlMessages id="wizard.financials.loan.addBtn" />
          ) : (
              <IntlMessages id="wizard.financials.loan.updateBtn" />
            )
        }
        visible={showModal}
        closable={true}
        onOk={() => childRef.current.submitForm()}
        onCancel={handleResetFormModal}>
        <LoanForm
          ref={childRef}
          loan={loan}
          onClose={handleResetFormModal}
          mode={mode}
          showModal={showModal}

        />
      </Modal>
    </>
  );
};

export default LoanModal;
