import React from 'react';
import PostItem from './PostItem';
import WriteBox from './WriteBox';
import Auxiliary from 'util/Auxiliary';
import ShowMore from '@tedconf/react-show-more';
import {Button} from 'antd';

const Posts = ({postList, profile, readonly, groupData, wall, user}) => {
  let toReturn = null;

  if (wall) {
    toReturn = (
      <ShowMore items={postList} by={100}>
        {({current, onMore}) => (
          <div>
            <ul>
              {current.map(post =>
                post.author === user ? (
                  <PostItem
                    postInfo={post}
                    profile={profile}
                    groupData={groupData}
                    key={post.id}
                  />
                ) : (
                  <PostItem
                    postInfo={post}
                    profile={profile}
                    readonly={readonly}
                    groupData={groupData}
                    key={post.id}
                  />
                ),
              )}
            </ul>
            <Button
              type="link"
              block
              disabled={!onMore}
              onClick={() => {
                if (!!onMore) onMore();
              }}>
              See More Posts
            </Button>
          </div>
        )}
      </ShowMore>
    );
  }
  if (readonly) {
    toReturn = (
      <ShowMore items={postList} by={100}>
        {({current, onMore}) => (
          <div>
            <ul>
              {current.map(post => (
                <PostItem
                  postInfo={post}
                  profile={profile}
                  readonly={readonly}
                  groupData={groupData}
                  key={post.id}
                />
              ))}
            </ul>
            <Button
              type="link"
              block
              disabled={!onMore}
              onClick={() => {
                if (!!onMore) onMore();
              }}>
              See More Posts
            </Button>
          </div>
        )}
      </ShowMore>
    );
  } else {
    toReturn = (
      <Auxiliary>
        <WriteBox profile={profile} groupData={groupData} />
        {postList.map(post => {
          return (
            <PostItem
              postInfo={post}
              profile={profile}
              key={post.id}
              groupData={groupData}
            />
          );
        })}
      </Auxiliary>
    );
  }

  return toReturn;
};

export default Posts;
