import React from 'react';
import {Button, Form, Input, message} from 'antd/lib';
import IntlMessages from 'util/IntlMessages';
import FirebaseAuth from 'firebase/firebaseAuth';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';
const FormItem = Form.Item;

const ChangeEmail = ({profile}) => {
  const intlMessages = useFormatMessage();
  const [form] = Form.useForm();

  const updateEmail = async input => {
    let formValues = form.getFieldsValue(['password', 'email']);
    formValues = {
      ...formValues,
      oldEmail: profile.email,
    };
    try {
      form.resetFields();
      await FirebaseAuth.changeEmailId(formValues);
      message.success(intlMessages('appModule.emailChangedSuccessfully'));
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <div className="gx-login-container">
      <div className="gx-login-content">
        <div className="gx-mb-4">
          <h2>Update Your Email</h2>
        </div>

        <Form
          form={form}
          onFinish={updateEmail}
          className="gx-login-form gx-form-row">
          <FormItem
            name="password"
            rules={[
              {
                required: true,
                type: 'string',
                message: 'Please enter your login password',
              },
            ]}>
            <Input type="password" placeholder="Enter your password" />
          </FormItem>

          <FormItem
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Enter your new email id',
              },
            ]}>
            <Input placeholder="Enter your new email id" type="email" />
          </FormItem>

          <FormItem>
            <Button type="primary" htmlType="submit">
              <IntlMessages id="app.userAuth.changeEmail" />
            </Button>
          </FormItem>
        </Form>
      </div>
    </div>
  );
};

export default ChangeEmail;
