import React, { useState } from 'react';
import { Table, Modal, message, Button } from 'antd/lib';
import Widget from 'components/Widget/index';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import FirebaseFin from 'firebase/firebaseFin';
import { FirebaseDAO } from 'firebase/firebase';
import numeral from 'numeral';
import { TransactionCategories } from 'constants/staticData';
import TransactionModal from 'components/app/trasactions/TransactionModal';
import IntlMessages from 'util/IntlMessages';
const { confirm } = Modal;

const RecentTransactions = ({ profile }) => {
  const isMobile = window.innerWidth <= 500;
  const intlMessages = useFormatMessage();
  const [showModalForm, setShowModalForm] = useState(false);
  const [transactionEditData, setTransactionEditData] = useState(null);
  const [accountAdd, setAccountAdd] = useState(false);
  const [accountEditData, setAccountEditData] = useState(null);

  const [transactions] = useCollectionData(FirebaseFin.getTransactions(), {
    idField: 'id',
    snapshotListenOptions: {
      includeMetadataChanges: true,
    },
  });

  const [accounts] = useCollectionData(
    FirebaseDAO.getFBCollectionData('accounts'),
    {
      idField: 'id',
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const setShowModal = state => {
    setShowModalForm(state);
  };

  const resetEditTrasaction = () => {
    setTransactionEditData(null);
  };

  const editTransactionData = row => {
    setTransactionEditData(row);
  };

  const resetEditAccount = () => {
    setAccountEditData(null);
  };

  const addAccountData = state => {
    setAccountAdd(state);
  };

  const deleteTransaction = row => {
    confirm({
      title: 'Are you sure you want to delete this Transaction?',
      onOk: () => {
        processTransactionDelete(row);
      },
      onCancel() { },
    });
  };
  const processTransactionDelete = async row => {
    await FirebaseDAO.deleteMultipleFBData([row.id], 'transactions');
    message.success(intlMessages('transaction.delete.success'));
  };

  let columns = [
    {
      title: 'Desc',
      dataIndex: 'description',
      render: (text, row) => (row.description ? row.description : '-'),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (text, row) => (
        <span>
          {TransactionCategories[1].children.filter(function (e) {
            return row.category[0] === 'income' && e.value === row.category[1];
          }).length > 0 ? (
              <span style={{ color: '#158715' }}>
                {numeral(row.amount).format('$0,0')}
              </span>
            ) : (
              <span style={{ color: '#ff141d' }}>
                {numeral(row.amount).format('$0,0')}
              </span>
            )}
        </span>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'category',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'action',
      render: (text, row) => (
        <span>
          <i
            className="icon icon-edit gx-pointer gx-text-primary gx-fs-lg gx-d-inline-flex gx-vertical-align-middle gx-mr-1"
            onClick={() => editTransactionData(row)}
          />
          &emsp;
          <i
            className="icon icon-trash gx-pointer gx-text-danger gx-fs-lg gx-d-inline-flex gx-vertical-align-middle gx-mr-1"
            onClick={() => deleteTransaction(row)}
          />
        </span>
      ),
    },
  ];

  // For Mobile Screen
  if (isMobile) {
    let removeIndex = [2];
    columns = columns.filter(function (value, index) {
      return removeIndex.indexOf(index) === -1;
    });
  }

  return (
    <div>
      <Widget
        title={
          <h2 className="h4 gx-text-capitalize gx-mb-0 gx-text-left gx-ml-1">
            Recent Transactions
          </h2>
        }
        extra={[
          <p
            key={Math.floor(Math.random() * 10000)}
            className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block gx-mr-1"
            onClick={() => setShowModal(true)}>
            <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle gx-mr-1" />
            <IntlMessages id="transaction.add" />
          </p>,
          //  For Mobile Sceen
          <span
            onClick={() => setShowModal(true)}
            className="gx-text-primary gx-fs-md gx-pointer gx-mb-4 gx-d-block gx-d-sm-none gx-mr-1">
            <i className="icon icon-add-circle gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle" />
            <IntlMessages id="transaction.add" />
          </span>,
        ]}>
        <div>
          {transactions && (
            <Table
              className="gx-table-no-bordered"
              rowClassName="loan-table-row"
              pagination={{ pageSize: 10 }}
              columns={columns}
              dataSource={transactions}
              size="middle"
              rowKey="id"
              locale={{
                emptyText: (
                  <div className="text-center">
                    <h3>You don't have any transactions yet. Let's add one!</h3>
                    <Button
                      variant="raised"
                      className="gx-mt-3"
                      type="primary"
                      onClick={() => setShowModal(true)}>
                      <div className="ant-row-flex">
                        <IntlMessages id="transaction.add" />
                      </div>
                    </Button>
                  </div>
                ),
              }}
            />
          )}
        </div>
      </Widget>

      <TransactionModal
        setShowModal={setShowModal}
        showModal={showModalForm}
        accounts={accounts}
        resetEditTrasaction={resetEditTrasaction}
        editTrasactionData={transactionEditData}
        onAddAccount={accountAdd}
        onResetAddAccount={addAccountData}
        resetEditAccount={resetEditAccount}
        editAccountData={accountEditData}></TransactionModal>
    </div>
  );
};

export default RecentTransactions;
