import React, {useState} from 'react';
import {useDocumentData} from 'react-firebase-hooks/firestore';
import {Form, Button, Input, Modal, message, Spin} from 'antd/lib';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';
import IntlMessages from 'util/IntlMessages';
import FirebaseSocial from 'firebase/firebaseSocial';
import {FaSpinner} from 'react-icons/fa';

const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;
const {TextArea} = Input;

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 24},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 24},
  },
};

const Biography = ({profile, readonly}) => {
  const [showModal, setShowModal] = useState(false);
  const intlMessages = useFormatMessage();
  const [form] = Form.useForm();

  const [user, userLoading, userError] = useDocumentData(
    FirebaseSocial.getUserDocumentData(profile.id),
    {
      snapshotListenOptions: {includeMetadataChanges: true},
    },
  );

  const onModalClose = () => setShowModal(false);
  const onModalOpen = () => setShowModal(true);

  const submitForm = values => {
    onModalClose();
    FirebaseSocial.updateBiography(values);
    message.success(intlMessages('profile.update.success'));
  };

  const cancelFormSubmission = () => {
    onModalClose();
    form.resetFields();
  };

  let extra = null;
  if (!readonly) {
    extra = (
      <i
        className={`icon icon-edit gx-pointer`}
        style={{fontSize: 15}}
        onClick={onModalOpen}
      />
    );
  }

  if (!user) {
    return <Spin indicator={spinnerIcon} />;
  } else {
    form.setFieldsValue({
      biography: user.social.biography,
    });

    return (
      <div>
        {userError && <strong>Connection error!</strong>}
        {userLoading && <p>Loading...</p>}
        {user && (
          <div>
            <div className="gx-mb-2">
              <div className="gx-profile-banner-bottom">
                <span className="gx-link gx-profile-setting ">{extra}</span>
              </div>
              <div
                className="gx-mb-2 gx-mt-3"
                style={{height: '100px', whiteSpace: 'pre-line'}}>
                {user.social.biography}
              </div>
            </div>

            <Modal
              title={<IntlMessages id="profile.saveBiography" />}
              toggle={onModalClose}
              visible={showModal}
              closable={true}
              onOk={() => form.submit()}
              onCancel={cancelFormSubmission}>
              <Form
                layout="vertical"
                {...formItemLayout}
                form={form}
                onFinish={submitForm}>
                <Form.Item name="biography" label="Biography: ">
                  <TextArea rows={5} />
                </Form.Item>
                <Button htmlType="submit" style={{display: 'none'}}></Button>
              </Form>
            </Modal>
          </div>
        )}
      </div>
    );
  }
};

export default Biography;
