import React, {useState} from 'react';
import {useCollectionData} from 'react-firebase-hooks/firestore';
import {message, Popover, Form, Button, Input} from 'antd/lib';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';
import FirebaseMessages from 'firebase/firebaseMessages';
import FirebaseSocial from 'firebase/firebaseSocial';
import IntlMessages from 'util/IntlMessages';
import Moment from 'moment';
import {Link} from 'react-router-dom';
const {TextArea} = Input;

const MessageBubble = ({userId, section}) => {
  const currentUserId = FirebaseSocial.auth.currentUser.uid;
  const intlMessages = useFormatMessage();
  const [followerLook, followLoading, followError] = useCollectionData(
    FirebaseSocial.getUserFollowers(userId),
    {
      idField: 'id',
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [form] = Form.useForm();
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [chatThreadId, setChatThreadId] = useState(null);
  const [friendData, setFriendData] = useState(null);

  const hashThread = str => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash += Math.pow(str.charCodeAt(i) * 31, str.length - i);
      hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  };

  const sendMessage = async friendId => {
    let threadId = `${userId}-${currentUserId}`;
    if (hashThread(currentUserId) <= hashThread(userId)) {
      threadId = `${currentUserId}-${userId}`;
    }
    setChatThreadId(threadId);
    let friendData = await FirebaseMessages.startConversation(userId);
    setShowMessageBox(!showMessageBox);
    setFriendData(friendData);
  };

  const submitForm = async e => {
    let input = form.getFieldsValue(['newMessage']);
    if (input.newMessage.trim() !== '') {
      const timestamp = Moment()
        .valueOf()
        .toString();
      let messageObj = {
        senderId: currentUserId,
        receiverId: userId,
        sentAt: Moment().format('YYYY-MM-DD hh:mm:ss A'),
        message: input.newMessage.trim(),
        type: '1', // Text message
        timestamp: timestamp,
      };
      await FirebaseMessages.saveMessage({
        messageObject: messageObj,
        threadId: chatThreadId,
        timestamp: timestamp,
      });
      message.success(intlMessages('message.sent.success'));
    } else {
      message.error(intlMessages('message.sent.error'));
    }
    form.resetFields();
    setShowMessageBox(false);
  };

  const content = (
    <div>
      <Form form={form} onFinish={submitForm}>
        <Form.Item
          name="newMessage"
          rules={[{required: true, message: 'Please enter a message'}]}>
          <TextArea placeholder="Message here" rows={4} />
        </Form.Item>
        <Button type="primary" htmlType="submit" block>
          {<IntlMessages id="send.message" />}
        </Button>
        <br />
        <Link to={{pathname: `/app/messages`, selectFriend: friendData}}>
          <h5 className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block">
            Go to Messages{' '}
            <i className="icon icon-long-arrow-right gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle" />
          </h5>
        </Link>
      </Form>
    </div>
  );

  return (
    <div>
      {followError && <strong>Connection error!</strong>}
      {followLoading && <p>Loading...</p>}
      {followerLook && (
        <span
          className="gx-flex-row"
          style={{cursor: 'pointer'}}
          onClick={() => sendMessage(userId)}>
          <div className="gx-mr-2">
            <i className="icon icon-chat-new gx-fs-xxl gx-text-grey" />
          </div>
          {section !== 'coach' ? (
            <div className="gx-media-body">
              <span className="gx-mb-0 gx-text-grey gx-fs-md">
                <a href>Send Messages</a>
              </span>
            </div>
          ) : null}
        </span>
      )}
      <Popover
        content={content}
        title="Send Message"
        trigger="click"
        visible={showMessageBox}
        placement="bottomRight"
      />
    </div>
  );
};

export default MessageBubble;
