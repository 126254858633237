import app from 'firebase/app';
import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import algoliasearch from 'algoliasearch';
import config from './firebaseConfig';

const client = algoliasearch(
  config.ALGOLIA_APP_ID,
  config.ALGOLIA_USER_SEARCH_KEY,
);

class FirebaseMX {
  constructor() {
    if (!firebase.apps.length) {
      app.initializeApp(config);
    }
    this.auth = app.auth();
    this.db = app.firestore();
    this.rt = app.database();
    this.analytics = app.analytics();
    this.index = client.initIndex('users');
    this.forumIndex = client.initIndex('forums');
  }

  async submitCredentials(memberGUID, institution, name) {
    let memberObj = {code: institution, guid: memberGUID, name: name};
    let docRef = this.db
      .collection('users')
      .doc(this.auth.currentUser.uid.toString())
      .collection('MX')
      .doc();
    docRef.set(memberObj);
  }

  getVerificationStatus(mxUserGuid) {
    return this.rt.ref(`mx/verifications/${mxUserGuid}`);
  }

  async updateCredentials(userId, memberId, guid, institution, name) {
    let memberObj = {code: institution, guid: guid, name: name};
    let docRef = this.db
      .collection('users')
      .doc(userId)
      .collection('MX')
      .doc(memberId);
    docRef.set(memberObj);
  }

  getUserMembers(userId) {
    let userRef = this.db
      .collection('users')
      .doc(userId)
      .collection('MX');
    return userRef;
  }

  getUserAccounts(userId) {
    let userRef = this.db
      .collection('mx')
      .doc(userId)
      .collection('accounts');
    return userRef;
  }

  getUserTransactions(userId) {
    let userRef = this.db
      .collection('mx')
      .doc(userId)
      .collection('txn');
    return userRef;
  }

  deleteMember(userId, memberId) {
    let docRef = this.db
      .collection('users')
      .doc(userId)
      .collection('MX')
      .doc(memberId);

    return docRef.delete();
  }
}

export default new FirebaseMX();
