import app from 'firebase/app';
import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import algoliasearch from 'algoliasearch';
import config from './firebaseConfig';

const client = algoliasearch(
  config.ALGOLIA_APP_ID,
  config.ALGOLIA_USER_SEARCH_KEY,
);

class FirebaseSearch {
  constructor() {
    if (!firebase.apps.length) {
      app.initializeApp(config);
    }
    this.auth = app.auth();
    this.db = app.firestore();
    this.analytics = app.analytics();
    this.index = client.initIndex('users');
    this.forumIndex = client.initIndex('forums');
  }

  // Search functions
  async getSearchResults(searchQuery) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    return await this.index.search({
      query: searchQuery,
      attributesToRetrieve: [
        'username',
        'avatar',
        'social',
        'disabled',
        'vanityURL',
        'id',
      ],
      hitsPerPage: 50,
    });
  }

  async getForumResults(searchQuery) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    return await this.forumIndex.search({
      query: searchQuery,
      attributesToRetrieve: [
        'text',
        'title',
        'upvotes',
        'downvotes',
        'comments',
        'id',
        'author',
        'mediaArray',
        'date',
      ],
      hitsPerPage: 50,
    });
  }
}

export default new FirebaseSearch();
