import React, {useState, useEffect} from 'react';
import {Empty, Spin} from 'antd/lib';
import {FaSpinner} from 'react-icons/fa';
import Auxiliary from 'util/Auxiliary';
import FirebaseSocial from 'firebase/firebaseSocial';
import GuestView from '../../../components/profile/ProfileHeader/GuestView';

const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;

const ProfileGuestView = ({vanityURL}) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    async function getUserOnRedirect(vanURL) {
      const response = await FirebaseSocial.getUserOnRedirect(vanURL);
      setUser(response);
    }
    getUserOnRedirect(vanityURL);
  }, [vanityURL]);

  if (!user) {
    return <Spin indicator={spinnerIcon} />;
  } else {
    return (
      <Auxiliary>
        {user.disabled ? (
          <Empty
            image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
            imageStyle={{
              height: 60,
            }}
            description={<span>User not found!</span>}
          />
        ) : (
          <GuestView profile={user} />
        )}
      </Auxiliary>
    );
  }
};

export default ProfileGuestView;
