import React from 'react';
import { injectStripe, CardElement } from 'react-stripe-elements';
import numeral from 'numeral';

class CheckoutForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      toPay: this.props.toPay,
      isdisable: false
    };
  }

  handleSubmit = (ev) => {
    this.setState({ isdisable: true });
    ev.preventDefault();

    if (this.props.stripe) {
      this.props.stripe
        .createToken()
        .then((payload) => {
          this.props.processCardPayment(payload.token);
        });
    } else {
      this.setState({ isdisable: false });
      console.log("Stripe.js hasn't loaded yet.");
    }
  }

  render() {
    return (
      <div className="checkout" >
        <p> &nbsp;</p>
        <div key="Card Detail" >
          <div className="App-payment" >
            <form onSubmit={this.handleSubmit} >
              <CardElement style={{ base: { fontSize: '18px' } }} />
              <p>&nbsp;</p>
              <div className="form-actions gx-mt-30 " >
                <button className="btn btn-primary btn-block gx-mt-30" disabled={this.state.isdisable}  > PAY {numeral(this.state.toPay).format('$0,0.00')} AND BOOK APPOINTMENT </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);
