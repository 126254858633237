import React, {useState} from 'react';
import {
  Avatar,
  Comment,
  Tooltip,
  Spin,
  Menu,
  Input,
  Modal,
  Form,
  message,
  Dropdown,
  Button,
  Row,
  Col,
} from 'antd/lib';
import {Link} from 'react-router-dom';
import {useDocumentData} from 'react-firebase-hooks/firestore';
import {FaSpinner} from 'react-icons/fa';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';
import FirebaseSocial from 'firebase/firebaseSocial';
import FirebasePosts from 'firebase/firebasePosts';
import {FirebasePostsServices} from 'loanpalz-constants';
import FirebaseSetup from 'firebase/firebaseCommon';
import DisplayDate from 'util/DisplayDate';

FirebaseSetup();

const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;

const {TextArea} = Input;
const {confirm} = Modal;

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 24},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 24},
  },
};

const CommentBox = ({commentData, postInfo, groupId, readonly}) => {
  const [form] = Form.useForm();
  const intlMessages = useFormatMessage();

  const [user, userLoading, userError] = useDocumentData(
    FirebaseSocial.getUserDocumentData(commentData.user),
    {
      snapshotListenOptions: {includeMetadataChanges: true},
    },
  );

  const onModalClose = () => setShowModal(false);
  const onModalOpen = () => setShowModal(true);
  const [showModal, setShowModal] = useState(false);

  const options = ['Edit', 'Delete'];
  let menus = () => (
    <Menu
      onClick={e => {
        if (e.key === 'Edit') {
          onModalOpen();
        } else {
          deletePost();
        }
      }}>
      {options.map(option => (
        <Menu.Item key={option}>{option}</Menu.Item>
      ))}
    </Menu>
  );

  let text = commentData.comment;
  let date = commentData.date;

  form.setFieldsValue({
    text: commentData.comment,
  });

  const onFinish = async values => {
    const {text} = values;
    onModalClose();

    await FirebasePostsServices.updatePostComment(
      postInfo,
      commentData,
      text,
      groupId,
    );
    message.success(intlMessages('post.updated'));
  };

  const deletePost = () => {
    confirm({
      title: 'Are you sure you want to delete this post?',
      onOk: () => {
        FirebasePostsServices.deletePostComment(postInfo, commentData, groupId);
      },
      onCancel() {},
    });
  };

  const onCancel = () => {
    onModalClose();
    form.resetFields();
  };

  let extra = null;
  if (!readonly) {
    extra = (
      <Dropdown overlay={menus} placement="topRight" trigger={['click']}>
        <i className="gx-icon-btn icon icon-ellipse-v" />
      </Dropdown>
    );
  }

  if (!postInfo || !commentData) {
    return <Spin indicator={spinnerIcon} />;
  } else {
    let alreadyLiked = null;
    commentData.likeArray.forEach(likeUser => {
      if (likeUser === FirebaseSocial.auth.currentUser.uid) {
        alreadyLiked = true;
      }
    });

    const handleLikeToggle = () => {
      if (!alreadyLiked) {
        FirebasePosts.likeComment(postInfo, commentData.id, groupId);
      } else {
        FirebasePosts.unlikeComment(
          postInfo,
          commentData.id,
          FirebaseSocial.auth.currentUser.uid,
          groupId,
        );
      }
    };

    const actions = [
      <span key="comment-basic-like">
        <Tooltip title="Like">
          {alreadyLiked ? (
            <i
              className="icon icon-like gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle"
              style={{color: 'primary'}}
              onClick={handleLikeToggle}
            />
          ) : (
            <i
              className="icon icon-like-o gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle"
              onClick={handleLikeToggle}
            />
          )}
        </Tooltip>
        <span style={{paddingLeft: 4, cursor: 'auto'}}>
          {commentData.likeArray.length + ' Likes'}
        </span>
      </span>,
    ];

    return (
      <div>
        {userError && <strong>Connection error!</strong>}
        {userLoading && <p>Loading...</p>}
        {user && (
          <div>
            <Row align="middle">
              <Col span={20}>
                <Comment
                  actions={actions}
                  author={
                    <Link
                      to={{
                        pathname: `/app/user/${user.vanityURL}`,
                        userId: user.id,
                      }}>
                      {commentData.coach ? (
                        <h6 className="gx-wall-user-title">
                          <strong>Coach </strong> {user.username}
                        </h6>
                      ) : (
                        user.username
                      )}
                    </Link>
                  }
                  avatar={
                    <Link
                      to={{
                        pathname: `/app/user/${user.vanityURL}`,
                        userId: user.id,
                      }}>
                      <Avatar src={user.avatar} />
                    </Link>
                  }
                  content={<div style={{whiteSpace: 'pre-line'}}>{text}</div>}
                  datetime={<DisplayDate date={date} timestamp />}
                />
              </Col>
              <Col span={4}>{extra}</Col>
            </Row>

            <Modal
              title="Edit Comment"
              toggle={onModalClose}
              visible={showModal}
              closable={true}
              onOk={() => form.submit()}
              onCancel={onCancel}>
              <Form
                layout="vertical"
                {...formItemLayout}
                form={form}
                onFinish={onFinish}>
                <Form.Item name="text" label="Comment:">
                  <TextArea rows={2} />
                </Form.Item>
                <Button htmlType="submit" style={{display: 'none'}}></Button>
              </Form>
            </Modal>
          </div>
        )}
      </div>
    );
  }
};

export default CommentBox;
