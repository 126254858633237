import app from 'firebase/app';
import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import config from './firebaseConfig';
import { FirebaseDAO } from './firebase';

class FirebaseCares {
  incomesCollection = 'incomes';
  expensesCollection = 'expenses';
  assetsCollection = 'accounts';
  liabilitiesCollection = 'loans';

  constructor() {
    if (!firebase.apps.length) {
      app.initializeApp(config);
    }
    this.auth = app.auth();
    this.db = app.firestore();
    this.analytics = app.analytics();
    this.storage = app.storage();
  }

  // Cares functions

  async createApplication() {
    const ref = await this.db.collection('cares').add({
      date_created: Math.floor(new Date().getTime() / 1000),
    });

    return ref.id;
  }

  async updateApplication(values) {
    return this.db
      .collection('cares')
      .doc(values.id)
      .set(values);
  }

  getUserDocumentData() {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }

    return this.db.collection('users').doc(`${this.auth.currentUser.uid}`);
  }

  async loopMedia(fileInfoList, appId) {
    let fileNum;
    let mediaArray = [];
    let storageLocation = `cares/${appId}/`;
    for (fileNum = 0; fileNum < fileInfoList.length; fileNum++) {
      let upload = fileInfoList[fileNum].originFileObj;
      let fileName = new Date().getTime();
      let img = this.storage.ref().child(storageLocation + fileName);
      await img.put(upload).then(() => {
        img.updateMetadata({
          customMetadata: { name: fileName, appId },
        });
      });
      let downloadURL = await img.getDownloadURL();

      mediaArray.push({ name: fileName, uri: downloadURL });
    }
    return mediaArray;
  }

  async onMediaUpload(fileInfoList, appId) {
    let mediaArray = [];
    if (fileInfoList.length > 0) {
      mediaArray = await this.loopMedia(fileInfoList, appId);
    }
    return mediaArray;
  }

  getNetWorth(startDate, endDate, hook = true) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let netRef = this.db
      .collection('networth')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('daily')
      .where('date', '>=', startDate)
      .where('date', '<=', endDate)
      .orderBy('date', 'asc');

    if (hook) {
      return netRef;
    } else {
      let data = [];
      netRef.get().then(snapshot => {
        snapshot.forEach(doc => {
          let dataObj = {
            ...doc.data(),
            objid: doc.id,
          };
          data.push(dataObj);
        });
      });
      return data;
    }
  }

  updateFinancialSnapshot(values) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    return this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .update({
        'financial.snapshot.loanamount': values.amount,
        'financial.snapshot.loanterm': values.term,
        'financial.snapshot.loaninterest': values.interest,
      });
  }

  async getAppliedAccelerators() {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let kits = [];
    let kitsRef = this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('kits');
    await kitsRef
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          let dataObj = {
            ...doc.data(),
            objid: doc.id,
          };
          kits.push(dataObj);
        });
      })
      .catch(err => {
        console.log('Error getting documents', err);
      });
    return kits;
  }

  applyAcceleratorToProfile(values) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    return this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('kits')
      .add(values);
  }

  updateAcceleratorData(values) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    const { objid } = values;
    delete values.objid;
    return this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('kits')
      .doc(objid)
      .update(values);
  }

  deleteAcceleratorData(values) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let batch = this.db.batch();
    let toDelete = this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('kits')
      .doc(values.objid);
    batch.delete(toDelete);
    batch.commit();
  }

  calculateIncome = async () => {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    const [error, income] = await FirebaseDAO.getFBCollectionData(
      this.incomesCollection,
    )
      .get()
      .then(snapshot => {
        let total = 0;

        snapshot.forEach(doc => {
          const amount = doc.data().amount;

          total += !isNaN(amount) ? parseFloat(amount) : 0;
        });

        return [null, total];
      })
      .catch(err => {
        console.log('Error getting documents', err);
        return [err, 0];
      });

    return [error, income];
  };

  calculateExpenses = async () => {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    const [error, expenses] = await FirebaseDAO.getFBCollectionData(
      this.expensesCollection,
    )
      .get()
      .then(snapshot => {
        let total = 0;

        snapshot.forEach(doc => {
          const amount = doc.data().amount;

          total += !isNaN(amount) ? parseFloat(amount) : 0;
        });

        return [null, total];
      })
      .catch(err => {
        console.log('Error getting documents', err);
        return [err, 0];
      });

    return [error, expenses];
  };

  calculateAssets = async () => {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    const [error, assets] = await FirebaseDAO.getFBCollectionData(
      this.assetsCollection,
    )
      .get()
      .then(snapshot => {
        let total = 0;
        snapshot.forEach(doc => {
          const amount = doc.data().balance;
          total += !isNaN(amount) ? parseFloat(amount) : 0;
        });
        return [null, total];
      })
      .catch(err => {
        console.log('Error getting documents', err);
        return [err, 0];
      });

    return [error, assets];
  };

  calculateLiabilities = async () => {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    const [error, liabilities] = await FirebaseDAO.getFBCollectionData(
      this.liabilitiesCollection,
    )
      .get()
      .then(snapshot => {
        let total = 0;
        snapshot.forEach(doc => {
          const amount = doc.data().currentBalance;
          total += !isNaN(amount) ? parseFloat(amount) : 0;
        });
        return [null, total];
      })
      .catch(err => {
        console.log('Error getting documents', err);
        return [err, 0];
      });

    return [error, liabilities];
  };

  // Static method, does not listen to snapshot updates
  calculateNetWorth = async () => {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    const assets = await this.calculateAssets();
    const liabilities = await this.calculateLiabilities();
    return { assets, liabilities };
  };

  addTransaction(values) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    this.addTags(values.tags);
    return this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('transactions')
      .add(values);
  }

  addTags(tags) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let uniqueTags = [];
    tags.forEach(tag => {
      this.db
        .collection('users')
        .doc(`${this.auth.currentUser.uid}`)
        .collection('tags')
        .where('tag.title', '==', tag.title)
        .get()
        .then(snapshot => {
          if (snapshot.empty) {
            this.db
              .collection('users')
              .doc(`${this.auth.currentUser.uid}`)
              .collection('tags')
              .add({ tag: tag });
            uniqueTags.push(tag);
          } else {
            snapshot.forEach(doc => {
              uniqueTags.push(doc.data().tag);
            });
          }
        })
        .catch(err => {
          console.log('Error getting documents', err);
        });
    });
  }

  editTransaction(values) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    this.addTags(values.tags);
    const { id } = values;
    delete values.id;
    return this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('transactions')
      .doc(id)
      .update(values);
  }

  getTransactions() {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    return this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('transactions')
      .orderBy('timestamp', 'desc');
  }

  getRecentTransactions() {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    return this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('transactions')
      .orderBy('timestamp', 'desc')
      .limit(50);
  }

  getBankAccounts() {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    return this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('accounts');
  }

  addBankAccount(values) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    return this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('accounts')
      .add(values);
  }

  // Plaid functions
  getPlaidData(accountType) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    return this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('plaid')
      .doc('accounts')
      .collection('active')
      .where('type', '==', accountType);
  }
}

export default new FirebaseCares();
