import React from 'react';
import {useCollectionData} from 'react-firebase-hooks/firestore';
import Widget from 'components/Widget/index';
import FirebaseCoaches from 'firebase/firebaseCoaches';
import {Spin} from 'antd/lib';
import {FaSpinner} from 'react-icons/fa';
import {OptimizelyFeature} from '@optimizely/react-sdk';

const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;

const Income = ({profile}) => {
  const [allCoaches, loading, error] = useCollectionData(
    FirebaseCoaches.getCoachesDocumentData(),
    {
      idField: 'id',
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  return (
    <div>
      {error && <strong> Error loading coaches.Please try again later </strong>}
      {loading && <Spin indicator={spinnerIcon} />}
      {allCoaches && (
        <OptimizelyFeature feature="income">
          {isEnabled =>
            isEnabled ? (
              <Widget>
                <div>
                  <h2>My Income</h2>
                </div>
              </Widget>
            ) : (
              <div className="gx-feature-disabled">
                Feature Flag Disabled by Optimizely
              </div>
            )
          }
        </OptimizelyFeature>
      )}
    </div>
  );
};

export default Income;
