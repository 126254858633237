import React, {useState} from 'react';
import {Avatar, Button, Card, Divider, Input, Modal, Spin, message} from 'antd';
import FirebasePosts from 'firebase/firebasePosts';
import FirebaseSetup from 'firebase/firebaseCommon';
import {FirebasePostsServices} from 'loanpalz-constants';
import UploadPicture from 'components/common/UploadPicture';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';

const {TextArea} = Input;

FirebaseSetup();

const WriteBox = ({profile, groupData}) => {
  const [text, setText] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const {coach} = profile;

  const [uploading, setUploading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const [fileInfoList, setFileInfoList] = useState([]);

  const intlMessages = useFormatMessage();

  const isEnabled = text === '';

  const handleAddPost = async () => {
    if (fileInfoList.length > 0) {
      setUploading(true);
      setSubmitting(true);
      message.info(intlMessages('files.uploading'));
      await FirebasePosts.onMediaUpload(fileInfoList, groupData).then(
        mediaArray => {
          FirebasePostsServices.addPost({text, mediaArray, groupData, coach});
          setSubmitting(false);
          setText('');
          setFileInfoList([]);
          setPreviewVisible(false);
          setPreviewImage('');
          setIsOpen(false);
        },
      );
      setUploading(false);
      message.success(intlMessages('files.uploaded'));
    } else {
      const [error] = await FirebasePostsServices.addPost({
        text,
        groupData,
        coach,
      });

      if (error) {
        message.error(error);
        console.log(error);
        return;
      }

      setText('');
      setPreviewVisible(false);
      setPreviewImage('');
      setIsOpen(false);
    }
  };

  const handleClickImage = () => {
    setIsOpen(!isOpen);
  };

  const onChange = input => {
    setText(input.target.value);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  if (!profile) {
    return null;
  } else {
    return (
      <Spin spinning={uploading} delay={500}>
        <Card className="gx-card">
          <div className="gx-media gx-mb-2">
            <Avatar className="gx-size-50 gx-mr-3" src={profile.avatar} />
            <div className="gx-media-body">
              <TextArea
                value={text}
                rows={4}
                onChange={event => {
                  onChange(event);
                }}
                placeholder="What's on your mind?"
              />
            </div>
          </div>

          <div className="gx-clearfix">
            {isOpen === true ? (
              <UploadPicture
                fList={fileInfoList}
                onFileChange={setFileInfoList}
              />
            ) : null}

            <Modal
              visible={previewVisible}
              footer={null}
              onCancel={handleCancel}>
              <img alt="example" style={{width: '100%'}} src={previewImage} />
            </Modal>
          </div>
          <Divider />

          <div className="ant-row-flex">
            <div className="gx-pointer gx-mb-3" onClick={handleClickImage}>
              <i className="icon icon-camera gx-mr-2 gx-fs-xl gx-d-inline-flex gx-vertical-align-middle" />
              <span className="gx-fs-sm"> Add Photos </span>
            </div>

            <Button
              type="primary"
              size="small"
              disabled={isEnabled ? 'disabled' : ''}
              loading={submitting}
              onClick={handleAddPost}>
              Add Post
            </Button>
          </div>
        </Card>
      </Spin>
    );
  }
};

export default WriteBox;
