import React, {useEffect} from 'react';
import {Col, InputNumber, Radio, Select, Tooltip} from 'antd';
import '../settingsWizard.less';
import withStepTemplate from './withStepTemplate';

import {InfoCircleOutlined} from '@ant-design/icons';

const {Option} = Select;

const StepTaxes = props => {
  useEffect(() => {
    if (!props.state.taxStepVisited) {
      props.dispatch({
        type: 'taxStepVisited',
        payload: true,
      });
    }
  });

  return (
    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
      {/* <h4 className="gx-mb-2 gx-mt-5 formLabel">
        <span>Have you filed your 2019 taxes yet?
          <Tooltip placement="topLeft" title='Have you filed your 2019 taxes yet?' trigger='hover'>
            <InfoCircleOutlined className="gx-ml-1 gx-pointer" style={{ fontSize: '16px', verticalAlign: 'middle' }} />
          </Tooltip>
        </span>
      </h4>
      <Radio.Group value={props.state.filedFor2019} buttonStyle="solid" onChange={value => {
        props.dispatch({
          type: 'filedFor2019',
          payload: value.target.value,
        });
      }}>
        <Radio.Button value={true}>Yes</Radio.Button>
        <Radio.Button value={false}>No</Radio.Button>
      </Radio.Group> */}

      {/* Filed for 2019 */}
      {props.state.filedFor2019 && (
        <div class="">
          <h4 className="gx-mb-2 mt-3 formLabel">
            <span>
              What was your filing status in your 2019 taxes?
              <Tooltip
                placement="topLeft"
                title="What was your filing status in your 2019 taxes?"
                trigger="hover">
                <InfoCircleOutlined
                  className="gx-ml-1 gx-pointer"
                  style={{fontSize: '16px', verticalAlign: 'middle'}}
                />
              </Tooltip>
            </span>
          </h4>

          <Radio.Group
            value={props.state.taxFileType}
            buttonStyle="solid"
            onChange={value => {
              props.dispatch({
                type: 'taxFileType',
                payload: value.target.value,
              });

              // setTimeout(() => {
              //   // props.push('worker')
              //   props.next()
              // }, 500)
            }}>
            <Radio.Button value="single">Single</Radio.Button>
            <Radio.Button value="married">Married</Radio.Button>
            <Radio.Button value="hoh">Head of household</Radio.Button>
          </Radio.Group>

          <div className="gx-mt-3">
            <h4 className="gx-mb-2 formLabel">
              <span>
                Number of dependents claimed on your taxes:
                <Tooltip
                  placement="topLeft"
                  title="Number of dependents claimed on your taxes"
                  trigger="hover">
                  <InfoCircleOutlined
                    className="gx-ml-1 gx-pointer"
                    style={{fontSize: '16px', verticalAlign: 'middle'}}
                  />
                </Tooltip>
              </span>
            </h4>
            <Select
              value={props.state.numChildren}
              // defaultValue="0"
              style={{width: 60}}
              onChange={value => {
                props.dispatch({
                  type: 'numChildren',
                  payload: value,
                });
                // setTimeout(() => {
                //   // props.push('marital_status')
                //   props.next()
                // }, 500)
              }}>
              <Option value="0">0</Option>
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
              <Option value="5">5</Option>
              <Option value="6">6</Option>
              <Option value="7">7</Option>
              <Option value="8">8</Option>
              <Option value="9">9</Option>
              <Option value="10">10</Option>
              <Option value="11">11</Option>
              <Option value="12">12</Option>
              <Option value="13">13</Option>
              <Option value="14">14</Option>
              <Option value="15">15</Option>
              <Option value="16">16</Option>
            </Select>
          </div>

          <h4 className="gx-mb-2 gx-mt-3 formLabel">
            <span>
              What was your approximate adjusted gross income in 2019?
              <Tooltip
                placement="topLeft"
                title="What was your adjusted gross income in 2019?"
                trigger="hover">
                <InfoCircleOutlined
                  className="gx-ml-1 gx-pointer"
                  style={{fontSize: '16px', verticalAlign: 'middle'}}
                />
              </Tooltip>
            </span>
          </h4>

          <InputNumber
            value={props.state.agi2019}
            onFocus={event => event.target.select()}
            onChange={value => {
              props.dispatch({
                type: 'agi2019',
                payload: value,
              });
              // setTimeout(() => {
              //   // props.push('marital_status')
              //   props.next()
              // }, 500)
            }}
            formatter={value =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            style={{width: '180px'}}
          />
        </div>
      )}

      {/* Didn't file for 2019 */}
      {!props.state.filedFor2019 && (
        <div>
          <h4 className="gx-mb-2 formLabel">
            <span>
              What was your filing status in your 2018 taxes?
              <Tooltip
                placement="topLeft"
                title="What was your filing status in your 2019 taxes?"
                trigger="hover">
                <InfoCircleOutlined
                  className="gx-ml-1 gx-pointer"
                  style={{fontSize: '16px', verticalAlign: 'middle'}}
                />
              </Tooltip>
            </span>
          </h4>

          <Radio.Group
            value={props.state.taxFileType}
            buttonStyle="solid"
            onChange={value => {
              props.dispatch({
                type: 'taxFileType',
                payload: value.target.value,
              });

              // setTimeout(() => {
              //   // props.push('worker')
              //   props.next()
              // }, 500)
            }}>
            <Radio.Button value="single">Single</Radio.Button>
            <Radio.Button value="married">Married</Radio.Button>
            <Radio.Button value="hoh">Head of household</Radio.Button>
          </Radio.Group>

          <div class="gx-mt-3">
            <h4 className="gx-mb-2 formLabel">
              <span>
                Number of dependents claimed on your taxes:
                <Tooltip
                  placement="topLeft"
                  title="Number of dependents claimed on your taxes"
                  trigger="hover">
                  <InfoCircleOutlined
                    className="gx-ml-1 gx-pointer"
                    style={{fontSize: '16px', verticalAlign: 'middle'}}
                  />
                </Tooltip>
              </span>
            </h4>
            <Select
              value={props.state.numChildren}
              // defaultValue="0"
              style={{width: 60}}
              onChange={value => {
                props.dispatch({
                  type: 'numChildren',
                  payload: value,
                });
                // setTimeout(() => {
                //   // props.push('marital_status')
                //   props.next()
                // }, 500)
              }}>
              <Option value="0">0</Option>
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
              <Option value="5">5</Option>
              <Option value="6">6</Option>
              <Option value="7">7</Option>
              <Option value="8">8</Option>
              <Option value="9">9</Option>
              <Option value="10">10</Option>
              <Option value="11">11</Option>
              <Option value="12">12</Option>
              <Option value="13">13</Option>
              <Option value="14">14</Option>
              <Option value="15">15</Option>
              <Option value="16">16</Option>
            </Select>
          </div>

          <h4 className="gx-mb-2 gx-mt-3 formLabel">
            <span>
              What was your approximate adjusted gross income in 2018?
              <Tooltip
                placement="topLeft"
                title="What was your approximate adjusted gross income in 2018?"
                trigger="hover">
                <InfoCircleOutlined
                  className="gx-ml-1 gx-pointer"
                  style={{fontSize: '16px', verticalAlign: 'middle'}}
                />
              </Tooltip>
            </span>
          </h4>

          <InputNumber
            value={props.state.agi2018}
            onFocus={event => event.target.select()}
            onChange={value => {
              props.dispatch({
                type: 'agi2018',
                payload: value,
              });
              // setTimeout(() => {
              //   // props.push('marital_status')
              //   props.next()
              // }, 500)
            }}
            formatter={value =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            style={{width: '180px'}}
          />

          <h4 className="gx-mb-2 gx-mt-3 formLabel">
            <span>
              How much do you estimate your 2019 Adjusted Gross Income (AGI)
              will be?
              <Tooltip
                placement="topLeft"
                title="How much do you estimate your 2019 Adjusted Gross Income (AGI) will be?"
                trigger="hover">
                <InfoCircleOutlined
                  className="gx-ml-1 gx-pointer"
                  style={{fontSize: '16px', verticalAlign: 'middle'}}
                />
              </Tooltip>
            </span>
          </h4>

          <InputNumber
            value={props.state.agiEstimated2019}
            onFocus={event => event.target.select()}
            onChange={value => {
              props.dispatch({
                type: 'agiEstimated2019',
                payload: value,
              });
              // setTimeout(() => {
              //   // props.push('marital_status')
              //   props.next()
              // }, 500)
            }}
            formatter={value =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            style={{width: '180px'}}
          />
        </div>
      )}
    </Col>
  );
};

export default withStepTemplate(StepTaxes);
