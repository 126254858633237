import React from "react";
import UserCell from "./UserCell/index";

const ChatUserList = ({ chatUsers, selectedSectionId, onSelectUser }) => {
  return (
    <div className="gx-chat-user">
      {chatUsers && chatUsers.map((chat, index) =>
        <UserCell key={index} chat={chat} selectedSectionId={selectedSectionId} onSelectUser={onSelectUser} />
      )}
    </div>
  )
};

export default ChatUserList;
