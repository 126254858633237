import React from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

const PlotPieChart = ({ data, title, colors, tooltip, outerRadius, innerRadius, height }) => {
    return (
        <ResponsiveContainer width="100%" height={height}>
            <PieChart>
                <Tooltip content={tooltip} />
                <text
                    x="50%" className="h5"
                    y="50%" textAnchor="middle" dominantBaseline="middle">
                    {title}
                </text>
                <Pie
                    data={data} dataKey="value"
                    cx="50%"
                    cy="50%"
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    fill="#8884d8"
                >
                    {
                        data.map((entry, index) => <Cell key={index} fill={colors[entry.id]} />)
                    }
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
}

export default PlotPieChart;
