import React from 'react';
import {Tooltip} from 'antd';

const AppModuleHeader = ({
  placeholder,
  onChange,
  value,
  onViewGrid,
  onViewList,
}) => {
  return (
    <div className="gx-module-box-header-inner">
      <div className="gx-search-bar gx-lt-icon-search-bar-lg gx-module-search-bar gx-d-none gx-d-sm-block">
        <div className="gx-form-group">
          <input
            className="ant-input gx-border-0"
            type="search"
            placeholder={placeholder}
            onChange={onChange}
            value={value}
          />
          <span className="gx-search-icon gx-pointer">
            <i className="icon icon-search" />
          </span>
        </div>
      </div>
      <div className="gx-module-box-header-right">
        <Tooltip title="List view">
          <span className="gx-fs-xl" onClick={() => onViewList()}>
            <i className="icon icon-menu gx-icon-btn" />
          </span>
        </Tooltip>

        <Tooltip title="Grid view">
          <span className="gx-fs-xl" onClick={() => onViewGrid()}>
            <i className="icon icon-apps gx-icon-btn" />
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default AppModuleHeader;

AppModuleHeader.defaultProps = {
  styleName: '',
  value: '',
  notification: true,
  apps: true,
};
