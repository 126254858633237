import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  Avatar,
  Input,
  Modal,
  Row,
  Col,
  Upload,
  message,
  Tabs,
  Card,
  Spin,
} from 'antd/lib';
import { useParams, withRouter } from 'react-router-dom';
import {
  useDocumentData,
  useCollectionData,
} from 'react-firebase-hooks/firestore';
import About from 'components/profile/About/index';
import Contact from 'components/profile/Contact/index';
import Posts from 'components/profile/Posts/index';
import { FaUpload, FaTrash, FaSpinner } from 'react-icons/fa';
import Auxiliary from 'util/Auxiliary';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import IntlMessages from 'util/IntlMessages';
import SocialList from '../../../routes/app/socialList';
import { TEMPORARY_AVATAR, TEMPORARY_COVER } from 'util/constants';
import FirebasePosts from 'firebase/firebasePosts';
import FirebaseSocial from 'firebase/firebaseSocial';
import FirebaseForums from 'firebase/firebaseForums';
import Vlogs from 'components/Vlogs';
import CoachTabs from 'components/app/coaches';
import PhotoGallery from 'components/profile/PhotoGallery';
import Thread from '../../../routes/app/forum/Thread';
import BookAppointment from 'components/app/coaches/booking/BookAppointment';
import Social from '../Social';

const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;

function sortDeepArray(arr) {
  return arr.sort((a, b) => {
    let aTime = new Date(a.date).getTime();
    let bTime = new Date(b.date).getTime();
    if (aTime < bTime) {
      return 1;
    }
    if (aTime > bTime) {
      return -1;
    }
    return 0;
  });
}

const ProfileHeader = ({ userProfile, currentProfile, readonly, history }) => {
  const [form] = Form.useForm();
  const { slug } = useParams();
  const [tempAvatar, setTempAvatar] = useState(userProfile.avatar);
  const [tempCover, setTempCover] = useState(userProfile.cover);
  const [avatarInfo, setAvatarInfo] = useState(null);
  const [coverInfo, setCoverInfo] = useState(null);
  const [avatar, setAvatar] = useState(userProfile.avatar);
  const [cover, setCover] = useState(userProfile.cover);
  const TabPane = Tabs.TabPane;

  useEffect(() => {
    form.setFieldsValue({
      username: userProfile.username,
      location: userProfile.social.location,
      avatar: avatar,
      cover: cover,
    });
  }, [form.location]); // eslint-disable-line

  const [current, currentLoading, currentError] = useDocumentData(
    FirebaseSocial.getUserDocumentData(currentProfile.id),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );

  const [user, userLoading, userError] = useDocumentData(
    FirebaseSocial.getUserDocumentData(userProfile.id),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );

  const [friendOneLook, friendOneLoading, friendOneError] = useCollectionData(
    FirebaseSocial.getUserFriendOne(userProfile.id),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [friendTwoLook, friendTwoLoading, friendTwoError] = useCollectionData(
    FirebaseSocial.getUserFriendTwo(userProfile.id),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [followerList, followerLoading, followerError] = useCollectionData(
    FirebaseSocial.getUserFollowers(userProfile.id),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [followingList, followingLoading, followingError] = useCollectionData(
    FirebaseSocial.getUserFollowing(userProfile.id),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [postList, postListLoading, postListError] = useCollectionData(
    FirebasePosts.getUserPostList(userProfile.id),
    {
      idField: 'id',
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [threadList, threadListLoading, threadListError] = useCollectionData(
    FirebaseForums.getUserForums(userProfile.id),
    {
      idField: 'id',
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [showModal, setShowModal] = useState(false);
  const intlMessages = useFormatMessage();

  const onModalClose = () => setShowModal(false);
  const onModalOpen = () => setShowModal(true);

  if (!user || !current || !postList) {
    return <Spin indicator={spinnerIcon} />;
  } else {
    let userPostList = sortDeepArray(postList);

    let location = user.social.location;
    let username = user.username;
    let tempLocation = user.social.location;
    let tempUsername = user.username;

    // TODO: Refactor this complex method
    const saveProfileInformation = async values => {
      await FirebaseSocial.updateSocialHeader(values);

      if (tempAvatar === null) {
        FirebaseSocial.removeProfilePicture();
        setTempAvatar(TEMPORARY_AVATAR);
        setAvatar(TEMPORARY_AVATAR);
      } else if (avatarInfo !== null) {
        FirebaseSocial.onProfilePictureChange(avatarInfo);
        setAvatar(tempAvatar);
      }

      if (tempCover === null) {
        FirebaseSocial.removeCoverPicture();
        setTempCover(TEMPORARY_COVER);
        setCover(TEMPORARY_COVER);
      } else if (coverInfo !== null) {
        FirebaseSocial.onCoverPictureChange(coverInfo);
        setCover(tempCover);
      }

      location = tempLocation;
      username = tempUsername;

      setAvatarInfo(null);
      setCoverInfo(null);

      message.success(intlMessages('profile.update.success'));
      onModalClose();
    };

    const cancelFormSubmission = () => {
      tempLocation = location;
      tempUsername = username;
      setTempAvatar(avatar);
      setTempCover(cover);
      setAvatarInfo(null);
      setCoverInfo(null);
      onModalClose();
    };

    let invalidUpload = false;
    function beforeUpload(file) {
      //Restrict image format, size, resolution
      const isJPG = file.type === 'image/jpeg';
      const isJPEG = file.type === 'image/jpeg';
      const isGIF = file.type === 'image/gif';
      const isPNG = file.type === 'image/png';

      if (!(isJPG || isJPEG || isGIF || isPNG)) {
        message.error(intlMessages('avatar.type.error'));
        invalidUpload = true;
        return false;
      }

      const isLt2M = file.size / 10240 / 10240 < 2;
      if (!isLt2M) {
        message.error(intlMessages('avatar.size.error'));
        invalidUpload = true;
        return false;
      }

      invalidUpload = false;
      return true;
    }

    function getBase64(img, callback) {
      if (!invalidUpload) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
      }
    }

    const avatarSettings = {
      name: 'file',
      headers: {
        authorization: 'authorization-text',
      },
      onChange: info => {
        if (!invalidUpload) {
          setAvatarInfo(info);
          getBase64(info.file.originFileObj, imageUrl =>
            setTempAvatar(imageUrl),
          );
        }
      },
    };

    const coverSettings = {
      name: 'file',
      headers: {
        authorization: 'authorization-text',
      },
      onChange: info => {
        if (!invalidUpload) {
          setCoverInfo(info);
          getBase64(info.file.originFileObj, imageUrl =>
            setTempCover(imageUrl),
          );
        }
      },
    };

    let extra = null;
    let extraContact = <Contact userId={user.id} />;
    let avatarEdit = (
      <div className="gx-flex-row">
        <div className="gx-profile-banner-avatar profile-pic-container">
          <Avatar
            src={avatar}
            className="gx-size-120 gx-pointer gx-mb-10 gx-ml-20"
          />
        </div>
        <div className="gx-profile-banner-avatar-info gx-pointer gx-vertical-align-middle">
          <h2 className="gx-mt-3 gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light">
            {username}
          </h2>
          <p className="gx-mb-0 gx-fs-lg">{location}</p>
        </div>
      </div>
    );

    if (!readonly) {
      extraContact = null;
      extra = (
        <i
          style={{ position: 'absolute', right: 10, cursor: 'pointer' }}
          className="icon icon-edit gx-fs-lg gx-mr-2 gx-mr-sm-3 gx-d-inline-flex gx-vertical-align-top"
          onClick={onModalOpen}
        />
      );

      avatarEdit = (
        <div className="gx-flex-row">
          <div
            className="gx-profile-banner-avatar profile-pic-container"
            onClick={onModalOpen}>
            <Avatar
              src={avatar}
              className="gx-size-120 gx-pointer gx-mb-10 gx-ml-20"
            />
            <div className="edit-profile-pencil gx-pointer">
              <i className="icon icon-edit gx-fs-xxxl gx-d-inline-flex gx-vertical-align-center" />
            </div>
          </div>
          <div
            onClick={onModalOpen}
            className="gx-profile-banner-avatar-info gx-pointer gx-vertical-align-middle">
            <h2 className="gx-mt-3 gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light">
              {username}
            </h2>
            <p className="gx-mb-0 gx-fs-lg">{location}</p>
          </div>
        </div>
      );
    }

    return (
      <div>
        {(friendOneError ||
          friendTwoError ||
          followerError ||
          followingError ||
          postListError ||
          threadListError ||
          userError ||
          currentError) && <strong>Connection error!</strong>}
        {(friendOneLoading ||
          friendTwoLoading ||
          followerLoading ||
          followingLoading ||
          postListLoading ||
          threadListLoading ||
          userLoading ||
          currentLoading) && <p>Loading...</p>}
        {friendOneLook &&
          friendTwoLook &&
          followerList &&
          followingList &&
          postList &&
          threadList &&
          user &&
          current && (
            <Auxiliary>
              <div
                className="gx-profile-banner"
                style={{
                  backgroundImage: `url(${cover})`,
                  backgroundSize: 'cover',
                  maxHeight: 169,
                }}>
                <div className="gx-profile-container" style={{ top: -8 }}>
                  {extra}
                  <div className="gx-profile-banner-top">
                    {avatarEdit}
                    <div className="gx-profile-banner-top-right">
                      <ul className="gx-follower-list">
                        <li>
                          <span className="gx-fs-sm">Followers</span>
                          <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">
                            {followerList.length}
                          </span>
                        </li>
                        <li>
                          <span className="gx-fs-sm">Following</span>
                          <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">
                            {followingList.length}
                          </span>
                        </li>
                        <li>
                          <span className="gx-fs-sm">Friends</span>
                          <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">
                            {friendOneLook.length + friendTwoLook.length}
                          </span>
                        </li>
                      </ul>
                      {user.coach === true && user.id !== current.id && (
                        <div className="gx-pt-3" style={{ marginBottom: -20 }}>
                          <BookAppointment coach={user} profile={current} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="gx-profile-banner-bottom" style={{ marginTop: 72 }}>
                <Tabs
                  style={{ width: '100%' }}
                  defaultActiveKey={slug}
                  onChange={key =>
                    history.push(`/app/user/${user.vanityURL}/${key}`)
                  }>
                  <TabPane tab="Profile" key="profile">
                    <Row>
                      <Col xl={16} lg={14} md={14} sm={24} xs={24}>
                        <About profile={user} readonly={readonly} />
                        <Posts
                          profile={user}
                          postList={userPostList}
                          readonly={readonly}
                        />
                      </Col>
                      <Col xl={8} lg={10} md={10} sm={24} xs={24}>
                        {extraContact}
                        <Row>
                          <Col xl={24} lg={24} md={24} sm={12} xs={24}>
                            <Social
                              userId={user.id}
                              readonly={readonly}
                              friendView={true}
                            />
                            <Social userId={user.id} followingView={true} />
                            <Social userId={user.id} followerView={true} />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane
                    tab={
                      'Friends ' + (friendOneLook.length + friendTwoLook.length)
                    }
                    key="friends">
                    <SocialList
                      friendView={true}
                      userId={user.id}
                      profileView={true}
                    />
                  </TabPane>
                  <TabPane
                    tab={'Following ' + followingList.length}
                    key="following">
                    <SocialList
                      followingView={true}
                      userId={user.id}
                      profileView={true}
                    />
                  </TabPane>
                  <TabPane
                    tab={'Followers ' + followerList.length}
                    key="followers">
                    <SocialList
                      followerView={true}
                      userId={user.id}
                      profileView={true}
                    />
                  </TabPane>
                  <TabPane tab="Vlogs" key="vlogs">
                    <Vlogs
                      profile={current}
                      userId={user.id}
                      profileView={true}
                    />
                  </TabPane>

                  <TabPane tab="Forums" key="forums">
                    {threadList.length > 0 ? (
                      threadList.map(thread => {
                        return <Thread threadInfo={thread} key={thread.id} />;
                      })
                    ) : readonly ? (
                      <Card>
                        <div className="text-center">
                          <h2>This user hasn't started any forums.</h2>
                        </div>
                      </Card>
                    ) : (
                          <Card>
                            <div className="text-center">
                              <h2>
                                You haven't created any forums. Let's make one now!
                          </h2>
                              <Button
                                variant="raised"
                                className="gx-mt-3"
                                type="primary"
                                onClick={() => history.push('/app/forum')}>
                                <div className="ant-row-flex">Go to Forums</div>
                              </Button>
                            </div>
                          </Card>
                        )}
                  </TabPane>

                  <TabPane tab="Gallery" key="gallery">
                    <PhotoGallery userId={user.id} />
                  </TabPane>

                  {!readonly && (
                    <TabPane tab="Coach" key="coach">
                      <CoachTabs
                        profile={current}
                        userId={user.id}
                        profileView={true}
                      />
                    </TabPane>
                  )}
                </Tabs>
              </div>
            </Auxiliary>
          )}

        <Modal
          title={
            current.name === '' ? (
              <IntlMessages id="profile.addProfileInfo" />
            ) : (
                <IntlMessages id="profile.saveProfileInfo" />
              )
          }
          toggle={onModalClose}
          visible={showModal}
          closable={true}
          onOk={() => {
            form.submit();
          }}
          onCancel={() => {
            cancelFormSubmission();
          }}>
          <Form form={form} onFinish={saveProfileInformation}>
            <div className="gx-modal-box-row">
              <div className="gx-modal-box-form-item">
                <Tabs defaultActiveKey="1" style={{ marginTop: '1px' }}>
                  <TabPane tab="General" key="1">
                    <Row style={{ justifyContent: 'left' }}>
                      <Col span={24}>
                        <div className="gx-form-group profile-pic-container">
                          <Form.Item name="avatar">
                            <Upload
                              name="avatar"
                              showUploadList={false}
                              {...avatarSettings}
                              beforeUpload={beforeUpload}>
                              <Avatar
                                src={tempAvatar || TEMPORARY_AVATAR}
                                className="gx-size-200 gx-pointer gx-mb-10 gx-ml-20"
                              />
                              <div className="edit-profile-pencil gx-pointer">
                                <i className="icon icon-edit gx-fs-xxxl gx-d-inline-flex gx-vertical-align-center" />
                              </div>
                            </Upload>
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>

                    <Row style={{ justifyContent: 'left' }}>
                      <Col span={24}>
                        <div
                          className="gx-form-group "
                          style={{ verticalAlign: 'bottom' }}>
                          <Upload
                            name="avatar"
                            showUploadList={false}
                            {...avatarSettings}
                            beforeUpload={beforeUpload}>
                            <Button type="primary" className="gx-mr-2">
                              <FaUpload className="gx-mr-2" />
                              <IntlMessages id="profile.addPicture" />
                            </Button>
                          </Upload>

                          <Button
                            type="danger"
                            onClick={() => {
                              setTempAvatar(null);
                            }}>
                            <FaTrash className="gx-mr-8 gx-mr-2" />
                            <IntlMessages id="profile.removePicture" />
                          </Button>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={24}>
                        <div className="gx-form-group">
                          <Form.Item name="username">
                            <Input
                              placeholder="Username"
                              addonBefore="Username"
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <div className="gx-form-group">
                          <Form.Item name="location">
                            <Input
                              placeholder="Enter your location"
                              addonBefore="Location"
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>

                  <TabPane tab="Cover Photo" key="2">
                    <Row style={{ justifyContent: 'left' }}>
                      <Col span={24}>
                        <div className="gx-form-group profile-pic-container">
                          <Form.Item name="cover">
                            <Upload
                              name="cover"
                              showUploadList={false}
                              {...coverSettings}
                              beforeUpload={beforeUpload}>
                              <Card
                                style={{ cursor: 'pointer' }}
                                cover={
                                  <img
                                    alt=""
                                    src={tempCover || TEMPORARY_COVER}
                                  />
                                }
                              />
                              <div className="edit-profile-pencil gx-pointer">
                                <i className="icon icon-edit gx-fs-xxxl gx-d-inline-flex gx-vertical-align-center" />
                              </div>
                            </Upload>
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>

                    <Row style={{ justifyContent: 'left' }}>
                      <Col span={24}>
                        <div
                          className="gx-form-group "
                          style={{ verticalAlign: 'bottom' }}>
                          <Upload
                            name="cover"
                            showUploadList={false}
                            {...coverSettings}
                            beforeUpload={beforeUpload}>
                            <Button type="primary" className="gx-mr-2">
                              <FaUpload className="gx-mr-2" />
                              <IntlMessages id="profile.addCover" />
                            </Button>
                          </Upload>
                          <Button
                            type="danger"
                            onClick={() => {
                              setTempCover(null);
                            }}>
                            <FaTrash className="gx-mr-8 gx-mr-2" />
                            <IntlMessages id="profile.removeCover" />
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                </Tabs>
              </div>
            </div>
            <Button htmlType="submit" style={{ display: 'none' }}></Button>
          </Form>
        </Modal>
      </div>
    );
  }
};
export default withRouter(ProfileHeader);
