import React, { useState } from "react";
import { Row, Col } from 'antd/lib';
import GroupItemCard from "./joinedGroupItemCard";
import { useCollectionData } from 'react-firebase-hooks/firestore';
import FirebaseGroups from 'firebase/firebaseGroups';
import CircularProgress from 'components/CircularProgress';

const JoinedGroupCardList = ({ profile }) => {
    const [searchKeyword, setSearchKeyword] = useState('');

    const [joinedGroups, joinedGroupsLoading] = useCollectionData(
        FirebaseGroups.getUserJoinedGroups(profile.id),
        {
            idField: 'id',
            snapshotListenOptions: {
                includeMetadataChanges: true,
            },
        },
    );

    const updateSearch = evt => {
        let keyWord = evt.target.value.trim();
        setSearchKeyword(keyWord);
    };

    return (
        <div>
            {
                joinedGroups && joinedGroups.length ?
                    <Row type="flex" justify="end">
                        <Col span={8} className="gx-mr-2 ">
                            <input
                                className="ant-input ant-input gx-mb-3"
                                type="search"
                                placeholder="Search groups"
                                id="search"
                                onKeyUp={updateSearch}
                                onChange={updateSearch}
                            />
                        </Col>
                    </Row>
                    : null
            }

            {joinedGroupsLoading ?
                <CircularProgress className="gx-loader-400" />
                :
                joinedGroups && joinedGroups.length ?
                    joinedGroups.map((data, index) =>
                        <GroupItemCard
                            profile={profile}
                            key={index}
                            data={data}
                            searchKeyword={searchKeyword}
                        />
                    ) :
                    <div className="text-center">
                        <h2>You don't have join any groups yet!</h2>
                    </div>
            }

        </div>
    )
}

export default JoinedGroupCardList;
