import {Col, Row} from 'antd';
import IconCheckBox from 'components/app/iconcheckbox';
import Widget from 'components/Widget/index';
import {FirebaseDAO} from 'firebase/firebase';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {SETTINGS_OPTIONS} from 'util/constants';

const SettingsStep = ({profile, collection, orientation}) => {
  const currentOptions = (profile && profile[collection]) || [];
  const [selectedOptions, setSelectedOptions] = useState(currentOptions);
  const [options] = useState(SETTINGS_OPTIONS[collection]);

  const onCheckBoxClick = async id => {
    let newOptionStates = selectedOptions;
    options.map(purpose => {
      if (purpose.value === id) {
        if (newOptionStates.includes(purpose.value)) {
          newOptionStates = newOptionStates.filter(
            option => option !== purpose.value,
          );
        } else {
          newOptionStates.push(purpose.value);
        }
      }
      return true;
    });
    setSelectedOptions(newOptionStates);
    await FirebaseDAO.updateFBDoc(collection, newOptionStates);
  };

  return (
    <Widget>
      <div style={{paddingTop: 20}}>
        <Row>
          {options.map(option => {
            return (
              <Col
                key={option.value}
                span={orientation === 'horizontal' ? 24 : 8}>
                <IconCheckBox
                  orientation={orientation}
                  id={option.value}
                  label={option.label}
                  icon={option.icon}
                  state={selectedOptions.includes(option.value) ? true : false}
                  onCheckBoxClick={onCheckBoxClick}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    </Widget>
  );
};

SettingsStep.propTypes = {
  profile: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default SettingsStep;
