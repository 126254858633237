import React, {useState} from 'react';
import {Modal, Upload, message} from 'antd';
import {FaPlus} from 'react-icons/fa';
import {v4 as uuid} from 'uuid';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';

const UploadPicture = ({fList, onFileChange}) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState(fList);
  const intlMessages = useFormatMessage();
  const handleCancel = () => setPreviewVisible(false);
  const handlePreview = file => {
    setPreviewImage(file.url || file.thumbUrl);
    setPreviewVisible(true);
  };

  const uploadButton = (
    <div>
      <FaPlus />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const dummyRequest = ({file, onSuccess}) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const beforeUpload = file => {
    //Restrict image format, size, resolution
    const isJPG = file.type === 'image/jpeg';
    const isJPEG = file.type === 'image/jpeg';
    const isGIF = file.type === 'image/gif';
    const isPNG = file.type === 'image/png';

    if (!(isJPG || isJPEG || isGIF || isPNG)) {
      file.invalid = true;
      message.error(intlMessages('goals.type.error'));
    }

    const isLt2M = file.size / 10240 / 10240 < 2;
    if (!isLt2M) {
      file.invalid = true;
      message.error(intlMessages('avatar.size.error'));
    }

    return false;
  };

  const mediaSettings = {
    name: 'file',
    headers: {
      authorization: 'authorization-text',
    },
    onChange: info => {
      let invalidUpload;
      info.fileList.forEach(file => {
        if (file.invalid) {
          invalidUpload = true;
        }
      });

      if (!invalidUpload) {
        let fileList = [...info.fileList];
        fileList = fileList.map(file => {
          if (file.response) {
            file.uri = file.response.url;
          }
          return file;
        });

        setFileList(fileList);
        onFileChange(fileList);
      } else {
        let fileList = [...info.fileList];
        let listWithoutInvalid = [];
        fileList.forEach(file => {
          if (!file.invalid) {
            listWithoutInvalid.push(file);
          }
        });

        setFileList(listWithoutInvalid);
        onFileChange(listWithoutInvalid);
      }
    },
  };

  return (
    <div>
      <Upload
        customRequest={dummyRequest}
        listType="picture-card"
        fileList={fileList.map(item => ({
          ...item,
          uid: uuid(),
          url: item.uri,
        }))}
        beforeUpload={beforeUpload}
        {...mediaSettings}
        onPreview={handlePreview}>
        {fileList.length >= 10 ? null : uploadButton}
      </Upload>
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{width: '100%'}} src={previewImage} />
      </Modal>
    </div>
  );
};

export default UploadPicture;
