import React, {Component} from 'react';
import {connect} from 'react-redux';
import URLSearchParams from 'url-search-params';
import {Redirect, Route, Switch} from 'react-router-dom';
import {ConfigProvider} from 'antd/lib';
import {IntlProvider} from 'react-intl';
import {InjectIntlContext} from '@comparaonline/react-intl-hooks';
import {TEMPORARY_AVATAR} from 'util/constants';
import AppLocale from 'lngProvider';
import MainApp from './MainApp';
import SignIn from '../SignIn';
import SignInAsGuest from '../SignInAsGuest';
import SignOut from '../SignOut';
import SignUp from '../SignUp';
import ForgotPassword from '../ForgotPassword';
import TermsOfService from '../content/TermsOfService';
import PrivacyPolicy from '../content/PrivacyPolicy';
import CookiePolicy from '../content/CookiePolicy';
import {setInitUrl} from 'appRedux/actions/Auth';
import {
  onLayoutTypeChange,
  onNavStyleChange,
  setThemeType,
} from 'appRedux/actions/Setting';
import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_SEMI_DARK,
  THEME_TYPE_DARK,
} from '../../constants/ThemeSetting';
import FirebaseSocial from 'firebase/firebaseSocial';

const RestrictedRoute = ({component: Component, profile, user, ...rest}) => {
  return (
    <Route
      {...rest}
      render={props =>
        user ? (
          <Component {...props} user={user} profile={profile} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: {from: props.location},
            }}
          />
        )
      }
    />
  );
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    const params = new URLSearchParams(this.props.location.search);

    if (params.has('theme')) {
      this.props.setThemeType(params.get('theme'));
    }
    if (params.has('nav-style')) {
      this.props.onNavStyleChange(params.get('nav-style'));
    }
    if (params.has('layout-type')) {
      this.props.onLayoutTypeChange(params.get('layout-type'));
    }
  }

  setThemeType = themeName => {
    let mySetting = this.state.profile;
    if (mySetting && mySetting.siteSettings && mySetting.siteSettings.theme) {
      if (mySetting.siteSettings.theme === 'THEME_TYPE_DARK') {
        document.body.classList.add('dark-theme');
      } else {
        document.body.classList.remove('dark-theme');
      }
    } else {
      if (themeName === THEME_TYPE_DARK) {
        document.body.classList.add('dark-theme');
      } else {
        document.body.classList.remove('dark-theme');
      }
    }
  };

  setLayoutType = layoutType => {
    let mySetting = this.state.profile;
    if (mySetting && mySetting.siteSettings && mySetting.siteSettings.layout) {
      if (mySetting.siteSettings.layout === 'LAYOUT_TYPE_FULL') {
        document.body.classList.remove('boxed-layout');
        document.body.classList.remove('framed-layout');
        document.body.classList.add('full-layout');
      } else if (mySetting.siteSettings.layout === 'LAYOUT_TYPE_BOXED') {
        document.body.classList.remove('full-layout');
        document.body.classList.remove('framed-layout');
        document.body.classList.add('boxed-layout');
      } else if (mySetting.siteSettings.layout === 'LAYOUT_TYPE_FRAMED') {
        document.body.classList.remove('boxed-layout');
        document.body.classList.remove('full-layout');
        document.body.classList.add('framed-layout');
      }
    } else {
      if (layoutType === LAYOUT_TYPE_FULL) {
        document.body.classList.remove('boxed-layout');
        document.body.classList.remove('framed-layout');
        document.body.classList.add('full-layout');
      } else if (layoutType === LAYOUT_TYPE_BOXED) {
        document.body.classList.remove('full-layout');
        document.body.classList.remove('framed-layout');
        document.body.classList.add('boxed-layout');
      } else if (layoutType === LAYOUT_TYPE_FRAMED) {
        document.body.classList.remove('boxed-layout');
        document.body.classList.remove('full-layout');
        document.body.classList.add('framed-layout');
      }
    }
  };

  setNavStyle = navStyle => {
    let mySetting = this.state.profile;
    if (
      mySetting &&
      mySetting.siteSettings &&
      mySetting.siteSettings.navStyle
    ) {
      if (
        mySetting.siteSettings.navStyle === 'NAV_STYLE_DEFAULT_HORIZONTAL' ||
        mySetting.siteSettings.navStyle === 'NAV_STYLE_DARK_HORIZONTAL' ||
        mySetting.siteSettings.navStyle ===
          'NAV_STYLE_INSIDE_HEADER_HORIZONTAL' ||
        mySetting.siteSettings.navStyle === 'NAV_STYLE_ABOVE_HEADER' ||
        mySetting.siteSettings.navStyle === 'NAV_STYLE_BELOW_HEADER'
      ) {
        document.body.classList.add('full-scroll');
        document.body.classList.add('horizontal-layout');
      } else {
        document.body.classList.remove('full-scroll');
        document.body.classList.remove('horizontal-layout');
      }
    } else {
      if (
        navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
        navStyle === NAV_STYLE_DARK_HORIZONTAL ||
        navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
        navStyle === NAV_STYLE_ABOVE_HEADER ||
        navStyle === NAV_STYLE_BELOW_HEADER
      ) {
        document.body.classList.add('full-scroll');
        document.body.classList.add('horizontal-layout');
      } else {
        document.body.classList.remove('full-scroll');
        document.body.classList.remove('horizontal-layout');
      }
    }
  };

  setThemeColor = color => {
    let mySetting = this.state.profile;
    if (mySetting && mySetting.siteSettings && mySetting.siteSettings.color) {
      window.less
        .modifyVars(mySetting.siteSettings.color)
        .then(() => {})
        .catch(error => {
          console.log(`Failed to update theme`);
        });
    } else {
      window.less
        .modifyVars({
          '@primary-color': '#038fde',
          '@secondary-color': '#fa8c16',
          '@text-color': '#545454',
          '@heading-color': '#535353',
          '@nav-dark-bg': '#003366',
          '@nav-dark-text-color': '#038fdd',
          '@header-text-color': '#262626',
          '@layout-header-background': '#fefefe',
          '@layout-footer-background': '#fffffd',
          '@body-background': '#ffffff',
          '@hor-nav-text-color': '#fffffd',
        })
        .then(() => {})
        .catch(error => {
          console.log(`Failed to update theme`);
        });
    }
  };

  componentDidMount() {
    FirebaseSocial.auth.onAuthStateChanged(async user => {
      if (user) {
        if (user.isAnonymous) {
          let profile = {
            guest: true,
            id: user.uid,
            avatar: TEMPORARY_AVATAR,
            fullName: 'Guest User',
            username: 'Guest User',
            email: 'Guest',
          };
          this.setState({user, profile});
        } else {
          FirebaseSocial.getUserDocumentData(user.uid).onSnapshot(doc => {
            let profile = doc.data();
            this.setState({user, profile});
          });
        }
      }
    });
  }

  render() {
    const {match, layoutType, navStyle, locale} = this.props;
    this.setLayoutType(layoutType);
    this.setNavStyle(navStyle);
    this.setThemeType(THEME_TYPE_SEMI_DARK);
    this.setThemeColor('default');
    const currentAppLocale = AppLocale[locale.locale];
    return (
      <ConfigProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
          key={currentAppLocale.locale}
          defaultLocale="en-us">
          <InjectIntlContext>
            <Switch>
              <Redirect exact from="/" to="/signin" />
              <Route exact path="/signin" component={SignIn} />
              <Route exact path="/signinguest" component={SignInAsGuest} />
              <Route exact path="/signup" component={SignUp} />
              <Route exact path="/signout" component={SignOut} />
              <Route exact path="/forgotpassword" component={ForgotPassword} />
              <Route exact path="/terms" component={TermsOfService} />
              <Route exact path="/cookies" component={CookiePolicy} />
              <Route exact path="/privacy" component={PrivacyPolicy} />

              {/* {!this.state.user && <Route component={SignIn} />} */}

              {this.state.user && this.state.profile && (
                <RestrictedRoute
                  path={`${match.url}`}
                  user={this.state.user}
                  profile={this.state.profile}
                  component={MainApp}
                />
              )}
            </Switch>
          </InjectIntlContext>
        </IntlProvider>
      </ConfigProvider>
    );
  }
}

const mapStateToProps = ({settings, auth}) => {
  const {locale, navStyle, layoutType} = settings;
  const {authUser, initURL} = auth;
  return {locale, navStyle, layoutType, authUser, initURL};
};

export default connect(mapStateToProps, {
  setInitUrl,
  setThemeType,
  onNavStyleChange,
  onLayoutTypeChange,
})(App);
