import app from 'firebase/app';
import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import config from './firebaseConfig';
import {TEMPORARY_GROUP_COVER} from 'util/constants';

class FirebaseGroups {
  constructor() {
    if (!firebase.apps.length) {
      app.initializeApp(config);
    }
    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
  }

  // Get All creted Groups of User
  getUserGroups(userId, keyword) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let groupRef = this.db
      .collection('groups')
      .doc(`${userId}`)
      .collection('userGroups');
    if (keyword) {
      return groupRef
        .where('groupName', '>=', keyword)
        .where('groupName', '<=', keyword + '\uf8ff')
        .orderBy('groupName', 'asc');
    } else {
      return groupRef;
    }
  }
  // Get Group Detail
  getGroupDetail(userId, groupId) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    return this.db
      .collection('groups')
      .doc(`${userId}`)
      .collection('userGroups')
      .doc(`${groupId}`);
  }
  // Create Group
  async createNewGroup(input) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }

    let date = new Date().getTime();
    let groupCoverPhoto = {name: '', downloadURL: TEMPORARY_GROUP_COVER};
    if (input.groupCoverPhoto.length > 0) {
      groupCoverPhoto = await this.uploadfile(input.groupCoverPhoto, date);
    }
    let userId = this.auth.currentUser.uid;
    let memberRef = this.db.collection('groupMembers');
    let groupId = '';
    await this.db
      .collection('groups')
      .doc(`${userId}`)
      .collection('userGroups')
      .add({
        userId: userId,
        groupCoverPhoto: groupCoverPhoto,
        groupName: input.groupName,
        groupDescription: input.groupDescription,
        groupCategory: '',
        groupTags: [],
        groupPrivacy: input.groupPrivacy,
        groupSearchable: input.groupSearchable,
        createdOn: new Date().toString(),
        status: 1,
        membersCount: 1,
        groupPostsCount: 0,
      })
      .then(function(docRef) {
        groupId = docRef.id;
        // Join Group to Creator
        memberRef
          .doc(`${groupId}`)
          .collection('members')
          .add({
            groupId: groupId,
            memberId: userId,
            groupAdminId: userId,
            memberType: 1, // 1 admin, 0 member
            status: 1,
            date: new Date().toString(),
          });
      });

    // Invite Members
    if (groupId && input.groupMembers.length > 0) {
      return this.addGroupMembers(groupId, userId, input.groupMembers);
    }
  }
  // Add group member for accept invites
  async addGroupMembers(groupId, groupAdminId, memberArray) {
    let memberRef = this.db
      .collection('groupMembers')
      .doc(`${groupId}`)
      .collection('members');
    await memberArray.forEach(member => {
      if (member.value !== member.label) {
        memberRef.add({
          groupId: groupId,
          memberId: member.value,
          groupAdminId: groupAdminId,
          memberType: 0,
          status: 1, // 0 blocked, 1 active member, 2 requested for membership
          date: new Date().toString(),
        });
        // Increment Member count
        this.db
          .collection('groups')
          .doc(`${groupAdminId}`)
          .collection('userGroups')
          .doc(groupId)
          .update({
            membersCount: firebase.firestore.FieldValue.increment(1),
          });
      } else {
        this.inviteGroupMembers(groupId, [member], groupAdminId);
      }
    });
    return true;
  }
  // Add outside users invites
  async inviteGroupMembers(groupId, invitesArray, groupAdminId) {
    // ToDo => Email invitation will sent to external user those are not members of Loanpalz

    let invitedRef = this.db
      .collection('groupMembersInvited')
      .doc(`${groupId}`)
      .collection('invited');
    await invitesArray.forEach(member => {
      invitedRef.add({
        groupId: groupId,
        invitedEmail: member.value,
        groupAdminId: groupAdminId,
        status: 1, // 1 invites sent
        date: new Date().toString(),
      });
    });
    return true;
  }
  // Upload group coverphoto
  async uploadfile(fileArray, name) {
    let upload = fileArray[0].originFileObj;
    let img = this.storage
      .ref()
      .child(`groups/${this.auth.currentUser.uid}/cover/${name}`);
    await img.put(upload).then(() => {
      img.updateMetadata({
        customMetadata: {name: name, uid: this.auth.currentUser.uid},
      });
    });
    let downloadURL = await img.getDownloadURL();

    return {name: name, downloadURL: downloadURL};
  }
  // Update group information
  async updateGroupInformation(input) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }

    let date = new Date().getTime();
    let groupCoverPhoto = input.oldGroupCoverPhoto;

    if (input.groupCoverPhoto.length > 0) {
      groupCoverPhoto = await this.uploadfile(input.groupCoverPhoto, date);
    }

    if (input.userId !== this.auth.currentUser.uid) {
      return alert('Unauthorized');
    } else {
      return this.db
        .collection('groups')
        .doc(`${input.userId}`)
        .collection('userGroups')
        .doc(input.id)
        .update({
          groupName: input.groupName,
          groupDescription: input.groupDescription,
          groupPrivacy: input.groupPrivacy,
          groupSearchable: input.groupSearchable,
          modifiedOn: new Date().toString(),
          groupCoverPhoto: groupCoverPhoto,
        });
    }
  }
  // Detete Group
  async deleteGroup(input) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }

    if (input.userId !== this.auth.currentUser.uid) {
      return alert('Unauthorized');
    } else {
      let batch = this.db.batch();
      // Delete Group
      let deleteGroup = this.db
        .collection('groups')
        .doc(`${input.userId}`)
        .collection('userGroups')
        .doc(`${input.id}`);
      batch.delete(deleteGroup);
      //Delete Memebers
      let membersRef = this.db
        .collection('groupMembers')
        .doc(input.id)
        .collection('members');
      await membersRef.get().then(snapshot => {
        snapshot.forEach(doc => {
          let toDelete = membersRef.doc(doc.id);
          batch.delete(toDelete);
        });
      });
      //Delete Invites
      let membersInviteRef = this.db
        .collection('groupMembersInvited')
        .doc(input.id)
        .collection('invited');
      await membersInviteRef.get().then(snapshot => {
        snapshot.forEach(doc => {
          let toDelete = membersInviteRef.doc(doc.id);
          batch.delete(toDelete);
        });
      });
      batch.commit();
    }
  }
  // Get groups active members
  getGroupActiveMembers(groupId) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    return this.db
      .collection('groupMembers')
      .doc(groupId)
      .collection('members')
      .where('status', '==', 1);
  }
  // Get groups Join Requests
  getGroupJoinRequests(groupId) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    return this.db
      .collection('groupMembers')
      .doc(groupId)
      .collection('members')
      .where('status', '==', 2);
  }
  // Get groups invited outside users
  getGroupOutSideInvitedUsers(groupId) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    return this.db
      .collection('groupMembersInvited')
      .doc(groupId)
      .collection('invited');
  }
  // Get user group members status
  getuserGroupMemberStatus(userId, groupId) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    return this.db
      .collection('groupMembers')
      .doc(groupId)
      .collection('members')
      .where('memberId', '==', userId);
  }
  // Get group post list
  getGroupPostList(groupId) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    return this.db
      .collection('groupPosts')
      .doc(groupId)
      .collection('userPosts')
      .orderBy('date', 'desc');
  }

  // Approve or Reject group join request
  approveRejectJoinRequest(requestData, action, group) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let batch = this.db.batch();
    if (action === 1) {
      // Approve Request
      let approveRef = this.db
        .collection('groupMembers')
        .doc(requestData.groupId)
        .collection('members')
        .doc(requestData.id);
      batch.update(approveRef, {status: 1});
      // Increment Member count
      let updateRef = this.db
        .collection('groups')
        .doc(`${group.userId}`)
        .collection('userGroups')
        .doc(group.id);
      batch.update(updateRef, {
        membersCount: firebase.firestore.FieldValue.increment(1),
      });
    } else {
      // Delete member
      let deleteMember = this.db
        .collection('groupMembers')
        .doc(requestData.groupId)
        .collection('members')
        .doc(requestData.id);
      batch.delete(deleteMember);
    }
    batch.commit();
  }
  // Remove Group Member
  removeGroupMember(input, group) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let batch = this.db.batch();
    let deleteMember = this.db
      .collection('groupMembers')
      .doc(input.groupId)
      .collection('members')
      .doc(input.id);
    batch.delete(deleteMember);

    if (input.status === 1) {
      // Decrement Members count
      let updateRef = this.db
        .collection('groups')
        .doc(`${group.userId}`)
        .collection('userGroups')
        .doc(group.id);
      batch.update(updateRef, {
        membersCount: firebase.firestore.FieldValue.increment(-1),
      });
    }
    batch.commit();
  }
  // Send Group join request
  sendGroupJoinRequest(group, userid) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    return this.db
      .collection('groupMembers')
      .doc(`${group.id}`)
      .collection('members')
      .add({
        groupId: group.id,
        memberId: userid,
        groupAdminId: group.userId,
        memberType: 0,
        status: 2, // 0 blocked, 1 active member, 2 requested for membership
        date: new Date().toString(),
      });
  }

  //Search Group
  async searchGroups(userId, keyword) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let groups = [];
    let searchRef = this.db.collectionGroup('userGroups');
    // This part is working only on case senstive keyword
    // .where('groupName', '>=', keyword)
    // .where('groupName', '<=', keyword + '\uf8ff')
    // .orderBy('groupName', 'asc');
    await searchRef
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          let dataObj = {
            ...doc.data(),
            id: doc.id,
          };
          if (
            dataObj.groupName.toLowerCase().indexOf(keyword.toLowerCase()) !==
              -1 &&
            dataObj.groupSearchable === 1 &&
            dataObj.userId !== userId &&
            dataObj.status === 1
          ) {
            groups.push(dataObj);
          }
        });
      })
      .catch(err => {
        console.log('Error getting Groups', err);
      });
    return groups;
  }
  // Get user Joined Groups
  getUserJoinedGroups(userId) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }

    return this.db
      .collectionGroup('members')
      .where('memberId', '==', userId)
      .where('memberType', '==', 0)
      .orderBy('status', 'asc');
  }
}

export default new FirebaseGroups();
