import React, { useRef } from 'react';
import { Modal } from 'antd';

import IntlMessages from 'util/IntlMessages';
import AccountForm from './AccountForm';

const AccountModal = ({ showModal, showHideModal, account }) => {
    const childRef = useRef();

    const handleResetFormModal = () => {
        showHideModal(false);
    };

    let mode = account ? 'edit' : 'add';

    return (
        <>
            <Modal
                title={
                    mode === 'add' ? (
                        <IntlMessages id="wizard.financials.account.addBtn" />
                    ) : (
                            <IntlMessages id="wizard.financials.account.updateBtn" />
                        )
                }
                visible={showModal}
                closable={true}
                onOk={() => childRef.current.submitForm()}
                onCancel={handleResetFormModal}>
                <AccountForm
                    ref={childRef}
                    account={account}
                    onClose={handleResetFormModal}
                    mode={mode}
                />
            </Modal>
        </>
    );
};

export default AccountModal;
