import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Alert
} from 'antd';

import { FirebaseDAO } from 'firebase/firebase';
import moment from 'moment';
import { LoanTermCalculator } from 'loanpalz-constants';

const dayFormat = 'D-MMM-YYYY';

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const LoanForm = forwardRef(({ loan, mode, onClose, showModal }, ref) => {
  const [visible, setVisible] = useState(!showModal);
  const [form] = Form.useForm();
  const intlMessages = useFormatMessage();
  const endCollection = 'loans';

  useImperativeHandle(ref, () => ({
    submitForm() {
      form.submit();
    },
  }));

  const onSubmit = async values => {
    if (!values.term && !values.minimumPayment) {
      setVisible(true);
      form.setFieldsValue({
        ...values
      });

    } else {
      let term = null;
      let _err = false;

      if (values.minimumPayment > 0) {
        term = new LoanTermCalculator(values.interest, values.currentBalance, values.minimumPayment).calculateLoanTerms();
        if (!term) {
          _err = true;
        }
      }
      if (_err) {
        setVisible(true);
        form.setFieldsValue({
          ...values
        });
      } else {
        if (term && term >= values.term) {
          values.term = 0;
        } else {
          values.minimumPayment = 0;
        }

        setVisible(false);
        if (onClose) {
          onClose();
        }

        if (mode === 'add') {
          values = {
            ...values,
            term: values.term ? values.term : 0,
            minimumPayment: values.minimumPayment ? values.minimumPayment : 0,
            startDate: Math.floor(new Date(values.startDate.format(dayFormat)).getTime() / 1000),
          };
          await FirebaseDAO.addFBData(values, endCollection);
          message.success(intlMessages('wizard.financials.loan.add.success'));
        } else if (mode === 'edit') {
          let id = loan.id;
          values = {
            ...values,
            term: values.term ? values.term : 0,
            minimumPayment: values.minimumPayment ? values.minimumPayment : 0,
            startDate: Math.floor(new Date(values.startDate.format(dayFormat)).getTime() / 1000),
            id,
          };
          await FirebaseDAO.updateFBData(values, endCollection);
          message.success(intlMessages('wizard.financials.loan.update.success'));
        }
      }
    }
  };

  let startDate = moment(new Date(), dayFormat);
  if (loan && loan.startDate) {
    startDate = moment(new Date(loan.startDate * 1000), dayFormat);
  }

  const setInitialValues = () => {

    form.resetFields();
    if (loan) {
      form.setFieldsValue({
        ...loan,
        startDate
      });
    }
  };


  setInitialValues();

  return (
    <>
      {visible ? (
        <div class="gx-pb-4">
          <Alert message="Please provide valid numerical value either for loan term or monthly payment." type="error" />
        </div>
      ) : null}

      <Form {...formItemLayout} form={form} onFinish={onSubmit}>
        <div className="gx-modal-box-row">
          <div className="gx-modal-box-form-item">
            <div className="gx-form-group">
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    type: 'string',
                    message: 'Please enter a loan name',
                  },
                ]}>
                <Input required placeholder="Loan name" />
              </Form.Item>
            </div>
            <div className="gx-form-group">
              <Form.Item
                label="Type"
                name="type"
                rules={[
                  {
                    required: true,
                    type: 'string',
                    message: 'Please select a loan type',
                  },
                ]}>
                <Select
                  style={{ width: '100%' }}
                  onChange={() => { }}
                  placeholder="Select a loan type">
                  <Option value="creditcard">Credit Card</Option>
                  <Option value="studentloan">Student Loan</Option>
                  <Option value="mortgage">Mortgage</Option>
                  <Option value="autoloan">Auto Loan</Option>
                  <Option value="personalloan">Personal Loan</Option>
                </Select>
              </Form.Item>
            </div>
            <div className="gx-form-group">
              <Form.Item
                label="Lender"
                name="lenderName"
                rules={[
                  {
                    required: true,
                    type: 'string',
                    message: 'Please enter a loan name',
                  },
                ]}>
                <Input required placeholder="Lender name" />
              </Form.Item>
            </div>
            <div className="gx-form-group">
              <Form.Item
                label="Start date"
                name="startDate"
                rules={[{ required: true, message: 'Please select a date' }]}>
                <DatePicker style={{ width: '100%' }} placeholder="Loan start date" margin="normal" />
              </Form.Item>
            </div>
            <div className="gx-form-group">
              <Form.Item
                inline
                name="term"
                label="Loan term (months)"
                rules={[
                  {
                    required: false,
                    type: 'integer',
                    message: 'Please enter a valid number',
                  },
                ]}>
                <InputNumber
                  onChange={() => { }}
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  placeholder="Loan term in months"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </div>

            <div className="text-left">
              <p style={{ color: "#808080c9", marginLeft: "50px", fontSize: "14px" }}> or </p>
            </div>

            <div className="gx-form-group">
              <Form.Item
                inline
                name="minimumPayment"
                label="Minimum payment"
                rules={[
                  {
                    required: false,
                    type: 'integer',
                    message: 'Please enter a valid number',
                  },
                ]}
              >
                <InputNumber
                  onChange={() => { }}
                  formatter={value =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  placeholder="Minimum payment"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </div>
            <div className="gx-form-group">
              <Form.Item
                inline
                name="originalLoanAmount"
                label="Original amount"
                rules={[
                  {
                    required: true,
                    type: 'integer',
                    message: 'Please enter a valid number',
                  },
                ]}>
                <InputNumber
                  onChange={() => { }}
                  formatter={value =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  placeholder="Original Loan amount"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </div>
            <div className="gx-form-group">
              <Form.Item
                inline
                name="currentBalance"
                label="Current balance"
                rules={[
                  {
                    required: true,
                    type: 'integer',
                    message: 'Please enter a valid number',
                  },
                ]}>
                <InputNumber
                  onChange={() => { }}
                  formatter={value =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  placeholder="Current Balance amount"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </div>
            <div className="gx-form-group">
              <Form.Item
                inline
                name="interest"
                label="Interest rate"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a valid number',
                  },
                ]}>
                <InputNumber
                  onChange={() => { }}
                  defaultValue={6}
                  min={0}
                  max={100}
                  formatter={value => `${value}%`}
                  parser={value => value.replace('%', '')}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </div>
            <Button htmlType="submit" style={{ display: 'none' }}></Button>
          </div>
        </div>
      </Form>
    </>
  );
});

export default LoanForm;
