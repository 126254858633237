import moment from 'moment';
import numeral from 'numeral';

const MONTHS_IN_THREE_YEARS = 1095;
const monthDiff = (d1, d2) => {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth() + 1;
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
};

const dayFormat = 'D-MMM-YYYY';

const calculateMonthly = ({
  goalAmount,
  goalPlannedDate,
  goalAmountNeeded,
  goalMonthlyAmount,
  goalAccountBalance,
  setGoalMonthlyAmount,
  isCustomMonthlyContrib,
  setMinimumMonthlyAmount,
  setProjectedDateMessage,
}) => {
  if (!goalAmountNeeded || !goalPlannedDate) return;
  const months = monthDiff(new Date(), new Date(goalPlannedDate));

  let newMonthlyAmount = goalAmountNeeded;

  if (parseInt(months, 10) > 0)
    newMonthlyAmount = goalAmountNeeded / parseInt(months, 10);

  setMinimumMonthlyAmount(newMonthlyAmount);

  if (typeof newMonthlyAmount === 'number' && !isCustomMonthlyContrib) {
    setGoalMonthlyAmount(parseInt(newMonthlyAmount, 10).toFixed(2));
  }

  let calculatedMonths = `by`;

  if (isCustomMonthlyContrib) {
    setGoalMonthlyAmount(parseInt(goalMonthlyAmount, 10).toFixed(2));
  }

  let monthlyTotalAmount = 0;
  let monthCount = 0;
  let gotTooMuchTime = false;
  for (monthCount; goalAmountNeeded > monthlyTotalAmount; monthCount++) {
    monthlyTotalAmount += parseInt(goalMonthlyAmount, 10);
    if (monthCount >= MONTHS_IN_THREE_YEARS) {
      calculatedMonths = `more than 3 years before/after `;
      gotTooMuchTime = true;
      break;
    }
  }
  monthCount = monthCount - 1;
  if (!gotTooMuchTime && monthCount < months) {
    const diffMonths = months - monthCount;
    calculatedMonths = ` ${diffMonths} ${
      diffMonths > 1 ? 'months' : 'month'
      } before`;
  } else if (!gotTooMuchTime && monthCount > months) {
    const diffMonths = monthCount - months;
    calculatedMonths = ` ${diffMonths} ${
      diffMonths > 1 ? 'months' : 'month'
      } after`;
  }

  const isMonthlyValid =
    goalMonthlyAmount &&
    typeof goalMonthlyAmount === 'string' &&
    !isNaN(goalMonthlyAmount);
  if (goalAmount < goalAccountBalance || parseInt(goalMonthlyAmount, 10) < 0) {
    setProjectedDateMessage(
      `Nice! You have reached your goal before your planned date of ${moment(
        goalPlannedDate,
      ).format(dayFormat)}`,
    );
  } else if (isMonthlyValid) {
    setProjectedDateMessage(
      `Nice! By saving ${numeral(parseFloat(goalMonthlyAmount)).format(
        '$0,0',
      )} per month, we project that you will reach your goal ${calculatedMonths} your planned date of ${moment(
        goalPlannedDate,
      ).format(dayFormat)}`,
    );
  }
};

export { calculateMonthly };
