import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { Button, message, Modal, Spin, Table } from 'antd';
import Widget from 'components/Widget/index';
import { FirebaseDAO } from 'firebase/firebase';
import numeral from 'numeral';
import React, { useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import {
  FaCar,
  FaCreditCard,
  FaGraduationCap,
  FaHome,
  FaMoneyBill,
  FaSpinner,
  FaUserTie,
} from 'react-icons/fa';
import IntlMessages from 'util/IntlMessages';
import './checklist.less';
import LoanModal from 'components/app/loan/LoanModal';

const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;
const { confirm } = Modal;

const LoanStep = props => {
  const isMobile = window.innerWidth <= 500;

  let columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 40,
      render: (text, record) => {
        let iconContent = null;
        switch (text) {
          case 'creditcard':
            iconContent = <FaCreditCard size={24} />;
            break;
          case 'mortgage':
            iconContent = <FaHome size={24} />;
            break;
          case 'studentloan':
            iconContent = <FaGraduationCap size={24} />;
            break;
          case 'autoloan':
            iconContent = <FaCar size={24} />;
            break;
          case 'personalloan':
            iconContent = <FaUserTie size={24} />;
            break;
          default:
            iconContent = <FaMoneyBill size={24} />;
            break;
        }
        return (
          <span
            className="linktext"
            onClick={e => {
              e.preventDefault();
              onEditLoan(record);
            }}>
            <div className="gx-flex-row gx-align-items-center">
              {iconContent}
            </div>
          </span>
        );
      },
    },
    {
      title: 'Loan name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return (
          <span
            className="linktext"
            onClick={e => {
              e.preventDefault();
              onEditLoan(record);
            }}>
            {text}
          </span>
        );
      },
    },
    {
      title: 'Original Loan Amount',
      dataIndex: 'originalLoanAmount',
      key: 'originalLoanAmount',
      render: (text, record) => {
        return (
          <span
            onClick={e => {
              e.preventDefault();
              onEditLoan(record);
            }}>
            {numeral(text).format('$0,0.00')}
          </span>
        );
      },
    },
    {
      title: 'Current balance',
      dataIndex: 'currentBalance',
      key: 'currentBalance',
      render: (text, record) => {
        return (
          <span
            onClick={e => {
              e.preventDefault();
              onEditLoan(record);
            }}>
            {numeral(text).format('$0,0.00')}
          </span>
        );
      },
    },
    {
      title: 'Interest rate',
      dataIndex: 'interest',
      key: 'interest',
      render: (text, record) => {
        return (
          <span
            onClick={e => {
              e.preventDefault();
              onEditLoan(record);
            }}>
            {text}%
          </span>
        );
      },
    },
    {
      title: 'Term (Months)',
      dataIndex: 'term',
      key: 'term',
      render: (text, record) => {
        return (
          <span
            onClick={e => {
              e.preventDefault();
              onEditLoan(record);
            }}>
            {text}
          </span>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: values => {
        return (
          <span>
            <i
              className="icon icon-edit gx-pointer gx-text-primary gx-fs-lg gx-d-inline-flex gx-vertical-align-middle gx-mr-0"
              onClick={() => onEditLoan(values)}
            />
            &emsp;
            <i
              className="icon icon-trash gx-pointer gx-text-danger gx-fs-lg gx-d-inline-flex gx-vertical-align-middle gx-mr-1"
              onClick={() => onDeleteCurrentLoan(values)}
            />
          </span>
        );
      },
    },
  ];

  // For Mobile Screen
  if (isMobile) {
    let removeIndex = [0, 3, 4];
    columns = columns.filter(function (value, index) {
      return removeIndex.indexOf(index) === -1;
    });
  }

  const [showModal, setShowModal] = useState(false);
  const [editLoan, setEditloan] = useState(null);
  const intlMessages = useFormatMessage();
  const endCollection = 'loans';
  const [loans, loading, error] = useCollectionData(
    FirebaseDAO.getFBCollectionData(endCollection),
    {
      idField: 'id',
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );

  const showHideModal = value => {
    setShowModal(value);
    if (value === false) {
      setEditloan(null);
    }
  };

  const onDeleteCurrentLoan = loan => {
    confirm({
      title: 'Are you sure you want to delete this loan?',
      onOk: () => {
        deleteCurrentLoan(loan);
      },
      onCancel() { },
    });
  };

  const onEditLoan = loan => {
    setShowModal(true);
    setEditloan(loan);
  };

  const onAddLoan = () => {
    setShowModal(true);
    setEditloan(null);
  };

  const deleteCurrentLoan = async loan => {
    await FirebaseDAO.deleteMultipleFBData([loan.id], endCollection);
    message.success(intlMessages('wizard.financials.loan.delete.success'));
  };

  return (
    <div>
      <Widget
        title={
          <h2 className="h4 gx-text-capitalize gx-mb-0 gx-text-left gx-ml-1">
            Loans
          </h2>
        }
        extra={[
          <p
            className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block gx-mr-1"
            onClick={() => onAddLoan()}>
            <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle gx-mr-1" />
            <IntlMessages id="loan.add" />
          </p>,
          //  For Mobile Screen
          <span
            onClick={() => onAddLoan()}
            className="gx-text-primary gx-fs-md gx-pointer gx-mb-4 gx-d-block gx-d-sm-none gx-mr-1">
            <i className="icon icon-add-circle gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle" />
            <IntlMessages id="loan.add" />
          </span>,
        ]}>
        {error && <strong>Error: {JSON.stringify(error)}</strong>}
        {loading && <Spin indicator={spinnerIcon} />}
        {loans && (
          <Table
            className="gx-table-no-bordered"
            rowClassName="loan-table-row"
            columns={columns}
            dataSource={loans}
            pagination={false}
            size="small"
            rowKey="id"
            locale={{
              emptyText: (
                <div className="gx-mt-3">
                  <h2>You don't have any loans yet. Let's add one!</h2>
                  <Button
                    variant="raised"
                    className="gx-mt-3"
                    type="primary"
                    onClick={() => setShowModal(true)}>
                    <div className="ant-row-flex">
                      <IntlMessages id="wizard.financials.loan.addBtn" />
                    </div>
                  </Button>
                </div>
              ),
            }}
          />
        )}
      </Widget>

      <LoanModal
        showModal={showModal}
        showHideModal={showHideModal}
        loan={editLoan}
      />
    </div>
  );
};

export default LoanStep;
