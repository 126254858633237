import app from 'firebase/app';
import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import config from './firebaseConfig';
import uuid from 'uuid';

function isIterable(obj) {
  // checks for null and undefined
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === 'function';
}

class FirebaseForums {
  constructor() {
    if (!firebase.apps.length) {
      app.initializeApp(config);
    }
    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
  }

  addThread(threadText, threadTitle, fileArray) {
    if (!fileArray) {
      fileArray = [];
    }

    let thread = {
      text: threadText,
      title: threadTitle,
      author: this.auth.currentUser.uid,
      date: new Date().toString(),
      upvotes: [],
      downvotes: [],
      mediaArray: fileArray,
      comments: [],
    };

    let userRef = this.db.collection('forums').doc();

    userRef.set({ ...thread, id: userRef.id });
  }

  async updateThread(threadText, threadTitle, fileArray, threadId) {
    let userRef = this.db.collection('forums').doc(threadId);

    userRef.update({
      text: threadText,
      title: threadTitle,
      date: new Date().toString(),
      mediaArray: fileArray,
    });
  }

  async deleteThread(threadId) {
    let img = this.storage.ref();
    let storageLocation = `forums/${this.auth.currentUser.uid}`;
    let postRef = await this.db.collection('forums').doc(threadId);

    postRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          let docData = doc.data();

          if (docData.mediaArray && docData.mediaArray.length > 0) {
            docData.mediaArray.forEach(upload => {
              img.child(storageLocation + `${upload.name}`).delete();
            });
          }
          postRef.delete();
        } else {
          console.log('No such document!');
        }
      })
      .catch(function (error) {
        console.log('Error getting document:', error);
      });
  }

  async loopMedia(fileInfoList) {
    let fileNum;
    let mediaArray = [];
    let storageLocation = `forums/${this.auth.currentUser.uid}`;

    for (fileNum = 0; fileNum < fileInfoList.length; fileNum++) {
      let upload = fileInfoList[fileNum].originFileObj;
      let fileName = new Date().getTime();
      let img = this.storage.ref().child(storageLocation + fileName);
      await img.put(upload).then(() => {
        img.updateMetadata({
          customMetadata: {
            name: fileName,
            uid: this.auth.currentUser.uid,
          },
        });
      });
      let downloadURL = await img.getDownloadURL();
      mediaArray.push({ name: fileName, uri: downloadURL });
    }
    return mediaArray;
  }

  async onMediaUpload(fileInfoList) {
    let mediaArray = [];

    if (fileInfoList.length > 0) {
      mediaArray = await this.loopMedia(fileInfoList);
    }
    return mediaArray;
  }

  getUserForums(userId) {
    return this.db.collection('forums').where('author', '==', userId);
  }

  getThreadList() {
    return this.db.collection('forums');
  }

  getThreadOnRedirect(threadId) {
    return this.db.collection('forums').doc(threadId);
  }

  async removePictures(threadId, removeList) {
    let threadInfo = null;
    let postRef = this.db.collection('forums').doc(threadId);
    await postRef.get().then(doc => (threadInfo = doc.data()));

    let updatedMediaArray = [];
    threadInfo.mediaArray.forEach(mediaFile => {
      let retainFile = true;
      removeList.forEach(name => {
        if (mediaFile.name === name) {
          retainFile = false;
        }
      });
      if (retainFile) {
        updatedMediaArray.push(mediaFile);
      }
    });

    postRef.update({ mediaArray: updatedMediaArray });

    let storageLocation = `forums/${this.auth.currentUser.uid}`;
    removeList.forEach(fileName => {
      this.storage
        .ref()
        .child(storageLocation + fileName)
        .delete();
    });
  }

  async upvoteThread(threadId) {
    let newUpArray = [];
    let threadInfo = null;

    let postRef = this.db.collection('forums').doc(threadId);
    await postRef.get().then(doc => (threadInfo = doc.data()));

    newUpArray = threadInfo.upvotes;

    newUpArray.push(this.auth.currentUser.uid);

    postRef.update({ upvotes: newUpArray });
  }

  async downvoteThread(threadId) {
    let newDownArray = [];
    let threadInfo = null;

    let postRef = this.db.collection('forums').doc(threadId);
    await postRef.get().then(doc => (threadInfo = doc.data()));

    newDownArray = threadInfo.downvotes;

    newDownArray.push(this.auth.currentUser.uid);

    postRef.update({ downvotes: newDownArray });
  }

  async removeVote(threadId, userId) {
    let threadInfo = null;

    let postRef = this.db.collection('forums').doc(threadId);
    await postRef.get().then(doc => (threadInfo = doc.data()));

    let threadUpvotes = [...threadInfo.upvotes];
    let threadDownvotes = [...threadInfo.downvotes];

    let reducedUpvotes = [];
    let reducedDownvotes = [];

    threadUpvotes.forEach(voter => {
      if (voter !== userId) {
        reducedUpvotes.push(voter);
      }
    });

    threadDownvotes.forEach(voter => {
      if (voter !== userId) {
        reducedDownvotes.push(voter);
      }
    });

    threadInfo.upvotes = reducedUpvotes;
    threadInfo.downvotes = reducedDownvotes;

    postRef.update(threadInfo);
  }

  async commentThread(threadId, commentData) {
    let result = null;
    let update = [];

    let userRef = this.db.collection('forums').doc(threadId);

    await userRef.get().then(doc => (result = doc.data()));

    if (result && result.comments) {
      if (isIterable(result.comments)) {
        update = [...result.comments];
      }
    }

    let guid = uuid.v4();
    update.push({ ...commentData, upvotes: [], downvotes: [], id: guid });
    userRef.update({ comments: update });
  }

  async upvoteComment(threadId, commentId) {
    let result = null;
    let commentArray = [];

    let postRef = this.db.collection('forums').doc(threadId);

    await postRef.get().then(doc => (result = doc.data()));

    if (result && result.comments) {
      if (isIterable(result.comments)) {
        commentArray = [...result.comments];
      }
    }

    for (let i = 0; i < commentArray.length; i++) {
      let commentUpvotes = [];
      if (commentArray[i].id === commentId) {
        commentUpvotes = [...commentArray[i].upvotes];
        commentUpvotes.push(this.auth.currentUser.uid);
        commentArray[i].upvotes = commentUpvotes;
      }
    }

    postRef.update({ comments: commentArray });
  }

  async downvoteComment(threadId, commentId) {
    let result = null;
    let commentArray = [];

    let postRef = this.db.collection('forums').doc(threadId);

    await postRef.get().then(doc => (result = doc.data()));

    if (result && result.comments) {
      if (isIterable(result.comments)) {
        commentArray = [...result.comments];
      }
    }

    for (let i = 0; i < commentArray.length; i++) {
      let commentDownvotes = [];
      if (commentArray[i].id === commentId) {
        commentDownvotes = [...commentArray[i].downvotes];
        commentDownvotes.push(this.auth.currentUser.uid);
        commentArray[i].downvotes = commentDownvotes;
      }
    }

    postRef.update({ comments: commentArray });
  }

  async removeCommentVote(threadId, commentId, userId) {
    let result = null;
    let commentArray = [];

    let postRef = this.db.collection('forums').doc(threadId);

    await postRef.get().then(doc => (result = doc.data()));

    if (result && result.comments) {
      if (isIterable(result.comments)) {
        commentArray = [...result.comments];
      }
    }

    for (let i = 0; i < commentArray.length; i++) {
      let commentUpvotes = [];
      let commentDownvotes = [];

      let reducedUpvotes = [];
      let reducedDownvotes = [];

      if (commentArray[i].id === commentId) {
        commentUpvotes = [...commentArray[i].upvotes];
        commentDownvotes = [...commentArray[i].downvotes];

        commentUpvotes.forEach(voter => {
          if (voter !== userId) {
            reducedUpvotes.push(voter);
          }
        });

        commentDownvotes.forEach(voter => {
          if (voter !== userId) {
            reducedDownvotes.push(voter);
          }
        });
      }
      commentArray[i].upvotes = reducedUpvotes;
      commentArray[i].downvotes = reducedDownvotes;
    }
    postRef.update({ comments: commentArray });
  }
}

export default new FirebaseForums();
