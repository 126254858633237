import React from 'react';
import {Radio, Input, Col} from 'antd/lib';
import {Link} from 'react-router-dom';

const ListDisplayStyle = ({
  onUpcomingSearch,
  onPastSearch,
  onCoachSearch,
  bookingView,
  onChangeDisplay,
  recent,
  coachesView,
  onChageCoachesDisplay,
  section,
  pastBookingView,
  onChangePastDisplay,
}) => (
  <div className="goals-utility">
    <div className="goals-utility-search">
      <Col span={12} className="p-0">
        {recent === true ? null : (
          <Input
            id="search-my-goals"
            placeholder={
              section === 'coaches'
                ? 'Search Coaches..'
                : 'Search appointments...'
            }
            type="search"
            onChange={
              section === 'coaches'
                ? onCoachSearch
                : section === 'past'
                ? onPastSearch
                : onUpcomingSearch
            }
            onKeyUp={
              section === 'coaches'
                ? onCoachSearch
                : section === 'past'
                ? onPastSearch
                : onUpcomingSearch
            }
          />
        )}
      </Col>
    </div>

    <div className="goals-utility-display">
      <Radio.Group
        defaultValue={
          section === 'coaches'
            ? coachesView
            : section === 'past'
            ? pastBookingView
            : bookingView
        }
        onChange={
          section === 'coaches'
            ? onChageCoachesDisplay
            : section === 'past'
            ? onChangePastDisplay
            : onChangeDisplay
        }>
        <Radio.Button value="list">List</Radio.Button>
        <Radio.Button value="grid">Grid</Radio.Button>
      </Radio.Group>
    </div>
    {section !== 'coaches' && recent === true && (
      <div className="goals-utility-actions">
        <Link
          to={`/app/coaching/appointment/history`}
          className="btn btn-sm btn-primary">
          All Appointments
        </Link>
      </div>
    )}
  </div>
);

export default ListDisplayStyle;
