import app from 'firebase/app';
import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import config from './firebaseConfig';

function isIterable(obj) {
  // checks for null and undefined
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === 'function';
}

function sortDeepArray(arr) {
  return arr.sort((a, b) => {
    let aTime = new Date(a.date).getTime();
    let bTime = new Date(b.date).getTime();
    if (aTime > bTime) {
      return 1;
    }
    if (aTime < bTime) {
      return -1;
    }
    return 0;
  });
}

function retrieveComments(commentDocument) {
  if (
    commentDocument &&
    commentDocument.comments &&
    isIterable(commentDocument.comments)
  ) {
    return sortDeepArray(commentDocument.comments);
  } else {
    return [];
  }
}

class FirebasePosts {
  constructor() {
    if (!firebase.apps.length) {
      app.initializeApp(config);
    }
    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
  }

  async updatePost(postText, fileArray, postData, groupId) {
    let table = 'posts';
    let docId = this.auth.currentUser.uid.toString();
    if (groupId) {
      table = 'groupPosts';
      docId = groupId;
    }

    let userRef = this.db
      .collection(table)
      .doc(docId)
      .collection('userPosts')
      .doc(postData.id);

    userRef.update({
      text: postText,
      date: Math.floor(new Date().getTime() / 1000),
      mediaArray: fileArray,
    });
  }

  async deletePost(postData, groupData) {
    let batch = this.db.batch();
    let postCommentsRef = this.db.collection('postComments').doc(postData.id);
    let commentsCollectionRef = postCommentsRef.collection('comments');
    await commentsCollectionRef.get().then(async function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
        batch.delete(doc.ref);
      });
    });
    batch.delete(postCommentsRef);

    await batch.commit();

    let img = this.storage.ref();

    let table = 'posts';
    let docId = postData.user;
    let storageLocation = `gallery/${this.auth.currentUser.uid}/`;
    if (groupData) {
      table = 'groupPosts';
      docId = groupData.id;
      storageLocation = `groups/${this.auth.currentUser.uid}/posts/`;
    }

    postData.mediaArray.forEach(upload => {
      img.child(storageLocation + `${upload.name}`).delete();
    });

    await this.db
      .collection(table)
      .doc(docId)
      .collection('userPosts')
      .doc(postData.id)
      .delete();

    await this.db
      .collection('postComments')
      .doc(postData.id)
      .delete();

    if (groupData) {
      // Increment Post count
      this.db
        .collection('groups')
        .doc(`${groupData.userId}`)
        .collection('userGroups')
        .doc(groupData.id)
        .update({
          groupPostsCount: firebase.firestore.FieldValue.increment(-1),
        });
    }
  }

  async loopMedia(fileInfoList, groupData) {
    let userId = this.auth.currentUser.uid;
    let userRef = this.db.collection('users').doc(userId);
    let userDoc = null;
    let userMedia = [];

    await userRef.get().then(doc => (userDoc = doc.data()));

    if (userDoc) {
      if (isIterable(userDoc.social.mediaArray)) {
        userMedia = [...userDoc.social.mediaArray];
      }
    }

    let fileNum;
    let mediaArray = [];
    let storageLocation = `gallery/${userId}/`;
    if (groupData) {
      storageLocation = `groups/${userId}/posts/`;
    }

    for (fileNum = 0; fileNum < fileInfoList.length; fileNum++) {
      let upload = fileInfoList[fileNum].originFileObj;
      let fileName = new Date().getTime();
      let img = this.storage.ref().child(storageLocation + fileName);
      await img.put(upload).then(() => {
        img.updateMetadata({
          customMetadata: {name: fileName, uid: userId},
        });
      });
      let downloadURL = await img.getDownloadURL();

      mediaArray.push({name: fileName, uri: downloadURL});
      userMedia.push({name: fileName, uri: downloadURL});
    }

    await userRef.update({social: {mediaArray: userMedia}});
    return mediaArray;
  }

  async onMediaUpload(fileInfoList, groupData) {
    let mediaArray = [];

    if (fileInfoList.length > 0) {
      mediaArray = await this.loopMedia(fileInfoList, groupData);
    }
    return mediaArray;
  }

  getUserPostList(userId) {
    return this.db
      .collection('posts')
      .doc(userId)
      .collection('userPosts');
  }

  getUserWall(userId) {
    return this.db
      .collection('wall')
      .doc(userId)
      .collection('posts');
  }

  async getPostMedia(postData, groupId) {
    let result = null;
    let returnArray = [];
    let table = 'posts';
    let docId = postData.user;
    if (groupId) {
      table = 'groupPosts';
      docId = groupId;
    }
    await this.db
      .collection(table)
      .doc(docId)
      .collection('userPosts')
      .doc(postData.id)
      .get()
      .then(doc => (result = doc.data()));

    result.mediaArray.forEach(media => {
      returnArray.push({uri: media.uri, name: media.name});
    });

    return returnArray;
  }

  async removePictures(postId, removeList) {
    let batch = this.db.batch();
    let userId = this.auth.currentUser.uid;
    let userRef = this.db.collection('users').doc(userId);
    let userInfo = null;

    await userRef.get().then(doc => (userInfo = doc.data()));

    let postInfo = null;
    let postRef = this.db
      .collection('posts')
      .doc(this.auth.currentUser.uid)
      .collection('userPosts')
      .doc(postId);
    await postRef.get().then(doc => (postInfo = doc.data()));

    let updatedMediaArray = [];
    let updatedUserMedia = [];

    postInfo.mediaArray.forEach(mediaFile => {
      let retainFile = true;
      removeList.forEach(name => {
        if (mediaFile.name === name) {
          retainFile = false;
        }
      });
      if (retainFile) {
        updatedMediaArray.push(mediaFile);
      }
    });

    userInfo.social.mediaArray.forEach(mediaFile => {
      let retainFile = true;
      removeList.forEach(name => {
        if (mediaFile.name === name) {
          retainFile = false;
        }
      });
      if (retainFile) {
        updatedUserMedia.push(mediaFile);
      }
    });

    batch.update(userRef, {social: {mediaArray: updatedUserMedia}});
    batch.update(postRef, {mediaArray: updatedMediaArray});

    let storageLocation = `gallery/${this.auth.currentUser.uid}/`;
    removeList.forEach(fileName => {
      this.storage
        .ref()
        .child(storageLocation + fileName)
        .delete();
    });

    return batch.commit();
  }

  async commentPost(post, comment, groupId) {
    try {
      let commentRef = this.db
        .collection('postComments')
        .doc(post.id)
        .collection('comments')
        .doc();

      const commentObject = {...comment, likeArray: [], id: commentRef.id};

      const [postCommentsError, commentId] = await this.db
        .collection('postComments')
        .doc(post.id)
        .collection('comments')
        .doc(commentRef.id)
        .set(commentObject)
        .then(docRef => [null, commentObject.id])
        .catch(err => [err]);

      if (postCommentsError) {
        return [postCommentsError];
      }

      /**
       * This section is for updating the preview comments (maximum total of 3) in post object.
       * This would be better to be moved in GCF so that we won't do unnecessary calls for the client side.
       * And doing this kind of updates can lead to outdated post comments when 2 or more users do comment at
       * nearly the same time.
       */

      let table = 'posts';
      let docId = post.user;

      if (groupId) {
        table = 'groupPosts';
        docId = groupId;
      }

      const postRef = this.db
        .collection(table)
        .doc(docId)
        .collection('userPosts')
        .doc(post.id);

      const postGet = await postRef.get();
      const postDoc = postGet.data();

      let updatedPostComments = retrieveComments(postDoc);

      if (updatedPostComments.length === 3) {
        updatedPostComments.shift();
      }

      updatedPostComments.push(commentObject);

      const [postUpdateError] = await postRef
        .update({
          comments: updatedPostComments,
          commentCount: firebase.firestore.FieldValue.increment(1),
        })
        .then(res => [null, res])
        .catch(err => [err]);

      if (postUpdateError) {
        return [postUpdateError];
      }

      return [null, {id: commentId}];
    } catch (err) {
      return [err];
    }
  }

  async likeComment(post, commentId, groupId) {
    let commentRef = this.db
      .collection('postComments')
      .doc(post.id)
      .collection('comments')
      .doc(commentId);
    await commentRef.update({
      likeArray: firebase.firestore.FieldValue.arrayUnion(
        this.auth.currentUser.uid,
      ),
    });

    let table = 'posts';
    let docId = post.user;
    if (groupId) {
      table = 'groupPosts';
      docId = groupId;
    }

    let commentArray = [];

    let postRef = this.db
      .collection(table)
      .doc(docId)
      .collection('userPosts')
      .doc(post.id);
    let postGet = await postRef.get();
    let postDocument = postGet.data();

    if (postDocument && postDocument.comments) {
      if (isIterable(postDocument.comments)) {
        commentArray = [...postDocument.comments];
      }
    }

    for (let i = 0; i < commentArray.length; i++) {
      let commentLikes = [];
      if (commentArray[i].id === commentId) {
        commentLikes = [...commentArray[i].likeArray];
        commentLikes.push(this.auth.currentUser.uid);
        commentArray[i].likeArray = commentLikes;
      }
    }

    postRef.update({comments: commentArray});

    // let table = 'posts';
    // let docId = post.user;
    // if (groupId) {
    //   table = 'groupPosts';
    //   docId = groupId;
    // }

    // let postRef = this.db
    //   .collection(table)
    //   .doc(docId)
    //   .collection('userPosts')
    //   .doc(post.id);
    // let postGet = await postRef.get();
    // let postDocument = postGet.data();

    // let commentArray = retrieveComments(postDocument.comments);

    // for (let i = 0; i < commentArray.length; i++) {
    //   if (commentArray[i].id === commentId) {
    //     commentArray[i].likeArray = firebase.firestore.FieldValue.arrayUnion(
    //       this.auth.currentUser.uid,
    //     );
    //   }
    // }

    // postRef.update({comments: commentArray});
  }

  async unlikeComment(post, commentId, userId, groupId) {
    let commentRef = this.db
      .collection('postComments')
      .doc(post.id)
      .collection('comments')
      .doc(commentId);
    await commentRef
      .update({
        likeArray: firebase.firestore.FieldValue.arrayRemove(userId),
      })
      .then(res => [null, res])
      .catch(err => [err]);

    let table = 'posts';
    let docId = post.user;
    if (groupId) {
      table = 'groupPosts';
      docId = groupId;
    }

    let commentArray = [];

    let postRef = this.db
      .collection(table)
      .doc(docId)
      .collection('userPosts')
      .doc(post.id);
    let postGet = await postRef.get();
    let postDocument = postGet.data();

    if (postDocument && postDocument.comments) {
      if (isIterable(postDocument.comments)) {
        commentArray = [...postDocument.comments];
      }
    }

    for (let i = 0; i < commentArray.length; i++) {
      let commentLikes = [];
      let updatedLikes = [];
      if (commentArray[i].id === commentId) {
        commentLikes = [...commentArray[i].likeArray];
        commentLikes.forEach(like => {
          if (like !== userId) {
            updatedLikes.push(like);
          }
        });
        commentArray[i].likeArray = updatedLikes;
      }
    }

    postRef.update({comments: commentArray});

    // let table = 'posts';
    // let docId = post.user;
    // if (groupId) {
    //   table = 'groupPosts';
    //   docId = groupId;
    // }
    // let postRef = this.db
    //   .collection(table)
    //   .doc(docId)
    //   .collection('userPosts')
    //   .doc(post.id);
    // let postGet = await postRef.get();
    // let postDocument = postGet.data();
    // let commentArray = retrieveComments(postDocument.comments);

    // for (let i = 0; i < commentArray.length; i++) {
    //   if (commentArray[i].id === commentId) {
    //     commentArray[i].likeArray = firebase.firestore.FieldValue.arrayRemove(
    //       userId,
    //     );
    //   }
    // }
    // postRef.update({comments: commentArray});
  }
}

export default new FirebasePosts();
