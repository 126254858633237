import React, { useState } from 'react';
import moment from 'moment';
import numeral from 'numeral';
import { Select, Table } from 'antd/lib';

const Option = Select.Option;
const monthFormat = 'MM/YYYY';
const dayFormat = 'D-MMM-YYYY';

const columns = [
  {
    title: 'Month',
    dataIndex: 'month',
  },
  {
    title: 'Date',
    dataIndex: 'date',
  },
  {
    title: 'Monthly Payment',
    dataIndex: 'monthlyPayment',
  },
  {
    title: 'Interest Amount',
    dataIndex: 'interestAmount',
  },
  {
    title: 'Principal Amount',
    dataIndex: 'principalAmount',
  },
  {
    title: 'Extra Principal',
    dataIndex: 'extraPrincipal',
  },
  {
    title: 'Outstanding Balance',
    dataIndex: 'outstandingBalance',
  },
];

const AmortizationTable = ({ loan }) => {
  let data = [];
  const [loanFreq, setLoanFreq] = useState('monthly');

  //console.log(`Loan amort data: ${JSON.stringify(loan)}`);

  function handleChange(value) {
    console.log(`selected ${value}`);
    setLoanFreq(value);
  }

  const calculateMonthyPayments = inputLoan => {
    const amortization = inputLoan.getAmortization();

    switch (loanFreq) {
      case 'monthly':
        amortization.forEach(dataElem => {
          data.push({
            key: dataElem.month,
            month: dataElem.month,
            date: moment(dataElem.date, dayFormat).format(dayFormat),
            monthlyPayment: numeral(dataElem.monthlyPayment).format('$0,0.00'),
            interestAmount: numeral(dataElem.interestAmount).format('$0,0.00'),
            principalAmount: numeral(dataElem.principalAmount).format(
              '$0,0.00',
            ),
            extraPrincipal: numeral(dataElem.extraPrincipal).format('$0,0.00'),
            outstandingBalance: numeral(dataElem.outstandingBalance).format(
              '$0,0.00',
            ),
          });
        });
        break;

      case 'quarterly':
        let i = 0;
        amortization.forEach(dataElem => {
          if (i % 3 === 0) {
            data.push({
              key: dataElem.month,
              month: dataElem.month,
              date: moment(new Date(dataElem.date), monthFormat).format(dayFormat),
              monthlyPayment: numeral(dataElem.monthlyPayment).format(
                '$0,0.00',
              ),
              interestAmount: numeral(dataElem.interestAmount).format(
                '$0,0.00',
              ),
              principalAmount: numeral(dataElem.principalAmount).format(
                '$0,0.00',
              ),
              extraPrincipal: numeral(dataElem.extraPrincipal).format('$0,0.00'),
              outstandingBalance: numeral(dataElem.outstandingBalance).format(
                '$0,0.00',
              ),
            });
          }
          i++;
        });
        break;

      case 'yearly':
        let j = 0;
        amortization.forEach(dataElem => {
          if (j % 12 === 0) {
            data.push({
              key: dataElem.month,
              month: dataElem.month,
              date: moment(new Date(dataElem.date), monthFormat).format(dayFormat),
              monthlyPayment: numeral(dataElem.monthlyPayment).format(
                '$0,0.00',
              ),
              interestAmount: numeral(dataElem.interestAmount).format(
                '$0,0.00',
              ),
              principalAmount: numeral(dataElem.principalAmount).format(
                '$0,0.00',
              ),
              extraPrincipal: numeral(dataElem.extraPrincipal).format('$0,0.00'),
              outstandingBalance: numeral(dataElem.outstandingBalance).format(
                '$0,0.00',
              ),
            });
          }
          j++;
        });
        break;

      default:
        break;
    }
  };

  calculateMonthyPayments(loan);

  return (
    <div>
      <div className="ant-row-flex ">
        <h2 className="h4 gx-mb-3">Loan Amortization Table</h2>
        <div className="gx-ml-auto">
          <Select
            className="gx-mb-2 gx-select-sm"
            defaultValue="monthly"
            onChange={handleChange}
            style={{ width: '100px' }}>
            <Option value="monthly">Monthly</Option>
            <Option value="quarterly">Quarterly</Option>
            <Option value="yearly">Yearly</Option>
          </Select>
        </div>
      </div>
      <div>
        <Table
          className="gx-table-responsive"
          columns={columns}
          dataSource={data}
          size="middle"
        />
      </div>
    </div>
  );
};

export default AmortizationTable;
