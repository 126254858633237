import React from 'react';
import Auxiliary from 'util/Auxiliary';
import {Popover, Badge} from 'antd/lib';
import FirebaseSocial from 'firebase/firebaseSocial';
import {useCollectionData} from 'react-firebase-hooks/firestore';
import styles from './notificationspopover.less'; // eslint-disable-line
import NotificationApp from './NotificationApp';

const NotificationsPopover = ({iconText}) => {
  const [requestList, requestLoading, requestError] = useCollectionData(
    FirebaseSocial.getUserFriendRequestsReceived(
      FirebaseSocial.auth.currentUser.uid,
    ),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  return (
    <Auxiliary>
      {requestError && <strong>Connection error!</strong>}
      {requestLoading && <p>Loading...</p>}
      {requestList && (
        <Popover
          overlayClassName="gx-popover-horizantal"
          placement="bottomRight"
          content={<NotificationApp requestList={requestList} />}
          trigger="click">
          <span className="gx-pointer gx-d-block">
            {iconText}
            <Badge count={requestList.length} className="notifications-badge">
              <div className="head-example" />
            </Badge>
          </span>
        </Popover>
      )}
    </Auxiliary>
  );
};

export default NotificationsPopover;
