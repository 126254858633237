import React from 'react';
import { Spin } from 'antd/lib';
import { FirebaseDAO } from 'firebase/firebase';
import FirebaseFin from 'firebase/firebaseFin'
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';
import ProcessAssetLiabilitiesData from 'components/app/widgets/dashboard/ProcessAssetLiabilitiesData';
import { FaSpinner } from 'react-icons/fa';
const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;

const AssetsLiabilitiesCard = ({ profile }) => {
    const [loans, loansLoading] = useCollectionData(
        FirebaseDAO.getFBCollectionData('loans'),
        {
            idField: 'id',
            snapshotListenOptions: { includeMetadataChanges: true },
        },
    );

    const [linkedLoans, linkedLoansLoading] = useCollectionData(
        FirebaseFin.getPlaidData('credit'),
        {
            idField: 'id',
            snapshotListenOptions: { includeMetadataChanges: true },
        },
    );


    const [accounts, accountsLoading] = useCollectionData(
        FirebaseDAO.getFBCollectionData('accounts'),
        {
            idField: 'id',
            snapshotListenOptions: { includeMetadataChanges: true },
        },
    );

    const [linkedAccounts, linkedAccountsLoading] = useCollectionData(
        FirebaseFin.getPlaidData('depository'),
        {
            idField: 'id',
            snapshotListenOptions: { includeMetadataChanges: true },
        },
    );

    const [userData, userDataLoading] = useDocumentData(
        FirebaseFin.getUserDocumentData(),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        },
    );

    return (
        (loansLoading || linkedAccountsLoading || linkedLoansLoading || accountsLoading || userDataLoading) ? <Spin indicator={spinnerIcon} /> :
            <ProcessAssetLiabilitiesData
                profile={profile}
                loans={loans}
                linkedLoans={linkedLoans}
                accounts={accounts}
                linkedAccounts={linkedAccounts}
                userData={userData}
            />
    );
};

export default AssetsLiabilitiesCard;
