import { Firebase } from 'loanpalz-constants';
import app from 'firebase/app';
import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import config from './firebaseConfig';

const initializeFirebase = () => {
    // Setup firebase service
    if (!firebase.apps.length) {
        app.initializeApp(config);
    }

    return new Firebase({
        firebase,
        auth: app.auth(),
        db: app.firestore(),
        storage: app.storage(),
    });
};

const appSetup = () => {
    try {
        initializeFirebase();
    } catch (err) {
        console.log('Setup Error: ', err);
    }
};

export default appSetup;
