import React from 'react';
import Auxiliary from 'util/Auxiliary';
import {useCollectionData} from 'react-firebase-hooks/firestore';
import FirebaseSocial from 'firebase/firebaseSocial';

const Profile = ({profile}) => {
  let userId = FirebaseSocial.auth.currentUser.uid.toString();

  const [friendOneLook, friendOneLoading, friendOneError] = useCollectionData(
    FirebaseSocial.getUserFriendOne(userId),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [friendTwoLook, friendTwoLoading, friendTwoError] = useCollectionData(
    FirebaseSocial.getUserFriendTwo(userId),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [followerList, followerLoading, followerError] = useCollectionData(
    FirebaseSocial.getUserFollowers(userId),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [followingList, followingLoading, followingError] = useCollectionData(
    FirebaseSocial.getUserFollowing(userId),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  if (!profile || !profile.social) {
    return null;
  } else {
    let location = profile.social.location;
    let username = profile.username;

    return (
      <div>
        {(friendOneError ||
          friendTwoError ||
          followerError ||
          followingError) && <strong>Connection error!</strong>}
        {(friendOneLoading ||
          friendTwoLoading ||
          followerLoading ||
          followingLoading) && <p>Loading...</p>}
        {friendOneLook && friendTwoLook && followerList && followingList && (
          <Auxiliary>
            <div className="gx-profileon">
              <div className="gx-profileon-thumb">
                <img src={profile.avatar} alt="" />
              </div>
              <div className="gx-profileon-content">
                <p className="gx-profileon-title">{username}</p>
                <span className="gx-fs-sm">{location}</span>
              </div>
            </div>

            <div className="gx-follower gx-text-center">
              <ul className="gx-follower-list">
                <li>
                  <span className="gx-fs-sm">Followers</span>
                  <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">
                    {followerList.length}
                  </span>
                </li>
                <li>
                  <span className="gx-fs-sm">Following</span>
                  <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">
                    {followingList.length}
                  </span>
                </li>
                <li>
                  <span className="gx-fs-sm">Friends</span>
                  <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">
                    {friendOneLook.length + friendTwoLook.length}
                  </span>
                </li>
              </ul>
            </div>
          </Auxiliary>
        )}
      </div>
    );
  }
};

export default Profile;
