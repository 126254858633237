import React from 'react';
import {Link} from 'react-router-dom';

const BodyListItem = ({item}) => {
  let id = null;
  if (item.id) {
    id = item.id;
  } else {
    id = item.objectID;
  }
  return (
    <div>
      <h4 class="ant-list-item-meta-title">
        <Link
          to={{
            pathname: `/app/user/${item.vanityURL}`,
            userId: id,
          }}>
          {item.username}
        </Link>
      </h4>
      {item.social.location && (
        <div className="ant-list-item-meta-description">
          {item.social.location}
        </div>
      )}
    </div>
  );
};

export default BodyListItem;
