import React, { Fragment } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import FirebaseFin from 'firebase/firebaseFin';
import { Link } from 'react-router-dom';
import { Card, Progress, Dropdown, Menu } from 'antd/lib';
import IntlMessages from 'util/IntlMessages';
import numeral from 'numeral';
import _ from 'lodash';
import { TEMPORARY_GOAL_IMAGE } from 'util/constants';

const DisplayGrid = ({ data, editGoal, deleteGoal }) => {

  const [accounts] = useCollectionData(FirebaseFin.getBankAccounts(), {
    idField: 'id',
    snapshotListenOptions: {
      includeMetadataChanges: true,
    },
  });

  // TODO: Refactor this ugly method
  const assignAccount = goal => {
    if (goal && Object.keys(goal).length) {
      // setGoal(goal);
      let { currentAmount, goalAmount } = goal;
      goalAmount = parseInt(goalAmount, 10);

      if (goal.accountId) {
        const theAccount = _.find(accounts, { id: goal.accountId });
        const hasValidBalance =
          theAccount &&
          typeof theAccount.balance !== 'undefined' &&
          theAccount.balance !== null;
        if (hasValidBalance) {
          currentAmount = parseInt(theAccount.balance.toFixed(2), 10);
        }
      } else {
        currentAmount = parseInt(currentAmount, 10);
      }
      const percent = (currentAmount / goalAmount) * 100;
      const progress = percent.toFixed(2);
      return { ...goal, currentAmount, progress };
    }
  };

  const options = ['Edit', 'Delete'];
  const manageGaol = item => (
    <Menu
      onClick={event => {
        if (event.key === 'Edit') {
          editGoal(item);
        } else {
          deleteGoal(item);
        }
      }}>
      {options.map(option => (
        <Menu.Item key={option}>{option}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Fragment>
      {data &&
        data.map(item => {
          item = assignAccount(item)
          return (
            <Card
              key={item.id}
              size="small"
              className="goal-card flex-fill"
              cover={
                <Link to={`/app/goal/${item.id}`}>
                  <img
                    className="goal-cover-image"
                    src={item.image || TEMPORARY_GOAL_IMAGE}
                    alt=""
                  />
                </Link>
              }>
              <Dropdown
                overlay={() => manageGaol(item)}
                placement="bottomRight"
                trigger={['click']}
              >
                <i className="gx-icon-btn icon icon-ellipse-v float-right m-0 btn p-0 ant-dropdown-link border-0" />
              </Dropdown>
              <div className="goal-card-heading p-2 d-flex">
                <div>
                  <h3>
                    <Link to={`/app/goal/${item.id}`}>{item.name}</Link>
                  </h3>
                  <span>{numeral(item.goalAmount).format('$0,0')}</span>
                </div>
              </div>
              <div className="mt-2 p-2 pr-4">
                <h4>
                  {item.progress < 100 ? (
                    <IntlMessages id="goals.progress" />
                  ) : (
                      <IntlMessages id="goals.accomplished" />
                    )}
                </h4>
                <Progress
                  percent={item.progress}
                  status={item.progress < 100 ? 'active' : 'success'}
                />
              </div>
            </Card>
          )
        })}
    </Fragment>
  );
};

export default DisplayGrid;
