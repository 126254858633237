import React, { Fragment } from 'react';
import DisplayCoachesCell from './DisplayCoachesCell'

const DisplayCoachesGrid = ({ profile, data, section }) => {

    return (
        <Fragment>
            {
                data &&
                data.map((appointment, index) => (
                    <DisplayCoachesCell
                        key={index}
                        profile={profile}
                        data={appointment}
                        section={section}
                        view={'grid'}
                    />
                ))
            }
        </Fragment>
    );
};

export default DisplayCoachesGrid;
