import React from 'react';
import PlaidLink from 'react-plaid-link';
import FirebasePlaid from 'firebase/firebasePlaid';
import {history} from 'appRedux/store';

const PlaidMenuLink = ({children, token}) => {
  const handlePlaidOnExit = (error, metadata) => {
    console.log('link: user exited');
    console.log(error, metadata);
  };

  const handlePlaidOnSuccess = async (token, metadata) => {
    console.log('Token: ', token);
    console.log('Metadata: ', metadata);
    await FirebasePlaid.exchangeToken(token, metadata);
    history.push('/app/accounts');
  };

  const handlePlaidOnLoad = () => {
    // console.log('link: loaded');
  };

  const handlePlaidOnEvent = (eventname, metadata) => {
    console.log('link: user event', eventname, metadata);
  };

  const style = {
    padding: 0,
    outline: 'none',
    background: 'transparent',
    border: 0,
  };

  return (
    <PlaidLink
      style={style}
      clientName="Loanpalz"
      env="development"
      token={token}
      product={['auth', 'transactions']}
      publicKey="b765b1e3967ea82ffb08abb10e84b4"
      onExit={handlePlaidOnExit}
      onSuccess={handlePlaidOnSuccess}
      onEvent={handlePlaidOnEvent}
      onLoad={handlePlaidOnLoad}>
      {children}
    </PlaidLink>
  );
};

export default PlaidMenuLink;
