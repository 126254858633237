import React from "react";
import FirebaseSocial from 'firebase/firebaseSocial';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { Avatar, Dropdown, Menu, Modal } from 'antd/lib';
import CircularProgress from "components/CircularProgress";
import DisplayDate from 'util/DisplayDate';
const { confirm } = Modal;

const MemberCell = ({ profile, data, groupData, removeMember }) => {
    const [memberData, memberDataLoading] = useDocumentData(
        FirebaseSocial.getUserDocumentData(data.memberId),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        },
    );

    const options = ['Remove Member'];

    const menus = () => (
        <Menu
            onClick={e => {
                if (e.key === 'Remove Member') {
                    onDeleteClick(data);
                } else {
                    // Other action will be here.
                }
            }}
        >
            {options.map(option => (
                <Menu.Item key={option}>{option}</Menu.Item>
            ))}
        </Menu>
    );

    const onDeleteClick = data => {
        confirm({
            title: 'Are you sure you want to remove this member?',
            onOk: () => {
                removeMember(data, groupData);
            },
            onCancel() { },
        });
    };

    const cell = () => {
        return (
            <div className="gx-contact-item">
                <div className="gx-module-list-icon">
                    <div className="gx-ml-2 gx-d-none gx-d-sm-flex">
                        {memberData.avatar === null || memberData.avatar === '' ?
                            (
                                <Avatar size="large">{memberData.username.charAt(0).toUpperCase()}</Avatar>
                            ) : (
                                <Avatar size="large" alt={memberData.username} src={memberData.avatar} />
                            )
                        }
                    </div>
                </div>

                <div className="gx-module-list-info gx-contact-list-info" style={{ maxWidth: "100%" }}>
                    <div className="gx-module-contact-content">
                        <p className="gx-mb-1">
                            <span className="gx-text-truncate gx-contact-name"> {memberData.username} </span>
                            <span className="gx-toolbar-separator">&nbsp;</span>
                            <span className="gx-text-truncate gx-job-title">
                                {memberData.email}
                            </span>
                        </p>

                        <div className="gx-text-muted">
                            <span className="gx-email gx-d-inline-block gx-mr-2">
                                {data.memberType === 1 ? "Group Admin" : "Group Member"}
                                <span className="gx-toolbar-separator">&nbsp;</span>
                                <DisplayDate date={data.date.toString()} />
                            </span>
                            {/* <span className="gx-phone gx-d-inline-block">{"phone"}</span> */}
                        </div>
                    </div>
                    {
                        groupData.userId === profile.id && groupData.userId !== data.memberId ?
                            <div className="gx-module-contact-right">
                                <Dropdown
                                    overlay={() => menus()}
                                    placement="bottomRight"
                                    trigger={['click']}
                                >
                                    <i className="gx-icon-btn icon icon-ellipse-v" />
                                </Dropdown>

                            </div>
                            : null
                    }
                </div>
            </div>
        )

    };

    return (
        memberDataLoading ?
            (
                <CircularProgress className="gx-loader-400" />
            ) :
            (
                cell()
            )

    );
}

export default MemberCell;
