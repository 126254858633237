import React from 'react';
import {Button} from 'antd';
import {FaBullseye} from 'react-icons/fa';

const NoGoalsYet = ({showAddGoalForm}) => {
  return (
    <div className="text-center m-5">
      <div className="gx-fs-80 gx-text-muted">
        <FaBullseye />
      </div>
      <h1 className="m-4 gx-text-muted">
        You don’t have any goals yet. Let’s add one!
      </h1>
      <Button className="btn btn-primary" onClick={() => showAddGoalForm(true)}>
        Add Goal
      </Button>
    </div>
  );
};
export default NoGoalsYet;
