import React, {useState} from 'react';
import {useDocumentData} from 'react-firebase-hooks/firestore';
import {Form, Button, Input, Modal, Row, Col, message, Spin} from 'antd/lib';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';
import FirebaseSocial from 'firebase/firebaseSocial';
import IntlMessages from 'util/IntlMessages';
import {
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaTumblr,
  FaFacebook,
} from 'react-icons/fa';
import {FaSpinner} from 'react-icons/fa';

const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;

const generateSocialLinks = data => {
  let items = [];
  data.forEach(media => {
    items.push(
      <Col xl={8} lg={12} md={12} sm={12} xs={24}>
        <a href={media.dataKey} target="_blank" rel="noopener noreferrer">
          <div className="gx-flex-row gx-mb-3">
            <div className="gx-mr-3">{media.icon}</div>
            <div style={{lineHeight: 3}}>Visit my {media.name}</div>
          </div>
        </a>
      </Col>,
    );
  });

  return items;
};

const SocialMedia = ({readonly, profile}) => {
  const [user, userLoading, userError] = useDocumentData(
    FirebaseSocial.getUserDocumentData(profile.id),
    {
      snapshotListenOptions: {includeMetadataChanges: true},
    },
  );

  const [form] = Form.useForm();
  const [showModal, setShowModal] = useState(false);
  const intlMessages = useFormatMessage();

  const onModalClose = () => setShowModal(false);
  const onModalOpen = () => setShowModal(true);

  React.useEffect(() => {
    if (user && user.social) {
      form.setFieldsValue({
        facebook: user.social.facebook,
        twitter: user.social.twitter,
        instagram: user.social.instagram,
        linkedin: user.social.linkedin,
        tumblr: user.social.tumblr,
      });
    }
  }, [user, form]);

  const formItemLayout = {
    labelCol: {
      xs: {span: 24},
      sm: {span: 4},
    },
    wrapperCol: {
      xs: {span: 24},
      sm: {span: 20},
    },
  };

  const submitForm = async values => {
    FirebaseSocial.updateSocialMedia(values);
    onModalClose();

    message.success(intlMessages('profile.update.success'));
  };

  const cancelEdit = () => {
    onModalClose();
    form.resetFields();
  };

  let extra = null;
  if (!readonly) {
    extra = (
      <i
        className={`icon icon-edit gx-pointer`}
        style={{fontSize: 15}}
        onClick={() => onModalOpen()}
      />
    );
  }

  if (!user) {
    return <Spin indicator={spinnerIcon} />;
  } else {
    const facebook = user.social.facebook;
    const twitter = user.social.twitter;
    const instagram = user.social.instagram;
    const linkedin = user.social.linkedin;
    const tumblr = user.social.tumblr;

    const socialMediaData = [
      {
        name: 'Facebook',
        dataKey: facebook,
        icon: <FaFacebook size="40px" />,
      },
      {
        name: 'Twitter',
        dataKey: twitter,
        icon: <FaTwitter size="40px" />,
      },
      {
        name: 'Instagram',
        dataKey: instagram,
        icon: <FaInstagram size="40px" />,
      },
      {
        name: 'LinkedIn',
        dataKey: linkedin,
        icon: <FaLinkedin size="40px" />,
      },
      {
        name: 'Tumblr',
        dataKey: tumblr,
        icon: <FaTumblr size="40px" />,
      },
    ];

    return (
      <div>
        {userError && <strong>Connection error!</strong>}
        {userLoading && <p>Loading...</p>}
        {user && (
          <div>
            <div className="gx-ml-2 gx-mb-3">
              <div className="gx-profile-banner-bottom">
                <span className="gx-link gx-profile-setting ">{extra}</span>
              </div>
              <Row>{generateSocialLinks(socialMediaData)}</Row>
            </div>

            <Modal
              title={<IntlMessages id="profile.addProfileInfo" />}
              toggle={onModalClose}
              visible={showModal}
              closable={true}
              onOk={() => form.submit()}
              onCancel={cancelEdit}>
              <Form {...formItemLayout} form={form} onFinish={submitForm}>
                <Form.Item name="facebook" label="Facebook">
                  <Input placeholder="https://www.facebook.com/" />
                </Form.Item>
                <Form.Item name="twitter" label="Twitter">
                  <Input placeholder="https://www.twitter.com/" />
                </Form.Item>
                <Form.Item name="instagram" label="Instagram">
                  <Input placeholder="https://www.instagram.com" />
                </Form.Item>
                <Form.Item name="linkedin" label="LinkedIn">
                  <Input placeholder="https://www.linkedin.com/in/" />
                </Form.Item>
                <Form.Item name="tumblr" label="Tumblr">
                  <Input placeholder="https://www.profile.tumblr.com" />
                </Form.Item>
                <Button htmlType="submit" style={{display: 'none'}}></Button>
              </Form>
            </Modal>
          </div>
        )}
      </div>
    );
  }
};

export default SocialMedia;
