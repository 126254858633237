import React from 'react';
import Widget from 'components/Widget/index';
import { Button } from 'antd/lib';
import IntlMessages from 'util/IntlMessages';

const FinancialSnapshotWelcomeCard = ({ name, onModalOpen }) => {
  return (
    <Widget styleName="gx-bg-dark-primary">
      <div className="gx-flex-row gx-justify-content-center gx-mb-3 gx-mb-md-4">
        <i className={`icon icon-revenue-new gx-fs-xlxl gx-text-white`} />
      </div>
      <div className="gx-text-center">
        <h2 className="h3 gx-mb-3 gx-text-white">
          {name}, you haven't added any loans yet.
        </h2>
        <p className="gx-text-white gx-mb-3">
          Click the button below to add your first loan and start tracking your
          progress.
        </p>
        <p className="gx-mt-5">
          <Button
            onClick={onModalOpen}
            size="large"
            className="gx-btn-secondary gx-pt-0"
            key={'addloan'}>
            <IntlMessages id="loan.add" />
          </Button>
        </p>
      </div>
    </Widget>
  );
};

export default FinancialSnapshotWelcomeCard;
