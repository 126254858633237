import React, {useState, useEffect} from 'react';
import {useDocumentData} from 'react-firebase-hooks/firestore';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import {
  Form,
  Card,
  Input,
  Button,
  Spin,
  Dropdown,
  Menu,
  Modal,
  message,
  Col,
  Divider,
  Row,
  Breadcrumb,
} from 'antd';
import UploadPicture from 'components/common/UploadPicture';
import {FaSpinner} from 'react-icons/fa';
import MediaList from 'components/profile/Posts/MediaList';
import DisplayDate from 'util/DisplayDate';
import FirebaseSocial from 'firebase/firebaseSocial';
import FirebaseForums from 'firebase/firebaseForums';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';
import VoteDisplay from './VoteDisplay';
import CommentDisplayDiv from 'components/common/CommentDisplayDiv';

const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;

function sortDeepArray(arr) {
  return arr.sort((a, b) => {
    let aTime = new Date(a.date).getTime();
    let bTime = new Date(b.date).getTime();
    if (aTime < bTime) {
      return 1;
    }
    if (aTime > bTime) {
      return -1;
    }
    return 0;
  });
}

const {TextArea} = Input;
const {confirm} = Modal;

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 24},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 24},
  },
};

const Thread = ({threadInfo, readonly, detailPage, search}) => {
  const [form] = Form.useForm();

  const [current, currentLoading, currentError] = useDocumentData(
    FirebaseSocial.getUserDocumentData(FirebaseSocial.auth.currentUser.uid),
    {
      snapshotListenOptions: {includeMetadataChanges: true},
    },
  );

  const [userProfile, userProfileLoading, userProfileError] = useDocumentData(
    FirebaseSocial.getUserDocumentData(threadInfo.author),
    {
      snapshotListenOptions: {includeMetadataChanges: true},
    },
  );

  if (current && userProfile && current.id === userProfile.id) {
    readonly = false;
  }

  const [newMessage, setNewMessage] = useState(null);
  const [uploading, setUploading] = useState(false);

  const isEnabled = newMessage === '';
  const options = ['Edit', 'Delete'];
  const [fileInfoList, setFileInfoList] = useState(threadInfo.mediaArray);
  const intlMessages = useFormatMessage();
  const [showModal, setShowModal] = useState(false);
  const onModalClose = () => setShowModal(false);
  const onModalOpen = () => setShowModal(true);

  let menus = () => (
    <Menu
      onClick={e => {
        if (e.key === 'Edit') {
          onModalOpen();
        } else {
          deleteForum();
        }
      }}>
      {options.map(option => (
        <Menu.Item key={option}>{option}</Menu.Item>
      ))}
    </Menu>
  );

  const deleteForum = () => {
    confirm({
      title: 'Are you sure you want to delete this forum?',
      onOk: async () => {
        await FirebaseForums.deleteThread(threadInfo.id);
        message.success(intlMessages('forum.deleted'));
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      title: threadInfo.title,
      description: threadInfo.text,
      files: threadInfo.mediaArray,
    });
  }, [form.title]); // eslint-disable-line

  const onFinish = async values => {
    const {title, description} = values;
    onModalClose();

    let oldPictures = [];
    let newPictures = [];
    let removedPictures = [];

    fileInfoList.forEach(file => {
      if (file.uri) {
        oldPictures.push(file);
      } else {
        newPictures.push(file);
      }
    });

    threadInfo.mediaArray.forEach(media => {
      let remove = true;
      fileInfoList.forEach(file => {
        if (media.name === file.name) {
          remove = false;
        }
      });

      if (remove) {
        removedPictures.push(media.name);
      }
    });

    if (removedPictures.length > 0) {
      await FirebaseForums.removePictures(threadInfo.id, removedPictures);
    }
    if (newPictures.length > 0) {
      setUploading(true);
      message.info(intlMessages('files.uploading'));
      await FirebaseForums.onMediaUpload(newPictures).then(result => {
        let pictureArray = _.union(oldPictures, result);
        FirebaseForums.updateThread(
          description,
          title,
          pictureArray,
          threadInfo.id,
        );
        message.success(intlMessages('files.uploaded'));
        setUploading(false);
      });
    } else {
      FirebaseForums.updateThread(
        description,
        title,
        oldPictures,
        threadInfo.id,
      );
      message.success(intlMessages('forum.updated'));
    }
  };

  const addComment = () => {
    const commentData = {
      user: FirebaseSocial.auth.currentUser.uid,
      comment: newMessage,
      date: new Date().toString(),
    };

    FirebaseForums.commentThread(threadInfo.id, commentData);
    setNewMessage('');
  };

  const updateCommentValue = evt => {
    setNewMessage(evt.target.value);
  };

  const onCancel = () => {
    onModalClose();
    form.resetFields();
  };

  let extra = null;
  if (!readonly) {
    extra = (
      <Dropdown overlay={menus} placement="topRight" trigger={['click']}>
        <i className="gx-icon-btn icon icon-ellipse-v" />
      </Dropdown>
    );
  }
  if (!threadInfo) {
    return <Spin indicator={spinnerIcon} />;
  } else {
    let comments = sortDeepArray(threadInfo.comments);

    let breadcrumb = null;
    if (detailPage) {
      breadcrumb = (
        <Breadcrumb className="gx-mb-3">
          <Breadcrumb.Item>
            <Link to={`/app/forum`}>
              <span className="gx-link">Back to Forums</span>
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      );
    }

    return (
      <div>
        {(currentError || userProfileError) && (
          <strong>Connection error!</strong>
        )}
        {(currentLoading || userProfileLoading) && <p>Loading...</p>}
        {current && userProfile && (
          <Spin spinning={uploading} delay={500}>
            <div>
              <Card>
                {breadcrumb}
                <Row>
                  <VoteDisplay threadInfo={threadInfo} forum={true} />
                  <Col span={23}>
                    <div className="gx-wall-content">
                      <div className="gx-media gx-wall-user-info gx-flex-nowrap gx-align-items-center">
                        <div className="gx-media-body">
                          <h6 className="gx-wall-user-title">
                            Posted by{' '}
                            <Link
                              to={{
                                pathname: `/app/user/${userProfile.vanityURL}`,
                                userId: threadInfo.author,
                              }}>
                              {userProfile.username}
                            </Link>{' '}
                            <DisplayDate date={threadInfo.date} />
                          </h6>
                          <h2>
                            <Link
                              to={{
                                pathname: `/app/forum/${threadInfo.id}`,
                              }}>
                              {threadInfo.title}
                            </Link>
                          </h2>
                        </div>
                        {extra}
                      </div>
                      <p>{threadInfo.text}</p>

                      {search ? (
                        <Button type="link" block>
                          <Link
                            to={{
                              pathname: `/app/forum/${threadInfo.id}`,
                            }}>
                            View Entire Forum
                          </Link>
                        </Button>
                      ) : (
                        <div>
                          <div className="gx-wall-medialist">
                            {threadInfo.mediaArray.length > 0 ? (
                              <MediaList mediaList={threadInfo.mediaArray} />
                            ) : null}
                          </div>
                          <div className="gx-flex-row gx-mb-2 gx-mb-xl-3">
                            <p className="gx-fs-sm gx-mr-3 gx-text-grey">
                              <span className="gx-d-inline-flex gx-vertical-align-middle">
                                {threadInfo.comments.length + ' Comments'}
                              </span>
                            </p>
                          </div>

                          <Divider />

                          <div className="gx-wall-comment-box">
                            <div className="gx-media gx-mb-2">
                              <div className="gx-media-body">
                                <TextArea
                                  value={newMessage}
                                  rows={2}
                                  onChange={event => updateCommentValue(event)}
                                  placeholder="Leave a Comment"
                                />
                              </div>
                            </div>

                            <div className="ant-row-flex">
                              <Button
                                type="primary"
                                size="small"
                                className="gx-ml-auto gx-mb-0"
                                disabled={isEnabled ? 'disabled' : ''}
                                onClick={addComment}>
                                Add Comment
                              </Button>
                            </div>
                          </div>
                          <CommentDisplayDiv
                            detailPage={detailPage}
                            threadInfo={threadInfo}
                            comments={comments}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </Card>

              <Modal
                title="Edit Forum"
                toggle={onModalClose}
                visible={showModal}
                closable={true}
                onOk={() => form.submit()}
                onCancel={onCancel}>
                <Form
                  layout="vertical"
                  {...formItemLayout}
                  form={form}
                  onFinish={onFinish}>
                  <Form.Item name="title" label="Title:">
                    <Input placeholder="Title" />
                  </Form.Item>
                  <Form.Item name="description" label="Description:">
                    <TextArea rows={10} placeholder="Forum Content" />
                  </Form.Item>
                  <Form.Item name="files" label="Pictures:">
                    <UploadPicture
                      fList={threadInfo.mediaArray}
                      onFileChange={setFileInfoList}
                    />
                  </Form.Item>
                  <Button htmlType="submit" style={{display: 'none'}}></Button>
                </Form>
              </Modal>
            </div>
          </Spin>
        )}
      </div>
    );
  }
};

export default Thread;
