import React from 'react';
import {Card} from 'antd/lib';
import VlogsItems from './vlogsItems';

const VlogList = ({
  vlogLook,
  currentUserId,
  userId,
  profile,
  onInfoUpdateClick,
  onPublishClick,
  onDeleteClick,
  vlogDetailView,
  userTags,
  likeUnlikeVideo,
}) => {
  return (
    <Card className="gx-card">
      {vlogLook &&
        vlogLook.map((video, index) => (
          <VlogsItems
            key={index}
            data={video}
            currentUserId={currentUserId}
            userId={userId}
            profile={profile}
            onInfoUpdateClick={onInfoUpdateClick}
            onPublishClick={onPublishClick}
            onDeleteClick={onDeleteClick}
            vlogDetailView={vlogDetailView}
            userTags={userTags}
            likeUnlikeVideo={likeUnlikeVideo}></VlogsItems>
        ))}
    </Card>
  );
};

export default VlogList;
