import React, {useState} from 'react';
import {AppContext} from 'auth/appContext';
import numeral from 'numeral';
import {AppleOutlined, AndroidOutlined} from '@ant-design/icons';
import {useCollectionData} from 'react-firebase-hooks/firestore';
import {
  Row,
  Col,
  Card,
  Tabs,
  List,
  Dropdown,
  Menu,
  Avatar,
  Drawer,
  message,
} from 'antd';
import Widget from 'components/Widget/index';
import Auxiliary from 'util/Auxiliary';
import FirebasePlaid from 'firebase/firebasePlaid';
import MemberEdit from '../plaid/MemberEdit';
import PlaidMenuLink from 'components/app/plaid/PlaidMenuLink';
import {OptimizelyFeature} from '@optimizely/react-sdk';
import NetWorthDonutChart from './NetWorthDonutChart';
import AccountBalancesLineChart from './AccountBalancesLineChart';

const {TabPane} = Tabs;

const compareInstitutions = (a, b) => {
  const instA = a.institution.name.toLowerCase();
  const instB = b.institution.name.toLowerCase();

  let comparison = 0;
  if (instA > instB) {
    comparison = 1;
  } else if (instA < instB) {
    comparison = -1;
  }
  return comparison;
};

const Accounts = ({profile}) => {
  const [accountList, loading, error] = useCollectionData(
    FirebasePlaid.getAccounts(),
    {
      idField: 'id',
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [tabActive, setTabActive] = useState('0');
  const [showDrawer, setShowDrawer] = useState(false);
  const onDrawerClose = () => setShowDrawer(false);
  const [memberEdit, setMemberEdit] = useState(null);

  const selectTab = key => {
    setTabActive(key);
  };

  const cancelEdit = () => {
    setMemberEdit(null);
    onDrawerClose();
  };

  const options = ['Refresh', 'Login', 'Mark closed'];

  const memberDropdownMenu = item => (
    <Menu
      onClick={async event => {
        switch (event.key) {
          case 'Refresh':
            console.log('Item: ', item);
            FirebasePlaid.refreshAccounts();
            message.success(
              'Request submitted successfully. Your accounts will refresh shortly.',
            );
            break;
          case 'Login':
            console.log('Logging in...');
            break;

          default:
            break;
        }
      }}>
      {options.map(option => (
        <Menu.Item key={option}>{option}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <OptimizelyFeature feature="accounts">
      {isEnabled =>
        isEnabled ? (
          <div>
            {error && <strong>Connection error!</strong>}
            {loading && <p>Loading...</p>}
            {accountList && accountList.length > 0 && (
              <div>
                <Row gutter={[24, 24]}>
                  <Col xl={6} lg={12} md={24} sm={24} xs={24}>
                    <Card title="Total portfolio">
                      <NetWorthDonutChart />
                    </Card>
                  </Col>
                  <Col xl={18} lg={12} md={24} sm={24} xs={24}>
                    <Card>
                      <AppContext.Consumer>
                        {({startDate, endDate}) => (
                          <AccountBalancesLineChart
                            startDate={startDate}
                            endDate={endDate}
                          />
                        )}
                      </AppContext.Consumer>
                    </Card>
                  </Col>
                </Row>

                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Card
                      title="Default size card"
                      extra={[
                        <span
                          className="gx-text-primary gx-mb-0 gx-pointer gx-mr-1"
                          onClick={() => console.log(true)}>
                          <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle gx-mr-1" />
                          <span>Hello</span>
                        </span>,
                        <span
                          className="gx-text-primary gx-mb-0 gx-pointer gx-mr-1"
                          onClick={() => console.log(true)}>
                          <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle gx-mr-1" />
                          <span>Hello</span>
                        </span>,
                        // For Mobile screen
                        <span
                          onClick={() => console.log(true)}
                          className="gx-text-primary gx-fs-md gx-pointer gx-mb-4 gx-d-block gx-d-sm-none gx-mr-1">
                          <i className="icon icon-add-circle gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle" />
                          <span>World!</span>
                        </span>,
                      ]}>
                      <p>Card content</p>
                      <p>Card content</p>
                      <p>Card content</p>
                    </Card>
                    <Widget>
                      <Tabs defaultActiveKey="1">
                        <TabPane
                          tab={
                            <span>
                              <AppleOutlined />
                              Tab 1
                            </span>
                          }
                          key="1">
                          Tab 1
                        </TabPane>
                        <TabPane
                          tab={
                            <span>
                              <AndroidOutlined />
                              Tab 2
                            </span>
                          }
                          key="2">
                          Tab 2
                        </TabPane>
                      </Tabs>
                    </Widget>
                  </Col>

                  <Col span={24}>
                    <Card
                      title="asdasdsd ad asd sad a"
                      style={{width: 800, height: 400}}
                      bodyStyle={{padding: 0}}>
                      <Tabs tabPosition="top" defaultActiveKey="1">
                        <TabPane tab="Tab 1" key="1">
                          <div className="custom-card">
                            <h3>Chart title nº 1</h3>
                            <div
                              style={{
                                position: 'relative',
                                marginTop: 30,
                                width: 750,
                                height: 250,
                              }}
                              id="chart"
                            />
                          </div>
                        </TabPane>
                        <TabPane tab="Tab 2" key="2">
                          <div className="custom-card">
                            <h3>Chart title nº 2</h3>
                            <div
                              style={{
                                position: 'relative',
                                marginTop: 30,
                                width: 750,
                                height: 250,
                              }}
                              id="chart2"
                            />
                          </div>
                        </TabPane>
                      </Tabs>
                    </Card>
                  </Col>
                  <Col span={6} />
                  <Col span={6} />
                </Row>

                <Auxiliary>
                  <Widget>
                    <Tabs
                      type="card"
                      activeKey={tabActive}
                      onChange={selectTab}>
                      <TabPane tab="My Accounts" key="0">
                        <div>
                          {accountList.length > 0 ? (
                            <List
                              itemLayout="horizontal"
                              dataSource={accountList.sort(compareInstitutions)}
                              renderItem={item => (
                                <List.Item
                                  actions={[
                                    <div className="gx-module-contact-right">
                                      <Dropdown
                                        overlay={() => memberDropdownMenu(item)}
                                        placement="bottomRight"
                                        trigger={['click']}>
                                        <i className="gx-icon-btn icon icon-ellipse-v" />
                                      </Dropdown>
                                    </div>,
                                  ]}>
                                  <List.Item.Meta
                                    avatar={
                                      <Avatar
                                        size={48}
                                        src={`data:image/png;base64,${item.institution.logo}`}
                                      />
                                    }
                                    title={
                                      <span
                                        style={{
                                          fontWeight: 400,
                                          fontSize: '16px',
                                        }}>
                                        {item.institution.name}
                                      </span>
                                    }
                                    description={
                                      <div>
                                        <span
                                          style={{
                                            fontWeight: 400,
                                            color: '#aaa',
                                            fontSize: '14px',
                                          }}>
                                          {item.name} - {item.mask}
                                        </span>
                                        <span
                                          style={{
                                            marginLeft: 10,
                                            fontWeight: 400,
                                            color: '#333',
                                            fontSize: '14px',
                                          }}>
                                          [Current balance:{' '}
                                          {numeral(
                                            item.balances.current,
                                          ).format('$0,0.00')}
                                          ]
                                        </span>
                                        {item.error &&
                                          item.error ===
                                            'ITEM_LOGIN_REQUIRED' && (
                                            <span
                                              style={{
                                                marginLeft: 10,
                                                fontWeight: 300,
                                                color: '#a00',
                                                fontSize: '12px',
                                              }}>
                                              <PlaidMenuLink
                                                token={item.public_token}>
                                                <i
                                                  className="icon icon-exclamation"
                                                  style={{
                                                    marginRight: 5,
                                                    verticalAlign: 'middle',
                                                  }}
                                                />
                                                Account Refresh Required
                                              </PlaidMenuLink>
                                            </span>
                                          )}
                                      </div>
                                    }
                                  />
                                </List.Item>
                              )}
                            />
                          ) : (
                            <h2>
                              You haven't connected any institutions yet. Please
                              go to the "Search institutions" tab to connect
                              your institution now.
                            </h2>
                          )}
                        </div>
                      </TabPane>
                    </Tabs>
                  </Widget>
                  <Drawer
                    title="Update your account credentials"
                    // width={720}
                    keyboard
                    closable={false}
                    destroyOnClose={true}
                    onClose={cancelEdit}
                    visible={showDrawer}>
                    <MemberEdit member={memberEdit} onCancel={cancelEdit} />
                  </Drawer>
                </Auxiliary>
              </div>
            )}
          </div>
        ) : (
          <div className="gx-feature-disabled">
            Feature Flag Disabled by Optimizely
          </div>
        )
      }
    </OptimizelyFeature>
  );
};

export default Accounts;
