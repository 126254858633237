import React from 'react';
import { Avatar, Button } from 'antd/lib';
import numeral from 'numeral';

const SummaryForm = ({ coach, service, date, slot, readonly, appointment, canUpdate, manageButton }) => {
    return (
        <div>
            <div className="gx-d-flex gx-justify-content-around gx-align-items-center gx-mb-3 gx-upperView summaryForm" >
                <Avatar className="gx-size-100 gx-imagePosition" src={coach.avatar} />
                <div className="gx-mb-3 gx-text-center" >
                    <h2 className="gx-textColor" >{coach.username}</h2>
                </div >
            </div>
            <div className="media gx-media-border" >
                <div className="media-body" >
                    <h5 className="mt-0" >BOOKING SUMMARY</h5>
                    <div className="gx-mod-txt" >
                        <div className="gx-contact-item">
                            <div className="gx-module-list-info gx-contact-list-info" style={{ maxWidth: "100%" }}>
                                <div className="gx-module-contact-content">
                                    <div className="gx-mb-2">
                                        <span className="gx-email gx-d-inline-block gx-mr-2">
                                            Service
                                <span className="gx-toolbar-separator">&nbsp;</span>
                                            {service.serviceName}
                                        </span>
                                    </div>
                                    <div className="gx-mb-2">
                                        <span className="gx-email gx-d-inline-block gx-mr-2">
                                            Price
                                <span className="gx-toolbar-separator">&nbsp;</span>
                                            {numeral(service.servicePrice).format('$0,0.00')}
                                        </span>
                                    </div>
                                    <div className="gx-mb-2">
                                        <span className="gx-email gx-d-inline-block gx-mr-2">
                                            Booking Date
                                <span className="gx-toolbar-separator gx-mr-20">&nbsp;</span>
                                            {date}
                                        </span>
                                    </div>
                                    <div className="gx-mb-2">
                                        <span className="gx-email gx-d-inline-block gx-mr-2">
                                            Booking Time
                                             <span className="gx-toolbar-separator">&nbsp;</span>
                                            {slot}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                !readonly ?
                    <div className="gx-txt-created gx-ml-20" >
                        <h4 className="gx-rgt-txt" > Total Booking Charges : {numeral(service.servicePrice).format('$0,0.00')} </h4>
                    </div >
                    :
                    canUpdate === false ? null :
                        <div className="text-center gx-mt-4" >

                            <Button
                                type="primary"
                                onClick={() => manageButton.editMyAppoientment(appointment)}
                            >
                                Update appointment
                            </Button>
                            <Button
                                onClick={() => manageButton.cancelAppointment(appointment)}
                            >
                                Cancel appointment
                            </Button>
                        </div >
            }
        </div>
    );
};

export default SummaryForm;
