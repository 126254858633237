import React, { useState } from 'react';
import {
  useCollectionData,
  useDocumentData,
} from 'react-firebase-hooks/firestore';
import {
  Avatar,
  Card,
  Input,
  Button,
  Menu,
  Badge,
  Dropdown,
  Row,
  Col,
} from 'antd/lib';
import CommentBox from './CommentBox';
import FirebaseSocial from 'firebase/firebaseSocial';
import FirebaseVlogs from 'firebase/firebaseVlogs';
import ReactPlayer from 'react-player';
import DisplayDate from '../../util/DisplayDate';

const { TextArea } = Input;

// const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;

const VlogDetail = ({
  vlog,
  profile,
  userId,
  currentUserId,
  backToList,
  userTags,
  likeUnlikeVideo,
  onInfoUpdateClick,
  onDeleteClick,
}) => {

  const [message, setMessage] = useState(null);
  const isEnabled = message === '';
  const options = ['Edit', 'Delete'];

  const [current] = useDocumentData(
    FirebaseSocial.getUserDocumentData(FirebaseSocial.auth.currentUser.uid),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );

  const [comments] = useCollectionData(
    FirebaseVlogs.getVlogsComments({ id: vlog.id, userId: userId }),
    {
      idField: 'id',
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  let menus = () => (
    <Menu
      onClick={e => {
        if (e.key === 'Edit') {
          onInfoUpdateClick(vlog);
        } else {
          onDeleteClick(vlog);
        }
      }}>
      {options.map(option => (
        <Menu.Item key={option}>{option}</Menu.Item>
      ))}
    </Menu>
  );

  const addComment = async () => {
    const commentData = {
      user: currentUserId,
      comment: message,
      likes: [],
      date: new Date().toString(),
    };
    await FirebaseVlogs.commentVlog({ id: vlog.id, userId: userId }, commentData);
    setMessage('');
  };

  const updateCommentValue = evt => {
    setMessage(evt.target.value);
  };

  // if (!postLikes) {
  //     return <Spin indicator={spinnerIcon} />;
  // }

  return (
    <div>
      {vlog && current && (
        <Card className="gx-card">
          <Row>
            <Col span={12}>
              <div className="">
                <i
                  className="icon icon-arrow-left gx-icon-btn"
                  onClick={backToList}
                />
              </div>
            </Col>
            <Col span={12}>
              {currentUserId === userId ? (
                <div>
                  <Dropdown
                    className="gx-ml-auto"
                    overlay={menus}
                    placement="topRight"
                    trigger={['click']}>
                    <i className="gx-icon-btn icon icon-ellipse-v" />
                  </Dropdown>
                </div>
              ) : null}
            </Col>
          </Row>
          <div className="gx-wall-content">
            <div className="gx-media gx-wall-user-info gx-flex-nowrap gx-align-items-center">
              <Avatar
                className="gx-mr-3 gx-mb-2 gx-size-50"
                src={profile.avatar}
              />
              <div className="gx-media-body">
                <h5 className="gx-wall-user-title">{profile.username}</h5>
                <DisplayDate date={vlog.date.toString()} />
              </div>
            </div>
            <p>
              <ReactPlayer
                url={vlog.video}
                width="100%"
                controls={true}
                //light="http://placekitten.com/200/300"
                style={{ maxHeight: '600px' }}
              />
            </p>
            <h3>{vlog.title}</h3>
            <p>{vlog.description}</p>
            <p>
              <span>
                {vlog &&
                  vlog.tags &&
                  vlog.tags.map((label, index) => {
                    if (userTags && userTags.length) {
                      for (var tags of userTags) {
                        if (tags.tag.title === label.title) {
                          label = tags.tag;
                          break;
                        }
                      }
                    }
                    return (
                      <Badge
                        key={index}
                        count={label.title}
                        style={{ backgroundColor: label.color }}
                      />
                    );
                  })}
              </span>
            </p>
            <div className="gx-flex-row gx-mb-2 gx-mb-xl-3">
              <p
                className="gx-fs-sm gx-pointer gx-mr-3 gx-text-grey"
              >
                {vlog.likes.includes(currentUserId) ? (
                  <i
                    className="icon icon-like gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle"
                    style={{ color: 'primary' }}
                    onClick={() => likeUnlikeVideo(vlog)}
                  />
                ) : (
                    <i
                      className="icon icon-like-o gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle"
                      onClick={() => likeUnlikeVideo(vlog)}
                    />
                  )}
                <span className="gx-d-inline-flex gx-vertical-align-middle">
                  {vlog.likes.length > 1
                    ? `${vlog.likes.length} Likes`
                    : `${vlog.likes.length} Like`}
                </span>
              </p>
              <p className="gx-fs-sm gx-pointer gx-mr-3 gx-text-grey">
                <i className="icon icon-chat-bubble gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle" />
                <span className="gx-d-inline-flex gx-vertical-align-middle">
                  {comments
                    ? comments.length > 1
                      ? `${comments.length} Comments`
                      : `${comments.length} Comment`
                    : `0 Comment`}
                </span>
              </p>
            </div>

            <div className="gx-wall-comment-box">
              {comments && comments.length > 0
                ? comments.map(comment => (
                  <CommentBox
                    vlog={vlog}
                    commentData={comment}
                    userId={userId}
                    currentUserId={currentUserId}
                    key={comment.id}
                  />
                ))
                : null}
            </div>
            <div className="gx-wall-comment-box">
              <div className="gx-media gx-mb-2">
                <Avatar className="gx-mr-3 gx-size-32" src={profile.avatar} />
                <div className="gx-media-body">
                  <TextArea
                    value={message}
                    rows={4}
                    onChange={event => updateCommentValue(event)}
                    placeholder="Leave a Comment"
                  />
                </div>
              </div>

              <div className="ant-row-flex">
                <Button
                  type="primary"
                  className="gx-ml-auto gx-mb-0"
                  disabled={isEnabled ? 'disabled' : ''}
                  onClick={addComment}>
                  Add Comment
                </Button>
              </div>
            </div>
          </div>
        </Card>
      )}
    </div>
  );
};

export default VlogDetail;
