import React, { useState, useEffect } from 'react';
import moment from 'moment';
import LoanStatistics from '../LoanStatistics';
import { Loan, LoanTermCalculator } from 'loanpalz-constants';
import { schemeTableau10 } from 'd3-scale-chromatic';

const colors = schemeTableau10;
const dayFormat = 'D-MMM-YYYY';

const ProcessLoans = ({ profile, loans }) => {
    const [processedLoans, setProcessedLoans] = useState(null);

    useEffect(() => {
        let _loans = [];
        loans && loans.map((loan, index) => {
            let term = loan.term;
            if (term === 0 && loan.minimumPayment > 0) {
                term = new LoanTermCalculator(loan.interest, loan.currentBalance, loan.minimumPayment).calculateLoanTerms();
            }
            let newLoan = new Loan(
                loan.interest,
                term,
                loan.currentBalance,
                moment(new Date(loan.startDate * 1000), dayFormat).format(dayFormat),
                0,
                [],
                loan.name,
                loan.color ? loan.color : colors[index],
            );
            newLoan.id = loan.id;
            newLoan.type = loan.type;
            newLoan.lenderName = loan.lenderName || '';
            newLoan.term = loan.term;
            newLoan.originalLoanAmount = loan.originalLoanAmount;
            newLoan.currentBalance = loan.currentBalance;
            newLoan.interest = loan.interest;

            _loans.push(newLoan);
            return newLoan;
        })
        setProcessedLoans(_loans);

    }, [loans]);

    return (
        <>
            {loans && processedLoans ?
                <LoanStatistics
                    profile={profile}
                    loans={processedLoans}
                    initCal={true}
                />
                :
                null
            }
        </>
    );
};

export default ProcessLoans;
