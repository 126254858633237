import {
  DISPLAY_MESSAGE
} from '../../constants/ActionTypes';

const initialState = {
  type: DISPLAY_MESSAGE,
  message: null,
  level: null
}

export default (state = initialState, { type, message, level }) => {
  switch (type) {
    case DISPLAY_MESSAGE:
      return {
        type: DISPLAY_MESSAGE,
        message,
        level
      }

    default:
      return state
    }
}
