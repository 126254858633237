import {useFormatMessage} from '@comparaonline/react-intl-hooks';
import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Spin,
  Table,
} from 'antd/lib';
import Widget from 'components/Widget/index';
import {FirebaseDAO} from 'firebase/firebase';
import numeral from 'numeral';
import React, {useState} from 'react';
import {useCollectionData} from 'react-firebase-hooks/firestore';
import {FaSpinner} from 'react-icons/fa';
import IntlMessages from 'util/IntlMessages';
import './checklist.less';

const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;
const {confirm} = Modal;
const {Option} = Select;
const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
  },
};

const IncomeStep = props => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return (
          <span
            className="linktext"
            onClick={() => {
              onEditIncome(record);
            }}>
            {text}
          </span>
        );
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) => {
        return (
          <span
            className="linktext"
            href
            onClick={() => {
              onEditIncome(record);
            }}>
            {numeral(text).format('$0,0.00')}
          </span>
        );
      },
    },
    {
      title: 'Frequency',
      dataIndex: 'frequency',
      key: 'frequency',
      render: (text, record) => {
        let frequencyText = '';
        switch (text) {
          case 'weekly':
            frequencyText = 'Once a week';
            break;
          case 'twiceamonth':
            frequencyText = 'Twice a month';
            break;
          case 'onceamonth':
            frequencyText = 'Once a month';
            break;
          case 'onceaquarter':
            frequencyText = 'Once a quarter';
            break;
          case 'onceayear':
            frequencyText = 'Once a year';
            break;
          default:
            break;
        }
        return (
          <span
            className="linktext"
            onClick={() => {
              onEditIncome(record);
            }}>
            {frequencyText}
          </span>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: values => {
        return (
          <span>
            <i
              className="icon icon-edit gx-pointer gx-text-primary gx-fs-lg gx-d-inline-flex gx-vertical-align-middle gx-mr-0"
              onClick={() => onEditIncome(values)}
            />
            &emsp;
            <i
              className="icon icon-trash gx-pointer gx-text-danger gx-fs-lg gx-d-inline-flex gx-vertical-align-middle gx-mr-1"
              onClick={() => onDeleteCurrentIncome(values)}
            />
          </span>
        );
      },
    },
  ];

  const [currentIncome, setCurrentIncome] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [formMode, setFormMode] = useState('add');
  const [form] = Form.useForm();
  const intlMessages = useFormatMessage();
  const endCollection = 'incomes';
  const [incomes, loading, error] = useCollectionData(
    FirebaseDAO.getFBCollectionData(endCollection),
    {
      idField: 'id',
      snapshotListenOptions: {includeMetadataChanges: true},
    },
  );

  const onFormSubmit = async () => {
    let formValues = form.getFieldsValue(['name', 'amount', 'frequency']);

    setShowModal(false);
    if (formMode === 'add') {
      message.success(intlMessages('wizard.financials.income.add.success'));
      await FirebaseDAO.addFBData(formValues, endCollection);
    } else if (formMode === 'edit') {
      message.success(intlMessages('wizard.financials.income.update.success'));
      let {id} = form.getFieldsValue(['id']);
      formValues = {
        ...formValues,
        id,
      };
      await FirebaseDAO.updateFBData(formValues, endCollection);
      currentIncome
        ? FirebaseDAO.getFBData(id, endCollection)
            .get()
            .then(doc => setCurrentIncome({...doc.data(), id: doc.id}))
        : console.log('no Income to set');
      setFormMode('add');
    }
    form.resetFields();
  };

  const onDeleteCurrentIncome = Income => {
    confirm({
      title: 'Are you sure you want to delete this Income?',
      onOk: () => {
        deleteCurrentIncome(Income);
      },
      onCancel() {},
    });
  };

  const onEditIncome = income => {
    setFormMode('edit');
    form.resetFields();
    form.setFieldsValue({
      ...income,
    });
    setShowModal(true);
  };

  const deleteCurrentIncome = async income => {
    await FirebaseDAO.deleteMultipleFBData([income.id], endCollection);
    setCurrentIncome(null);
    message.success(intlMessages('wizard.financials.income.delete.success'));
  };

  const handleResetFormModal = () => {
    setShowModal(false);
    form.resetFields();
  };

  const handleSubmitValidation = () => {
    form.validateFields((err, values) => {
      if (err) {
        console.log('Form validation failed');
      }
    });
  };

  return (
    <div>
      <Widget
        title={
          <h2 className="h4 gx-text-capitalize gx-mb-0 gx-text-left  gx-ml-3">
            Sources of Income
          </h2>
        }
        extra={
          <p
            className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block  gx-mr-3"
            onClick={() => setShowModal(true)}>
            <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle gx-mr-1" />
            Add Income
          </p>
        }>
        {error && <strong>Error: {JSON.stringify(error)}</strong>}
        {loading && <Spin indicator={spinnerIcon} />}
        {incomes && (
          <Table
            className="gx-table-no-bordered"
            rowClassName="Income-table-row"
            columns={columns}
            dataSource={incomes}
            pagination={false}
            size="small"
            rowKey="name"
            locale={{
              emptyText: (
                <div className="gx-mt-3">
                  <h2>You don't have any incomes yet. Let's add one!</h2>
                  <Button
                    variant="raised"
                    className="gx-mt-3"
                    type="primary"
                    onClick={() => setShowModal(true)}>
                    <div className="ant-row-flex">
                      <IntlMessages id="wizard.financials.income.addBtn" />
                    </div>
                  </Button>
                </div>
              ),
            }}
          />
        )}
      </Widget>

      <Modal
        title={
          formMode === 'add' ? (
            <IntlMessages id="wizard.financials.income.addBtn" />
          ) : (
            <IntlMessages id="wizard.financials.income.updateBtn" />
          )
        }
        // toggle={onContactClose}
        visible={showModal}
        closable={true}
        onOk={() => form.submit()}
        onSubmit={() => handleSubmitValidation()}
        onCancel={handleResetFormModal}>
        <Form
          {...formItemLayout}
          form={form}
          onSubmit={handleSubmitValidation}
          onFinish={onFormSubmit}>
          <div className="gx-modal-box-row">
            <div className="gx-modal-box-form-item">
              <div className="gx-form-group">
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      type: 'string',
                      message: 'Please enter an Income name',
                    },
                  ]}>
                  <Input required placeholder="Income name" />
                </Form.Item>
              </div>
              <div className="gx-form-group">
                <Form.Item
                  inline
                  name="amount"
                  label="Amount"
                  rules={[
                    {
                      required: true,
                      type: 'integer',
                      message: 'Please enter a valid number',
                    },
                  ]}>
                  <InputNumber
                    onChange={() => {}}
                    formatter={value =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    placeholder="Amount"
                    style={{width: '100%'}}
                  />
                </Form.Item>
              </div>
              <div className="gx-form-group">
                <Form.Item
                  label="Frequency"
                  name="frequency"
                  rules={[
                    {
                      required: true,
                      type: 'string',
                      message: 'Please select an Income frequency',
                    },
                  ]}>
                  <Select
                    style={{width: '100%'}}
                    onChange={() => {}}
                    placeholder="Select a Income frequency">
                    <Option value="weekly">Once a week</Option>
                    <Option value="onceamonth">Once a month</Option>
                    <Option value="twiceamonth">Twice a month</Option>
                    <Option value="onceaquarter">Once a quarter</Option>
                    <Option value="onceayear">Once a year</Option>
                  </Select>
                </Form.Item>
              </div>
              <Button htmlType="submit" style={{display: 'none'}}></Button>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default IncomeStep;
