export const settingsSiteReason = {
  root: 'finance',
  node: 'reason',
  options: [
    {
      key: 'track',
      label: 'Track my spending and pay my debt faster',
    },
    {
      key: 'network',
      label: 'Network with the community',
    },
    {
      key: 'coach',
      label: 'Get help from professional coaches',
    },
  ],
};

export const settingsMoneyReason = {
  root: 'finance',
  node: 'goals',
  options: [
    {
      key: 'debt',
      label: 'Pay Off Debt',
    },
    {
      key: 'retirement',
      label: 'Save for Retirement',
    },
    {
      key: 'paytopay',
      label: 'Stop Living Pay to Pay',
    },
    {
      key: 'college',
      label: 'Pay for Kids College',
    },
    {
      key: 'home',
      label: 'Save for a Home',
    },
    {
      key: 'travel',
      label: 'Travel',
    },
  ],
};

export const settingsBasicInfo = {
  root: 'finance',
  node: 'info',
  options: [
    {
      key: 'home',
      label: 'I Own a Home',
    },
    {
      key: 'rent',
      label: 'I Rent',
    },
    {
      key: 'car',
      label: 'I Own a Car',
    },
    {
      key: 'married',
      label: 'I am Married',
    },
    {
      key: 'kids',
      label: 'I Have Kids',
    },
    {
      key: 'pets',
      label: 'I Have Pets',
    },
  ],
};

export const accountTypes = [
  // Banking accounts
  {
    value: 'banking',
    label: 'Banking',
    class: 'cash',
    classLabel: 'Cash/MMA/CD',
    children: [
      {
        value: 'checking',
        label: 'Checking',
      },
      {
        value: 'savings',
        label: 'Savings',
      },
      {
        value: 'moneymarket',
        label: 'Money Market',
      },
      {
        value: 'cd',
        label: 'Certificate of Deposit (CD)',
      },
    ],
  },

  // Investment accounts
  {
    value: 'equities',
    label: 'Equities (Stocks)',
    class: 'equities',
    classLabel: 'Equities (Stocks)',
    children: [
      {
        value: 'stocks',
        label: 'Stocks',
      },
    ],
  },

  // Fixed income accounts
  {
    value: 'fixedincome',
    label: 'Fixed Income (Bonds)',
    class: 'fixedincome',
    classLabel: 'Fixed Income (Bonds)',
    children: [
      {
        value: 'bonds',
        label: 'Bonds',
      },
    ],
  },

  // Retirement accounts
  {
    value: 'retirement',
    label: 'Retirement',
    class: 'retirement',
    classLabel: 'Retirement (IRA/401K/Pension plans)',
    children: [
      {
        value: 'ira',
        label: 'IRA',
      },
      {
        value: 'rothira',
        label: 'Roth IRA',
      },
      {
        value: 'sepira',
        label: 'Sep IRA',
      },
      {
        value: 'fouronek',
        label: '401K',
      },
      {
        value: 'pension',
        label: 'Pension Plan',
      },
    ],
  },

  // Credit cards
  {
    value: 'creditcards',
    label: 'Credit Cards',
    class: 'creditcards',
    classLabel: 'Credit Cards',
    children: [
      {
        value: 'creditcard',
        label: 'Credit Card',
      },
      {
        value: 'storecredit',
        label: 'Store Credit Card',
      },
      {
        value: 'layaway',
        label: 'Layaway',
      },
    ],
  },

  // Loans
  {
    value: 'loans',
    label: 'Loans',
    class: 'loans',
    classLabel: 'Loans',
    children: [
      {
        value: 'studentloan',
        label: 'Student Loan',
      },
      {
        value: 'autoloan',
        label: 'Auto Loan',
      },
      {
        value: 'personalloan',
        label: 'Personal Loan',
      },
    ],
  },

  // Mortgage
  {
    value: 'mortgages',
    label: 'Mortgages',
    class: 'mortgages',
    classLabel: 'Mortgages',
    children: [
      {
        value: 'primarymortgage',
        label: 'Primary Mortgage',
      },
      {
        value: 'investmentmortgage',
        label: 'Invetment Property Mortgage',
      },
    ],
  },

  // Real estate
  {
    value: 'realestate',
    label: 'Real Estate',
    class: 'realestate',
    classLabel: 'Real Estate',
    children: [
      {
        value: 'primaryhome',
        label: 'Primary Home',
      },
      {
        value: 'investmenthome',
        label: 'Investment Home',
      },
      {
        value: 'rentalproperty',
        label: 'Rental Property',
      },
      {
        value: 'land',
        label: 'Land',
      },
    ],
  },

  // Business interests
  {
    value: 'businessinterests',
    label: 'Business Interests',
    class: 'businessinterests',
    classLabel: 'Business Interests',
    children: [
      {
        value: 'startup',
        label: 'Startup',
      },
      {
        value: 'shares',
        label: 'Business Shares',
      },
      {
        value: 'private',
        label: 'Private Business',
      },
      {
        value: 'hedge',
        label: 'Hedge Fund',
      },
    ],
  },

  // Other
  {
    value: 'other',
    label: 'Other',
    class: 'other',
    classLabel: 'Other',
    children: [
      {
        value: 'inheritance',
        label: 'Inheritance',
      },
      {
        value: 'other',
        label: 'Other',
      },
    ],
  },
];
