import React, { useState } from 'react';
import { Avatar, Button, Drawer, Input, Tabs, Modal } from 'antd/lib';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import FirebaseMessages from 'firebase/firebaseMessages';
import FirebaseSocial from 'firebase/firebaseSocial';
import CustomScrollbars from 'util/CustomScrollbars';
import CustomScrollbarsScrollToBottom from 'util/CustomScrollbarsScrollToBottom';
import Moment from 'moment';
import ChatUserList from 'components/chat/ChatUserList';
import Conversation from 'components/chat/Conversation/index';
import ContactList from 'components/chat/ContactList/index';
import IntlMessages from 'util/IntlMessages';
import MessageSearchBox from 'components/MessageSearchBox';
import CircularProgress from 'components/CircularProgress/index';

const TabPane = Tabs.TabPane;
const userNotFound = 'No user found';
const { confirm } = Modal;

const Message = props => {
  const { profile } = props;
  const currentUserId = FirebaseMessages.auth.currentUser.uid;
  const [keyword, setKeyword] = useState(null);
  const [message, setMessage] = useState(null);
  const [chatThreadId, setChatThreadId] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loader, setLoader] = useState(false);
  const [drawerState, setDrawerState] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(1); // eslint-disable-line
  const [userState, setUserState] = useState(1);
  const [messagesEnd, setMessagesEnd] = useState(null);
  const [selectFriend, setSelectFriend] = useState(true);
  const [selectedSectionId, setSelectedSectionId] = useState(null);
  const [offset, setOffset] = useState(0); // eslint-disable-line
  const [limit, setLimit] = useState(50); // eslint-disable-line

  const filterChatUser = searchKeyword => {
    setKeyword(searchKeyword);
  };

  const [friendOneLook] = useCollectionData(
    FirebaseSocial.getUserFriendOne(currentUserId),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [friendTwoLook] = useCollectionData(
    FirebaseSocial.getUserFriendTwo(currentUserId),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  // Combine friends
  let contactList = [];

  if (friendOneLook && friendTwoLook) {

    friendOneLook.forEach(friendPair => {
      contactList.push(friendPair.friendOne);
    });

    friendTwoLook.forEach(friendPair => {
      contactList.push(friendPair.friendTwo);
    });
  }

  const [chatUsers] = useCollectionData(
    FirebaseMessages.getChatInbox(currentUserId, keyword),
    {
      idField: 'id',
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [conversation] = useCollectionData(
    FirebaseMessages.getLiveMessages({
      threadId: chatThreadId,
      friendId: selectedSectionId,
      offset: offset,
      limit: limit,
    }),
    {
      idField: 'id',
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const hashThread = str => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash += Math.pow(str.charCodeAt(i) * 31, str.length - i);
      hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  };

  const onToggleDrawer = () => {
    setDrawerState(!drawerState);
  };

  const onSelectUser = async friend => {
    let threadId = `${friend.id}-${currentUserId}`;
    if (hashThread(currentUserId) <= hashThread(friend.id)) {
      threadId = `${currentUserId}-${friend.id}`;
    }

    //let listMessage = await FirebaseMessages.getMessages({ threadId: threadId, friendId: friend.id });
    setChatThreadId(threadId);
    setLoader(true);
    setSelectedSectionId(friend.id);
    setSelectedUser(friend);
    setDrawerState(false);
    setTimeout(() => {
      setLoader(false);
      setSelectFriend(true);
    }, 1000);
  };

  const startConversation = friendId => {
    FirebaseMessages.startConversation(friendId);
  };

  if (selectFriend) {
    if (props.location.selectFriend) {
      let friend = props.location.selectFriend;
      onSelectUser(friend);
      setSelectFriend(false);
      delete props.location.selectFriend;
    }
  }

  const scrollToBottom = () => {
    messagesEnd.scrollIntoView({ behavior: 'smooth' });
  };

  const processClearThread = async threadId => {
    await FirebaseMessages.clearThreadMessage({
      threadId: threadId,
      friendId: selectedUser.id,
    });
  };

  const clearThreadMessage = threadId => {
    confirm({
      title: 'Are you sure you want to clear chat history?',
      onOk: () => {
        processClearThread(threadId);
      },
      onCancel() { },
    });
  };

  const updateSearchChatUser = evt => {
    let search = evt.target.value;
    setKeyword(search);
  };

  const Communication = () => {
    return (
      <div className="gx-chat-main">
        <div className="gx-chat-main-header">
          <span className="gx-d-block gx-d-lg-none gx-chat-btn">
            <i className="icon icon-menu gx-icon-btn"
              aria-label="Menu"
              onClick={() => onToggleDrawer()}
            />
          </span>

          <div className="gx-chat-main-header-info">
            <div className="gx-chat-avatar gx-mr-2">
              <div className="gx-status-pos">
                <Avatar
                  src={selectedUser.avatar}
                  className="gx-rounded-circle gx-size-60"
                  alt=""
                />
                <span className={`gx-status gx-${selectedUser.status}`} />
              </div>
            </div>
            <div className="gx-chat-contact-name">{selectedUser.username}</div>
          </div>

          <div className="gx-flex-row gx-justify-content-between">
            <i
              className="gx-icon-btn icon icon-trash"
              onClick={() => {
                clearThreadMessage(chatThreadId);
              }}
            />
          </div>
        </div>

        <CustomScrollbarsScrollToBottom className="gx-chat-list-scroll">
          <Conversation
            conversationData={conversation.reverse()}
            selectedUser={selectedUser}
            currentProfile={profile}
          />
          <div
            style={{ float: 'left', clear: 'both' }}
            ref={el => setMessagesEnd(el)}></div>
        </CustomScrollbarsScrollToBottom>

        <div className="gx-chat-main-footer">
          <div
            className="gx-flex-row gx-align-items-center"
            style={{ maxHeight: 51 }}>
            <div className="gx-col">
              <div className="gx-form-group">
                <textarea
                  id="required"
                  className="gx-border-0 ant-input gx-chat-textarea"
                  onKeyUp={_handleKeyPress}
                  onChange={updateMessageValue}
                  value={message || ''}
                  placeholder="Type and hit enter to send message"
                />
              </div>
            </div>
            <i
              className="gx-icon-btn icon icon-sent"
              onClick={() => sendMessage}
            />
          </div>
        </div>
      </div>
    );
  };

  const AppUsersInfo = () => {
    return (
      <div className="gx-chat-sidenav-main">
        <div className="gx-bg-grey-light gx-chat-sidenav-header">
          <div className="gx-chat-user-hd gx-mb-0">
            <i
              className="gx-icon-btn icon icon-arrow-left"
              onClick={() => setUserState(1)}
            />
          </div>
          <div className="gx-chat-user gx-chat-user-center">
            <div className="gx-chat-avatar gx-mx-auto">
              <Avatar
                src={profile.avatar}
                className="gx-size-60"
                alt="John Doe"
              />
            </div>

            <div className="gx-user-name h4 gx-my-2">{profile.username}</div>
          </div>
        </div>
        <div className="gx-chat-sidenav-content">
          <CustomScrollbars className="gx-chat-sidenav-scroll">
            <div className="gx-p-4">
              <form>
                <div className="gx-form-group gx-mt-4">
                  <label>Mood</label>

                  <Input
                    fullWidth
                    id="exampleTextarea"
                    multiline
                    rows={3}
                    onKeyUp={_handleKeyPress}
                    onChange={updateMessageValue}
                    defaultValue="it's a status....not your diary..."
                    placeholder="Status"
                    margin="none"
                  />
                </div>
              </form>
            </div>
          </CustomScrollbars>
        </div>
      </div>
    );
  };

  const ChatUsers = () => {
    return (
      <div className="gx-chat-sidenav-main">
        <div className="gx-chat-sidenav-header">
          <div className="gx-chat-user-hd">
            <div
              className="gx-chat-avatar gx-mr-3"
              onClick={() => setUserState(2)}>
              <div className="gx-status-pos">
                <Avatar
                  id="avatar-button"
                  src={profile.avatar}
                  className="gx-size-50"
                  alt=""
                />
                <span className="gx-status gx-online" />
              </div>
            </div>

            <div className="gx-module-user-info gx-flex-column gx-justify-content-center">
              <div className="gx-module-title">
                <h5 className="gx-mb-0">{profile.username}</h5>
              </div>
              <div className="gx-module-user-detail">
                <span className="gx-text-grey gx-link">{profile.email}</span>
              </div>
            </div>
          </div>

          <div className="gx-chat-search-wrapper">
            <MessageSearchBox
              styleName="gx-chat-search-bar gx-lt-icon-search-bar-lg"
              placeholder="Search or start new chat"
              onChange={updateSearchChatUser}
              filterChatUser={filterChatUser}
            />
          </div>
        </div>

        <div className="gx-chat-sidenav-content">
          {/*<AppBar position="static" className="no-shadow chat-tabs-header">*/}
          <Tabs className="gx-tabs-half" defaultActiveKey="1">
            <TabPane
              label={<IntlMessages id="chat.chatUser" />}
              tab={<IntlMessages id="chat.chatUser" />}
              key="1">
              <CustomScrollbars className="gx-chat-sidenav-scroll-tab-1">
                {chatUsers && chatUsers.length === 0 ? (
                  <div className="gx-p-5">{userNotFound}</div>
                ) : (
                    <ChatUserList
                      chatUsers={chatUsers}
                      selectedSectionId={selectedSectionId}
                      onSelectUser={onSelectUser}
                    />
                  )}
              </CustomScrollbars>
            </TabPane>
            <TabPane
              label={<IntlMessages id="chat.contacts" />}
              tab={<IntlMessages id="chat.contacts" />}
              key="2">
              <CustomScrollbars className="gx-chat-sidenav-scroll-tab-2">
                {contactList && contactList.length === 0 ? (
                  <div className="gx-p-5">{userNotFound}</div>
                ) : (
                    <ContactList
                      contactList={contactList}
                      selectedSectionId={selectedSectionId}
                      onSelectUser={onSelectUser}
                      startConversation={startConversation}
                    />
                  )}
              </CustomScrollbars>
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  };

  const _handleKeyPress = e => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  // eslint-disable-next-line
  const handleChange = (event, value) => {
    setSelectedTabIndex(value);
  };

  // eslint-disable-next-line
  const handleChangeIndex = index => {
    setSelectedTabIndex(index);
  };

  const showCommunication = () => {
    return (
      <div className="gx-chat-box">
        {selectedUser === null ? (
          <div className="gx-comment-box">
            <div className="gx-fs-80">
              <i className="icon icon-chat gx-text-muted" onClick={() => onToggleDrawer()} />
            </div>
            <h1 className="gx-text-muted">
              {<IntlMessages id="chat.selectUserChat" />}
            </h1>
            <Button
              className="gx-d-block gx-d-lg-none hems"
              type="primary"
              onClick={() => onToggleDrawer()} >
              {<IntlMessages id="chat.selectContactChat" />}
            </Button>
          </div>
        ) : (
            Communication()
          )}
      </div>
    );
  };

  const sendMessage = async () => {
    if (message.trim() !== '') {
      const timestamp = Moment()
        .valueOf()
        .toString();
      let messageObj = {
        senderId: currentUserId,
        receiverId: selectedUser.id,
        sentAt: new Date().toString(),
        message: message.trim(),
        type: '1', // Text message
        timestamp: timestamp,
      };
      await FirebaseMessages.saveMessage({
        messageObject: messageObj,
        threadId: chatThreadId,
        timestamp: timestamp,
      });
      //let listMessage = await FirebaseMessages.getMessages({ threadId: chatThreadId, friendId: selectedUser.id });
      setMessage('');
      scrollToBottom();
    }
  };

  const updateMessageValue = evt => {
    setMessage(evt.target.value);
  };

  return (
    <div className="-main-contegxnt">
      <div className="gx-app-module gx-chat-module">
        <div className="gx-chat-module-box">
          <div className="gx-d-block gx-d-lg-none">
            <Drawer
              placement="left"
              closable={false}
              visible={drawerState}
              onClose={onToggleDrawer}>
              {userState === 1 ? ChatUsers() : AppUsersInfo()}
            </Drawer>
          </div>
          <div className="gx-chat-sidenav gx-d-none gx-d-lg-flex">

            {userState === 1 ? ChatUsers() : AppUsersInfo()}
          </div>
          {loader ? (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          ) : (
              showCommunication()
            )}
        </div>
      </div>
    </div>
  );
};

export default Message;
