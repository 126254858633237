const config = {
  apiKey: 'AIzaSyAb4Ng_FOJfcyXFcbOIM2gpsykcvHSCKJw',
  authDomain: 'gspfa-6abfd.firebaseapp.com',
  databaseURL: 'https://gspfa-6abfd.firebaseio.com',
  projectId: 'gspfa-6abfd',
  storageBucket: 'gspfa-6abfd.appspot.com',
  messagingSenderId: '1062346192395',
  appId: '1:1062346192395:web:f58a81c584667aabd0f90e',
  measurementId: 'G-0XYSBX3L51',
  ALGOLIA_APP_ID: 'F5ZAQ42GIV',
  ALGOLIA_USER_SEARCH_KEY: 'c086c9ca13388f4a0ddc1f6e9528d446',
};

export default config;
