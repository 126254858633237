import React from 'react';
import {
  FaGooglePlusSquare,
  FaFacebookSquare,
  FaTwitterSquare,
  FaApple,
} from 'react-icons/fa';
import {Tooltip, message} from 'antd/lib';
import FirebaseAuth from 'firebase/firebaseAuth';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';

const SocialSignIn = () => {
  const intlMessages = useFormatMessage();
  return (
    <ul className="gx-social-link">
      <li>
        <Tooltip placement="bottom" title="Sign in with Facebook">
          <FaFacebookSquare
            size="3em"
            className="gx-social-icon"
            onClick={async () => {
              await FirebaseAuth.signInWithFacebook();
              message.success(intlMessages('appModule.loginSuccessfully'));
            }}
          />
        </Tooltip>
      </li>
      <li>
        <Tooltip placement="bottom" title="Sign in with Twitter">
          <FaTwitterSquare
            size="3em"
            className="gx-social-icon"
            onClick={() => {
              FirebaseAuth.signInWithFacebook();
            }}
          />
        </Tooltip>
      </li>
      <li>
        <Tooltip placement="bottom" title="Sign in with Apple">
          <FaApple
            size="3em"
            className="gx-social-icon"
            onClick={() => {
              FirebaseAuth.signInWithFacebook();
            }}
          />
        </Tooltip>
      </li>
      <li>
        <Tooltip placement="bottom" title="Sign in with Google">
          <FaGooglePlusSquare
            size="3em"
            className="gx-social-icon"
            onClick={() => {
              FirebaseAuth.signInWithFacebook();
            }}
          />
        </Tooltip>
      </li>
    </ul>
  );
};

export default SocialSignIn;
