import {Button} from 'antd/lib';
import PropTypes from 'prop-types';
import React from 'react';
import {withWizard} from 'react-albus';
import {FaChevronLeft, FaChevronRight} from 'react-icons/fa';
import IntlMessages from 'util/IntlMessages';

const ButtonGroup = Button.Group;
const BottomNavigation = ({wizard, prev, done, skip}) => {
  const onNext = async () => {
    if (wizard.history.index + 1 >= wizard.steps.length) {
      return;
    }
    wizard.next();
  };

  const onPrev = async () => {
    if (wizard.history.index - 1 >= wizard.steps.length) {
      return;
    }
    wizard.previous();
  };

  return (
    <div>
      {!done ? (
        <ButtonGroup className="gx-mt-3">
          {prev && (
            <Button onClick={onPrev}>
              <div className="ant-row-flex">
                <FaChevronLeft size="12" style={{margin: 'auto'}} />
                <IntlMessages id="wizard.prev" />
              </div>
            </Button>
          )}
          <Button type="primary" onClick={onNext}>
            <div className="ant-row-flex">
              <IntlMessages id="wizard.next" />
              <FaChevronRight size="12" style={{margin: 'auto'}} />
            </div>
          </Button>
        </ButtonGroup>
      ) : (
        <Button type="primary" onClick={skip}>
          <div className="ant-row-flex">
            <IntlMessages id="wizard.continue" />
          </div>
        </Button>
      )}
    </div>
  );
};

BottomNavigation.propTypes = {
  wizard: PropTypes.instanceOf(Object).isRequired,
  prev: PropTypes.bool.isRequired,
  done: PropTypes.bool,
  skip: PropTypes.func,
};

BottomNavigation.defaultProps = {
  skip: Function.prototype,
  done: false,
};

export default withWizard(BottomNavigation);
