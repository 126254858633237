import React, {useState, useEffect} from 'react';
import {useDocumentData} from 'react-firebase-hooks/firestore';
import {Link} from 'react-router-dom';
import ShowMore from '@tedconf/react-show-more';
import ThreadComment from '../../routes/app/forum/ThreadComment';
import {Button, Row} from 'antd';
import CommentBox from 'components/profile/Posts/CommentBox';
import FirebaseSocial from 'firebase/firebaseSocial';
import {FirebasePostsServices} from 'loanpalz-constants';
import FirebaseSetup from 'firebase/firebaseCommon';
import _ from 'lodash';

FirebaseSetup();

function sortDeepArray(arr) {
  return arr.sort((a, b) => {
    let aTime = new Date(a.date).getTime();
    let bTime = new Date(b.date).getTime();
    if (aTime < bTime) {
      return 1;
    }
    if (aTime > bTime) {
      return -1;
    }
    return 0;
  });
}

const CommentDisplayDiv = ({
  detailPage,
  threadInfo,
  commentArray,
  commentCount,
  comments,
  postInfo,
  groupId,
}) => {
  const [numCommentsDisplayed, setNumCommentsDisplayed] = useState(3);
  const [displayedComments, setDisplayedComments] = useState(
    sortDeepArray(commentArray),
  );

  const [user, userLoading, userError] = useDocumentData(
    FirebaseSocial.getUserDocumentData(FirebaseSocial.auth.currentUser.uid),
    {
      snapshotListenOptions: {includeMetadataChanges: true},
    },
  );

  useEffect(() => {
    async function updateComments() {
      if (numCommentsDisplayed > 3) {
        let tempCommentCollection = await FirebasePostsServices.getPostComments(
          {
            postId: postInfo.id,
          },
        );
        setDisplayedComments(
          _.take(tempCommentCollection[1], numCommentsDisplayed),
        );
      } else {
        setDisplayedComments(commentArray);
      }
    }

    updateComments();
  }, [postInfo, commentArray, numCommentsDisplayed]);

  async function getMoreComments(postInfo) {
    let postComments = await FirebasePostsServices.getPostComments({
      postId: postInfo.id,
    });

    if (numCommentsDisplayed === 3) {
      if (commentCount < 10) {
        setNumCommentsDisplayed(commentCount);
        setDisplayedComments(_.take(postComments[1], commentCount));
      } else {
        setNumCommentsDisplayed(10);
        setDisplayedComments(_.take(postComments[1], 10));
      }
    } else {
      if (commentCount < numCommentsDisplayed + 10) {
        setNumCommentsDisplayed(commentCount);
        setDisplayedComments(_.take(postComments[1], commentCount));
      } else {
        setNumCommentsDisplayed(numCommentsDisplayed + 10);
        setDisplayedComments(
          _.take(postComments[1], numCommentsDisplayed + 10),
        );
      }
    }
  }

  let expandButton = null;
  if (displayedComments.length < commentCount) {
    expandButton = (
      <Row justify="center" align="middle">
        <Button
          type="link"
          onClick={() => {
            getMoreComments(postInfo);
          }}>
          See More Comments
        </Button>
      </Row>
    );
  }

  if (postInfo) {
    return (
      <div>
        {userError && <strong>Connection error!</strong>}
        {userLoading && <p>Loading...</p>}
        {user && (
          <div>
            {displayedComments.map(comment =>
              comment.user === user.id ? (
                <CommentBox
                  postInfo={postInfo}
                  commentData={comment}
                  key={comment.id}
                  groupId={groupId}
                />
              ) : (
                <CommentBox
                  postInfo={postInfo}
                  commentData={comment}
                  key={comment.id}
                  groupId={groupId}
                  readonly
                />
              ),
            )}
            {expandButton}
          </div>
        )}
      </div>
    );
  } else {
    if (detailPage) {
      return (
        <div className="gx-wall-comment-box">
          {comments.length > 0 ? (
            <ShowMore items={comments} by={10}>
              {({current, onMore}) => (
                <div>
                  <ul>
                    {current.map(comment => (
                      <ThreadComment
                        threadData={threadInfo}
                        comment={comment}
                        key={comment.id}
                      />
                    ))}
                  </ul>
                  <Button
                    type="link"
                    block
                    disabled={!onMore}
                    onClick={() => {
                      if (!!onMore) onMore();
                    }}>
                    See More Comments
                  </Button>
                </div>
              )}
            </ShowMore>
          ) : null}
        </div>
      );
    } else {
      return (
        <div className="gx-wall-comment-box">
          {comments.length > 0 ? (
            <div>
              <ul>
                {comments.slice(0, 2).map(comment => (
                  <ThreadComment
                    threadData={threadInfo}
                    comment={comment}
                    key={comment.id}
                  />
                ))}
              </ul>
              <Button type="link" block>
                <Link
                  to={{
                    pathname: `/app/forum/${threadInfo.id}`,
                  }}>
                  View Entire Forum
                </Link>
              </Button>
            </div>
          ) : (
            <Button type="link" block>
              <Link
                to={{
                  pathname: `/app/forum/${threadInfo.id}`,
                }}>
                View Entire Forum
              </Link>
            </Button>
          )}
        </div>
      );
    }
  }
};

export default CommentDisplayDiv;
