import React from 'react';
import {connect} from 'react-redux';
import numeral from 'numeral';
import {Row, Col} from 'antd';
import {THEME_TYPE_DARK} from 'constants/ThemeSetting';

const shortMonthFormat = 'MMM-YYYY';

const KitSavingsWidget = props => {
  const {interestSavings, timeSavings, newDate} = props;
  let {iconColor} = props;

  if (props.themeType === THEME_TYPE_DARK) {
    iconColor = 'white';
  }

  return (
    <div>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <div className="gx-media gx-align-items-center gx-flex-nowrap gx-mb-20">
            <div className="gx-mr-lg-4 gx-mr-3">
              <i
                className={`icon icon-revenue-new gx-fs-xlxl gx-text-${iconColor} gx-d-flex`}
                style={{fontSize: 45}}
              />
            </div>
            <div className="gx-media-body">
              <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1 gx-text-success">
                {numeral(interestSavings).format('$0,0')}
              </h1>
              <p className="gx-text-grey gx-mb-0">Savings in interest</p>
            </div>
          </div>
        </Col>

        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <div className="gx-media gx-align-items-center gx-flex-nowrap gx-mb-20">
            <div className="gx-mr-lg-4 gx-mr-3">
              <i
                className={`icon icon-inbuilt-apps gx-fs-xlxl gx-text-${iconColor} gx-d-flex`}
                style={{fontSize: 45}}
              />
            </div>
            <div className="gx-media-body">
              <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1 gx-text-success">
                {timeSavings}
              </h1>
              <p className="gx-text-grey gx-mb-0">Months early</p>
            </div>
          </div>
        </Col>

        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <div className="gx-media gx-align-items-center gx-flex-nowrap gx-mb-20">
            <div className="gx-mr-lg-4 gx-mr-3">
              <i
                className={`icon icon-datepicker gx-fs-xlxl gx-text-${iconColor} gx-d-flex`}
                style={{fontSize: 45}}
              />
            </div>
            <div className="gx-media-body">
              <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1 gx-text-success">
                {newDate.format(shortMonthFormat)}
              </h1>
              <p className="gx-text-grey gx-mb-0">New payoff date</p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({settings}) => {
  const {themeType} = settings;
  return {themeType};
};

export default connect(mapStateToProps, null)(KitSavingsWidget);
