import React from 'react';
import {AppContext} from 'auth/appContext';
import NetWorthPieChart from './networthchart/NetWorthPieChart';
import NetWorthAreaChart from './networthchart/NetWorthAreaChart';

const StackedNetWorthChart = () => {
  return (
    <AppContext.Consumer>
      {({startDate, endDate}) => (
        <div>
          <NetWorthAreaChart startDate={startDate} endDate={endDate} />
          <NetWorthPieChart startDate={startDate} endDate={endDate} />
        </div>
      )}
    </AppContext.Consumer>
  );
};

export default StackedNetWorthChart;
