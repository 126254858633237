import React, { Component } from 'react';
import { connect } from 'react-redux';
import { message } from 'antd/lib';

export class Messages extends Component {
  constructor(props) {
    super();

    this.state = {
      message: null,
      level: null
    };
  }

  componentDidUpdate(nextProps) {
    const { message, level } = nextProps;
    this.setState({
      message,
      level
    });
  }

  // componentDidUpdate(prevProps, prevState) {
  //   // only update chart if the data has changed
  //   if (prevProps.message !== this.props.message) {
  //     this.setState({
  //       message: null,
  //       level: null
  //     });
  //   }
  // }

  getRenderMessage = (messageString, level) => {
    if (!messageString) {
      return null;
    }

    let renderedMessage = '-';

    switch (level) {
      case 'info':
        renderedMessage = message.info(messageString);
        break;

      case 'error':
        renderedMessage = message.error(messageString);
        break;

      case 'success':
        renderedMessage = message.success(messageString);
        break;

      case 'warning':
        renderedMessage = message.warning(messageString);
        break;

      case 'loading':
        renderedMessage = message.loading(messageString);
        break;

      default:
        renderedMessage = message.info(messageString);
        break;
    }

    return renderedMessage;
  };

  render() {
    return <div>{this.getRenderMessage(this.state.message, this.state.level)}</div>;
  }
}

const mapStateToProps = state => ({
  message: state.messages.message,
  level: state.messages.level
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Messages);
