import React, {useState} from 'react';
import {Row, Col, Button} from 'antd/lib';
import FirebaseGroups from 'firebase/firebaseGroups';
import {useCollectionData} from 'react-firebase-hooks/firestore';
import GroupItemCard from './GroupItemCard';
import IntlMessages from 'util/IntlMessages';
import ApproveGroupJoinRequest from '../ApproveRequests';
import GroupMembersModal from './groupMembersModal';

import CircularProgress from 'components/CircularProgress';

const GroupCardList = ({
  profile,
  setShowModal,
  editGroup,
  onDeleteClick,
  approveRejectJoinRequest,
}) => {
  const [currentGroup, setCurrentGroup] = useState(null);
  const [requestList, setRequestList] = useState(null);
  const [showRequestModal, setshowRequestModal] = useState(false);
  const [groupListData, setGroupList] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [showMembersModal, setShowMembersModal] = useState(false);

  const [groupList, groupListLoading] = useCollectionData(
    FirebaseGroups.getUserGroups(profile.id, searchKeyword),
    {
      idField: 'id',
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const groupJoinRequest = (data, requests) => {
    setCurrentGroup(data);
    setRequestList(requests);
    setshowRequestModal(true);
  };

  const closeRequstModal = () => {
    setCurrentGroup(null);
    setRequestList(null);
    setshowRequestModal(false);
  };

  const manageGroupMembers = groupData => {
    setCurrentGroup(groupData);
    setShowMembersModal(true);
  };

  const closeMembersModal = () => {
    setCurrentGroup(null);
    setShowMembersModal(false);
  };

  // eslint-disable-next-line
  const searchGroups = searchText => {
    if (searchText === '') {
      setGroupList(groupListData);
    } else {
      const filterResult = groupList.filter(
        group =>
          group.groupName.toLowerCase().indexOf(searchText.toLowerCase()) > -1,
      );
      setGroupList(filterResult);
    }
  };

  const updateSearch = evt => {
    let keyword = evt.target.value.trim();
    setSearchKeyword(keyword);
    // searchGroups(keyword);
  };

  return (
    <div>
      {searchKeyword || (groupList && groupList.length) ? (
        <Row type="flex" justify="end">
          <Col span={8} className="gx-mr-2 ">
            <input
              className="ant-input ant-input gx-mb-3 "
              type="search"
              placeholder="Search groups"
              id="search"
              onKeyUp={updateSearch}
              onChange={updateSearch}
            />
          </Col>
        </Row>
      ) : null}

      {groupListLoading ? (
        <CircularProgress className="gx-loader-400" />
      ) : groupList && groupList.length ? (
        groupList.map((data, index) => (
          <GroupItemCard
            profile={profile}
            key={index}
            data={data}
            editGroup={editGroup}
            onDeleteClick={onDeleteClick}
            groupJoinRequest={groupJoinRequest}
            manageGroupMembers={manageGroupMembers}
          />
        ))
      ) : searchKeyword ? (
        <div className="text-center">
          <h4>No group found with "{searchKeyword}" keyword.</h4>
        </div>
      ) : (
        <div className="text-center">
          <h2>You don't have created any groups yet. Let's create one!</h2>
          <Button
            variant="raised"
            className="gx-mt-3"
            type="primary"
            onClick={() => setShowModal(true)}>
            <div className="ant-row-flex">
              <IntlMessages id="group.add.heading" />
            </div>
          </Button>
        </div>
      )}

      <ApproveGroupJoinRequest
        group={currentGroup}
        requests={requestList}
        showModal={showRequestModal}
        closeRequstModal={closeRequstModal}
        approveRejectJoinRequest={approveRejectJoinRequest}
      />
      {currentGroup ? (
        <GroupMembersModal
          profile={profile}
          groupData={currentGroup}
          showMembersModal={showMembersModal}
          closeMembersModal={closeMembersModal}
          removeMember={approveRejectJoinRequest}
        />
      ) : null}
    </div>
  );
};

export default GroupCardList;
