import React, {useState, useEffect} from 'react';
import {List, message, Spin, Row, Col, Avatar} from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import {
  covertTimestamp,
  getAvailabilityEntry,
  dateFormatDisplay,
} from 'util/commonUtils';
import {CALENDAR_ICON} from 'util/constants';

const _avatar = <Avatar shape="square" src={CALENDAR_ICON} />;

const AvailabilityList = ({
  availability,
  bookedSlots,
  maxDate,
  selectDateTime,
  selectedDateSlot,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(0);
  const todayTimestamp = covertTimestamp(new Date());
  const [dateSlice, setDateSlice] = useState([]);
  const [dateFiltered, setDateFiltered] = useState(true);
  const [dataTriger, setDataTriger] = useState(true);
  const [selectedTime, setselectedTime] = useState(selectedDateSlot.time);
  const [selectedID, setSelectedID] = useState('');
  const [selectedDate, setSelectedDate] = useState(selectedDateSlot.date);

  const getAppointmentSlots = () => {
    let oldData = data;
    let _data = [];

    if (dateSlice.length) {
      let newData = dateSlice[page];

      if (newData) {
        newData.map((_date, index) => {
          let availSlots = getAvailabilityEntry({
            availability: availability,
            date: _date * 1000,
          });
          let slots = [];

          if (availSlots) {
            let timeSlots = availSlots.timeSlots;
            timeSlots.map((s, i) =>
              slots.push({
                id: i + '_' + index,
                time: s,
                show: true,
              }),
            );
            // Filter Old Booked Slots
            if (bookedSlots.length && slots.length) {
              let nextDayTimestamp = (86400 + _date) * 1000;
              bookedSlots.map(book => {
                if (
                  book.date >= _date * 1000 &&
                  book.date <= nextDayTimestamp
                ) {
                  slots = slots.filter(slot => slot.time !== book.timeSlot);
                }
                return true;
              });
            }
          }

          if (slots.length) {
            _data.push({date: _date, slots: slots});
          }
          return true;
        });
        let finalData = oldData.concat(_data);
        if (dataTriger) {
          setDataTriger(false);
          setData(finalData);
          setPage(page + 1);
        }
      }
    }
  };

  useEffect(() => {
    if (!data.length) {
      getAppointmentSlots();
    }
  });

  let start = new Date(dateFormatDisplay(todayTimestamp * 1000, 'MM/DD/YYYY'));
  let end = new Date(dateFormatDisplay(maxDate * 1000, 'MM/DD/YYYY'));
  let newend = end.setDate(end.getDate() + 1);
  end = new Date(newend);
  let dateTimeArray = [];
  while (start < end) {
    let datetime = new Date(start).getTime() / 1000;
    dateTimeArray.push(datetime);
    var newDate = start.setDate(start.getDate() + 1);
    start = new Date(newDate);
  }

  if (dateFiltered) {
    setDateFiltered(false);
    let datetimePagination = new Array(Math.ceil(dateTimeArray.length / 100))
      .fill()
      .map(_ => dateTimeArray.splice(0, 100));
    setDateSlice(datetimePagination);
  }

  const triggerInfiniteDataLoad = () => {
    setDataTriger(true);
    setLoading(true);

    if (data.length > 14) {
      message.warning('Infinite List loaded all');
      setLoading(false);
      setHasMore(false);
      return;
    }
    getAppointmentSlots();
  };

  const selectTime = (date, time, i) => {
    setselectedTime(time);
    setSelectedID(i);
    selectDateTime(date, time, i);
    setSelectedDate('');
  };

  return (
    <div className="availability-infinite-container">
      <InfiniteScroll
        initialLoad={false}
        pageStart={0}
        loadMore={() => triggerInfiniteDataLoad()}
        hasMore={!loading && hasMore}
        useWindow={false}>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={item => (
            <List.Item key={item.date}>
              <List.Item.Meta
                avatar={_avatar}
                title={dateFormatDisplay(item.date * 1000)}
                description={
                  <Row className="time-list">
                    {item.slots && item.slots.length ? (
                      item.slots.map(
                        el =>
                          el.show === true && (
                            <Col span={8} key={el.id}>
                              {selectedTime === el.time &&
                              (selectedID === el.id ||
                                selectedDate ===
                                  dateFormatDisplay(
                                    item.date * 1000,
                                    'MMDDYYYY',
                                  )) ? (
                                <div
                                  className="gx-changeboxView"
                                  onClick={() =>
                                    selectTime(item.date, el.time, el.id)
                                  }>
                                  {el.time.split('to')[0]}
                                </div>
                              ) : (
                                <div
                                  className="gx-boxView"
                                  onClick={() =>
                                    selectTime(item.date, el.time, el.id)
                                  }>
                                  {el.time.split('to')[0]}
                                </div>
                              )}
                            </Col>
                          ),
                      )
                    ) : (
                      <Col span={24}>
                        <p className="text-center gx-m-3">
                          {'- No time slot available for this date -'}
                        </p>
                      </Col>
                    )}
                  </Row>
                }
              />
            </List.Item>
          )}>
          {loading && hasMore && (
            <div className="availability-loading-container">
              <Spin />
            </div>
          )}
        </List>
      </InfiniteScroll>
    </div>
  );
};

export default AvailabilityList;
