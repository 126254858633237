export default [
    // Espenses
    {
        value: 'expense',
        label: 'Expense',
        children: [
            {
                value: "advertising",
                label: "Advertising"
            },
            {
                value: "advisoryfee",
                label: "Advisory Fee"
            },
            {
                value: "atmcash",
                label: "ATM/Cash"
            },
            {
                value: "automotive",
                label: "Automotive"
            },
            {
                value: "businessmiscellaneous",
                label: "Business Miscellaneous"
            },
            {
                value: "cablesatellite",
                label: "Cable/Satellite"
            },
            {
                value: "charitablegiving",
                label: "Charitable Giving"
            },
            {
                value: "checks",
                label: "Checks"
            },
            {
                value: "childdependent",
                label: "Child/Dependent"
            },
            {
                value: "clothingshoes",
                label: "Clothing/Shoes"
            },
            {
                value: "duessubscriptions",
                label: "Dues & Subscriptions"
            },
            {
                value: "education",
                label: "Education"
            },
            {
                value: "electronics",
                label: "Electronics"
            },
            {
                value: "entertainment",
                label: "Entertainment"
            },
            {
                value: "gasolinefuel",
                label: "Gasoline/Fuel"
            },
            {
                value: "generalmerchandise",
                label: "General Merchandise"
            },
            {
                value: "gifts",
                label: "Gifts"
            },
            {
                value: "groceries",
                label: "Groceries"
            },
            {
                value: "healthcaremedical",
                label: "Healthcare/Medical"
            },
            {
                value: "hobbies",
                label: "Hobbies"
            },
            {
                value: "homeimprovement",
                label: "Home Improvement"
            },
            {
                value: "homemaintenance",
                label: "Home Maintenance"
            },
            {
                value: "insurance",
                label: "Insurance"
            },
            {
                value: "loans",
                label: "Loans"
            },
            {
                value: "mortgages",
                label: "Mortgages"
            },
            {
                value: "officemaintenance",
                label: "Office Maintenance"
            },
            {
                value: "officesupplies",
                label: "Office Supplies"
            },
            {
                value: "onlineservices",
                label: "Online Services"
            },
            {
                value: "otherbills",
                label: "Other Bills"
            },
            {
                value: "otherexpenses",
                label: "Other Expenses"
            },
            {
                value: "personalcare",
                label: "Personal Care"
            },
            {
                value: "petspetcare",
                label: "Pets/Pet Care"
            },
            {
                value: "postageshipping",
                label: "Postage & Shipping"
            },
            {
                value: "printing",
                label: "Printing"
            },
            {
                value: "rent",
                label: "Rent"
            },
            {
                value: "restaurants",
                label: "Restaurants"
            },
            {
                value: "servicechargesfees",
                label: "Service Charges/Fees"
            },
            {
                value: "softwarelicense",
                label: "Software License"
            },
            {
                value: "taxes",
                label: "Taxes"
            },
            {
                value: "telephone",
                label: "Telephone"
            },
            {
                value: "travel",
                label: "Travel"
            },
            {
                value: "utilities",
                label: "Utilities"
            },
            {
                value: "wagespaid",
                label: "Wages Paid"
            }],
    },
    // Income
    {
        value: 'income',
        label: 'Income',
        children: [
            {
                value: "bonus",
                label: "Bonus"
            },
            {
                value: "consulting",
                label: "Consulting"
            },
            {
                value: "deposits",
                label: "Deposits"
            },
            {
                value: "dividendsreceived",
                label: "Dividends Received"
            },
            {
                value: "dividendsreceivedtaxadvantaged",
                label: "Dividends Received (tax-advantaged)"
            },
            {
                value: "interest",
                label: "Interest"
            },
            {
                value: "investmentincome",
                label: "Investment Income"
            },
            {
                value: "otherincome",
                label: "Other Income"
            },
            {
                value: "paycheckssalary",
                label: "Paychecks/Salary"
            },
            {
                value: "retirementincome",
                label: "Retirement Income"
            },
            {
                value: "sales",
                label: "Sales"
            },
            {
                value: "services",
                label: "Services"
            }
        ],
    },

    // Transfers
    {
        value: 'transfers',
        label: 'Transfers',
        children: [
            {
                value: "allocatedexcesscash",
                label: "Allocated Excess Cash"
            },
            {
                value: "cashraised",
                label: "Cash Raised"
            },
            {
                value: "clientrequest",
                label: "Client Request"
            },
            {
                value: "creditcardpayments",
                label: "Credit Card Payments"
            },
            {
                value: "diversifiedtransferredinsecurities",
                label: "Diversified Transferred-in Securities"
            },
            {
                value: "expensereimbursement",
                label: "Expense Reimbursement"
            },
            {
                value: "generalrebalance",
                label: "General Rebalance"
            },
            {
                value: "nottraded",
                label: "Not Traded"
            },
            {
                value: "personalstrategyimplementation",
                label: "Personal Strategy Implementation"
            },
            {
                value: "portfoliomanagement",
                label: "Portfolio Management"
            },
            {
                value: "retirementcontributions",
                label: "Retirement Contributions"
            },
            {
                value: "savings",
                label: "Savings"
            },
            {
                value: "securitiestrades",
                label: "Securities Trades"
            },
            {
                value: "strategychange",
                label: "Strategy Change"
            },
            {
                value: "taxlocation",
                label: "Tax Location"
            },
            {
                value: "taxlossharvesting",
                label: "Tax Loss Harvesting"
            },
            {
                value: "transfers",
                label: "Transfers"
            }
        ],
    },

    // Uncategorized
    {
        value: 'uncategorized',
        label: 'Uncategorized'
    },
];