import { Card } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import './iconCheckBox.less';
import { ICON_SIZE_STEP_CHECKLIST } from 'util/constants';

const IconCheckBox = ({
  id,
  label,
  state,
  icon,
  onCheckBoxClick,
  orientation,
}) => {
  const onClick = () => {
    onCheckBoxClick(id);
  };

  if (orientation === 'horizontal') {
    return (
      <Card onClick={onClick} style={{ paddingTop: '0px', cursor: 'pointer' }}>
        <div style={{ marginTop: 20 }}>
          <div className="gx-flex-row">
            {state ? (
              <FaCheckCircle
                size={ICON_SIZE_STEP_CHECKLIST}
                style={{ color: 'green' }}
              />
            ) : (
                icon
              )}
            <div className="labelHorizontal" style={{ lineHeight: '40px' }}>
              {label}
            </div>
          </div>
        </div>
      </Card>
    );
  }

  return (
    <Card
      onClick={onClick}
      style={{ paddingTop: 12, cursor: 'pointer' }}
      className="gx-text-align-center">
      {state ? (
        <FaCheckCircle
          size={ICON_SIZE_STEP_CHECKLIST}
          style={{ color: 'green' }}
        />
      ) : (
          icon
        )}
      <br />
      <div style={{ marginTop: 10 }}>{label}</div>
    </Card>
  );
};
IconCheckBox.defaultProps = {
  checklist: false,
};
IconCheckBox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onCheckBoxClick: PropTypes.func.isRequired,
  orientation: PropTypes.string.isRequired,
  state: PropTypes.bool.isRequired,
  checklist: PropTypes.bool,
};
export default IconCheckBox;
