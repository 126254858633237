import React from 'react';
import { Button, Modal } from 'antd/lib';
import MembersList from '../groupDetail/groupMembers/membersList';
import FirebaseGroups from 'firebase/firebaseGroups';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import CircularProgress from "components/CircularProgress";

const GroupMembersModal = ({ profile, groupData, showMembersModal, closeMembersModal, removeMember }) => {

    // Get Groups active members
    const [groupMembers, groupMembersLoading] = useCollectionData(FirebaseGroups.getGroupActiveMembers(groupData.id), {
        idField: 'id',
        snapshotListenOptions: {
            includeMetadataChanges: true,
        },
    });

    return (
        <div>
            <Modal
                visible={showMembersModal}
                closable={false}
                onCancel={() => closeMembersModal()}
                header={false}
                footer={
                    <Button key="cancel" onClick={() => closeMembersModal()}>
                        Close
                    </Button>
                }
            >
                {groupMembersLoading ?
                    <CircularProgress className="gx-loader-400" /> :
                    (
                        groupMembers && groupMembers.length ?
                            <MembersList
                                profile={profile}
                                groupData={groupData}
                                groupMembers={groupMembers}
                                removeMember={removeMember}
                                type="modal"
                            />
                            : null
                    )
                }
            </Modal>
        </div>
    );
};

export default GroupMembersModal;
