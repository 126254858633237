import React, {useState} from 'react';
import Widget from 'components/Widget/index';
import Auxiliary from 'util/Auxiliary';
import {Modal} from 'antd';
import {history} from 'appRedux/store';

const SocialGuestView = ({followerView, followingView, friendView, length}) => {
  const [setShowModal] = useState(false);
  let title = null;

  if (followerView) {
    title = <span>Followers - {length}</span>;
  } else if (followingView) {
    title = <span>Following - {length}</span>;
  } else if (friendView) {
    title = <span>Friends - {length}</span>;
  }

  function info() {
    Modal.info({
      content: (
        <p>
          In order to view this content, please{' '}
          <a
            href
            onClick={() => {
              setShowModal(false);
              history.push('/signin');
            }}>
            {' '}
            sign in
          </a>{' '}
          or{' '}
          <a
            href
            onClick={() => {
              setShowModal(false);
              history.push('/signup');
            }}>
            {' '}
            create a new account.
          </a>
        </p>
      ),
      onOk() {},
    });
  }

  return (
    <Auxiliary>
      <Widget
        styleName="gx-card-profile-sm"
        title={title}
        extra={
          <a href onClick={info}>
            See More
          </a>
        }
      />
    </Auxiliary>
  );
};
export default SocialGuestView;
