import app from 'firebase/app';
import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import config from './firebaseConfig';

class FirebaseGoals {
  constructor() {
    if (!firebase.apps.length) {
      app.initializeApp(config);
    }
    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
  }
  collection = 'goals'
  getGoals() {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    return this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection(this.collection);
  }

  uploadGoalImage(info, filename) {
    const img = this.storage
      .ref()
      .child(`images/${this.auth.currentUser.uid}/goals/${filename}`);
    let upload = info.fileList[0].originFileObj;
    try {
      return img.put(upload)
    } catch (err) {
      console.log({ err })
    }
  }

  addGoal(data) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    return this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection(this.collection)
      .add(data);
  }

  async getGoal(id) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    return await this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection(this.collection)
      .doc(id)
      .get()
      .then(doc => ({ ...doc.data(), id: doc.id }));
  }

  updateGoal(data) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    const { id } = data;
    delete data.id;
    return this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection(this.collection)
      .doc(id)
      .update(data);
  }

  deleteGoal(goal) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let batch = this.db.batch();
    let toDelete = this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection(this.collection)
      .doc(goal.id);
    batch.delete(toDelete);
    batch.commit();
  }
}

export default new FirebaseGoals();
