import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Col, Row, Spin} from 'antd/lib';
import Photos from 'components/wall/Photos/index';
import Profile from 'components/wall/Profile/index';
import Posts from 'components/profile/Posts/index';
import GuestRedirect from 'containers/GuestRedirect';
import CustomScrollbars from 'util/CustomScrollbars';
import FirebasePosts from 'firebase/firebasePosts';
import FirebaseSocial from 'firebase/firebaseSocial';
import {
  useDocumentData,
  useCollectionData,
} from 'react-firebase-hooks/firestore';
import {FaSpinner} from 'react-icons/fa';
import Social from 'components/profile/Social';

const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;

function sortDeepArray(arr) {
  return arr.sort((a, b) => {
    let aTime = new Date(a.date).getTime();
    let bTime = new Date(b.date).getTime();
    if (aTime < bTime) {
      return 1;
    }
    if (aTime > bTime) {
      return -1;
    }
    return 0;
  });
}

const Wall = ({profile, guest}) => {
  let userId = FirebasePosts.auth.currentUser.uid.toString();
  const [userMedia, setUserMedia] = useState([]);

  const [user, userLoading, userError] = useDocumentData(
    FirebaseSocial.getUserDocumentData(userId),
    {
      snapshotListenOptions: {includeMetadataChanges: true},
    },
  );

  const [userWall, userWallLoading, userWallError] = useCollectionData(
    FirebasePosts.getUserWall(userId),
    {
      snapshotListenOptions: {includeMetadataChanges: true},
    },
  );

  const getUserMedia = async () => {
    const result = await FirebaseSocial.getUserMedia(user);
    setUserMedia(result);
  };

  if (guest) {
    return <GuestRedirect />;
  } else if (!userWall || !user) {
    return <Spin indicator={spinnerIcon} />;
  } else if (user.social.mediaArray.length !== userMedia.length) {
    getUserMedia();
    return <Spin indicator={spinnerIcon} />;
  } else {
    let wallPostList = sortDeepArray(userWall);

    return (
      <div>
        {(userWallError || userError) && <strong>Connection error!</strong>}
        {(userWallLoading || userLoading) && <p>Loading...</p>}
        {userWall && user && (
          <div className="gx-main-content">
            <Row>
              <Col
                xl={6}
                lg={8}
                md={8}
                sm={10}
                xs={24}
                className="gx-d-none gx-d-sm-block">
                <CustomScrollbars className="gx-wall-scroll">
                  <Profile profile={profile} />
                  <Social friendView={true} userId={userId} readonly={false} />

                  <Photos
                    photoList={userMedia}
                    title={'Photos ' + userMedia.length}
                  />
                  <span className="gx-text-primary gx-fs-md gx-pointer gx-d-block gx-mb-4">
                    <Link
                      to={{
                        pathname: `/app/user/${user.vanityURL}/gallery`,
                        userId: user.id,
                      }}>
                      Go to gallery
                      <i
                        className={`icon icon-long-arrow-right gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle`}
                      />
                    </Link>
                  </span>
                </CustomScrollbars>
              </Col>
              <Col xl={12} lg={8} md={16} sm={14} xs={24}>
                <CustomScrollbars className="gx-wall-scroll">
                  <div className="gx-wall-postlist">
                    <Posts
                      postList={wallPostList}
                      profile={profile}
                      wall
                      user={user.id}
                    />
                  </div>
                </CustomScrollbars>
              </Col>
              <Col
                xl={6}
                lg={8}
                md={6}
                sm={24}
                xs={24}
                className="gx-d-none gx-d-lg-block">
                <CustomScrollbars className="gx-wall-scroll">
                  <span className="gx-text-primary gx-fs-md gx-pointer gx-d-block gx-mb-4">
                    See All Communities
                    <i
                      className={`icon icon-long-arrow-right gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle`}
                    />
                  </span>
                </CustomScrollbars>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
};

export default Wall;
