import React, {useState} from 'react';
import {
  Form,
  Button,
  Input,
  Modal,
  message,
  Upload,
  InputNumber,
  Spin,
  Card,
} from 'antd/lib';
import IntlMessages from 'util/IntlMessages';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';
import FirebaseCoaches from 'firebase/firebaseCoaches';
import {useCollectionData} from 'react-firebase-hooks/firestore';
import ServiceCell from './ServiceCell';
import {FaSpinner} from 'react-icons/fa';
const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;
const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
  },
};

const Services = ({readonly, profile}) => {
  const {confirm} = Modal;
  const [showModal, setShowModal] = useState(false);
  const intlMessages = useFormatMessage();
  const [form] = Form.useForm();
  const [fileInfoList, setFileInfoList] = useState([]);
  const [formType, setFormType] = useState('add');

  const onModalClose = () => {
    form.resetFields();
    setShowModal(false);
    setFileInfoList([]);
  };

  const onModalOpen = e => {
    if (e) {
      let formFields = e;
      formFields = {
        ...formFields,
        servicePrice: parseFloat(formFields.servicePrice),
      };
      setFormType('edit');
      form.resetFields();
      form.setFieldsValue({
        ...formFields,
      });
    }
    setShowModal(true);
  };

  const onDeleteSevice = async values => {
    confirm({
      title: 'Are you sure you want to delete this service?',
      onOk: () => {
        deleteSevice(values);
      },
      onCancel() {},
    });
  };

  const deleteSevice = e => {
    FirebaseCoaches.deleteService(e.id);
    message.success(intlMessages('coach.service.deleted'));
  };

  const [services, servicesLoading, servicesError] = useCollectionData(
    FirebaseCoaches.getMyServices(),
    {
      idField: 'id',
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  // Submit Form
  const submitForm = async values => {
    let formValues = form.getFieldsValue(['serviceName', 'servicePrice']);

    if (formType === 'edit') {
      let {id, coverPhoto} = form.getFieldsValue(['id', 'coverPhoto']);
      formValues = {
        ...formValues,
        id,
        servicePrice: parseFloat(
          formValues.servicePrice.toFixed(3).slice(0, -1),
        ),
        old_coverPhoto: coverPhoto,
        coverPhoto: fileInfoList,
      };
      onModalClose();
      await FirebaseCoaches.updateService(formValues);
      message.success(intlMessages('coach.service.updated'));
    } else {
      formValues = {
        ...formValues,
        servicePrice: parseFloat(
          formValues.servicePrice.toFixed(3).slice(0, -1),
        ),
        coverPhoto: fileInfoList,
      };
      onModalClose();
      await FirebaseCoaches.addService(formValues);
      message.success(intlMessages('coach.service.added'));
    }
    setFormType('add');
    form.resetFields();
    setFileInfoList([]);
  };

  /***** Photo Upload Section ****/
  const uploadButton = (
    <div>
      <i type="icon icon-plus" />
      <div className="ant-upload-text">Upload Cover Photo</div>
    </div>
  );

  let invalidUpload = false;

  const mediaSettings = {
    name: 'file',
    headers: {
      authorization: 'authorization-text',
    },
    onChange: info => {
      if (!invalidUpload) {
        setFileInfoList(info.fileList);
      }
    },
  };

  function beforeUpload(file) {
    const isJPG = file.type === 'image/jpeg';
    const isJPEG = file.type === 'image/jpeg';
    const isGIF = file.type === 'image/gif';
    const isPNG = file.type === 'image/png';

    if (!(isJPG || isJPEG || isGIF || isPNG)) {
      message.error(intlMessages('avatar.type.error'));
      invalidUpload = true;
      return false;
    }

    const isLt2M = file.size / 10240 / 10240 < 2;
    if (!isLt2M) {
      message.error(intlMessages('avatar.size.error'));
      invalidUpload = true;
      return false;
    }

    invalidUpload = false;
    return true;
  }
  /***** END Photo Upload Section ****/

  let extra = null;
  if (!readonly) {
    extra = [
      <span
        className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block gx-mr-1"
        onClick={() => onModalOpen()}
        key={'web'}>
        <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle gx-mr-1" />
        <IntlMessages id="coach.add.service" />
      </span>,
      // For Mobile screen
      <span
        onClick={() => onModalOpen()}
        className="gx-text-primary gx-fs-md gx-pointer gx-mb-4 gx-d-block gx-d-sm-none gx-mr-1"
        key={'moblile'}>
        <i className="icon icon-add-circle gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle" />
        <IntlMessages id="coach.add.service" />
      </span>,
    ];

    return (
      <div>
        <div className="gx-profile-banner-bottom">
          <span className="gx-link gx-profile-setting ">{extra}</span>
        </div>

        {servicesError && (
          <strong>Error: {JSON.stringify(servicesError)}</strong>
        )}
        {servicesLoading && <Spin indicator={spinnerIcon} />}

        {services && services.length ? (
          services.map((service, index) => (
            <ServiceCell
              service={service}
              key={service.id}
              onDeleteSevice={onDeleteSevice}
              onModalOpen={onModalOpen}
            />
          ))
        ) : (
          <Card>
            <div className="text-center">
              <h4>You don't have added any services yet. Let's add one!</h4>
              <Button
                variant="raised"
                className="gx-mt-3"
                type="primary"
                onClick={() => onModalOpen()}>
                <div className="ant-row-flex">
                  <IntlMessages id="coach.add.service" />
                </div>
              </Button>
            </div>
          </Card>
        )}

        <Modal
          title={
            formType === 'add' ? (
              <IntlMessages id="coach.add.service" />
            ) : (
              <IntlMessages id="coach.update.service" />
            )
          }
          toggle={onModalClose}
          visible={showModal}
          closable={true}
          onOk={() => form.submit()}
          onCancel={onModalClose}>
          <Form {...formItemLayout} form={form} onFinish={submitForm}>
            <div className="gx-modal-box-row">
              <div className="gx-modal-box-form-item">
                <div className="gx-form-group">
                  <Form.Item
                    label="Service Name"
                    name="serviceName"
                    rules={[
                      {
                        required: true,
                        type: 'string',
                        message: 'Please enter a service name',
                      },
                    ]}>
                    <Input required placeholder="Service Name" />
                  </Form.Item>
                </div>
                <div className="gx-form-group">
                  <Form.Item
                    label="Service Price"
                    name="servicePrice"
                    rules={[
                      {
                        required: true,
                        type: 'number',
                        message: 'Please enter service price',
                      },
                    ]}>
                    <InputNumber
                      // formatter={value =>
                      //   //`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '')
                      // }
                      // parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      placeholder="Service price"
                      style={{width: '100%'}}
                    />
                  </Form.Item>
                </div>
                <Button htmlType="submit" style={{display: 'none'}}></Button>
              </div>
            </div>
            <div className="gx-form-group">
              <Form.Item name="coverPhoto" label="Cover Photo">
                <Upload
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={true}
                  // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  fileList={fileInfoList}
                  {...mediaSettings}
                  beforeUpload={beforeUpload}>
                  {fileInfoList.length > 0 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </div>
            <Button htmlType="submit" style={{display: 'none'}}></Button>
          </Form>
        </Modal>
      </div>
    );
  }
};

export default Services;
