import React from 'react';
import {Col, Row} from 'antd/lib';
import DisplayAppointmentCell from './DisplayAppointmentCell';

const DisplayAppointmentList = ({
  data,
  editMyAppoientment,
  cancelAppointment,
  section,
  canUpdate = true,
  upcomingSearch,
  pastSearch,
}) => {
  const manageButton = {
    editMyAppoientment,
    cancelAppointment,
  };

  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col className="align-self-center" span={8}>
          Service
        </Col>
        <Col className="align-self-center" span={5}>
          {section === 'coach' ? 'User' : 'Coach'}
        </Col>
        <Col className="align-self-center" span={4}>
          Date
        </Col>
        <Col className="align-self-center" span={5}>
          Time
        </Col>
        <Col className="align-self-center" span={2}></Col>
      </Row>
      {data.map((appointment, index) => (
        <DisplayAppointmentCell
          key={index}
          data={appointment}
          manageButton={manageButton}
          section={section}
          view={'list'}
          recent={true}
          canUpdate={canUpdate}
          upcomingSearch={upcomingSearch}
          pastSearch={pastSearch}
        />
      ))}
    </React.Fragment>
  );
};

export default DisplayAppointmentList;
