import React from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import IntlMessages from 'util/IntlMessages';
import {Button} from 'antd/lib';
import moment from 'moment';
const dayFormat = 'D-MMM-YYYY';

const filters = [
  {
    id: 0,
    handle: 'starred',
    title: 'Starred',
    icon: 'star',
  },
  {
    id: 1,
    handle: 'important',
    title: 'Important',
    icon: 'important',
  },
  {
    id: 2,
    handle: 'dueDate',
    title: 'Sheduled',
    icon: 'schedule',
  },
  {
    id: 3,
    handle: 'pastDue',
    title: 'Past due',
    icon: 'calendar',
  },
  {
    id: 4,
    handle: 'today',
    title: 'Today',
    icon: 'alert',
  },
  {
    id: 5,
    handle: 'completed',
    title: 'Done',
    icon: 'check-circle-o',
  },
];

const TaskSideBar = ({allTasks, labels, setTasks, showAllTasks, dispatch}) => {
  const getTasksByFilter = filter => {
    let filtered;
    switch (filter.handle) {
      case 'starred':
        filtered = allTasks.filter(task => task.starred === true);
        break;
      case 'important':
        filtered = allTasks.filter(task => task.important === true);
        break;
      case 'dueDate':
        filtered = allTasks.filter(
          task => new Date(task.date * 1000) > new Date(),
        );
        break;
      case 'pastDue':
        filtered = allTasks.filter(
          task => new Date(task.date * 1000) < new Date(),
        );
        break;
      case 'today':
        filtered = allTasks.filter(
          task =>
            moment(new Date(task.date * 1000), dayFormat).format(dayFormat) ===
            moment(new Date(), dayFormat).format(dayFormat),
        );
        break;
      case 'completed':
        filtered = allTasks.filter(task => task.completed === true);
        break;

      default:
        break;
    }

    showAllTasks();
    setTasks(filtered);
  };

  const taskFilterByLabel = label => {
    console.log('side drawer filter task');
    const filtered = allTasks.filter(task => {
      return (
        task &&
        task.tags &&
        task.tags.findIndex(tagObject => tagObject.title === label) !== -1
      );
    });
    showAllTasks();
    setTasks(filtered);
  };

  const getNavLabels = () => {
    if (labels && labels.length > 0) {
      return labels.map((label, index) => (
        <li
          key={index}
          onClick={() => {
            taskFilterByLabel(label.tag.title);
          }}>
          <span className="gx-link">
            <i
              className={`icon icon-circle gx-text-${label.tag.color}`}
              style={{color: label.tag.color}}
            />
            <span>{label.tag.title}</span>
          </span>
        </li>
      ));
    }
    return null;
  };

  const getNavFilters = () => {
    return filters.map((filter, index) => (
      <li
        key={index}
        onClick={() => {
          getTasksByFilter(filter);
        }}>
        <span className={filter.id === !0 ? 'gx-link active' : 'gx-link'}>
          <i className={`icon icon-${filter.icon}`} />
          <span>{filter.title}</span>
        </span>
      </li>
    ));
  };

  return (
    <div>
      <div className="gx-module-side">
        <div className="gx-module-side-header">
          <div className="gx-module-logo">
            <i className="icon icon-check-circle-o gx-mr-4" />
            <IntlMessages id="sidebar.tasks" />
          </div>
        </div>
        <div className="gx-module-side-content">
          <CustomScrollbars className="gx-module-side-scroll">
            <div className="gx-module-add-task">
              <Button
                variant="raised"
                type="primary"
                className="gx-btn-block"
                onClick={() => dispatch({type: 'addTask'})}>
                <IntlMessages id="todo.addTask" />
              </Button>
            </div>
            <ul className="gx-module-nav">
              <li
                onClick={() => {
                  showAllTasks();
                  setTasks(allTasks);
                }}>
                <span className="gx-link active">
                  <i className="icon icon-all-contacts gx-pt-1" />
                  <span>
                    <IntlMessages id="todo.all" />
                  </span>
                </span>
              </li>

              <li className="gx-module-nav-label">
                <IntlMessages id="todo.filters" />
              </li>

              {getNavFilters()}

              <li className="gx-module-nav-label">
                <IntlMessages id="todo.tags" />
              </li>
              {getNavLabels()}
            </ul>
          </CustomScrollbars>
        </div>
      </div>
    </div>
  );
};

export default TaskSideBar;
