import React from 'react';
import {Avatar} from 'antd/lib';
import DisplayDate from '../../../../util/DisplayDate';

const UserCell = ({chat, selectedSectionId, onSelectUser}) => {
  return (
    <div
      className={`gx-chat-user-item ${
        selectedSectionId === chat.id ? 'active' : ''
      }`}
      onClick={() => {
        onSelectUser(chat);
      }}>
      <div className="gx-chat-user-row">
        <div className="gx-chat-avatar">
          <div className="gx-status-pos">
            <Avatar
              src={chat.avatar}
              className="gx-size-40"
              alt={chat.username}
            />
            <span className={`gx-status gx-small gx-${chat.status}`} />
          </div>
        </div>

        <div className="gx-chat-info">
          <span className="gx-name h4">{chat.username}</span>
          <div className="gx-chat-info-des gx-text-truncate">
            {chat.lastMessage && chat.lastMessage.substring(0, 25) + '...'}
          </div>
          <div className="gx-last-message-time">
            {' '}
            {chat.lastSentAt && (
              <DisplayDate date={chat.lastSentAt.toString()} />
            )}{' '}
          </div>
        </div>

        {chat.unreadMessage > 0 ? (
          <div className="gx-chat-date">
            <div className="gx-bg-primary gx-rounded-circle gx-badge gx-text-white">
              {chat.unreadMessage}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default UserCell;
