import React from 'react';
import FRNotification from './FRNotification';
import Auxiliary from 'util/Auxiliary';
import CustomScrollbars from 'util/CustomScrollbars';

const NotificationApp = ({requestList}) => {
  return (
    <Auxiliary>
      <div className="gx-popover-header">
        <h3 className="gx-mb-0">Notifications</h3>
        <i className="gx-icon-btn icon icon-charvlet-down" />
      </div>
      <CustomScrollbars className="gx-popover-scroll">
        <ul className="gx-sub-popover">
          {requestList.map((item, index) => (
            <FRNotification request={item.sending} key={index} />
          ))}
        </ul>
      </CustomScrollbars>
    </Auxiliary>
  );
};

export default NotificationApp;
