import React from 'react';
import moment from 'moment';
const dateFormat = 'M/DD/YYYY';

export const AppContext = React.createContext({
  user: null,
  profile: null,
  startDate: moment(new Date(), dateFormat).subtract(90, 'day'),
  endDate: moment(new Date(), dateFormat),
  setDateRange: () => {},
});
