import React from 'react';
import WidgetHeader from 'components/WidgetHeader/index';

const Photos = ({photoList, title}) => {
  let mediaList = photoList.slice(0, 6);
  return (
    <div className="gx-entry-sec">
      <WidgetHeader title={title} />
      <ul className="gx-gallery-list">
        {mediaList.map((photo, index) => (
          <li key={index}>
            <img alt="..." src={photo} />
          </li>
        ))}
      </ul>
    </div>
  );
};
export default Photos;
