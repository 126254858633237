import React, { useState } from 'react';
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  LineChart,
  Line
} from 'recharts';
import numeral from 'numeral';
import Widget from 'components/Widget/index';
const _ = require('lodash');

const LoanChart = ({
  loanPortfolio,
  startingBalance,
  loanFreq,
  renderTooltipContent,
  graphBy,
  chartColors
}) => {
  const [opacity, setOpacity] = useState({
    opacity: {},
  });
  let allCleanData = [];
  if (loanPortfolio) {
    allCleanData = loanPortfolio.getBalanceRemainingTimeSeriesData(graphBy);
  }
  let keys = [];
  let cleanData = [];
  if (allCleanData && allCleanData.length) {

    let x = 0;
    allCleanData.forEach(e => {
      let dataObj = e;
      let _keys = Object.keys(allCleanData[x]);
      keys = _.union(keys, _keys);
      keys.forEach(k => {
        if (dataObj[k] === undefined) {
          if (graphBy === 'monthlypayment') {
            dataObj[k] = 0;
          } else {
            dataObj[k] = allCleanData[x - 1][k];
          }
        }
      });
      cleanData.push(dataObj);
      x++;
      return true;
    });
  }

  let _cleanData = []

  if (loanFreq === 'quarterly') {
    _cleanData = [];
    let i = 0;
    cleanData.forEach(dataElem => {
      if (i % 3 === 0) {
        _cleanData.push(dataElem);
      }
      i++;
    });
  }

  if (loanFreq === 'yearly') {
    _cleanData = [];
    let j = 0;
    cleanData.forEach(dataElem => {
      if (j % 12 === 0) {
        _cleanData.push(dataElem);
      }
      j++;
    });
  }

  const handleMouseEnter = o => {
    const { dataKey } = o;
    setOpacity({ ...opacity, [dataKey]: 0.5 });
  };

  const handleMouseLeave = o => {
    const { dataKey } = o;
    setOpacity({ ...opacity, [dataKey]: 1 });
  };

  return (
    <Widget>
      {
        graphBy === "percenttowordprincipal" ? null
          :
          <div className="ant-row-flex gx-px-4">
            <h5 className="gx-mt-2 gx-mb-4">
              Starting balance: {numeral(startingBalance).format('$0,0')}
            </h5>
          </div>
      }

      {loanFreq === 'monthly' && (
        <ResponsiveContainer
          className="gx-ml-6 gx-mb-3"
          width="97%"
          height={350}>
          {graphBy === "percenttowordprincipal" || graphBy === 'monthlypayment' ?

            <LineChart
              data={cleanData}
              margin={{ top: 0, right: 10, left: 30, bottom: 0 }}>
              <CartesianGrid vertical={false} strokeDasharray="6 6" />
              <XAxis dataKey="name" />
              {
                graphBy === "percenttowordprincipal" ?
                  <YAxis type="number" domain={[0, 100]} tickFormatter={value => value.toFixed(0) + '%'} />
                  :
                  <YAxis type="number" domain={[0, 'dataMax']} tickFormatter={value => numeral(value).format('$0a')} />
              }
              <Tooltip content={renderTooltipContent} />
              <Legend
                // content={renderLegend}
                iconSize={16}
                iconType="circle"
                verticalAlign="top"
                height={36}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
              {loanPortfolio.processedLoans.map((_data, index) => {
                // console.log(_data);
                return (
                  <Line
                    key={"line" + index}
                    type="monotone"
                    dataKey={_data.name}
                    stroke={chartColors[_data.id]}
                    dot={null}
                  />
                );
              })}
            </LineChart>

            :
            <AreaChart
              data={cleanData}
              margin={{ top: 0, right: 10, left: 30, bottom: 0 }}>
              <CartesianGrid vertical={false} strokeDasharray="6 6" />
              <XAxis dataKey="name" />
              <YAxis tickFormatter={value => numeral(value).format('$0a')} />
              <Tooltip content={renderTooltipContent} />

              <Legend
                // content={renderLegend}
                iconSize={16}
                iconType="circle"
                verticalAlign="top"
                height={36}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
              {loanPortfolio.processedLoans.map((_data, index) => {
                // console.log(_data);
                return (
                  <Area
                    key={"area" + index}
                    type="monotone"
                    dataKey={_data.name}
                    strokeOpacity={opacity[_data.name]}
                    fillOpacity={opacity[_data.name]}
                    fill={chartColors[_data.id]}
                    stroke={chartColors[_data.id]}
                    // stackId={index}
                    stackId={1}
                  />
                );
              })}
            </AreaChart>
          }
        </ResponsiveContainer>
      )}

      {(loanFreq === 'quarterly' || loanFreq === 'yearly') && (

        <ResponsiveContainer
          className="gx-ml-3 gx-mb-3"
          width="97%"
          height={350}>

          {graphBy === "percenttowordprincipal" || graphBy === 'monthlypayment' ?

            <LineChart
              data={_cleanData}
              margin={{ top: 0, right: 10, left: 30, bottom: 0 }}>
              <CartesianGrid vertical={false} strokeDasharray="6 6" />
              <XAxis dataKey="name" />
              {
                graphBy === "percenttowordprincipal" ?
                  <YAxis type="number" domain={[0, 100]} tickFormatter={value => value.toFixed(0) + '%'} />
                  :
                  <YAxis type="number" domain={[0, 'dataMax']} tickFormatter={value => numeral(value).format('$0a')} />
              }
              <Tooltip content={renderTooltipContent} />
              <Legend
                // content={renderLegend}
                iconSize={16}
                iconType="circle"
                verticalAlign="top"
                height={36}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
              {loanPortfolio.processedLoans.map((_data, index) => {
                // console.log(_data);
                return (
                  <Line
                    key={"line" + index}
                    type="monotone"
                    dataKey={_data.name}
                    stroke={chartColors[_data.id]}
                    dot={null}
                  />
                );
              })}
            </LineChart>
            :
            <BarChart
              data={_cleanData}
              margin={{ top: 0, right: 10, left: 10, bottom: 0 }}>
              <XAxis dataKey="name" />
              <Tooltip content={renderTooltipContent} />
              <Legend />
              {loanPortfolio.processedLoans.map((_data, c) => (
                <Bar key={"bar" + c} dataKey={_data.name} stackId={c} fill={chartColors[_data.id]} />
              ))}
            </BarChart>
          }
        </ResponsiveContainer>
      )}
    </Widget>
  );
};

export default LoanChart;
