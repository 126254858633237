import React, {useState} from 'react';
import {Avatar, Menu, Dropdown} from 'antd/lib';
import {Link} from 'react-router-dom';
import FirebaseSocial from 'firebase/firebaseSocial';
import {useDocumentData} from 'react-firebase-hooks/firestore';
import DisplayDate from 'util/DisplayDate';
const RequestCell = ({data, group, approveRejectJoinRequest}) => {
  const [memberData, memberDataLoading] = useDocumentData(
    FirebaseSocial.getUserDocumentData(data.memberId),
    {
      snapshotListenOptions: {includeMetadataChanges: true},
    },
  );
  const [actionStatus, setActionStatus] = useState(0);

  const actionFired = action => {
    setActionStatus(action);
  };

  const options = ['Approve', 'Reject'];

  const menus = () => (
    <Menu
      onClick={e => {
        if (e.key === 'Approve') {
          approveRejectJoinRequest(data, 1, group);
          actionFired(1);
        } else {
          approveRejectJoinRequest(data, 2, group);
          actionFired(2);
        }
      }}>
      {options.map(option => (
        <Menu.Item key={option}>{option}</Menu.Item>
      ))}
    </Menu>
  );

  const cell = () => {
    return (
      <div className="gx-contact-item gx-pl-0">
        <div className="gx-module-list-icon">
          <div className="gx-ml-2 gx-d-none gx-d-sm-flex">
            <Link
              to={{
                pathname: `/app/user/${memberData.vanityURL}`,
                userId: memberData.userId,
              }}>
              {memberData.avatar === null || memberData.avatar === '' ? (
                <Avatar size="large">
                  {memberData.username.charAt(0).toUpperCase()}
                </Avatar>
              ) : (
                <Avatar
                  size="large"
                  alt={memberData.username}
                  src={memberData.avatar}
                />
              )}
            </Link>
          </div>
        </div>

        <div
          className="gx-module-list-info gx-contact-list-info"
          style={{maxWidth: '100%'}}>
          <div className="gx-module-contact-content">
            <p className="gx-mb-1">
              <span className="gx-text-truncate gx-contact-name">
                <Link
                  to={{
                    pathname: `/app/user/${memberData.vanityURL}`,
                    userId: memberData.userId,
                  }}>
                  {memberData.username}{' '}
                </Link>{' '}
              </span>
            </p>

            <div className="gx-text-muted">
              <span className="gx-email gx-d-inline-block gx-mr-2">
                Requested -
                <DisplayDate date={data.date.toString()} />
              </span>
              {/* <span className="gx-phone gx-d-inline-block">{"phone"}</span> */}
            </div>
          </div>
          <div className="gx-module-contact-right">
            {actionStatus ? (
              actionStatus === 1 ? (
                <p
                  className="gx-ml-auto gx-mb-0 gx-btn-block "
                  style={{color: 'green'}}>
                  Approved
                </p>
              ) : (
                <p
                  className="gx-ml-auto gx-mb-0 gx-btn-block "
                  style={{color: 'red'}}>
                  Rejected
                </p>
              )
            ) : (
              <Dropdown
                overlay={() => menus()}
                placement="bottomRight"
                trigger={['click']}>
                <i className="gx-icon-btn icon icon-ellipse-v" />
              </Dropdown>
            )}
          </div>
        </div>
      </div>
    );
  };

  return memberDataLoading ? null : data && memberData ? cell() : null;
};

export default RequestCell;
