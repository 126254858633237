import React from 'react';
import {Link} from 'react-router-dom';
import {message, Avatar, Modal} from 'antd/lib';
import FirebaseSocial from 'firebase/firebaseSocial';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';

const FRNotification = ({request}) => {
  const intlMessages = useFormatMessage();
  const {confirm} = Modal;

  const acceptRequest = id => {
    confirm({
      title: 'Accept friend request?',
      onOk: () => {
        FirebaseSocial.friend(id);
        message.success(intlMessages('social.friend.success'));
      },
      onCancel() {},
    });
  };

  const rejectRequest = id => {
    confirm({
      title: 'Reject friend request?',
      onOk: () => {
        FirebaseSocial.rejectRequest(id);
        message.success(intlMessages('social.unfriend.success'));
      },
      onCancel() {},
    });
  };

  return (
    <li className="gx-media">
      <Link
        to={{pathname: `/app/user/${request.vanityURL}`, userId: request.id}}>
        <Avatar className="gx-size-40 gx-mr-3" src={request.avatar} />
      </Link>
      <div className="gx-media-body gx-align-self-center">
        <p className="gx-fs-sm gx-mb-0">
          {'Friend Request from ' + request.username}
        </p>
        <div className="gx-flex-row">
          <a
            href
            key="list-loadmore-edit"
            onClick={() => acceptRequest(request.id)}>
            Accept
          </a>
          <div className="gx-toolbar-separator">&nbsp;</div>
          <a
            href
            key="list-loadmore-more"
            onClick={() => rejectRequest(request.id)}>
            Reject
          </a>
        </div>
      </div>
    </li>
  );
};

export default FRNotification;
