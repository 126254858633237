import React from 'react';
import { Avatar, Popover, Row } from 'antd/lib';
import { Link } from 'react-router-dom';
import { useProfile } from 'auth';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { FirebaseDAO } from 'firebase/firebase';
import FirebaseSocial from 'firebase/firebaseSocial';

const UserProfile = ({ mode }) => {
  const profile = useProfile();

  const [user, userLoading, userError] = useDocumentData(
    FirebaseSocial.getUserDocumentData(FirebaseDAO.auth.currentUser.uid),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );

  if (!user && !profile.guest) {
    return null;
  } else if (!user && profile.guest) {
    return (
      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
        <div style={{ margin: 'auto' }}>
          <Row type="flex" justify="space-around" align="middle">
            <Avatar
              src={profile.avatar}
              className="gx-size-40 gx-pointer gx-mb-10 gx-ml-20"
            />
          </Row>
          <Popover
            placement="bottomRight"
            content={
              <ul className="gx-user-popover">
                <li>
                  <Link to="/signin">Sign Up or Sign In</Link>
                </li>
              </ul>
            }
            trigger="click">
            <Row type="flex">
              <span className="gx-avatar-name">
                {profile && (
                  <div>
                    {profile.username}
                    <i
                      className="icon icon-chevron-down gx-fs-s gx-ml-1"
                      style={{ verticalAlign: 'sub' }}
                    />
                  </div>
                )}
              </span>
            </Row>
          </Popover>
        </div>
      </div>
    );
  } else {
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li>
          <Link to="/app/loans">My Loans</Link>
        </li>
        <li>
          <Link to={{ pathname: `/app/user/${user.vanityURL}`, userId: user.id }}>
            My Profile
          </Link>
        </li>
        <li>
          <Link to="/signout">Sign Out</Link>
        </li>
      </ul>
    );

    if (mode === 'compact') {
      return (
        <Popover
          overlayClassName="gx-popover-horizantal"
          placement="bottomLeft"
          content={userMenuOptions}
          trigger="click">
          <Row type="flex">
            <span className="gx-avatar-name">
              {user && profile && (
                <Avatar
                  src={user.avatar}
                  className="gx-size-40 gx-pointer gx-mb-10 gx-ml-20"
                />
              )}
            </span>
          </Row>
        </Popover>
      );
    }

    return (
      <div>
        {userError && <strong>Connection error!</strong>}
        {userLoading && <p>Loading...</p>}
        {user && (
          <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
            <div style={{ margin: 'auto' }}>
              <Row type="flex" justify="space-around" align="middle">
                {user && (
                  <Link
                    to={{
                      pathname: `/app/user/${user.vanityURL}`,
                      userId: user.id,
                    }}>
                    <Avatar
                      src={user.avatar}
                      className="gx-size-40 gx-pointer gx-mb-10 gx-ml-20"
                    />
                  </Link>
                )}
              </Row>
              <Popover
                placement="bottomRight"
                content={userMenuOptions}
                trigger="click">
                <Row type="flex">
                  <span className="gx-avatar-name">
                    {profile && (
                      <div>
                        {profile.username}
                        <i
                          className="icon icon-chevron-down gx-fs-s gx-ml-1"
                          style={{ verticalAlign: 'sub' }}
                        />
                      </div>
                    )}
                  </span>
                </Row>
              </Popover>
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default UserProfile;
