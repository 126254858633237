import React from 'react';
import { Col, Row } from 'antd/lib';
import { Area, AreaChart, ResponsiveContainer, Tooltip } from 'recharts';
import Auxiliary from 'util/Auxiliary';
import NetWorthChartCard from 'components/app/widgets/NetWorthChartCard';
import CashflowChartCard from 'components/app/widgets/CashflowChartCard';
import IncomeChartCard from 'components/app/widgets/IncomeChartCard';
import LoansChartCard from 'components/app/widgets/LoansChartCard';
import CreditCardChartCard from 'components/app/widgets/CreditCardChartCard';
import StackedNetWorthChart from 'components/app/widgets/StackedNetWorthChart';
import { citiesData, queriesData, visitsData } from 'routes/main/Metrics/data';
import { WelcomeCard, AssetsLiabilitiesCard } from './components';
import RecentTransactions from 'components/app/widgets/RecentTransactions';
import RecentAccounts from 'components/app/widgets/RecentAccounts';
import { LoanStep } from '../settings/components';
import Goals from '../goals';
import { withOptimizely } from '@optimizely/react-sdk';

const Dashboard = ({ profile, optimizely }) => {
  let welcomeDisplayed = localStorage.getItem('welcome_displayed');
  if (!welcomeDisplayed) {
    // Set the display cookie after 60 seconds
    setTimeout(() => {
      localStorage.setItem('welcome_displayed', true);
    }, 60000);
  }

  return (
    <Auxiliary>
      <Row>
        {optimizely.isFeatureEnabled('debt_asset_stats') ? (
          <Col xl={12} lg={12} md={12} sm={12} xs={24}>
            <AssetsLiabilitiesCard profile={profile} />
          </Col>
        ) : (
            <div className="gx-feature-disabled">
              Feature Flag Disabled by Optimizely
          </div>
          )}

        {!welcomeDisplayed && (
          <Col xl={12} lg={24} md={24} sm={24} xs={24}>
            <WelcomeCard profile={profile} />
          </Col>
        )}

        {optimizely.isFeatureEnabled('net_worth') ? (
          <Col xl={6} lg={12} md={12} sm={12} xs={24}>
            <NetWorthChartCard
              chartProperties={{
                title: 'NET WORTH',
                prize: '26,873',
                icon: 'stats',
                bgColor: 'primary',
                styleName: 'up',
                desc: 'This week',
                percent: '3%',
              }}
              children={<StackedNetWorthChart />}
            />
          </Col>
        ) : (
            <div className="gx-feature-disabled">
              Feature Flag Disabled by Optimizely
          </div>
          )}

        {optimizely.isFeatureEnabled('cash_flow') ? (
          <Col xl={6} lg={12} md={12} sm={12} xs={24}>
            <CashflowChartCard
              chartProperties={{
                title: 'CASHFLOW',
                prize: '3,840',
                icon: 'stats',
                bgColor: 'orange',
                styleName: 'up',
                desc: '2%^',
                percent: '20',
              }}
              children={
                <ResponsiveContainer width="100%" height={75}>
                  <AreaChart
                    data={citiesData}
                    margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                    <Tooltip />
                    <Area
                      dataKey="cities"
                      type="monotone"
                      strokeWidth={0}
                      stackId="2"
                      stroke="#C87000"
                      fill="#C87000"
                      fillOpacity={1}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              }
            />
          </Col>
        ) : (
            <div className="gx-feature-disabled">
              Feature Flag Disabled by Optimizely
          </div>
          )}

        {optimizely.isFeatureEnabled('income') ? (
          <Col xl={6} lg={12} md={12} sm={12} xs={24}>
            <IncomeChartCard
              chartProperties={{
                title: 'INCOME',
                prize: '84,700',
                icon: 'stats',
                bgColor: 'teal',
                styleName: 'down',
                desc: 'Avg. 327 visits daily',
                percent: '',
              }}
              children={
                <ResponsiveContainer width="100%" height={75}>
                  <AreaChart
                    data={visitsData}
                    margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                    <Tooltip />
                    <Area
                      dataKey="visit"
                      strokeWidth={0}
                      stackId="2"
                      stroke="#158765"
                      fill="#158765"
                      fillOpacity={1}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              }
            />
          </Col>
        ) : (
            <div className="gx-feature-disabled">
              Feature Flag Disabled by Optimizely
          </div>
          )}

        {optimizely.isFeatureEnabled('loans') ? (
          <Col xl={6} lg={12} md={12} sm={12} xs={24}>
            <LoansChartCard
              chartProperties={{
                title: 'LOANS',
                prize: '$234,239',
                icon: 'stats',
                bgColor: 'red',
                styleName: 'down',
                desc: 'from past month',
                percent: '10',
              }}
              children={
                <ResponsiveContainer width="100%" height={75}>
                  <AreaChart
                    data={queriesData}
                    margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                    <Tooltip />
                    <Area
                      dataKey="queries"
                      strokeWidth={0}
                      stackId="2"
                      stroke="#BB1258"
                      fill="#BB1258"
                      fillOpacity={1}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              }
            />
          </Col>
        ) : (
            <div className="gx-feature-disabled">
              Feature Flag Disabled by Optimizely
          </div>
          )}

        {optimizely.isFeatureEnabled('credit_cards') ? (
          <Col xl={6} lg={12} md={12} sm={12} xs={24}>
            <CreditCardChartCard
              chartProperties={{
                title: 'CREDIT CARDS',
                prize: '$14,789',
                icon: 'stats',
                bgColor: 'red',
                styleName: 'down',
                desc: 'from past month',
                percent: '3',
              }}
              children={
                <ResponsiveContainer width="100%" height={75}>
                  <AreaChart
                    data={queriesData}
                    margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                    <Tooltip />
                    <Area
                      dataKey="queries"
                      strokeWidth={0}
                      stackId="2"
                      stroke="#BB1258"
                      fill="#BB1258"
                      fillOpacity={1}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              }
            />
          </Col>
        ) : (
            <div className="gx-feature-disabled">
              Feature Flag Disabled by Optimizely
          </div>
          )}
      </Row>

      <Row>
        {optimizely.isFeatureEnabled('transactions') ? (
          <Col xl={12} lg={12} md={12} sm={12} xs={24}>
            <RecentTransactions profile={profile} />
          </Col>
        ) : (
            <div className="gx-feature-disabled">
              Feature Flag Disabled by Optimizely
          </div>
          )}

        {optimizely.isFeatureEnabled('accounts') ? (
          <Col xl={12} lg={12} md={12} sm={12} xs={24}>
            <RecentAccounts profile={profile} />
          </Col>
        ) : (
            <div className="gx-feature-disabled">
              Feature Flag Disabled by Optimizely
          </div>
          )}

        {optimizely.isFeatureEnabled('loans') ? (
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <LoanStep profile={profile} />
          </Col>
        ) : (
            <div className="gx-feature-disabled">
              Feature Flag Disabled by Optimizely
          </div>
          )}

        {optimizely.isFeatureEnabled('goals') ? (
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Goals profile={profile} />
          </Col>
        ) : (
            <div className="gx-feature-disabled">
              Feature Flag Disabled by Optimizely
          </div>
          )}
      </Row>
    </Auxiliary>
  );
};

export default withOptimizely(Dashboard);
