import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import Messages from './Messages';
import Settings from './Settings';
import Auth from './Auth';
import Notes from './Notes';
import Common from './Common';
import Templates from './Templates';
import {firebaseReducer} from 'react-redux-firebase';
import {firestoreReducer} from 'redux-firestore';

export default history =>
  combineReducers({
    router: connectRouter(history),
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    messages: Messages,
    settings: Settings,
    auth: Auth,
    notes: Notes,
    common: Common,
    templates: Templates,
  });
