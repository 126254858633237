import React from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { Cell, Pie, PieChart, Tooltip, ResponsiveContainer } from 'recharts';
import numeral from 'numeral';
import { NetWorthPieChartCustomTooltip } from 'util/chartUtils';
import { FirebaseDAO } from 'firebase/firebase';
import CircularProgress from 'components/CircularProgress/index';
import { useSpring, animated } from 'react-spring';
import '../widgets.less';

// const COLORS = ['#bbeaa6', '#ed9a73'];
const COLORS = ['#00C49F', '#FF8042'];

const NetWorthPieChart = () => {
  // Retrieve user profile
  const [profile] = useDocumentData(
    FirebaseDAO.getFBCurrentUserDocumentData(),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );

  let chartData = [];
  let networthValue = 0;
  let prevNetworth = networthValue;

  if (profile) {
    let a =
      profile.financial.networth && profile.financial.networth.assets
        ? parseFloat(profile.financial.networth.assets)
        : 0;
    let l =
      profile.financial.networth && profile.financial.networth.liabilities
        ? parseFloat(profile.financial.networth.liabilities)
        : 0;
    chartData = [{ name: 'Assets', value: a }, { name: 'Liabilities', value: l }];
    networthValue = a - l;
  }

  const props = useSpring({
    number: networthValue,
    from: { number: prevNetworth },
  });

  if (!profile && !chartData) {
    return (
      <div className="networthchart">
        <div className="gx-fillchart-content">
          <CircularProgress />
        </div>
      </div>
    );
  } else {
    return (
      <div className="networthchart">
        <div className="gx-fillchart-content">
          <div className="networth-title">NET WORTH</div>
          <h2
            className={
              'networth-amount gx-mb-2 gx-fs-xxl gx-font-weight-medium ' +
              (networthValue < 0 ? 'danger' : 'primary')
            }>
            <animated.span>
              {props.number.interpolate(val =>
                numeral(Math.floor(val)).format('$0,0'),
              )}
            </animated.span>
          </h2>
        </div>

        <ResponsiveContainer>
          <PieChart margin={{ top: 5, right: 70, bottom: 0, left: 70 }}>
            >
            <Tooltip content={<NetWorthPieChartCustomTooltip />} />
            <Pie
              dataKey="value"
              data={chartData}
              cx="70%"
              cy="50%"
              startAngle={180}
              endAngle={0}
              innerRadius={80}
              outerRadius={100}
              fill="#003366"
              paddingAngle={5}>
              {chartData.map((entry, index) => (
                <Cell key={index} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  }
};

export default NetWorthPieChart;
