import React, { useRef } from 'react';
import { Card, Col, Row, Spin } from 'antd/lib';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import FirebaseSocial from 'firebase/firebaseSocial';
import { FaSpinner } from 'react-icons/fa';
import GuestRedirect from 'containers/GuestRedirect';
import { useModal, Modal } from 'react-morphing-modal';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-morphing-modal/dist/ReactMorphingModal.css';
import '../Posts/slickCustomStyles.less';
import Carousel from 'util/Carousel'

const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;

const PhotoGallery = ({ userId, guest }) => {
  const triggerRef = useRef(null);
  const { modalProps, open } = useModal({ background: '#000' });
  const handleModalOpen = () => open(triggerRef);
  const [user, userLoading, userError] = useDocumentData(
    FirebaseSocial.getUserDocumentData(userId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );

  if (!user) {
    return <Spin indicator={spinnerIcon} />;
  } else if (guest) {
    return <GuestRedirect />;
  } else {
    let userMedia = user.social.mediaArray;
    if (userMedia && userMedia.length > 0) {
      return (
        <div>
          {userError && <strong>Connection error!</strong>}
          {userLoading && <p>Loading...</p>}
          {user && (
            <Card title="User gallery">
              <div ref={triggerRef} onClick={handleModalOpen}>
                <Row align="top" gutter={[4, 4]}>
                  {userMedia.map((media, index) => (
                    <Col key={index} xl={6} lg={8} md={12} sm={24} xs={24}>
                      <img style={{ cursor: 'pointer' }} alt={`Gallery ${media.name}`} src={media.uri} />
                    </Col>
                  ))}
                </Row>
              </div>
              <Modal {...modalProps}>
                <Carousel mediaList={userMedia} />
              </Modal>
            </Card>
          )}
        </div>
      )
    } else {
      return (
        <Card>
          <div className="text-center">
            <h2>This user hasn't shared any images yet.</h2>
          </div>
        </Card>
      )
    }
  }
};

export default PhotoGallery;
