import React, {Fragment} from 'react';
import DisplayAppointmentCell from './DisplayAppointmentCell';

const DisplayAppointmentGrid = ({
  data,
  editMyAppoientment,
  cancelAppointment,
  section,
  canUpdate = true,
  upcomingSearch,
  pastSearch,
}) => {
  const manageButton = {
    editMyAppoientment,
    cancelAppointment,
  };
  return (
    <Fragment>
      {data &&
        data.map((appointment, index) => (
          <DisplayAppointmentCell
            key={index}
            data={appointment}
            manageButton={manageButton}
            section={section}
            view={'grid'}
            canUpdate={canUpdate}
            upcomingSearch={upcomingSearch}
            pastSearch={pastSearch}
          />
        ))}
    </Fragment>
  );
};

export default DisplayAppointmentGrid;
