import React from 'react';
import { Row, Col, Divider } from 'antd/lib';
import { FaSquareFull, FaRegStar } from 'react-icons/fa';
import PlotPieChart from './PlotPieChart';
import AnimatedCurrencyDisplay from '../../../common/AnimatedCurrencyDisplay';
import Widget from 'components/Widget/index';
import './style.css';

const compareAccounts = (a, b) => {
    const instA = a.balance;
    const instB = b.balance;
    let comparison = 0;
    if (instA < instB) {
        comparison = 1;
    } else if (instA > instB) {
        comparison = -1;
    }
    return comparison;
}

const NetWorthChart = ({ accounts, accountBalance, assetsColors, renderTooltipContent }) => {
    let accountNames = [];
    let chartData = [];
    if (accounts && accounts.length) {
        accounts = accounts.sort(compareAccounts);
        accounts.forEach(account => {
            accountNames.push(
                <Row key={'account' + account.id}>
                    <Col xl={4} lg={5} md={5} sm={6} xs={2}>
                        <FaSquareFull className="gx-mr-1"
                            style={{ color: assetsColors[account.id] }}
                        />
                    </Col>
                    <Col xl={20} lg={19} md={19} sm={18} xs={22}>
                        <span style={{ display: 'block' }} className="no-wrap gx-text-grey gx-fs-sm gx-mb-0">{account.name}</span>
                    </Col>
                    <Col xl={3}></Col>
                    <Col xl={20}>
                        <Divider style={{ marginTop: '2px', marginBottom: '2px' }} className="gx-text-grey gx-fs-sm" />
                    </Col>
                    <Col xl={1}></Col>
                </Row>

            );
            chartData.push({ name: account.name, value: account.balance, id: account.id })
        });
    } else {
        accountNames.push(
            <p key={'account'}>
                <FaSquareFull
                    style={{ color: 'gray' }}
                />
                <span style={{ width: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}  >No Account</span>
            </p>
        );
        chartData.push({ name: 'No Account', value: 0, id: 0 })
    }

    return (
        <Widget styleName='gx-card-full gx-py-3 gx-px-2'>
            <h4 className="gx-text-muted gx-pl-5">ASSETS</h4>
            <Row>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <PlotPieChart
                        data={chartData}
                        title={'Assets'}
                        colors={assetsColors}
                        tooltip={renderTooltipContent}
                        outerRadius={55}
                        innerRadius={35}
                        height={150}
                    />
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24} >
                    <h2>
                        <AnimatedCurrencyDisplay
                            minimum={0}
                            maximum={accountBalance}
                        />
                    </h2>
                    {accountNames}
                </Col>
                <Col xl={24} xs={0}>
                    <div className="gx-text-muted gx-pl-5">
                        <h5><FaRegStar className="gx-mr-1" />Pro Tip</h5>
                        <p>Try to save three to six months of expenses or start with $1000 as an emergency fund.</p>
                    </div>
                </Col>
            </Row >
        </Widget>
    );
}

export default NetWorthChart;