import React, {Component} from 'react';
import {Layout, Popover} from 'antd';
import {Link} from 'react-router-dom';
import CustomScrollbars from 'util/CustomScrollbars';
import languageData from './languageData';
import {switchLanguage, toggleCollapsedSideNav} from 'appRedux/actions/Setting';
import SearchBox from 'components/SearchBox';
import DateRangeBox from 'components/DateRangeBox';
import UserInfo from 'components/UserInfo';
import AppNotification from 'components/AppNotification';
import MailNotification from 'components/MailNotification';
import Auxiliary from 'util/Auxiliary';
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from 'constants/ThemeSetting';
import {connect} from 'react-redux';
import {OptimizelyFeature} from '@optimizely/react-sdk';
import {history} from 'appRedux/store';

const {Header} = Layout;

class Topbar extends Component {
  state = {
    searchText: '',
  };

  languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language => (
          <li
            className="gx-media gx-pointer"
            key={JSON.stringify(language)}
            onClick={e => this.props.switchLanguage(language)}>
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );

  updateSearchChatUser = evt => {
    this.setState({
      searchText: evt.target.value,
    });
  };

  updateSearchQuery = val => {
    console.log('Search: ', val);

    this.setState({
      searchText: val,
    });

    history.push(`/app/search?query=${this.state.searchText}`);
  };

  onDatePickerChange = (dates, dateStrings) => {
    console.log('From: ', dates[0], ', to: ', dates[1]);
    console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
  };

  render() {
    const {locale, width, navCollapsed, navStyle} = this.props; // eslint-disable-line
    return (
      <Auxiliary>
        <Header>
          {navStyle === NAV_STYLE_DRAWER ||
          ((navStyle === NAV_STYLE_FIXED ||
            navStyle === NAV_STYLE_MINI_SIDEBAR) &&
            width < TAB_SIZE) ? (
            <div className="gx-linebar gx-mr-3">
              <i
                className="gx-icon-btn icon icon-menu"
                onClick={() => {
                  this.props.toggleCollapsedSideNav(!navCollapsed);
                }}
              />
            </div>
          ) : (
            <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
              <img alt="" src={require('assets/images/loanpalz-logo.png')} />
            </Link>
          )}

          <OptimizelyFeature feature="search_users">
            {isEnabled =>
              isEnabled ? (
                <SearchBox
                  styleName="gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg"
                  placeholder="Search Users..."
                  onChange={this.updateSearchChatUser.bind(this)}
                  value={this.state.searchText}
                />
              ) : (
                <div className="gx-feature-disabled">
                  Feature Flag Disabled by Optimizely
                </div>
              )
            }
          </OptimizelyFeature>

          <DateRangeBox
            styleName="gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg"
            onChange={this.onDatePickerChange.bind(this)}
          />

          <ul className="gx-header-notifications gx-ml-auto">
            <OptimizelyFeature feature="search_users">
              {isEnabled =>
                isEnabled ? (
                  <li className="gx-notify gx-notify-search gx-d-inline-block gx-d-lg-none gx-pointer">
                    <Popover
                      overlayClassName="gx-popover-horizantal"
                      placement="bottomRight"
                      content={
                        <SearchBox
                          styleName="gx-popover-search-bar"
                          placeholder="Search Users..."
                          onChange={this.updateSearchChatUser.bind(this)}
                          value={this.state.searchText}
                        />
                      }
                      trigger="click">
                      <span className="gx-pointer gx-d-block">
                        <i className="icon icon-search-new" />
                      </span>
                    </Popover>
                  </li>
                ) : (
                  <div className="gx-feature-disabled">
                    Feature Flag Disabled by Optimizely
                  </div>
                )
              }
            </OptimizelyFeature>

            <li className="gx-notify gx-notify-search gx-d-inline-block gx-d-lg-none gx-pointer">
              <Popover
                overlayClassName="gx-popover-horizantal"
                placement="bottomRight"
                content={
                  <DateRangeBox
                    styleName="gx-popover-search-bar"
                    onChange={this.onDatePickerChange.bind(this)}
                  />
                }
                trigger="click">
                <span className="gx-pointer gx-d-block">
                  <i className="icon icon-calendar" />
                </span>
              </Popover>
            </li>

            {width >= TAB_SIZE ? null : (
              <Auxiliary>
                <li className="gx-notify">
                  <Popover
                    overlayClassName="gx-popover-horizantal"
                    placement="bottomRight"
                    content={<AppNotification />}
                    trigger="hover">
                    <span className="gx-pointer gx-d-block">
                      <i className="icon icon-notification" />
                    </span>
                  </Popover>
                </li>

                <li className="gx-msg">
                  <MailNotification
                    iconText={<i className="icon icon-chat-new" />}
                  />
                </li>
              </Auxiliary>
            )}
            {/* <li className='gx-language'>
              <Popover overlayClassName='gx-popover-horizantal' placement='bottomRight' content={this.languageMenu()} trigger='click'>
                <span className='gx-pointer gx-flex-row gx-align-items-center'>
                  <i className={`flag flag-24 flag-${locale.icon}`} />
                  <span className='gx-pl-2 gx-language-name'>{locale.name}</span>
                  <i className='icon icon-chevron-down gx-pl-2' />
                </span>
              </Popover>
            </li> */}
            {width >= TAB_SIZE ? null : (
              <Auxiliary>
                <li className="gx-user-nav">
                  <UserInfo />
                </li>
              </Auxiliary>
            )}
          </ul>
        </Header>
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({settings}) => {
  const {locale, navStyle, navCollapsed, width} = settings;
  return {locale, navStyle, navCollapsed, width};
};

export default connect(mapStateToProps, {
  toggleCollapsedSideNav,
  switchLanguage,
})(Topbar);
