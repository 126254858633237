import React from 'react';
import { Row, Col, Divider } from 'antd/lib';
import { FaSquareFull, FaRegSmile } from 'react-icons/fa';
import Widget from 'components/Widget/index';
import PlotPieChart from './PlotPieChart';
import AnimatedCurrencyDisplay from '../../../common/AnimatedCurrencyDisplay';
import './style.css';

const compareLoans = (a, b) => {
    const instA = a.currentBalance;
    const instB = b.currentBalance;
    let comparison = 0;
    if (instA < instB) {
        comparison = 1;
    } else if (instA > instB) {
        comparison = -1;
    }
    return comparison;
}

const LiabilityChart = ({ loans, startingBalance, liabilityColors, renderTooltipContent }) => {
    let loanNames = [];
    let chartData = [];
    if (loans && loans.length) {
        loans = loans.sort(compareLoans);
        loans.forEach(loan => {
            loanNames.push(
                <Row key={'loan' + loan.id}>
                    <Col xl={4} lg={5} md={5} sm={6} xs={7}>
                        <FaSquareFull
                            style={{ color: liabilityColors[loan.id] }}
                        />
                    </Col>
                    <Col xl={20} lg={19} md={19} sm={18} xs={17}>
                        <span style={{ display: 'block' }} className="no-wrap gx-text-grey gx-fs-sm gx-mb-0">{loan.name}</span>
                    </Col>
                    <Col xl={3}></Col>
                    <Col xl={20}>
                        <Divider style={{ marginTop: '2px', marginBottom: '2px' }} className="gx-text-grey gx-fs-sm" />
                    </Col>
                    <Col xl={1}></Col>
                </Row>
            );
            chartData.push({ name: loan.name, value: loan.currentBalance, id: loan.id })
        });
    } else {
        loanNames.push(
            <p key={'loan'}>
                <FaSquareFull
                    style={{ color: 'gray' }}
                /> No Loan
            </p>
        );
        chartData.push({ name: 'No Loan', value: 0, id: 0 })
    }
    return (
        <Widget styleName='gx-card-full gx-py-3 gx-px-2'>
            <h4 className="gx-text-muted gx-pl-5">LIABLITIES</h4>
            <Row >
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <PlotPieChart
                        data={chartData}
                        title={'Liabilities'}
                        colors={liabilityColors}
                        tooltip={renderTooltipContent}
                        outerRadius={55}
                        innerRadius={35}
                        height={150}
                    />
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <h2>
                        <AnimatedCurrencyDisplay
                            minimum={0}
                            maximum={startingBalance}
                        />
                    </h2>
                    {loanNames}
                </Col>
                <Col xl={24} xs={0}>
                    <div className="gx-text-muted gx-pl-5">
                        <h5><FaRegSmile className="gx-mr-1" />Did you know?</h5>
                        <p>People who work with a coach pay off their debts 40% faster. </p>
                    </div>
                </Col>
            </Row>
        </Widget>
    );
}
export default LiabilityChart;