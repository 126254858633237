import React from 'react';
import { Form, Input } from 'antd/lib';
const MessageSearchBox = ({ styleName, placeholder, onChange, value, filterChatUser }) => {
    const [form] = Form.useForm();
    const submitForm = async values => {
        filterChatUser(values.search);
    };

    return (
        <div className={`gx-search-bar ${styleName}`}>
            <div className="gx-form-group">
                <Form form={form} onFinish={submitForm} >
                    <Form.Item name="search">
                        <Input className="ant-input" type="search" placeholder={placeholder} onChange={el => onChange(el)} />
                    </Form.Item>
                    <span className="gx-search-icon gx-pointer">
                        <i className="icon icon-search" />
                    </span>
                </Form>
            </div>
        </div>
    );
};
export default MessageSearchBox;

MessageSearchBox.defaultProps = {
    styleName: '',
    value: ''
};
