import React, { useEffect, useState } from 'react';
import FirebaseGoals from 'firebase/firebaseGoals';
import FirebaseFin from 'firebase/firebaseFin';
import { Row, Col, Progress, Card, Modal, message } from 'antd';
import moment from 'moment';
import IntlMessages from 'util/IntlMessages';
import { TEMPORARY_GOAL_IMAGE } from '../../../util/constants';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import _ from 'lodash';
import numeral from 'numeral';
import GoalForm from '../goalForm/GoalForm';
import { history } from 'appRedux/store';
import ManageGoalButtons from './ManageGoalButtons';
import IconWithTextCard from 'components/Metrics/IconWithTextCard';

const dayFormat = 'D-MMM-YYYY';

const GoalDetails = ({ match }) => {
  const [goal, setGoal] = useState({});
  const [tempGoal, setTempGoal] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const { confirm } = Modal;
  const goalsRoute = '/app/goals';

  const [accounts] = useCollectionData(FirebaseFin.getBankAccounts(), {
    idField: 'id',
    snapshotListenOptions: {
      includeMetadataChanges: true,
    },
  });

  const backToList = () => {
    history.push(goalsRoute);
  };

  const saveGoal = goal => {
    setTempGoal(goal);
    setGoal(goal);
  };

  const editGoal = goal => {
    setTempGoal(goal);
    setModalVisible(true);
  };

  const deleteGoal = goal => {
    confirm({
      title: 'Do you want to delete this goal?',
      onOk: () => {
        FirebaseGoals.deleteGoal(goal);
        message.success('Goal Deleted!');
        backToList();
      },
      onCancel: () => { },
    });
  };

  const manage = {
    editGoal,
    deleteGoal,
  };

  // TODO: Refactor this ugly method
  const assignAccount = goalData => {
    if (goalData && Object.keys(goalData).length) {
      setGoal(goalData);
      let { currentAmount, goalAmount } = goalData;
      goalAmount = parseInt(goalAmount, 10);

      if (goalData.accountId) {
        const theAccount = _.find(accounts, { id: goalData.accountId });
        const hasValidBalance =
          theAccount &&
          typeof theAccount.balance !== 'undefined' &&
          theAccount.balance !== null;
        if (hasValidBalance) {
          currentAmount = parseInt(theAccount.balance.toFixed(2), 10);
        }
      } else {
        currentAmount = parseInt(currentAmount, 10);
      }
      const percent = (currentAmount / goalAmount) * 100;
      const progress = percent.toFixed(2);
      setGoal({ ...goalData, currentAmount, progress });
    }
  };

  useEffect(() => {
    FirebaseGoals.getGoal(match.params.id)
      .then(response => {
        setGoal(response);
        assignAccount(response);
      })
      .catch(err => {
        console.log(err);
      }); // eslint-disable-next-line
  }, [match.params.id, goal.accountId]);

  return (
    <div className="goal-wrapper">
      <div className="goals-heading">
        <h1>
          <IntlMessages id="goals.heading.myGoals" />
        </h1>
      </div>
      <GoalForm
        visible={modalVisible}
        setVisible={setModalVisible}
        setGoal={saveGoal}
        accounts={accounts}
        goal={tempGoal}
      />
      <Card
        size="small"
        className="goal-card flex-fill"
        cover={
          <img
            src={goal.image || TEMPORARY_GOAL_IMAGE}
            className="goal-cover-image-big"
            alt=""
          />
        }>
        <div className="d-flex">
          <div className="flex-fill">
            <i
              className="icon flex-grow icon-arrow-left gx-icon-btn"
              onClick={backToList}
            />
          </div>
          <div className="text-right">
            <ManageGoalButtons item={goal} manage={manage} />
          </div>
        </div>
        <Row className="m-1">
          <Col span={24}>
            <Row className="mb-3">
              <Col
                xl={18}
                lg={18}
                md={12}
                sm={12}
                xs={12}
                className="gx-col-full">
                <h1>{goal.name}</h1>
                <div className="goal-description">{goal.description}</div>
              </Col>

              <Col
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={12}
                className="gx-col-full">
                <div className="goal-meta-progress mt-3 mr-3">
                  <h4>
                    {goal.progress < 100 ? (
                      <IntlMessages id="goals.progress" />
                    ) : (
                        <IntlMessages id="goals.accomplished" />
                      )}
                  </h4>
                  <Progress
                    percent={goal.progress}
                    status={goal.progress < 100 ? 'active' : 'success'}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col
                xl={6}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="gx-col-full">
                <IconWithTextCard
                  icon="timepicker"
                  iconColor="geekblue"
                  title={moment(new Date(goal.goalPlannedDate * 1000), dayFormat).format(dayFormat)}
                  subTitle="Goal due date"
                />
              </Col>
              <Col
                xl={6}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="gx-col-full">
                <IconWithTextCard
                  icon="revenue-new"
                  iconColor="geekblue"
                  title={numeral(goal.goalAmount).format('$0,0')}
                  subTitle="Goal amount"
                />
              </Col>
              <Col
                xl={6}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="gx-col-full">
                <IconWithTextCard
                  icon="diamond"
                  iconColor="geekblue"
                  title={numeral(goal.currentAmount).format('$0,0')}
                  subTitle="Saved so far"
                />
              </Col>
              <Col
                xl={6}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="gx-col-full">
                <IconWithTextCard
                  icon="wysiwyg"
                  iconColor="geekblue"
                  title={numeral(goal.goalAmount - goal.currentAmount).format(
                    '$0,0',
                  )}
                  subTitle="Remaining amount"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  );
};
export default GoalDetails;
