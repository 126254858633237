import React from 'react';
import { Avatar, List, Dropdown, Progress, Col, Row, Menu } from 'antd/lib';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import moment from 'moment';
import { TEMPORARY_GOAL_IMAGE } from 'util/constants';

const dayFormat = 'D-MMM-YYYY';

const DisplayList = ({ data, editGoal, deleteGoal }) => {

  const options = ['Edit', 'Delete'];
  const manageGaol = item => (
    <Menu
      onClick={event => {
        if (event.key === 'Edit') {
          editGoal(item);
        } else {
          deleteGoal(item);
        }
      }}>
      {options.map(option => (
        <Menu.Item key={option}>{option}</Menu.Item>
      ))}
    </Menu>
  );
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col className="align-self-center" span={6}>
          Goal
        </Col>
        <Col className="align-self-center" span={5}>
          Due Date
        </Col>
        <Col className="align-self-center" span={4}>
          Target Amount
        </Col>
        <Col className="align-self-center" span={5}>
          Progress
        </Col>
        <Col className="align-self-center" span={4}></Col>
      </Row>

      <List
        itemLayout="vertical"
        size="small"
        dataSource={data}
        renderItem={item => (
          <List.Item key={item.title}>
            <Row>
              <Col className="align-self-center" span={6}>
                <Link to={`/app/goal/${item.id}`}>
                  <Avatar
                    shape="square"
                    size={40}
                    src={item.image || TEMPORARY_GOAL_IMAGE}
                    className="mr-3"
                  />
                  {item.name}
                </Link>
              </Col>
              <Col className="align-self-center" span={5}>
                {moment(new Date(item.goalPlannedDate * 1000), dayFormat).format(dayFormat)}
              </Col>
              <Col className="align-self-center" span={4}>
                {numeral(item.goalAmount).format('$0,0')}
              </Col>
              <Col className="align-self-center" span={5}>
                <Progress
                  percent={item.progress}
                  status={item.progress < 100 ? 'active' : 'success'}
                />
              </Col>
              <Col className="align-self-center" span={4}>
                <Dropdown
                  overlay={() => manageGaol(item)}
                  placement="bottomRight"
                  trigger={['click']}
                >
                  <i className="gx-icon-btn icon icon-ellipse-v float-right m-0 btn p-0 ant-dropdown-link border-0" />
                </Dropdown>
              </Col>
            </Row>
          </List.Item>
        )}
      />
    </React.Fragment>
  );
};

export default DisplayList;
