import {Button, Tabs} from 'antd/lib';
import {history} from 'appRedux/store';
import Widget from 'components/Widget';
import React from 'react';
import IntlMessages from 'util/IntlMessages';
import {
  AccountStep,
  IncomeStep,
  LoanStep,
  SettingsStep,
  GeneralNotification,
  SocialNotification,
  FinancialNotification,
  OtherNotification,
  ChangeEmail,
  ResetPassword,
  DeleteAccount,
} from './components';
import {
  EssentialStep,
  NonEssentialStep,
} from './components/Expenses/components';
import Customizer from 'containers/UiCustomizer';
import Transactions from '../transactions';

const TabPane = Tabs.TabPane;

const Settings = ({profile}) => {
  const onWizardOpen = () => history.push('/app/onboarding?from=/app/settings');

  return (
    <div>
      <Widget
        title="Settings"
        styleName="gx-card-tabs gx-card-tabs-left gx-card-profile">
        <Tabs
          tabPosition="left"
          defaultActiveKey="1"
          style={{marginTop: '0px'}}>
          <TabPane tab="General" key="1">
            <Tabs
              tabPosition="top"
              defaultActiveKey="1a"
              style={{marginTop: '0px'}}>
              <TabPane tab="Purpose" key="1a">
                <div className="gx-mb-2 gx-mt-3">
                  <h2>Purpose</h2>
                  <SettingsStep
                    profile={profile}
                    collection="purposes"
                    orientation="horizontal"
                  />
                </div>
              </TabPane>
              <TabPane tab="Goals" key="1b">
                <div className="gx-mb-2 gx-mt-3">
                  <h2>My Goals</h2>
                  <SettingsStep
                    profile={profile}
                    collection="goals"
                    orientation="vertical"
                  />
                </div>
              </TabPane>
              <TabPane tab="Personal Info" key="1c">
                <div className="gx-mb-2 gx-mt-3">
                  <h2>Personal Info</h2>
                  <SettingsStep
                    profile={profile}
                    collection="personal"
                    orientation="vertical"
                  />
                </div>
              </TabPane>
              <TabPane tab="Setup Wizard" key="1d">
                <div className="gx-mb-2 gx-mt-3">
                  <Button type="primary" onClick={() => onWizardOpen()}>
                    <IntlMessages id="settings.launchwizard" />
                  </Button>
                </div>
              </TabPane>
            </Tabs>
          </TabPane>

          <TabPane tab="Financial" key="200">
            <Tabs
              tabPosition="top"
              defaultActiveKey="2a"
              style={{marginTop: '0px'}}>
              <TabPane tab="Income" key="2a">
                <div className="gx-mb-2 gx-mt-3">
                  <IncomeStep />
                </div>
              </TabPane>
              <TabPane tab="Essential Expenses" key="2b">
                <div className="gx-mb-2 gx-mt-3">
                  <EssentialStep />
                </div>
              </TabPane>
              <TabPane tab="Non-essential Expenses" key="2c">
                <div className="gx-mb-2 gx-mt-3">
                  <NonEssentialStep />
                </div>
              </TabPane>
              <TabPane tab="Assets" key="2d">
                <div className="gx-mb-2 gx-mt-3">
                  <AccountStep />
                </div>
              </TabPane>
              <TabPane tab="Liabilities" key="2e">
                <div className="gx-mb-2 gx-mt-3">
                  <LoanStep />
                </div>
              </TabPane>
              <TabPane tab="Transactions" key="2f">
                <div className="gx-mb-2 gx-mt-3">
                  <Transactions profile={profile} section={'setting'} />
                </div>
              </TabPane>
            </Tabs>
          </TabPane>

          <TabPane tab="Site Settings" key="14">
            <div className="gx-mb-2 gx-mt-3">
              <Customizer profile={profile} />
            </div>
          </TabPane>

          <TabPane tab="Notifications" key="15">
            <div className="gx-mb-2 gx-mt-3">
              <Tabs defaultActiveKey="15a" style={{marginTop: '0px'}}>
                <TabPane tab="General" key="15a">
                  <div className="gx-mb-2 gx-mt-3">
                    <GeneralNotification profile={profile} />
                  </div>
                </TabPane>
                <TabPane tab="Social" key="15b">
                  <div className="gx-mb-2 gx-mt-3">
                    <SocialNotification profile={profile} />
                  </div>
                </TabPane>
                <TabPane tab="Financial" key="15c">
                  <div className="gx-mb-2 gx-mt-3">
                    <FinancialNotification profile={profile} />
                  </div>
                </TabPane>
                <TabPane tab="Other" key="15d">
                  <div className="gx-mb-2 gx-mt-3">
                    <OtherNotification profile={profile} />
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </TabPane>
          <TabPane tab="Authentication" key="16">
            <div className="gx-mb-2 gx-mt-3">
              <Tabs defaultActiveKey="16a" style={{marginTop: '0px'}}>
                <TabPane tab="Update Email" key="16a">
                  <div className="gx-mb-2 gx-mt-3">
                    <ChangeEmail profile={profile} />
                  </div>
                </TabPane>
                <TabPane tab="Reset Password" key="16b">
                  <div className="gx-mb-2 gx-mt-3">
                    <ResetPassword profile={profile} />
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </TabPane>
          <TabPane tab="Delete Account" key="17">
            <div className="gx-mb-2 gx-mt-3">
              <Tabs defaultActiveKey="17a" style={{marginTop: '0px'}}>
                <TabPane tab="Delete Account" key="17a">
                  <div className="gx-mb-2 gx-mt-3">
                    <DeleteAccount profile={profile} />
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </TabPane>
        </Tabs>
      </Widget>
    </div>
  );
};

export default Settings;
