import {Badge, Card, Checkbox, Tooltip} from 'antd';
import moment from 'moment';
import {
  EditOutlined,
  StarFilled,
  StarOutlined,
  DeleteOutlined,
  CheckCircleFilled,
  CloseCircleOutlined,
} from '@ant-design/icons';
import React from 'react';

const {Meta} = Card;
const dayFormat = 'D-MMM-YYYY';

const TaskItem = ({
  todo,
  onTodoChecked,
  onEditTask,
  onDeleteTask,
  onStarredTask,
  onMarkCompletedTask,
  taskFilterByLabel,
}) => {
  return (
    <Card
      hoverable={true}
      headStyle={{backgroundColor: '#eee'}}
      title={todo.title}
      actions={[
        <Tooltip title={todo.starred ? 'Unstar task' : 'Star task'}>
          <div
            onClick={() => {
              onStarredTask(todo);
            }}>
            {todo.starred ? <StarFilled /> : <StarOutlined />}
          </div>
        </Tooltip>,
        <Tooltip title="Delete task">
          <div
            onClick={() => {
              onDeleteTask(todo);
            }}>
            <DeleteOutlined />
          </div>
        </Tooltip>,
        <Tooltip title="Edit task">
          <div
            onClick={() => {
              onEditTask(todo);
            }}>
            <EditOutlined />
          </div>
        </Tooltip>,
        <Tooltip title={todo.completed ? 'Mark undone' : 'Mark done'}>
          <div
            onClick={() => {
              onMarkCompletedTask(todo);
            }}>
            {todo.completed ? (
              <CheckCircleFilled style={{color: 'darkgreen'}} />
            ) : (
              <CloseCircleOutlined style={{color: 'darkred'}} />
            )}
          </div>
        </Tooltip>,
      ]}
      extra={
        <Checkbox
          color="primary"
          checked={todo.selected}
          onClick={event => {
            event.stopPropagation();
            onTodoChecked(todo);
          }}
          value="SelectTodo"
          className="gx-icon-btn"
        />
      }>
      <Meta
        title={moment(new Date(todo.date * 1000), dayFormat).format(dayFormat)}
        description={todo.description}
      />
      <div className="gx-mt-3">
        {todo.tags &&
          todo.tags.map((label, index) => {
            return (
              <Badge
                key={index}
                count={label.title}
                style={{backgroundColor: label.color}}
                onClick={() => {
                  taskFilterByLabel(label.title);
                }}
              />
            );
          })}
      </div>
    </Card>
  );
};

export default TaskItem;
