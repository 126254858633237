import React, { useState } from 'react';
import {
  Select,
  Button,
  Modal,
  Form,
  Input,
  DatePicker,
  InputNumber,
  message,
  Tag,
  Cascader,
} from 'antd/lib';

import IntlMessages from 'util/IntlMessages';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import FirebaseFin from 'firebase/firebaseFin';
import { FirebaseDAO } from 'firebase/firebase';
import moment from 'moment';
import {
  TransactionCategories,
  Frequences,
  tagColors,
} from 'constants/staticData';
import { accountTypes } from 'constants/DataConstants';
const dayFormat = 'D-MMM-YYYY';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const TransactionModal = ({
  setShowModal,
  showModal,
  accounts,
  resetEditTrasaction,
  editTrasactionData,
  onAddAccount,
  onResetAddAccount,
  resetEditAccount,
  editAccountData,
}) => {
  const intlMessages = useFormatMessage();
  const [form] = Form.useForm();
  const [tagInputVisible, setTagInputVisible] = useState(false);
  const [tagValue, setTagValue] = useState(null);
  const [addaccount, setAddaccount] = useState(false);
  const [formMode, setFormMode] = useState('add');
  const [tags, setTags] = useState([]);
  const [allowEdit, setAllowEdit] = useState(true);
  const [formModeAccount, setFormModeAccount] = useState('add');
  const [accountSection, setAccountSection] = useState(false);

  const handleClose = removedTag => {
    const newTags = tags.filter(tag => tag !== removedTag);
    setTags(newTags);
  };

  if (editTrasactionData && allowEdit) {
    let row = editTrasactionData;
    setTags(row.tags);
    form.resetFields();
    row = {
      ...row,
      date: row.date ? moment(new Date(row.date * 1000), dayFormat) : moment(new Date(), dayFormat),
    };
    form.setFieldsValue({
      ...row,
    });
    setFormMode('edit');
    setShowModal(true);
    setAllowEdit(false);
  }

  if (onAddAccount && allowEdit) {
    setFormModeAccount('add');
    setShowModal(true);
    setAllowEdit(false);
    setAccountSection(true);
    setAddaccount(true);
  }

  if (editAccountData && allowEdit) {
    let row = editAccountData;
    form.resetFields();
    form.setFieldsValue({
      ...row,
    });
    setFormModeAccount('edit');
    setShowModal(true);
    setAllowEdit(false);
    setAccountSection(true);
    setAddaccount(true);
  }

  const forMap = tag => {
    const tagElem = (
      <Tag
        closable
        onClose={e => {
          e.preventDefault();
          handleClose(tag);
        }}
        color={tag.color}>
        {tag.title}
      </Tag>
    );
    return (
      <span key={tag.title} style={{ display: 'inline-block' }}>
        {tagElem}
      </span>
    );
  };

  const tagChild = tags ? tags.map(forMap) : null;

  const handleInputChange = e => {
    setTagValue(e.target.value.toLowerCase());
  };

  const handleInputConfirm = () => {
    let color = tagColors[Math.ceil(Math.random() * 30)];
    let alltags = tags ? tags : [];
    if (
      tagValue &&
      alltags.findIndex(tagObject => tagObject.title === tagValue) === -1
    ) {
      alltags = [...alltags, { title: tagValue, color: color }];
    }
    setTags(alltags);
    setTagInputVisible(false);
    setTagValue(null);
  };

  const handleResetFormModal = () => {
    setShowModal(false);
    form.resetFields();
    resetEditTrasaction();
    setTags([]);
    setFormMode('add');
    setAllowEdit(true);
    setAccountSection(false);
  };

  const cancelAddAccount = () => {
    setAddaccount(false);
    if (accountSection === true) {
      setShowModal(false);
      onResetAddAccount(false);
      resetEditAccount();
      handleResetFormModal();
    }
    setFormModeAccount('add');
    setAllowEdit(true);
  };

  const displayRender = label => {
    return label[label.length - 1];
  };

  let frequencesOptions = [];
  if (Frequences && Frequences.length > 0) {
    Frequences.forEach(frequency => {
      frequencesOptions.push(
        <Option value={frequency.value} key={frequency.value}>
          {frequency.label}
        </Option>,
      );
    });
  }

  let accountOptions = [];
  if (accounts && accounts.length > 0) {
    accounts.forEach(account => {
      accountOptions.push(
        <Option value={account.id} key={account.id}>
          {account.name}
        </Option>,
      );
    });
  }

  const onFormSubmit = async () => {
    let formValues = form.getFieldsValue([
      'amount',
      'accountId',
      'date',
      'category',
      'frequency',
      'description',
    ]);

    let _account = '';
    for (var account of accounts) {
      if (account.id === formValues.accountId) {
        _account = account.name;
        break;
      }
    }
    formValues = {
      ...formValues,
      date: Math.floor(new Date(formValues.date.format(dayFormat)).getTime() / 1000),
      tags: tags,
      account: _account,
      timestamp: moment()
        .valueOf()
        .toString(),
      description: formValues.description ? formValues.description : '',
    };
    setShowModal(false);
    if (formMode === 'add') {
      await FirebaseFin.addTransaction(formValues);
      message.success(intlMessages('transaction.add.success'));
    } else {
      let { id } = form.getFieldsValue(['id']);
      formValues = {
        ...formValues,
        id,
      };
      await FirebaseFin.editTransaction(formValues);
      message.success(intlMessages('transaction.update.success'));
    }
    setTags([]);
    form.resetFields();
    resetEditTrasaction();
    setAllowEdit(true);
    setFormMode('add');
  };

  const saveAccountInformation = async () => {
    let formValues = form.getFieldsValue(['name', 'balance', 'type']);
    if (formModeAccount === 'add') {
      await FirebaseDAO.addFBData(formValues, 'accounts');
      message.success(intlMessages('wizard.financials.account.add.success'));
    } else {
      let { id } = form.getFieldsValue(['id']);
      formValues = {
        ...formValues,
        id,
      };
      await FirebaseDAO.updateFBData(formValues, 'accounts');
      message.success(intlMessages('wizard.financials.account.update.success'));
    }

    setAddaccount(false);
    if (accountSection === true) {
      setShowModal(false);
      onResetAddAccount();
      resetEditAccount();
      handleResetFormModal();
    }
    setFormModeAccount('add');
    setAllowEdit(true);
  };

  const searchCategoryFilter = (inputValue, path) => {
    return path.some(
      option =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
    );
  };

  return (
    <div>
      {addaccount ? (
        <Modal
          title={
            formModeAccount === 'add' ? (
              <IntlMessages id="wizard.financials.account.addBtn" />
            ) : (
                <IntlMessages id="wizard.financials.account.updateBtn" />
              )
          }
          visible={showModal}
          closable={true}
          onOk={() => form.submit()}
          onCancel={cancelAddAccount}>
          <Form
            {...formItemLayout}
            form={form}
            onFinish={saveAccountInformation}>
            <div className="gx-modal-box-row">
              <div className="gx-modal-box-form-item">
                <div className="gx-form-group">
                  <Form.Item
                    label="Account name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        type: 'string',
                        message: 'Please enter an Account name',
                      },
                    ]}>
                    <Input required placeholder="Account name" />
                  </Form.Item>
                </div>
                <div className="gx-form-group">
                  <Form.Item
                    inline="true"
                    name="balance"
                    label="Initial Balance"
                    rules={[
                      {
                        required: true,
                        type: 'integer',
                        message: 'Please enter a valid number',
                      },
                    ]}>
                    <InputNumber
                      onChange={() => { }}
                      formatter={value =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      placeholder="Initial Balance"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </div>
                <div className="gx-form-group">
                  <Form.Item
                    label="Account Type"
                    name="type"
                    rules={[
                      {
                        required: true,
                        type: 'array',
                        message: 'Please select an Account type',
                      },
                    ]}>
                    <Cascader
                      options={accountTypes}
                      showSearch={{ searchCategoryFilter }}
                    />
                  </Form.Item>
                </div>
                <Button htmlType="submit" style={{ display: 'none' }}></Button>
              </div>
            </div>
          </Form>
        </Modal>
      ) : (
          <Modal
            title={
              formMode === 'add' ? (
                <IntlMessages id="transaction.add" />
              ) : (
                  <IntlMessages id="transaction.update" />
                )
            }
            visible={showModal}
            closable={true}
            onOk={() => form.submit()}
            onCancel={handleResetFormModal}>
            <Form {...formItemLayout} form={form} onFinish={onFormSubmit}>
              <div className="gx-modal-box-row">
                <div className="gx-modal-box-form-item">
                  <div className="gx-form-group">
                    <Form.Item
                      label="Amount"
                      name="amount"
                      rules={[
                        {
                          required: true,
                          type: 'integer',
                          message: 'Please enter amount',
                        },
                      ]}>
                      <InputNumber
                        formatter={value =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        placeholder="Amount"
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </div>
                  <div className="gx-form-group">
                    <Form.Item
                      label="Select Account"
                      name="accountId"
                      rules={[
                        {
                          required: true,
                          type: 'any',
                          message: 'Please select an account',
                        },
                      ]}>
                      <Select
                        style={{ width: '100%' }}
                        onChange={() => { }}
                        placeholder="Please select an account">
                        {accountOptions}
                      </Select>
                    </Form.Item>
                    <div
                      className="gx-text-primary gx-pointer gx-d-none gx-d-sm-block"
                      style={{ marginTop: -10, left: 172, position: 'relative' }}
                      onClick={() => setAddaccount(true)}>
                      <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle gx-mr-1" />
                      <span>Add Account</span>
                    </div>
                  </div>
                  <div className="gx-form-group">
                    <Form.Item
                      label="Date"
                      name="date"
                      rules={[{ required: true, message: 'Please select a date' }]}>
                      <DatePicker
                        style={{ width: '100%' }}
                        placeholder="Date"
                        margin="normal"
                      />
                    </Form.Item>
                  </div>
                  <div className="gx-form-group">
                    <Form.Item
                      label="Category"
                      name="category"
                      rules={[
                        {
                          required: true,
                          message: 'Please select a category',
                        },
                      ]}>
                      <Cascader
                        options={TransactionCategories}
                        expandTrigger="hover"
                        displayRender={displayRender}
                      //onChange={onChange}
                      />
                    </Form.Item>
                  </div>
                  <div className="gx-form-group">
                    <Form.Item
                      label="Frequency"
                      name="frequency"
                      rules={[
                        {
                          required: true,
                          type: 'string',
                          message: 'Please select frequency',
                        },
                      ]}>
                      <Select
                        style={{ width: '100%' }}
                        onChange={() => { }}
                        placeholder="Please select frequency">
                        {frequencesOptions}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="gx-form-group">
                    <Form.Item label="Description" name="description">
                      <Input placeholder="Description" />
                    </Form.Item>
                  </div>
                  <div className="gx-form-group">
                    <Form.Item label="Tags" name="tags">
                      <div>
                        {tagInputVisible && (
                          <Input
                            type="text"
                            size="small"
                            style={{ width: 78 }}
                            value={tagValue}
                            onChange={handleInputChange}
                            onBlur={handleInputConfirm}
                            onPressEnter={handleInputConfirm}
                          />
                        )}
                        {!tagInputVisible && (
                          <Tag
                            onClick={() => setTagInputVisible(true)}
                            style={{ background: '#fff', borderStyle: 'dashed' }}>
                            + New Tag
                          </Tag>
                        )}
                      </div>
                    </Form.Item>
                    <div>{tagChild}</div>
                  </div>

                  <Button htmlType="submit" style={{ display: 'none' }}></Button>
                </div>
              </div>
            </Form>
          </Modal>
        )}
    </div>
  );
};

export default TransactionModal;
