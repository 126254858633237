import {
  DISPLAY_MESSAGE
} from '../../constants/ActionTypes';

export const showMessage = (message, level) => {
  return {
    type: DISPLAY_MESSAGE,
    message,
    level
  };
};