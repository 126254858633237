import React, { useState } from 'react';
import { Table, Modal, message, Button } from 'antd/lib';
import Widget from 'components/Widget/index';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { FirebaseDAO } from 'firebase/firebase';
import numeral from 'numeral';
import AccountModal from 'components/app/account/AccountModal';
import IntlMessages from 'util/IntlMessages';
const { confirm } = Modal;

const RecentAccounts = ({ profile }) => {
  const isMobile = window.innerWidth <= 500;
  const intlMessages = useFormatMessage();
  const [showModalForm, setShowModalForm] = useState(false);
  const [accountEditData, setAccountEditData] = useState(null);

  const [accounts] = useCollectionData(
    FirebaseDAO.getFBCollectionData('accounts'),
    {
      idField: 'id',
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const setShowModal = state => {
    setShowModalForm(state);
    setAccountEditData(null);
  };

  const editAccountData = row => {
    setAccountEditData(row);
    setShowModalForm(true);
  };


  const deleteBankAcccount = row => {
    confirm({
      title: 'Are you sure you want to delete this Account?',
      onOk: () => {
        processBankAccountDelete(row);
      },
      onCancel() { },
    });
  };

  const processBankAccountDelete = async row => {
    await FirebaseDAO.deleteMultipleFBData([row.id], 'accounts');
    message.success(intlMessages('wizard.financials.account.delete.success'));
  };

  let columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      render: (text, row) => <span>{numeral(row.balance).format('$0,0')}</span>,
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render: (text, row) => (
        <span>
          <i
            className="icon icon-edit gx-pointer gx-text-primary gx-fs-lg gx-d-inline-flex gx-vertical-align-middle gx-mr-1"
            onClick={() => editAccountData(row)}
          />
          &emsp;
          <i
            className="icon icon-trash gx-pointer gx-text-danger gx-fs-lg gx-d-inline-flex gx-vertical-align-middle gx-mr-1"
            onClick={() => deleteBankAcccount(row)}
          />
        </span>
      ),
    },
  ];

  // For Mobile Screen
  if (isMobile) {
    let removeIndex = [2];
    columns = columns.filter(function (value, index) {
      return removeIndex.indexOf(index) === -1;
    });
  }

  return (
    <div>
      <Widget
        title={
          <h2 className="h4 gx-text-capitalize gx-mb-0 gx-text-left gx-ml-1">
            Accounts
          </h2>
        }
        extra={[
          <p
            className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block gx-mr-1"
            onClick={() => setShowModal(true)}>
            <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle gx-mr-1" />
            <IntlMessages id="wizard.financials.account.addBtn" />
          </p>,
          //  For Mobile Sceen
          <span
            onClick={() => setShowModal(true)}
            className="gx-text-primary gx-fs-md gx-pointer gx-mb-4 gx-d-block gx-d-sm-none gx-mr-1">
            <i className="icon icon-add-circle gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle" />
            <IntlMessages id="wizard.financials.account.addBtn" />
          </span>,
        ]}>
        {accounts && (
          <Table
            className="gx-table-no-bordered"
            rowClassName="loan-table-row"
            pagination={{ pageSize: 10 }}
            columns={columns}
            dataSource={accounts}
            size="middle"
            rowKey="id"
            locale={{
              emptyText: (
                <div className="text-center">
                  <h3>You don't have any accounts yet. Let's add one!</h3>
                  <Button
                    variant="raised"
                    className="gx-mt-3"
                    type="primary"
                    onClick={() => setShowModal(true)}>
                    <div className="ant-row-flex">
                      <IntlMessages id="wizard.financials.account.addBtn" />
                    </div>
                  </Button>
                </div>
              ),
            }}
          />
        )}
      </Widget>
      <AccountModal
        showHideModal={setShowModal}
        showModal={showModalForm}
        account={accountEditData}
      />
    </div>
  );
};

export default RecentAccounts;
