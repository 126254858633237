import React, { useState, Fragment, useEffect } from 'react';
import DisplayList from './DisplayList';
import DisplayGrid from './DisplayGrid';
import GoalUtilities from './GoalUtilities';
import IntlMessages from 'util/IntlMessages';
import FirebaseGoals from 'firebase/firebaseGoals';
import GoalForm from './goalForm/GoalForm';
import { message, Modal } from 'antd';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import FirebaseFin from 'firebase/firebaseFin';
import _ from 'lodash';
import NoGoalsYet from './NoGoalsYet';
import search from 'javascript-array-search';
import Widget from 'components/Widget/index';

const GoalsList = ({ goals: goalListing }) => {
  const [goals, setGoals] = useState(goalListing);
  const [origGoals, setOrigGoals] = useState(goals); //eslint-disable-line
  const [modalVisible, setModalVisible] = useState(false);
  const [goalsDisplay, setGoalsDisplay] = useState(
    window.localStorage.getItem('goals-display') || 'list',
  );
  const [goal, setGoal] = useState({});
  const [findText, setFindText] = useState();
  const [filteredGoals, setFilteredGoals] = useState();
  const { confirm } = Modal;

  const [accounts] = useCollectionData(FirebaseFin.getBankAccounts(), {
    idField: 'id',
    snapshotListenOptions: {
      includeMetadataChanges: true,
    },
  });

  const onSearch = e => {
    const text = e.target.value;
    setFindText(text);
    const isValidSearch = text && text.length > 2;
    const isClearedSearch = !text;
    if (isValidSearch) {
      try {
        const filtered = search(origGoals, text, ['name']);
        setFilteredGoals(filtered);
        setGoals(filtered);
      } catch (error) {
        message.error(error);
      }
    }
    if (isClearedSearch) {
      setGoals(origGoals);
    }
  };

  const onChangeDisplay = e => {
    setGoalsDisplay(e.target.value);
    window.localStorage.setItem('goals-display', e.target.value);
  };

  // TODO: Refactor this ugly/repeat method
  useEffect(() => {
    const goalsMap = goalListing.map(goal => {
      let { currentAmount, goalAmount } = goal;
      goalAmount = parseInt(goalAmount, 10);
      if (goal.accountId) {
        const theAccount = _.find(accounts, { id: goal.accountId });
        const hasValidBalance =
          theAccount &&
          typeof theAccount.balance !== 'undefined' &&
          theAccount.balance !== null;
        if (hasValidBalance) {
          currentAmount = parseInt(theAccount.balance.toFixed(2), 10);
        }
      } else {
        currentAmount = parseInt(currentAmount, 10);
      }
      const percent = (currentAmount / goalAmount) * 100;
      const progress = percent.toFixed(2);
      return { ...goal, currentAmount, progress };
    });
    if (findText) {
      setGoals(filteredGoals);
    } else {
      setGoals(goalsMap);
    }
  }, [goalListing]); // eslint-disable-line

  const editGoal = goal => {
    setGoal({ ...goal });
    setModalVisible(true);
  };

  const deleteGoal = goal => {
    confirm({
      title: 'Do you want to delete this goal?',
      onOk: () => {
        FirebaseGoals.deleteGoal(goal);
        message.success('Goal Deleted!');
      },
      onCancel: () => { },
    });
  };

  return (
    <Widget>
      {goalListing && !goalListing.length ? (
        <NoGoalsYet showAddGoalForm={setModalVisible} />
      ) : (
          <Fragment>
            <div className="goals-heading">
              <h1>
                <IntlMessages id="goals.heading.myGoals" />
              </h1>
              <GoalUtilities
                onSearch={onSearch}
                onChangeDisplay={onChangeDisplay}
                goalsDisplay={goalsDisplay}
                setModalVisible={setModalVisible}
              />
            </div>
            <div className="goals-display">
              {goalsDisplay === 'list' ? (
                <div className="goals-display-list">
                  <DisplayList
                    data={goals}
                    editGoal={editGoal}
                    deleteGoal={deleteGoal}
                  />
                </div>
              ) : (
                  <div className="goals-display-grid ant-row">
                    <DisplayGrid
                      data={goals}
                      editGoal={editGoal}
                      deleteGoal={deleteGoal}
                    />
                  </div>
                )}
            </div>
          </Fragment>
        )}
      <GoalForm
        visible={modalVisible}
        setVisible={setModalVisible}
        setGoal={setGoal}
        accounts={accounts}
        goal={goal}
      />
    </Widget>
  );
};

export default GoalsList;
