import React, {Component} from 'react';
import {Layout} from 'antd/lib';
import Sidebar from '../Sidebar/index';
import HorizontalDefault from '../Topbar/HorizontalDefault/index';
import HorizontalDark from '../Topbar/HorizontalDark/index';
import InsideHeader from '../Topbar/InsideHeader/index';
import AboveHeader from '../Topbar/AboveHeader/index';
import BelowHeader from '../Topbar/BelowHeader/index';
import Topbar from '../Topbar/index';
import {footerText} from 'util/config';
import App from 'routes/index';
import Messages from 'containers/Messages';
import {connect} from 'react-redux';
import {AppContext} from 'auth/appContext';
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
} from '../../constants/ThemeSetting';
import NoHeaderNotification from '../Topbar/NoHeaderNotification/index';
import Helmet from 'react-helmet';
import moment from 'moment';

const dateFormat = 'M/DD/YYYY';
const {Content, Footer} = Layout;

export class MainApp extends Component {
  constructor(props) {
    super(props);
    this.setDateRange = (dates, dateStrings) => {
      // console.log('From: ', dates[0], ', to: ', dates[1]);
      // console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
      this.setState({
        startDate: dates[0],
        endDate: dates[1],
      });
    };

    this.state = {
      user: props.user,
      profile: props.profile,
      startDate: moment(new Date(), dateFormat).subtract(90, 'day'),
      endDate: moment(new Date(), dateFormat),
      setDateRange: this.setDateRange,
    };
  }

  getContainerClass = navStyle => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return 'gx-container-wrap';
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return 'gx-container-wrap';
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return 'gx-container-wrap';
      case NAV_STYLE_BELOW_HEADER:
        return 'gx-container-wrap';
      case NAV_STYLE_ABOVE_HEADER:
        return 'gx-container-wrap';
      default:
        return '';
    }
  };

  getNavStyles = navStyle => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return <HorizontalDefault />;
      case NAV_STYLE_DARK_HORIZONTAL:
        return <HorizontalDark />;
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return <InsideHeader />;
      case NAV_STYLE_ABOVE_HEADER:
        return <AboveHeader />;
      case NAV_STYLE_BELOW_HEADER:
        return <BelowHeader />;
      case NAV_STYLE_FIXED:
        return <Topbar />;
      case NAV_STYLE_DRAWER:
        return <Topbar />;
      case NAV_STYLE_MINI_SIDEBAR:
        return <Topbar />;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <NoHeaderNotification />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <NoHeaderNotification />;
      default:
        return null;
    }
  };

  getSidebar = (navStyle, width) => {
    if (width < TAB_SIZE) {
      return <Sidebar />;
    }
    switch (navStyle) {
      case NAV_STYLE_FIXED:
        return <Sidebar />;
      case NAV_STYLE_DRAWER:
        return <Sidebar />;
      case NAV_STYLE_MINI_SIDEBAR:
        return <Sidebar />;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <Sidebar />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <Sidebar />;
      default:
        return null;
    }
  };

  render() {
    const {match, width, navStyle, user} = this.props;
    let titleStr = 'Welcome! | loanpalz';
    if (user && user.email) {
      titleStr = `Welcome, ${user.email}! | loanpalz`;
    } else if (user && !user.email) {
      titleStr = `Welcome! | loanpalz`;
    }
    return (
      <AppContext.Provider value={this.state}>
        <Helmet titleTemplate={titleStr} defaultTitle={titleStr} />
        <Layout className="gx-app-layout">
          {this.getSidebar(navStyle, width)}
          <Layout>
            {this.getNavStyles(navStyle)}
            <Content
              className={`gx-layout-content ${this.getContainerClass(
                navStyle,
              )} `}>
              <App match={match} />
              <Footer>
                <div className="gx-layout-footer-content">{footerText}</div>
              </Footer>
            </Content>
          </Layout>
          <Messages />
        </Layout>
      </AppContext.Provider>
    );
  }
}

const mapStateToProps = ({settings}) => {
  const {width, navStyle} = settings;
  return {width, navStyle};
};
export default connect(mapStateToProps)(MainApp);
// export default MainApp;
