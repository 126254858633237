import {Badge, Checkbox, Dropdown, Menu} from 'antd/lib';
import React from 'react';

const options = ['Edit', 'Mark done', 'Delete'];

const TaskItem = ({
  todo,
  onTodoSelect,
  onTodoChecked,
  onEditTask,
  onDeleteTask,
  onStarredTask,
  onMarkCompletedTask,
  taskFilterByLabel,
}) => {
  const menus = () => (
    <Menu
      onClick={e => {
        if (e.key === 'Edit') {
          onEditTask(todo);
        } else if (e.key === 'Delete') {
          onDeleteTask(todo);
        } else if (e.key === 'Mark done') {
          onMarkCompletedTask(todo);
        }
      }}>
      {options.map(option => (
        <Menu.Item key={option}>
          {option === 'Mark done'
            ? todo.completed
              ? 'Mark undone'
              : option
            : option}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="gx-module-list-item">
      <div className="gx-module-list-icon">
        <Checkbox
          color="primary"
          checked={todo.selected}
          onClick={event => {
            event.stopPropagation();
            onTodoChecked(todo);
          }}
          value="SelectTodo"
          className="gx-icon-btn"
        />

        <div
          onClick={() => {
            onStarredTask(todo);
          }}>
          {todo.starred ? (
            <i className="gx-icon-btn icon icon-star" />
          ) : (
            <i className="gx-icon-btn icon icon-star-o" />
          )}
        </div>
      </div>
      <div className="gx-module-list-info">
        <div className="gx-module-todo-content">
          <div
            className={`gx-subject ${todo.completed &&
              'gx-text-muted gx-text-strikethrough'}`}
            onClick={() => {
              onTodoSelect(todo);
            }}>
            {todo.title}
          </div>
          <div className="gx-manage-margin">
            {todo.tags &&
              todo.tags.map((label, index) => {
                return (
                  <Badge
                    key={index}
                    count={label.title}
                    style={{backgroundColor: label.color}}
                    onClick={() => {
                      taskFilterByLabel(label.title);
                    }}
                  />
                );
              })}
          </div>
        </div>
        <div className="gx-module-todo-right">
          <Dropdown overlay={menus} placement="bottomRight" trigger={['click']}>
            <i className="gx-icon-btn icon icon-ellipse-v" />
          </Dropdown>

          {/* {addApplicationState && (
            <AddApplication
              open={addApplicationState}
              application={application}
              onSaveApplication={onSaveApplication}
              applicationId={id}
              onApplicationClose={this.onApplicationClose}
              onDeleteApplication={this.onDeleteApplication}
            />
          )} */}
        </div>
      </div>
    </div>
  );
};

export default TaskItem;
