import { history } from 'appRedux/store';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { message } from 'antd';
import FirebaseAuth from 'firebase/firebaseAuth';

const SignInAsGuest = () => {
  const intlMessages = useFormatMessage();
  // Sign in as guest
  FirebaseAuth.signInAnonymously();

  // Display message
  message.success(intlMessages('appModule.guestSigninSuccessful'));

  // Forward to /app/dashboard
  history.push('/app/dashboard');
  return null;
};

export default SignInAsGuest;
