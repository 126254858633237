import {useContext} from 'react';
import {AppContext} from './appContext';

export const useSession = () => {
  const {user} = useContext(AppContext);
  return user;
};

export const useProfile = () => {
  const {profile} = useContext(AppContext);
  return profile;
};
