import app from 'firebase/app';
import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import config from './firebaseConfig';
import { TEMPORARY_AVATAR, TEMPORARY_COVER } from 'util/constants';
import { history } from 'appRedux/store';
import FirebaseSocial from 'firebase/firebaseSocial';

class FirebaseAuth {
  constructor() {
    if (!firebase.apps.length) {
      app.initializeApp(config);
    }
    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();

    // Facebook redirect
    this.auth
      .getRedirectResult()
      .then(async result => {
        if (result.credential) {
          // This gives you a Facebook Access Token. You can use it to access the Facebook API.
          // var token = result.credential.accessToken;

          // Does the user have a profile?
          const { user } = result;
          let profile = await FirebaseSocial.getUser(user.uid);

          // If no Loanpalz profile, create one
          if (!profile.financial) {
            const { displayName, email, photoURL } = user.providerData[0];

            // await this.createUserProfileFromFacebook(user);
            await this.createUserProfile(
              user.uid,
              email,
              displayName,
              email,
              photoURL,
            );
          }

          // Redirect to dashboard
          history.push('/app/dashboard');
        }
      })
      .catch(function (error) {
        const { code, message, email, credential } = error;
        console.log('Facebook login error: ', message, code, email, credential);
      });
  }

  // Auth functions
  signIn(email, password) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  async signInAnonymously() {
    return this.auth.signInAnonymously().catch(function (error) {
      console.log('Anonymous sign-in error code: ', error.code);
      console.log('Error message: ', error.message);
    });
  }

  async signInWithFacebook() {
    let provider = new firebase.auth.FacebookAuthProvider();
    provider.addScope('user_birthday');
    provider.addScope('email');
    provider.addScope('user_friends');
    provider.setCustomParameters({
      display: 'popup',
    });

    // Sign in with redirect: short version
    return firebase.auth().signInWithRedirect(provider);

    // Sign in with redirect
    // return firebase
    //   .auth()
    //   .signInWithRedirect(provider)
    //   .then(result => {
    //     // This gives you a Facebook Access Token. You can use it to access the Facebook API.
    //     // var token = result.credential.accessToken;
    //     const {user} = result;
    //     return user;
    //   })
    //   .catch(function(error) {
    //     const {code, message, email, credential} = error;
    //     console.log('Facebook login error: ', message, code, email, credential);
    //   });
  }

  async signInWithPersistence(email, password, persistence = 'SESSION') {
    if (persistence === 'SESSION') {
      return this.auth
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() => {
          return this.auth.signInWithEmailAndPassword(email, password);
        });
    } else {
      return this.auth
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => {
          return this.auth.signInWithEmailAndPassword(email, password);
        });
    }
  }

  async signOut() {
    return this.auth.signOut();
  }

  async createUserProfile(
    id,
    username,
    fullName,
    email,
    avatar = TEMPORARY_AVATAR,
    cover = TEMPORARY_COVER,
  ) {
    const financial = {
      snapshot: false,
    };
    const vanityURL = '';
    const social = {
      location: '',
      biography: '',
      company: '',
      education: '',
      phone: '',
      birthday: null,
      mediaArray: [],
    };
    const user = {
      id,
      avatar,
      cover,
      username,
      fullName,
      email,
      financial,
      social,
      vanityURL,
    };
    return this.db
      .collection('users')
      .doc(id)
      .set(user);
  }

  async signUp(fullName, username, email, password) {
    let user = await this.auth.createUserWithEmailAndPassword(email, password);
    let uid = user.user.uid;
    await this.createUserProfile(uid, username, fullName, email);
    return this.auth.currentUser.updateProfile({
      username,
      fullName,
    });
  }

  sendResetPassword(values) {
    const actionCodeSettings = {
      url: 'https://www.loanpalz.com/signin',
    };
    return this.auth
      .sendPasswordResetEmail(values.email, actionCodeSettings)
      .then(function () {
        console.log('Password reset email sent');
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async resetPassword(values) {
    let user = this.db.collection('users');
    let response = '';
    await user
      .where('email', '==', values.email)
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log('No matching documents.');
          response = 'no';
        }
        snapshot.forEach(doc => {
          if (doc.data().disabled !== true) {
            response = true;
            this.sendResetPassword(values);
          } else {
            response = 'disabled';
          }
        });
      })
      .catch(err => {
        console.log('Error getting documents', err);
      });
    return response;
  }

  async changeEmailId(values) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    const userRef = this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`);

    const authRef = await this.auth.signInWithEmailAndPassword(
      values.oldEmail,
      values.password,
    );

    return authRef.user.updateEmail(values.email).then(() => {
      return userRef.update({
        email: values.email,
      });
    });
  }

  async changePassword(values) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    const authRef = this.auth.currentUser;
    return await this.auth
      .signInWithEmailAndPassword(values.email, values.currentPassword)
      .then(function (user) {
        return authRef.updatePassword(values.newPassword).then(
          () => {
            console.log('done');
          },
          error => {
            console.log(error);
          },
        );
      });
  }

  async deleteAccount(values) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    const userRef = this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`);

    return await this.auth
      .signInWithEmailAndPassword(values.email, values.password)
      .then(function (user) {
        return userRef.update({
          disabled: true,
        });
      });
  }
}

export default new FirebaseAuth();
