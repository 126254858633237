import React from 'react';
import FirebaseFin from 'firebase/firebaseFin';
import {
  FaBullseye,
  FaCreditCard,
  FaFileInvoiceDollar,
  FaMoneyBill,
  FaPiggyBank,
  FaCouch,
  FaUnlink,
  FaUserGraduate,
  FaHome,
  FaBuilding,
  FaCar,
  FaPlane,
  FaChartArea,
  FaUsers,
  FaUserTie,
  FaUserFriends,
  FaBaby,
  FaPaw,
} from 'react-icons/fa';
export const ICON_SIZE_STEP_CHECKLIST = 40;

export const TEMPORARY_AVATAR =
  'https://firebasestorage.googleapis.com/v0/b/loanpalz/o/static%2Fprofile-default.jpg?alt=media';

export const TEMPORARY_COVER =
  'https://firebasestorage.googleapis.com/v0/b/loanpalz/o/static%2Fcover-default.jpeg?alt=media';

export const TEMPORARY_GOAL_IMAGE =
  'https://firebasestorage.googleapis.com/v0/b/loanpalz/o/static%2Fgoal-default.png?alt=media';

export const GOAL_IMAGE_DIRECTORY =
  'https://firebasestorage.googleapis.com/v0/b';
export const GOALS_OPTIONS = [
  {
    label: 'Pay Off Debt',
    value: 'payoffdebt',
    icon: <FaPiggyBank size={ICON_SIZE_STEP_CHECKLIST} />,
  },
  {
    label: 'Save for Retirement',
    value: 'retirement',
    icon: <FaCouch size={ICON_SIZE_STEP_CHECKLIST} />,
  },
  {
    label: 'Stop Living Pay to Pay',
    value: 'stoppaytopay',
    icon: <FaUnlink size={ICON_SIZE_STEP_CHECKLIST} />,
  },
  {
    label: "Pay for Kids' College",
    value: 'payeducation',
    icon: <FaUserGraduate size={ICON_SIZE_STEP_CHECKLIST} />,
  },
  {
    label: 'Save for a Home',
    value: 'savehome',
    icon: <FaHome size={ICON_SIZE_STEP_CHECKLIST} />,
  },
  {
    label: 'Travel',
    value: 'travel',
    icon: <FaPlane size={ICON_SIZE_STEP_CHECKLIST} />,
  },
];

export const PURPOSE_OPTIONS = [
  {
    label: 'Track my spending and pay my debt faster',
    value: 'trackmyspending',
    icon: <FaChartArea size={ICON_SIZE_STEP_CHECKLIST} />,
  },
  {
    label: 'Network with the community',
    value: 'networkwithcommunity',
    icon: <FaUsers size={ICON_SIZE_STEP_CHECKLIST} />,
  },
  {
    label: 'Get help from professional coaches',
    value: 'gethelpcoaches',
    icon: <FaUserTie size={ICON_SIZE_STEP_CHECKLIST} />,
  },
];

export const PERSONAL_OPTIONS = [
  {
    label: 'I Own a Home',
    value: 'I_OWN_A_HOME',
    icon: <FaHome size={ICON_SIZE_STEP_CHECKLIST} />,
  },
  {
    label: 'I Rent',
    value: 'I_RENT',
    icon: <FaBuilding size={ICON_SIZE_STEP_CHECKLIST} />,
  },
  {
    label: 'I Own a Car',
    value: 'I_OWN_A_CAR',
    icon: <FaCar size={ICON_SIZE_STEP_CHECKLIST} />,
  },
  {
    label: 'I am Married',
    value: 'I_AM_MARRIED',
    icon: <FaUserFriends size={ICON_SIZE_STEP_CHECKLIST} />,
  },
  {
    label: 'I Have Kids',
    value: 'I_HAVE_KIDS',
    icon: <FaBaby size={ICON_SIZE_STEP_CHECKLIST} />,
  },
  {
    label: 'I Have Pets',
    value: 'I_HAVE_PETS',
    icon: <FaPaw size={ICON_SIZE_STEP_CHECKLIST} />,
  },
];

export const INFO_TYPES = {
  cares: {
    key: 'cares',
    title: 'Your stimulus survey is completed.',
    description: 'We hope to see you again in the future.',
  },
  welcome: {
    key: 'welcome',
    title: 'Welcome to Stimulus Action!',
    description:
      'Determine your eligibilty by filling in this short survey.',
  },
  debt: {
    key: 'debt',
    title: 'WE CAN HELP YOU PAY YOUR DEBT FASTER.',
    description:
      'Loanpalz provides calculators, coaches and money-savings tips!',
  },
  incomes: {
    key: 'incomes',
    title: '',
    description:
      'Great job! Now, let’s make a plan for these dollars to work better for you.',
    calculate: FirebaseFin.calculateIncome,
  },
  expenses: {
    key: 'expenses',
    title: '',
    description: 'You’re spending less than you’re earning! That’s great.',
    calculate: FirebaseFin.calculateExpenses,
  },
  assets: {
    key: 'assets',
    title: '',
    description:
      'Your assets are all accounted for. Now, let’s look at your liabilities.',
    calculate: FirebaseFin.calculateAssets,
  },
  liabilities: {
    key: 'liabilities',
    title: '',
    description:
      'Debt is holding you back. Let’s start tracking debt to eliminate it completely.',
    calculate: FirebaseFin.calculateLiabilities,
  },
};

export const CHECKLIST_STEPS_LIST = [
  {
    label: 'Goals',
    value: 'GOALS',
    icon: <FaBullseye size={ICON_SIZE_STEP_CHECKLIST} />,
    title: 'GOOD JOB!',
    description: "You've completed step 1.",
  },
  {
    label: 'Income',
    value: 'INCOME',
    icon: <FaMoneyBill size={ICON_SIZE_STEP_CHECKLIST} />,
    title: 'AWESOME PROGRESS!',
    description: "You're done with Step 2.",
  },
  {
    label: 'Expenses',
    value: 'EXPENSES',
    icon: <FaHome size={ICON_SIZE_STEP_CHECKLIST} />,
    title: 'EXPENSES FINISHED!',
    description: "You're done with Step 3.",
  },
  {
    label: 'Assets',
    value: 'ASSETS',
    icon: <FaFileInvoiceDollar size={ICON_SIZE_STEP_CHECKLIST} />,
    title: 'ASSETS DONE!',
    description: "You're ready for the last step.",
  },
  {
    label: 'Liabilities',
    value: 'LIABILITIES',
    icon: <FaCreditCard size={ICON_SIZE_STEP_CHECKLIST} />,
    title: 'LIABILITIES DONE!',
    description: "You're almost there. Click Next to view your dashboard.",
  },
];

export const CHECKLIST_STEPS_LIST_VALUES = CHECKLIST_STEPS_LIST.reduce(
  (prev, next) => {
    prev[next.value] = next.value;
    return prev;
  },
  {},
);

export const CHECKLIST_STEPS_LIST_TITLES = CHECKLIST_STEPS_LIST.reduce(
  (prev, next) => {
    prev[next.value] = next.title;
    return prev;
  },
  {},
);

export const CHECKLIST_STEPS_LIST_DESC = CHECKLIST_STEPS_LIST.reduce(
  (prev, next) => {
    prev[next.value] = next.description;
    return prev;
  },
  {},
);

export const STEP_GRAPHIC_OPTIONS = [
  {
    key: 'INCOME',
    label: 'Income',
    description:
      'A secure financial future starts with your income. Add your sources of income to track your net worth and cashflow.',
    image:
      'https://firebasestorage.googleapis.com/v0/b/loanpalz/o/static%2Fwizard-step-income.jpg?alt=media',
  },
  {
    key: 'ESSENTIAL_EXPENSES',
    label: 'Essential Expenses',
    description:
      'Let’s start with the absolute necessary expenses - housing, utilities, and food.',
    image:
      'https://firebasestorage.googleapis.com/v0/b/loanpalz/o/static%2Fwizard-step-expenses.jpg?alt=media',
  },
  {
    key: 'NON_ESSENTIAL_EXPENSES',
    label: 'Nonessential Expenses',
    description:
      'Now let’s categorize your non-essential expenses - items such as clothing, travel and going out.',
    image:
      'https://firebasestorage.googleapis.com/v0/b/loanpalz/o/static%2Fwizard-step-nonessential.jpg?alt=media',
  },
  {
    key: 'ASSETS',
    label: 'Assets',
    description:
      'List your assets - it’s what you own and it will be used to calculate your net worth.',
    image:
      'https://firebasestorage.googleapis.com/v0/b/loanpalz/o/static%2Fwizard-step-assets.jpg?alt=media',
  },
  {
    key: 'LIABILITIES',
    label: 'Liabilities',
    description:
      'Let’s look at your liabilities now. List your debts and loans.',
    image:
      'https://firebasestorage.googleapis.com/v0/b/loanpalz/o/static%2Fwizard-step-liabilities.jpg?alt=media',
  },
];

const INCOME = STEP_GRAPHIC_OPTIONS.filter(item => item.key === 'INCOME')[0];
const ESSENTIAL_EXPENSES = STEP_GRAPHIC_OPTIONS.filter(
  item => item.key === 'ESSENTIAL_EXPENSES',
)[0];
const NON_ESSENTIAL_EXPENSES = STEP_GRAPHIC_OPTIONS.filter(
  item => item.key === 'NON_ESSENTIAL_EXPENSES',
)[0];
const ASSETS = STEP_GRAPHIC_OPTIONS.filter(item => item.key === 'ASSETS')[0];
const LIABILITIES = STEP_GRAPHIC_OPTIONS.filter(
  item => item.key === 'LIABILITIES',
)[0];
const graphics = {
  income: {
    title: INCOME.label,
    description: INCOME.description,
    image: INCOME.image,
  },
  essential: {
    title: ESSENTIAL_EXPENSES.label,
    description: ESSENTIAL_EXPENSES.description,
    image: ESSENTIAL_EXPENSES.image,
  },
  nonessential: {
    title: NON_ESSENTIAL_EXPENSES.label,
    description: NON_ESSENTIAL_EXPENSES.description,
    image: NON_ESSENTIAL_EXPENSES.image,
  },
  assets: {
    title: ASSETS.label,
    description: ASSETS.description,
    image: ASSETS.image,
  },
  liabilities: {
    title: LIABILITIES.label,
    description: LIABILITIES.description,
    image: LIABILITIES.image,
  },
};

export const SETTINGS_OPTIONS = {
  goals: GOALS_OPTIONS,
  purposes: PURPOSE_OPTIONS,
  personal: PERSONAL_OPTIONS,
  graphics,
};

export const TEMPORARY_GROUP_COVER =
  'https://firebasestorage.googleapis.com/v0/b/loanpalz/o/static%2Fprofile-default.jpg?alt=media';

export const TEMPORARY_COACH_COVER =
  'https://firebasestorage.googleapis.com/v0/b/loanpalz/o/static%2Fprofile-default.jpg?alt=media';

export const CALENDAR_ICON =
  'https://firebasestorage.googleapis.com/v0/b/loanpalz/o/static%2Fcalendar.png?alt=media';
