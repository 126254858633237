import app from 'firebase/app';
import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import config from './firebaseConfig';
import {TEMPORARY_AVATAR, TEMPORARY_COVER} from 'util/constants';
import asyncForEach from 'util/asyncForEach';

class FirebaseSocial {
  constructor() {
    if (!firebase.apps.length) {
      app.initializeApp(config);
    }
    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
  }

  getUserDocumentData(userId) {
    return this.db.collection('users').doc(userId);
  }

  getUserOnRedirect(vanityURL) {
    return this.db
      .collection('users')
      .where('vanityURL'.toString(), '==', vanityURL.toString())
      .get()
      .then(async snapshot => {
        if (!snapshot.empty) {
          let userDoc = null;
          await this.db
            .collection('users')
            .doc(snapshot.docs[0].id)
            .get()
            .then(doc => (userDoc = doc.data()));
          return userDoc;
        }
      });
  }

  async getUser(id) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let result = null;
    await this.db
      .collection('users')
      .doc(id)
      .get()
      .then(doc => (result = {...doc.data(), id: doc.id}));
    return result;
  }

  async updateSocialHeader(values) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }

    await this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .update({
        username: values.username,
        'social.location': values.location,
      });
  }

  onProfilePictureChange(info) {
    const img = this.storage
      .ref()
      .child(`images/${this.auth.currentUser.uid}/avatar`);
    let upload = info.file.originFileObj;
    img.put(upload).then(() => {
      img.updateMetadata({
        customMetadata: {uid: this.auth.currentUser.uid, imagetype: 'avatar'},
      });
      // Wait for the GCF to finish resizing the image
      setTimeout(() => {
        this.db
          .collection('users')
          .doc(`${this.auth.currentUser.uid}`)
          .update({last_updated: new Date()});
      }, 5000);
    });
  }

  onCoverPictureChange(info) {
    const img = this.storage
      .ref()
      .child(`images/${this.auth.currentUser.uid}/cover`);
    let upload = info.file.originFileObj;
    img.put(upload).then(() => {
      img.updateMetadata({
        customMetadata: {uid: this.auth.currentUser.uid, imagetype: 'cover'},
      });
      // Wait for the GCF to finish resizing the image
      setTimeout(() => {
        this.db
          .collection('users')
          .doc(`${this.auth.currentUser.uid}`)
          .update({last_updated: new Date()});
      }, 5000);
    });
  }

  removeProfilePicture() {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .update({avatar: TEMPORARY_AVATAR});
  }

  removeCoverPicture() {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .update({cover: TEMPORARY_COVER});
  }

  updateBiography(values) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }

    let userRef = this.db.collection('users').doc(this.auth.currentUser.uid);

    if (values.biography) {
      return userRef.update({'social.biography': values.biography});
    }
  }

  updateOverview(values) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let batch = this.db.batch();
    let userRef = this.db.collection('users').doc(this.auth.currentUser.uid);
    batch.update(userRef, {'social.company': values.company});
    batch.update(userRef, {'social.birthday': values.birthday});
    batch.update(userRef, {'social.education': values.education});
    batch.update(userRef, {'social.location': values.location});
    batch.update(userRef, {vanityURL: values.vanityURL});
    return batch.commit();
  }

  updateSocialMedia(values) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let batch = this.db.batch();
    let userRef = this.db.collection('users').doc(this.auth.currentUser.uid);
    batch.update(userRef, {'social.facebook': values.facebook});
    batch.update(userRef, {'social.twitter': values.twitter});
    batch.update(userRef, {'social.instagram': values.instagram});
    batch.update(userRef, {'social.linkedin': values.linkedin});
    batch.update(userRef, {'social.tumblr': values.tumblr});
    return batch.commit();
  }

  async isVanityFree(vanURL) {
    return this.db
      .collection('users')
      .where('vanityURL'.toString(), '==', vanURL.toString())
      .get()
      .then(snapshot => {
        return snapshot.empty;
      });
  }

  async isVanityAllowed(vanURL) {
    let wholeVanity = vanURL.replace(/[^A-Z0-9]/gi, '');

    if (wholeVanity.length < vanURL.length) {
      return false;
    } else return true;
  }

  async isVanityLong(vanURL) {
    if (vanURL.length < 6) {
      return false;
    } else return true;
  }

  updateSidebar(values) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }

    return this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .update({
        email: values.email,
        'social.company': values.company,
        'social.phone': values.phone,
      });
  }

  getUserFollowers(userId) {
    return this.db
      .collection('followers')
      .where('following.id'.toString(), '==', userId.toString());
  }

  getUserFollowing(userId) {
    return this.db
      .collection('followers')
      .where('follower.id'.toString(), '==', userId.toString());
  }

  // async getSuggestedFriends(userId) {
  //   let userSuggested = [];
  //   let userFriendPairs = [];
  //   let userFriends = [];

  //   await this.db
  //     .collection('friends')
  //     .get()
  //     .then(snapshot => {
  //       snapshot.forEach(friendPair => {
  //         if (friendPair.id.includes(userId)) {
  //           userFriendPairs.push(friendPair.data());
  //         }
  //       });
  //     });

  //   userFriendPairs.forEach(pair => {
  //     if (pair.friendOne.id === userId) {
  //       userFriends.push(pair.friendTwo.id);
  //     } else {
  //       userFriends.push(pair.friendOne.id);
  //     }
  //   });

  //   for (let i = 0; i < userFriends.length; i++) {
  //     let friendsOfAFriend = [];

  //     await this.db
  //       .collection('friends')
  //       .get()
  //       .then(snapshot => {
  //         snapshot.forEach(friendPair => {
  //           if (friendPair.id.includes(userFriends[i])) {
  //             friendsOfAFriend.push(friendPair.data());
  //           }
  //         });
  //       });

  //     friendsOfAFriend.forEach(pair => {
  //       if (pair.friendOne.id === userFriends[i]) {
  //         userSuggested.push(pair.friendTwo);
  //       } else {
  //         userSuggested.push(pair.friendOne);
  //       }
  //     });
  //   }

  //   console.log('User Suggested: ', userSuggested);

  //   let uniqueSuggestions = [];
  //   userSuggested.forEach(suggested => {
  //     let alreadyPresent = false;
  //     uniqueSuggestions.forEach(unique => {
  //       if (unique.id === suggested.id) {
  //         alreadyPresent = true;
  //       }
  //       if (!alreadyPresent) {
  //         uniqueSuggestions.push(suggested);
  //       }
  //     });
  //   });

  //   console.log('Unique: ', uniqueSuggestions);

  //   let returnArray = [];
  //   uniqueSuggestions.forEach(suggestedFriend => {
  //     if (!userFriends.includes(suggestedFriend)) {
  //       returnArray.push(suggestedFriend);
  //     }
  //   });

  //   return returnArray;
  // }

  async followUser(userId) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }

    let follower = null;
    let following = null;
    let newFollower = this.getUserDocumentData(this.auth.currentUser.uid);
    let newFollowing = this.getUserDocumentData(userId);

    await newFollower.get().then(doc => {
      follower = doc.data();
    });

    await newFollowing.get().then(doc => {
      following = doc.data();
    });

    this.db
      .collection('followers')
      .doc(`${this.auth.currentUser.uid}_${userId}`)
      .set({
        follower: follower,
        following: following,
      });
  }

  async unfollowUser(userId) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }

    this.db
      .collection('followers')
      .doc(`${this.auth.currentUser.uid}_${userId}`)
      .delete();
  }

  getUserFriendOne(userId) {
    return this.db
      .collection('friends')
      .where('friendTwo.id'.toString(), '==', userId.toString());
  }

  getUserFriendTwo(userId) {
    return this.db
      .collection('friends')
      .where('friendOne.id'.toString(), '==', userId.toString());
  }

  getUserFriendRequestsReceived(userId) {
    return this.db
      .collection('friendRequests')
      .where('receiving.id', '==', userId);
  }

  getUserFriendRequestsSent(userId) {
    return this.db
      .collection('friendRequests')
      .where('sending.id', '==', userId);
  }

  async friendRequestUser(userId) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }

    let requestId = `${this.auth.currentUser.uid}_${userId}`;

    let sending = null;
    let receiving = null;
    let source = this.getUserDocumentData(this.auth.currentUser.uid);
    let destination = this.getUserDocumentData(userId);

    await source.get().then(doc => {
      sending = doc.data();
    });

    await destination.get().then(doc => {
      receiving = doc.data();
    });

    this.db
      .collection('friendRequests')
      .doc(requestId)
      .set({
        sending,
        receiving,
      });
  }

  async unfriend(userId) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }

    let friendPairOne = this.db
      .collection('friends')
      .doc(`${this.auth.currentUser.uid}_${userId}`);
    let friendPairTwo = this.db
      .collection('friends')
      .doc(`${userId}_${this.auth.currentUser.uid}`);
    friendPairOne.delete();
    friendPairTwo.delete();
  }

  async friend(userId) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let batch = this.db.batch();

    let sending = null;
    let receiving = null;
    let request = this.getUserDocumentData(userId);
    let confirm = this.getUserDocumentData(this.auth.currentUser.uid);

    await request.get().then(doc => {
      sending = doc.data();
    });

    await confirm.get().then(doc => {
      receiving = doc.data();
    });

    let removeFROne = this.db
      .collection('friendRequests')
      .doc(`${this.auth.currentUser.uid}_${userId}`);
    let removeFRTwo = this.db
      .collection('friendRequests')
      .doc(`${userId}_${this.auth.currentUser.uid}`);
    batch.delete(removeFROne);
    batch.delete(removeFRTwo);

    let addFriend = this.db
      .collection('friends')
      .doc(`${this.auth.currentUser.uid}_${userId}`);
    batch.set(addFriend, {friendOne: sending, friendTwo: receiving});

    batch.commit();
  }

  async rejectRequest(userId) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }

    this.db
      .collection('friendRequests')
      .doc(`${userId}_${this.auth.currentUser.uid}`)
      .delete();
  }

  async getUserMedia(mediaDoc) {
    let returnArray = [];
    await asyncForEach(mediaDoc.social.mediaArray, photo => {
      returnArray.push(photo.uri);
    });
    return returnArray;
  }
}

export default new FirebaseSocial();
