import React from 'react';
import ReceivedMessageCell from './ReceivedMessageCell/index';
import SentMessageCell from './SentMessageCell/index';

const Conversation = ({conversationData, selectedUser, currentProfile}) => {
  return (
    <div className="gx-chat-main-content">
      {conversationData &&
        conversationData.map((conversation, index) =>
          conversation.senderId !== selectedUser.id ? (
            <SentMessageCell
              key={index}
              conversation={conversation}
              currentProfile={currentProfile}
            />
          ) : (
            <ReceivedMessageCell
              key={index}
              conversation={conversation}
              user={selectedUser}
            />
          ),
        )}
    </div>
  );
};

export default Conversation;
