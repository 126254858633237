import React from 'react';
import moment from 'moment';

const DisplayDate = ({date, timestamp}) => {
  let postDate;
  if (timestamp) {
    postDate = moment(moment.unix(date));
  } else {
    postDate = moment(date, 'ddd MMM DD YYYY kk:mm:ss Z');
  }

  const currentDate = moment(new Date());
  const duration = moment.duration(currentDate.diff(postDate));
  const minutes = duration.asMinutes() | 0;
  const hours = duration.asHours() | 0;

  switch (true) {
    case minutes === 0:
      return <span className="gx-text-grey gx-fs-sm gx-mb-0">Just now</span>;
    case minutes < 60:
      return (
        <span className="gx-text-grey gx-fs-sm gx-mb-0">
          {minutes} minutes ago
        </span>
      );
    case hours < 24:
      return (
        <span className="gx-text-grey gx-fs-sm gx-mb-0">{hours} hours ago</span>
      );
    default:
      return (
        <span className="gx-text-grey gx-fs-sm gx-mb-0">
          {postDate.format('DD MMM, YYYY')}
        </span>
      );
  }
};

export default DisplayDate;
