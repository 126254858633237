// Messages
export const DISPLAY_MESSAGE = 'DISPLAY_MESSAGE';
export const MESSAGE_SUCCESS = 'MESSAGE_SUCCESS';
export const MESSAGE_ERROR = 'MESSAGE_ERROR';
export const MESSAGE_INFO = 'MESSAGE_INFO';
export const MESSAGE_WARNING = 'MESSAGE_WARNING';
export const MESSAGE_LOADING = 'MESSAGE_LOADING';

// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'FETCH_START';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_ERROR = 'FETCH_ERROR';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';

//Sticky
export const GET_STICKY = 'GET_STICKY';
export const NOTES_UPDATE = 'NOTES_UPDATE';
export const FETCH_ALL_NOTES_SUCCESS = 'FETCH_ALL_NOTES_SUCCESS';
export const UPDATE_ALL_NOTES_SUCCESS = 'UPDATE_ALL_NOTES_SUCCESS';

//Contact
export const GET_All_CONTACT_SUCCESS = 'GET_All_CONTACT_SUCCESS';
export const ON_ADD_CONTACT_SUCCESS = 'ON_ADD_CONTACT_SUCCESS';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';

//Requirements
export const GET_ALL_REQUIREMENTS_SUCCESS = 'GET_All_REQUIREMENT_SUCCESS';
export const ON_ADD_REQUIREMENT_SUCCESS = 'ON_ADD_REQUIREMENT_SUCCESS';
export const UPDATE_REQUIREMENT_SUCCESS = 'UPDATE_REQUIREMENT_SUCCESS';
export const DELETE_REQUIREMENT_SUCCESS = 'DELETE_REQUIREMENT_SUCCESS';

//Applications
export const GET_ALL_APPLICATIONS_SUCCESS = 'GET_All_APPLICATION_SUCCESS';
export const ON_ADD_APPLICATION_SUCCESS = 'ON_ADD_APPLICATION_SUCCESS';
export const UPDATE_APPLICATION_SUCCESS = 'UPDATE_APPLICATION_SUCCESS';
export const DELETE_APPLICATION_SUCCESS = 'DELETE_APPLICATION_SUCCESS';
export const STAR_APPLICATION_SUCCESS = 'STAR_APPLICATION_SUCCESS';

//Borrowers
export const GET_ALL_BORROWERS_SUCCESS = 'GET_All_BORROWERS_SUCCESS';
export const GET_BORROWER_DETAILS_SUCCESS = 'GET_BORROWER_DETAILS_SUCCESS';
export const ON_ADD_BORROWER_SUCCESS = 'ON_ADD_BORROWER_SUCCESS';
export const UPDATE_BORROWER_SUCCESS = 'UPDATE_BORROWER_SUCCESS';
export const DELETE_BORROWER_SUCCESS = 'DELETE_BORROWER_SUCCESS';
export const STAR_BORROWER_SUCCESS = 'STAR_BORROWER_SUCCESS';

// Templates
export const GET_TEMPLATE_DETAILS_SUCCESS = 'GET_TEMPLATE_DETAILS_SUCCESS';
export const GET_ALL_TEMPLATES_SUCCESS = 'GET_ALL_TEMPLATES_SUCCESS';
export const ON_ADD_TEMPLATE_SUCCESS = 'ON_ADD_TEMPLATE_SUCCESS';
export const UPDATE_TEMPLATE_SUCCESS = 'UPDATE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';

export const GET_ALL_GOALS_SUCCESS = 'GET_ALL_GOALS_SUCCESS'
export const CHANGE_GOALS_DISPLAY = 'CHANGE_GOALS_DISPLAY'