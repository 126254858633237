import React, {useState} from 'react';
import {useDocumentData} from 'react-firebase-hooks/firestore';
import moment from 'moment';
import AboutItem from './AboutItem';
import {
  Form,
  Button,
  Input,
  Modal,
  Row,
  Col,
  DatePicker,
  message,
  Spin,
} from 'antd';
import IntlMessages from 'util/IntlMessages';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';
import FirebaseSocial from 'firebase/firebaseSocial';
import {FaSpinner} from 'react-icons/fa';

const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;

const Overview = ({readonly, profile}) => {
  const [form] = Form.useForm();
  const [user, userLoading, userError] = useDocumentData(
    FirebaseSocial.getUserDocumentData(profile.id),
    {
      snapshotListenOptions: {includeMetadataChanges: true},
    },
  );

  React.useEffect(() => {
    if (user && user.social) {
      form.setFieldsValue({
        company: user.social.company,
        location: user.social.location,
        education: user.social.education,
        birthday: user.social.birthday
          ? moment(user.social.birthday.toDate())
          : moment(),
        vanityURL: user.vanityURL,
      });
    }
  }, [user, form]);

  const formItemLayout = {
    labelCol: {
      xs: {span: 24},
      sm: {span: 4},
    },
    wrapperCol: {
      xs: {span: 24},
      sm: {span: 20},
    },
  };

  const [showModal, setShowModal] = useState(false);
  const intlMessages = useFormatMessage();
  const onModalClose = () => setShowModal(false);
  const onModalOpen = () => setShowModal(true);

  const onFinish = async values => {
    let freeVanity = true;
    let allowedVanity = true;
    let longVanity = true;

    if (values.vanityURL && values.vanityURL !== user.vanityURL) {
      freeVanity = await FirebaseSocial.isVanityFree(values.vanityURL);
    }
    if (values.vanityURL) {
      allowedVanity = await FirebaseSocial.isVanityAllowed(values.vanityURL);
    }
    if (values.vanityURL) {
      longVanity = await FirebaseSocial.isVanityLong(values.vanityURL);
    }

    if (!freeVanity && values.vanityURL) {
      message.error(intlMessages('profile.vanity.error'));
    } else if (!allowedVanity && values.vanityURL) {
      message.error(intlMessages('profile.vanity.allowed.error'));
    } else if (!longVanity && values.vanityURL) {
      message.error(intlMessages('profile.vanity.length.error'));
    } else {
      if (values.birthday) {
        values.birthday = values.birthday.toDate();
      }
      FirebaseSocial.updateOverview(values);
      onModalClose();
      message.success(intlMessages('profile.update.success'));
    }
  };

  const cancelFormSubmission = () => {
    onModalClose();
    form.resetFields();
  };

  let extra = null;
  if (!readonly) {
    extra = (
      <i
        className={`icon icon-edit gx-pointer`}
        style={{fontSize: 15}}
        onClick={onModalOpen}
      />
    );
  }

  if (!user) {
    return <Spin indicator={spinnerIcon} />;
  } else {
    return (
      <div>
        {userError && <strong>Connection error!</strong>}
        {userLoading && <p>Loading...</p>}
        {user && (
          <div>
            <div className="gx-mb-2">
              <div className="gx-profile-banner-bottom">
                <span className="gx-link gx-profile-setting">{extra}</span>
              </div>
              <Row>
                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <AboutItem
                    data={{
                      title: 'Works at',
                      icon: 'company',
                      desc: [user.social.company ? user.social.company : ''],
                    }}
                  />
                </Col>
                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <AboutItem
                    data={{
                      title: 'Birthday',
                      icon: 'birthday-new',
                      desc: [
                        user.social.birthday
                          ? moment(user.social.birthday.toDate()).format('ll')
                          : '',
                      ],
                    }}
                  />
                </Col>
                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <AboutItem
                    data={{
                      title: 'Went to',
                      icon: 'graduation',
                      desc: [
                        user.social.education ? user.social.education : '',
                      ],
                    }}
                  />
                </Col>
                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <AboutItem
                    data={{
                      title: 'Lives in',
                      icon: 'home',
                      desc: [user.social.location ? user.social.location : ''],
                    }}
                  />
                </Col>
                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <AboutItem
                    data={{
                      title: 'Vanity URL',
                      icon: 'wysiwyg',
                      desc: [user.vanityURL],
                    }}
                  />
                </Col>
              </Row>
            </div>

            <Modal
              title={
                profile.name === '' ? (
                  <IntlMessages id="profile.addProfileInfo" />
                ) : (
                  <IntlMessages id="profile.saveProfileInfo" />
                )
              }
              toggle={onModalClose}
              visible={showModal}
              closable={true}
              onOk={() => form.submit()}
              onCancel={cancelFormSubmission}>
              <Form {...formItemLayout} form={form} onFinish={onFinish}>
                <Form.Item name="company" label="Company">
                  <Input placeholder="Workplace" />
                </Form.Item>
                <Form.Item name="education" label="Education">
                  <Input placeholder="Education" />
                </Form.Item>
                <Form.Item name="location" label="Location">
                  <Input placeholder="Location" />
                </Form.Item>
                <Form.Item name="vanityURL" label="Vanity URL:">
                  <Input value={user.vanityURL} />
                </Form.Item>
                <Form.Item name="birthday" label="Birthday">
                  <DatePicker placeholder="Birthday" />
                </Form.Item>
                <Button htmlType="submit" style={{display: 'none'}}></Button>
              </Form>
            </Modal>
          </div>
        )}
      </div>
    );
  }
};

export default Overview;
