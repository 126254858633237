import React from 'react';
import {Radio, Input, Button, Col} from 'antd';
import {FaPlus} from 'react-icons/fa';

const GoalUtilities = ({
  onSearch,
  goalsDisplay,
  onChangeDisplay,
  setModalVisible,
}) => (
  <div className="goals-utility">
    <div className="goals-utility-search">
      <Col span={12} className="p-0">
        <Input
          id="search-my-goals"
          placeholder="Search goals..."
          type="search"
          onChange={onSearch}
        />
      </Col>
    </div>
    <div className="goals-utility-display">
      <Radio.Group defaultValue={goalsDisplay} onChange={onChangeDisplay}>
        <Radio.Button value="list">List</Radio.Button>
        <Radio.Button value="grid">Grid</Radio.Button>
      </Radio.Group>
    </div>
    <div className="goals-utility-actions">
      <Button
        type="button"
        className="btn btn-sm btn-primary"
        onClick={() => setModalVisible(true)}>
        <FaPlus className="add-goal-icon" /> Add Goal
      </Button>
    </div>
  </div>
);

export default GoalUtilities;
