import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Menu} from 'antd/lib';
import {Link} from 'react-router-dom';
import IntlMessages from '../../util/IntlMessages';
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from '../../constants/ThemeSetting';
import {FirebaseDAO} from 'firebase/firebase';

const SubMenu = Menu.SubMenu;

class HorizontalNav extends Component {
  getNavStyleSubMenuClass = navStyle => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return 'gx-menu-horizontal gx-submenu-popup-curve';
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return 'gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve';
      case NAV_STYLE_BELOW_HEADER:
        return 'gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve';
      case NAV_STYLE_ABOVE_HEADER:
        return 'gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve';
      default:
        return 'gx-menu-horizontal';
    }
  };

  render() {
    const {pathname, navStyle} = this.props;
    const selectedKeys = pathname.pathname;
    const defaultOpenKeys = selectedKeys.split('/')[1];
    return (
      <Menu
        defaultOpenKeys={[defaultOpenKeys]}
        selectedKeys={[selectedKeys]}
        mode="horizontal">
        <SubMenu
          className={this.getNavStyleSubMenuClass(navStyle)}
          key="head"
          title={<IntlMessages id="sidebar.main" />}>
          <Menu.Item key="loantracker">
            <Link to="/app/loans">
              <i className="icon icon-revenue-new" />
              <IntlMessages id="sidebar.loantracker" />
            </Link>
          </Menu.Item>
          <Menu.Item key="accelerator">
            <Link to="/app/accelerator">
              <i className="icon icon-apps-new" />
              <IntlMessages id="sidebar.kits" />
            </Link>
          </Menu.Item>
          <Menu.Item key="coaches">
            <Link to="/app/coaches">
              <i className="icon icon-team" />
              <IntlMessages id="sidebar.coaches" />
            </Link>
          </Menu.Item>
          <Menu.Item key="messages">
            <Link to="/app/messages">
              <i className="icon icon-email" />
              <IntlMessages id="sidebar.messages" />
            </Link>
          </Menu.Item>
          <Menu.Item key="goals">
            <Link to="/app/goals">
              <i className="icon icon-star" />
              <IntlMessages id="sidebar.goals" />
            </Link>
          </Menu.Item>
          <Menu.Item key="tasks">
            <Link to="/app/tasks">
              <i className="icon icon-check-square-o" />
              <IntlMessages id="sidebar.tasks" />
            </Link>
          </Menu.Item>
          <Menu.Item key="profile">
            <Link to={`/app/user/${FirebaseDAO.auth.currentUser.uid}`}>
              <i className="icon icon-profile2" />
              <IntlMessages id="sidebar.profile" />
            </Link>
          </Menu.Item>
          <Menu.Item key="settings">
            <Link to="/app/settings">
              <i className="icon icon-setting" />
              <IntlMessages id="sidebar.settings" />
            </Link>
          </Menu.Item>
          <Menu.Item key="connect">
            <Link to="/app/link">
              <i className="icon icon-add" />
              <IntlMessages id="sidebar.connect" />
            </Link>
          </Menu.Item>
        </SubMenu>

        <SubMenu
          className={this.getNavStyleSubMenuClass(navStyle)}
          key="templates"
          title={<IntlMessages id="sidebar.templates" />}>
          <Menu.Item key="in-built-apps/templates">
            <Link to="/in-built-apps/templates">
              <i className="icon icon-ckeditor" />
              <IntlMessages id="sidebar.templates" />
            </Link>
          </Menu.Item>

          <Menu.Item key="in-built-apps/steps">
            <Link to="/in-built-apps/steps">
              <i className="icon icon-apps" />
              <IntlMessages id="sidebar.steps" />
            </Link>
          </Menu.Item>

          <Menu.Item key="in-built-apps/sections">
            <Link to="/in-built-apps/sections">
              <i className="icon icon-product-grid" />
              <IntlMessages id="sidebar.sections" />
            </Link>
          </Menu.Item>

          <Menu.Item key="in-built-apps/requirements">
            <Link to="/in-built-apps/requirements">
              <i className="icon icon-check-square-o" />
              <IntlMessages id="sidebar.req" />
            </Link>
          </Menu.Item>
        </SubMenu>

        {process.env.REACT_APP_DISPLAY_MODE === 'dev' && (
          <SubMenu
            className={this.getNavStyleSubMenuClass(navStyle)}
            key="in-built-apps"
            title={<IntlMessages id="sidebar.inBuiltApp" />}>
            <Menu.Item key="profile">
              <Link to={`/app/user/${FirebaseDAO.auth.currentUser.uid}`}>
                <i className="icon icon-profile2" />
                <IntlMessages id="sidebar.extensions.profile" />
              </Link>
            </Menu.Item>

            <Menu.Item key="wall">
              <Link to="/app/wall">
                <i className="icon icon-avatar -flex-column-reverse" />
                <IntlMessages id="sidebar.wall" />
              </Link>
            </Menu.Item>

            <Menu.Item key="forum">
              <Link to="/app/forum">
                <i className="icon icon-team -flex-column-reverse" />
                <IntlMessages id="sidebar.forum" />
              </Link>
            </Menu.Item>
          </SubMenu>
        )}
      </Menu>
    );
  }
}

HorizontalNav.propTypes = {};
const mapStateToProps = ({settings}) => {
  const {themeType, navStyle, pathname, locale} = settings;
  return {themeType, navStyle, pathname, locale};
};
export default connect(mapStateToProps)(HorizontalNav);
