import React from 'react';
import { Button, Modal } from 'antd/lib';
import RequestList from './requestList';

const ApproveGroupJoinRequest = ({ group, requests, showModal, closeRequstModal, approveRejectJoinRequest }) => {
    return (
        <div>
            <Modal
                visible={showModal}
                closable={false}
                onCancel={() => closeRequstModal()}
                header={false}
                footer={
                    <Button key="cancel" onClick={() => closeRequstModal()}>
                        Close
                    </Button>
                }
            >
                <RequestList
                    group={group}
                    requests={requests}
                    approveRejectJoinRequest={approveRejectJoinRequest}
                />
            </Modal>
        </div>
    );
};

export default ApproveGroupJoinRequest;
