import React, {useState} from 'react';
import {Modal, Button, Radio, message} from 'antd/lib';
import {Elements, StripeProvider} from 'react-stripe-elements';
import CheckoutForm from './CheckoutForm';
import IntlMessages from 'util/IntlMessages';
import {Row, Col} from 'antd/lib';
import moment from 'moment';
import {Wizard, Steps, Step} from 'react-albus';
import FirebaseCoaches from 'firebase/firebaseCoaches';
import ServiceCell from '../services/ServiceCell';
import SummaryForm from './SummaryForm';
import '../style.css';
import {
  covertTimestamp,
  getAvailabilityEntry,
  dateFormatDisplay,
} from 'util/commonUtils';
import numeral from 'numeral';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';

import InfiniteCalendar from 'react-infinite-calendar';
import AvailabilityList from './AvailabilityList';
import 'react-infinite-calendar/styles.css';
import {FaSpinner} from 'react-icons/fa';
import Select from 'react-timezone-select';

const BookAppointment = ({coach, profile}) => {
  const intlMessages = useFormatMessage();
  const [showModal, setShowModal] = useState(false);
  const [checkSlot, setCheckSlot] = useState(false);
  const [date, setDate] = useState(new Date());
  const [serviceValue, setCheckServiceValues] = useState([]);
  const [services, setServices] = useState([]);
  const [availability, setAvailability] = useState([]);
  const [timings, setTimings] = useState([]);
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedDatetime, setSelectedDatetime] = useState(0);
  const formatDate = moment(date).format('MMM Do YY');
  const [maxDate, setMaxDate] = useState(covertTimestamp(new Date()));
  const [modalInit, setModalInit] = useState(false);
  const [calendarView, setCalendarView] = useState(true);
  const [bookedSlots, setBookedSlots] = useState([]);
  const [timezone, setTimezone] = useState({
    value: 'GMT',
    label: '(GMT) Dublin, Edinburgh, Lisbon, London',
  });

  const onModalClose = () => {
    setShowModal(false);
    setCheckServiceValues([]);
    setServices([]);
    setAvailability([]);
    setTimings([]);
    setSelectedTime('');
    setCalendarView(true);
    setSelectedDatetime(0);
    setDate(new Date());
  };

  const onServiceChange = async e => {
    setCheckServiceValues(e.target.value);
  };

  const selectTime = (data, i) => {
    setSelectedTime(data);
  };

  const selectDateTime = (date, time, i) => {
    setSelectedDatetime(date * 1000);
    setSelectedTime(time);
    setDate(new Date(dateFormatDisplay(date * 1000, 'Y, M, D')));
  };

  const processCardPayment = async payload => {
    if (payload) {
      let token = payload;
      token = {
        ...token,
        tokenId: token.id,
      };

      let bookingData = {
        service: serviceValue.id,
        date: selectedDatetime,
        timeSlot: selectedTime,
        coach: coach.id,
        cancelled: false,
        client: profile.id,
        paymentStatus: 'UNPAID',
        refunded: false,
        timeZone: timezone,
      };

      let [error, response] = await FirebaseCoaches.addMyBookings(bookingData);

      if (error) {
        message.error(
          'There is an error during process payment and coach booking! Try again.',
        );
      } else {
        let trnxData = {
          paymentType: 'coachBookings',
          bookingRefId: response.id,
          amount: parseFloat(serviceValue.servicePrice),
          token: token,
          status: 'UNPAID',
          paymentDetails: {},
          userId: profile.id,
          refundDetails: {},
          refunded: false,
        };
        await FirebaseCoaches.addPaymentTransaction(trnxData);
        onModalClose();
        message.success(intlMessages('coach.appointmentbooking.success'));
      }
    } else {
      message.error(intlMessages('coach.appointmentbooking.wrongCardDetail'));
    }
  };

  const selectedDate = e => {
    //setDate(e);
    let currentDatetime = covertTimestamp(e);
    setSelectedDatetime(currentDatetime * 1000);
    let slots = [];
    if (availability.length > 0) {
      let availSlots = getAvailabilityEntry({
        availability: availability,
        date: currentDatetime * 1000,
      });
      if (availSlots) {
        let timeSlots = availSlots.timeSlots;
        timeSlots.map((s, i) =>
          slots.push({
            id: i,
            time: s,
            show: true,
          }),
        );
      }
      // Filter Old Booked Slots
      if (bookedSlots.length && slots.length) {
        let nextDayTimestamp = (currentDatetime + 86400) * 1000;
        bookedSlots.map(book => {
          if (
            book.date >= currentDatetime * 1000 &&
            book.date <= nextDayTimestamp
          ) {
            slots = slots.filter(slot => slot.time !== book.timeSlot);
          }
          return true;
        });
      }
      setTimings(slots);
    }
  };

  async function getServicedata(coachId) {
    let response = await FirebaseCoaches.getServicesData(coachId);
    if (response.length > 0) {
      setServices(response);
      setCheckServiceValues(response[0]);
    }
  }

  async function geAvailibilityData(coachId) {
    let response = await FirebaseCoaches.geAvailibilityData(coachId);
    let bookingsData = await FirebaseCoaches.getAppointmentsOfCoachData({
      coachid: coachId,
      filter: 'upcoming',
    });
    setAvailability(response);
    setBookedSlots(bookingsData);
    let slots = [];
    let currentDatetime = covertTimestamp(new Date());

    if (response.length > 0) {
      let lastDate = response.reduce(
        (max, p) => (p.endDate > max ? p.endDate : max),
        response[0].endDate,
      );

      if (Number.isInteger(lastDate)) {
        setMaxDate(lastDate);
      }
      setSelectedDatetime(currentDatetime * 1000);
      let availSlots = getAvailabilityEntry({
        availability: response,
        date: currentDatetime * 1000,
      });

      if (availSlots) {
        let timeSlots = availSlots.timeSlots;
        timeSlots.map((s, i) =>
          slots.push({
            id: i,
            time: s,
            show: true,
          }),
        );
      }
      // Filter Old Booked Slots
      if (bookingsData.length && slots.length) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        let todatTimestamp = covertTimestamp(today);
        let nextDayTimestamp = (todatTimestamp + 86400) * 1000;
        bookingsData.map(book => {
          if (
            book.date >= todatTimestamp * 1000 &&
            book.date <= nextDayTimestamp
          ) {
            slots = slots.filter(slot => slot.time !== book.timeSlot);
          }
          return true;
        });
      }
    }
    setTimings(slots);
    setCheckSlot(false);
    setModalInit(false);
    setShowModal(true);
  }

  const onModalOpen = coach => {
    setModalInit(true);
    setCheckSlot(true);
    const coachId = coach;
    getServicedata(coachId);
    geAvailibilityData(coachId);
  };

  const changeCalendarView = e => {
    setCalendarView(e);
  };

  const selectTimezone = e => {
    setTimezone(e);
  };

  // Calendar init Setting
  let yesterday = new Date(new Date());
  yesterday.setDate(new Date().getDate() - 1);
  const minDate = dateFormatDisplay(
    covertTimestamp(yesterday) * 1000,
    'Y, M, D',
  );
  const todayDate = dateFormatDisplay(
    covertTimestamp(new Date()) * 1000,
    'Y, M, D',
  );
  const _maxDate = dateFormatDisplay(maxDate * 1000, 'Y, M, D');

  return (
    <>
      <Button
        type="primary"
        className="gx-ml-auto gx-mb-0 gx-btn-block"
        onClick={() => onModalOpen(coach.id)}
        key={coach.id + 'book'}>
        {modalInit ? (
          <FaSpinner size="15" className="icon-spin" />
        ) : (
          <IntlMessages id="coachCard.book.appointment" />
        )}
      </Button>

      <Modal
        title={<IntlMessages id="coachCard.calendar" />}
        toggle={onModalClose}
        visible={showModal}
        closable={true}
        onCancel={onModalClose}
        destroyOnClose={true}
        footer={null}>
        <Wizard>
          <Steps>
            <Step
              id="step1"
              render={({next}) => (
                <div>
                  <div className="gx-text-align-center">
                    <Radio.Group
                      defaultValue={calendarView ? 'calender' : 'list'}>
                      <Radio.Button
                        value="calender"
                        onClick={() => changeCalendarView(true)}>
                        Calendar View
                      </Radio.Button>
                      <Radio.Button
                        value="list"
                        onClick={() => changeCalendarView(false)}>
                        List View
                      </Radio.Button>
                    </Radio.Group>

                    {calendarView ? (
                      <InfiniteCalendar
                        locale={{
                          weekStartsOn: 1,
                        }}
                        height={300}
                        onSelect={selectedDate}
                        min={new Date(minDate)}
                        minDate={new Date(todayDate)}
                        selected={date}
                        max={new Date(_maxDate)}
                        maxDate={new Date(_maxDate)}
                      />
                    ) : (
                      <AvailabilityList
                        availability={availability}
                        bookedSlots={bookedSlots}
                        maxDate={maxDate}
                        selectDateTime={selectDateTime}
                        selectedDateSlot={{
                          date: dateFormatDisplay(selectedDatetime, 'MMDDYYYY'),
                          time: selectedTime,
                        }}
                      />
                    )}
                  </div>
                  {calendarView ? (
                    <Row className="time-list">
                      {timings && timings.length ? (
                        timings.map(
                          el =>
                            el.show === true && (
                              <Col span={8} key={el.id}>
                                {selectedTime === el.time && (
                                  <div
                                    className="gx-changeboxView"
                                    onClick={() => selectTime(el.time)}>
                                    {el.time.split('to')[0]}
                                  </div>
                                )}
                                {selectedTime !== el.time && (
                                  <div
                                    className="gx-boxView"
                                    onClick={() => selectTime(el.time, el.id)}>
                                    {el.time.split('to')[0]}
                                  </div>
                                )}
                              </Col>
                            ),
                        )
                      ) : (
                        <Col span={24}>
                          <p className="text-center gx-mt-5 gx-ml-10">
                            {checkSlot
                              ? 'Checking available slots...'
                              : '- No time slot available for this date -'}
                          </p>
                        </Col>
                      )}
                    </Row>
                  ) : null}
                  <div className="gx-mt-3 gx-mr-5">
                    <h5>Time Zone:</h5>
                    <div className="select-wrapper">
                      <Select
                        value={timezone}
                        defaultValue={timezone}
                        onChange={e => selectTimezone(e)}
                      />
                    </div>
                  </div>
                  <div className="ant-modal-footer" style={{marginTop: 20}}>
                    <Button onClick={onModalClose}> Cancel </Button>
                    {timings.length && selectedTime ? (
                      <Button type="primary" onClick={next}>
                        Next
                      </Button>
                    ) : (
                      <Button type="primary" disabled>
                        Next
                      </Button>
                    )}
                  </div>
                </div>
              )}
            />
            <Step
              id="step2"
              render={({next, previous}) => (
                <div>
                  <Radio.Group onChange={onServiceChange} value={serviceValue}>
                    <div className="service-list">
                      {services.map((e, i) => (
                        <Radio key={i} value={e}>
                          <ServiceCell
                            service={e}
                            key={e.id}
                            readony={true}
                            avtarHeight={50}
                          />
                        </Radio>
                      ))}
                    </div>
                  </Radio.Group>
                  <div className="ant-modal-footer" style={{marginTop: 20}}>
                    <Button onClick={previous}> Previous </Button>
                    {serviceValue && (
                      <Button type="primary" onClick={next}>
                        Next
                      </Button>
                    )}
                  </div>
                </div>
              )}
            />
            <Step
              id="step3"
              render={({next, previous}) => (
                <div>
                  <SummaryForm
                    coach={coach}
                    service={serviceValue}
                    date={formatDate}
                    slot={selectedTime}
                  />
                  <div className="ant-modal-footer" style={{marginTop: 20}}>
                    <Button onClick={previous}> Previous </Button>
                    <Button type="primary" onClick={next}>
                      Proceed to Pay{' '}
                      {numeral(serviceValue.servicePrice).format('$0,0.00')}
                    </Button>
                  </div>
                </div>
              )}
            />
            <Step
              id="step 4"
              render={({previous}) => (
                <div>
                  <StripeProvider apiKey={FirebaseCoaches.stripe_public_key}>
                    <div className="example">
                      <h4> Please fill your card detail</h4>
                      <Elements>
                        <CheckoutForm
                          toPay={serviceValue.servicePrice}
                          processCardPayment={processCardPayment}
                        />
                      </Elements>
                    </div>
                  </StripeProvider>
                  <div className="ant-modal-footer" style={{marginTop: 20}}>
                    <Button onClick={previous}> Previous </Button>
                  </div>
                </div>
              )}
            />
          </Steps>
        </Wizard>
      </Modal>
    </>
  );
};

export default BookAppointment;
