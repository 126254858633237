import React from 'react';
import {Button} from 'antd';
import {FaEdit, FaTrash} from 'react-icons/fa';

const ManageGoalButtons = ({item, manage}) => {
  const buttons = [
    {
      className: 'ant-btn-primary goal-btn-icon',
      icon: <FaEdit />,
      text: 'Edit',
      onClick: () => {
        manage.editGoal(item);
      },
    },
    {
      className: 'ant-btn-danger goal-btn-icon',
      icon: <FaTrash />,
      text: 'Delete',
      onClick: () => {
        manage.deleteGoal(item);
      },
    },
  ];

  return buttons.map(btn => (
    <Button className={btn.className} onClick={btn.onClick}>
      {btn.icon}
      <span className="m l-1">{btn.text}</span>
    </Button>
  ));
};

export default ManageGoalButtons;
