import React, {useState} from 'react';
import {Row, Col} from 'antd';
import Widget from 'components/Widget/index';
import {FirebaseDAO} from 'firebase/firebase';
import ToggleBox from 'components/app/togglebox';

const optionStates = [
  {label: 'Goals Notifications', value: 'goals', state: false},
  {label: 'Budget Notifications', value: 'budgets', state: false},
];

const FinancialNotification = ({profile}) => {
  const [oStates, setOstates] = useState(
    (profile.notifications && profile.notifications.financial) || optionStates,
  );
  const endCollection = 'notifications.financial';
  const toggleState = state => !state;
  const onCheckBoxClick = async id => {
    let newOptionStates = [];
    oStates.map(option => {
      if (option.value === id) {
        newOptionStates.push({...option, state: toggleState(option.state)});
      } else {
        newOptionStates.push(option);
      }
      return true;
    });
    setOstates(newOptionStates);
    await FirebaseDAO.updateFBDoc(endCollection, newOptionStates);
  };

  return (
    <Widget style={{padding: 20}}>
      <Row>
        {oStates.map(o => {
          return (
            <Col key={o.value} span={24}>
              <ToggleBox
                id={o.value}
                label={o.label}
                key={o.value}
                state={o.state}
                onCheckBoxClick={onCheckBoxClick}
                orientation="horizontal"
              />
            </Col>
          );
        })}
      </Row>
    </Widget>
  );
};

export default FinancialNotification;
