import React from 'react';
import {Col, Radio, Tooltip, InputNumber} from 'antd';
import '../settingsWizard.less';
import withStepTemplate from './withStepTemplate';

import {InfoCircleOutlined} from '@ant-design/icons';

const stepGig = props => {
  return (
    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
      <h4 className="gx-mb-2 formLabel">
        <span>
          Do you earn other income from working (gig work or 1099 income)?
          <Tooltip
            placement="topLeft"
            title="Do you earn other income from working (gig work or 1099 income)?"
            trigger="hover">
            <InfoCircleOutlined
              className="gx-ml-1 gx-pointer"
              style={{fontSize: '16px', verticalAlign: 'middle'}}
            />
          </Tooltip>
        </span>
      </h4>
      <Radio.Group
        value={props.state.ownGig}
        buttonStyle="solid"
        onChange={value => {
          props.dispatch({
            type: 'ownGig',
            payload: value.target.value,
          });
        }}>
        <Radio.Button value={true}>Yes</Radio.Button>
        <Radio.Button value={false}>No</Radio.Button>
      </Radio.Group>

      {/* Business */}
      {props.state.ownGig && (
        <div>
          <h4 className="gx-mb-2 gx-mt-3 formLabel">
            <span>
              Has your gig work/1099 income been negatively affected by the
              COVID-19 crisis?
              <Tooltip
                placement="topLeft"
                title="Has your gig work/1099 income been negatively affected by the COVID-19 crisis?"
                trigger="hover">
                <InfoCircleOutlined
                  className="gx-ml-1 gx-pointer"
                  style={{fontSize: '16px', verticalAlign: 'middle'}}
                />
              </Tooltip>
            </span>
          </h4>

          <Radio.Group
            value={props.state.covidGigImpact}
            buttonStyle="solid"
            onChange={value => {
              props.dispatch({
                type: 'covidGigImpact',
                payload: value.target.value,
              });
            }}>
            <Radio.Button value={true}>Yes</Radio.Button>
            <Radio.Button value={false}>No</Radio.Button>
          </Radio.Group>

          {props.state.covidGigImpact && (
            <div>
              <h4 className="gx-mb-2 gx-mt-3 formLabel">
                <span>
                  Approximately how much 1099 income did you earn last year?
                  <Tooltip
                    placement="topLeft"
                    title="Self-employment income equals gross income (i.e. 1099 income received) minus ordinary business expenses."
                    trigger="hover">
                    <InfoCircleOutlined
                      className="gx-ml-1 gx-pointer"
                      style={{fontSize: '16px', verticalAlign: 'middle'}}
                    />
                  </Tooltip>
                </span>
              </h4>

              <InputNumber
                value={props.state.avgMonthlyPayrollGig}
                onFocus={event => event.target.select()}
                onChange={value => {
                  props.dispatch({
                    type: 'avgMonthlyPayrollGig',
                    payload: value,
                  });
                }}
                formatter={value =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                style={{width: '180px'}}
              />
            </div>
          )}
        </div>
      )}
    </Col>
  );
};

export default withStepTemplate(stepGig);
