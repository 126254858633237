import React from 'react';
import {Col, Input, Tooltip} from 'antd';
import '../settingsWizard.less';
import withStepTemplate from './withStepTemplate';
import {InfoCircleOutlined} from '@ant-design/icons';
import Grid from '@material-ui/core/Grid';

const stepEmail = props => {
  return (
    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
      <h3 className="gx-mb-2 gx-mt-3 gx-mb-5 formLabel">
        <ul>
          <li style={{textAlign: 'left'}}>
            Based on your information, the next page shows your{' '}
            <strong style={{color: '#000'}}>recommended action items</strong>.
          </li>

          <li style={{textAlign: 'left'}}>
            If you would like updates on new stimulus, please provide your name
            and email.
          </li>
        </ul>
      </h3>

      {/* <Grid container spacing={2} style={{textAlign: 'center'}}>
        <Grid item xs={6} sm={5} md={4} lg={4} xl={4}> */}
      <h4 className="gx-mb-2 gx-mt-3 formLabel">
        <span>First Name:</span>
      </h4>

      <Input
        style={{width: '30vw'}}
        value={props.state.firstName}
        onChange={value => {
          props.dispatch({
            type: 'firstName',
            payload: value.target.value,
          });
        }}
      />

      <h4 className="gx-mb-2 gx-mt-3 formLabel">
        <span>Last Name:</span>
      </h4>

      <Input
        style={{width: '30vw'}}
        value={props.state.lastName}
        onChange={value => {
          props.dispatch({
            type: 'lastName',
            payload: value.target.value,
          });
        }}
      />
      {/* </Grid>
      </Grid> */}

      <h4 className="gx-mb-2 gx-mt-3 formLabel">
        <span>Email Address:</span>
      </h4>

      <Input
        style={{width: '30vw'}}
        value={props.state.email}
        type="email"
        onChange={value => {
          props.dispatch({
            type: 'email',
            payload: value.target.value,
          });
        }}
      />
    </Col>
  );
};

export default withStepTemplate(stepEmail);
