import React from 'react';
import {Button} from 'antd/lib';
import {Link} from 'react-router-dom';
import DisplayDate from 'util/DisplayDate';
import {useCollectionData} from 'react-firebase-hooks/firestore';
import FirebaseGroups from 'firebase/firebaseGroups';

const SearchDataCard = ({profile, data, leaveGroup, sendJoinRequest}) => {
  const [memberStatus] = useCollectionData(
    FirebaseGroups.getuserGroupMemberStatus(profile.id, data.id),
    {
      idField: 'id',
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  return (
    <div className="gx-media gx-featured-item">
      <div className="gx-featured-thumb">
        <Link
          to={{
            pathname: `/app/group/${profile.vanityURL}/${data.id}`,
            userId: data.userId,
          }}>
          <img
            className="gx-rounded-lg gx-width-175"
            src={data.groupCoverPhoto.downloadURL}
            alt="..."
          />
        </Link>
      </div>
      <div className="gx-media-body gx-featured-content">
        <div className="gx-featured-content-left">
          <h3 className="gx-mb-2">
            <Link
              to={{
                pathname: `/app/group/${profile.vanityURL}/${data.id}`,
                userId: data.userId,
              }}>
              {data.groupName}
            </Link>
          </h3>
          <p className="gx-text-grey gx-mb-1">{data.groupDescription}</p>
          <div className="ant-row-flex">
            <p className="gx-text-grey gx-mb-1">
              <i
                className={`icon icon-search gx-fs-xs gx-mr-2 gx-d-inline-flex gx-vertical-align-middle`}
              />
              {data.groupSearchable === 0
                ? 'Only Member Searchable'
                : 'Public Searchable'}
            </p>
            <p className="gx-text-grey gx-mb-1">
              <i
                className={`icon icon-team gx-fs-xs gx-mr-2 gx-d-inline-flex gx-vertical-align-middle`}
              />
              {data.membersCount
                ? data.membersCount > 1
                  ? `${data.membersCount} Members`
                  : `${data.membersCount} Member`
                : `0 Member`}
            </p>
          </div>
          <div className="ant-row-flex">
            <p className="gx-text-grey gx-mb-1">
              <i
                className={`icon icon-compose gx-fs-xs gx-mr-2 gx-d-inline-flex gx-vertical-align-middle`}
              />
              {data.groupPostsCount
                ? data.groupPostsCount > 1
                  ? `${data.groupPostsCount} Posts`
                  : `${data.groupPostsCount} Post`
                : `0 Post`}
            </p>
            <p className="gx-text-grey gx-mb-1">
              <i
                className={`icon icon-datepicker gx-fs-xs gx-mr-2 gx-d-inline-flex gx-vertical-align-middle`}
              />
              {<DisplayDate date={data.createdOn.toString()} />}
            </p>
          </div>
        </div>
        <div className="gx-featured-content-right">
          {memberStatus && memberStatus.length ? (
            memberStatus[0].status === 2 ? (
              <p>
                <Button
                  type="danger"
                  className="gx-ml-auto gx-mb-0 gx-btn-block "
                  key={data.id + 'cancel'}
                  onClick={() => leaveGroup(memberStatus[0], data)}>
                  Cancel Request
                </Button>
              </p>
            ) : (
              <p>
                <Button
                  type="danger"
                  className="gx-ml-auto gx-mb-0 gx-btn-block "
                  key={data.id + 'leave'}
                  onClick={() => leaveGroup(memberStatus[0], data)}>
                  Leave Group
                </Button>
              </p>
            )
          ) : (
            <p>
              <Button
                className="gx-ml-auto gx-mb-0 gx-btn-block "
                key={data.id + 'view'}
                onClick={() => sendJoinRequest(data)}>
                Send Join Request
              </Button>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchDataCard;
