import React from 'react';
import Widget from 'components/Widget';
import Follow from 'components/app/contact/Follow';
import FriendRequest from '../../app/contact/FriendRequest';
import MessageBubble from 'components/app/contact/MessageBubble';

const Contact = ({userId}) => {
  return (
    <div>
      <Widget title="Get in touch" styleName="gx-card-profile-sm">
        <div>
          <Follow userId={userId} />
          <FriendRequest userId={userId} />
          <MessageBubble userId={userId} />
        </div>
      </Widget>
    </div>
  );
};

export default Contact;
