import React from 'react';
import {Avatar, Row, Col, Tabs, Spin} from 'antd/lib';
import {useCollectionData} from 'react-firebase-hooks/firestore';
import {FaSpinner} from 'react-icons/fa';
import About from 'components/profile/About/index';
import Posts from 'components/profile/Posts/index';
import Auxiliary from 'util/Auxiliary';
import SocialList from '../../../routes/app/socialList';
import FirebasePosts from 'firebase/firebasePosts';
import FirebaseSocial from 'firebase/firebaseSocial';
import FirebaseForums from 'firebase/firebaseForums';
import Vlogs from 'components/Vlogs';
import PhotoGallery from 'components/profile/PhotoGallery';
import Thread from '../../../routes/app/forum/Thread';
import SocialGuestView from '../Social/SocialGuestView';

const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;

function sortDeepArray(arr) {
  return arr.sort((a, b) => {
    let aTime = new Date(a.date).getTime();
    let bTime = new Date(b.date).getTime();
    if (aTime < bTime) {
      return 1;
    }
    if (aTime > bTime) {
      return -1;
    }
    return 0;
  });
}

const GuestView = ({profile}) => {
  let userId = profile.id;

  const TabPane = Tabs.TabPane;

  const [friendOneLook, friendOneLoading, friendOneError] = useCollectionData(
    FirebaseSocial.getUserFriendOne(userId),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [friendTwoLook, friendTwoLoading, friendTwoError] = useCollectionData(
    FirebaseSocial.getUserFriendTwo(userId),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [followerList, followerLoading, followerError] = useCollectionData(
    FirebaseSocial.getUserFollowers(userId),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [followingList, followingLoading, followingError] = useCollectionData(
    FirebaseSocial.getUserFollowing(userId),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [postList, postListLoading, postListError] = useCollectionData(
    FirebasePosts.getUserPostList(userId),
    {
      idField: 'id',
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [threadList, threadListLoading, threadListError] = useCollectionData(
    FirebaseForums.getUserForums(userId),
    {
      idField: 'id',
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  if (!profile || !profile.social || !postList) {
    return <Spin indicator={spinnerIcon} />;
  } else {
    let userPostList = sortDeepArray(postList);

    let location = profile.social.location;
    let username = profile.username;
    let cover = profile.cover;
    let avatar = profile.avatar;

    return (
      <div>
        {(friendOneError ||
          friendTwoError ||
          followerError ||
          followingError ||
          postListError ||
          threadListError) && <strong>Connection error!</strong>}
        {(friendOneLoading ||
          friendTwoLoading ||
          followerLoading ||
          followingLoading ||
          postListLoading ||
          threadListLoading) && <p>Loading...</p>}
        {friendOneLook &&
          friendTwoLook &&
          followerList &&
          followingList &&
          postList &&
          threadList && (
            <Auxiliary>
              <div
                className="gx-profile-banner"
                style={{
                  backgroundImage: `url(${cover})`,
                  backgroundSize: 'cover',
                  maxHeight: 169,
                }}>
                <div className="gx-profile-container" style={{top: -8}}>
                  <div className="gx-profile-banner-top">
                    <div className="gx-flex-row">
                      <div className="gx-profile-banner-avatar profile-pic-container">
                        <Avatar
                          src={avatar}
                          className="gx-size-120 gx-pointer gx-mb-10 gx-ml-20"
                        />
                      </div>
                      <div className="gx-profile-banner-avatar-info gx-pointer gx-vertical-align-middle">
                        <h2 className="gx-mt-3 gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light">
                          {username}
                        </h2>
                        <p className="gx-mb-0 gx-fs-lg">{location}</p>
                      </div>
                    </div>
                    <div className="gx-profile-banner-top-right">
                      <ul className="gx-follower-list">
                        <li>
                          <span className="gx-fs-sm">Followers</span>
                          <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">
                            {followerList.length}
                          </span>
                        </li>
                        <li>
                          <span className="gx-fs-sm">Following</span>
                          <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">
                            {followingList.length}
                          </span>
                        </li>
                        <li>
                          <span className="gx-fs-sm">Friends</span>
                          <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">
                            {friendOneLook.length + friendTwoLook.length}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="gx-profile-banner-bottom" style={{marginTop: 72}}>
                <Tabs defaultActiveKey="1" style={{width: '100%'}}>
                  <TabPane tab="Profile" key="1">
                    <Row>
                      <Col xl={16} lg={14} md={14} sm={24} xs={24}>
                        <About profile={profile} readonly={true} />
                        <Posts
                          profile={profile}
                          postList={userPostList}
                          readonly={true}
                        />
                      </Col>
                      <Col xl={8} lg={10} md={10} sm={24} xs={24}>
                        <Row>
                          <Col xl={24} lg={24} md={24} sm={12} xs={24}>
                            <SocialGuestView
                              friendView
                              length={
                                friendOneLook.length + friendTwoLook.length
                              }
                            />
                            <SocialGuestView
                              followingView
                              length={followingList.length}
                            />
                            <SocialGuestView
                              followerView
                              length={followerList.length}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane
                    tab={
                      'Friends ' + (friendOneLook.length + friendTwoLook.length)
                    }
                    key="2">
                    <SocialList guest userId={profile.id} />
                  </TabPane>
                  <TabPane tab={'Following ' + followingList.length} key="3">
                    <SocialList guest userId={profile.id} />
                  </TabPane>
                  <TabPane tab={'Followers ' + followerList.length} key="4">
                    <SocialList guest userId={profile.id} />
                  </TabPane>
                  <TabPane tab="Vlogs" key="5">
                    <Vlogs
                      profile={profile}
                      userId={userId}
                      profileView={true}
                    />
                  </TabPane>

                  <TabPane tab={'Forums'} key="6">
                    {threadList.map(thread => {
                      return <Thread threadInfo={thread} key={thread.id} />;
                    })}
                  </TabPane>

                  <TabPane tab={'Gallery'} key="7">
                    <PhotoGallery userId={profile.id} guest />
                  </TabPane>
                </Tabs>
              </div>
            </Auxiliary>
          )}
      </div>
    );
  }
};
export default GuestView;
