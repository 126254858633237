import React from 'react';
import numeral from 'numeral';
import Aux from 'util/Auxiliary';

const LineIndicator = ({ title, title2, width, value, color }) => {
  return (
    <Aux>
      <div
        className="ant-row-flex"
        style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
        <span className="gx-mr-1">{title}</span>
        <span
          className="gx-text-right"
          style={{ width: '300px', fontWeight: 600 }}>
          {numeral(value).format('$0,0')}
        </span>
      </div>
      <div className="gx-line-indi-info">
        <div
          className={`gx-line-indi gx-bg-${color}`}
          style={{
            width: Number.parseInt(width, 10) * 4,
          }}
        />
        <div
          className={`gx-line-indi gx-bg-grey`}
          style={{
            width: 400 - Number.parseInt(width, 10) * 4,
          }}
        />
      </div>
    </Aux>
  );
};

export default LineIndicator;