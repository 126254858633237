import Payment from 'payment';
import moment from 'moment';
import { COACH_TIMESLOTS } from 'loanpalz-constants';

function clearNumber(value = '') {
    return value.replace(/\D+/g, '');
}

export function formatCreditCardNumber(value) {
    if (!value) {
        return value;
    }

    const issuer = Payment.fns.cardType(value);
    const clearValue = clearNumber(value);
    let nextValue;

    switch (issuer) {
        case 'amex':
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                10,
            )} ${clearValue.slice(10, 15)}`;
            break;
        case 'dinersclub':
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                10,
            )} ${clearValue.slice(10, 14)}`;
            break;
        default:
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                8,
            )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
            break;
    }

    return nextValue.trim();
}

export function formatCVC(value, prevValue, allValues = {}) {
    const clearValue = clearNumber(value);
    let maxLength = 4;

    if (allValues.number) {
        const issuer = Payment.fns.cardType(allValues.number);
        maxLength = issuer === 'amex' ? 4 : 3;
    }

    return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
    const clearValue = clearNumber(value);

    if (clearValue.length >= 3) {
        return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }

    return clearValue;
}

export function formatFormData(data) {
    return Object.keys(data).map(d => `${d}: ${data[d]}`);
}

export const covertTimestamp = date => {
    return moment.utc(date).unix();
};

export const dateFormatDisplay = (timestamp, format = 'dddd, MMMM Do YYYY') => {
    if (!timestamp) return '';
    return moment(timestamp, 'x').format(format);
};

export const getAvailabilityEntry = ({ availability, date } = {}) => {
    return availability.find(entry => {
        const d = date ? new Date(parseInt(date, 10)) : new Date();
        d.setHours(0, 0, 0, 0);
        const timestamp = Math.floor(d.getTime());

        const dStart = new Date(entry.startDate * 1000);
        dStart.setHours(0, 0, 0, 0);
        const startTime = Math.floor(dStart.getTime());

        const dEnd = new Date(entry.endDate * 1000);
        dEnd.setHours(0, 0, 0, 0);
        const endTime = Math.floor(dEnd.getTime());

        return timestamp >= startTime && timestamp <= endTime;
    });
};

export const arrangeTimeSlots = (timeSlots = []) => {
    const clone = [...timeSlots];

    const newTimeSlots = clone.sort((first, second) => {
        const firstIndex = COACH_TIMESLOTS.findIndex(
            entry => entry.value === first,
        );

        const secondIndex = COACH_TIMESLOTS.findIndex(
            entry => entry.value === second,
        );

        return firstIndex - secondIndex;
    });

    return newTimeSlots;
};

export const convertFirebaseDataIntoNetworthAreaChartData = inputData => {
    let output = [];
    if (inputData) {
        inputData.forEach(entry => {
            output.push({
                name: entry.date.seconds,
                networth: Math.round(entry.networth),
            });
        });
    }
    return output;
};