import React from 'react';
import ReactDOM from 'react-dom';
import NextApp from './NextApp';
// import * as Sentry from '@sentry/browser';
import registerServiceWorker from './registerServiceWorker';
import {AppContainer} from 'react-hot-loader';

// Init Sentry if this is not a DEV environment
// if (process.env.REACT_APP_DISPLAY_MODE !== 'dev') {
//   Sentry.init({
//     release: 'loanpalz@mvp-0.0.1',
//     dsn: 'https://a2df82e24c914e708f87dc760814875f@sentry.io/1797305',
//     beforeSend(event, hint) {
//       // Check if it is an exception, and if so, show the report dialog
//       if (event.exception) {
//         Sentry.showReportDialog({eventId: event.event_id});
//       }
//       return event;
//     },
//   });
// }

// Wrap the rendering in a function:
const render = Component => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <NextApp />
    </AppContainer>,
    document.getElementById('root'),
  );
};

// Do this once
registerServiceWorker();

// Render once
render(NextApp);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./NextApp', () => {
    render(NextApp);
  });
}
