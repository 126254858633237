import React from "react";
import { Row, Col } from 'antd/lib';
import MemberCell from "./memberCell";
import Widget from 'components/Widget';
const MembersList = ({ profile, groupData, groupMembers, removeMember, type }) => {

    let groupTitle = groupData ? groupData.groupName : "Group";

    return (
        <Widget
            title={groupTitle + " members"}
            styleName="gx-card-tabs gx-card-tabs-left gx-card-profile"
            extra=
            {
                groupMembers && groupMembers.length && !type ?
                    <Row type="flex" justify="end">
                        <Col>
                            <input
                                className="ant-input ant-input gx-mb-3"
                                type="search"
                                placeholder="Search Members"
                                id="search"
                            // onKeyUp={updateSearch}
                            // onChange={updateSearch}
                            />
                        </Col>
                    </Row>
                    : null
            }
        >
            {
                groupMembers && groupMembers.length ?
                    groupMembers.map((data, index) =>
                        <MemberCell
                            profile={profile}
                            key={index}
                            data={data}
                            groupData={groupData}
                            removeMember={removeMember}
                        />

                    ) : null
            }

        </Widget >
    )
}

export default MembersList;
