import React from 'react';
import {Row, Col} from 'antd';
import CustomScrollbars from 'util/CustomScrollbars';
import TaskItem from './TaskItem';

const TaskGrid = ({
  toDos,
  onTodoChecked,
  onEditTask,
  onDeleteTask,
  onStarredTask,
  onImportantTask,
  onMarkCompletedTask,
  taskFilterByLabel,
}) => {
  return (
    <div className="gx-ml-3 gx-mr-3 gx-mt-3">
      <CustomScrollbars className="gx-module-content-scroll">
        <Row gutter={[24, 24]}>
          {toDos &&
            toDos.map((todo, index) => (
              <Col key={index} xl={6} lg={6} md={8} sm={12} xs={24}>
                <TaskItem
                  key={index}
                  index={index}
                  todo={todo}
                  onTodoChecked={onTodoChecked}
                  onEditTask={onEditTask}
                  onDeleteTask={onDeleteTask}
                  onStarredTask={onStarredTask}
                  onImportantTask={onImportantTask}
                  onMarkCompletedTask={onMarkCompletedTask}
                  taskFilterByLabel={taskFilterByLabel}
                />
              </Col>
            ))}
        </Row>
      </CustomScrollbars>
    </div>
  );
};

export default TaskGrid;
