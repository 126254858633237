/* eslint-disable react/jsx-wrap-multilines */
import {Card, Col, Row} from 'antd/lib';
import PropTypes from 'prop-types';
import React from 'react';
import './InfoWithImage.less';

const InfoWithImage = ({title, description, image}) => (
  <Row>
    <Col xs={{span: 24}} lg={{span: 24}}>
      <Card
        className="gx-p-4"
        cover={<img alt="example" className="info-img" src={image} />}>
        <h1 className="wiz-grid-title">{title}</h1>
        <h2 className="wiz-grid-description">{description}</h2>
      </Card>
    </Col>
  </Row>
);

InfoWithImage.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

InfoWithImage.defaultProps = {
  title: null,
  description: null,
};

export default InfoWithImage;
