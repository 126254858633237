import 'firebase/analytics';
import app from 'firebase/app';
import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/messaging';
import config from './firebaseConfig';

export class Firebase {
  constructor() {
    if (!firebase.apps.length) {
      app.initializeApp(config);
    }
    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
    this.analytics = app.analytics();
    this.functions = app.functions();
    this.stripe_public_key = 'pk_test_vXyGseIAiuaGymmzEJNbjEfh';

    // Disabling due to lack of browser support
    // this.messaging = app.messaging();
    // this.messaging.usePublicVapidKey(
    //   'BKHjRBy_w4SxQICvb6_WkZt3yY29r6TAesniUiTTX8GDHbimq6AldAgHKwG13d9whe6ImypoRKCts1sgkYNn0FM',
    // );

    // Emulators
    // if (window.location.hostname === 'localhost') {
    //   this.db.settings({host: 'localhost:8080', ssl: false});
    //   this.functions.useFunctionsEmulator('http://localhost:5001');
    // }
  }

  // First Level Simple FB CRUD
  getFBCurrentUserDocumentData() {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    return this.db.collection('users').doc(`${this.auth.currentUser.uid}`);
  }

  getFBCollectionData(endCollection, parentCollection = 'users') {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    return this.db
      .collection(parentCollection)
      .doc(`${this.auth.currentUser.uid}`)
      .collection(endCollection);
  }

  getFBCollectionDataWhereBool(
    endCollection,
    key,
    value,
    parentCollection = 'users',
  ) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    return this.db
      .collection(parentCollection)
      .doc(`${this.auth.currentUser.uid}`)
      .collection(endCollection)
      .where(key, '==', value);
  }

  addFBData(values, endCollection, parentCollection = 'users') {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    return this.db
      .collection(parentCollection)
      .doc(`${this.auth.currentUser.uid}`)
      .collection(endCollection)
      .add(values);
  }

  updateFBData(values, endCollection, parentCollection = 'users') {
    const {id} = values;
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    return this.db
      .collection(parentCollection)
      .doc(`${this.auth.currentUser.uid}`)
      .collection(endCollection)
      .doc(id)
      .update({
        ...values,
      });
  }

  updateFBDoc(doc, docData, parentCollection = 'users') {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    return this.db
      .collection(parentCollection)
      .doc(`${this.auth.currentUser.uid}`)
      .update({
        [doc]: docData,
      });
  }

  getFBData(id, endCollection, parentCollection = 'users') {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    return this.db
      .collection(parentCollection)
      .doc(`${this.auth.currentUser.uid}`)
      .collection(endCollection)
      .doc(id);
  }

  async deleteMultipleFBData(ids, endCollection, parentCollection = 'users') {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let batch = this.db.batch();
    if (ids && ids.length > 0) {
      ids.forEach(id => {
        let dataRef = this.db
          .collection(parentCollection)
          .doc(`${this.auth.currentUser.uid}`)
          .collection(endCollection)
          .doc(id);
        batch.delete(dataRef);
      });
      batch.commit();
    }
  }
}

export const FirebaseDAO = new Firebase();
app.firestore();
app.functions();
app.storage();

export default app;
