import React from 'react';
import { Switch, message } from 'antd/lib';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import './styles.less';

const ToggleBox = ({ id, label, state, onCheckBoxClick, orientation, section, record }) => {
  const intlMessages = useFormatMessage();

  const onClick = () => {

    if (section === 'kit') {
      record.enabled = !state;
      onCheckBoxClick(record);
    } else {
      onCheckBoxClick(id);
      message.success(intlMessages('settings.success'));
    }
  };

  if (orientation === 'horizontal') {
    return (
      <div className="settingsRow">
        <div
          className="gx-flex-row"
          onClick={onClick}
          style={{ cursor: 'pointer' }}>
          <Switch checked={state} /> <span className="gx-ml-2">{label}</span>
        </div>
      </div>
    );
  }

  return (
    <div
      className="gx-text-align-center"
      onClick={onClick}
      style={{ cursor: 'pointer' }}>
      <Switch checked={state} /> <span className="gx-ml-2">{label}</span>
    </div>
  );
};

export default ToggleBox;
