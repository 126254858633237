import React, {useState} from 'react';
import {
  Col,
  Row,
  Tabs,
  Modal,
  Input,
  message,
  Button,
  Spin,
  Form,
} from 'antd/lib';
import Auxiliary from 'util/Auxiliary';
import {FaSpinner} from 'react-icons/fa';
import FirebaseForums from 'firebase/firebaseForums';
import FirebaseSearch from 'firebase/firebaseSearch';
import Widget from 'components/Widget';
import IntlMessages from 'util/IntlMessages';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';
import {useCollectionData} from 'react-firebase-hooks/firestore';
import Thread from './Thread';
import UploadPicture from 'components/common/UploadPicture';

const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;

function sortDeepArray(arr) {
  return arr.sort((a, b) => {
    let aTime = new Date(a.date).getTime();
    let bTime = new Date(b.date).getTime();
    if (aTime < bTime) {
      return 1;
    }
    if (aTime > bTime) {
      return -1;
    }
    return 0;
  });
}

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 24},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 24},
  },
};

const {TextArea} = Input;
const {TabPane} = Tabs;
const Search = Input.Search;

const Forum = () => {
  const [form] = Form.useForm();
  let userId = FirebaseForums.auth.currentUser.uid.toString();

  const [threadList, threadListLoading, threadListError] = useCollectionData(
    FirebaseForums.getUserForums(userId),
    {
      idField: 'id',
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [
    totalThreadList,
    totalThreadListLoading,
    totalThreadListError,
  ] = useCollectionData(FirebaseForums.getThreadList(), {
    idField: 'id',
    snapshotListenOptions: {
      includeMetadataChanges: true,
    },
  });

  const [tabActive, setTabActive] = useState('1');
  const [uploading, setUploading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const onModalClose = () => setShowModal(false);
  const onModalOpen = () => setShowModal(true);

  const [fileInfoList, setFileInfoList] = useState([]);

  const [searchResults, setSearchResults] = useState([]);

  const intlMessages = useFormatMessage();

  const selectTab = key => {
    setTabActive(key);
  };

  const handleAddForum = async values => {
    const {title, description} = values;
    onModalClose();

    if (fileInfoList.length > 0) {
      setUploading(true);
      message.info(intlMessages('files.uploading'));
      await FirebaseForums.onMediaUpload(fileInfoList).then(result => {
        FirebaseForums.addThread(description, title, result);
        message.success(intlMessages('files.uploaded'));
        message.success(intlMessages('forum.created'));
        setUploading(false);
      });
    } else {
      FirebaseForums.addThread(description, title, []);
      message.success(intlMessages('forum.updated'));
      message.success(intlMessages('forum.created'));
    }

    form.resetFields();
  };

  const onCancel = () => {
    onModalClose();
    form.resetFields();
  };

  const handleSearch = async input => {
    const response = await FirebaseSearch.getForumResults(input);
    let sortedResults = sortDeepArray(response.hits);
    setSearchResults(sortedResults);
  };

  if (!totalThreadList || !threadList) {
    return <Spin indicator={spinnerIcon} />;
  } else {
    let userThreadList = sortDeepArray(threadList);
    let allThreads = sortDeepArray(totalThreadList);

    return (
      <div>
        {(threadListError || totalThreadListError) && (
          <strong>Connection error!</strong>
        )}
        {(threadListLoading || totalThreadListLoading) && <p>Loading...</p>}
        {threadList && totalThreadList && (
          <Spin spinning={uploading} delay={500}>
            <div>
              <Auxiliary>
                <Row>
                  <Col
                    xl={24}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                    className="gx-order-lg-1">
                    <Widget
                      title={
                        <h2 className="h4 gx-text-capitalize gx-mb-0 gx-text-left gx-ml-1">
                          {/* <i className="icon icon-social gx-mr-2"></i> */}
                          <IntlMessages id="sidebar.forums" />
                        </h2>
                      }
                      extra={[
                        <p
                          className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block gx-mr-1"
                          onClick={() => onModalOpen()}>
                          <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle gx-mr-1" />
                          <IntlMessages id="forum.add" />
                        </p>,
                        // For Mobile screen
                        <span
                          onClick={() => onModalOpen()}
                          className="gx-text-primary gx-fs-md gx-pointer gx-mb-4 gx-d-block gx-d-sm-none gx-mr-1">
                          <i className="icon icon-add-circle gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle" />
                          <IntlMessages id="forum.add" />
                        </span>,
                      ]}>
                      <Tabs
                        type="card"
                        activeKey={tabActive}
                        onChange={selectTab}>
                        <TabPane tab="All Forums" key="1">
                          {allThreads.length > 0 ? (
                            allThreads.map(thread => {
                              return (
                                <Thread
                                  threadInfo={thread}
                                  readonly={true}
                                  key={thread.id}
                                />
                              );
                            })
                          ) : (
                            <div className="text-center">
                              <h2>
                                There are currently no forums. Let's create one!
                              </h2>
                              <Button
                                variant="raised"
                                className="gx-mt-3"
                                type="primary"
                                onClick={() => setShowModal(true)}>
                                <div className="ant-row-flex">
                                  <IntlMessages id="forum.add" />
                                </div>
                              </Button>
                            </div>
                          )}
                        </TabPane>
                        <TabPane tab="My Forums" key="2">
                          {userThreadList.length > 0 ? (
                            userThreadList.map(thread => {
                              return (
                                <Thread threadInfo={thread} key={thread.id} />
                              );
                            })
                          ) : (
                            <div className="text-center">
                              <h2>
                                You currently have no forums. Let's create one!
                              </h2>
                              <Button
                                variant="raised"
                                className="gx-mt-3"
                                type="primary"
                                onClick={() => setShowModal(true)}>
                                <div className="ant-row-flex">
                                  <IntlMessages id="forum.add" />
                                </div>
                              </Button>
                            </div>
                          )}
                        </TabPane>
                        <TabPane tab="Search Forums" key="3">
                          <Search
                            placeholder="Enter keywords"
                            onSearch={handleSearch}
                            enterButton
                          />
                          {searchResults.length > 0
                            ? searchResults.map(thread => {
                                return (
                                  <Thread
                                    threadInfo={thread}
                                    key={thread.id}
                                    readonly={true}
                                    search
                                  />
                                );
                              })
                            : null}
                          <div className="gx-mt-3 gx-text-right">
                            Search powered by
                            <img
                              className="gx-ml-2"
                              style={{width: 100}}
                              alt=""
                              src={require('assets/images/logo-algolia.png')}
                            />
                          </div>
                        </TabPane>
                      </Tabs>
                    </Widget>
                  </Col>
                </Row>
              </Auxiliary>

              <Modal
                title="New Forum"
                toggle={onModalClose}
                visible={showModal}
                closable={true}
                onOk={() => form.submit()}
                onCancel={onCancel}>
                <Form
                  layout="vertical"
                  {...formItemLayout}
                  form={form}
                  onFinish={handleAddForum}>
                  <Form.Item name="title" label="Title:">
                    <Input placeholder="Title" />
                  </Form.Item>
                  <Form.Item name="description" label="Description:">
                    <TextArea rows={10} placeholder="Forum Content" />
                  </Form.Item>
                  <Form.Item name="files" label="Pictures:">
                    <UploadPicture
                      fList={fileInfoList}
                      onFileChange={setFileInfoList}
                    />
                  </Form.Item>
                </Form>
              </Modal>
            </div>
          </Spin>
        )}
      </div>
    );
  }
};
export default Forum;
