import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import numeral from 'numeral';
import {
  Form,
  Input,
  Radio,
  Breadcrumb,
  Card,
  Button,
  Row,
  Col,
  InputNumber,
  DatePicker,
  Tabs,
  Modal,
  message,
} from 'antd/lib';
import FirebaseFin from 'firebase/firebaseFin';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';
import StarRatingComponent from 'react-star-rating-component';
import IntlMessages from 'util/IntlMessages';
import KitComparisonChart from 'components/app/accelerator/KitComparisonChart';
import KitSavingsWidget from 'components/app/accelerator/KitSavingsWidget';
import {Loan} from 'loanpalz-constants';

const {TabPane} = Tabs;
const {MonthPicker} = DatePicker;
const monthFormat = 'MM/YYYY';
const shortMonthFormat = 'MMM-YYYY';

const KitDetail = ({kit, loan, onDeselect}) => {
  const [kitForm] = Form.useForm();

  // Savings widget
  const [interestSavings, setInterestSavings] = useState(0);
  const [timeSavings, setTimeSavings] = useState(0);
  const [newDate, setNewDate] = useState(moment(new Date(), monthFormat));

  const intlMessages = useFormatMessage();

  // Modal state
  const [showModal, setShowModal] = useState(false);

  // Tabs state
  const [activeTabKey, setActiveTabKey] = useState('1');

  // Loan calculators
  const [loanCalc, setLoanCalc] = useState(null);
  const [newLoanCalc, setNewLoanCalc] = useState(null);

  // Overlays
  const [overlay, setOverlay] = useState({});

  const [form] = Form.useForm();

  const onApplyKitFormSubmit = values => {
    // TODO: Fix this ugly logic
    const ol = {
      type: overlay.type,
      amount: overlay.amount,
      startDate: moment(overlay.startDate, monthFormat).format(monthFormat),
    };

    FirebaseFin.applyAcceleratorToProfile({
      ...ol,
      id,
      name: values.kitNameOverride,
      interestSavings,
      timeSavings,
      newDate: moment(newDate, monthFormat).format(monthFormat),
      enabled: true,
    });
    onModalClose();
    onDeselect();
    message.success(intlMessages('kit.add.success'));
  };

  const {
    id,
    thumb,
    name,
    type,
    onetimesavings,
    totalsavings,
    typeDesc,
    rating,
    description,
  } = kit;

  const formLayout = 'horizontal';
  const inlineFormItemLayout =
    formLayout === 'horizontal'
      ? {
          labelCol: {span: 4},
          wrapperCol: {span: 20},
        }
      : null;

  const submitInlineForm = values => {
    // console.log('Form submit', values);
  };

  const onModalClose = () => setShowModal(false);
  const onModalOpen = () => setShowModal(true);

  const operations = (
    <Button onClick={() => onModalOpen()} type="primary">
      Apply to My Profile
    </Button>
  );

  const onFieldChange = values => {
    let kitValues = kitForm.getFieldsValue(['type', 'startDate', 'amount']);

    // console.log('kit values: ', kitValues);

    const startMonth = moment(new Date(), monthFormat).format(monthFormat);

    // Current loan
    const currentLoan = new Loan(
      loan.interest,
      loan.term,
      loan.amount,
      startMonth,
    );
    setLoanCalc(currentLoan);

    // Overlays
    // TODO: Read these from Firestore
    const ol = {
      type: kitValues.type,
      amount: kitValues.amount,
      startDate: kitValues.startDate,
    };

    setOverlay(ol);

    // const overlayArray = [ol];
    const newLoan = new Loan(
      loan.interest,
      loan.term,
      loan.amount,
      startMonth,
      0,
      [{...ol, enabled: true}],
    );
    setNewLoanCalc(newLoan);

    // Calculate interest savings
    const interestDelta =
      currentLoan.getTotalInterest() - newLoan.getTotalInterest();
    setInterestSavings(interestDelta);

    // Calculate time savings
    const timeDelta = currentLoan.getTotalMonths() - newLoan.getTotalMonths();
    setTimeSavings(timeDelta);

    // Calculate new maturity date
    const newMaturityDate = newLoan.calculateMaturityDate();
    setNewDate(newMaturityDate);
  };

  // Set form defaults
  if (!loanCalc) {
    kitForm.setFieldsValue({
      startDate: moment(new Date(), monthFormat),
      type: type || 'onetimesaving',
      amount: onetimesavings || 100,
    });
    onFieldChange();
  }

  function callback(key) {
    setActiveTabKey(key);
  }

  form.setFieldsValue({
    kitNameOverride: name,
  });

  return (
    <div>
      <Card className="gx-card" title={name}>
        <Breadcrumb className="gx-mb-3">
          <Breadcrumb.Item>
            <Link to="/app/loans">
              <span className="gx-link">Home</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span className="gx-link" onClick={() => onDeselect()}>
              Loan Accelerator
            </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{name}</Breadcrumb.Item>
        </Breadcrumb>

        <Tabs
          activeKey={activeTabKey}
          size="large"
          animated={false}
          onChange={callback}
          tabBarExtraContent={operations}>
          <TabPane tab="Overview" key="1">
            <Row>
              <Col xl={8} md={8} sm={12} xs={24}>
                <div className="gx-product-image">
                  <div className="gx-grid-thumb-equal">
                    <span className="gx-link gx-grid-thumb-cover">
                      <img alt={name} src={thumb} />
                    </span>
                  </div>
                </div>
              </Col>
              <Col xl={16} md={16} sm={12} xs={24}>
                <div className="gx-product-body">
                  <h3 className="gx-product-title">
                    {name}
                    <small className="gx-text-grey">{', ' + typeDesc}</small>
                  </h3>
                  <div className="ant-row-flex">
                    {kit.type === 'recurringsaving' && (
                      <h4 style={{width: '100%'}}>
                        <span className="gx-text-muted gx-mr-2">
                          Monthly savings:
                        </span>
                        {numeral(onetimesavings).format('$0,0')}
                      </h4>
                    )}
                    <h4 style={{width: '100%'}}>
                      <span className="gx-text-muted gx-mr-2">
                        Total savings:
                      </span>
                      {numeral(totalsavings).format('$0,0')}
                    </h4>
                  </div>
                  <div className="ant-row-flex gx-mb-1 gx-mt-2">
                    <h4>
                      <span className="gx-text-muted gx-mr-2">
                        Member rating:
                      </span>
                    </h4>
                    <StarRatingComponent
                      name=""
                      value={rating}
                      starCount={5}
                      editing={false}
                    />
                    <strong className="gx-d-inline-block gx-ml-2">
                      {rating}
                    </strong>
                  </div>
                  <p>{description}</p>
                </div>

                <div className="gx-product-footer">
                  <Button variant="raised" onClick={() => setActiveTabKey('2')}>
                    <IntlMessages id="kits.viewcalc" />
                  </Button>

                  <Button variant="raised" onClick={() => setActiveTabKey('3')}>
                    <IntlMessages id="kits.getstarted" />
                  </Button>
                </div>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Calculator" key="2">
            <div className="gx-product-body">
              <h2 className="gx-mb-3">Financial Calculator</h2>
              <Row>
                <Col xl={17} lg={17} md={24} sm={24} xs={24}>
                  <Form
                    layout={formLayout}
                    form={kitForm}
                    onFinish={submitInlineForm}>
                    <div className="gx-modal-box-row">
                      <div className="gx-modal-box-form-item">
                        <Form.Item
                          label="Type"
                          name="type"
                          {...inlineFormItemLayout}
                          rules={[
                            {
                              required: true,
                            },
                          ]}>
                          <Radio.Group
                            buttonStyle="solid"
                            onChange={onFieldChange}>
                            <Radio.Button value="onetimesaving">
                              One-Time Saving
                            </Radio.Button>
                            <Radio.Button value="recurringsaving">
                              Recurring Saving
                            </Radio.Button>
                            <Radio.Button value="onetimeincome">
                              One-Time Income
                            </Radio.Button>
                            <Radio.Button value="recurringincome">
                              Recurring Income
                            </Radio.Button>
                          </Radio.Group>
                        </Form.Item>

                        <Form.Item
                          name="startDate"
                          label="Start Date"
                          {...inlineFormItemLayout}
                          rules={[
                            {
                              required: true,
                            },
                          ]}>
                          <MonthPicker
                            className="gx-mb-3 gx-w-100"
                            onChange={onFieldChange}
                            defaultValue={moment(new Date(), monthFormat)}
                          />
                        </Form.Item>

                        <Form.Item
                          {...inlineFormItemLayout}
                          name="amount"
                          label="Amount"
                          rules={[
                            {
                              required: true,
                              type: 'integer',
                              message: 'You must enter a valid number',
                            },
                          ]}>
                          <InputNumber
                            onChange={onFieldChange}
                            formatter={value =>
                              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            placeholder="Loan amount"
                            style={{width: '100%'}}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <Button
                      htmlType="submit"
                      style={{display: 'none'}}></Button>
                  </Form>
                </Col>

                <Col xl={7} lg={7} md={24} sm={24} xs={24}>
                  <KitSavingsWidget
                    interestSavings={interestSavings || 0}
                    timeSavings={timeSavings || 0}
                    newDate={
                      moment(newDate, shortMonthFormat) ||
                      moment(new Date(), shortMonthFormat)
                    }
                    iconColor="geekblue"
                  />
                </Col>
              </Row>

              <Row gutter={24}>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  {loanCalc && newLoanCalc && (
                    <KitComparisonChart
                      loanCalc={loanCalc.getAmortization()}
                      newLoanCalc={newLoanCalc.getAmortization()}
                      loan={loan}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </TabPane>
          <TabPane tab="Guide" key="3">
            <Row>
              <Col xl={24} md={24} sm={24} xs={24}>
                <div className="gx-product-body">
                  <h1>Get Started Today</h1>
                  <h3 className="gx-product-title">
                    {name}
                    <small className="gx-text-grey">{', ' + typeDesc}</small>
                  </h3>
                  <div className="ant-row-flex">
                    {kit.type === 'recurringsaving' && (
                      <h4 style={{width: '100%'}}>
                        <span className="gx-text-muted gx-mr-2">
                          Monthly savings:
                        </span>
                        {numeral(onetimesavings).format('$0,0')}
                      </h4>
                    )}
                    <h4 style={{width: '100%'}}>
                      <span className="gx-text-muted gx-mr-2">
                        Total savings:
                      </span>
                      {numeral(totalsavings).format('$0,0')}
                    </h4>
                  </div>
                  <div className="ant-row-flex gx-mb-1 gx-mt-2">
                    <h4>
                      <span className="gx-text-muted gx-mr-2">
                        Member rating:
                      </span>
                    </h4>
                    <StarRatingComponent
                      name=""
                      value={rating}
                      starCount={5}
                      editing={false}
                    />
                    <strong className="gx-d-inline-block gx-ml-2">
                      {rating}
                    </strong>
                  </div>
                  <p>{description}</p>
                </div>

                <div className="gx-product-footer">
                  <Button variant="raised" onClick={() => setActiveTabKey('2')}>
                    <IntlMessages id="kits.viewcalc" />
                  </Button>

                  <Button variant="raised" onClick={() => setActiveTabKey('3')}>
                    <IntlMessages id="kits.getstarted" />
                  </Button>
                </div>
              </Col>
            </Row>
          </TabPane>
        </Tabs>

        <div className="gx-product-item"></div>
      </Card>

      <Modal
        title={intlMessages('kits.applykit') + ': ' + name}
        toggle={onModalClose}
        visible={showModal}
        closable={true}
        onOk={() => form.submit()}
        onCancel={onModalClose}>
        <Form form={form} onFinish={onApplyKitFormSubmit}>
          <div className="gx-modal-box-row">
            <div className="gx-modal-box-form-item">
              <Row>
                <Col span={24}>
                  <div className="gx-form-group">
                    <Form.Item
                      {...inlineFormItemLayout}
                      name="kitNameOverride"
                      label="Customize kit name">
                      <Input />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <Button htmlType="submit" style={{display: 'none'}}></Button>
        </Form>
      </Modal>
    </div>
  );
};

export default KitDetail;
