import app from 'firebase/app';
import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import config from './firebaseConfig';

class FirebaseMessages {
  constructor() {
    if (!firebase.apps.length) {
      app.initializeApp(config);
    }
    this.auth = app.auth();
    this.db = app.firestore();
    this.analytics = app.analytics();
  }

  // Messages functions
  getChatInbox(userId, keyword) {
    let inboxRef = this.db
      .collection('users')
      .doc(userId)
      .collection('conversations');
    if (keyword) {
      return inboxRef
        .where('username', '>=', keyword)
        .where('username', '<=', keyword + '\uf8ff');
    } else {
      return inboxRef.orderBy('lastSentAt', 'desc');
      //.where('conversations.lastMessage', '>', '')
      //.orderBy('conversations.lastSentAt', 'desc');
    }
  }

  getMessageNotifications() {
    return this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('conversations')
      .where('unreadMessage', '>', 0);
  }

  // Messages
  async getMessages(params) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }

    this.clearMessageCount(params.friendId);
    let listMessage = [];
    let messageRef = this.db
      .collection('messages')
      .doc(params.threadId)
      .collection(`${this.auth.currentUser.uid}`);
    //.limit(20);

    await messageRef
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          listMessage.push(doc.data());
        });
      })
      .catch(err => {
        console.log('Error getting message', err);
      });
    return listMessage;
  }

  getLiveMessages(params) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    if (params.threadId && params.friendId) {
      this.clearMessageCount(params.friendId);
      return this.db
        .collection('messages')
        .doc(params.threadId)
        .collection(`${this.auth.currentUser.uid}`)
        .orderBy('timestamp', 'desc')
        .limit(params.limit);
    }
  }

  clearMessageCount(friendId) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('conversations')
      .doc(friendId)
      .update({
        unreadMessage: 0,
      });
  }

  getUserDocumentData(userId) {
    return this.db.collection('users').doc(userId);
  }

  async startConversation(friendId) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let friend = null;
    let friendData = this.getUserDocumentData(friendId);
    await friendData.get().then(doc => {
      friend = {
        ...doc.data(),
        lastMessage: '',
        lastSentAt: '',
        unreadMessage: 0,
        id: friendId,
      };
    });

    // Sender
    this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('conversations')
      .doc(friendId)
      .get()
      .then(snapshot => {
        if (!snapshot.exists) {
          this.db
            .collection('users')
            .doc(`${this.auth.currentUser.uid}`)
            .collection('conversations')
            .doc(friendId)
            .set(friend);
        }
      });

    // Receiver
    let current = null;
    let currentProfile = this.getUserDocumentData(this.auth.currentUser.uid);
    await currentProfile.get().then(doc => {
      current = doc.data();
      current = {
        ...current,
        lastMessage: '',
        lastSentAt: '',
        unreadMessage: 0,
      };
    });

    this.db
      .collection('users')
      .doc(friendId)
      .collection('conversations')
      .doc(`${this.auth.currentUser.uid}`)
      .get()
      .then(snapshot => {
        if (!snapshot.exists) {
          this.db
            .collection('users')
            .doc(friendId)
            .collection('conversations')
            .doc(`${this.auth.currentUser.uid}`)
            .set(current);
        }
      });

    return friend;
  }

  async saveMessage(params) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }

    let batch = this.db.batch();

    let saveSenderMessage = this.db
      .collection('messages')
      .doc(params.threadId)
      .collection(`${this.auth.currentUser.uid}`)
      .doc(params.timestamp);
    batch.set(saveSenderMessage, params.messageObject);

    let saveReceiverMessage = this.db
      .collection('messages')
      .doc(params.threadId)
      .collection(params.messageObject.receiverId)
      .doc(params.timestamp);
    batch.set(saveReceiverMessage, params.messageObject);

    let senderConversations = this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('conversations')
      .doc(params.messageObject.receiverId);
    batch.update(senderConversations, {
      lastMessage: params.messageObject.message,
      lastSentAt: params.messageObject.sentAt,
      unreadMessage: 0,
    });

    let receiverConversations = this.db
      .collection('users')
      .doc(params.messageObject.receiverId)
      .collection('conversations')
      .doc(`${this.auth.currentUser.uid}`);
    batch.update(receiverConversations, {
      lastMessage: params.messageObject.message,
      lastSentAt: params.messageObject.sentAt,
      unreadMessage: firebase.firestore.FieldValue.increment(1),
    });

    batch.commit();
  }

  async clearThreadMessage(params) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }

    let batch = this.db.batch();
    let messageRef = this.db
      .collection('messages')
      .doc(params.threadId)
      .collection(`${this.auth.currentUser.uid}`);

    await messageRef.get().then(snapshot => {
      snapshot.forEach(doc => {
        let toDelete = this.db
          .collection('messages')
          .doc(params.threadId)
          .collection(`${this.auth.currentUser.uid}`)
          .doc(doc.id);
        batch.delete(toDelete);
      });
    });

    let clearConversations = this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('conversations')
      .doc(params.friendId);
    batch.update(clearConversations, {
      lastMessage: '',
      lastSentAt: '',
      unreadMessage: 0,
    });

    batch.commit();
  }
}

export default new FirebaseMessages();
