import React from 'react';
import {Link} from 'react-router-dom';
import {Avatar} from 'antd/lib';

const AvatarListItem = ({item}) => {
  let id = null;
  if (item.id) {
    id = item.id;
  } else {
    id = item.objectID;
  }
  return (
    <Link
      to={{
        pathname: `/app/user/${item.vanityURL}`,
        userId: id,
      }}>
      <Avatar
        className="gx-size-50"
        style={{border: 'solid #ddd 1px'}}
        src={item.avatar}
      />
    </Link>
  );
};

export default AvatarListItem;
