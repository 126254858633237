import React from 'react';
import numeral from 'numeral';
import {Steps as WizardSteps, Popover, Row, Col} from 'antd';
import {FaChevronLeft, FaChevronRight} from 'react-icons/fa';
import {Spring} from 'react-spring/renderprops';
import '../settingsWizard.less';
import {
  calculatePersonalReturn,
  calculateBusinessReturn,
} from './returnCalculator';
// import logo from './assets/images/cares-logo.png'

import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

// const WizardStep = WizardSteps.Step;

// const customDot = (dot, {status, index}) => <span>{dot}</span>;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    flexGrow: 1,
    marginTop: theme.spacing(3),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function getSteps() {
  return [
    'Enter Information',
    // 'Income & Tax Information',
    'Stimulus Actions',
    'Book Appointment',
  ];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return 'Select campaign settings...';
    case 1:
      return 'What is an ad group anyways?';
    case 2:
      return 'This is the bit I really care about!';
    default:
      return 'Unknown stepIndex';
  }
}

const withStepTemplate = Component => componentProps => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Container maxWidth="md">
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
            {/* <Paper className={classes.paper}> */}
            <img
              src={require('assets/images/cares-logo.png')}
              style={{width: '60px'}}
              alt="logo"
            />
            {componentProps.hideButtons && (
              <div
                style={{cursor: 'pointer', marginTop: '10px'}}
                className="ant-row-flex"
                onClick={componentProps.previous}>
                <FaChevronLeft
                  size="12"
                  className="gx-ml-1"
                  style={{margin: 'auto', color: '#038fde'}}
                />
                <span style={{color: '#038fde'}}>Back</span>
              </div>
            )}
            {/* </Paper> */}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Paper className={classes.paper}>
              <span style={{color: '#999'}}>Potential Business Stimulus</span>
              <h1 style={{color: 'green', margin: '2px'}}>
                {numeral(calculateBusinessReturn(componentProps.state)).format(
                  '$0,0',
                )}
              </h1>
              {/* <span style={{color: '#999'}}>in business loans or grants.</span> */}
            </Paper>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Paper className={classes.paper}>
              <span style={{color: '#999', textAlign: 'left'}}>
                Potential Personal Stimulus
              </span>
              <h1 style={{color: 'green', margin: '2px'}}>
                {numeral(calculatePersonalReturn(componentProps.state)).format(
                  '$0,0',
                )}
              </h1>
              {/* <span style={{color: '#999'}}>in stimulus checks.</span> */}
            </Paper>
          </Grid>
        </Grid>
      </div>

      <div className="container-bordered gx-mt-2">
        {/* <div className={classes.root}> */}
        <Stepper
          activeStep={componentProps.currentWizardStep || 0}
          alternativeLabel>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {/* <WizardSteps
            className="wiz-steps"
            current={componentProps.currentWizardStep || 0}
            progressDot={customDot}>
            <WizardStep title="Initial Information" />
            <WizardStep
              title="Income & Tax Info"
              // description="Income & Tax Information"
            />
            <WizardStep
              title="Stimulus Actions"
              // description="You can hover on the dot."
            />
            <WizardStep
              title="Book Appointment"
              // description="You can hover on the dot."
            />
          </WizardSteps> */}
        {/* </div> */}

        <Spring
          config={{tension: 200, friction: 20, precision: 0.1}}
          from={{
            width: '120%',
            opacity: 0,
          }}
          to={{
            width: '100%',
            opacity: 1,
          }}>
          {props => {
            return (
              <Row
                type="flex"
                gutter={10}
                style={{
                  // alignItems: 'center',
                  // border: '1px solid palevioletred',
                  // minHeight: '300px',
                  // padding: '10px 20px',
                  // margin: '10px 20px',
                  marginTop: '40px',
                  marginBottom: '80px',
                  width: props.width,
                  // opacity: props.opacity,
                }}>
                <div className="inner">
                  <Component {...props} {...componentProps} />
                </div>
              </Row>
            );
          }}
        </Spring>

        {/* <div style={{marginTop: '40px', textAlign: 'center'}}>
          <Button type="default" onClick={componentProps.previous}>
            <div className="ant-row-flex">
              <FaChevronLeft size="12" style={{margin: 'auto'}} />
              Back
            </div>
          </Button>
          <Button
            type="primary"
            // onClick={() => {
            //   if (componentProps.state.incomeType === INCOME_TYPES[0].value) {
            //     componentProps.push('business_owner');
            //   } else {
            //     componentProps.push('worker');
            //   }
            // }}

            onClick={componentProps.next}>
            <div className="ant-row-flex">
              Next
              <FaChevronRight size="12" style={{margin: 'auto'}} />
            </div>
          </Button>
        </div>
         */}

        {!componentProps.hideButtons && (
          <div>
            {componentProps.currentWizardStep === steps.length ? (
              <div>
                <Typography className={classes.instructions}>
                  All steps completed
                </Typography>
                <Button onClick={handleReset}>Reset</Button>
              </div>
            ) : (
              <div style={{textAlign: 'center'}}>
                {/* <Typography className={classes.instructions}>
                {getStepContent(componentProps.currentWizardStep)}
              </Typography> */}
                <div className="gx-mb-5">
                  <Button
                    // disabled={componentProps.currentWizardStep === 0}
                    onClick={componentProps.previous}
                    className={classes.backButton}>
                    <FaChevronLeft size="12" style={{marginRight: '2px'}} />
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={componentProps.next}>
                    {componentProps.currentWizardStep === steps.length - 1
                      ? 'Finish'
                      : 'Next'}
                    <FaChevronRight size="12" style={{marginLeft: '2px'}} />
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Container>
  );
};

export default withStepTemplate;
