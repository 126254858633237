import React from 'react';
import {useCollectionData} from 'react-firebase-hooks/firestore';
import {message, Modal} from 'antd/lib';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';
import FirebaseSocial from 'firebase/firebaseSocial';

const FriendRequest = ({userId}) => {
  const intlMessages = useFormatMessage();
  const {confirm} = Modal;

  const [friendOneLook, friendOneLoading, friendOneError] = useCollectionData(
    FirebaseSocial.getUserFriendOne(FirebaseSocial.auth.currentUser.uid),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [friendTwoLook, friendTwoLoading, friendTwoError] = useCollectionData(
    FirebaseSocial.getUserFriendTwo(FirebaseSocial.auth.currentUser.uid),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [
    requestSentLook,
    requestSentLoading,
    requestSentError,
  ] = useCollectionData(
    FirebaseSocial.getUserFriendRequestsSent(
      FirebaseSocial.auth.currentUser.uid,
    ),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [
    requestRecLook,
    requestRecLoading,
    requestRecError,
  ] = useCollectionData(
    FirebaseSocial.getUserFriendRequestsReceived(
      FirebaseSocial.auth.currentUser.uid,
    ),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const friendRequest = id => {
    confirm({
      title: 'Send friend request?',
      onOk: () => {
        FirebaseSocial.friendRequestUser(id);
        message.success(intlMessages('social.friend.success'));
      },
      onCancel() {},
    });
  };

  const unfriend = id => {
    confirm({
      title: 'Unfriend?',
      onOk: () => {
        FirebaseSocial.unfriend(id);
        message.success(intlMessages('social.unfriend.success'));
      },
      onCancel() {},
    });
  };

  const friend = id => {
    confirm({
      title: 'Accept friend request?',
      onOk: () => {
        FirebaseSocial.friend(id);
        message.success(intlMessages('social.friend.success'));
      },
      onCancel() {},
    });
  };

  let isFriendOne = null;
  if (friendOneLook) {
    let i = 0;
    for (i; i < friendOneLook.length; i++) {
      if (friendOneLook[i].friendOne.id.toString() === userId.toString()) {
        isFriendOne = true;
      }
    }
  }

  let isFriendTwo = null;
  if (friendTwoLook) {
    let i = 0;
    for (i; i < friendTwoLook.length; i++) {
      if (friendTwoLook[i].friendTwo.id.toString() === userId.toString()) {
        isFriendTwo = true;
      }
    }
  }

  let haveRequested = null;
  if (requestSentLook) {
    requestSentLook.forEach(requestPair => {
      if (requestPair.receiving.id === userId) {
        haveRequested = true;
      }
    });
  }

  let hasRequested = null;
  if (requestRecLook) {
    requestRecLook.forEach(requestPair => {
      if (requestPair.sending.id === userId) {
        hasRequested = true;
      }
    });
  }

  let friendIcon = <i className="icon icon-signup gx-fs-xxl gx-text-grey" />;
  return (
    <div>
      {(friendOneError ||
        friendTwoError ||
        requestSentError ||
        requestRecError) && <strong>Connection error!</strong>}
      {(friendOneLoading ||
        friendTwoLoading ||
        requestSentLoading ||
        requestRecLoading) && <p>Loading...</p>}
      {friendOneLook && friendTwoLook && requestSentLook && requestRecLook && (
        <span className="gx-flex-row">
          <div className="gx-mr-2">
            {isFriendOne || isFriendTwo ? (
              <a href onClick={() => unfriend(userId)}>
                {friendIcon}
              </a>
            ) : hasRequested ? (
              <a href onClick={() => friend(userId)}>
                {friendIcon}
              </a>
            ) : (
              <a href onClick={() => friendRequest(userId)}>
                {friendIcon}
              </a>
            )}
          </div>
          <div className="gx-media-body">
            <span className="gx-mb-0 gx-text-grey gx-fs-md">
              {isFriendOne || isFriendTwo ? (
                <span>
                  <a href onClick={() => unfriend(userId)}>
                    Unfriend
                  </a>
                </span>
              ) : haveRequested ? (
                <span>Friend request sent!</span>
              ) : hasRequested ? (
                <a href onClick={() => friend(userId)}>
                  Accept friend request!
                </a>
              ) : (
                <a href onClick={() => friendRequest(userId)}>
                  Send Friend Request
                </a>
              )}
            </span>
          </div>
        </span>
      )}
    </div>
  );
};

export default FriendRequest;
