import React, { PureComponent } from 'react';
import SettingsWizard from '../settings/SettingsWizard';
import './onboarding.less';
class Onboarding extends PureComponent {
  render() {
    return (
      <SettingsWizard />
    );
  }
}

export default Onboarding;
