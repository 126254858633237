import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import FirebaseSearch from 'firebase/firebaseSearch';
import {FaSpinner} from 'react-icons/fa';
import {Spin} from 'antd/lib';
import {OptimizelyFeature} from '@optimizely/react-sdk';
import SearchDisplayList from '../displayList/SearchDisplayList';

const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;

const Search = () => {
  const [searchResults, setSearchResults] = useState(null);
  const query = useQuery();
  const q = query.get('query');

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  useEffect(() => {
    async function getSearchResults(q) {
      const response = await FirebaseSearch.getSearchResults(q);
      FirebaseSearch.analytics.logEvent('search_users', {query: q});
      //Filter Disabled users
      let selectedTasks = response.hits.filter(
        users => users.disabled !== true,
      );
      setSearchResults(selectedTasks);
    }
    getSearchResults(q);
  }, [q]);

  if (!searchResults) {
    return <Spin indicator={spinnerIcon} />;
  } else {
    return (
      <OptimizelyFeature feature="search_users">
        {isEnabled =>
          isEnabled ? (
            <div>
              <SearchDisplayList query={q} results={searchResults} />
              <div className="gx-mt-3 gx-text-right">
                Search powered by
                <img
                  className="gx-ml-2"
                  style={{width: 100}}
                  alt=""
                  src={require('assets/images/logo-algolia.png')}
                />
              </div>
            </div>
          ) : (
            <div className="gx-feature-disabled">
              Feature Flag Disabled by Optimizely
            </div>
          )
        }
      </OptimizelyFeature>
    );
  }
};

export default Search;
