import React from 'react';
import {
  useDocumentData,
  useCollectionData,
} from 'react-firebase-hooks/firestore';
import FirebaseSocial from 'firebase/firebaseSocial';
import {FaSpinner} from 'react-icons/fa';
import Widget from 'components/Widget/index';
import {Link} from 'react-router-dom';
import {Avatar, Spin} from 'antd';

const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;

const Social = ({
  userId,
  readonly,
  followerView,
  followingView,
  friendView,
}) => {
  if (!userId) {
    userId = FirebaseSocial.auth.currentUser.uid;
  }

  const [user, userLoading, userError] = useDocumentData(
    FirebaseSocial.getUserDocumentData(userId),
    {
      snapshotListenOptions: {includeMetadataChanges: true},
    },
  );

  const [followerLook, followerLoading, followerError] = useCollectionData(
    FirebaseSocial.getUserFollowers(userId),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [followingLook, followingLoading, followingError] = useCollectionData(
    FirebaseSocial.getUserFollowing(userId),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [friendOneLook, friendOneLoading, friendOneError] = useCollectionData(
    FirebaseSocial.getUserFriendOne(userId),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [friendTwoLook, friendTwoLoading, friendTwoError] = useCollectionData(
    FirebaseSocial.getUserFriendTwo(userId),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [
    currentOneLook,
    currentOneLoading,
    currentOneError,
  ] = useCollectionData(
    FirebaseSocial.getUserFriendOne(FirebaseSocial.auth.currentUser.uid),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [
    currentTwoLook,
    currentTwoLoading,
    currentTwoError,
  ] = useCollectionData(
    FirebaseSocial.getUserFriendTwo(FirebaseSocial.auth.currentUser.uid),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  if (!user) {
    return <Spin indicator={spinnerIcon} />;
  } else {
    let mutual = '';
    let userFriends = [];
    let currentFriends = [];
    if (
      readonly &&
      friendView &&
      friendOneLook &&
      currentOneLook &&
      friendTwoLook &&
      currentTwoLook
    ) {
      let mutualCount = 0;

      friendOneLook.forEach(friendPair => {
        userFriends.push(friendPair.friendOne);
      });
      friendTwoLook.forEach(friendPair => {
        userFriends.push(friendPair.friendTwo);
      });

      currentOneLook.forEach(currentPair => {
        currentFriends.push(currentPair.friendOne);
      });
      currentTwoLook.forEach(currentPair => {
        currentFriends.push(currentPair.friendTwo);
      });

      userFriends.forEach(friend => {
        currentFriends.forEach(currentFriend => {
          if (currentFriend.id === friend.id) {
            mutualCount++;
          }
        });
      });

      mutual = 'Mutual Friends - ' + mutualCount;
    }

    let title = null;
    let display = null;
    let extra = null;
    if (followerView && followerLook) {
      title = <span>Followers - {followerLook.length}</span>;
      let followerArray = [];
      followerLook.forEach(followPair => {
        followerArray.push(followPair.follower);
      });
      display = followerArray;
      extra = (
        <Link
          to={{
            pathname: `/app/user/${user.vanityURL}/followers`,
            userId: user.id,
          }}>
          See More
        </Link>
      );
    } else if (followingView && followingLook) {
      title = <span>Following - {followingLook.length}</span>;
      let followingArray = [];
      followingLook.forEach(followPair => {
        followingArray.push(followPair.following);
      });
      display = followingArray;
      extra = (
        <Link
          to={{
            pathname: `/app/user/${user.vanityURL}/following`,
            userId: user.id,
          }}>
          See More
        </Link>
      );
    } else if (friendView && friendOneLook && friendTwoLook) {
      title = (
        <span>
          Friends - {friendOneLook.length + friendTwoLook.length}{' '}
          <span className="gx-text-grey">{mutual}</span>
        </span>
      );
      let friendArray = [];
      friendOneLook.forEach(friendPair => {
        friendArray.push(friendPair.friendOne);
      });
      friendTwoLook.forEach(friendPair => {
        friendArray.push(friendPair.friendTwo);
      });
      display = friendArray;
      extra = (
        <Link
          to={{
            pathname: `/app/user/${user.vanityURL}/friends`,
            userId: user.id,
          }}>
          See More
        </Link>
      );
    }

    return (
      <div>
        {(followerError ||
          followingError ||
          friendOneError ||
          currentOneError ||
          friendTwoError ||
          currentTwoError ||
          userError) && <strong>Connection error!</strong>}
        {(followerLoading ||
          followingLoading ||
          friendOneLoading ||
          currentOneLoading ||
          friendTwoLoading ||
          currentTwoLoading ||
          userLoading) && <p>Loading...</p>}
        {followerLook &&
          followingLook &&
          friendOneLook &&
          currentOneLook &&
          friendTwoLook &&
          currentTwoLook &&
          user && (
            <Widget styleName="gx-card-profile-sm" title={title} extra={extra}>
              <div className="gx-pt-2">
                <ul className="gx-fnd-list gx-mb-0">
                  {display.map((userView, index) => (
                    <li className="gx-mb-2" key={index}>
                      <Link
                        to={{
                          pathname: `/app/user/${userView.vanityURL}`,
                          userId: userView.id,
                        }}>
                        <div className="gx-user-fnd">
                          <Avatar
                            shape="square"
                            size={110}
                            src={userView.avatar}
                          />
                          <div className="gx-user-fnd-content">
                            <h6>{userView.username}</h6>
                          </div>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </Widget>
          )}
      </div>
    );
  }
};

export default Social;
