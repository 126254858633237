import React from 'react';
import {Row, Col} from 'antd';
import {FaCaretUp, FaCaretDown} from 'react-icons/fa';
import FirebaseForums from 'firebase/firebaseForums';

const VoteDisplay = ({threadInfo, comment, forum}) => {
  let upvoted,
    downvoted = null;

  if (forum) {
    threadInfo.upvotes.forEach(voter => {
      if (voter === FirebaseForums.auth.currentUser.uid) {
        upvoted = true;
      }
    });
    threadInfo.downvotes.forEach(voter => {
      if (voter === FirebaseForums.auth.currentUser.uid) {
        downvoted = true;
      }
    });
  } else {
    comment.upvotes.forEach(voter => {
      if (voter === FirebaseForums.auth.currentUser.uid) {
        upvoted = true;
      }
    });
    comment.downvotes.forEach(voter => {
      if (voter === FirebaseForums.auth.currentUser.uid) {
        downvoted = true;
      }
    });
  }

  const handleUpvote = () => {
    FirebaseForums.removeVote(
      threadInfo.id,
      FirebaseForums.auth.currentUser.uid,
    );
    if (!upvoted) {
      FirebaseForums.upvoteThread(threadInfo.id);
      upvoted = true;
      downvoted = false;
    } else {
      downvoted = false;
      upvoted = false;
    }
  };

  const handleDownvote = () => {
    FirebaseForums.removeVote(
      threadInfo.id,
      FirebaseForums.auth.currentUser.uid,
    );
    if (!downvoted) {
      FirebaseForums.downvoteThread(threadInfo.id);
      downvoted = true;
      upvoted = false;
    } else {
      downvoted = false;
      upvoted = false;
    }
  };

  const handleCommentUpvote = async () => {
    await FirebaseForums.removeCommentVote(
      threadInfo.id,
      comment.id,
      FirebaseForums.auth.currentUser.uid,
    );
    if (!upvoted) {
      FirebaseForums.upvoteComment(threadInfo.id, comment.id);
      upvoted = true;
      downvoted = false;
    } else {
      downvoted = false;
      upvoted = false;
    }
  };

  const handleCommentDownvote = async () => {
    await FirebaseForums.removeCommentVote(
      threadInfo.id,
      comment.id,
      FirebaseForums.auth.currentUser.uid,
    );
    if (!downvoted) {
      FirebaseForums.downvoteComment(threadInfo.id, comment.id);
      downvoted = true;
      upvoted = false;
    } else {
      downvoted = false;
      upvoted = false;
    }
  };

  if (forum) {
    return (
      <Col span={1}>
        <Row justify="center">
          {upvoted ? (
            <FaCaretUp
              onClick={handleUpvote}
              size="20"
              style={{cursor: 'pointer'}}
              color="#038fde"
            />
          ) : (
            <FaCaretUp
              onClick={handleUpvote}
              size="20"
              style={{cursor: 'pointer'}}
            />
          )}
        </Row>
        <Row justify="center">
          {threadInfo.upvotes.length - threadInfo.downvotes.length}
        </Row>
        <Row justify="center">
          {downvoted ? (
            <FaCaretDown
              onClick={handleDownvote}
              size="20"
              style={{cursor: 'pointer'}}
              color="#038fde"
            />
          ) : (
            <FaCaretDown
              onClick={handleDownvote}
              size="20"
              style={{cursor: 'pointer'}}
            />
          )}
        </Row>
      </Col>
    );
  } else {
    return (
      <Col span={1}>
        <Row justify="center">
          {upvoted ? (
            <FaCaretUp
              onClick={handleCommentUpvote}
              size="20"
              style={{cursor: 'pointer'}}
              color="#038fde"
            />
          ) : (
            <FaCaretUp
              onClick={handleCommentUpvote}
              size="20"
              style={{cursor: 'pointer'}}
            />
          )}
        </Row>
        <Row justify="center">
          {comment.upvotes.length - comment.downvotes.length}
        </Row>
        <Row justify="center">
          {downvoted ? (
            <FaCaretDown
              onClick={handleCommentDownvote}
              size="20"
              style={{cursor: 'pointer'}}
              color="#038fde"
            />
          ) : (
            <FaCaretDown
              onClick={handleCommentDownvote}
              size="20"
              style={{cursor: 'pointer'}}
            />
          )}
        </Row>
      </Col>
    );
  }
};

export default VoteDisplay;
