import app from 'firebase/app';
import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import config from './firebaseConfig';

class FirebaseTasks {
  constructor() {
    if (!firebase.apps.length) {
      app.initializeApp(config);
    }
    this.auth = app.auth();
    this.db = app.firestore();
    this.analytics = app.analytics();
  }

  // Tasks functions
  addTask(values) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    // update Tags
    if (values.tags && values.tags.length > 0) {
      this.addTaskTags(values.tags);
    }

    return this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('tasks')
      .add(values);
  }

  addTaskTags(tags) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let uniqueTags = [];
    tags.forEach(tag => {
      this.db
        .collection('users')
        .doc(`${this.auth.currentUser.uid}`)
        .collection('tags')
        .where('tag.title', '==', tag.title)
        .get()
        .then(snapshot => {
          if (snapshot.empty) {
            this.db
              .collection('users')
              .doc(`${this.auth.currentUser.uid}`)
              .collection('tags')
              .add({tag: tag});
            uniqueTags.push(tag);
          } else {
            snapshot.forEach(doc => {
              uniqueTags.push(doc.data().tag);
            });
          }
        })
        .catch(err => {
          console.log('Error getting documents', err);
        });
    });
  }

  updateTask(values) {
    const {id, title, date, description, tags} = values;
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    // update Tags
    this.addTaskTags(tags);

    return this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('tasks')
      .doc(id)
      .update({title, date, description, tags});
  }

  updateTaskStarred(values) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }

    return this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('tasks')
      .doc(values.id)
      .update({
        starred: values.starred,
      });
  }

  updateTaskImportant(values) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }

    return this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('tasks')
      .doc(values.id)
      .update({
        important: values.important,
      });
  }

  // Single task
  updateTaskComplete(values) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }

    return this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('tasks')
      .doc(values.id)
      .update({
        completed: values.completed,
      });
  }

  // Multiple Tasks
  updateTasksCompletedStatus(taskIds, status) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let batch = this.db.batch();
    if (taskIds && taskIds.length > 0) {
      taskIds.forEach(id => {
        let task = this.db
          .collection('users')
          .doc(`${this.auth.currentUser.uid}`)
          .collection('tasks')
          .doc(id);
        batch.update(task, {completed: status});
      });
      return batch.commit().then(function() {
        console.log('Batched.');
      });
    }
  }

  updateTaskLabel(values) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }

    return this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('tasks')
      .doc(values.id)
      .update({
        tags: values.tags,
      });
  }

  // Get a single task
  async getTask(id) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let result = null;
    await this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('tasks')
      .doc(id)
      .get()
      .then(doc => (result = {...doc.data(), id: doc.id}));
    return result;
  }

  async getTasks() {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }

    let tasks = [];

    let tasksRef = this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('tasks');

    await tasksRef
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          let dataObj = {
            ...doc.data(),
            id: doc.id,
          };
          tasks.push(dataObj);
        });
      })
      .catch(err => {
        console.log('Error getting tasks', err);
      });
    return tasks;
  }

  async deleteTasks(taskIds) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }

    let batch = this.db.batch();
    if (taskIds && taskIds.length > 0) {
      taskIds.forEach(id => {
        let tasksRef = this.db
          .collection('users')
          .doc(`${this.auth.currentUser.uid}`)
          .collection('tasks')
          .doc(id);
        batch.delete(tasksRef);
      });
      batch.commit();
    }
  }

  async getTaskTags() {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let tags = [];

    let tagRef = this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('tags');

    await tagRef
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          let dataObj = {
            ...doc.data(),
            id: doc.id,
          };
          tags.push(dataObj);
        });
      })
      .catch(err => {
        console.log('Error getting tasks', err);
      });
    return tags;
  }
}

export default new FirebaseTasks();
