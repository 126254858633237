import React from 'react';
import numeral from 'numeral';
import { ResponsivePie } from '@nivo/pie';

const KitInterestDistributionPieChart = ({ data }) => {
  return (
    <ResponsivePie
      data={data}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      fit={true}
      margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
      height={400}
      colors={{ scheme: 'spectral' }}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      radialLabelsSkipAngle={10}
      radialLabelsTextXOffset={6}
      radialLabelsTextColor="#333333"
      radialLabelsLinkOffset={0}
      radialLabelsLinkDiagonalLength={16}
      radialLabelsLinkHorizontalLength={24}
      radialLabelsLinkStrokeWidth={1}
      radialLabelsLinkColor={{ from: 'color' }}
      enableSlicesLabels={true}
      sliceLabel={function(e) {
        return numeral(e.value).format('$0,0');
      }}
      slicesLabelsSkipAngle={10}
      slicesLabelsTextColor="#333333"
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      legends={[
        {
          anchor: 'bottom',
          direction: 'row',
          translateY: 56,
          itemWidth: 150,
          itemHeight: 18,
          itemTextColor: '#999',
          symbolSize: 18,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000'
              }
            }
          ]
        }
      ]}
    />
  );
};

export default KitInterestDistributionPieChart;
