import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {
  onNavStyleChange,
  toggleCollapsedSideNav,
} from 'appRedux/actions/Setting';
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_DARK,
  THEME_TYPE_SEMI_DARK,
} from '../../constants/ThemeSetting';

class SidebarLogo extends Component {
  render() {
    const {width, themeType, navCollapsed} = this.props;
    let {navStyle} = this.props;
    if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
      navStyle = NAV_STYLE_DRAWER;
    }
    return (
      <div className="gx-layout-sider-header">
        {navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR ? (
          <div className="gx-linebar">
            <i
              className={`gx-icon-btn icon icon-${
                navStyle === NAV_STYLE_MINI_SIDEBAR
                  ? 'menu-unfold'
                  : 'menu-fold'
              } ${themeType === THEME_TYPE_DARK ? 'gx-text-white' : ''}`}
              onClick={() => {
                if (navStyle === NAV_STYLE_DRAWER) {
                  this.props.toggleCollapsedSideNav(!navCollapsed);
                } else if (navStyle === NAV_STYLE_FIXED) {
                  this.props.onNavStyleChange(NAV_STYLE_MINI_SIDEBAR);
                } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
                  this.props.toggleCollapsedSideNav(!navCollapsed);
                } else {
                  this.props.onNavStyleChange(NAV_STYLE_FIXED);
                }
              }}
            />
          </div>
        ) : null}

        <Link to="/app/dashboard" className="gx-site-logo">
          {(navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
            navStyle === NAV_STYLE_FIXED) &&
          width >= TAB_SIZE ? (
            <img
              alt="Loanpalz"
              src={require('assets/images/loanpalz-logo.png')}
            />
          ) : themeType === THEME_TYPE_DARK ||
            themeType === THEME_TYPE_SEMI_DARK ? (
            <img
              alt="Loanpalz"
              src={require('assets/images/loanpalz-logo-white.png')}
            />
          ) : (
            <img
              alt="Loanpalz"
              src={require('assets/images/loanpalz-logo.png')}
            />
          )}
        </Link>
      </div>
    );
  }
}

const mapStateToProps = ({settings}) => {
  const {navStyle, themeType, width, navCollapsed} = settings;
  return {navStyle, themeType, width, navCollapsed};
};

export default connect(
  mapStateToProps,
  {
    onNavStyleChange,
    toggleCollapsedSideNav,
  },
)(SidebarLogo);
