import React from 'react';
import Widget from 'components/Widget';
import {Tabs} from 'antd/lib';
import Services from './services/Services';
import Availability from './availability/Availability';
import CoachingAppointments from 'routes/app/coachingAppointments';

const CoachTabs = ({readonly, profile}) => {
  const {TabPane} = Tabs;
  return (
    <Widget
      title="Coach"
      styleName="gx-card-tabs gx-card-tabs-left gx-card-profile">
      <Tabs defaultActiveKey="1" style={{marginTop: '1px'}}>
        <TabPane tab="Services" key="1">
          <Services readonly={readonly} profile={profile} />
        </TabPane>
        <TabPane tab="Availability" key="2">
          <Availability readonly={readonly} profile={profile} />
        </TabPane>
        <TabPane tab="Coaching Booking" key="3">
          <CoachingAppointments
            readonly={readonly}
            profile={profile}
            coach={true}
          />
        </TabPane>
      </Tabs>
    </Widget>
  );
};
export default CoachTabs;
