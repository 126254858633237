import React from 'react';
import {useDocumentData} from 'react-firebase-hooks/firestore';
import {Card, List, Spin} from 'antd/lib';
import Follow from 'components/app/contact/Follow';
import MessageBubble from 'components/app/contact/MessageBubble';
import FriendRequest from 'components/app/contact/FriendRequest';
import FirebaseSocial from 'firebase/firebaseSocial';
import {FaSpinner} from 'react-icons/fa';
import AvatarListItem from './AvatarListItem';
import BodyListItem from './BodyListItem';

const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;
const SocialDisplayList = ({display, userId}) => {
  if (!userId) {
    userId = FirebaseSocial.auth.currentUser.uid;
  }
  const [user, userLoading, userError] = useDocumentData(
    FirebaseSocial.getUserDocumentData(userId),
    {
      idField: 'id',
      snapshotListenOptions: {includeMetadataChanges: true},
    },
  );

  if (!user) {
    return <Spin indicator={spinnerIcon} />;
  } else {
    return (
      <div>
        {userError && <strong>Connection error!</strong>}
        {userLoading && <p>Loading...</p>}
        {user && (
          <div className="gx-applications-list">
            <Card className="gx-card">
              <List
                itemLayout="horizontal"
                dataSource={display}
                renderItem={item => (
                  <List.Item
                    actions={
                      item.id === FirebaseSocial.auth.currentUser.uid
                        ? null
                        : [
                            <Follow userId={item.id} />,
                            <FriendRequest userId={item.id} />,
                            <MessageBubble userId={item.id} />,
                          ]
                    }>
                    <List.Item.Meta
                      avatar={<AvatarListItem item={item} />}
                      title={<BodyListItem item={item} />}
                    />
                  </List.Item>
                )}
              />
            </Card>
          </div>
        )}
      </div>
    );
  }
};

export default SocialDisplayList;
