import React, { useState, useEffect, useCallback } from 'react';
import { Tabs, Row, Col, Slider, InputNumber, Select } from 'antd';
import { LoanChart } from '../LoanBalanceChart';
import LoansChartCard from 'components/app/widgets/LoansChartCard';
import AmortizationTable from '../AmortizationTable';
import moment from 'moment';
import {
  LoanPortfolio,
  Overlay,
  OVERLAY_RECURRINGSAVING,
  METHOD_AVALANCHE,
  METHOD_SNOWBALL
} from 'loanpalz-constants';
import numeral from 'numeral';
import { debounce } from 'lodash';
import Widget from 'components/Widget/index';
import { schemeTableau10 } from 'd3-scale-chromatic';
import { LoanStep } from '../../../../routes/app/settings/components';
import './style.css';

const { TabPane } = Tabs;
const dayFormat = 'D-MMM-YYYY';
const colors = schemeTableau10;
const Option = Select.Option;
const todayDate = moment(new Date(), dayFormat).format(dayFormat);

const LoanStatistics = ({ loans }) => {
  const [loanPortfolio, setLoanPortfolio] = useState();
  const [loanFreq, setLoanFreq] = useState('monthly');
  const [payOffDate, setPayOffDate] = useState('');
  const [porfolioBalance, setPorfolioBalance] = useState(0);
  const [payOffDateYear, setPayOffDateYear] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [averageInterest, setAverageInterest] = useState(0);
  const [overlaySlider, setOverlaySlider] = useState(0);
  const [processedLoans, setProcessedLoans] = useState(null);
  const [overlays, setOverlays] = useState([]);
  const [graphType, setGraphType] = useState(METHOD_AVALANCHE);
  const [selectedChart, setSelectedChart] = useState(null);
  const [chartColors, setChartColors] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);

  const handleChange = value => {
    setLoanFreq(value);
  };

  const handleGraphType = value => {
    setGraphType(value);
  }

  useEffect(() => {
    let loanPort = new LoanPortfolio(graphType);
    loanPort.setLoans(loans);
    loanPort.setOverlays(overlays);
    loanPort.calculateMonthlyPaymentWithOverlays();
    setLoanPortfolio(loanPort);
    setProcessedLoans(loanPort.processedLoans);
    setPayOffDate(loanPort.calculatePayoffDate());
    setPayOffDateYear(loanPort.calculatePayoffTime());
    setTotalInterest(loanPort.calculateTotalInterest());
    setAverageInterest(loanPort.calculateWeightedAverageInterest());
    setPorfolioBalance(loanPort.calculatePortfolioCurrentBalance());
    let _colors = {};
    loans.map((loan, i) => {
      return _colors[loan.id] = colors[i];
    });
    setChartColors(_colors);
    setDataLoaded(true);

  }, [loans, overlays, graphType]);

  const handler = useCallback(
    debounce(value => {
      let overlayArray = [];
      overlayArray.push(
        new Overlay(
          value,
          OVERLAY_RECURRINGSAVING,
          todayDate,
          true,
        ),
      );
      setOverlays([...overlayArray]);
    }, 1000),
    [],
  );

  const sliderOnChange = value => {
    setOverlaySlider(value);
    handler(value);
  };

  const selectChartSection = (v) => {
    setSelectedChart(v);
  }

  const renderTooltipContent = (o) => {
    const { payload, label } = o;
    const total = payload.reduce((result, entry) => (result + entry.value), 0);
    return (
      <div className="customized-tooltip-content" >
        <p className="datestyle">{`${label}`}</p>
        <ul className="list">
          {
            payload.map((entry, index) => (
              <li key={`item-${index}`} style={{ color: entry.color }}>
                <span className="li-span">
                  {
                    selectedChart === "percenttowordprincipal" ?
                      `${entry.name}: ${Math.round(entry.value)}%`
                      :
                      `${entry.name}: ${numeral(entry.value).format('$0,0')}`
                  }
                </span>
              </li>
            ))
          }
        </ul>
        {
          selectedChart === "percenttowordprincipal" ?
            null :
            <p className="datestyle">Total : {`${numeral(total).format('$0,0')}`}</p>
        }
      </div>
    );
  };

  return (
    <div>
      {dataLoaded && loans && processedLoans && loanPortfolio && (
        <div>
          <Row gutter={24}>
            <Col xl={6} lg={12} md={12} sm={12} xs={24}>
              <LoansChartCard
                chartProperties={{
                  title: 'PRINCIPAL PAID',
                  prize: porfolioBalance,
                  icon: 'stats',
                  bgColor: colors[0],
                  styleName: 'down',
                  desc: 'Total principal paid across all loans',
                  // percent: '0',
                  spring: true,
                }}
              />
            </Col>
            <Col xl={6} lg={12} md={12} sm={12} xs={24}>
              <LoansChartCard
                chartProperties={{
                  title: 'PAYOFF DATE',
                  prize: payOffDate,
                  icon: 'stats',
                  bgColor: colors[4],
                  styleName: '',
                  desc: todayDate === payOffDate ? 'Yay, you\'re debt- free!' : 'Debt-free in ' + payOffDateYear,
                  percent: '',
                }}
              />
            </Col>
            <Col xl={6} lg={12} md={12} sm={12} xs={24}>
              <LoansChartCard
                chartProperties={{
                  title: 'INTEREST PAID',
                  prize: totalInterest,
                  icon: 'stats',
                  bgColor: colors[2],
                  styleName: '',
                  desc: 'Total interest paid accross all loans',
                  percent: '',
                  spring: true,

                }}
              />
            </Col>
            <Col xl={6} lg={12} md={12} sm={12} xs={24}>
              <LoansChartCard
                chartProperties={{
                  title: 'AVERAGE INTEREST RATE',
                  prize: numeral(averageInterest / 100).format('0.00 %'),
                  icon: 'stats',
                  bgColor: colors[6],
                  styleName: '',
                  desc: 'Average interest rate across all loans',
                  percent: '',
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24} >
              <Widget>
                <Row>
                  <Col span={9}>
                    <div className="ant-row-flex gx-px-4 ">
                      {/* <h2 className="h4 gx-mb-3">Loan Balance Chart</h2> */}
                      <div className="gx-ml-auto">
                        <Select
                          className="gx-mb-2 gx-select-sm"
                          defaultValue={METHOD_AVALANCHE}
                          onChange={handleGraphType}
                          style={{ width: '200px' }}
                        >
                          <Option value={METHOD_AVALANCHE}>Highest Interest Rate</Option>
                          <Option value={METHOD_SNOWBALL}>Lowest Principal Amount</Option>
                        </Select>

                        <Select
                          className="gx-mb-2 gx-select-sm gx-ml-2"
                          defaultValue="monthly"
                          onChange={handleChange}
                          style={{ width: '100px' }}>
                          <Option value="monthly">Monthly</Option>
                          <Option value="quarterly">Quarterly</Option>
                          <Option value="yearly">Yearly</Option>
                        </Select>
                      </div>
                    </div>
                  </Col>
                  <Col span={5}>
                    <h4 className="gx-pt-2">Extra monthly principal:</h4>
                  </Col>
                  <Col span={6}>
                    <Slider
                      min={0}
                      max={10000}
                      onChange={sliderOnChange}
                      tipFormatter={value => numeral(value).format('$0,0')}
                      value={typeof overlaySlider === 'number' ? overlaySlider : 0}
                    />
                  </Col>
                  <Col span={4}>
                    <InputNumber
                      min={0}
                      max={10000}
                      formatter={value =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      style={{ marginLeft: 16, width: 120 }}
                      value={overlaySlider}
                      onChange={sliderOnChange}
                    />
                  </Col>
                </Row>
              </Widget>
            </Col>
          </Row>

          <Tabs size="large">
            <TabPane tab="Chart" key="chart">
              <Tabs onChange={selectChartSection} >
                <TabPane tab="Principal Remaining" key={'principal'}>
                  <LoanChart
                    loans={processedLoans}
                    loanPortfolio={loanPortfolio}
                    startingBalance={porfolioBalance}
                    handleChange={handleChange}
                    handleGraphType={handleGraphType}
                    loanFreq={loanFreq}
                    renderTooltipContent={renderTooltipContent}
                    graphBy={'principal'}
                    chartColors={chartColors}
                  />
                </TabPane>
                <TabPane tab="Interest Paid" key={'interest'}>
                  <LoanChart
                    loans={processedLoans}
                    loanPortfolio={loanPortfolio}
                    startingBalance={totalInterest}
                    handleChange={handleChange}
                    handleGraphType={handleGraphType}
                    loanFreq={loanFreq}
                    renderTooltipContent={renderTooltipContent}
                    graphBy={'interest'}
                    chartColors={chartColors}
                  />
                </TabPane>
                <TabPane tab="Monthly Payment" key={'monthlypayment'}>
                  <LoanChart
                    loans={processedLoans}
                    loanPortfolio={loanPortfolio}
                    startingBalance={porfolioBalance}
                    handleChange={handleChange}
                    handleGraphType={handleGraphType}
                    loanFreq={loanFreq}
                    renderTooltipContent={renderTooltipContent}
                    graphBy={'monthlypayment'}
                    chartColors={chartColors}
                  />
                </TabPane>
                <TabPane tab="Percent Toward Principal" key={'percenttowordprincipal'}>
                  <LoanChart
                    loans={processedLoans}
                    loanPortfolio={loanPortfolio}
                    startingBalance={porfolioBalance}
                    handleChange={handleChange}
                    handleGraphType={handleGraphType}
                    loanFreq={loanFreq}
                    renderTooltipContent={renderTooltipContent}
                    graphBy={'percenttowordprincipal'}
                    chartColors={chartColors}
                  />
                </TabPane>

              </Tabs>
            </TabPane>

            <TabPane tab="Amortization Tables" key="table">
              <Tabs >
                {processedLoans.map((loan, index) => {
                  return (
                    <TabPane tab={loan.name} key={index}>
                      <AmortizationTable loan={loan} />
                    </TabPane>
                  );
                })}
              </Tabs>
            </TabPane>
            <TabPane tab="Loans" key="loans">
              <LoanStep />
            </TabPane>
          </Tabs>
        </div>
      )}
    </div>
  );
};

export default LoanStatistics;
