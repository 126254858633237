import React, { useState } from 'react';
import { Col, Row, Tabs, Modal, message } from 'antd/lib';
import FirebaseGroups from 'firebase/firebaseGroups';
import Auxiliary from 'util/Auxiliary';
import Widget from "components/Widget";
import { GroupCardList, GroupModal, JoinedGroupCardList, SearchGroups } from 'components/app/groups';
import IntlMessages from 'util/IntlMessages';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
const { TabPane } = Tabs;

const Groups = ({ profile }) => {

  const { confirm } = Modal;
  const intlMessages = useFormatMessage();
  const [showModalForm, setShowModalForm] = useState(false);
  const [formType, setFormType] = useState("add");
  const [editGroupFormData, setEditGroupFormData] = useState(null);

  const setShowModal = (state) => {
    setShowModalForm(state);
  }

  const resetEditGroup = () => {
    setEditGroupFormData(null);
    setFormType("add");
  };

  const editGroup = row => {
    setEditGroupFormData(row);
    setFormType("edit");
  };

  const onDeleteClick = async values => {
    confirm({
      title: 'Are you sure you want to delete this group?',
      onOk: () => {
        deleteGroup(values);
        message.success(intlMessages('group.delete.success'));
      },
      onCancel() { },
    });
  };

  const deleteGroup = async input => {
    await FirebaseGroups.deleteGroup(input);
  }

  const approveRejectJoinRequest = (requstData, action, group) => {
    FirebaseGroups.approveRejectJoinRequest(requstData, action, group);
  }

  return (
    <Auxiliary>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-order-lg-1">
          <Widget
            title={
              <h2 className="h4 gx-text-capitalize gx-mb-0 gx-text-left gx-ml-1">
                {/* <i className="icon icon-social gx-mr-2"></i> */}
                <IntlMessages id="sidebar.groups" />
              </h2>
            }
            extra={[
              <p
                className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block gx-mr-1"
                onClick={() => setShowModal(true)}
              >
                <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle gx-mr-1" />
                <IntlMessages id="group.add" />
              </p>,
              // For Mobile screen
              <span
                onClick={() => setShowModal(true)}
                className="gx-text-primary gx-fs-md gx-pointer gx-mb-4 gx-d-block gx-d-sm-none gx-mr-1">
                <i className="icon icon-add-circle gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle" />
                <IntlMessages id="group.add" />
              </span>,
            ]}
          >
            <Tabs
              tabPosition="top"
              defaultActiveKey="1"
              style={{ marginTop: '0px' }}
            >
              <TabPane tab="My Groups" key="1">
                {
                  <GroupCardList
                    profile={profile}
                    setShowModal={setShowModal}
                    editGroup={editGroup}
                    onDeleteClick={onDeleteClick}
                    approveRejectJoinRequest={approveRejectJoinRequest}
                  />
                }
              </TabPane>
              <TabPane tab="My Joined Groups" key="2">
                {
                  <JoinedGroupCardList
                    profile={profile}
                  />
                }
              </TabPane>
              <TabPane tab="Join Group" key="3">
                <SearchGroups
                  profile={profile}
                />
              </TabPane>
            </Tabs>
          </Widget>
        </Col>
      </Row>
      <GroupModal
        userId={profile.id}
        setShowModal={setShowModal}
        showModal={showModalForm}
        formType={formType}
        resetEditGroup={resetEditGroup}
        editFormGroup={editGroupFormData}
      />
    </Auxiliary>
  );
};
export default Groups;
