import React from 'react';
import {
  useCollectionData,
  useDocumentData,
} from 'react-firebase-hooks/firestore';
import FirebaseGroups from 'firebase/firebaseGroups';
import {Empty, message} from 'antd/lib';
import Auxiliary from 'util/Auxiliary';
import GroupDetailComponent from 'components/app/groups/groupDetail';
import {useParams} from 'react-router-dom';
import CircularProgress from 'components/CircularProgress';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';

const GroupDetail = ({profile, userId}) => {
  const {groupId} = useParams();
  const intlMessages = useFormatMessage();

  const [groupData, groupDataLoading] = useDocumentData(
    FirebaseGroups.getGroupDetail(userId, groupId),
    {
      idField: 'id',
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [memberStatus, memberStatusLoading] = useCollectionData(
    FirebaseGroups.getuserGroupMemberStatus(profile.id, groupId),
    {
      idField: 'id',
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  // Send group join request
  const sendGroupJoinRequest = async (groupdata, userId) => {
    await FirebaseGroups.sendGroupJoinRequest(groupdata, userId);
    message.success(intlMessages('group.joinGrouprequest.sent'));
  };
  // Leave Group
  const leaveGroup = (memberData, groupData) => {
    FirebaseGroups.removeGroupMember(memberData, groupData);
  };

  if (groupDataLoading && memberStatusLoading) {
    return <CircularProgress className="gx-loader-400" />;
  } else {
    return (
      <Auxiliary>
        {groupData && groupData.status === 1 && memberStatus ? (
          <GroupDetailComponent
            profile={profile}
            groupData={groupData}
            memberStatus={memberStatus}
            sendGroupJoinRequest={sendGroupJoinRequest}
            leaveGroup={leaveGroup}
          />
        ) : (
          <Empty
            image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
            imageStyle={{
              height: 60,
            }}
            description={<span>group not found!</span>}
          />
        )}
      </Auxiliary>
    );
  }
};

export default GroupDetail;
