const baseSingle = {
  base: 1200,
  min: 75000,
  max: 109000,
};

const baseMarried = {
  base: 2400,
  min: 150000,
  max: 198000,
};

const baseHoh = {
  base: 1200,
  min: 112500,
  max: 136500,
};

const calculateSlidingScale = (agi, childrenComp, inputs) => {
  let {base, min, max} = inputs;

  if (agi >= max) {
    return 0;
  }

  if (agi < min) {
    return base + childrenComp;
  }

  return (
    base + childrenComp - (childrenComp * (agi - min)) / min - (agi - min) / 20
  );
};

export const calculatePersonalReturn = state => {
  let result = 0;
  if (state.taxStepVisited) {
    let childrenComp = state.numChildren * 500;
    if (state.taxFileType === 'single') {
      if (state.filedFor2019) {
        result = calculateSlidingScale(state.agi2019, childrenComp, baseSingle);
      } else {
        result = calculateSlidingScale(state.agi2018, childrenComp, baseSingle);
      }
    } else if (state.taxFileType === 'married') {
      if (state.filedFor2019) {
        result = calculateSlidingScale(
          state.agi2019,
          childrenComp,
          baseMarried,
        );
      } else {
        result = calculateSlidingScale(
          state.agi2018,
          childrenComp,
          baseMarried,
        );
      }
      if (result > 0) result += childrenComp;
    } else {
      if (state.filedFor2019) {
        result = calculateSlidingScale(state.agi2019, childrenComp, baseHoh);
      } else {
        result = calculateSlidingScale(state.agi2018, childrenComp, baseHoh);
      }
    }
  }

  if (result < 0) {
    return 0;
  }

  return result;
};

export const calculateBusinessReturn = state => {
  let result = 0;

  if (state.covidBusinessImpact) {
    result += 2.5 * state.avgMonthlyPayroll;
  }

  if (state.covidGigImpact) {
    result += (2.5 * state.avgMonthlyPayrollGig) / 12;
  }

  if (state.covidNonProfitImpact) {
    result += (2.5 * state.avgMonthlyPayrollNonProfit) / 12;
  }

  return result;
};
