import React from 'react';
import {Button, Form, Input, message} from 'antd';
import FirebaseAuth from 'firebase/firebaseAuth';
import IntlMessages from 'util/IntlMessages';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';
import {Link} from 'react-router-dom';
import Helmet from 'react-helmet';
import {history} from 'appRedux/store';
import SocialSignIn from './SocialSignIn';

const ForgotPassword = props => {
  const [form] = Form.useForm();
  const intlMessages = useFormatMessage();
  const onFormSubmit = async () => {
    let values = form.getFieldsValue(['email']);
    let sent = await FirebaseAuth.resetPassword(values);

    if (sent === true) {
      message.success(intlMessages('app.userAuth.resetPassword.success'));
      form.resetFields();
      history.push('/signin');
    } else if (sent === 'disabled') {
      message.error(intlMessages('appModule.accountDeleted'));
    } else {
      message.error(intlMessages('appModule.accountNotFound'));
    }
  };

  return (
    <div className="gx-app-login-wrap">
      <Helmet defaultTitle="Sign in | loanpalz" />
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg"></div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="app.userAuth.resetPassword" />
              </h1>
              <p style={{height: 200}}>
                <IntlMessages id="app.userAuth.forgot" />
              </p>
            </div>
            <div className="gx-app-logo">
              <img
                alt="example"
                src={require('assets/images/loanpalz-logo.png')}
              />
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              form={form}
              onFinish={onFormSubmit}
              className="gx-signin-form gx-form-row0">
              <Form.Item name="email" rules={[{required: true, type: 'email'}]}>
                <Input placeholder="Email" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.resetPassword" />
                </Button>
                <span>
                  <IntlMessages id="app.userAuth.or" />
                </span>{' '}
                <Link to="/signin">
                  <IntlMessages id="app.userAuth.signIn" />
                </Link>
              </Form.Item>
              <Button htmlType="submit" style={{display: 'none'}}></Button>
            </Form>
            <div className="gx-flex-row gx-justify-content-between gx-app-social-block">
              <span>Or sign in with</span>
              <SocialSignIn />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
