import React from 'react';
import Widget from 'components/Widget';
import {Tabs} from 'antd/lib';
import Overview from './Overview';
import SocialMedia from './SocialMedia';
import Biography from './Biography';

const About = ({profile, readonly}) => {
  const {TabPane} = Tabs;

  if (!profile) {
    return null;
  } else {
    return (
      <Widget
        title="About"
        styleName="gx-card-tabs gx-card-tabs-left gx-card-profile">
        <Tabs defaultActiveKey="1" style={{marginTop: '1px'}}>
          <TabPane tab="Overview" key="1">
            <Overview readonly={readonly} profile={profile} />
          </TabPane>
          <TabPane tab="Biography" key="2">
            <Biography profile={profile} readonly={readonly} />
          </TabPane>
          <TabPane tab="Social Links" key="3">
            <SocialMedia readonly={readonly} profile={profile} />
          </TabPane>
        </Tabs>
      </Widget>
    );
  }
};
export default About;
