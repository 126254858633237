import React, { useState } from 'react';
import FirebaseSearch from 'firebase/firebaseSearch';
import FirebaseGroups from 'firebase/firebaseGroups';
import {
  Select,
  Button,
  Modal,
  Form,
  Input,
  Upload,
  Spin,
  Row,
  Col,
  message,
} from 'antd/lib';
import IntlMessages from 'util/IntlMessages';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
const { Option } = Select;

const GroupModal = ({
  userId,
  setShowModal,
  showModal,
  formType,
  resetEditGroup,
  editFormGroup,
}) => {
  const [form] = Form.useForm();
  const intlMessages = useFormatMessage();
  const [fetching, setFetching] = useState(true);
  const [membersValue, setMembersValue] = useState([]);
  const [data, setData] = useState([]);
  const [fileInfoList, setFileInfoList] = useState([]);
  const [groupPrivacy, setGroupPrivacy] = useState(1);
  const [groupSearchable, setGroupSearchable] = useState(1);
  const [editInit, setEditInit] = useState(true);

  if (editFormGroup && editInit) {
    let fromData = editFormGroup;
    setGroupPrivacy(fromData.groupPrivacy);
    setGroupSearchable(fromData.groupSearchable);
    fromData = {
      ...fromData,
      groupPrivacy: groupPrivacy,
      groupSearchable: groupSearchable,
    };
    form.resetFields();
    form.setFieldsValue({
      ...fromData,
    });
    setShowModal(true);
    setEditInit(false);
  }

  // Search Members
  const fetchUser = async search => {
    let users = [];
    if (search.trim().length > 0) {
      const response = await FirebaseSearch.getSearchResults(search);
      FirebaseSearch.analytics.logEvent('search_users', { query: search });
      //Filter Disabled users
      users = response.hits.filter(
        users => users.disabled !== true && users.objectID !== userId,
      );
    }
    //console.log("user => ", users);
    setData(users);
    setFetching(false);
  };

  const onFormSubmit = async () => {
    let input = form.getFieldsValue(['groupName', 'groupDescription']);

    if (formType === 'add') {
      input = {
        ...input,
        groupPrivacy: groupPrivacy,
        groupSearchable: groupSearchable,
        groupCoverPhoto: fileInfoList,
        groupMembers: membersValue,
      };
      await FirebaseGroups.createNewGroup(input);
      message.success(intlMessages('group.create.success'));
    } else {
      let { id, userId, groupCoverPhoto } = form.getFieldsValue(['id', 'userId', 'groupCoverPhoto']);
      input = {
        ...input,
        id,
        userId,
        groupPrivacy: groupPrivacy,
        groupSearchable: groupSearchable,
        groupCoverPhoto: fileInfoList,
        oldGroupCoverPhoto: groupCoverPhoto,
      };
      await FirebaseGroups.updateGroupInformation(input);
      message.success(intlMessages('group.update.success'));
    }
    handleCancel();
  };

  const handleChange = value => {
    setFetching(false);
    setMembersValue(value);
  };

  const uploadButton = (
    <div>
      <i type="icon icon-plus" />
      <div className="ant-upload-text">Upload Cover Photo</div>
    </div>
  );

  let invalidUpload = false;

  function beforeUpload(file) {
    //Restrict image format, size, resolution
    const isJPG = file.type === 'image/jpeg';
    const isJPEG = file.type === 'image/jpeg';
    const isGIF = file.type === 'image/gif';
    const isPNG = file.type === 'image/png';

    if (!(isJPG || isJPEG || isGIF || isPNG)) {
      message.error(intlMessages('avatar.type.error'));
      invalidUpload = true;
      return false;
    }

    const isLt2M = file.size / 10240 / 10240 < 2;
    if (!isLt2M) {
      message.error(intlMessages('avatar.size.error'));
      invalidUpload = true;
      return false;
    }

    invalidUpload = false;
    return true;
  }

  const mediaSettings = {
    name: 'file',
    headers: {
      authorization: 'authorization-text',
    },
    onChange: info => {
      if (!invalidUpload) {
        setFileInfoList(info.fileList);
      }
    },
  };

  const handleCancel = () => {
    resetEditGroup();
    setShowModal(false);
    setEditInit('add');
    form.resetFields();
    setGroupPrivacy(1);
    setGroupSearchable(1);
  };

  const changeGroupPrivacy = e => {
    setGroupPrivacy(e);
  };

  const changeGroupSearchable = e => {
    setGroupSearchable(e);
  };

  return (
    <div>
      <Modal
        title={
          formType === 'add' ? (
            <IntlMessages id="group.add.heading" />
          ) : (
              <IntlMessages id="group.update" />
            )
        }
        visible={showModal}
        closable={true}
        onOk={() => form.submit()}
        onCancel={() => handleCancel()}>
        <Form form={form} onFinish={onFormSubmit}>
          <div className="gx-modal-box-row">
            <div className="gx-modal-box-form-item">
              <Row>
                <Col span={24}>
                  <label>Group Name</label>
                  <Form.Item
                    name="groupName"
                    rules={[
                      {
                        required: true,
                        type: 'string',
                        message: 'Please enter group name',
                      },
                    ]}>
                    <Input placeholder="Group name" style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <label>Group Description</label>
                  <Form.Item
                    name="groupDescription"
                    rules={[
                      {
                        required: true,
                        type: 'string',
                        message: 'Please type somthing about group',
                      },
                    ]}>
                    <Input
                      placeholder="Group descripton"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
                {formType === 'add' ? (
                  <Col span={24}>
                    <label>Add Group Members</label>
                    <Form.Item name="groupMembers">
                      <Select
                        mode="tags"
                        labelInValue
                        value={membersValue}
                        placeholder="Search users"
                        notFoundContent={
                          fetching ? <Spin size="small" /> : null
                        }
                        filterOption={false}
                        onSearch={e => fetchUser(e)}
                        onChange={handleChange}
                        style={{ width: '100%' }}>
                        {data.length &&
                          data.map(d => (
                            <Option key={Math.random()} value={d.objectID}>
                              {d.username}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col span={9}>
                  <label>Group Cover Photo</label>
                  <Form.Item name="groupCoverPhoto">
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={true}
                      // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      fileList={fileInfoList}
                      {...mediaSettings}
                      beforeUpload={beforeUpload}>
                      {fileInfoList.length > 0 ? null : uploadButton}
                    </Upload>
                  </Form.Item>
                </Col>

                <Col span={15}>
                  <label>Group Privacy</label>
                  <Form.Item className="gx-form-row" name="groupPrivacy">
                    <Select
                      defaultValue={groupPrivacy}
                      style={{ width: '100%' }}
                      onChange={changeGroupPrivacy}>
                      <Option value={0}>Private</Option>
                      <Option value={1}>Public</Option>
                    </Select>
                  </Form.Item>

                  <label>Group Searchability</label>
                  <Form.Item name="groupSearchable">
                    <Select
                      defaultValue={groupSearchable}
                      style={{ width: '100%' }}
                      onChange={changeGroupSearchable}>
                      <Option value={0}>Only Member Searchable</Option>
                      <Option value={1}>Public Searchable</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Button htmlType="submit" style={{ display: 'none' }}></Button>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default GroupModal;
