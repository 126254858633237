import React from 'react';
import {Col, Radio, Select, Tooltip} from 'antd';
import '../settingsWizard.less';
import withStepTemplate from './withStepTemplate';

import {InfoCircleOutlined} from '@ant-design/icons';

const {Option} = Select;

const StepEmployment = props => {
  return (
    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
      <h4 className="gx-mb-2 formLabel">
        <span>
          Are you currently employed?
          <Tooltip
            placement="topLeft"
            title="Please pick your current employment status"
            trigger="hover">
            <InfoCircleOutlined
              className="gx-ml-1 gx-pointer"
              style={{fontSize: '16px', verticalAlign: 'middle'}}
            />
          </Tooltip>
        </span>
      </h4>
      <Radio.Group
        value={props.state.employmentStatus}
        buttonStyle="solid"
        onChange={value => {
          props.dispatch({
            type: 'employmentStatus',
            payload: value.target.value,
          });
          // if (value.target.value === 'single') {
          //   setTimeout(() => {
          //     props.push('worker')
          //   }, 500)
          // }
        }}>
        <Radio.Button value="employed">Yes</Radio.Button>
        <Radio.Button value="unemployed">No</Radio.Button>
        <Radio.Button value="laidoff">Laid off</Radio.Button>
        <Radio.Button value="furloughed">Furloughed</Radio.Button>
      </Radio.Group>

      {(props.state.employmentStatus === 'unemployed' ||
        props.state.employmentStatus === 'laidoff' ||
        props.state.employmentStatus === 'furloughed') && (
        <div class="gx-mt-3">
          <h4 className="gx-mb-2 formLabel">
            <span>
              Have you been laid off or furloughed due to the COVID-19 Crisis?
              <Tooltip
                placement="topLeft"
                title="Have you lost your job due to COVID-19?"
                trigger="hover">
                <InfoCircleOutlined
                  className="gx-ml-1 gx-pointer"
                  style={{fontSize: '16px', verticalAlign: 'middle'}}
                />
              </Tooltip>
            </span>
          </h4>

          <Radio.Group
            value={props.state.covidEmploymentImpact}
            buttonStyle="solid"
            onChange={value => {
              props.dispatch({
                type: 'covidEmploymentImpact',
                payload: value.target.value,
              });
            }}>
            <Radio.Button value={true}>Yes</Radio.Button>
            <Radio.Button value={false}>No</Radio.Button>
          </Radio.Group>
        </div>
      )}

      {props.state.covidEmploymentImpact && (
        <div className="gx-mt-3">
          <h4 className="gx-mb-2 formLabel">
            <span>
              Which state do you work in?
              <Tooltip
                placement="topLeft"
                title="Which state do you work in?"
                trigger="hover">
                <InfoCircleOutlined
                  className="gx-ml-1 gx-pointer"
                  style={{fontSize: '16px', verticalAlign: 'middle'}}
                />
              </Tooltip>
            </span>
          </h4>

          <Select
            value={props.state.workState}
            defaultValue="AL"
            style={{width: 200}}
            onChange={value => {
              props.dispatch({
                type: 'workState',
                payload: value,
              });
              // setTimeout(() => {
              //   // props.push('marital_status')
              //   props.next()
              // }, 500)
            }}>
            <Option value="AL">Alabama</Option>
            <Option value="AK">Alaska</Option>
            <Option value="AZ">Arizona</Option>
            <Option value="AR">Arkansas</Option>
            <Option value="CA">California</Option>
            <Option value="CO">Colorado</Option>
            <Option value="CT">Connecticut</Option>
            <Option value="DE">Delaware</Option>
            <Option value="DC">District Of Columbia</Option>
            <Option value="FL">Florida</Option>
            <Option value="GA">Georgia</Option>
            <Option value="HI">Hawaii</Option>
            <Option value="ID">Idaho</Option>
            <Option value="IL">Illinois</Option>
            <Option value="IN">Indiana</Option>
            <Option value="IA">Iowa</Option>
            <Option value="KS">Kansas</Option>
            <Option value="KY">Kentucky</Option>
            <Option value="LA">Louisiana</Option>
            <Option value="ME">Maine</Option>
            <Option value="MD">Maryland</Option>
            <Option value="MA">Massachusetts</Option>
            <Option value="MI">Michigan</Option>
            <Option value="MN">Minnesota</Option>
            <Option value="MS">Mississippi</Option>
            <Option value="MO">Missouri</Option>
            <Option value="MT">Montana</Option>
            <Option value="NE">Nebraska</Option>
            <Option value="NV">Nevada</Option>
            <Option value="NH">New Hampshire</Option>
            <Option value="NJ">New Jersey</Option>
            <Option value="NM">New Mexico</Option>
            <Option value="NY">New York</Option>
            <Option value="NC">North Carolina</Option>
            <Option value="ND">North Dakota</Option>
            <Option value="OH">Ohio</Option>
            <Option value="OK">Oklahoma</Option>
            <Option value="OR">Oregon</Option>
            <Option value="PA">Pennsylvania</Option>
            <Option value="RI">Rhode Island</Option>
            <Option value="SC">South Carolina</Option>
            <Option value="SD">South Dakota</Option>
            <Option value="TN">Tennessee</Option>
            <Option value="TX">Texas</Option>
            <Option value="UT">Utah</Option>
            <Option value="VT">Vermont</Option>
            <Option value="VA">Virginia</Option>
            <Option value="WA">Washington</Option>
            <Option value="WV">West Virginia</Option>
            <Option value="WI">Wisconsin</Option>
            <Option value="WY">Wyoming</Option>
          </Select>
        </div>
      )}
    </Col>
  );
};

export default withStepTemplate(StepEmployment);
