import React from 'react';
import Helmet from 'react-helmet';
import CustomScrollbars from 'util/CustomScrollbars';

const PrivacyPolicy = props => {

  return (
    <div className="gx-legal-content-container">
      <Helmet defaultTitle="Terms of Service | loanpalz" />
        <div className="gx-app-content-wrapper">
          <div className="gx-app-content-sidebar-wrapper">
            <div className="gx-app-logo-content-bg"></div>

            <div className="gx-content-sidebar">
              <h1>Privacy Policy</h1>
              <p>Welcome to loanpalz!</p>
              <p>Loanpalz builds digital products that enable people to transform their financial life, connect with each other, get help from professional coaches, and ultimately gain financial freedom. These Terms govern your use of loanpalz features, apps, services, technologies, and software we offer, except where we expressly state that separate terms (and not these) apply. These Products are provided to you by Loanpalz, Inc.</p>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src={require('assets/images/loanpalz-logo.png')} />
            </div>
          </div>
          
          <div className="gx-content-main">
            <CustomScrollbars>
              <h1 style={{marginTop: 0}}>Loanpalz Privacy Policy</h1>
              
              <h1>Our Commitment to Privacy</h1>

              <p>Loanpalz™ (“Company” or “We ” or “our” or “us”) respects your privacy and is committed to protecting it through our compliance with this Privacy Policy. We want our users and members to fully understand what information we collect, how we use this information, and the steps we take to protect your personal information. This following describes our Privacy Policy. Please read this Privacy Policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our website. By accessing or using this website, you agree to this Privacy Policy. This Privacy Policy may change from time to time. Your continued use of this website after we make changes is deemed to be acceptance of those changes, so please check the Privacy Policy periodically for updates.</p>
              <p>We take the security of your information very seriously. We take several administrative, technical, physical and managerial measures to protect your personal information from unauthorized access. As one example, all personal information that you send us through the registration and sign-up processes are encrypted using industry-standard methods. As discussed below, we collect information about you to enable us to serve you more effectively and efficiently, to improve our users’ experience, to improve and further develop our products and service offerings, and to process your requests and activities on our website. We also may use your contact information to supply you with information we believe will be of use or interest to you. We are not responsible for the content or the policies of other websites to which this website may link. Please contact us if you have any questions or concerns about this Privacy Policy, what information we collect and how we use that information.</p>
              <p>This Privacy Policy applies to any and all types and forms of information we may collect: (i) on this website; (ii) in e-mail, text and other electronic messages between you and this website (or between and among users of our services); (iii) through mobile and desktop applications you download from this website, which provide dedicated non-browser-based interaction between you and this website; and (iv) when you interact with our advertising and applications on third-party websites and services, if those applications or advertising include links to this Privacy Policy. When you register to receive our services, we require that you supply your full name, email address, and demographic information (for example, your age, sex, and the city in which you work). Users who register must agree to the Terms of Use and Community Guidelines for use of this website, and the services provided through this website.</p>
              
              <h1>Children Under the Age of Thirteen (13) Years of Age</h1>
              <p>Our website is not intended for children under thirteen (13) years of age. No one under age thirteen (13) may provide any personal information to or on the website. We do not knowingly collect personal information from children under thirteen (13) years of age. If you are under thirteen (13) years of age, do not use or provide any information on this website or on or through any of its features/services, do not register on the website, do not make any purchases through the website, do not use any of the interactive or public comment features of this website or provide any information about yourself to us, including your name, address, telephone number, e-mail address or any screen name or user name you may use. If we learn we have collected or received personal information from a child under thirteen (13) without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under thirteen (13), please contact us by e-mail at support@Loanpalz.com, or write us at: Loanpalz™, 21165 Whitfield Pl. Ste#401 Sterling, VA 20165. IF YOU ARE THIRTEEN (13) YEARS OR YOUNGER, PLEASE DO NOT USE Loanpalz OR ANY OF ITS SERVICES FOR ANY PURPOSE.</p>

              <h1>Children under the Age of Eighteen (18) Years of Age</h1>
              <p>If you are a child under the age of eighteen (18) years of age (a “Minor”), you can use this website and related services only with the consent and under the supervision of your parents or legal guardians. If you are a Minor, please do not submit any personal information to this website. IF YOU ARE EIGHTEEN (18) YEARS OLD OR YOUNGER, PLEASE DO NOT SUBMIT ANY PERSONAL INFORMATION TO THIS WEBSITE FOR ANY PURPOSE.</p>

              <h1>Important Notice and Agreement</h1>
              <p>By accessing and using our website and related services, you affirm that you are either more than eighteen (18) years of age, or an emancipated minor, or possess legal parental or guardian consent, and are fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in this Privacy Policy, the Terms of Use and the Community Guidelines, and to abide by and comply with this Privacy Policy, the Terms of Use and the Community Guidelines. In any case, by accessing and using our website and related services, you affirm that you are over the age of thirteen (13) years of age, as THE WEBSITE IS NOT INTENDED FOR CHILDREN UNDER THIRTEEN (13) YEARS OF AGE THAT ARE UNACCOMPANIED BY HIS OR HER PARENT OR LEGAL GUARDIAN.</p>

              <h1>Collection and Use of Information</h1>
              <p>We receive and store information, including without limitation, personal information, about you and your use of our website and its services. This information is gathered in a number of ways, including without limitation, when you provide it to us directly, when you enter it on our website, you’re interacting with our service, when you are communicating with us, from third parties, such as our business partners, and automatically as you navigate through the site. Some of the information collected automatically may include usage details, IP addresses and information collected through cookies, web beacons and other tracking technologies. Personal information is information that can be used to uniquely identify or contact you. Non-personal information is information that does not permit direct association with you. We may collect, use, transfer and disclose non-personal information for any purpose. You may choose not to provide personal information we may request of you. However, most of the personal information we request is of the type that is required in order to provide our products and services; for example, your name and address, your email address, your telephone number, and your credit card number.</p>
              <p>The information we collect on or through our website may also include, without limitation, certain information that you provide by filling in forms on our website, by subscribing and using our services, by posting material or requesting further services, by communicating through our website, when you search on the website, when you enter or participate in surveys, contests or promotions sponsored by us, when you report a problem with our website, and details of transactions you carry out through our website. You may be required to provide financial information before placing an order through our website.</p>
              <p>You also may provide information to be published or displayed or posted on public areas of the website, or transmitted to other users of the website or third parties. Any user or third party contributions are posted on and transmitted to others at your own risk. Although we limit access to certain pages/you may set certain privacy settings for such information by logging into your account profile, please be aware that no security measures are perfect or impenetrable. Additionally, we cannot control the actions of other users of the website with whom you may choose to share your information. Therefore, we cannot and do not guarantee that any of your user or third party contributions will not be viewed by unauthorized persons.</p>
              <p>When you interact with us, we may ask you to supply us with personal information so that we can provide, enhance and personalize our services and marketing efforts. We use this personal information, for example, to process your registration, your orders and your payments, and to communicate with you on these and other topics. We also use your e-mail address to send you newsletters, as well as messages about new website features, our special offers, promotional announcements, consumer surveys and other correspondence concerning our service. If you no longer want to receive marketing communications from us, simply let us know by emailing us at info@loanpalz.com. Please note that you cannot unsubscribe from certain correspondence from us, including messages relating to your account transactions and billing transactions.</p>
              <p>We may keep track of your interactions with us and collect information related to you and your use of our service, including but not limited to your online activity, products and services used, reviews, payment history, correspondence, operating systems used, browser type and Internet protocol addresses. We may also provide analysis of our users in the aggregate or otherwise in anonymous form to prospective partners, advertisers and other third parties.</p>
              <p>While using our services, you will have opportunities to post reviews for various reasons. Please understand that when you post such information, it is made public and is not subject to this Privacy Policy. We are not responsible for any third party’s use of information you publicly disclose through our services.</p>
              <p>Our website uses certain third party services, including without limitation, Google AdWords, Google Analytics, and the Google Display Network together to serve ads to you on other websites after you’ve visited our website. This practice is known as remarketing and is a tool that Google offers to businesses. If you would like to know more about the program, you can do so here. But here are the basics: we, along with third-party vendors, Google and DoubleClick, use first-party cookies (such as the Google Analytics cookie) and third-party cookies (such as the DoubleClick cookie) together to inform, optimize, and serve ads based on your past visits to our website. If you would like to opt-out of receiving advertising like this from Google, you can manage these settings by visiting the Google Ads Preferences Manager. Also, Google recommends installing the Google Analytics Opt-out Browser Add-on.</p>
              <p>From time to time, we may partner with companies whose products we believe will interest our members. In conjunction with these partners, we may send promotional announcements and/or advertisements on their behalf, as permitted by law. If you do not want to receive such promotional email, simply let us know by sending us an email at info@loanpalz.com.</p>

              <h1>Information We Collect Through Automatic Data Collection Technologies</h1>
              <p>We also may use these technologies to collect information about your online activities over time and across third-party websites or other online services. The information we collect automatically is statistical data and generally does not include personal information, but we may maintain it or associate it with personal information we collect in other ways or receive from third parties. Some of the technologies we use for automatic data collection include cookies, browser cookies, flash cookies, and webs beacons.</p>
              <p>Cookies are small pieces of data placed on a user’s hard drive during the exchange of data that happens when the user’s browser visits our website. Our cookies assist us in collecting information regarding the pages of our website that you access or visit, and in recording any preferences you indicate on our website. We also use cookies to make your experience on our website more personalized and convenient. Our cookies cannot and do not retrieve any other information from your hard drive.</p>
              <p>We do not collect personal information automatically, but we may tie this information to personal information about you that we collect from other sources or you provide to us.</p>

              <h1>Disclosure of Information</h1>
              <p>We use other companies, agents or contractors to perform services on our behalf. For example, we may partner with other companies to provide infrastructure and information technology (“IT”) services, personalize and optimize our web pages, process credit card transactions, provide customer service, collect debts, analyze and enhance data, including users’ interaction with our website, and process consumer surveys. In the course of providing such services, these other companies may have access to some of your information. We do not authorize these companies to use or disclose your personal information except for the purpose of providing the services we request of them.</p>
              <p>We also reserve the right to disclose personal information if we reasonably believe that access, use, preservation or disclosure of such information is reasonably necessary to: (i) satisfy any applicable law, regulation, legal process, or enforceable governmental request, (ii) enforce applicable terms of use, including investigation of potential violations thereof, (iii) detect, prevent, or otherwise address illegal or suspected illegal activities, security or technical issues, or (d) protect against harm to the rights, property or safety of Loanpalz™, its users or the public as required or permitted by law. In connection with any reorganization, restructuring, merger or sale, or other transferring of assets we reserve the right to transfer information, including personal information, provided that the receiving party agrees to respect your personal information in a manner that is consistent with our Privacy Policy.</p>

              <h1>Links to Other Sites</h1>
              <p>The Loanpalz™ website contains links to sites operated by third parties whose policies regarding the handling of information may differ from ours. While we endeavor to associate with reputable websites, we cannot be responsible for the information handling practices of these other websites. These linked websites have separate and independent privacy statements, notices and terms of use, which we recommend you read carefully before using those sites.</p>

              <h1>Third-Party Use of Cookies and Other Tracking Technologies</h1>
              <p>Some content or applications, including advertisements, on the website are served by third-parties, including advertisers, ad networks and servers, content providers and application providers. These third-parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect information about you when you use our website. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites and other online services. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content. We do not control these third parties’ tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly.</p>

              <h1>Security</h1>
              <p>Unfortunately, no security system can be guaranteed to be one hundred percent (100%) secure. Accordingly, we cannot guarantee the security of your information and cannot assume liability for improper access to it. By using our services, including our website and user interfaces, or providing information to us through any means, you agree that we can communicate with you electronically regarding security, privacy, and administrative issues relating to your use of our services. You are responsible for maintaining the confidentiality of your account access information and for restricting access to the computer or device through which you access your Loanpalz™ account. If you disclose your password to anyone or share your account and/or devices with other people, you take full responsibility for their actions. Where possible, users of public or shared devices should log out at the completion of each visit.</p>

              <h1>Notice to California Residents Regarding Your California Privacy Rights</h1>
              <p>If you are a California resident, California Civil Code §1798.83 permits you to request information regarding the disclosure of your personal information by certain members of Loanpalz™, to third parties for the third parties’ direct marketing purposes. To make such a request, please send an email to info@loanpalz.com or write us at: Loanpalz™, 21165 Whitfield Pl. Ste#401 Sterling, VA 20165.</p>
              <p>We take great pride in the relationship of trust we have built with our guests we are dedicated to treating your personal information with care and respect. Pursuant to California Civil Code §1798.83(c)(2), members of Loanpalz™, does not share members’, users’ or guests’ personal information with other affiliated companies or others outside Loanpalz™, for those parties’ direct marketing use unless a guest elects that we do so. For more information about our privacy and data collection policies, you may wish to review our Privacy Policy.</p>

              <h1>Compliance and Cooperation with Regulatory Authorities</h1>
              <p>We regularly review our compliance with our Privacy Policy. When we receive formal written complaints, we will contact the person who made the complaint to follow up. We work with the appropriate regulatory authorities, including local data protection authorities, to resolve any complaints regarding the transfer of personal data that we cannot resolve with our users or members directly.</p>

              <h1>Changes to this Privacy Policy</h1>
              <p>As we update and expand our services, we may make changes to this Privacy Policy. You should check back for updates to this Privacy Policy from time to time. Your continued use of our website after such changes to our Privacy Policy will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by the modified Privacy Policy. The most current version of this Privacy Policy can be reviewed by visiting our website and clicking on “Privacy Policy” located at the bottom of the pages of the Loanpalz™ website. The most current version of the Privacy Policy will supersede all previous versions.</p>

              <h1>Contacting Us</h1>
              <p>If you have questions concerning your account or this Privacy Policy, please contact us at info@loanpalz.com.</p>

              <h5>Effective: Jan 1, 2020</h5>
            </CustomScrollbars>
          </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
