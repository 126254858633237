import React, { useState } from 'react';
import { Button, Form, Input, message } from 'antd/lib';
import IntlMessages from 'util/IntlMessages';
import FirebaseAuth from 'firebase/firebaseAuth';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { Redirect } from "react-router-dom";
import CircularProgress from 'components/CircularProgress';
const FormItem = Form.Item;

const DeleteAccount = ({ profile }) => {
    const [disabled, setDisabled] = useState(true);
    const [signout, setSignout] = useState(false);
    const [submit, setSubmit] = useState(false);
    const intlMessages = useFormatMessage();
    const [form] = Form.useForm();

    const checkInput = e => {
        let input = e.target.value;

        if (input.trim().length >= 6) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }

    const cancelDeletion = () => {
        setDisabled(true);
        form.resetFields();
    }

    const deleteAccount = async input => {
        setDisabled(true);
        setSubmit(true)
        let formValues = form.getFieldsValue(['password']);
        formValues = {
            ...formValues,
            email: profile.email,
        };
        try {
            form.resetFields();
            await FirebaseAuth.deleteAccount(formValues);
            message.success(intlMessages('appModule.accountDeletedSuccessfully'));
            setSignout(true);
            await FirebaseAuth.signOut()

        } catch (error) {
            setSubmit(false);
            message.error(error.message);
        }
    };

    return (
        <div>
            {signout ? (
                <Redirect to="/signout" />

            ) : (
                    <div className="gx-login-container">
                        <div className="gx-login-content">
                            {
                                submit ? (
                                    <div className="gx-loader-view" style={{ height: "150px" }}>
                                        <CircularProgress />
                                        <span>We are deleting your account. Please wait!</span>
                                    </div>
                                ) : (
                                        <div>
                                            <div className="gx-mb-4">
                                                <h3>Please enter your password to confirm delete account</h3>
                                            </div >

                                            <Form
                                                form={form}
                                                onFinish={deleteAccount}
                                                className="gx-login-form gx-form-row">
                                                <FormItem
                                                    name="password"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            type: 'string',
                                                            message: 'Please enter your login password',
                                                        },
                                                    ]}>
                                                    <Input type="password" onChange={(e) => checkInput(e)} placeholder="Enter your password" />
                                                </FormItem>
                                                <FormItem>
                                                    <Button type="danger" htmlType="submit" disabled={disabled} >
                                                        <IntlMessages id="app.userAuth.deleteAccountButton" />
                                                    </Button>
                                                    <Button type="default" htmlType="reset" onClick={cancelDeletion}>
                                                        <IntlMessages id="app.userAuth.CANCEL" />
                                                    </Button>
                                                </FormItem>
                                            </Form>
                                        </div>
                                    )
                            }
                        </div >
                    </div >

                )}
        </div >
    );
};

export default DeleteAccount;
