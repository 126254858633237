import React from 'react';
import {Input} from 'antd/lib';
import {history} from 'appRedux/store';
import './searchbox.less';
const {Search} = Input;

const SearchBox = ({styleName, placeholder}) => {
  return (
    <div className={`gx-search-bar ${styleName}`}>
      <Search
        placeholder="Search users..."
        onSearch={value => history.push(`/app/search?query=${value}`)}
      />
    </div>
  );
};
export default SearchBox;

SearchBox.defaultProps = {
  value: '',
};
