import React, {useState} from 'react';
import {Avatar, List, Dropdown, Col, Row, Card, Menu} from 'antd';
import {useDocumentData} from 'react-firebase-hooks/firestore';
import FirebaseSocial from 'firebase/firebaseSocial';
import FirebaseCoaches from 'firebase/firebaseCoaches';
import {TEMPORARY_COACH_COVER} from 'util/constants';
import {dateFormatDisplay} from 'util/commonUtils';
import BookingDetailModal from './BookingDetailModal';

const DisplayAppointmentCell = ({
  data,
  manageButton,
  section,
  view,
  canUpdate,
  upcomingSearch,
  pastSearch,
}) => {
  const [showDetail, setShowDetail] = useState(false);

  let keyword = upcomingSearch ? upcomingSearch : pastSearch;

  const [userData] = useDocumentData(
    FirebaseSocial.getUserDocumentData(
      section === 'coach' ? data.client : data.coach,
    ),
    {
      snapshotListenOptions: {includeMetadataChanges: true},
    },
  );

  const [serviceData] = useDocumentData(
    FirebaseCoaches.getServiceDetail(data.coach, data.service),
    {
      snapshotListenOptions: {includeMetadataChanges: true},
    },
  );

  let user = userData;
  let service = serviceData;

  if (keyword && user && service) {
    if (
      user.username.toLowerCase().indexOf(keyword.toLowerCase()) === -1 &&
      service.serviceName.toLowerCase().indexOf(keyword.toLowerCase()) === -1 &&
      data.timeSlot.toLowerCase().indexOf(keyword.toLowerCase()) === -1 &&
      dateFormatDisplay(data.date)
        .toLowerCase()
        .indexOf(keyword.toLowerCase()) === -1
    ) {
      user = null;
      service = null;
    }
  }

  const viewDetail = () => {
    setShowDetail(true);
  };

  const hideDetail = () => {
    setShowDetail(false);
  };

  const options = ['Update Appointment', 'Cancel Appointment'];

  const memberDropdownMenu = item => (
    <Menu
      onClick={event => {
        if (event.key === 'Update Appointment') {
          manageButton.editMyAppoientment(item);
        } else {
          manageButton.cancelAppointment(item);
        }
      }}>
      {options.map(option => (
        <Menu.Item key={option}>{option}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      {user &&
        service &&
        (view === 'list' ? (
          <List
            itemLayout="vertical"
            size="small"
            dataSource={[data]}
            renderItem={item => (
              <List.Item key={item.title}>
                <Row>
                  <Col className="align-self-center" span={8}>
                    <div className="gx-link" onClick={() => viewDetail()}>
                      <Avatar
                        shape="square"
                        size={40}
                        src={
                          service.coverPhotodownloadURL || TEMPORARY_COACH_COVER
                        }
                        className="mr-3"
                      />
                      {service.serviceName}
                    </div>
                  </Col>
                  <Col className="align-self-center" span={5}>
                    {user.username}
                  </Col>
                  <Col className="align-self-center" span={4}>
                    {dateFormatDisplay(item.date)}
                  </Col>
                  <Col className="align-self-center" span={5}>
                    {item.timeSlot}
                  </Col>
                  <Col className="align-self-center" span={2}>
                    {canUpdate === false ? null : (
                      <Dropdown
                        overlay={() => memberDropdownMenu(item)}
                        placement="bottomRight"
                        trigger={['click']}>
                        <i className="gx-icon-btn icon icon-ellipse-v" />
                      </Dropdown>
                    )}
                  </Col>
                </Row>
              </List.Item>
            )}
          />
        ) : (
          <Card
            key={'grid' + data.id}
            size="small"
            className="goal-card flex-fill"
            cover={
              <div className="gx-link" onClick={() => viewDetail()}>
                <img
                  className="goal-cover-image"
                  src={service.coverPhotodownloadURL || TEMPORARY_COACH_COVER}
                  alt=""
                />
              </div>
            }>
            {canUpdate === false ? null : (
              <Dropdown
                overlay={() => memberDropdownMenu(data)}
                placement="bottomRight"
                trigger={['click']}>
                <i className="gx-icon-btn icon icon-ellipse-v float-right m-0 btn p-0 ant-dropdown-link border-0" />
              </Dropdown>
            )}
            <div className="goal-card-heading p-2 d-flex">
              <div>
                <h5>
                  <div className="gx-link" onClick={() => viewDetail()}>
                    {service.serviceName}
                  </div>
                </h5>
                <p>{user.username}</p>
                <p>{dateFormatDisplay(data.date)}</p>
                <span>{data.timeSlot}</span>
              </div>
            </div>
          </Card>
        ))}
      <BookingDetailModal
        user={user}
        service={service}
        date={data.date}
        slot={data.timeSlot}
        showModal={showDetail}
        onModalClose={hideDetail}
        canUpdate={canUpdate}
        appointment={data}
        manageButton={manageButton}
      />
    </>
  );
};

export default DisplayAppointmentCell;
