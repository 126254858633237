import React from 'react';
import { Col, Row, Badge } from 'antd/lib';
import moment from 'moment';
import CustomScrollbars from 'util/CustomScrollbars';

const dayFormat = 'D-MMM-YYYY';

const TaskDetail = ({ todo, onEditTask, onDeleteTask, onStarredTask, onImportantTask, onMarkCompletedTask, taskFilterByLabel }) => {
  const { title, description } = todo;
  return (
    <div>
      <div className="gx-module-detail gx-module-list">
        <CustomScrollbars className="gx-todo-detail-content-scroll">
          <div className="gx-module-detail-item gx-module-detail-header">
            <Row>
              <Col xs={24} sm={12} md={17} lg={12} xl={17}>
                <div className="gx-flex-row">
                  <div className="gx-user-name gx-mr-md-4 gx-mr-2 gx-my-1">
                    <h4 className="gx-mb-0 gx-pointer">{title}</h4>
                  </div>
                </div>
              </Col>

              <Col xs={24} sm={12} md={7} lg={12} xl={7}>
                <div className="gx-flex-row gx-justify-content-between">
                  {todo.completed ? (
                    <span
                      className="gx-text-muted gx-d-block"
                      onClick={() => {
                        onMarkCompletedTask(todo);
                      }}
                    >
                      <i className="gx-icon-btn icon icon-close-circle" />
                    </span>
                  ) : (
                      <span
                        className="gx-text-muted gx-d-block"
                        onClick={() => {
                          onMarkCompletedTask(todo);
                        }}
                      >
                        <i className="gx-icon-btn icon icon-check-circle-o" />
                      </span>
                    )}

                  <span
                    className="gx-d-block"
                    onClick={() => {
                      onEditTask(todo);
                    }}
                  >
                    <i className="gx-icon-btn icon icon-edit" />
                  </span>
                  <i
                    className="gx-icon-btn icon icon-trash"
                    onClick={() => {
                      onDeleteTask(todo);
                    }}
                  />

                  <span
                    className="gx-d-block"
                    onClick={() => {
                      onStarredTask(todo);
                    }}
                  >
                    {todo.starred ? <i className="gx-icon-btn icon icon-star" /> : <i className="gx-icon-btn icon icon-star-o" />}
                  </span>

                  <span
                    className="gx-d-block"
                    onClick={() => {
                      onImportantTask(todo);
                    }}
                  >
                    {todo.important ? (
                      <i className="gx-icon-btn icon icon-important" />
                    ) : (
                        <i className="gx-icon-btn icon icon-important-o" />
                      )}
                  </span>
                </div>
              </Col>
            </Row>
          </div>
          <div className="gx-module-detail-item gx-mb-md-4 gx-mb-2">
            <div className="gx-flex-row gx-align-items-center gx-justify-content-between gx-flex-1 gx-flex-nowrap">
              <div className="gx-task-des gx-col">Due Date: {moment(new Date(todo.date * 1000), dayFormat).format(dayFormat)}</div>
            </div>
          </div>

          <div className="gx-module-detail-item gx-mb-md-4 gx-mb-2">
            <div className="gx-flex-row gx-align-items-center gx-justify-content-between gx-flex-1 gx-flex-nowrap">
              <div className="gx-task-des gx-col">{description}</div>
            </div>
          </div>
          <div className="gx-module-detail-item gx-mb-md-4 gx-mb-2">
            <div className="gx-mb-md-4 gx-mb-2">
              {todo &&
                todo.tags &&
                todo.tags.map((label, index) => {
                  return <Badge key={index} count={label.title} style={{ backgroundColor: label.color }} onClick={() => { taskFilterByLabel(label.title) }} />;
                })}
            </div>
          </div>
        </CustomScrollbars>
      </div>
    </div>
  );
};

export default TaskDetail;
