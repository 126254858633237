import React, { useState } from 'react';
import { Form, Button, Modal, Row, Col, message, DatePicker, Checkbox, Spin } from 'antd/lib';
import { COACH_TIMESLOTS } from 'loanpalz-constants';
import IntlMessages from 'util/IntlMessages';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import FirebaseCoaches from 'firebase/firebaseCoaches';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { FaSpinner } from 'react-icons/fa';
import AvailablityCell from './AvailablityCell';
import { covertTimestamp, dateFormatDisplay } from 'util/commonUtils';
import moment from 'moment';
import Select from 'react-timezone-select';

const Availability = ({ readonly, profile }) => {

  const { confirm } = Modal;
  const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;
  const [form] = Form.useForm();
  const intlMessages = useFormatMessage();
  const { RangePicker } = DatePicker;
  const [showModal, setShowModal] = useState(false);
  const [availDate, setAvailDate] = useState([]);
  const [availDate1, setAvailDate1] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [availTime, setAvailTime] = useState([]);
  const [formType, setFormType] = useState('add');
  const [selectedOption, setSelectedOption] = useState('');

  const dateFormat = "YYYY-MM-DD";
  let coachsTimeSlots = [];
  const dateRule = [{ required: true, message: 'Please select Available Date Range' }];
  const [dateRangeValidation, setDateRangeValication] = useState(dateRule);
  if (COACH_TIMESLOTS && COACH_TIMESLOTS.length > 0) {
    COACH_TIMESLOTS.forEach(slot => {
      coachsTimeSlots.push(
        <Col span={24} className="gx-mb-2">
          <Checkbox value={slot.value} key={slot.value}>{slot.label}</Checkbox>
        </Col>
      );
    });
  };

  const onModalOpen = e => {

    if (e) {
      form.resetFields();
      form.setFieldsValue({
        ...e,
      });
      let sD = dateFormatDisplay(e.startDate * 1000, dateFormat);
      let eD = dateFormatDisplay(e.endDate * 1000, dateFormat);
      let mSd = moment(sD, dateFormat);
      let mEd = moment(eD, dateFormat);
      setDateRange([mSd, mEd]);
      setAvailDate(e.startDate);
      setAvailDate1(e.endDate);
      setAvailTime(e.timeSlots);
      setFormType('edit');
      setDateRangeValication([]);
    }
    setShowModal(true);
  };

  const onModalClose = () => {
    setShowModal(false);
    setDateRange([]);
    setAvailDate([]);
    setAvailDate1([]);
    setAvailTime([]);
    setFormType('add');
    setDateRangeValication(dateRule);
    form.resetFields();
  }

  const onTimeCheck = e => {
    setAvailTime(e);
  };

  const onDateCheck = e => {
    let sdate = covertTimestamp(e[0]._d);
    let edate = covertTimestamp(e[1]._d);
    setAvailDate(sdate);
    setAvailDate1(edate);
  };

  const onDeleteAvalablity = async values => {
    confirm({
      title: 'Are you sure you want to delete availability?',
      onOk: () => {
        deleteAvalablity(values);
      },
      onCancel() { },
    });
  };

  const deleteAvalablity = e => {
    FirebaseCoaches.deleteAvail(e.id);
    message.success(intlMessages('coach.availability.deleted'));
  };

  const [
    availability,
    availabilityLoading,
    availabilityError,
  ] = useCollectionData(FirebaseCoaches.getCoachAvailability(profile.id), {
    idField: 'id',
    snapshotListenOptions: {
      includeMetadataChanges: true,
    },
  });

  const selectTimezone = e => {
    setSelectedOption(e)
  }

  const submitForm = async values => {
    let formData = form.getFieldsValue(['timeZone']);
    if (availDate && availDate1 && availTime) {
      setShowModal(false);
      if (formType === 'edit') {
        let { id } = form.getFieldsValue(['id']);
        values = {
          timeZone: formData.timeZone,
          startDate: availDate,
          endDate: availDate1,
          timeSlots: availTime,
          id: id
        };
        await FirebaseCoaches.updateAvailability(values);
        onModalClose();
        message.success(intlMessages('coach.availability.updated'));
      } else {
        values = {
          timeZone: formData.timeZone,
          startDate: availDate,
          endDate: availDate1,
          timeSlots: availTime,
        };
        await FirebaseCoaches.addAvailability(values);
        onModalClose();
        message.success(intlMessages('coach.availability.added'));
      }
    } else {
      alert('Please fill all fields');
    }
  };

  let extra = null;
  if (!readonly) {
    extra = ([
      <span
        className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block gx-mr-1"
        onClick={() => onModalOpen()}
        key={"web"}
      >
        <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle gx-mr-1" />
        <IntlMessages id="coach.add.availability" />
      </span>,
      // For Mobile screen
      <span
        onClick={() => onModalOpen()}
        className="gx-text-primary gx-fs-md gx-pointer gx-mb-4 gx-d-block gx-d-sm-none gx-mr-1"
        key={"mobile"}
      >
        <i className="icon icon-add-circle gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle" />
        <IntlMessages id="coach.add.availability" />
      </span>
    ]
    );

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <div>

        <div className="gx-profile-banner-bottom">
          <span className="gx-link gx-profile-setting ">{extra}</span>
        </div>
        {availabilityError && <strong>Error: {JSON.stringify(availabilityError)}</strong>}
        {availabilityLoading && <Spin indicator={spinnerIcon} />}
        {
          availability && availability.length ?
            availability.map((e, i) => (
              <AvailablityCell
                availability={e}
                onDeleteAvalablity={onDeleteAvalablity}
                onModalOpen={onModalOpen}
                key={e.id}
              />
            )) :
            !availabilityLoading &&
            <div className="text-center gx-px-3 gx-pt-3 gx-pb-3 gx-mt-3">
              <h3>You don't have any appointments!</h3>
              <Button
                variant="raised"
                className="gx-mt-3"
                type="primary"
                onClick={() => onModalOpen()}>
                <div className="ant-row-flex">
                  <IntlMessages id="coach.add.availability" />
                </div>
              </Button>
            </div>
        }
        <Modal
          title={
            formType === 'add' ? (
              <IntlMessages id="coach.add.availability" />
            ) : (
                <IntlMessages id="coach.update.availability" />
              )
          }
          visible={showModal}
          closable={true}
          onOk={() => form.submit()}
          onCancel={onModalClose}>
          <Form {...formItemLayout} form={form} onFinish={submitForm}>
            <div className="gx-modal-box-row">
              <div className="gx-modal-box-form-item">
                <div className="gx-form-group">
                  <Form.Item
                    label="Time Zone"
                    name="timeZone"
                    rules={[
                      { required: true, message: 'Please select a time zone!' },
                    ]}>
                    <Select
                      value={selectedOption}
                      defaultValue={selectedOption}
                      onChange={(e) => selectTimezone(e)}
                    />

                  </Form.Item>
                </div>

                <div className="gx-form-group">
                  <Form.Item
                    label="Available Date"
                    name="date"
                    rules={dateRangeValidation}>
                    <RangePicker style={{ width: '100%' }} defaultValue={dateRange} onChange={onDateCheck} format={dateFormat} />
                  </Form.Item>
                </div>
                <div className="gx-form-group">
                  <Form.Item
                    label="Available Time"
                    rules={[
                      {
                        required: true,
                        message: 'Please select Available Date Range',
                      }]}
                  >
                    <Checkbox.Group
                      style={{ width: '100%', height: '100%' }}
                      onChange={onTimeCheck}
                      defaultValue={availTime}
                    >
                      <Row>
                        {coachsTimeSlots}
                      </Row>
                    </Checkbox.Group>

                  </Form.Item>
                </div>
                <Button htmlType="submit" style={{ display: 'none' }}></Button>
              </div>
            </div>

            <Button htmlType="submit" style={{ display: 'none' }}></Button>
          </Form>
        </Modal>
      </div >
    );
  }
};

export default Availability;
