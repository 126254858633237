import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Badge } from 'antd/lib';
import ReactPlayer from 'react-player';
import DisplayDate from '../../util/DisplayDate';

const VlogsItems = ({
  profile,
  data,
  currentUserId,
  userId,
  onInfoUpdateClick,
  onPublishClick,
  onDeleteClick,
  vlogDetailView,
  userTags,
  likeUnlikeVideo,
}) => {
  return (
    <div className="gx-media gx-featured-item">
      <div className="gx-featured-content-left">
        <div className="gx-featured-thumb">
          <ReactPlayer
            url={data.video}
            width="100%"
            height="100%"
            controls={true}
            //light="http://placekitten.com/200/300"
            className="gx-rounded-lg gx-width-175"
          />
        </div>
      </div>

      <div className="gx-media-body gx-featured-content">
        <div className="gx-featured-content-left">
          <h3
            className="gx-mb-2 gx-pointer"
            onClick={() => vlogDetailView(data)}>
            <Link>{data.title}</Link>
          </h3>
          <p className="gx-text-grey gx-mb-1">{data.description}</p>
          <div className="ant-row-flex">
            <p className="gx-text-grey gx-mb-1">
              <i
                className={`icon icon-datepicker gx-fs-xs gx-mr-2 gx-d-inline-flex gx-vertical-align-middle`}
              />
              <DisplayDate date={data.date.toString()} />
            </p>
          </div>

          <div className="ant-row-flex ">
            <span>
              {data &&
                data.tags &&
                data.tags.map((label, index) => {
                  if (userTags && userTags.length) {
                    for (var tags of userTags) {
                      if (tags.tag.title === label.title) {
                        label = tags.tag;
                        break;
                      }
                    }
                  }
                  return (
                    <Badge
                      key={index}
                      count={label.title}
                      style={{ backgroundColor: label.color }}
                    />
                  );
                })}
            </span>
          </div>

          <div className="ant-row-flex" style={{ cursor: 'pointer' }}>
            <p className="gx-text-grey gx-mb-1">
              <span>
                {data.likes.includes(currentUserId) ? (
                  <i
                    className={`iclassName="icon icon-like gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle"`}
                    style={{ color: 'primary' }}
                    onClick={() => likeUnlikeVideo(data)}
                  />
                ) : (
                    <i
                      className={`icon icon-like-o gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle`}
                      onClick={() => likeUnlikeVideo(data)}
                    />
                  )}
                {data.likes.length > 1
                  ? `${data.likes.length} Likes`
                  : `${data.likes.length} Like`}
              </span>
            </p>

            <Link
              className="gx-text-grey gx-ml-4 gx-mb-1"
              onClick={() => vlogDetailView(data)}>
              <span style={{ float: 'right' }}>
                <i className="icon icon-chat-bubble gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle" />
                {data.commentCount
                  ? data.commentCount > 1
                    ? `${data.commentCount} Comments`
                    : `${data.commentCount} Comment`
                  : `0 Comment`}
              </span>
            </Link>
          </div>
        </div>

        <div className="gx-featured-content-right">
          <p>
            <Button
              className="gx-ml-auto gx-mb-0 gx-btn-block "
              onClick={() => vlogDetailView(data)}>
              View Details
            </Button>
          </p>
          {currentUserId === userId ? (
            <span>
              <p>
                <Button
                  className="gx-ml-auto gx-mb-0 gx-btn-block"
                  onClick={() => onInfoUpdateClick(data)}>
                  Edit Details
                </Button>
              </p>

              {data.published === false ? (
                <p>
                  <Button
                    type="primary"
                    className="gx-ml-auto gx-mb-0 gx-btn-block"
                    onClick={() => onPublishClick(data)}>
                    Publish
                  </Button>
                </p>
              ) : (
                  <p>
                    <Button
                      type="primary"
                      className="gx-ml-auto gx-mb-0 gx-btn-block"
                      onClick={() => onPublishClick(data)}>
                      Unpublish
                  </Button>
                  </p>
                )}
              <p>
                <Button
                  type="danger"
                  className="gx-ml-auto gx-mb-0 gx-btn-block"
                  onClick={() => onDeleteClick(data)}>
                  Delete
                </Button>
              </p>
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default VlogsItems;
