import React, {useState, useRef} from 'react';
import {Modal, Form, Input, Button, Tag, message, Card} from 'antd/lib';
import VideoRecorder from 'react-video-recorder';
import FirebaseVlogs from 'firebase/firebaseVlogs';
import {FirebaseDAO} from 'firebase/firebase';
import {useCollectionData} from 'react-firebase-hooks/firestore';
import Aux from 'util/Auxiliary.js';
import WidgetHeader from 'components/WidgetHeader/index';
import IntlMessages from 'util/IntlMessages';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';
import {tagColors} from '../../constants/staticData';
import VlogList from './vlogList';
import VlogDetail from './vlogDetail';

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
  },
};

const Vlogs = ({profile, userId}) => {
  const videoPlayerEl = useRef(null);
  const {confirm} = Modal;
  const [form] = Form.useForm();
  const intlMessages = useFormatMessage();
  const [showModal, setShowModal] = useState(false);
  const currentUserId = FirebaseVlogs.auth.currentUser.uid;
  const [tags, setTags] = useState([]);
  const [tagInputVisible, setTagInputVisible] = useState(false);
  const [tagValue, setTagValue] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [formMode, setFormMode] = useState('add');
  const [okButtonStatus, setOkButtonStatus] = useState(true);
  const [publishStatus, setPublishStatus] = useState(false);
  const [detailView, setDetailView] = useState(false);
  const [detailViewData, setDetailViewData] = useState(null);
  const [viewTitle, setViewTitle] = useState('Vlogs');
  const [isOnInitially, setIsOnInitially] = useState(true);

  const [vlogLook] = useCollectionData(FirebaseVlogs.getusersVlogs(userId), {
    idField: 'id',
    snapshotListenOptions: {
      includeMetadataChanges: true,
    },
  });

  const [userTags] = useCollectionData(
    FirebaseDAO.getFBCollectionData('tags'),
    {
      idField: 'id',
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const actionHandler = e => {};

  const handleRecordingComplete = async blob => {
    setIsOnInitially(false);
    setVideoUrl(blob);
    setOkButtonStatus(false);
    // setUpoadingStatus('block');
    //let name = new Date().getTime();
    //let videoLink = await FirebaseVlogs.uploadVlogVideo(blob, name);
    // setUploadStarted(false);
  };

  const addVideoModal = () => {
    setPublishStatus(false);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    if (videoPlayerEl && formMode === 'add') {
      videoPlayerEl.current.handleStopReplaying();
    }
    setVideoUrl(null);
    setTags([]);
    setOkButtonStatus(true);
    form.resetFields();
    setTimeout(() => {
      setFormMode('add');
    }, 500);
  };

  const saveVlog = async () => {
    let formValues = form.getFieldsValue(['title', 'description']);
    setShowModal(false);

    if (formMode === 'add') {
      videoPlayerEl.current.handleStopReplaying();
      formValues = {
        ...formValues,
        published: publishStatus,
        date: new Date().toString(),
        tags: tags,
        description: formValues.description ? formValues.description : '',
        likes: [],
        video: videoUrl,
        commentCount: 0,
      };
      message.success(intlMessages('vlog.add.success'));
      await FirebaseVlogs.saveVlogPost(formValues);
    } else if (formMode === 'edit') {
      message.success(intlMessages('vlog.update.success'));
      let {id} = form.getFieldsValue(['id']);
      formValues = {
        ...formValues,
        published: publishStatus,
        date: new Date().toString(),
        tags: tags,
        description: formValues.description ? formValues.description : '',
      };
      formValues = {
        ...formValues,
        id,
      };
      await FirebaseVlogs.updateVlogInfo(formValues);
      setFormMode('add');
    }

    form.resetFields();
    setTags([]);
    setTagValue(null);
    setVideoUrl(null);
    setOkButtonStatus(true);
    //setUploadStarted(false);
    // setUpoadingStatus('none');
  };

  const handleClose = removedTag => {
    const newTags = tags.filter(tag => tag !== removedTag);
    setTags(newTags);
  };

  const forMap = tag => {
    const tagElem = (
      <Tag
        closable
        onClose={e => {
          e.preventDefault();
          handleClose(tag);
        }}
        color={tag.color}>
        {tag.title}
      </Tag>
    );
    return (
      <span key={tag.title} style={{display: 'inline-block'}}>
        {tagElem}
      </span>
    );
  };

  const tagChild = tags ? tags.map(forMap) : null;

  const handleInputChange = e => {
    setTagValue(e.target.value.toLowerCase());
  };

  const handleInputConfirm = () => {
    let color = tagColors[Math.ceil(Math.random() * 30)];
    let alltags = tags ? tags : [];

    if (
      tagValue &&
      alltags.findIndex(tagObject => tagObject.title === tagValue) === -1
    ) {
      alltags = [...alltags, {title: tagValue, color: color}];
    }
    setTags(alltags);
    setTagInputVisible(false);
    setTagValue(null);
  };

  const onInfoUpdateClick = async values => {
    setFormMode('edit');
    setOkButtonStatus(false);
    setTags(values.tags);
    form.resetFields();
    form.setFieldsValue(values);
    setShowModal(true);
  };

  const onPublishClick = async values => {
    await FirebaseVlogs.updatePublishStatus(values);
    values.published
      ? message.success(intlMessages('vlog.unpublish.success'))
      : message.success(intlMessages('vlog.publish.success'));
  };

  const onDeleteClick = async values => {
    confirm({
      title: 'Are you sure you want to delete this vlog?',
      onOk: () => {
        deleteVlog(values);
        message.success(intlMessages('vlog.delete.success'));
      },
      onCancel() {},
    });
  };

  const deleteVlog = async values => {
    setDetailView(false);
    await FirebaseVlogs.deleteVlog(values);
  };

  const vlogDetailView = data => {
    setViewTitle(data.title);
    setDetailView(true);
    setDetailViewData(data);
  };

  const backToList = () => {
    setViewTitle('Vlogs');
    setDetailView(false);
    setDetailViewData(null);
  };

  const likeUnlikeVideo = async video => {
    let likes = video.likes;
    if (likes.includes(currentUserId)) {
      likes = likes.filter(item => item !== currentUserId);
    } else {
      likes.push(currentUserId);
    }
    await FirebaseVlogs.likeUnLikeVideo({
      id: video.id,
      userId: userId,
      likes: likes,
    });
  };

  return (
    <Aux>
      <WidgetHeader
        styleName="gx-flex-row"
        title={viewTitle}
        extra={
          currentUserId === userId ? (
            <span onClick={() => addVideoModal()}>
              {' '}
              <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle gx-mr-1" />{' '}
              <IntlMessages id="vlogs.newVlog" />
            </span>
          ) : (
            <span></span>
          )
        }
      />
      {currentUserId === userId ? (
        <span
          onClick={() => setShowModal(true)}
          className="gx-text-primary gx-fs-md gx-pointer gx-mb-4 gx-d-block gx-d-sm-none">
          <i className="icon icon-add-circle gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle" />{' '}
          <IntlMessages id="vlogs.newVlog" />
        </span>
      ) : (
        <span></span>
      )}

      {detailView ? (
        <VlogDetail
          vlog={detailViewData}
          profile={profile}
          currentUserId={currentUserId}
          userId={userId}
          backToList={backToList}
          userTags={userTags}
          likeUnlikeVideo={likeUnlikeVideo}
          onInfoUpdateClick={onInfoUpdateClick}
          onDeleteClick={onDeleteClick}></VlogDetail>
      ) : (
        <div>
          {vlogLook && vlogLook.length > 0 ? (
            <VlogList
              vlogLook={vlogLook}
              currentUserId={currentUserId}
              userId={userId}
              profile={profile}
              onInfoUpdateClick={onInfoUpdateClick}
              onPublishClick={onPublishClick}
              onDeleteClick={onDeleteClick}
              vlogDetailView={vlogDetailView}
              userTags={userTags}
              likeUnlikeVideo={likeUnlikeVideo}
            />
          ) : currentUserId === userId ? (
            <Card>
              <div className="text-center">
                <h2>You don't have any videos yet. Let's add one!</h2>
                <Button
                  variant="raised"
                  className="gx-mt-3"
                  type="primary"
                  onClick={() => setShowModal(true)}>
                  <div className="ant-row-flex">
                    <IntlMessages id="vlogs.newVlog" />
                  </div>
                </Button>
              </div>
            </Card>
          ) : (
            <Card>
              <div className="text-center">
                <h2>This user hasn't posted any vlogs!</h2>
              </div>
            </Card>
          )}
        </div>
      )}

      <Modal
        title={
          formMode === 'add' ? (
            <IntlMessages id="vlogs.addVlog" />
          ) : (
            <IntlMessages id="vlogs.editVlog" />
          )
        }
        visible={showModal}
        closable={true}
        // onOk={() => form.submit()}
        onCancel={() => closeModal()}
        //okButtonProps={{ disabled: okButtonStatus }}
        footer={
          formMode === 'add'
            ? [
                <Button key="cancel" onClick={() => closeModal()}>
                  Cancel
                </Button>,
                <Button
                  key="publish"
                  onClick={() => {
                    setPublishStatus(true);
                    form.submit();
                  }}
                  disabled={okButtonStatus}>
                  Publish Vlog
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={() => form.submit()}
                  disabled={okButtonStatus}>
                  Save as Draft
                </Button>,
              ]
            : [
                <Button key="cancel" onClick={() => closeModal()}>
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={() => form.submit()}
                  disabled={okButtonStatus}>
                  Update Vlog
                </Button>,
              ]
        }>
        <div className="gx-modal-box-form-item">
          {formMode === 'add' ? (
            <div style={{maxHeight: '200'}}>
              <VideoRecorder
                ref={videoPlayerEl}
                isOnInitially={isOnInitially}
                isReplayingVideo={false}
                onStartRecording={actionHandler}
                onStopRecording={actionHandler}
                onRecordingComplete={handleRecordingComplete}
              />
              <p></p>
            </div>
          ) : null}
          <Form {...formItemLayout} form={form} onFinish={saveVlog}>
            <div className="gx-modal-box-row">
              <div className="gx-modal-box-form-item">
                <div className="gx-form-group">
                  <Form.Item
                    label="Title"
                    name="title"
                    rules={[
                      {
                        required: true,
                        type: 'string',
                        message: 'Please enter a title',
                      },
                    ]}>
                    <Input required placeholder="Title" />
                  </Form.Item>
                </div>
                <div className="gx-form-group">
                  <Form.Item label="Description" name="description">
                    <Input required placeholder="Description" />
                  </Form.Item>
                </div>
                <div className="gx-form-group">
                  <Form.Item label="Tags" name="tags">
                    <div>
                      {tagInputVisible && (
                        <Input
                          type="text"
                          size="small"
                          style={{width: 78}}
                          value={tagValue}
                          onChange={handleInputChange}
                          onBlur={handleInputConfirm}
                          onPressEnter={handleInputConfirm}
                        />
                      )}
                      {!tagInputVisible && (
                        <Tag
                          onClick={() => setTagInputVisible(true)}
                          style={{background: '#fff', borderStyle: 'dashed'}}>
                          + New Tag
                        </Tag>
                      )}
                    </div>
                  </Form.Item>
                  <div>{tagChild}</div>
                </div>
                <Button htmlType="submit" style={{display: 'none'}}></Button>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </Aux>
  );
};

export default Vlogs;
