import React from 'react';
import {history} from 'appRedux/store';
import {Button, Card, Row} from 'antd';

const GuestRedirect = () => {
  return (
    <Card styleName="gx-card-full gx-text-center">
      <Row justify="center">
        <div className="gx-media gx-wall-user-info gx-flex-nowrap gx-align-items-center gx-mb-0 gx-mt-0">
          In order to view this content, please sign in to your account, or
          create an account.
        </div>
      </Row>
      <Row justify="center" className="gx-mt-4">
        <Button onClick={() => history.push('/signin')}>Sign In</Button>
        <Button onClick={() => history.push('/signup')} type="primary">
          Sign Up
        </Button>
      </Row>
    </Card>
  );
};

export default GuestRedirect;
