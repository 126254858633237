import React from 'react';
import { Dropdown, Menu, Tag } from 'antd/lib';
import { dateFormatDisplay } from 'util/commonUtils';

const AvailablityCell = ({ availability, onModalOpen, onDeleteAvalablity }) => {

    const options = ['Edit', 'Delete'];

    const menus = () => (
        <Menu
            onClick={e => {
                if (e.key === 'Edit') {
                    onModalOpen(availability);
                } else {
                    onDeleteAvalablity(availability);
                }
            }}
        >
            {options.map(option => (
                <Menu.Item key={option}>{option}</Menu.Item>
            ))}
        </Menu>
    );

    return (
        <div className="gx-contact-item">

            <div className="gx-module-list-info gx-contact-list-info" style={{ maxWidth: "100%" }}>
                <div className="gx-module-contact-content">
                    <p className="gx-mb-1">
                        <span className="gx-text-muted" >Start Date</span>
                        <span className="gx-toolbar-separator">&nbsp;</span>
                        <span className="gx-text-truncate gx-contact-name"> {dateFormatDisplay(availability.startDate * 1000)} </span>
                    </p>
                    <p className="gx-mb-1 ">
                        <span className="gx-text-muted" >End Date</span>
                        <span className="gx-toolbar-separator">&nbsp;</span>
                        <span className="gx-text-truncate gx-contact-name"> {dateFormatDisplay(availability.endDate * 1000)} </span>
                    </p>
                    <p className="gx-mb-1 ">
                        <span className="gx-text-muted" >Availablity</span>
                        <span className="gx-toolbar-separator">&nbsp;</span>
                        <span className="gx-text-truncate gx-contact-name">
                            {availability && availability.timeSlots && availability.timeSlots.map((k, v) => (
                                <Tag style={{ marginBottom: 5 }} color="#108ee9" key={v}>
                                    {k}{' '}
                                </Tag>
                            ))}
                        </span>
                    </p>
                    <p className="gx-mb-1 ">
                        <span className="gx-text-muted" >Time Zone</span>
                        <span className="gx-toolbar-separator">&nbsp;</span>
                        <span className="gx-text-truncate gx-contact-name">
                            {availability && availability.timeZone ? availability.timeZone.label : "GMT-00:00"}
                        </span>
                    </p>

                </div>

                <div className="gx-module-contact-right">
                    <Dropdown
                        overlay={() => menus()}
                        placement="bottomRight"
                        trigger={['click']}
                        key={availability.id}
                    >
                        <i className="gx-icon-btn icon icon-ellipse-v" />
                    </Dropdown>

                </div>

            </div>
        </div>

    );
};

export default AvailablityCell;
