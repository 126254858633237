import React from 'react';
import {useCollectionData} from 'react-firebase-hooks/firestore';
import FirebaseSocial from 'firebase/firebaseSocial';
import SocialDisplayList from '../displayList/SocialDisplayList';
import GuestRedirect from 'containers/GuestRedirect';

const SocialList = ({
  followerView,
  followingView,
  friendView,
  userId,
  guest,
}) => {
  const [followerLook, followerLoading, followerError] = useCollectionData(
    FirebaseSocial.getUserFollowers(userId),
    {
      idField: 'id',

      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [followingLook, followingLoading, followingError] = useCollectionData(
    FirebaseSocial.getUserFollowing(userId),
    {
      idField: 'id',

      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [friendOneLook, friendOneLoading, friendOneError] = useCollectionData(
    FirebaseSocial.getUserFriendOne(userId),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [friendTwoLook, friendTwoLoading, friendTwoError] = useCollectionData(
    FirebaseSocial.getUserFriendTwo(userId),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  if (guest) {
    return <GuestRedirect />;
  } else {
    let display = null;
    if (followerView && followerLook) {
      let followerArray = [];
      followerLook.forEach(followPair => {
        followerArray.push(followPair.follower);
      });
      display = followerArray;
    } else if (followingView && followingLook) {
      let followingArray = [];
      followingLook.forEach(followPair => {
        followingArray.push(followPair.following);
      });
      display = followingArray;
    } else if (friendView && friendOneLook && friendTwoLook) {
      let friendArray = [];
      friendOneLook.forEach(friendPair => {
        friendArray.push(friendPair.friendOne);
      });
      friendTwoLook.forEach(friendPair => {
        friendArray.push(friendPair.friendTwo);
      });
      display = friendArray;
    }

    return (
      <div>
        {(followerError ||
          followingError ||
          friendOneError ||
          friendTwoError) && <strong>Connection error!</strong>}
        {(followerLoading ||
          followingLoading ||
          friendOneLoading ||
          friendTwoLoading) && <p>Loading...</p>}
        {followerLook && followingLook && friendOneLook && friendTwoLook && (
          <SocialDisplayList display={display} userId={userId} />
        )}
      </div>
    );
  }
};

export default SocialList;
