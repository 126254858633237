import React from 'react';
import { Modal } from 'antd/lib';
import SummaryForm from '../booking/SummaryForm';
import IntlMessages from 'util/IntlMessages';
import { dateFormatDisplay } from 'util/commonUtils';

const BookingDetailModal = ({ user, service, date, slot, showModal, onModalClose, canUpdate, appointment, manageButton }) => {
    return (
        <Modal
            title={<IntlMessages id="coach.booking.detail" />}
            toggle={onModalClose}
            visible={showModal}
            closable={true}
            onCancel={onModalClose}
            destroyOnClose={true}
            footer={null}
        >
            <SummaryForm
                coach={user}
                service={service}
                date={dateFormatDisplay(date)}
                slot={slot}
                readonly={true}
                canUpdate={canUpdate}
                appointment={appointment}
                manageButton={manageButton}
            />
        </Modal>
    )
}

export default BookingDetailModal;