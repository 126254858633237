import React, {useState} from 'react';
import {useCollectionData} from 'react-firebase-hooks/firestore';
import FirebaseGroups from 'firebase/firebaseGroups';
import {Row, Col, Tabs, Button} from 'antd/lib';
import Posts from 'components/profile/Posts';
import Auxiliary from 'util/Auxiliary';
import Widget from 'components/Widget';
import MembersList from './groupMembers/membersList';
import RequestList from '../ApproveRequests/requestList';
import {Link} from 'react-router-dom';

const GroupDetailComponent = ({
  profile,
  groupData,
  memberStatus,
  sendGroupJoinRequest,
  leaveGroup,
}) => {
  const userId = profile.id;
  const TabPane = Tabs.TabPane;
  const [readonly, setReadonly] = useState(true);
  const [readOnlyPost, setReadOnlyPost] = useState(true);

  // Get Groups active members
  const [
    groupMembers,
    groupMembersLoading,
    groupMembersError,
  ] = useCollectionData(FirebaseGroups.getGroupActiveMembers(groupData.id), {
    idField: 'id',
    snapshotListenOptions: {
      includeMetadataChanges: true,
    },
  });
  // Get ReqUest of users to join Group.
  const [
    groupJoinRequests,
    groupJoinRequestsLoading,
    groupJoinRequestsError,
  ] = useCollectionData(FirebaseGroups.getGroupJoinRequests(groupData.id), {
    idField: 'id',
    snapshotListenOptions: {
      includeMetadataChanges: true,
    },
  });
  // Get invited users those are not Loanplaz users.
  const [
    groupOutSideInvitedUser,
    groupOutSideInvitedUserLoading,
    groupOutSideInvitedUserError,
  ] = useCollectionData(
    FirebaseGroups.getGroupOutSideInvitedUsers(groupData.id),
    {
      idField: 'id',
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );
  // Get Groups all post
  const [
    groupPostList,
    groupPostListLoading,
    groupPostListError,
  ] = useCollectionData(FirebaseGroups.getGroupPostList(groupData.id), {
    idField: 'id',
    snapshotListenOptions: {
      includeMetadataChanges: true,
    },
  });
  // Set Group Edit condition
  if (userId === groupData.userId && readonly) {
    setReadonly(false);
    setReadOnlyPost(false);
  }

  // Set if user can Post on Group
  if (memberStatus && memberStatus.length && readOnlyPost) {
    if (memberStatus[0].status === 1) {
      setReadOnlyPost(false);
    }
  }
  // Remove Member
  const removeMember = async (data, groupData) => {
    await FirebaseGroups.removeGroupMember(data, groupData);
  };
  // Approve or reject group join requests
  const approveRejectJoinRequest = (requstData, action, group) => {
    FirebaseGroups.approveRejectJoinRequest(requstData, action, group);
  };

  if (!profile || !groupData) {
    return null;
  } else {
    let extra = null;
    let avatarEdit = (
      <div className="gx-flex-row">
        {/* <div className="gx-profile-banner-avatar profile-pic-container">
                    <Avatar
                        src={avatar}
                        className="gx-size-120 gx-pointer gx-mb-10 gx-ml-20"
                    />
                </div> */}
        <div className="gx-profile-banner-avatar-info gx-pointer gx-vertical-align-middle">
          <h2 className="gx-mt-3 gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light">
            {groupData.groupName}
          </h2>
          <p className="gx-mb-0 gx-fs-lg">{''}</p>
          <p className="gx-mb-0 gx-fs-md">
            {memberStatus.length > 0 && memberStatus[0].memberType === 0 ? (
              <Button
                size="small"
                className="gx-ml-auto gx-mb-0 gx-btn-block"
                onClick={() => leaveGroup(memberStatus[0], groupData)}>
                {memberStatus[0].status === 1
                  ? ' Leave Group'
                  : 'Cancel Request'}
              </Button>
            ) : (
              <Button
                size="small"
                type="primary"
                className="gx-ml-auto gx-mb-0 gx-btn-block"
                onClick={() => sendGroupJoinRequest(groupData, profile.id)}>
                Join Group
              </Button>
            )}
          </p>
        </div>
      </div>
    );

    if (!readonly) {
      extra = (
        <i
          style={{position: 'absolute', right: 10, cursor: 'pointer'}}
          className="icon icon-edit gx-fs-lg gx-mr-2 gx-mr-sm-3 gx-d-inline-flex gx-vertical-align-top"
          // onClick={onModalOpen}
        />
      );

      avatarEdit = (
        <div className="gx-flex-row">
          <div
            className="gx-profile-banner-avatar profile-pic-container"
            // onClick={onModalOpen}
          >
            {/* <Avatar
                            src={avatar}
                            className="gx-size-120 gx-pointer gx-mb-10 gx-ml-20"
                        /> */}
            <div className="edit-profile-pencil gx-pointer">
              <i className="icon icon-edit gx-fs-xxxl gx-d-inline-flex gx-vertical-align-center" />
            </div>
          </div>
          <div
            // onClick={onModalOpen}
            className="gx-profile-banner-avatar-info gx-pointer gx-vertical-align-middle">
            <h2 className="gx-mt-3 gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light">
              {groupData.groupName}
            </h2>
            <p className="gx-mb-0 gx-fs-lg">{''}</p>
            <p className="gx-mb-0 gx-fs-md">Group Admin</p>
            <p className="gx-mb-0 gx-fs-md">
              <Link to={`/app/groups`}>
                <i
                  className="icon icon-arrow-left  gx-icon-btn"
                  style={{color: '#fff'}}
                />
              </Link>
            </p>
          </div>
        </div>
      );
    }

    return (
      <div>
        {(groupMembersError ||
          groupJoinRequestsError ||
          groupOutSideInvitedUserError ||
          groupPostListError) && <strong>Connection error!</strong>}
        {(groupMembersLoading ||
          groupJoinRequestsLoading ||
          groupOutSideInvitedUserLoading ||
          groupPostListLoading) && <p> Loading...</p>}
        {groupMembers &&
          groupJoinRequests &&
          groupOutSideInvitedUser &&
          groupPostList && (
            <div>
              {groupData && groupPostList && (
                <Auxiliary>
                  <div
                    className="gx-profile-banner"
                    style={{
                      backgroundImage: `url(${
                        groupData.groupCoverPhoto.name !== ''
                          ? groupData.groupCoverPhoto.downloadURL
                          : null
                      })`,
                      backgroundSize: 'cover',
                      maxHeight: 169,
                    }}>
                    <div className="gx-profile-container" style={{top: -8}}>
                      {extra}
                      <div className="gx-profile-banner-top">
                        {avatarEdit}
                        <div className="gx-profile-banner-top-right">
                          <ul className="gx-follower-list">
                            <li>
                              <span className="gx-fs-sm">Total Members</span>
                              <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">
                                {groupMembers && groupMembers.length
                                  ? groupMembers.length
                                  : 0}
                              </span>
                            </li>
                            <li>
                              <span className="gx-fs-sm">Total Posts</span>
                              <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">
                                {groupPostList && groupPostList.length
                                  ? groupPostList.length
                                  : 0}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="gx-profile-banner-bottom"
                    style={{marginTop: 72}}>
                    <Tabs defaultActiveKey="1" style={{width: '100%'}}>
                      <TabPane tab="ACTIVITY" key="1">
                        <Row>
                          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Widget
                              title="About Group"
                              styleName="gx-card-tabs gx-card-tabs-left gx-card-profile">
                              <div className="gx-mb-2">
                                <div
                                  className="gx-mb-2 gx-mt-3 gx-pointer"
                                  style={{height: 'auto'}}>
                                  {groupData.groupDescription}
                                </div>
                                <p className="gx-text-grey gx-mb-1">
                                  <i
                                    className={`icon icon-social gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle`}
                                  />
                                  {groupData.groupPrivacy === 1
                                    ? 'Public '
                                    : 'Private'}
                                </p>
                                <p className="gx-text-grey gx-mb-1">
                                  <i
                                    className={`icon icon-view gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle`}
                                  />
                                  {groupData.groupSearchable === 0
                                    ? 'Hidden'
                                    : 'Visible'}
                                </p>
                              </div>
                            </Widget>
                            <Posts
                              profile={profile}
                              postList={groupPostList}
                              readonly={readOnlyPost}
                              groupData={groupData}
                            />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tab={'MEMBERS'} key="2">
                        <MembersList
                          profile={profile}
                          groupData={groupData}
                          groupMembers={groupMembers}
                          removeMember={removeMember}
                        />
                      </TabPane>
                      {userId === groupData.userId ? (
                        <TabPane tab={'JOIN REQUESTS'} key="3">
                          <RequestList
                            group={groupData}
                            requests={groupJoinRequests}
                            approveRejectJoinRequest={approveRejectJoinRequest}
                            section={'detail'}
                          />
                        </TabPane>
                      ) : null}
                    </Tabs>
                  </div>
                </Auxiliary>
              )}
            </div>
          )}
      </div>
    );
  }
};
export default GroupDetailComponent;
