import React from 'react';
import Widget from 'components/Widget/index';
import '../widgets.less';
import { Progress } from 'antd/lib';

const LiabilityCard = ({
  loans,
  payOffDate,
  loanPortfolio,
  type,
}) => {
  let percentRatio
  if (loanPortfolio) {
    percentRatio = loanPortfolio.getLonsStatasticsByCurrentMonth(loans, type)
  }

  return (
    <Widget styleName={`gx-card-full gx-py-4 gx-px-2 gx-bg- `}>
      <div className="gx-flex-row gx-justify-content-center gx-mb-3 gx-mb-sm-4">
        <span
          className={`gx-size-80 gx-border gx-border-orange gx-text-orange gx-flex-row gx-justify-content-center gx-align-items-center gx-rounded-circle`}>
          <i className={`icon icon-calendar-new gx-fs-xlxl`} />
        </span>
      </div>
      <div className="gx-text-center">
        <h2 className={`gx-fs-xxxl gx-font-weight-medium gx-text-`}>
          {payOffDate}
        </h2>

        <p className={`gx-mb-2 gx-mb-sm-3 gx-text-`}>DEBT-FREE</p>
      </div>
      <div className="gx-text-center gx-mb-1">
        <Progress
          percent={percentRatio}
          status={percentRatio < 100 ? 'active' : 'success'}
        />
      </div>
    </Widget>
  );
};

export default LiabilityCard;
