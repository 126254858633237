import React from 'react';
import Helmet from 'react-helmet';
import CustomScrollbars from 'util/CustomScrollbars';

const CookiePolicy = props => {

  return (
    <div className="gx-legal-content-container">
      <Helmet defaultTitle="Terms of Service | loanpalz" />
        <div className="gx-app-content-wrapper">
          <div className="gx-app-content-sidebar-wrapper">
            <div className="gx-app-logo-content-bg"></div>

            <div className="gx-content-sidebar">
              <h1>Cookie Policy</h1>
              <p>Welcome to loanpalz!</p>
              <p>Loanpalz builds digital products that enable people to transform their financial life, connect with each other, get help from professional coaches, and ultimately gain financial freedom. These Terms govern your use of loanpalz features, apps, services, technologies, and software we offer, except where we expressly state that separate terms (and not these) apply. These Products are provided to you by Loanpalz, Inc.</p>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src={require('assets/images/loanpalz-logo.png')} />
            </div>
          </div>
          
          <div className="gx-content-main">
            <CustomScrollbars>
              <h1 style={{marginTop: 0}}>Loanpalz Cookie Policy</h1>
              
              <p>Welcome to our Cookie Policy. We’re grateful for your interest in the Loanpalz community. Loanpalz Inc. (“Loanpalz”, "us", "we", or "our") operates this website ( https://www.Loanpalz.com ) and other services that link to this Cookie Policy (collectively referred to as the “Services”). We believe you should fully understand how we collect and use data, and this policy outlines how we use cookies for these purposes. Some terms used in this policy have been defined in our Privacy Policy, which includes additional information about how we collect and use data at Loanpalz.</p>
              
              <h1>What is a Cookie?</h1>
              <p>Cookies are files with small amounts of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies that may also be used are beacons, tags, and scripts to collect and track information and to provide, improve, and analyze our Services. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. You can also delete individual cookies from your browser. However, if you do not accept or if you delete cookies, you may not be able to use some portions of our Services.</p>

              <h1>How We Use Cookies</h1>
              <p>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information. Some cookies are associated with your account and personal information in order to personalize and provide our Services. All cookies are internal (i.e., first-party), rather than third-party, and are only used when you have signed up for our Services.</p>

              <h5>Effective: Jan 1, 2020</h5>
            </CustomScrollbars>
          </div>
      </div>
    </div>
  );
};

export default CookiePolicy;
