import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {Spin} from 'antd/lib';
import App from './app/index';
import * as firebase from 'firebase/app';
import {useAuthState} from 'react-firebase-hooks/auth';
import {FaSpinner} from 'react-icons/fa';

const spinnerIcon = <FaSpinner size="3" />;

const RouteApp = ({match}) => {
  const [user, initializing] = useAuthState(firebase.auth());

  if (initializing) {
    return (
      <div
        style={{
          width: '100%',
          boxSizing: 'border-box',
          padding: '3rem',
          justifyContent: 'center',
          display: 'flex',
        }}>
        <Spin indicator={spinnerIcon} />
      </div>
    );
  } else {
    if (user) {
      return (
        <div className="gx-main-content-wrapper">
          <Switch>
            <Route path={`${match.url}app`} component={App} />
          </Switch>
        </div>
      );
    } else {
      return null;
    }
  }
};

export default RouteApp;
