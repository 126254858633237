import React, {useState} from 'react';
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Bar,
  BarChart,
} from 'recharts';
import {Link} from 'react-router-dom';
import {Select} from 'antd/lib';
import numeral from 'numeral';
import moment from 'moment';
import Widget from 'components/Widget/index';
import {LoanComparisonCustomTooltip} from 'util/chartUtils';

const Option = Select.Option;
const monthFormat = 'MM/YYYY';

const KitAggregateChart = ({loan, loanCalc, newLoanCalc}) => {
  let data = [];
  let newData = [];
  const [loanFreq, setLoanFreq] = useState('monthly');

  function handleChange(value) {
    setLoanFreq(value);
  }

  const calculateMonthyPayments = loan => {
    const amortization = loanCalc;

    switch (loanFreq) {
      case 'monthly':
        amortization.forEach(dataElem => {
          data.push({
            name: moment(new Date(dataElem.date), monthFormat).format(
              monthFormat,
            ),
            balance: dataElem.outstandingBalance,
          });
        });
        break;

      case 'quarterly':
        let i = 0;
        amortization.forEach(dataElem => {
          if (i % 3 === 0) {
            data.push({
              name: moment(new Date(dataElem.date), monthFormat),
              balance: dataElem.outstandingBalance,
            });
          }
          i++;
        });
        break;

      case 'yearly':
        let j = 0;
        amortization.forEach(dataElem => {
          if (j % 12 === 0) {
            data.push({
              name: moment(new Date(dataElem.date), monthFormat),
              balance: dataElem.outstandingBalance,
            });
          }
          j++;
        });
        break;

      default:
        break;
    }
    // console.log('Calc monthly payments: ', data);
  };

  const calculateNewMonthyPayments = (loan, extraPayment) => {
    const amortization = newLoanCalc;

    switch (loanFreq) {
      case 'monthly':
        amortization.forEach(dataElem => {
          newData.push({
            name: moment(new Date(dataElem.date), monthFormat).format(
              monthFormat,
            ),
            balance: dataElem.outstandingBalance,
          });
        });
        break;

      case 'quarterly':
        let i = 0;
        amortization.forEach(dataElem => {
          if (i % 3 === 0) {
            newData.push({
              name: moment(new Date(dataElem.date), monthFormat),
              balance: dataElem.outstandingBalance,
            });
          }
          i++;
        });
        break;

      case 'yearly':
        let j = 0;
        amortization.forEach(dataElem => {
          if (j % 12 === 0) {
            newData.push({
              name: moment(new Date(dataElem.date), monthFormat),
              balance: dataElem.outstandingBalance,
            });
          }
          j++;
        });
        break;

      default:
        break;
    }
    // console.log('Calc monthly payments: ', data);
  };

  calculateMonthyPayments(loan);
  calculateNewMonthyPayments(loan, 100);

  const combineDataSets = (currentSet, newSet) => {
    let combined = [];
    currentSet.forEach((elem, index) => {
      combined.push({
        name: elem.name,
        current: elem.balance,
        new: newSet[index] ? newSet[index].balance : 0,
      });
    });

    return combined;
  };

  const combinedData = combineDataSets(data, newData);

  return (
    <Widget styleName="gx-card-full">
      <div className="ant-row-flex gx-px-4 gx-pt-4">
        <h2 className="h4 gx-mb-3">Accelerated Payoff Chart</h2>
        <div className="gx-ml-auto">
          <Select
            className="gx-mb-2 gx-select-sm"
            defaultValue="monthly"
            onChange={handleChange}
            style={{width: '100px'}}>
            <Option value="monthly">Monthly</Option>
            <Option value="quarterly">Quarterly</Option>
            <Option value="yearly">Yearly</Option>
          </Select>
        </div>
      </div>
      <div className="ant-row-flex gx-px-4 gx-mb-3">
        <h5>
          Starting balance: {numeral(loan.amount).format('$0,0')}
          <span className=" gx-ml-1">
            (
            <Link to="/app/loans">
              <span className="gx-link">Change Loan Values</span>
            </Link>
            )
          </span>
        </h5>
      </div>

      {loanFreq === 'monthly' && (
        <ResponsiveContainer width="100%" height={360}>
          <AreaChart
            data={combinedData}
            margin={{top: 0, right: 10, left: 10, bottom: 0}}>
            <Tooltip content={<LoanComparisonCustomTooltip />} />
            <XAxis dataKey="name" />
            <defs>
              <linearGradient id="color15" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#38AAE5" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#F5FCFD" stopOpacity={0.8} />
              </linearGradient>
            </defs>
            <Area
              dataKey="current"
              type="monotone"
              fillOpacity={1}
              strokeWidth={2}
              stackId="2"
              stroke="#003366"
              fill="#003366"
            />
            <Area
              dataKey="new"
              type="monotone"
              fillOpacity={1}
              strokeWidth={2}
              stackId="1"
              stroke="#59AA2B"
              fill="#59AA2B"
            />
          </AreaChart>
        </ResponsiveContainer>
      )}

      {(loanFreq === 'quarterly' || loanFreq === 'yearly') && (
        <ResponsiveContainer width="100%" height={180}>
          <BarChart
            data={data}
            margin={{top: 0, right: 10, left: 10, bottom: 0}}>
            <XAxis dataKey="name" />
            <YAxis />
            <defs>
              <linearGradient id="color15" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#38AAE5" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#F5FCFD" stopOpacity={0.8} />
              </linearGradient>
            </defs>
            <Tooltip />
            <Bar dataKey="balance" stackId="a" fill="url(#color15)" />
          </BarChart>
        </ResponsiveContainer>
      )}
    </Widget>
  );
};

export default KitAggregateChart;
