import React, {useState, useEffect} from 'react';
import moment from 'moment';
import _ from 'lodash';
import {Input, Modal, Button, Form, message, DatePicker, Tag} from 'antd';
import IntlMessages from 'util/IntlMessages';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';
import FirebaseTasks from 'firebase/firebaseTasks';
import {tagColors} from 'constants/staticData';

const dayFormat = 'D-MMM-YYYY';
const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
  },
};

const TaskForm = ({task, tagss, formMode, showModal, dispatch}) => {
  const [form] = Form.useForm();
  const [tags, setTags] = useState(null);
  const [tagValue, setTagValue] = useState(null);
  const [tagInputVisible, setTagInputVisible] = useState(false);

  useEffect(() => {
    setTags(_.cloneDeep(tagss));
  }, [tagss]);

  const intlMessages = useFormatMessage();

  const forMap = tag => {
    return (
      <Tag
        key={tag.title}
        closable
        onClose={e => {
          e.preventDefault();
          handleClose(tag);
        }}
        color={tag.color}>
        {tag.title}
      </Tag>
    );
  };

  const tagChild = tags ? tags.map(forMap) : null;
  const handleClose = removedTag => {
    const newTags = tags.filter(tag => tag !== removedTag);
    setTags(newTags);
  };

  const handleInputChange = e => {
    setTagValue(e.target.value.toLowerCase());
  };

  const handleInputConfirm = () => {
    let color = tagColors[Math.ceil(Math.random() * 30)];
    let alltags = tags ? tags : [];
    if (
      tagValue &&
      alltags.findIndex(tagObject => tagObject.title === tagValue) === -1
    ) {
      alltags = [...alltags, {title: tagValue, color: color}];
    }
    setTags(alltags);
    setTagInputVisible(false);
    setTagValue(null);
  };

  const onFinish = async values => {
    values = {
      ...values,
      date: Math.floor(
        new Date(values.date.format(dayFormat)).getTime() / 1000,
      ),
      tags: tags,
      description: values.description ?? '',
    };

    dispatch({type: 'hideModal'});

    if (formMode === 'add') {
      await FirebaseTasks.addTask(values);
      message.success(intlMessages('task.add.success'));
    } else if (formMode === 'edit') {
      let {id} = task;
      values = {
        ...values,
        id,
      };
      await FirebaseTasks.updateTask(values);
    }
    form.resetFields();
    setTagValue(null);
  };

  if (formMode === 'edit' && task) {
    form.setFieldsValue({
      ...task,
      date: moment(new Date(task.date * 1000), dayFormat),
    });
  }

  function disabledDate(current) {
    return current && current < moment().startOf('day');
  }

  return (
    <Modal
      title={
        formMode === 'add' ? (
          <IntlMessages id="tasks.addTask" />
        ) : (
          <IntlMessages id="tasks.editTask" />
        )
      }
      visible={showModal}
      closable={true}
      onOk={() => form.submit()}
      onCancel={() => dispatch({type: 'hideModal'})}>
      <Form {...formItemLayout} form={form} onFinish={onFinish}>
        <Form.Item
          label="Task name"
          name="title"
          rules={[{required: true, message: 'Please enter a task title'}]}>
          <Input placeholder="Task name" />
        </Form.Item>

        <Form.Item
          label="Due date"
          name="date"
          rules={[{required: true, message: 'Please select a date'}]}>
          <DatePicker
            disabledDate={disabledDate}
            placeholder="Due date"
            margin="normal"
          />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: false,
              message: 'Please enter a task description',
            },
          ]}>
          <Input placeholder="Description" margin="normal" />
        </Form.Item>

        <Form.Item label="Tags" name="tags">
          <div>
            {tagInputVisible && (
              <Input
                type="text"
                size="small"
                style={{width: 78}}
                value={tagValue}
                onChange={handleInputChange}
                onBlur={handleInputConfirm}
                onPressEnter={handleInputConfirm}
              />
            )}
            {!tagInputVisible && (
              <Tag
                onClick={() => setTagInputVisible(true)}
                style={{background: '#fff', borderStyle: 'dashed'}}>
                + New Tag
              </Tag>
            )}
          </div>
        </Form.Item>
        <span>{tagChild}</span>
        <Button htmlType="submit" style={{display: 'none'}}></Button>
      </Form>
    </Modal>
  );
};

export default TaskForm;
