import {useFormatMessage} from '@comparaonline/react-intl-hooks';
import React, {useState} from 'react';
import {
  Card,
  Table,
  Button,
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  InputNumber,
  message,
} from 'antd/lib';
import numeral from 'numeral';
import ToggleBox from 'components/app/togglebox';
import IntlMessages from 'util/IntlMessages';
import moment from 'moment';
import FirebaseFin from 'firebase/firebaseFin';
import {Loan} from 'loanpalz-constants';

const {confirm} = Modal;
const {Option} = Select;
const {MonthPicker} = DatePicker;
const monthFormat = 'MM/YYYY';
const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
  },
};

const AppliedKits = ({kits, getAppliedAccelerators, loan}) => {
  const [showModal, setShowModal] = useState(false);
  const intlMessages = useFormatMessage();
  const [form] = Form.useForm();

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      render: text => <span className="gx-link">{text}</span>,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Interest Savings',
      dataIndex: 'interestSavings',
      key: 'interestsavings',
      render: (text, record) => (
        <span>{numeral(record.interestSavings).format('$0,0')}</span>
      ),
    },
    {
      title: 'Time Savings',
      dataIndex: 'timeSavingsIndex',
      key: 'timesavingsIndex',
      render: (text, record) => <span>{record.timeSavings} months</span>,
    },
    {
      title: 'Enable',
      dataIndex: 'enabledisable',
      key: 'enabledisable',
      render: (text, record) => (
        <span>
          <ToggleBox
            id={record.objid}
            key={record.objid}
            label={null}
            state={record.enabled}
            onCheckBoxClick={onCheckBoxClick}
            orientation="horizontal"
            section={'kit'}
            record={record}
          />
        </span>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Button type="default" onClick={() => onEditKits(record)}>
            <i className="icon icon-edit gx-fs-lg gx-d-inline-flex gx-vertical-align-middle gx-mr-1" />
          </Button>
          <Button type="danger" onClick={() => onDeleteAccelerator(record)}>
            <i className="icon icon-trash gx-fs-lg gx-d-inline-flex gx-vertical-align-middle gx-mr-1" />
          </Button>
        </span>
      ),
    },
  ];

  const onCheckBoxClick = async values => {
    await FirebaseFin.updateAcceleratorData(values);
    message.success(intlMessages('wizard.financials.income.update.success'));
    getAppliedAccelerators();
  };

  const onEditKits = record => {
    record.startDate = moment(record.startDate, monthFormat);
    form.resetFields();
    form.setFieldsValue({
      ...record,
    });
    setShowModal(true);
  };

  const handleSubmitValidation = () => {
    form.validateFields((err, values) => {
      if (err) {
        console.log('Form validation failed');
      }
    });
  };

  const handleResetFormModal = () => {
    setShowModal(false);
    form.resetFields();
  };

  const onFormSubmit = async () => {
    let formValues = form.getFieldsValue([
      'name',
      'type',
      'startDate',
      'amount',
    ]);
    let startMonth = moment(formValues.startDate, monthFormat).format(
      monthFormat,
    );
    const currentLoan = new Loan(
      loan.interest,
      loan.term,
      loan.amount,
      startMonth,
    );
    const newLoan = new Loan(
      loan.interest,
      loan.term,
      loan.amount,
      startMonth,
      0,
      [{...formValues, enabled: true}],
    );
    let interestSavings =
      currentLoan.getTotalInterest() - newLoan.getTotalInterest();
    let timeSavings = currentLoan.getTotalMonths() - newLoan.getTotalMonths();

    console.log('interestSavings=>', interestSavings);
    let {objid} = form.getFieldsValue(['objid']);
    formValues = {
      ...formValues,
      startDate: startMonth,
      objid,
      interestSavings,
      timeSavings,
    };

    await FirebaseFin.updateAcceleratorData(formValues);
    message.success(intlMessages('kit.accelerator.update'));
    form.resetFields();
    getAppliedAccelerators();
    setShowModal(false);
  };

  const onDeleteAccelerator = record => {
    confirm({
      title: 'Are you sure you want to delete this Accelerator ?',
      onOk: () => {
        deleteAccelerator(record);
      },
      onCancel() {},
    });
  };

  const deleteAccelerator = async record => {
    await FirebaseFin.deleteAcceleratorData(record);
    message.success(intlMessages('kit.accelerator.delete'));
    getAppliedAccelerators();
  };

  return (
    <div>
      <Card title="Applied Accelerators">
        <Table
          className="gx-table-responsive"
          pagination={{pageSize: 5}}
          scroll={{y: 240}}
          columns={columns}
          dataSource={kits}
          rowKey="objid"
        />
      </Card>

      <Modal
        title={<IntlMessages id="kit.accelerator.update.title" />}
        visible={showModal}
        closable={true}
        onOk={() => form.submit()}
        onSubmit={() => handleSubmitValidation()}
        onCancel={handleResetFormModal}>
        <Form
          {...formItemLayout}
          form={form}
          onSubmit={handleSubmitValidation}
          onFinish={onFormSubmit}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                type: 'string',
                message: 'Please enter an accelerator name',
              },
            ]}>
            <Input required placeholder="Accelerator name" />
          </Form.Item>

          <Form.Item
            label="Saving Type"
            name="type"
            rules={[
              {
                required: true,
                type: 'string',
                message: 'Please select an accelerator type',
              },
            ]}>
            <Select
              style={{width: '100%'}}
              onChange={() => {}}
              placeholder="Please select an accelerator type">
              <Option value="onetimesaving">One time Saving</Option>
              <Option value="recurringsaving">Recurring Saving</Option>
              <Option value="onetimeincome">One time Income</Option>
              <Option value="recurringincome">Recuring Income</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="startDate"
            label="Start Date"
            rules={[
              {
                required: true,
                message: 'Please select start date',
              },
            ]}>
            <MonthPicker
              style={{width: '100%'}}
              //onChange={onFieldChange}
              defaultValue={moment(new Date(), monthFormat)}
            />
          </Form.Item>

          <Form.Item
            label="Saving Amount"
            name="amount"
            rules={[
              {
                required: true,
                type: 'integer',
                message: 'Please enter saving amount',
              },
            ]}>
            <InputNumber
              formatter={value =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              placeholder="Loan amount"
              style={{width: '100%'}}
            />
          </Form.Item>

          <Button htmlType="submit" style={{display: 'none'}}></Button>
        </Form>
      </Modal>
    </div>
  );
};

export default AppliedKits;
