import React from 'react';
import FirebaseFin from 'firebase/firebaseFin';
import FinancialChart from 'components/app/loantracker/FinancialChart';

const Loans = ({ profile }) => {
  FirebaseFin.analytics.logEvent('loan_tracker', { profile });
  return <FinancialChart profile={profile} />;
};

export default Loans;
