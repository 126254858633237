import React from 'react';
import {Avatar} from 'antd/lib';
import {Link} from 'react-router-dom';

const NotificationItem = ({notification}) => {
  const {
    avatar,
    unreadMessage,
    username,
    lastSentAt,
    lastMessage,
  } = notification;
  return (
    <li className="gx-media">
      <div className="gx-user-thumb gx-mr-3">
        <Avatar className="gx-size-40" alt={avatar} src={avatar} />
        {unreadMessage > 0 ? (
          <span className="gx-badge gx-badge-danger gx-text-white gx-rounded-circle">
            {unreadMessage}
          </span>
        ) : null}
      </div>
      <div className="gx-media-body">
        <div className="gx-flex-row gx-justify-content-between gx-align-items-center">
          <h5 className="gx-text-capitalize gx-user-name gx-mb-0">
            <span className="gx-link">{username}</span>
          </h5>
          <span className="gx-meta-date">
            <small>{lastSentAt}</small>
          </span>
        </div>
        <p className="gx-fs-sm">{lastMessage}</p>
        <Link to={{pathname: `/app/messages`, selectFriend: notification}}>
          <span className="gx-btn gx-btn-sm gx-top2 gx-text-muted">
            <i className="icon icon-reply gx-pr-2" />
            Reply
          </span>
        </Link>
        <Link to={{pathname: `/app/messages`, selectFriend: notification}}>
          <span className="gx-btn gx-btn-sm gx-top2 gx-text-muted">
            <i className="icon icon-custom-view gx-pr-2" />
            Read
          </span>
        </Link>
      </div>
    </li>
  );
};

export default NotificationItem;
