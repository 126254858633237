import React from 'react';
import numeral from 'numeral';
import { Button } from 'antd/lib';
import StarRatingComponent from 'react-star-rating-component';
import IntlMessages from 'util/IntlMessages';

const KitItem = ({ kit, grid, onKitSelect }) => {
	const { thumb, name, onetimesavings, totalsavings, typeDesc, rating, description } = kit;
	return (
		<div
			className={`gx-product-item  ${grid ? 'gx-product-vertical' : 'gx-product-horizontal'}`}
			onClick={() => onKitSelect(kit)}
		>
			<div className="gx-product-image">
				<div className="gx-grid-thumb-equal">
					<span className="gx-link gx-grid-thumb-cover">
						<img alt={name} src={thumb} />
					</span>
				</div>
			</div>

			<div className="gx-product-body">
				<h3 className="gx-product-title">
					{name}
					<small className="gx-text-grey">{', ' + typeDesc}</small>
				</h3>
				<div className="ant-row-flex">
					{kit.type === 'recurringsaving' && (
						<h4 style={{ width: '100%' }}>
							<span className="gx-text-muted gx-mr-2">Monthly savings:</span>
							{numeral(onetimesavings).format('$0,0')}
						</h4>
					)}
					<h4 style={{ width: '100%' }}>
						<span className="gx-text-muted gx-mr-2">Total savings:</span>
						{numeral(totalsavings).format('$0,0')}
					</h4>
				</div>
				<div className="ant-row-flex gx-mb-1 gx-mt-2">
					<h4>
						<span className="gx-text-muted gx-mr-2">Member rating:</span>
					</h4>
					<StarRatingComponent name="" value={rating} starCount={5} editing={false} />
					<strong className="gx-d-inline-block gx-ml-2">{rating}</strong>
				</div>
				<p>{description}</p>
			</div>

			<div className="gx-product-footer">
				{/* <Button variant="raised">
					<IntlMessages id="kits.quickview" />
				</Button> */}

				<Button type="primary">
					<IntlMessages id="kits.readmore" />
				</Button>
			</div>
		</div>
	);
};

export default KitItem;
