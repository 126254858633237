import React from 'react';
import {Spin} from 'antd/lib';
import {FaSpinner} from 'react-icons/fa';
import {useDocumentData} from 'react-firebase-hooks/firestore';
import FirebaseForums from 'firebase/firebaseForums';
import Thread from './Thread';

const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;

const ThreadLink = ({threadId}) => {
  let userId = FirebaseForums.auth.currentUser.uid;

  const [threadInfo, threadInfoLoading, threadInfoError] = useDocumentData(
    FirebaseForums.getThreadOnRedirect(threadId),
    {
      snapshotListenOptions: {includeMetadataChanges: true},
    },
  );

  if (!threadInfo || !threadId) {
    return <Spin indicator={spinnerIcon} />;
  } else {
    if (userId === threadInfo.author) {
      return (
        <div>
          {threadInfoError && <strong>Connection error!</strong>}
          {threadInfoLoading && <p>Loading...</p>}
          {threadInfo && <Thread threadInfo={threadInfo} detailPage={true} />}
        </div>
      );
    } else {
      return (
        <div>
          {threadInfoError && <strong>Connection error!</strong>}
          {threadInfoLoading && <p>Loading...</p>}
          {threadInfo && (
            <Thread threadInfo={threadInfo} detailPage={true} readonly={true} />
          )}
        </div>
      );
    }
  }
};

export default ThreadLink;
