import React, {useReducer, useEffect} from 'react';
import {Modal, Checkbox, Drawer, Dropdown, Menu, message, Spin} from 'antd/lib';
import {FirebaseDAO} from 'firebase/firebase';
import {useCollectionData} from 'react-firebase-hooks/firestore';
import TaskSideBar from './TaskSideBar';
import {FaSpinner} from 'react-icons/fa';
import TaskList from 'components/app/tasks/TaskList';
import TaskGrid from 'components/app/tasks/TaskGrid';
import TaskDetail from 'components/app/tasks/TaskDetail/index';
import AppModuleHeader from 'components/AppModuleHeader/index';
import CircularProgress from 'components/CircularProgress/index';
import Auxiliary from 'util/Auxiliary';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';
import FirebaseTasks from 'firebase/firebaseTasks';
import TaskForm from './TaskForm';
import {initialState, reducer} from './reducer';

const options = [
  'All',
  'None',
  'Completed',
  'Scheduled',
  'Starred',
  'Unstarred',
  'Important',
  'Unimportant',
];
const {confirm} = Modal;
const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;

const Tasks = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const intlMessages = useFormatMessage();

  const [firebaseTasks, firebaseTasksLoading] = useCollectionData(
    FirebaseDAO.getFBCollectionData('tasks'),
    {
      idField: 'id',
      snapshotListenOptions: {includeMetadataChanges: true},
    },
  );

  const [labels, labelsLoading] = useCollectionData(
    FirebaseDAO.getFBCollectionData('tags'),
    {
      idField: 'id',
      snapshotListenOptions: {includeMetadataChanges: true},
    },
  );

  useEffect(() => {
    // Check the returned value from Firebase and only re-render if there's difference
    if (firebaseTasks && state.render !== JSON.stringify(firebaseTasks)) {
      dispatch({type: 'setTasks', payload: firebaseTasks});
      dispatch({type: 'setAllTasks', payload: firebaseTasks});
      dispatch({type: 'setRender', payload: JSON.stringify(firebaseTasks)});
    }
  }, [firebaseTasksLoading, firebaseTasks, state.render]);

  const onStarredTask = async task => {
    const {id, starred} = task;
    await FirebaseTasks.updateTaskStarred({id: id, starred: !starred});
    task.starred = !starred;
    const aTasks = state.allTasks.map(todo => {
      if (todo.id === task.id) {
        return task;
      } else {
        return todo;
      }
    });
    dispatch({type: 'setTasks', payload: aTasks});
    dispatch({type: 'setAllTasks', payload: aTasks});
    message.success(intlMessages('task.star.success'));
  };

  const onImportantTask = async task => {
    const {id, important} = task;
    await FirebaseTasks.updateTaskImportant({id: id, important: !important});
    task.important = !important;
    const aTasks = state.allTasks.map(todo => {
      if (todo.id === task.id) {
        return task;
      } else {
        return todo;
      }
    });
    dispatch({type: 'setTasks', payload: aTasks});
    dispatch({type: 'setAllTasks', payload: aTasks});
    message.success(intlMessages('task.important.success'));
  };

  const onMarkCompletedTask = async task => {
    const {id, completed} = task;
    await FirebaseTasks.updateTaskComplete({id: id, completed: !completed});
    task.completed = !completed;
    const aTasks = state.allTasks.map(todo => {
      if (todo.id === task.id) {
        return task;
      } else {
        return todo;
      }
    });
    dispatch({type: 'setAllTasks', payload: aTasks});
    dispatch({type: 'setTasks', payload: aTasks});
    message.success(intlMessages('task.completed.success'));
  };

  const getTasksByFilter = (tasks, filter, label, condition = true) => {
    let selectedTasks = 0;
    let aTasks = state.tasks.map(todo => {
      let searchCondition = condition ? todo[filter] : !todo[filter];
      selectedTasks++;
      return {...todo, selected: searchCondition};
    });
    dispatch({type: 'setSelectedTasks', payload: selectedTasks});
    dispatch({type: 'setOptionName', payload: label});
    dispatch({type: 'setTasks', payload: aTasks.filter(todo => !todo.deleted)});
    dispatch({type: 'setAllTasks', payload: aTasks});
    return aTasks;
  };

  const onAllTodoSelect = () => {
    if (state.optionName === 'All') {
      getTasksByFilter(state.allTasks, 'none', 'None', true);
      dispatch({type: 'setOptionName', payload: 'None'});
      dispatch({type: 'setSelectedTasks', payload: 0});
    } else {
      getTasksByFilter(state.allTasks, 'all', 'All', false);
      dispatch({type: 'setOptionName', payload: 'All'});
    }
  };

  const onOptionMenuItemSelect = e => {
    switch (e.key) {
      case 'All':
        getTasksByFilter(state.allTasks, 'all', 'All', false);
        dispatch({type: 'setOptionName', payload: 'All'});
        break;
      case 'None':
        getTasksByFilter(state.allTasks, 'none', 'None', true);
        dispatch({type: 'setOptionName', payload: 'None'});
        dispatch({type: 'setSelectedTasks', payload: 0});
        break;
      case 'Completed':
        getTasksByFilter(state.allTasks, 'completed', 'Completed', true);
        break;
      case 'Scheduled':
        getTasksByFilter(state.allTasks, 'completed', 'Scheduled', false);
        break;
      case 'Starred':
        getTasksByFilter(state.allTasks, 'starred', 'Starred', true);
        break;
      case 'Unstarred':
        getTasksByFilter(state.allTasks, 'starred', 'Unstarred', false);
        break;
      case 'Important':
        getTasksByFilter(state.allTasks, 'important', 'Important', true);
        break;
      case 'Unimportant':
        getTasksByFilter(state.allTasks, 'important', 'Unimportant', false);
        break;
      default:
        return '';
    }
  };

  const onLabelMenuItemSelect = e => {
    const label = +e.key;
    const seletedTag = labels[label].tag;
    const tagValue = seletedTag.title;
    const aTasks = state.allTasks.map(todo => {
      if (todo.selected) {
        if (
          todo.tags &&
          todo.tags.findIndex(tagObject => tagObject.title === tagValue) !== -1
        ) {
          return {...todo, labels: removeLabel(todo, seletedTag)};
        } else {
          return {...todo, labels: addLabel(todo, seletedTag)};
        }
      } else {
        return todo;
      }
    });
    dispatch({type: 'setAllTasks', payload: aTasks});
    dispatch({type: 'setTasks', payload: aTasks});
    message.success(intlMessages('task.label.success'));
  };

  const onLabelUpdate = (data, label) => {
    if (data.labels.includes(label.id)) {
      data.labels = removeLabel(data, label.id);
    } else {
      data.labels = addLabel(data, label.id);
    }
    const aTasks = state.allTasks.map(todo => {
      if (todo.id === data.id) {
        return data;
      } else {
        return todo;
      }
    });
    dispatch({type: 'setCurrentTask', task: data});
    dispatch({type: 'setAllTasks', payload: aTasks});
    dispatch({type: 'setTasks', payload: aTasks});
    message.success(intlMessages('task.label.success'));
  };

  const onTodoChecked = data => {
    data.selected = !data.selected;
    let selectedTasks = 0;
    const aTasks = state.tasks.map(todo => {
      if (todo.selected) {
        selectedTasks++;
      }
      if (todo.id === data.id) {
        if (todo.selected) {
          selectedTasks++;
        }
        return data;
      } else {
        return todo;
      }
    });
    dispatch({type: 'setSelectedTasks', payload: selectedTasks});
    dispatch({type: 'setTasks', payload: aTasks});
  };

  const taskFilterByLabel = label => {
    const aTasks = state.allTasks.filter(task => {
      return (
        task &&
        task.tags &&
        task.tags.findIndex(tagObject => tagObject.title === label) !== -1
      );
    });
    dispatch({type: 'setCurrentTask', task: null});
    dispatch({type: 'setTasks', payload: aTasks});
  };

  const searchTasks = searchText => {
    if (searchText === '') {
      dispatch({
        type: 'setTasks',
        payload: state.allTasks.filter(todo => !todo.deleted),
      });
    } else {
      const searchTasksResult = state.allTasks.filter(
        todo =>
          !todo.deleted &&
          (todo.title.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
            (todo.tags &&
              todo.tags.findIndex(
                tagObject => tagObject.title === searchText.toLowerCase(),
              ) !== -1) ||
            todo.description.toLowerCase().indexOf(searchText.toLowerCase()) >
              -1),
      );
      dispatch({type: 'setTasks', payload: searchTasksResult});
    }
  };

  const optionMenu = () => {
    return (
      <Menu onClick={onOptionMenuItemSelect}>
        {options.map((option, index) => (
          <Menu.Item key={option}>{option}</Menu.Item>
        ))}
      </Menu>
    );
  };

  const labelMenu = () => {
    return (
      <Menu onClick={onLabelMenuItemSelect}>
        {labels.map((label, index) => (
          <Menu.Item key={index}>{label.tag.title}</Menu.Item>
        ))}
      </Menu>
    );
  };

  const removeLabel = async (todo, tag) => {
    todo.tags.splice(
      todo.tags.findIndex(tagObject => tagObject.title === tag.title),
      1,
    );
    let params = {id: todo.id, tags: todo.tags};
    await FirebaseTasks.updateTaskLabel(params);
    return todo.tags;
  };

  const addLabel = async (todo, tag) => {
    todo.tags = todo.tags.concat(tag);
    let params = {id: todo.id, tags: todo.tags};
    await FirebaseTasks.updateTaskLabel(params);
    return todo.tags;
  };

  const updateSearch = evt => {
    dispatch({type: 'setSearchString', payload: evt.target.value});
    searchTasks(evt.target.value);
  };

  const deleteSelectedTasks = async () => {
    let selectedTasks = state.tasks.filter(task => task.selected === true);
    let selectedIds = [];
    selectedTasks.forEach(task => selectedIds.push(task.id));
    await FirebaseTasks.deleteTasks(selectedIds);
    dispatch({type: 'setSelectedTasks', payload: 0});
    message.success(intlMessages('task.delete.success'));
  };

  const onDeleteTasks = () => {
    confirm({
      title: 'Are you sure you want to delete selected task(s)?',
      onOk: () => {
        deleteSelectedTasks();
      },
      onCancel() {},
    });
  };

  const deleteCurrentTask = async task => {
    await FirebaseTasks.deleteTasks([task.id]);
    dispatch({type: 'setSelectedTasks', payload: 0});
    dispatch({type: 'showAllTasks'});
    message.success(intlMessages('task.delete.success'));
  };

  const onDeleteCurrentTask = task => {
    confirm({
      title: 'Are you sure you want to delete this task?',
      onOk: () => {
        deleteCurrentTask(task);
      },
      onCancel() {},
    });
  };

  const markAllCompletedTask = async status => {
    let selectedTasks = state.tasks.filter(task => task.selected === true);
    let selectedIds = [];
    selectedTasks.forEach(task => selectedIds.push(task.id));
    await FirebaseTasks.updateTasksCompletedStatus(selectedIds, status);
    dispatch({type: 'setSelectedTasks', payload: 0});
    dispatch({type: 'showAllTasks'});
    message.success(intlMessages('task.completed.success'));
  };

  const onMarkAllCompletedTask = status => {
    let title =
      'Are you sure you want to mark selected task(s) as uncompleted?';
    if (status) {
      title = 'Are you sure you want to mark selected task(s) as competed?';
    }
    confirm({
      title: title,
      onOk: () => {
        markAllCompletedTask(status);
      },
      onCancel() {},
    });
  };

  if (firebaseTasksLoading || labelsLoading) {
    return <Spin indicator={spinnerIcon} />;
  }

  const showToDos = (currentTask, toDos, view) => {
    // Task detail
    if (currentTask) {
      return (
        <TaskDetail
          todo={currentTask}
          onEditTask={task => dispatch({type: 'editTask', task})}
          onDeleteTask={onDeleteCurrentTask}
          onLabelUpdate={onLabelUpdate}
          onStarredTask={onStarredTask}
          onImportantTask={onImportantTask}
          onMarkCompletedTask={onMarkCompletedTask}
          taskFilterByLabel={taskFilterByLabel}
        />
      );
    }

    if (view === 'list') {
      return (
        <TaskList
          toDos={toDos}
          onTodoSelect={task => dispatch({type: 'setCurrentTask', task})}
          onTodoChecked={onTodoChecked}
          onEditTask={task => dispatch({type: 'editTask', task})}
          onDeleteTask={onDeleteCurrentTask}
          useDragHandle={true}
          onStarredTask={onStarredTask}
          onImportantTask={onImportantTask}
          onMarkCompletedTask={onMarkCompletedTask}
          taskFilterByLabel={taskFilterByLabel}
        />
      );
    }

    // Default grid view
    return (
      <TaskGrid
        toDos={toDos}
        onTodoChecked={onTodoChecked}
        onEditTask={task => dispatch({type: 'editTask', task})}
        onDeleteTask={onDeleteCurrentTask}
        useDragHandle={true}
        onStarredTask={onStarredTask}
        onImportantTask={onImportantTask}
        onMarkCompletedTask={onMarkCompletedTask}
        taskFilterByLabel={taskFilterByLabel}
      />
    );
  };

  return (
    <div className="gx-main-content">
      <div className="gx-app-module">
        <div className="gx-d-block gx-d-lg-none">
          <Drawer
            placement="left"
            closable={false}
            visible={state.drawerState}
            onClose={() => dispatch({type: 'toggleDrawerState'})}>
            <TaskSideBar
              tasks={state.tasks}
              allTasks={state.allTasks}
              setTasks={tasks => dispatch({type: 'setTasks', payload: tasks})}
              labels={labels}
              showAllTasks={() => dispatch({type: 'showAllTasks'})}
              dispatch={dispatch}
            />
          </Drawer>
        </div>

        <div className="gx-module-sidenav gx-d-none gx-d-lg-flex">
          <TaskSideBar
            tasks={state.tasks}
            allTasks={state.allTasks}
            setTasks={tasks => dispatch({type: 'setTasks', payload: tasks})}
            labels={labels}
            showAllTasks={() => dispatch({type: 'showAllTasks'})}
            dispatch={dispatch}
          />
        </div>

        <div className="gx-module-box">
          <div className="gx-module-box-header">
            <span className="gx-drawer-btn gx-d-flex gx-d-lg-none">
              <i
                className="icon icon-menu gx-icon-btn"
                aria-label="Menu"
                onClick={() => dispatch({type: 'toggleDrawerState'})}
              />
            </span>
            <AppModuleHeader
              placeholder="Search tasks"
              onViewGrid={() => dispatch({type: 'setViewGrid'})}
              onViewList={() => dispatch({type: 'setViewList'})}
              onChange={updateSearch}
              value={state.searchString}
            />
          </div>

          <div className="gx-module-box-content">
            {state.currentTask === null ? (
              <div className="gx-module-box-topbar gx-module-box-topbar-todo">
                {state.tasks && state.tasks.length > 0 ? (
                  <Auxiliary>
                    <Checkbox
                      className="gx-icon-btn"
                      color="primary"
                      indeterminate={
                        state.selectedTasks > 0 &&
                        state.selectedTasks < state.tasks.length
                      }
                      checked={state.selectedTasks > 0}
                      onChange={onAllTodoSelect}
                      value="SelectMail"
                    />
                    <Dropdown
                      overlay={optionMenu()}
                      placement="bottomRight"
                      trigger={['click']}>
                      <div>
                        <span className="gx-px-2">{state.optionName}</span>
                        <i className="icon icon-charvlet-down" />
                      </div>
                    </Dropdown>
                  </Auxiliary>
                ) : null}

                {state.selectedTasks > 0 && state.tasks.length > 0 && (
                  <div className="gx-flex-row gx-justify-content-between">
                    <Dropdown
                      overlay={labelMenu()}
                      placement="bottomRight"
                      trigger={['click']}>
                      <i key={1} className="gx-icon-btn icon icon-tag" />
                    </Dropdown>
                    <i
                      key={2}
                      onClick={() => onMarkAllCompletedTask(true)}
                      className="gx-text-green gx-icon-btn icon icon-check-circle-o"
                    />
                    <i
                      key={3}
                      onClick={() => onMarkAllCompletedTask(false)}
                      className="gx-text-muted gx-icon-btn icon icon-close-circle"
                    />
                    <i
                      key={4}
                      onClick={() => onDeleteTasks()}
                      className="gx-icon-btn icon icon-trash"></i>
                  </div>
                )}
                {state.tasks.length === 0 && (
                  <div className="gx-flex-row gx-justify-content-between">
                    <span>No tasks meet the selection criteria</span>
                  </div>
                )}
              </div>
            ) : (
              <div className="gx-module-box-topbar">
                <i
                  className="icon icon-arrow-left gx-icon-btn"
                  onClick={() => dispatch({type: 'showAllTasks'})}
                />
              </div>
            )}
            {firebaseTasksLoading ? (
              <div className="gx-loader-view">
                <CircularProgress />
              </div>
            ) : (
              showToDos(state.currentTask, state.tasks, state.view)
            )}
          </div>
        </div>
      </div>
      <TaskForm
        task={state.currentlyEditedTask}
        tagss={state.currentlyEditedTags}
        formMode={state.formMode}
        showModal={state.showModal}
        dispatch={dispatch}
      />
    </div>
  );
};

export default Tasks;
