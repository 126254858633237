import React from 'react';
import {Spin} from 'antd/lib';
import Auxiliary from 'util/Auxiliary';
import ProfileHeader from 'components/profile/ProfileHeader/index';
import {FaSpinner} from 'react-icons/fa';

const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;

const Profile = ({profile}) => {
  if (!profile) {
    return <Spin indicator={spinnerIcon} />;
  } else {
    return (
      <Auxiliary>
        <ProfileHeader userProfile={profile} currentProfile={profile} />
      </Auxiliary>
    );
  }
};

export default Profile;
