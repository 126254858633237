import React from 'react';
import {Scrollbars} from 'react-custom-scrollbars';

const CustomScrollbars = props => (
  <Scrollbars
    {...props}
    autoHide
    renderTrackHorizontal={props => (
      <div
        {...props}
        style={{overflow: 'hidden', display: 'none', height: 0}}
        className="track-horizontal"
      />
    )}
    renderTrackVertical={props => (
      <div
        {...props}
        style={{overflow: 'hidden', display: 'none', height: 0}}
        className="track-vertical"
      />
    )}
    renderThumbHorizontal={props => (
      <div {...props} className="thumb-horizontal" />
    )}
    renderThumbVertical={props => <div {...props} className="thumb-vertical" />}
  />
);

export default CustomScrollbars;
