import React from 'react';
import {Dropdown, Avatar, Menu} from 'antd/lib';
import numeral from 'numeral';

const ServiceCell = ({
  service,
  onDeleteSevice,
  onModalOpen,
  readony,
  avtarHeight = 110,
}) => {
  const options = ['Edit', 'Delete'];

  const menus = () => (
    <Menu
      onClick={e => {
        if (e.key === 'Edit') {
          onModalOpen(service);
        } else {
          onDeleteSevice(service);
        }
      }}>
      {options.map(option => (
        <Menu.Item key={option}>{option}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="gx-contact-item">
      <div className="gx-module-list-icon">
        <div className="gx-ml-2 gx-d-none gx-d-sm-flex">
          {service && service.coverPhoto && service.coverPhoto.downloadURL ? (
            <Avatar
              shape="square"
              size={avtarHeight}
              alt={service.serviceName}
              src={service.coverPhoto.downloadURL}
            />
          ) : (
            <Avatar shape="square" size={avtarHeight}>
              {service.serviceName.charAt(0).toUpperCase()}
            </Avatar>
          )}
        </div>
      </div>

      <div
        className="gx-module-list-info gx-contact-list-info"
        style={{maxWidth: '82%'}}>
        <div className="gx-module-contact-content">
          <p className="gx-mb-1">
            <span className="gx-text-truncate gx-contact-name">
              {' '}
              {service.serviceName}{' '}
            </span>
          </p>
          <div className="gx-text-muted">
            <span className="gx-email gx-d-inline-block gx-mr-2">
              Price
              <span className="gx-toolbar-separator">&nbsp;</span>
              {numeral(service.servicePrice).format('$0,0.00')}
            </span>
          </div>
          <div className="gx-text-muted">
            <div
              className="gx-email gx-d-inline-block gx-mr-2 "
              style={{whiteSpace: 'initial'}}>
              Description
              <span className="gx-toolbar-separator">&nbsp;</span>
              {service.description ? service.description : '-'}
            </div>
          </div>
        </div>
        {!readony ? (
          <div className="gx-module-contact-right">
            <Dropdown
              overlay={() => menus()}
              placement="bottomRight"
              trigger={['click']}
              key={service.id}>
              <i className="gx-icon-btn icon icon-ellipse-v" />
            </Dropdown>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ServiceCell;
