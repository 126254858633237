import React from 'react';
import {Link} from 'react-router-dom';
import {Avatar, Comment, Spin, Row, Col} from 'antd/lib';
import {useDocumentData} from 'react-firebase-hooks/firestore';
import {FaSpinner} from 'react-icons/fa';
import FirebaseSocial from 'firebase/firebaseSocial';
import DisplayDate from '../../../util/DisplayDate';
import VoteDisplay from './VoteDisplay';

const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;

const ThreadComment = ({comment, threadData}) => {
  const [user, userLoading, userError] = useDocumentData(
    FirebaseSocial.getUserDocumentData(comment.user),
    {
      snapshotListenOptions: {includeMetadataChanges: true},
    },
  );

  let text = comment.comment;
  let date = comment.date;

  if (!comment) {
    return <Spin indicator={spinnerIcon} />;
  } else {
    return (
      <div>
        {userError && <strong>Connection error!</strong>}
        {userLoading && <p>Loading...</p>}
        {user && (
          <Row>
            <VoteDisplay threadInfo={threadData} comment={comment} />
            <Col span={23}>
              <Comment
                author={
                  <Link
                    to={{
                      pathname: `/app/user/${user.vanityURL}`,
                      userId: user.id,
                    }}>
                    {user.username}
                  </Link>
                }
                avatar={
                  <Link
                    to={{
                      pathname: `/app/user/${user.vanityURL}`,
                      userId: user.id,
                    }}>
                    <Avatar src={user.avatar} />
                  </Link>
                }
                content={text}
                datetime={<DisplayDate date={date} />}
              />
            </Col>
          </Row>
        )}
      </div>
    );
  }
};

export default ThreadComment;
