import React from 'react';
import Widget from 'components/Widget/index';
import './widgets.less';

class NetWorthChartCard extends React.Component {
  state = {
    isHide: false,
    children: '',
    chartProperties: {
      prize: '',
      title: '',
      bgColor: '',
      styleName: '',
      desc: '',
      percent: '',
    },
  };

  componentDidMount() {
    this.setState(previousState => ({
      chartProperties: this.props.chartProperties,
      children: this.props.children,
    }));
  }

  handleToggle() {
    this.setState(previousState => ({
      isHide: !previousState.isHide,
    }));
  }

  render() {
    const {chartProperties, isHide, children} = this.state;
    const {bgColor} = chartProperties;
    return (
      <Widget styleName="gx-card-full">
        <div
          className={
            isHide === true
              ? `gx-fillchart gx-bg-${bgColor} gx-fillchart-nocontent`
              : `gx-fillchart gx-bg-${bgColor} gx-overlay-fillchart`
          }>
          {children}
          <div
            className="gx-fillchart-btn-close"
            onClick={this.handleToggle.bind(this)}>
            <i className="icon icon-close" />
          </div>
          <div
            className="gx-fillchart-btn"
            onClick={this.handleToggle.bind(this)}>
            <i className={`icon icon-stats gx-fs-xxxl`} />
          </div>
        </div>
      </Widget>
    );
  }
}

export default NetWorthChartCard;
