import React, {useState, useEffect} from 'react';
import {Avatar, List, Col, Row, Card, Rate, Button} from 'antd/lib';
import {Link} from 'react-router-dom';
import BookAppointment from '../booking/BookAppointment';
import MessageBubble from 'components/app/contact/MessageBubble';
import FirebaseCoaches from 'firebase/firebaseCoaches';
import {
  covertTimestamp,
  getAvailabilityEntry,
  dateFormatDisplay,
} from 'util/commonUtils';
import IntlMessages from 'util/IntlMessages';

const DisplayCoachesCell = ({profile, data, view}) => {
  const [maxDate, setMaxDate] = useState(covertTimestamp(new Date()));
  const [availibilty, setAvailibilty] = useState(null);
  const [bookingDisabled, setBookingDisabled] = useState(false);

  async function getAvailability() {
    let response = await FirebaseCoaches.geAvailibilityData(data.id);
    setAvailibilty(response);
    let slots = [];
    let currentDatetime = covertTimestamp(new Date());
    let bookingsData = await FirebaseCoaches.getAppointmentsOfCoachData({
      coachid: data.id,
      filter: 'upcoming',
    });

    if (response.length > 0) {
      let lastDate = response.reduce(
        (max, p) => (p.endDate > max ? p.endDate : max),
        response[0].endDate,
      );
      if (Number.isInteger(lastDate)) {
        setMaxDate(lastDate);
      }
      let availSlots = getAvailabilityEntry({
        availability: response,
        date: currentDatetime * 1000,
      });
      if (availSlots) {
        let timeSlots = availSlots.timeSlots;
        timeSlots.map((s, i) =>
          slots.push({
            id: i,
            time: s,
            show: true,
          }),
        );
      }
      if (bookingsData.length && slots.length) {
        const today = new Date(maxDate * 1000);
        today.setHours(0, 0, 0, 0);
        let todatTimestamp = covertTimestamp(today);
        let nextDayTimestamp = (todatTimestamp + 86400) * 1000;
        bookingsData.map(book => {
          if (
            book.date >= todatTimestamp * 1000 &&
            book.date <= nextDayTimestamp
          ) {
            slots = slots.filter(slot => slot.time !== book.timeSlot);
          }
          return true;
        });
      }
      let todayDate = dateFormatDisplay(currentDatetime * 1000, 'YMD');
      let _lastDate = dateFormatDisplay(maxDate * 1000, 'YMD');

      if (todayDate === _lastDate && slots.length === 0) {
        setBookingDisabled(true);
      }
    } else {
      setBookingDisabled(true);
    }
  }
  // Effect hooks
  useEffect(() => {
    if (!availibilty) {
      getAvailability();
    }
  });

  return (
    <>
      {data &&
        (view === 'list' ? (
          <List
            itemLayout="vertical"
            size="small"
            dataSource={[data]}
            renderItem={item => (
              <List.Item key={item.id}>
                <Row>
                  <Col className="align-self-center" span={6}>
                    <Link to={`/app/user/${data.vanityURL}`}>
                      <Avatar
                        shape="round"
                        size={40}
                        src={data.avatar}
                        className="mr-3"
                      />
                      {data.username}
                    </Link>
                  </Col>
                  <Col className="align-self-center" span={6}>
                    {data.coachProfile && data.coachProfile.tagLine
                      ? data.coachProfile.tagLine
                      : '-'}
                  </Col>
                  <Col className="align-self-center" span={4}>
                    <Rate allowHalf defaultValue={2.5} disabled />
                  </Col>
                  <Col className="align-self-center" span={4}>
                    {profile.id === data.id ? null : bookingDisabled ? (
                      <Button
                        type="primary"
                        className="gx-ml-auto gx-mb-0 gx-btn-block disabled"
                        key={'disabledbook' + data.id}>
                        <IntlMessages id="coachCard.book.appointment" />
                      </Button>
                    ) : (
                      <BookAppointment coach={data} profile={profile} />
                    )}
                  </Col>
                  <Col className="align-self-center" span={4}>
                    {profile.id === data.id ? null : (
                      <MessageBubble userId={data.id} section={'coach'} />
                    )}
                  </Col>
                </Row>
              </List.Item>
            )}
          />
        ) : (
          <Card
            key={'grid' + data.id}
            size="small"
            className="goal-card flex-fill"
            cover={
              <Link to={`/app/user/${data.vanityURL}`}>
                <img className="goal-cover-image" src={data.avatar} alt="" />
              </Link>
            }>
            <div className="float-right m-0 btn p-0 ant-dropdown-link border-0">
              {profile.id === data.id ? null : (
                <MessageBubble userId={data.id} section={'coach'} />
              )}
            </div>
            <div className="goal-card-heading p-2 d-flex">
              <div>
                <h5>
                  <Link to={`/app/user/${data.vanityURL}`}>
                    {data.username}
                  </Link>
                </h5>
                <p>
                  {data.coachProfile && data.coachProfile.tagLine
                    ? data.coachProfile.tagLine
                    : '-'}
                </p>
                <span>
                  <Rate allowHalf defaultValue={2.5} disabled />
                </span>
              </div>
            </div>
            {profile.id === data.id ? null : bookingDisabled ? (
              <Button
                type="primary"
                className="gx-ml-auto gx-mb-0 gx-btn-block disabled"
                key={'disabledbook' + data.id}>
                <IntlMessages id="coachCard.book.appointment" />
              </Button>
            ) : (
              <BookAppointment coach={data} profile={profile} />
            )}
          </Card>
        ))}
    </>
  );
};

export default DisplayCoachesCell;
