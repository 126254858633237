import React from 'react';
import { Col, Row } from 'antd/lib';
import DisplayCoachesCell from './DisplayCoachesCell'

const DispyCoachesList = ({ profile, data, section }) => {
    return (
        <React.Fragment>
            <Row className="mb-3">
                <Col className="align-self-center" span={6}>
                    Coach
                </Col>
                <Col className="align-self-center" span={6}>
                    Tagline
                </Col>
                <Col className="align-self-center" span={4}>
                    Rating
                </Col>
                <Col className="align-self-center" span={4}>
                </Col>
                <Col className="align-self-center" span={4}></Col>
            </Row>
            {
                data.map((coach, index) => (
                    <DisplayCoachesCell
                        key={index}
                        profile={profile}
                        data={coach}
                        section={section}
                        view={'list'}

                    />
                ))
            }
        </React.Fragment>
    );
};

export default DispyCoachesList
    ;
