import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Card } from 'antd';
import { Loan, Asset, CombineRecords, LoanPortfolio, AssetPortfolio, METHOD_AVALANCHE, LoanTermCalculator } from 'loanpalz-constants';
import numeral from 'numeral';
import Widget from 'components/Widget/index';
import { schemePaired, schemeTableau10, schemeDark2 } from 'd3-scale-chromatic';
import {
    NetWorthCard,
    NetWorthChart,
    LiabilityCard,
    LiabilityChart,
} from './index';
import LoanModal from 'components/app/loan/LoanModal';
import AccountModal from 'components/app/account/AccountModal';
import IntlMessages from 'util/IntlMessages';
import moment from 'moment';
const colors = schemePaired;
const dayFormat = 'D-MMM-YYYY';

const ProcessAssetLiabilitiesData = ({ profile, loans, accounts, linkedLoans, linkedAccounts, userData }) => {
    const [loanPortfolio, setLoanPortfolio] = useState();
    const [payOffDate, setPayOffDate] = useState('');
    const [processedLoans, setProcessedLoans] = useState(null);
    const [liabilityColors, setLiabilityColors] = useState({});
    const [assetsColors, setAssetsColors] = useState({});
    const [networthValue, setNetworthValue] = useState(0);
    const [assetsValue, setAssetsValue] = useState(0);
    const [loansValue, setLoansValue] = useState(0);
    const [processed, setProcessed] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showAccountModal, setShowAccountModal] = useState(false);
    const [processedAssests, setProcessedAssests] = useState(null);

    useEffect(() => {
        let combinedLoansArray = [];
        let combinedAccountsArray = [];
        let _assetsValue = 0;
        let _loansValue = 0;

        // Combine Loans
        let allLoans = new CombineRecords(loans, linkedLoans, 'loan').getCombinedRecoreds();
        allLoans.map((loan, index) => {
            let term = loan.term;
            if (term === 0 && loan.minimumPayment > 0) {
                term = new LoanTermCalculator(loan.interest, loan.currentBalance, loan.minimumPayment).calculateLoanTerms();
            }
            let newLoan = new Loan(
                loan.interest,
                term,
                loan.currentBalance,
                moment(new Date(loan.startDate * 1000), dayFormat).format(dayFormat),
                0,
                [],
                loan.name,
            );
            newLoan.id = loan.id;
            newLoan.type = loan.type;
            newLoan.lenderName = loan.lenderName;
            newLoan.term = loan.term;
            newLoan.originalLoanAmount = loan.originalLoanAmount;
            newLoan.currentBalance = loan.currentBalance;
            newLoan.interest = loan.interest;
            _loansValue += loan.currentBalance;
            return combinedLoansArray.push(newLoan)
        })

        // Combine Accounts
        let allAccounts = new CombineRecords(accounts, linkedAccounts, 'account').getCombinedRecoreds();
        // Add accounts
        allAccounts.map((account, i) => {
            let newAsset = new Asset(
                account.balance,
                account.name,
                colors[i]
            );
            newAsset.id = account.id;
            newAsset.type = account.type;
            _assetsValue += account.balance;
            return combinedAccountsArray.push(newAsset)
        })

        setProcessedAssests(combinedAccountsArray);
        // Set Asset/Liablities/Networth Value
        setAssetsValue(_assetsValue);
        setLoansValue(_loansValue);
        setNetworthValue(_assetsValue - _loansValue)

        // Process All Liabilities
        let loanPort = new LoanPortfolio(METHOD_AVALANCHE);
        loanPort.setLoans(combinedLoansArray);
        loanPort.calculateMonthlyPaymentWithOverlays();
        setProcessedLoans(loanPort.processedLoans);

        let _colors = {};
        combinedLoansArray.map((loan, i) => {
            return (_colors[loan.id] = (colors[i + 4] ? colors[i + 4] : schemeTableau10[i + 4 - 10]));
        });
        setLiabilityColors(_colors);

        // Assets
        let getAssets = new AssetPortfolio(combinedAccountsArray);
        getAssets.setAssets(combinedAccountsArray);
        // setAccountBalance(getAssets.calculateTotalAssets());
        let __colors = {};
        combinedAccountsArray.map((account, c) => {
            return (__colors[account.id] = (c <= 3 ? colors[c] : schemeDark2[c - 4]));
        });
        setAssetsColors(__colors);

        // Debt Free Card
        let staticLoans = [];
        loans.map((_loan, index) => {
            let _term = _loan.term;
            if (_term === 0 && _loan.minimumPayment > 0) {
                _term = new LoanTermCalculator(_loan.interest, _loan.currentBalance, _loan.minimumPayment).calculateLoanTerms();
            }
            let newLoan = new Loan(
                _loan.interest,
                _term,
                _loan.currentBalance,
                moment(new Date(_loan.startDate * 1000), dayFormat).format(dayFormat),
                0,
                [],
                _loan.name,
            );
            newLoan.id = _loan.id;
            newLoan.type = _loan.type;
            newLoan.lenderName = _loan.lenderName;
            newLoan.term = _loan.term;
            newLoan.originalLoanAmount = _loan.originalLoanAmount;
            newLoan.currentBalance = _loan.currentBalance;
            newLoan.interest = _loan.interest;
            _loansValue += _loan.originalLoanAmount;
            return staticLoans.push(newLoan)
        })
        let _loanPort = new LoanPortfolio(METHOD_AVALANCHE);
        _loanPort.setLoans(staticLoans);
        _loanPort.calculateMonthlyPaymentWithOverlays();
        setLoanPortfolio(_loanPort);
        setPayOffDate(_loanPort.calculatePayoffDate());

        //Complete Processing
        setProcessed(true)
    }, [loans, accounts, linkedAccounts, linkedLoans, assetsValue, loansValue, userData]);

    const renderTooltipContent = o => {
        const { payload } = o;
        return payload.map((entry, index) => (
            <p
                key={index}
                style={{
                    backgroundColor: '#ffff',
                    fontSize: '12px',
                    border: '1px solid rgb(110, 110, 243)',
                    padding: '10px',
                }}>
                {`${entry.name}: ${numeral(entry.value).format('$0,0')}`}
            </p>
        ));
    };

    const showHideModal = value => {
        setShowModal(value);
    };

    const showHideAccountModal = value => {
        setShowAccountModal(value);
    }

    return (
        <Widget>
            <Row>
                <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                    {
                        accounts.length || loans.length ?
                            <NetWorthCard
                                networth={networthValue}
                                accountBalance={assetsValue}
                                loanBalance={loansValue}
                            /> :
                            null
                    }
                </Col>
                <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                    {
                        loans && loans.length ?
                            <LiabilityCard
                                payOffDate={payOffDate}
                                loanPortfolio={loanPortfolio}
                                type={METHOD_AVALANCHE}
                                loans={loans}
                            /> :
                            null
                    }
                </Col>
                <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                    {
                        processed && accounts.length ?
                            <NetWorthChart
                                accountBalance={assetsValue}
                                accounts={processedAssests}
                                assetsColors={assetsColors}
                                renderTooltipContent={renderTooltipContent}
                            />
                            :
                            <Card>
                                <div className="text-center">
                                    <h4>You don't have Account yet. Lets add your first Account!</h4>
                                    <Button
                                        variant="raised"
                                        className="gx-mt-3"
                                        type="primary"
                                        onClick={() => setShowAccountModal(true)}>
                                        <div className="ant-row-flex">
                                            <IntlMessages id="wizard.financials.account.addBtn" />
                                        </div>
                                    </Button>
                                </div>
                                <AccountModal
                                    showHideModal={showHideAccountModal}
                                    showModal={showAccountModal}
                                />
                            </Card>
                    }
                </Col>
                <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                    {
                        processed && processedLoans.length ?
                            <LiabilityChart
                                startingBalance={loansValue}
                                loans={processedLoans}
                                renderTooltipContent={renderTooltipContent}
                                liabilityColors={liabilityColors}
                            />
                            :
                            <Card>
                                <div className="text-center">
                                    <h4>You don't have loan yet. Lets add your first loan and start tracking your progress!</h4>
                                    <Button
                                        variant="raised"
                                        className="gx-mt-3"
                                        type="primary"
                                        onClick={() => showHideModal(true)}>
                                        <div className="ant-row-flex">
                                            <IntlMessages id="loan.add" />
                                        </div>
                                    </Button>
                                </div>
                                <LoanModal
                                    showModal={showModal}
                                    showHideModal={showHideModal}
                                />
                            </Card>
                    }
                </Col>
            </Row>
        </Widget>
    );
};

export default ProcessAssetLiabilitiesData;
