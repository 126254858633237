import React, { useState, useEffect } from 'react';
import moment from 'moment';
import FlipMove from 'react-flip-move';
import KitItem from 'components/app/accelerator/KitItem';
import KitDetail from 'components/app/accelerator/KitDetail';
import kitsData from './kitsData';
import { Col, Row, Spin } from 'antd/lib';
import Widget from 'components/Widget/index';
import { ResponsiveContainer } from 'recharts';
import Auxiliary from 'util/Auxiliary';
import { Loan } from 'loanpalz-constants';
import AppliedKits from 'components/app/accelerator/AppliedKits';
import KitAggregateChart from 'components/app/accelerator/KitAggregateChart';
import KitInterestDistributionPieChart from 'components/app/accelerator/KitInterestDistributionPieChart';
import KitSavingsWidget from 'components/app/accelerator/KitSavingsWidget';
import FirebaseFin from 'firebase/firebaseFin';
import { FaSpinner } from 'react-icons/fa';
import { OptimizelyFeature } from '@optimizely/react-sdk';

const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;

const customEnterAnimation = {
  from: { transform: 'scale(0.5, 1)' },
  to: { transform: 'scale(1, 1)' },
};

const monthFormat = 'MM/YYYY';
const shortMonthFormat = 'MMM-YYYY';
const startMonth = moment(new Date(), monthFormat);

const Accelerator = ({ profile }) => {
  const [kits] = useState(kitsData);
  const [selectedKit, setSelectedKit] = useState(null);
  const [loan, setLoan] = useState(null);

  // Pie chart data
  const [pieChartData, setPieChartData] = useState(null);

  // Loan calculators
  const [loanCalc, setLoanCalc] = useState(null); // eslint-disable-line
  const [newLoanCalc, setNewLoanCalc] = useState(null); // eslint-disable-line

  // Savings widget
  const [interestSavings, setInterestSavings] = useState(0);
  const [timeSavings, setTimeSavings] = useState(0);
  const [newDate, setNewDate] = useState(moment(new Date(), monthFormat));

  const onKitSelect = selectedKit => setSelectedKit(selectedKit);
  const onKitDeselect = () => {
    setSelectedKit(null);
    getAppliedAccelerators();
  };

  // Applied kits
  const [appliedKits, setAppliedKits] = useState(null);

  async function getAppliedAccelerators() {
    let response = await FirebaseFin.getAppliedAccelerators();
    setAppliedKits(response);
  }

  // Main effects
  useEffect(() => {
    const setStateVars = () => {
      if (profile) {
        const userLoan = {
          amount: profile.financial.snapshot.loanamount,
          term: profile.financial.snapshot.loanterm,
          interest: profile.financial.snapshot.loaninterest,
        };

        setLoan(userLoan);

        // Set current loan calculator
        const loan = new Loan(
          userLoan.interest,
          userLoan.term,
          userLoan.amount,
          startMonth,
        );
        setLoanCalc(loan);

        // Set new loan calc
        const newLoan = new Loan(
          userLoan.interest,
          userLoan.term,
          userLoan.amount,
          startMonth,
          0,
          appliedKits,
        );
        setNewLoanCalc(newLoan);

        // Calculate interest savings
        const interestDelta =
          loan.getTotalInterest() - newLoan.getTotalInterest();
        setInterestSavings(interestDelta);

        // Calculate time savings
        const timeDelta = loan.getTotalMonths() - newLoan.getTotalMonths();
        setTimeSavings(timeDelta);

        // Calculate new maturity date
        const newMaturityDate = newLoan.calculateMaturityDate();
        setNewDate(newMaturityDate);

        // Set pie chart data
        let pieData = [];
        if (appliedKits) {
          appliedKits.forEach(kit => {
            if (kit.enabled) {
              pieData.push({
                id: kit.name,
                label: kit.name,
                value: Math.round(kit.interestSavings),
              });
            }
          });
        }

        setPieChartData(pieData);
      }
    };

    if (!appliedKits) {
      getAppliedAccelerators();
    }
    setStateVars();
  }, [profile, appliedKits]);

  if (!profile || !appliedKits || !loan) {
    return <Spin indicator={spinnerIcon} />;
  } else {
    return (
      <OptimizelyFeature feature="loan_accelerator">
        {isEnabled =>
          isEnabled ? (
            <Auxiliary>
              {!selectedKit &&
                loanCalc &&
                newLoanCalc &&
                appliedKits &&
                appliedKits.length > 0 && (
                  <div>
                    <Row>
                      <Col xl={17} md={24} sm={24} xs={24}>
                        <Widget styleName="gx-card-full">
                          <div className="ant-row-flex gx-px-4 gx-pt-4">
                            <h2 className="h4 gx-mb-3">
                              Interest Savings from Accelerators
                            </h2>
                            <div className="gx-ml-auto"></div>
                          </div>

                          <ResponsiveContainer width="100%" height={400}>
                            <KitInterestDistributionPieChart
                              data={pieChartData}
                            />
                          </ResponsiveContainer>
                        </Widget>
                      </Col>
                      <Col xl={7} md={8} sm={16} xs={24}>
                        <Widget styleName="gx-card-full">
                          <div className="ant-row-flex gx-px-4 gx-pt-4">
                            <h2 className="h4 gx-mb-3">Accelerated Savings</h2>
                            <ResponsiveContainer width="100%" height={400}>
                              <KitSavingsWidget
                                interestSavings={interestSavings || 0}
                                timeSavings={timeSavings || 0}
                                newDate={
                                  moment(newDate, shortMonthFormat) ||
                                  moment(new Date(), shortMonthFormat)
                                }
                                iconColor="geekblue"
                              />
                            </ResponsiveContainer>
                          </div>
                        </Widget>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={24} md={24} sm={24} xs={24}>
                        <KitAggregateChart
                          loanCalc={loanCalc.getAmortization()}
                          newLoanCalc={newLoanCalc.getAmortization()}
                          loan={loan}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={24} md={24} sm={24} xs={24}>
                        <AppliedKits
                          getAppliedAccelerators={getAppliedAccelerators}
                          kits={appliedKits}
                          loan={loan}
                        />
                      </Col>
                    </Row>
                  </div>
                )}

              {!selectedKit && (
                <div>
                  <FlipMove
                    staggerDurationBy="30"
                    duration={500}
                    enterAnimation={customEnterAnimation}>
                    <Row>
                      {kits.map((kit, index) => (
                        <Col key={index} xl={6} md={12} sm={18} xs={24}>
                          <KitItem
                            key={kit.id}
                            grid
                            kit={kit}
                            onKitSelect={onKitSelect}
                          />
                        </Col>
                      ))}
                    </Row>
                  </FlipMove>
                </div>
              )}

              {selectedKit && (
                <KitDetail
                  kit={selectedKit}
                  loan={loan}
                  onDeselect={onKitDeselect}
                />
              )}
            </Auxiliary>
          ) : (
              <div className="gx-feature-disabled">
                Feature Flag Disabled by Optimizely
              </div>
            )
        }
      </OptimizelyFeature>
    );
  }
};

export default Accelerator;
