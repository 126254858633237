import React, {useState} from 'react';
import {useCollectionData} from 'react-firebase-hooks/firestore';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';
import {
  Button,
  Cascader,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Spin,
  Table,
} from 'antd/lib';
import Widget from 'components/Widget/index';
import {FirebaseDAO} from 'firebase/firebase';
import _ from 'lodash';
import numeral from 'numeral';
import {FaSpinner} from 'react-icons/fa';
import IntlMessages from 'util/IntlMessages';
import {accountTypes} from 'constants/DataConstants';
import './checklist.less';

const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;
const {confirm} = Modal;
const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
  },
};

const AccountStep = props => {
  const columns = [
    {
      title: 'Account Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return (
          <span
            className="linktext"
            onClick={() => {
              onEditAccount(record);
            }}>
            {text}
          </span>
        );
      },
    },
    {
      title: 'Initial Balance',
      dataIndex: 'balance',
      key: 'balance',
      render: (text, record) => {
        return (
          <a
            href
            onClick={() => {
              onEditAccount(record);
            }}>
            {numeral(text).format('$0,0.00')}
          </a>
        );
      },
    },
    {
      title: 'Account Type',
      dataIndex: 'type',
      key: 'type',
      render: (text, record) => {
        const category = _.find(accountTypes, {value: _.first(text)});
        const subCategory = _.find(category.children, {value: _.last(text)});
        return (
          <a
            href
            onClick={() => {
              onEditAccount(record);
            }}>
            {category.label + ' / ' + subCategory.label}
          </a>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: values => {
        return (
          <span>
            <i
              className="icon icon-edit gx-pointer gx-text-primary gx-fs-lg gx-d-inline-flex gx-vertical-align-middle gx-mr-0"
              onClick={() => onEditAccount(values)}
            />
            &emsp;
            <i
              className="icon icon-trash gx-pointer gx-text-danger gx-fs-lg gx-d-inline-flex gx-vertical-align-middle gx-mr-1"
              onClick={() => onDeleteCurrentAccount(values)}
            />
          </span>
        );
      },
    },
  ];

  const [currentAccount, setCurrentAccount] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [formMode, setFormMode] = useState('add');
  const [form] = Form.useForm();
  const intlMessages = useFormatMessage();
  const endCollection = 'accounts';
  const [accounts, loading, error] = useCollectionData(
    FirebaseDAO.getFBCollectionData(endCollection),
    {
      idField: 'id',
      snapshotListenOptions: {includeMetadataChanges: true},
    },
  );

  const onFormSubmit = async () => {
    let formValues = form.getFieldsValue(['name', 'type', 'balance']);

    setShowModal(false);
    if (formMode === 'add') {
      message.success(intlMessages('wizard.financials.account.add.success'));
      await FirebaseDAO.addFBData(formValues, endCollection);
    } else if (formMode === 'edit') {
      message.success(intlMessages('wizard.financials.account.update.success'));
      let {id} = form.getFieldsValue(['id']);
      formValues = {
        ...formValues,
        id,
      };
      await FirebaseDAO.updateFBData(formValues, endCollection);
      currentAccount
        ? FirebaseDAO.getFBData(id, endCollection)
            .get()
            .then(doc => setCurrentAccount({...doc.data(), id: doc.id}))
        : console.log('no Account to set');
      setFormMode('add');
    }
    form.resetFields();
  };

  const onDeleteCurrentAccount = account => {
    confirm({
      title: 'Are you sure you want to delete this Account?',
      onOk: () => {
        deleteCurrentAccount(account);
      },
      onCancel() {},
    });
  };

  const onEditAccount = account => {
    setFormMode('edit');
    form.resetFields();
    form.setFieldsValue({
      ...account,
    });
    setShowModal(true);
  };

  const deleteCurrentAccount = async account => {
    await FirebaseDAO.deleteMultipleFBData([account.id], endCollection);
    setCurrentAccount(null);
    message.success(intlMessages('wizard.financials.account.delete.success'));
  };

  const handleResetFormModal = () => {
    setShowModal(false);
    form.resetFields();
  };

  const handleSubmitValidation = () => {
    form.validateFields((err, values) => {
      if (err) {
        console.log('Form validation failed');
      }
    });
  };

  return (
    <div>
      <Widget
        title={
          <h2 className="h4 gx-text-capitalize gx-mb-0 gx-text-left  gx-ml-3">
            Assets
          </h2>
        }
        extra={
          <p
            className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block  gx-mr-3"
            onClick={() => setShowModal(true)}>
            <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle gx-mr-1" />
            Add Asset
          </p>
        }>
        {error && <strong>Error: {JSON.stringify(error)}</strong>}
        {loading && <Spin indicator={spinnerIcon} />}
        {accounts && (
          <Table
            className="gx-table-no-bordered"
            rowClassName="Account-table-row"
            columns={columns}
            dataSource={accounts}
            pagination={false}
            size="small"
            rowKey="name"
            locale={{
              emptyText: (
                <div className="gx-mt-3">
                  <h2>You don't have any Accounts yet. Let's add one!</h2>
                  <Button
                    variant="raised"
                    className="gx-mt-3"
                    type="primary"
                    onClick={() => setShowModal(true)}>
                    <div className="ant-row-flex">
                      <IntlMessages id="wizard.financials.account.addBtn" />
                    </div>
                  </Button>
                </div>
              ),
            }}
          />
        )}
      </Widget>

      <Modal
        title={
          formMode === 'add' ? (
            <IntlMessages id="wizard.financials.account.addBtn" />
          ) : (
            <IntlMessages id="wizard.financials.account.updateBtn" />
          )
        }
        // toggle={onContactClose}
        visible={showModal}
        closable={true}
        onOk={() => form.submit()}
        onSubmit={() => handleSubmitValidation()}
        onCancel={handleResetFormModal}>
        <Form
          {...formItemLayout}
          form={form}
          onSubmit={handleSubmitValidation}
          onFinish={onFormSubmit}>
          <div className="gx-modal-box-row">
            <div className="gx-modal-box-form-item">
              <div className="gx-form-group">
                <Form.Item
                  label="Account name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      type: 'string',
                      message: 'Please enter an Account name',
                    },
                  ]}>
                  <Input required placeholder="Account name" />
                </Form.Item>
              </div>
              <div className="gx-form-group">
                <Form.Item
                  name="balance"
                  label="Initial Balance"
                  rules={[
                    {
                      required: true,
                      type: 'integer',
                      message: 'Please enter a valid number',
                    },
                  ]}>
                  <InputNumber
                    onChange={() => {}}
                    formatter={value =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    placeholder="Initial Balance"
                    style={{width: '100%'}}
                  />
                </Form.Item>
              </div>
              <div className="gx-form-group">
                <Form.Item
                  label="Account Type"
                  name="type"
                  rules={[
                    {
                      required: true,
                      type: 'array',
                      message: 'Please select an Account type',
                    },
                  ]}>
                  <Cascader
                    options={accountTypes}
                    expandTrigger="hover"
                    allowClear="false"
                    // onChange={(value, selectedOptions) =>
                    //   console.log(value, selectedOptions)
                    // }
                    displayRender={label => label[label.length - 1]}
                    showSearch={(inputValue, path) =>
                      path.some(
                        option =>
                          option.label
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) > -1,
                      )
                    }
                  />
                </Form.Item>
              </div>
              <Button htmlType="submit" style={{display: 'none'}}></Button>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default AccountStep;
