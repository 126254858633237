import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import 'assets/vendors/style';
import 'styles/wieldy.less';
import configureStore, { history } from './appRedux/store';
import App from './containers/App/index'; // eslint-disable-line
import SettingsWizard from './routes/app/settings/SettingsWizard'

import {
  createInstance,
  OptimizelyProvider,
  setLogLevel,
} from '@optimizely/react-sdk';

// Optimizely DEV/PROD setup
let sdkKey = 'QvPwbo7bEHWE9YxZ8yWLhy';
if (process.env.REACT_APP_DISPLAY_MODE === 'dev') {
  sdkKey = '5oXxz4yTWY9ZgweKm83Mjc';
}
const optimizely = createInstance({ sdkKey });
setLogLevel('error');

export const store = configureStore();

const NextApp = () => (
  <OptimizelyProvider
    optimizely={optimizely}
    user={{
      id: 'user123',
    }}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={SettingsWizard} />
        </Switch>
      </ConnectedRouter>
    </Provider>
  </OptimizelyProvider>
);

export default NextApp;
