import React, { Fragment } from 'react';
import { Input, DatePicker, InputNumber, Row, Col, Form } from 'antd';
import GoalFormField from './GoalFormField';
import numeral from 'numeral';

const GoalFormPreview = ({
  goalName,
  goalAmount,
  goalAccount,
  setGoalName,
  setGoalAmount,
  goalPlannedDate,
  goalAmountNeeded,
  goalMonthlyAmount,
  goalAccountBalance,
  onChangePlannedDate,
  setGoalMonthlyAmount,
  minimumMonthlyAmount,
  projectedDateMessage,
  setGoalAccountBalance,
  isCustomMonthlyContrib,
  setIsCustomMonthlyContrib,
}) => {

  return (
    <div className="goal-preview-wrapper">
      <div className="goal-preview">
        <Row>
          <Col span={24}>
            <h2>Your Goal</h2>
          </Col>
        </Row>
        <div className="form-group">
          <GoalFormField
            label={<h4>Goal Name</h4>}
            field={
              <Col span={24}>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      type: 'string',
                      message: 'Please enter goal name',
                    },
                  ]}>
                  <Input
                    value={goalName}
                    onChange={e => setGoalName(e.target.value)}
                  />
                </Form.Item>
              </Col>
            }
          />
        </div>
        <div className="form-group">
          <GoalFormField
            label={<h4>Goal Amount</h4>}
            field={
              <Fragment>
                <Col span={24}>
                  <Form.Item
                    name="goalAmount"
                    rules={[
                      {
                        required: true,
                        type: 'integer',
                        message: 'Please enter goal amount',
                      },
                    ]}>
                    <InputNumber
                      value={goalAmount}
                      onChange={value => setGoalAmount(value)}
                      formatter={value =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    />
                  </Form.Item>
                </Col>
              </Fragment>
            }
          />
        </div>

        <div className="form-group">
          <GoalFormField
            label={<h4>Your current balance</h4>}
            field={
              <Fragment>
                <Col span={24}>
                  <Form.Item
                    rules={[
                      {
                        required: false,
                      },
                    ]}>
                    <InputNumber
                      value={goalAccountBalance}
                      onChange={value => {
                        setGoalAccountBalance(value);
                        setIsCustomMonthlyContrib(false);
                      }}
                      // disabled={!!goalAccount}
                      formatter={value =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    />
                  </Form.Item>
                </Col>
              </Fragment>
            }
          />
        </div>

        <div className="form-group">
          <Row>
            <Col span={24}>
              <h4>Amount Needed</h4>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {goalAmountNeeded && parseInt(goalAmountNeeded, 10) > 0 ? (
                <p>
                  You still need {numeral(goalAmountNeeded).format('$0,0')} to
                  reach your goal
                </p>
              ) : (
                  <p>You have reached your goal</p>
                )}
            </Col>
          </Row>
        </div>
      </div>

      <div className="plan-preview">
        <Row>
          <Col span={24}>
            <h2>Your Plan</h2>
          </Col>
        </Row>
        <div className="form-group">
          <GoalFormField
            label={<h4>Your planned date</h4>}
            field={
              <Col span={24}>
                <Form.Item
                  name="goalPlannedDate"
                  rules={[
                    {
                      required: true,
                      type: 'object',
                      message: 'Please select date',
                    },
                  ]}>
                  <DatePicker className="gx-mb-3 gx-w-100" placeholder="Planned completion date" margin="normal" onChange={onChangePlannedDate} />
                </Form.Item>
              </Col>
            }
          />
        </div>
        <div className="form-group">
          <GoalFormField
            label={<h4>Your monthly contribution</h4>}
            field={
              <Fragment>
                <Col span={24}>
                  <Form.Item
                    rules={[
                      {
                        required: false,
                        type: 'integer',
                        message: 'Enter a monthly contribution',
                      },
                    ]}>
                    <InputNumber
                      value={goalMonthlyAmount}
                      min={0}
                      onChange={value => {
                        if (value) {
                          setGoalMonthlyAmount(value);
                          setIsCustomMonthlyContrib(true);
                        }
                      }}
                      formatter={value =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    />
                  </Form.Item>
                </Col>
              </Fragment>
            }
          />
        </div>
        <div className="form-group">
          <Row>
            <Col span={24}>
              <h4>Projected Date</h4>
              <p>{projectedDateMessage}</p>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default GoalFormPreview;
