import React from 'react';
import {useCollectionData} from 'react-firebase-hooks/firestore';
import {message, Modal} from 'antd/lib';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';
import FirebaseSocial from 'firebase/firebaseSocial';

const Follow = ({userId}) => {
  const intlMessages = useFormatMessage();
  const {confirm} = Modal;

  const [followerLook, followLoading, followError] = useCollectionData(
    FirebaseSocial.getUserFollowing(FirebaseSocial.auth.currentUser.uid),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const follow = id => {
    confirm({
      title: 'Follow?',
      onOk: () => {
        FirebaseSocial.followUser(id);
        message.success(intlMessages('social.follow.success'));
      },
      onCancel() {},
    });
  };

  const unfollow = id => {
    confirm({
      title: 'Unfollow?',
      onOk: () => {
        FirebaseSocial.unfollowUser(id);
        message.success(intlMessages('social.unfollow.success'));
      },
      onCancel() {},
    });
  };

  let isFollower = null;
  if (followerLook) {
    followerLook.forEach(followerPair => {
      if (followerPair.following.id.toString() === userId.toString()) {
        isFollower = true;
      }
      if (isFollower === null) {
        isFollower = false;
      }
    });
  }

  return (
    <div>
      {followError && <strong>Connection error!</strong>}
      {followLoading && <p>Loading...</p>}
      {followerLook && (
        <span className="gx-flex-row">
          <div className="gx-mr-2">
            {isFollower ? (
              <p onClick={() => unfollow(userId)} style={{cursor: 'pointer'}}>
                <i className="icon icon-like gx-fs-xxl gx-text-grey" />
              </p>
            ) : (
              <p onClick={() => follow(userId)} style={{cursor: 'pointer'}}>
                <i className="icon icon-like-o gx-fs-xxl gx-text-grey" />
              </p>
            )}
          </div>

          <span className="gx-text-grey gx-fs-md" style={{lineHeight: '24px'}}>
            {isFollower ? (
              <p onClick={() => unfollow(userId)} style={{cursor: 'pointer'}}>
                Unfollow
              </p>
            ) : (
              <p onClick={() => follow(userId)} style={{cursor: 'pointer'}}>
                Follow
              </p>
            )}
          </span>
        </span>
      )}
    </div>
  );
};

export default Follow;
