import {useFormatMessage} from '@comparaonline/react-intl-hooks';
import {
  Button,
  Form,
  InputNumber,
  message,
  Modal,
  Select,
  Spin,
  Table,
} from 'antd';
import Widget from 'components/Widget/index';
import {FirebaseDAO} from 'firebase/firebase';
import numeral from 'numeral';
import React, {useState} from 'react';
import {useCollectionData} from 'react-firebase-hooks/firestore';
import {FaSpinner} from 'react-icons/fa';
import IntlMessages from 'util/IntlMessages';
import '../../checklist.less';

const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;
const {confirm} = Modal;
const {Option} = Select;
const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
  },
};

const NonEssentialStep = props => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        let nonEssentialType = '';
        switch (text) {
          case 'clothing/shoes':
            nonEssentialType = 'Clothing/Shoes';
            break;
          case 'personalcare':
            nonEssentialType = 'Personal Care';
            break;
          case 'travel':
            nonEssentialType = 'Travel';
            break;
          case 'goingout/fun':
            nonEssentialType = 'Going Out/Fun';
            break;
          default:
            break;
        }
        return (
          <span
            className="linktext"
            onClick={() => {
              onEditNonEssentialExpense(record);
            }}>
            {nonEssentialType}
          </span>
        );
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) => {
        return (
          <a
            href
            onClick={() => {
              onEditNonEssentialExpense(record);
            }}>
            {numeral(text).format('$0,0.00')}
          </a>
        );
      },
    },
    {
      title: 'Frequency',
      dataIndex: 'frequency',
      key: 'frequency',
      render: (text, record) => {
        let frequencyText = '';
        switch (text) {
          case 'weekly':
            frequencyText = 'Once a Week';
            break;
          case 'twiceamonth':
            frequencyText = 'Twice a Month';
            break;
          case 'onceamonth':
            frequencyText = 'Once a Month';
            break;
          case 'onceaquarter':
            frequencyText = 'Once a Quarter';
            break;
          case 'onceayear':
            frequencyText = 'Once a Year';
            break;
          default:
            break;
        }
        return (
          <a
            href
            onClick={() => {
              onEditNonEssentialExpense(record);
            }}>
            {frequencyText}
          </a>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: values => {
        return (
          <span>
            <i
              className="icon icon-edit gx-pointer gx-text-primary gx-fs-lg gx-d-inline-flex gx-vertical-align-middle gx-mr-0"
              onClick={() => onEditNonEssentialExpense(values)}
            />
            &emsp;
            <i
              className="icon icon-trash gx-pointer gx-text-danger gx-fs-lg gx-d-inline-flex gx-vertical-align-middle gx-mr-1"
              onClick={() => onDeleteCurrentNonEssentialExpense(values)}
            />
          </span>
        );
      },
    },
  ];

  const [currentNonEssentialExpense, setCurrentNonEssentialExpense] = useState(
    null,
  );
  const [showModal, setShowModal] = useState(false);
  const [formMode, setFormMode] = useState('add');
  const [form] = Form.useForm();
  const intlMessages = useFormatMessage();
  const endCollection = 'expenses';
  const [nonEssentialExpenses, loading, error] = useCollectionData(
    FirebaseDAO.getFBCollectionDataWhereBool(endCollection, 'essential', false),
    {
      idField: 'id',
      snapshotListenOptions: {includeMetadataChanges: true},
    },
  );

  const onFormSubmit = async () => {
    let formValues = form.getFieldsValue(['name', 'amount', 'frequency']);
    formValues = {
      ...formValues,
      essential: false,
    };
    setShowModal(false);
    if (formMode === 'add') {
      message.success(intlMessages('wizard.financials.nexpenses.add.success'));
      await FirebaseDAO.addFBData(formValues, endCollection);
    } else if (formMode === 'edit') {
      message.success(
        intlMessages('wizard.financials.nexpenses.update.success'),
      );
      let {id} = form.getFieldsValue(['id']);
      formValues = {
        ...formValues,
        id,
      };
      await FirebaseDAO.updateFBData(formValues, endCollection);
      currentNonEssentialExpense
        ? FirebaseDAO.getFBData(id, endCollection)
            .get()
            .then(doc =>
              setCurrentNonEssentialExpense({...doc.data(), id: doc.id}),
            )
        : console.log('No Non Essential Expense to set');
      setFormMode('add');
    }
    form.resetFields();
  };

  const onDeleteCurrentNonEssentialExpense = nonEssentialExpense => {
    confirm({
      title: 'Are you sure you want to delete this Non Essential Expense?',
      onOk: () => {
        deleteCurrentNonEssentialExpense(nonEssentialExpense);
      },
      onCancel() {},
    });
  };

  const onEditNonEssentialExpense = nonEssentialExpense => {
    setFormMode('edit');
    form.resetFields();
    form.setFieldsValue({
      ...nonEssentialExpense,
    });
    setShowModal(true);
  };

  const deleteCurrentNonEssentialExpense = async nonEssentialExpense => {
    await FirebaseDAO.deleteMultipleFBData(
      [nonEssentialExpense.id],
      endCollection,
    );
    setCurrentNonEssentialExpense(null);
    message.success(intlMessages('wizard.financials.nexpenses.delete.success'));
  };

  const handleResetFormModal = () => {
    setShowModal(false);
    form.resetFields();
  };

  const handleSubmitValidation = () => {
    form.validateFields((err, values) => {
      if (err) {
        console.log('Form validation failed');
      }
    });
  };

  return (
    <div>
      <Widget
        title={
          <h2 className="h4 gx-text-capitalize gx-mb-0 gx-text-left  gx-ml-3">
            Non-Essential Expenses
          </h2>
        }
        extra={
          <p
            className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block  gx-mr-3"
            onClick={() => setShowModal(true)}>
            <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle gx-mr-1" />
            Add Non-Essential Expense
          </p>
        }>
        {error && <strong>Error: {JSON.stringify(error)}</strong>}
        {loading && <Spin indicator={spinnerIcon} />}
        {nonEssentialExpenses && (
          <Table
            className="gx-table-no-bordered"
            rowClassName="expenses-table-row"
            columns={columns}
            dataSource={nonEssentialExpenses}
            pagination={false}
            size="small"
            rowKey="name"
            locale={{
              emptyText: (
                <div className="gx-mt-3">
                  <h2>
                    You don't have any Non-Essential Expenses yet. Let's add
                    one!
                  </h2>
                  <Button
                    variant="raised"
                    className="gx-mt-3"
                    type="primary"
                    onClick={() => setShowModal(true)}>
                    <div className="ant-row-flex">
                      <IntlMessages id="wizard.financials.nexpenses.addBtn" />
                    </div>
                  </Button>
                </div>
              ),
            }}
          />
        )}
      </Widget>

      <Modal
        title={
          formMode === 'add' ? (
            <IntlMessages id="wizard.financials.nexpenses.addBtn" />
          ) : (
            <IntlMessages id="wizard.financials.nexpenses.updateBtn" />
          )
        }
        // toggle={onContactClose}
        visible={showModal}
        closable={true}
        onOk={() => form.submit()}
        onSubmit={() => handleSubmitValidation()}
        onCancel={handleResetFormModal}>
        <Form
          {...formItemLayout}
          form={form}
          onSubmit={handleSubmitValidation}
          onFinish={onFormSubmit}>
          <div className="gx-modal-box-row">
            <div className="gx-modal-box-form-item">
              <div className="gx-form-group">
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      type: 'string',
                      message: 'Please select a Non-Essential Expenses',
                    },
                  ]}>
                  <Select
                    style={{width: '100%'}}
                    onChange={() => {}}
                    placeholder="Select a Non-Essential Expenses">
                    <Option value="clothing/shoes">Clothing/Shoes</Option>
                    <Option value="personalcare">Personal Care</Option>
                    <Option value="travel">Travel</Option>
                    <Option value="goingout/fun">Going Out/Fun</Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="gx-form-group">
                <Form.Item
                  inline
                  name="amount"
                  label="Amount"
                  rules={[
                    {
                      required: true,
                      type: 'integer',
                      message: 'Please enter a valid number',
                    },
                  ]}>
                  <InputNumber
                    onChange={() => {}}
                    formatter={value =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    placeholder="Amount"
                    style={{width: '100%'}}
                  />
                </Form.Item>
              </div>
              <div className="gx-form-group">
                <Form.Item
                  label="Frequency"
                  name="frequency"
                  rules={[
                    {
                      required: true,
                      type: 'string',
                      message: 'Please select a Non-Essential frequency',
                    },
                  ]}>
                  <Select
                    style={{width: '100%'}}
                    onChange={() => {}}
                    placeholder="Select a Non-Essential frequency">
                    <Option value="weekly">Once a Week</Option>
                    <Option value="onceamonth">Once a Month</Option>
                    <Option value="twiceamonth">Twice a Month</Option>
                    <Option value="onceaquarter">Once a Quarter</Option>
                    <Option value="onceayear">Once a Year</Option>
                  </Select>
                </Form.Item>
              </div>
              <Button htmlType="submit" style={{display: 'none'}}></Button>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default NonEssentialStep;
