import React from 'react';
import Slider from 'react-slick';

function NextArrow(props) {
    return (
        <div {...props} style={{ fontSize: "60px", display: 'block', right: "100px", zIndex: "15", height: "40px", width: "40px", opacity: "1", color: "white" }} >
            <span className="icon icon-chevron-right"></span>
        </div>
    );
}

function PrevArrow(props) {
    return (
        <div {...props} style={{ fontSize: "60px", display: 'block', left: "100px", zIndex: "15", height: "40px", width: "40px", opacity: "1", color: "white" }} >
            <span className="icon icon-chevron-left"></span>
        </div>
    );
}

export default function Carousel({ mediaList }) {
    const settings = {
        accessibility: true,
        arrows: true,
        dots: true,
        infinite: true,
        speed: 200,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };

    return (
        <Slider {...settings}>
            {mediaList.map((media, index) => (
                <div key={index}>
                    <img alt="Slideshow item" src={media.uri} />
                </div>
            ))}
        </Slider>
    );
}