import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import CircularProgress from 'components/CircularProgress/index';
import {useCollectionData} from 'react-firebase-hooks/firestore';
import FirebaseFin from 'firebase/firebaseFin';
import {convertFirebaseDataIntoNetworthAreaChartData} from 'util/commonUtils';
import {AccountBalancesLineChartCustomTooltip} from 'util/chartUtils';

const COLORS = ['#00C49F', '#FF8042', '#777', '#333'];
const dayFormat = 'MM/DD/YYYY';

const AccountBalancesLineChart = ({startDate, endDate}) => {
  const [networth] = useCollectionData(
    FirebaseFin.getNetWorth(startDate.toDate(), endDate.toDate()),
    {
      snapshotListenOptions: {includeMetadataChanges: true},
    },
  );

  const gradientOffset = () => {
    let data = convertFirebaseDataIntoNetworthAreaChartData(networth);
    const dataMax = Math.max(...data.map(i => i.networth));
    const dataMin = Math.min(...data.map(i => i.networth));
    if (dataMax <= 0) {
      return 0;
    }
    if (dataMin >= 0) {
      return 1;
    }
    return dataMax / (dataMax - dataMin);
  };

  const off = gradientOffset();

  const yAxisTickFormatter = value => numeral(value).format('$0.0a');

  if (!networth) {
    return (
      <div className="networthchart">
        <div className="gx-fillchart-content">
          <CircularProgress />
        </div>
      </div>
    );
  } else {
    return (
      <ResponsiveContainer width="100%" height={330}>
        <div>
          <h4 className="text-center">Net worth over time</h4>
          <div className="mb-4">
            <ResponsiveContainer width="100%" height={280}>
              <AreaChart
                height={280}
                data={convertFirebaseDataIntoNetworthAreaChartData(networth)}
                margin={{top: 0, right: 10, left: 30, bottom: 0}}>
                <defs>
                  <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                    <stop offset={off} stopColor={COLORS[0]} stopOpacity={1} />
                    <stop offset={off} stopColor={COLORS[1]} stopOpacity={1} />
                  </linearGradient>
                </defs>
                <XAxis
                  type="number"
                  dataKey="name"
                  tickCount={10}
                  tickMargin={10}
                  tickFormatter={val =>
                    moment(new Date(val * 1000), dayFormat).format(dayFormat)
                  }
                  domain={['dataMin', 'dataMax']}></XAxis>
                <YAxis tickMargin={10} tickFormatter={yAxisTickFormatter} />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip content={<AccountBalancesLineChartCustomTooltip />} />
                <Area
                  dot={false}
                  type={'monotone'}
                  dataKey="networth"
                  stroke="#777"
                  fill="url(#splitColor)"
                />
                {/* <Brush /> */}
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      </ResponsiveContainer>
    );
  }
};

export default AccountBalancesLineChart;
