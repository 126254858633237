const productData = [
  {
    id: '12345',
    thumb:
      'https://hgtvhome.sndimg.com/content/dam/images/hgtv/fullset/2006/4/7/2/RE_Dos_Realtor_hor.jpg.rend.hgtvcom.581.436.suffix/1400935880311.jpeg',
    name: 'Sell Your House',
    type: 'onetimesaving',
    typeDesc: 'One-Time Saving',
    totalsavings: '120000',
    onetimesavings: '120000',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      }
    ],
    rating: 3.3,
    description:
      'Thinking of selling your house? How much could you make from the transaction? See how much you could make from selling your house based on the estimated sale price and your outstanding loan, and learn how you can sell for more with our certified real estate experts...'
  },
  {
    id: '12346',
    thumb: 'https://chicago.cbslocal.com/wp-content/uploads/sites/15116062/2010/12/wbbm-1209-smokin.jpg',
    name: 'Quit Smoking',
    type: 'recurringsaving',
    typeDesc: 'Ongoing Savings',
    totalsavings: '18000',
    onetimesavings: '300',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      }
    ],
    rating: 5,
    description:
      'Smoking is harmful to both your health and your budget. Every pack of cigarettes or e-cigs can add up to hundreds of dollars to your monthly budget, prolonging your loan and getting you in a deeper hole ... '
  },
  {
    id: '12347',
    thumb:
      'https://images2.minutemediacdn.com/image/upload/c_crop,h_2960,w_5267,x_0,y_153/f_auto,q_auto,w_1100/v1554919139/shape/mentalfloss/istock-491247524.jpg',
    name: 'Cancel Cable TV',
    type: 'recurringsaving',
    typeDesc: 'Ongoing Savings',
    totalsavings: '4800',
    onetimesavings: '80',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      }
    ],
    rating: 4,
    description:
      'Cable and streaming sevice subscriptions can add hundreds of dollars to your monthly expenses. By being selective, you can reduce your budget and finish that loan early ...'
  },
  {
    id: '12348',
    thumb: 'https://www.autoguide.com/blog/wp-content/uploads/2016/04/2016-chevrolet-spark.jpg',
    name: 'Sell Your Car',
    type: 'onetimesaving',
    typeDesc: 'One-Time Saving',
    totalsavings: '24000',
    onetimesavings: '24000',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      }
    ],
    rating: 3.3,
    description:
      'Time to get rid of that once-loved, but now unaffordable car. Selling your car can bring much needed cash for an early payment and will significantly reduce your interest payments ...'
  },
  {
    id: '12349',
    thumb: 'https://retireby40.org/wp-content/uploads/2012/02/flickr-nikcname.jpg',
    name: 'Prepay Loan',
    type: 'onetimesaving',
    typeDesc: 'One-Time Saving',
    totalsavings: '5000',
    onetimesavings: '5000',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      }
    ],
    rating: 3.5,
    description:
      'When you prepay your loan, it means that you make extra payments on your principal loan balance. Paying additional principal on your loan can save you thousands of dollars in interest and help you get out of debt faster.'
  },
  {
    id: '12350',
    thumb: 'https://s.marketwatch.com/public/resources/images/MW-FV357_twenty_ZH_20170929180740.jpg',
    name: 'Pay Extra Principal',
    type: 'recurringsaving',
    typeDesc: 'Recurring Saving',
    totalsavings: '12000',
    onetimesavings: '200',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      }
    ],
    rating: 4.2,
    description:
      'The benefit of paying additional principal on a loan is not just in reducing the monthly interest expense a tiny bit at a time. It comes from paying down your outstanding balance with additional loan principal payments, which slashes the total interest you’ll owe over the life of the loan.'
  },
  {
    id: '12351',
    thumb: 'https://static.makeuseof.com/wp-content/uploads/2017/03/sell-on-ebay-670x335.jpg',
    name: 'Sell Stuff',
    type: 'onetimeincome',
    typeDesc: 'One-Time Income',
    totalsavings: '1500',
    onetimesavings: '1500',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      }
    ],
    rating: 3.1,
    description:
      'Selling on eBay can be a fun hobby, but if you are paying debt, it means it can help close that gap fater. Many people have conquered a mountain of debt by selling on eBay and Craigslist. It is easier than you think and it can change your loan trajectory in significant ways.'
  },
  {
    id: '12352',
    thumb: 'https://tompkinsins.com/wp-content/uploads/2019/06/Screen-Shot-2019-06-26-at-4.49.35-PM-1200x797.png',
    name: 'Drive for Uber or Lyft',
    type: 'recurringincome',
    typeDesc: 'Recurring Income',
    totalsavings: '90000',
    onetimesavings: '1500',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      }
    ],
    rating: 3.1,
    description:
      'One of the best benefits of driving with Uber or Lyft is the flexibility of the work. How many gigs can you say will let you choose your own hours to work? You get to choose when you drive and where you are going to work. I live in the bay area, so one day I can choose to drive in San Francisco, and the next day I can choose to drive in San Jose.'
  }
];

export default productData;
