import React from "react";
import RequestCell from './requestCell';
import Widget from 'components/Widget';
import { Row, Col } from 'antd/lib';

const RequestList = ({ group, requests, approveRejectJoinRequest, section }) => {

    let title = null;
    if (requests && requests.length && group) {
        title = (group ? group.groupName : "Group") + " member join requests";
    }
    return (
        <Widget
            title={title}
            styleName="gx-card-tabs gx-card-tabs-left gx-card-profile"
            extra=
            {
                requests && requests.length && section === 'detail' ?
                    <Row type="flex" justify="end">
                        <Col>
                            <input
                                className="ant-input ant-input gx-mb-3"
                                type="search"
                                placeholder="Search requests"
                                id="search"
                            // onKeyUp={updateSearch}
                            // onChange={updateSearch}
                            />
                        </Col>
                    </Row>
                    : null
            }
        >
            {
                requests && requests.length ?
                    requests.map((data, index) =>
                        <RequestCell
                            key={data.id}
                            data={data}
                            group={group}
                            approveRejectJoinRequest={approveRejectJoinRequest}
                        />
                    ) :
                    <div className="text-center">
                        <h4 className="gx-mt-4">No join request to approve!</h4>
                    </div>
            }
        </Widget>
    );
}

export default RequestList;
