import React from 'react';
import {Modal, Input, InputNumber, Form, Button, Cascader, message} from 'antd';
import {accountTypes} from 'constants/DataConstants';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';
import {FirebaseDAO} from 'firebase/firebase';

const GoalFormAddAccount = ({
  showModal,
  setShowModal,
  cancelAddAccount,
  setNewAccountAdded,
}) => {
  const intlMessages = useFormatMessage();
  const [form] = Form.useForm();

  const saveAccountInformation = async values => {
    // let formValues = form.getFieldsValue(['name', 'balance', 'type']);
    await FirebaseDAO.addFBData(values, 'accounts');
    setNewAccountAdded(values);
    setShowModal(false);
    handleResetFormModal();
    message.success(intlMessages('wizard.financials.account.add.success'));
  };

  const handleResetFormModal = () => {
    setShowModal(false);
    form.resetFields();
  };

  const formItemLayout = {
    labelCol: {
      xs: {span: 24},
      sm: {span: 8},
    },
    wrapperCol: {
      xs: {span: 24},
      sm: {span: 16},
    },
  };

  const searchCategoryFilter = (inputValue, path) => {
    return path.some(
      option =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
    );
  };

  return (
    <Modal
      title={`Create Account`}
      visible={showModal}
      closable={true}
      onOk={() => form.submit()}
      onCancel={cancelAddAccount}>
      <Form {...formItemLayout} form={form} onFinish={saveAccountInformation}>
        <div className="gx-modal-box-row">
          <div className="gx-modal-box-form-item">
            <div className="gx-form-group">
              <Form.Item
                label="Account name"
                name="name"
                rules={[
                  {
                    required: true,
                    type: 'string',
                    message: 'Please enter an Account name',
                  },
                ]}>
                <Input required placeholder="Account name" />
              </Form.Item>
            </div>
            <div className="gx-form-group">
              <Form.Item
                name="balance"
                label="Initial Balance"
                rules={[
                  {
                    required: true,
                    type: 'integer',
                    message: 'Please enter a valid number',
                  },
                ]}>
                <InputNumber
                  onChange={() => {}}
                  formatter={value =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  placeholder="Initial Balance"
                  style={{width: '100%'}}
                />
              </Form.Item>
            </div>
            <div className="gx-form-group">
              <Form.Item
                label="Account Type"
                name="type"
                rules={[
                  {
                    required: true,
                    type: 'array',
                    message: 'Please select an Account type',
                  },
                ]}>
                <Cascader
                  options={accountTypes}
                  showSearch={{searchCategoryFilter}}
                />
              </Form.Item>
            </div>
            <Button htmlType="submit" style={{display: 'none'}}></Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default GoalFormAddAccount;
