export default [
    'magenta',
    'red',
    'volcano',
    'orange',
    'gold',
    ' brown',
    'blueviolet',
    'green',
    'grey',
    'goldenrod',
    'darkmagenta',
    'darkorange',
    'darkgreen',
    'darkblue',
    'darkcyan',
    'darkgrey',
    'darkkhaki',
    'darkolivegreen',
    'firebrick',
    'forestgreen',
    'goldenrod',
    'hotpink',
    'teal',
    'tomato',
    'royalblue',
    'violet',
    'green',
    'cyan',
    'blue',
    'geekblue',
    'purple',
];