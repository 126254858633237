import React, {useState} from 'react';
import {Col, message, Modal, Tooltip} from 'antd';
import '../settingsWizard.less';
// import withStepTemplateNoButtons from './withStepTemplateNoButtons'
import withStepTemplate from './withStepTemplate';
import UploadPicture from './UploadPicture';
import {InlineWidget, PopupWidget, PopupText} from 'react-calendly';
import {InfoCircleOutlined} from '@ant-design/icons';
import Button from '@material-ui/core/Button';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';

const key = 'updatable';

// We can inject some CSS into the DOM.
const styles = {
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
};

const StepUpload = props => {
  const {classes, children, className, ...other} = props;

  const [fileInfoList, setFileInfoList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const handleClickImage = () => {
    setIsOpen(!isOpen);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const openMessage = () => {
    message.loading({
      content: 'Please wait. Your documents are being uploaded...',
      key,
    });
  };

  const handleUploadDocuments = async () => {
    if (fileInfoList.length > 0) {
      console.log('sssss');
      setUploading(true);
      openMessage();
      await props.firebase
        .onMediaUpload(fileInfoList, props.state.id)
        .then(console.log('Upload complete'));
      setUploading(false);
      message.success({
        content: 'Your documents have been uploaded successfully.',
        key,
        duration: 5,
      });
    }

    setPreviewVisible(false);
    setPreviewImage('');
    setIsOpen(false);
  };

  if (uploading) {
    console.log('Documents are uploading');
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        {/* <PopupWidget
        styles={{
          width: '100%',
          height: '800px',
        }}
        text="Speak to a Tax Professional"
        url="https://calendly.com/gsp-stimuls/speak-to-a-tax-professional"
        color="#00a2ff"
        branding={false}
      /> */}
        <div style={{textAlign: 'center'}}>
          <InlineWidget
            onClick={() => console.log('clicked')}
            styles={{
              width: '100%',
              height: '2000px',
            }}
            url="https://calendly.com/gsp-stimuls/speak-to-a-tax-professional?utm_campaign=CARES&utm_source=caresnetwork"
          />
        </div>
        {/* <Button
          variant="contained"
          color="prissmary"
          // className={clsx(classes.root, className)}
        >
          <PopupText
            text="Speak to a Tax Professional"
            url="https://calendly.com/gsp-stimuls/speak-to-a-tax-professional"
          />
        </Button> */}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(withStepTemplate(StepUpload));
// export default stepUpload
