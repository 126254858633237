import React from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import TaskItem from './TaskItem';

const TaskList = ({
  toDos,
  onTodoSelect,
  onTodoChecked,
  onEditTask,
  onDeleteTask,
  onStarredTask,
  onImportantTask,
  onMarkCompletedTask,
  taskFilterByLabel,
}) => {
  return (
    <div className="gx-module-list">
      <CustomScrollbars className="gx-module-content-scroll">
        {toDos &&
          toDos.map((todo, index) => (
            <TaskItem
              key={index}
              index={index}
              todo={todo}
              onTodoSelect={onTodoSelect}
              onTodoChecked={onTodoChecked}
              onEditTask={onEditTask}
              onDeleteTask={onDeleteTask}
              onStarredTask={onStarredTask}
              onImportantTask={onImportantTask}
              onMarkCompletedTask={onMarkCompletedTask}
              taskFilterByLabel={taskFilterByLabel}
            />
          ))}
      </CustomScrollbars>
    </div>
  );
};

export default TaskList;
