import React from "react";
import { useDocumentData } from 'react-firebase-hooks/firestore';
import FirebaseGroups from 'firebase/firebaseGroups';
import SearchDataCard from '../searchGroup/SearchDataCard'

const JoinedgroupItemCard = ({ profile, data, searchKeyword }) => {

  const [groupData] = useDocumentData(
    FirebaseGroups.getGroupDetail(data.groupAdminId, data.groupId),
    {
      idField: 'id',
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const leaveGroup = (memberData, groupData) => {
    FirebaseGroups.removeGroupMember(memberData, groupData);
  }

  let groupDetail = groupData;

  // Search Filter
  if (searchKeyword && groupDetail) {
    if (groupDetail.groupName.toLowerCase().indexOf(searchKeyword.toLowerCase()) === -1) {
      groupDetail = null;
    }
  }

  return (
    groupDetail ?
      <SearchDataCard
        data={groupDetail}
        profile={profile}
        leaveGroup={leaveGroup}
      />
      : null
  );
}

export default JoinedgroupItemCard;
