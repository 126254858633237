import React from 'react';
import {DatePicker} from 'antd/lib';
import {AppContext} from 'auth/appContext';
import moment from 'moment';

const RangePicker = DatePicker.RangePicker;
const dateFormat = 'M/DD/YYYY';

const DateRangeBox = ({styleName, onChange}) => {
  return (
    <div className={`gx-datepicker-bar ${styleName}`}>
      <AppContext.Consumer>
        {({startDate, endDate, setDateRange}) => (
          <RangePicker
            defaultValue={[startDate, endDate]}
            format={dateFormat}
            className="gx-ml-2"
            ranges={{
              'This Month': [moment().startOf('month'), moment()],
              'Last Month': [
                moment()
                  .subtract(1, 'month')
                  .startOf('month'),
                moment()
                  .subtract(1, 'month')
                  .endOf('month'),
              ],
              '90 Days': [moment().subtract(90, 'day'), moment()],
              '6 Months': [moment().subtract(6, 'month'), moment()],
              '1 Year': [moment().subtract(1, 'year'), moment()],
              'This Year': [moment().startOf('year'), moment()],
              'Last Year': [
                moment()
                  .subtract(1, 'year')
                  .startOf('year'),
                moment()
                  .subtract(1, 'year')
                  .endOf('year'),
              ],
            }}
            onChange={setDateRange}
          />
        )}
      </AppContext.Consumer>
    </div>
  );
};

export default DateRangeBox;
