import React, {useState} from 'react';
import {Row, Col, message} from 'antd/lib';
import FirebaseGroups from 'firebase/firebaseGroups';
import SearchDataCard from './SearchDataCard';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';

const SearchGroups = ({profile}) => {
  const intlMessages = useFormatMessage();
  const [groups, setGroups] = useState([]);
  const [searching, setSearching] = useState(true);
  const [keyWord, setKeyWord] = useState('');

  const searchGroup = async evt => {
    setKeyWord(evt.target.value.trim());

    if (searching && keyWord.length > 2) {
      setSearching(false);
      let searchedGroup = await FirebaseGroups.searchGroups(
        profile.id,
        keyWord,
      );
      setGroups(searchedGroup);
      setSearching(true);
    }
  };

  const leaveGroup = (memberData, groupData) => {
    FirebaseGroups.removeGroupMember(memberData, groupData);
  };

  const sendJoinRequest = async groupData => {
    await FirebaseGroups.sendGroupJoinRequest(groupData, profile.id);
    message.success(intlMessages('group.joinGrouprequest.sent'));
  };

  return (
    <div>
      {
        <Row type="flex">
          <Col span={22}>
            <input
              className="ant-input ant-input gx-ml-5 gx-mb-5"
              type="search"
              placeholder="Search groups to join. Please input at least 3 characters to search."
              id="search"
              onKeyUp={searchGroup}
              onChange={searchGroup}
            />
          </Col>
        </Row>
      }
      {groups && groups.length ? (
        groups.map((data, index) => (
          <SearchDataCard
            data={data}
            profile={profile}
            leaveGroup={leaveGroup}
            sendJoinRequest={sendJoinRequest}
            key={index}
          />
        ))
      ) : (
        <div className="text-center">
          {keyWord ? (
            keyWord.length < 3 ? (
              <h4>Please type at least 3 characters</h4>
            ) : (
              <h4>No group found with "{keyWord}" keyword.</h4>
            )
          ) : (
            <h4>Please search group to join</h4>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchGroups;
