import {createBrowserHistory} from 'history';
import {applyMiddleware, compose, createStore} from 'redux';
import {routerMiddleware} from 'connected-react-router';
import reducers from '../reducers/index';
// import logger from 'redux-logger';
import {createSelector} from 'reselect';
import {IntlProvider} from 'react-intl';
import AppLocale from 'lngProvider';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas/index';
import thunk from 'redux-thunk';
import {reduxFirestore, getFirestore} from 'redux-firestore';
import {reactReduxFirebase, getFirebase} from 'react-redux-firebase';
import fbConfig from 'firebase/firebase';
import {showMessage} from '../actions/Messages';

export const intlSelector = createSelector(
  // state => state.intl.currentLocale,
  state => state.settings.locale.locale,
  state => AppLocale[state.settings.locale.locale].messages,
  (currentLocale, messages) =>
    new IntlProvider({locale: currentLocale, messages}).getChildContext().intl,
);

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
};

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();
const middlewares = [
  // logger,
  routeMiddleware,
  sagaMiddleware,
  thunk.withExtraArgument({
    getFirebase,
    getFirestore,
    intl: intlSelector,
    showMessage,
  }),
];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState) {
  let store = createStore(
    reducers(history),
    initialState,
    composeEnhancers(
      applyMiddleware(...middlewares),
      reduxFirestore(fbConfig),
      reactReduxFirebase(fbConfig, rrfConfig),
    ),
  );
  sagaMiddleware.run(rootSaga);
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers/index', () => {
      const nextRootReducer = require('../reducers/index');
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}

export {history};
