import React, { Component } from 'react';
import { Menu, Affix, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { IoIosMore } from "react-icons/io";
import CustomScrollbars from 'util/CustomScrollbars';
import SidebarLogo from './SidebarLogo';
import Auxiliary from 'util/Auxiliary';
import UserProfile from './UserProfile';
import AppsNavigation from './AppsNavigation';
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from 'constants/ThemeSetting';
import IntlMessages from 'util/IntlMessages';
import { connect } from 'react-redux';
import { userSignOut } from 'appRedux/actions/Auth';
import FirebaseAuth from 'firebase/firebaseAuth';
import { withOptimizely } from '@optimizely/react-sdk';
import PlaidMenuLink from 'components/app/plaid/PlaidMenuLink';

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class SidebarContent extends Component {
  getNoHeaderClass = navStyle => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return 'gx-no-header-notifications';
    }
    return '';
  };

  getNavStyleSubMenuClass = navStyle => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return 'gx-no-header-submenu-popup';
    }
    return '';
  };

  render() {
    const { themeType, navStyle, pathname } = this.props;
    const selectedKeys = pathname.pathname;
    const defaultOpenKeys = selectedKeys.split('/')[1];
    return (
      <Auxiliary>
        <SidebarLogo />
        <div className="gx-sidebar-content">
          <div
            className={`gx-sidebar-notifications ${this.getNoHeaderClass(
              navStyle,
            )}`}>
            <UserProfile onSignOut={FirebaseAuth.signOut} />
            <AppsNavigation />
          </div>

          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
              className="sidebase-menu"
              mode="inline">
              <MenuItemGroup
                key="defaultloan"
                className="gx-menu-group"
                title={<IntlMessages id="sidebar.loanpalz" />}>
                <Menu.Item key="home">
                  <Link to="/app/dashboard">
                    <i className="icon icon-home" />
                    <IntlMessages id="sidebar.home" />
                  </Link>
                </Menu.Item>
                <SubMenu
                  key="finances"
                  className={this.getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-revenue-new" />
                      <IntlMessages id="sidebar.finances" />
                    </span>
                  }>
                  {this.props.optimizely.isFeatureEnabled('net_worth') ? (
                    <Menu.Item key="networth">
                      <Link to="/app/networth">
                        <i className="icon icon-chart-area" />
                        <IntlMessages id="sidebar.networth" />
                      </Link>
                    </Menu.Item>
                  ) : (
                      <div className="gx-feature-disabled">
                        Feature Flag Disabled by Optimizely
                      </div>
                    )}

                  {this.props.optimizely.isFeatureEnabled('cash_flow') ? (
                    <Menu.Item key="mycashflow">
                      <Link to="/app/cashflow">
                        <i className="icon icon-chart-line" />
                        <IntlMessages id="sidebar.cashflow" />
                      </Link>
                    </Menu.Item>
                  ) : (
                      <div className="gx-feature-disabled">
                        Feature Flag Disabled by Optimizely
                      </div>
                    )}

                  {this.props.optimizely.isFeatureEnabled('income') ? (
                    <Menu.Item key="income">
                      <Link to="/app/income">
                        <i className="icon icon-diamond" />
                        <IntlMessages id="sidebar.income" />
                      </Link>
                    </Menu.Item>
                  ) : (
                      <div className="gx-feature-disabled">
                        Feature Flag Disabled by Optimizely
                      </div>
                    )}

                  {this.props.optimizely.isFeatureEnabled('loans') ? (
                    <Menu.Item key="loans">
                      <Link to="/app/loans">
                        <i className="icon icon-company" />
                        <IntlMessages id="sidebar.loans" />
                      </Link>
                    </Menu.Item>
                  ) : (
                      <div className="gx-feature-disabled">
                        Feature Flag Disabled by Optimizely
                      </div>
                    )}

                  {this.props.optimizely.isFeatureEnabled('credit_cards') ? (
                    <Menu.Item key="creditcards">
                      <Link to="/app/creditcards">
                        <i className="icon icon-card" />
                        <IntlMessages id="sidebar.creditcards" />
                      </Link>
                    </Menu.Item>
                  ) : (
                      <div className="gx-feature-disabled">
                        Feature Flag Disabled by Optimizely
                      </div>
                    )}

                  {this.props.optimizely.isFeatureEnabled(
                    'loan_accelerator',
                  ) ? (
                      <Menu.Item key="accelerator">
                        <Link to="/app/accelerator">
                          <i className="icon icon-apps-new" />
                          <IntlMessages id="sidebar.kits" />
                        </Link>
                      </Menu.Item>
                    ) : (
                      <div className="gx-feature-disabled">
                        Feature Flag Disabled by Optimizely
                      </div>
                    )}

                  {this.props.optimizely.isFeatureEnabled('accounts') ? (
                    <Menu.Item key="accounts">
                      <Link to="/app/accounts">
                        <i className="icon icon-revenue-new" />
                        <IntlMessages id="sidebar.assets" />
                      </Link>
                    </Menu.Item>
                  ) : (
                      <div className="gx-feature-disabled">
                        Feature Flag Disabled by Optimizely
                      </div>
                    )}

                  {this.props.optimizely.isFeatureEnabled('budget') ? (
                    <Menu.Item key="mybudget">
                      <Link to="/app/budget">
                        <i className="icon icon-revenue-new" />
                        <IntlMessages id="sidebar.budget" />
                      </Link>
                    </Menu.Item>
                  ) : (
                      <div className="gx-feature-disabled">
                        Feature Flag Disabled by Optimizely
                      </div>
                    )}

                  {this.props.optimizely.isFeatureEnabled('goals') ? (
                    <Menu.Item key="goals">
                      <Link to="/app/goals">
                        <i className="icon icon-star" />
                        <IntlMessages id="sidebar.goals" />
                      </Link>
                    </Menu.Item>
                  ) : (
                      <div className="gx-feature-disabled">
                        Feature Flag Disabled by Optimizely
                      </div>
                    )}

                  {this.props.optimizely.isFeatureEnabled('transactions') ? (
                    <Menu.Item key="transactions">
                      <Link to="/app/transactions">
                        <i className="icon icon-revenue-new" />
                        <IntlMessages id="sidebar.transactions" />
                      </Link>
                    </Menu.Item>
                  ) : (
                      <div className="gx-feature-disabled">
                        Feature Flag Disabled by Optimizely
                      </div>
                    )}
                </SubMenu>
                <SubMenu
                  key="network"
                  className={this.getNavStyleSubMenuClass(navStyle)}
                  // onTitleClick={() => history.push('/app/network')}
                  title={
                    <span>
                      <i className="icon icon-revenue-new" />
                      <IntlMessages id="sidebar.network" />
                    </span>
                  }>
                  <Menu.Item key="wall">
                    <Link to="/app/wall">
                      <i className="icon icon-avatar -flex-column-reverse" />
                      <IntlMessages id="sidebar.wall" />
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="forum">
                    <Link to="/app/forum">
                      <i className="icon icon-team -flex-column-reverse" />
                      <IntlMessages id="sidebar.forum" />
                    </Link>
                  </Menu.Item>

                  {/* <Menu.Item key="profile">
                    <Link to={`/app/user/${FirebaseAuth.auth.currentUser.uid}`}>
                      <i className="icon icon-profile2" />
                      <IntlMessages id="sidebar.profile" />
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="gallery">
                    <Link
                      to={{
                        pathname: `/app/user/${FirebaseAuth.auth.currentUser.uid}/gallery`,
                        userId: FirebaseAuth.auth.currentUser.uid,
                      }}>
                      <i className="icon icon-image" />
                      <IntlMessages id="sidebar.gallery" />
                    </Link>
                  </Menu.Item> */}

                  <Menu.Item key="messages">
                    <Link to="/app/messages">
                      <i className="icon icon-chat-bubble" />
                      <IntlMessages id="sidebar.messages" />
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="tasks">
                    <Link to="/app/tasks">
                      <i className="icon icon-check-square-o" />
                      <IntlMessages id="sidebar.tasks" />
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="groups">
                    <Link to="/app/groups">
                      <i className="icon icon-social" />
                      <IntlMessages id="sidebar.groups" />
                    </Link>
                  </Menu.Item>
                </SubMenu>

                <Menu.Item key="coaches">
                  <Link to="/app/coaches">
                    <i className="icon icon-team" />
                    <IntlMessages id="sidebar.coaches" />
                  </Link>
                </Menu.Item>

                <Menu.Item key="settings">
                  <Link to="/app/settings">
                    <i className="icon icon-setting" />
                    <IntlMessages id="sidebar.settings" />
                  </Link>
                </Menu.Item>

                {this.props.optimizely.isFeatureEnabled('link_account') ? (
                  <Menu.Item key="connect">
                    <PlaidMenuLink>
                      <i className="icon icon-add" />
                      <IntlMessages id="sidebar.connect" />
                    </PlaidMenuLink>
                  </Menu.Item>
                ) : (
                    <div className="gx-feature-disabled">
                      Feature Flag Disabled by Optimizely
                    </div>
                  )}
              </MenuItemGroup>
              <div className="sidebar-flex-menu"></div>
            </Menu>

            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
              className="sidebase-menu"
              mode="inline">
            </Menu>

            <Affix offsetBottom={0}>
              <Menu
                style={{ borderTop: '1px #fff' }}
                defaultOpenKeys={[defaultOpenKeys]}
                selectedKeys={[selectedKeys]}
                theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
                className="sidebase-menu"
                mode="inline">
                <span className="ant-menu-dark ant-menu-item">
                  <Row>
                    <Col xl={{ span: 18, offset: 12 }}>
                      <IoIosMore style={{ height: '30px' }} className="ant-menu-dark ant-menu-item" />
                    </Col>
                  </Row>
                </span>
                <Menu.Item key="help">
                  <Link to="/app/help">
                    <i className="icon icon-question-circle" />
                    <IntlMessages id="sidebar.help" />
                  </Link>
                </Menu.Item>

                <Menu.Item key="signout">
                  <Link to="/signout">
                    <i className="icon icon-lock-screen" />
                    <IntlMessages id="sidebar.signout" />
                  </Link>
                </Menu.Item>

              </Menu>
            </Affix>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};

const mapStateToProps = ({ settings }) => {
  const { navStyle, themeType, locale, pathname } = settings;
  return { navStyle, themeType, locale, pathname };
};

const mapDispatchToProps = {
  userSignOut,
};

export default withOptimizely(
  connect(mapStateToProps, mapDispatchToProps)(SidebarContent),
);
