import React from 'react';
import {Button, Form, Input, message} from 'antd/lib';
import IntlMessages from 'util/IntlMessages';
import FirebaseAuth from 'firebase/firebaseAuth';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';
const FormItem = Form.Item;

const ResetPassword = ({profile}) => {
  const intlMessages = useFormatMessage();
  const [form] = Form.useForm();

  const changePassword = async input => {
    let formValues = form.getFieldsValue([
      'currentPassword',
      'newPassword',
      'confirmPassword',
    ]);

    if (formValues.newPassword !== formValues.confirmPassword) {
      message.error(intlMessages('appModule.confirmPasswordMismatch'));
    } else if (formValues.oldPassword === formValues.newPassword) {
      message.error(intlMessages('appModule.sameNewPasswordError'));
    } else {
      formValues = {
        ...formValues,
        email: profile.email,
      };
      try {
        await FirebaseAuth.changePassword(formValues);
        form.resetFields();
        message.success(intlMessages('appModule.passwordChangedSuccessfully'));
      } catch (error) {
        message.error(error.message);
      }
    }
  };

  return (
    <div className="gx-login-container">
      <div className="gx-login-content">
        <div className="gx-mb-4">
          <h2>Update Your Password</h2>
        </div>

        <Form
          form={form}
          onFinish={changePassword}
          className="gx-login-form gx-form-row">
          <FormItem
            name="currentPassword"
            rules={[
              {
                required: true,
                type: 'string',
                message: 'Please enter your current password',
              },
            ]}>
            <Input type="password" placeholder="Your current password" />
          </FormItem>

          <FormItem
            name="newPassword"
            rules={[
              {
                required: true,
                type: 'string',
                message: 'Please enter minimum 6 characters new password!',
                min: 6,
              },
            ]}>
            <Input type="password" placeholder="New Password" />
          </FormItem>

          <FormItem
            name="confirmPassword"
            rules={[
              {
                required: true,
                type: 'string',
                message: 'Please confirm your new password',
                min: 6,
              },
            ]}>
            <Input placeholder="Retype New Password" type="password" />
          </FormItem>

          <FormItem>
            <Button type="primary" htmlType="submit">
              <IntlMessages id="app.userAuth.resetPassword" />
            </Button>
          </FormItem>
        </Form>
      </div>
    </div>
  );
};

export default ResetPassword;
