import React, { useState, useEffect } from 'react';
import { Modal, Steps, message, Form, Select } from 'antd';
import { FaBullseye, FaUserCircle, FaEye } from 'react-icons/fa';
import { GOAL_IMAGE_DIRECTORY } from 'util/constants';
import GoalFormAccount from './GoalFormAccount';
import GoalFormDetails from './GoalFormDetails';
import GoalFormPreview from './GoalFormPreview';
import GoalFormButtons from './GoalFormButtons';
import FirebaseGoals from 'firebase/firebaseGoals';
import _ from 'lodash';
import { calculateMonthly } from 'util/goalAmountCalculator';
import moment from 'moment';

const dayFormat = 'D-MMM-YYYY';

const GoalForm = ({ goal, setGoal, accounts, visible, setVisible }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [goalName, setGoalName] = useState();
  const [goalDescription, setGoalDescription] = useState();
  const [goalAmount, setGoalAmount] = useState();
  const [goalImage, setGoalImage] = useState();
  const [tempImage, setTempImage] = useState(null);
  const [goalPlannedDate, setGoalPlannedDate] = useState();
  const [goalAccount, setGoalAccount] = useState(null);
  const [goalAccountOption, setGoalAccountOption] = useState('later');
  const [goalMonthlyAmount, setGoalMonthlyAmount] = useState();
  const [goalAccountBalance, setGoalAccountBalance] = useState(0);
  const [goalAmountNeeded, setGoalAmountNeeded] = useState();
  const [addAccount, setAddAccount] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [projectedDateMessage, setProjectedDateMessage] = useState('');
  const [isCustomMonthlyContrib, setIsCustomMonthlyContrib] = useState(false);
  const [minimumMonthlyAmount, setMinimumMonthlyAmount] = useState(0);
  const [saving, setSaving] = useState(false);
  const { Option } = Select;
  let accountsList = [];
  const { Step } = Steps;
  const [form] = Form.useForm();

  useEffect(() => {
    if (Object.keys(goal).length) {
      let existingGoal = {
        ...goal,
        goalPlannedDate: moment(new Date(goal.goalPlannedDate * 1000), dayFormat),
      };
      setGoalName(goal.name);
      setGoalAmount(goal.goalAmount);
      setGoalDescription(goal.description);
      setTempImage(goal.image);
      setGoalPlannedDate(moment(new Date(goal.goalPlannedDate * 1000), dayFormat));
      setGoalAccountOption('later');
      if (goal.accountId) {
        setGoalAccountOption('existing');
        setGoalAccount(goal.accountId);
        existingGoal = { ...existingGoal, account: goal.accountId };
      }

      if (
        typeof goal.currentAmount !== 'undefined' &&
        goal.currentAmount !== null &&
        goal.currentAmount !== ''
      ) {
        setGoalAccountBalance(goal.currentAmount || 0);
      }

      setGoalMonthlyAmount(goal.monthlyContribution);
      form.setFieldsValue(existingGoal);
    } // eslint-disable-next-line
  }, [goal, visible]);

  const selectAccountFromNewAccount = account => {
    if (account) {
      const savedAccount = _.find(accounts, { ...account });
      if (savedAccount) {
        setGoalAccountOption('existing');
        form.setFieldsValue({ account: savedAccount.id });
        setGoalAccount(savedAccount.id);
        setGoalAccountBalance(account.balance);
      }
    }
  };

  if (accounts && accounts.length) {
    accountsList = accounts.map(account => (
      <Option value={account.id} key={account.id}>
        {account.name}
      </Option>
    ));
  }

  useEffect(() => {
    if (goalAccountOption === 'existing') {
      const theAccount = _.find(accounts, { id: goalAccount });
      if (
        theAccount &&
        typeof theAccount.balance !== 'undefined' &&
        theAccount.balance !== null
      ) {
        setGoalAccountBalance(parseInt(theAccount.balance, 10).toFixed(2));
      }
    }
  }, [accounts, goalAccount, goalAccountOption]);

  useEffect(() => {
    const monthlyParams = {
      goalAmount,
      goalPlannedDate,
      goalAmountNeeded,
      goalMonthlyAmount,
      goalAccountBalance,
      setGoalMonthlyAmount,
      isCustomMonthlyContrib,
      setMinimumMonthlyAmount,
      setProjectedDateMessage,
    };
    calculateMonthly(monthlyParams);
  }, [
    goalAmount,
    goalPlannedDate,
    goalAmountNeeded,
    goalMonthlyAmount,
    goalAccountBalance,
    projectedDateMessage,
    isCustomMonthlyContrib,
  ]);

  useEffect(() => {
    if (typeof goalAmount === 'number' && goalAccountBalance !== null) {
      setGoalAmountNeeded(goalAmount - goalAccountBalance);
    }
  }, [goalAmount, goalAccountBalance, goalAccountOption, goalAccount]);

  const onChangePlannedDate = (date, dateString) => {
    setGoalPlannedDate(date);
  };

  const resetForm = () => {
    setGoal({});
    setVisible(false);
    setGoalName();
    setGoalDescription();
    setGoalAmount();
    setTempImage();
    setGoalImage();
    setGoalMonthlyAmount();
    setGoalPlannedDate();
    setGoalAmountNeeded();
    setGoalAccountBalance(0);
    setCurrentStep(0);
    setProjectedDateMessage();
    setGoalAccountOption('later');
    setGoalAccount(null);
    setSaving(false);
    form.resetFields();
  };
  const handleCancel = () => {
    resetForm();
    setGoal({});
  };

  const handleSubmit = () => {
    setSaving(true);
    let formFields = {
      name: goalName,
      description: goalDescription || '',
      goalAmount: parseInt(goalAmount, 10),
      goalPlannedDate: Math.floor(new Date(goalPlannedDate.format(dayFormat)).getTime() / 1000),
      currentAmount: parseInt(goalAccountBalance, 10),
      monthlyContribution: parseInt(goalMonthlyAmount, 10),
      accountId: goalAccount,
      createdAt: moment().format(),
    };

    const createGoal = () => {
      FirebaseGoals.addGoal(formFields);
      message.success('Goal Saved!');
      resetForm();
    };

    const updateGoal = () => {
      FirebaseGoals.updateGoal(formFields);
      message.success('Goal Saved!');
      resetForm();
    };

    const saveToFirebase = () => {
      if (Object.keys(goal).length && goal.id) {
        formFields = { ...formFields, id: goal.id };
        updateGoal();
      } else {
        createGoal();
      }
    };
    if (!goalImage) {
      if (!tempImage) formFields = { ...formFields, image: null };
      saveToFirebase();
    } else {
      const filename = `${moment().format(
        'YYYY-MM-DD-HH-mm-ss',
      )}-${goalImage.file.name.replace(' ', '-')}`;
      try {
        FirebaseGoals.uploadGoalImage(goalImage, filename).then(response => {
          const { state, metadata } = response;
          const image = `${GOAL_IMAGE_DIRECTORY}/${
            metadata.bucket
            }/o/${encodeURIComponent(metadata.fullPath)}?alt=media`;
          if (state === 'success') {
            formFields = { ...formFields, image };
            saveToFirebase();
          } else {
            message.error('Saving failed');
          }
        });
      } catch (error) {
        message.error(error);
      }
    }
  };

  const goalFormSteps = [
    {
      title: 'Goal',
      icon: <FaBullseye />,
      content: (
        <GoalFormDetails
          tempImage={tempImage}
          goalName={goalName}
          goalAmount={goalAmount}
          setGoalName={setGoalName}
          setGoalImage={setGoalImage}
          setTempImage={setTempImage}
          setGoalAmount={setGoalAmount}
          goalPlannedDate={goalPlannedDate}
          goalDescription={goalDescription}
          setGoalDescription={setGoalDescription}
          onChangePlannedDate={onChangePlannedDate}
        />
      ),
    },
    {
      title: 'Account',
      icon: <FaUserCircle />,
      content: (
        <GoalFormAccount
          accounts={accounts}
          showModal={showModal}
          addAccount={addAccount}
          goalAccount={goalAccount}
          accountsList={accountsList}
          setShowModal={setShowModal}
          setAddAccount={setAddAccount}
          setGoalAccount={setGoalAccount}
          goalAccountOption={goalAccountOption}
          setGoalAccountOption={setGoalAccountOption}
          setGoalAccountBalance={setGoalAccountBalance}
          selectAccountFromNewAccount={selectAccountFromNewAccount}
        />
      ),
    },
    {
      title: 'Preview',
      icon: <FaEye />,
      content: (
        <GoalFormPreview
          goalName={goalName}
          goalAmount={goalAmount}
          goalAccount={goalAccount}
          setGoalName={setGoalName}
          setGoalAmount={setGoalAmount}
          goalPlannedDate={goalPlannedDate}
          goalAmountNeeded={goalAmountNeeded}
          goalMonthlyAmount={goalMonthlyAmount}
          goalAccountBalance={goalAccountBalance}
          onChangePlannedDate={onChangePlannedDate}
          setGoalAmountNeeded={setGoalAmountNeeded}
          setGoalMonthlyAmount={setGoalMonthlyAmount}
          minimumMonthlyAmount={minimumMonthlyAmount}
          projectedDateMessage={projectedDateMessage}
          setGoalAccountBalance={setGoalAccountBalance}
          isCustomMonthlyContrib={isCustomMonthlyContrib}
          setIsCustomMonthlyContrib={setIsCustomMonthlyContrib}
        />
      ),
    },
  ];

  const next = () => {
    form
      .validateFields()
      .then(() => {
        setCurrentStep(currentStep + 1);
      })
      .catch(error => {
        if (error) return;
      });
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <Modal
      title={Object.keys(goal).length ? 'Edit Goal' : 'Create Goal'}
      visible={visible}
      width="50%"
      maskClosable={false}
      okText="Save Goal"
      onCancel={handleCancel}
      footer={false}>
      <div className="goals-form">
        <Steps current={currentStep}>
          {goalFormSteps.map(step => (
            <Step
              key={step.title}
              title={step.title}
              description={step.description}
              icon={step.icon}
            />
          ))}
        </Steps>

        <div className="steps-content">
          <Form form={form} onFinish={handleSubmit}>
            {goalFormSteps[currentStep].content}
          </Form>
        </div>
        <div className="steps-action">
          <GoalFormButtons
            handleCancel={handleCancel}
            currentStep={currentStep}
            goalFormSteps={goalFormSteps}
            next={next}
            prev={prev}
            saveGoal={handleSubmit}
            saving={saving}
          />
        </div>
      </div>
    </Modal>
  );
};

export default GoalForm;
