import React, {useEffect, useReducer} from 'react';
import uuid from 'uuid';
import {Wizard, Steps, Step} from 'react-albus';
import {BrowserRouter, Route} from 'react-router-dom';

import FirebaseCares from 'firebase/firebaseCARES';
import StepWelcome from './steps/stepWelcome';
import StepStateResidence from './steps/stepStateResidence';
import StepEmployment from './steps/stepEmployment';
import StepTaxes from './steps/stepTaxes';
import StepPreTaxes from './steps/stepPreTaxes';
import StepBusiness from './steps/stepBusiness';
import StepGig from './steps/stepGig';
import StepNonProfit from './steps/stepNonProfit';
import StepEmail from './steps/stepEmail';
import StepRecommendations from './steps/stepRecommendations';
import StepUpload from './steps/stepUpload';
import './settingsWizard.less';
import ReactZenDeskChat from '@inlightmedia/react-zendesk-chat';
import CssBaseline from '@material-ui/core/CssBaseline';

import {
  ThemeProvider,
  createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import blue from '@material-ui/core/colors/blue';

const primary = {light: blue[300], main: blue[500], dark: blue[700]}; // #F44336
const secondary = {light: orange[300], main: orange[500], dark: orange[700]}; // #F44336
// const secondary = {light: purple[300], main: purple[500], dark: purple[700]}; // #F44336

let theme = createMuiTheme({
  palette: {
    primary,
    secondary,
  },
  status: {
    danger: 'orange',
  },
  overrides: {
    MuiStepper: {
      // text: {
      //   fill: '#D3D3D3',
      // },
      root: {
        padding: '0px',
        // color: 'white',
        // border: 'solid',
        // borderColor: '#D3D3D3',
        // borderRadius: 25,
        // borderWidth: 1,

        // '&$active': {
        //   border: 'solid',
        //   // borderColor: Colors.primaryColor,
        //   borderRadius: 25,
        //   fill: 'white',
        //   borderWidth: 1,
        // },
        // '&$completed': {
        //   border: 'none',
        // },
      },
    },
  },
});

theme.typography.h1 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '4rem',
  },
};

// theme = responsiveFontSizes(theme);

// export const INCOME_TYPES = [
//   {
//     label: 'Business owner / self employed',
//     value: 'BUSINESS_OWNER',
//   },
//   {
//     label: 'Non-business owner',
//     value: 'NON_BUSINESS_OWNER',
//   },
// ];

const initialState = {
  id: uuid(),
  estimatedAmt: 0,
  date_created: Math.floor(new Date().getTime() / 1000),
  maritalStatus: 'single',
  taxFileType: 'single',
  haveChildren: false,
  numChildren: 0,
  employmentStatus: 'employed',
  covidEmploymentImpact: false,
  workState: 'AL',
  filedFor2019: true,
  filedFor2018: false,
  agi2019: 0,
  agiEstimated2019: 0,
  agi2018: 0,
  ownBusiness: false,
  numEmployees: 0,
  covidBusinessImpact: false,
  avgMonthlyPayroll: 0,
  avgMonthlyPayrollGig: 0,
  avgMonthlyPayrollNonProfit: 0,
  ownGig: false,
  covidGigImpact: false,
  ownNonProfit: false,
  covidNonProfitImpact: false,
  nonProfitState: 'AL',
  taxStepVisited: false,
  residenceState: 'AL',
  numFte: 0,
  covidImpact: false,
  businessState: 'AL',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  persist: true,
};

function reducer(state, action) {
  FirebaseCares.analytics.logEvent('cares_form_' + action.type, {
    payload: action.payload,
  });
  switch (action.type) {
    case 'hideModal':
      return {...state, showModal: false};
    default:
      return {
        ...state,
        [action.type]: action.payload,
      };
  }
}

const SettingsWizard = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (state.persist) {
      if (!state.id) {
        async function createApplication() {
          let id = await FirebaseCares.createApplication();
          console.log('Creating appliction with ID: ', id);
          dispatch({type: 'id', payload: id});
        }
        createApplication();
      } else {
        console.log(`State changed: ${JSON.stringify(state)}`);
        async function updateApplication() {
          console.log('Updating appliction with ID: ', state.id);
          await FirebaseCares.updateApplication(state);
        }
        updateApplication();
      }
    }
  }, [state]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <div className="content">
        <Route
          render={({history}) => (
            <Wizard history={history}>
              <Steps>
                {/* <Step
                  id="welcome"
                  render={({next, push}) => {
                    FirebaseCares.analytics.logEvent(
                      'cares_page_enter_welcome',
                      {
                        payload: JSON.stringify(state),
                      },
                    );
                    return (
                      <StepWelcome
                        theme={theme}
                        firebase={FirebaseCares}
                        next={next}
                      />
                    );
                  }}
                /> */}
                <Step
                  id="state_residence"
                  render={({next, previous, push}) => {
                    FirebaseCares.analytics.logEvent(
                      'cares_page_enter_state_residence',
                      {
                        payload: JSON.stringify(state),
                      },
                    );
                    return (
                      <StepStateResidence
                        currentWizardStep={0}
                        firebase={FirebaseCares}
                        push={push}
                        previous={previous}
                        next={next}
                        state={state}
                        dispatch={dispatch}
                      />
                    );
                  }}
                />
                <Step
                  id="employment"
                  render={({next, previous, push}) => {
                    FirebaseCares.analytics.logEvent(
                      'cares_page_enter_employment',
                      {
                        payload: JSON.stringify(state),
                      },
                    );
                    return (
                      <StepEmployment
                        currentWizardStep={0}
                        firebase={FirebaseCares}
                        push={push}
                        previous={previous}
                        next={next}
                        state={state}
                        dispatch={dispatch}
                      />
                    );
                  }}
                />
                <Step
                  id="pretaxes"
                  render={({next, previous, push}) => {
                    FirebaseCares.analytics.logEvent(
                      'cares_page_enter_pretaxes',
                      {
                        payload: JSON.stringify(state),
                      },
                    );
                    return (
                      <StepPreTaxes
                        currentWizardStep={0}
                        firebase={FirebaseCares}
                        push={push}
                        previous={previous}
                        next={next}
                        state={state}
                        dispatch={dispatch}
                      />
                    );
                  }}
                />
                <Step
                  id="taxes"
                  render={({next, previous, push}) => {
                    FirebaseCares.analytics.logEvent('cares_page_enter_taxes', {
                      payload: JSON.stringify(state),
                    });
                    return (
                      <StepTaxes
                        currentWizardStep={0}
                        firebase={FirebaseCares}
                        push={push}
                        previous={previous}
                        next={next}
                        state={state}
                        dispatch={dispatch}
                      />
                    );
                  }}
                />
                <Step
                  id="business"
                  render={({next, previous, push}) => {
                    FirebaseCares.analytics.logEvent(
                      'cares_page_enter_business',
                      {
                        payload: JSON.stringify(state),
                      },
                    );
                    return (
                      <StepBusiness
                        currentWizardStep={0}
                        firebase={FirebaseCares}
                        push={push}
                        previous={previous}
                        next={next}
                        state={state}
                        dispatch={dispatch}
                      />
                    );
                  }}
                />
                <Step
                  id="gig"
                  render={({next, previous, push}) => {
                    FirebaseCares.analytics.logEvent('cares_page_enter_gig', {
                      payload: JSON.stringify(state),
                    });
                    return (
                      <StepGig
                        currentWizardStep={0}
                        firebase={FirebaseCares}
                        push={push}
                        previous={previous}
                        next={next}
                        state={state}
                        dispatch={dispatch}
                      />
                    );
                  }}
                />
                <Step
                  id="nonprofit"
                  render={({next, previous, push}) => {
                    FirebaseCares.analytics.logEvent(
                      'cares_page_enter_nonprofit',
                      {
                        payload: JSON.stringify(state),
                      },
                    );
                    return (
                      <StepNonProfit
                        currentWizardStep={0}
                        firebase={FirebaseCares}
                        push={push}
                        previous={previous}
                        next={next}
                        state={state}
                        dispatch={dispatch}
                      />
                    );
                  }}
                />
                <Step
                  id="email"
                  render={({next, previous, push}) => {
                    FirebaseCares.analytics.logEvent('cares_page_enter_email', {
                      payload: JSON.stringify(state),
                    });
                    return (
                      <StepEmail
                        currentWizardStep={1}
                        firebase={FirebaseCares}
                        push={push}
                        previous={previous}
                        next={next}
                        state={state}
                        dispatch={dispatch}
                      />
                    );
                  }}
                />
                <Step
                  id="recommendations"
                  render={({next, previous, push}) => {
                    FirebaseCares.analytics.logEvent(
                      'cares_page_enter_recommendations',
                      {
                        payload: JSON.stringify(state),
                      },
                    );
                    return (
                      <StepRecommendations
                        currentWizardStep={1}
                        firebase={FirebaseCares}
                        push={push}
                        previous={previous}
                        next={next}
                        state={state}
                        dispatch={dispatch}
                        hideButtons={true}
                      />
                    );
                  }}
                />

                <Step
                  id="upload"
                  render={({next, previous, push}) => {
                    FirebaseCares.analytics.logEvent(
                      'cares_page_enter_upload',
                      {
                        payload: JSON.stringify(state),
                      },
                    );
                    return (
                      <StepUpload
                        currentWizardStep={2}
                        firebase={FirebaseCares}
                        push={push}
                        previous={previous}
                        next={next}
                        state={state}
                        dispatch={dispatch}
                        hideButtons={true}
                      />
                    );
                  }}
                />
              </Steps>
            </Wizard>
          )}
        />
      </div>
      {/* <ReactZenDeskChat appID="41kJX43Ml1g2B9E5xy90crFCHAZcwNTZ" /> */}
    </ThemeProvider>
  );
};

export default SettingsWizard;
