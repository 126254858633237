import app from 'firebase/app';
import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import config from './firebaseConfig';

class FirebaseVlogs {
  constructor() {
    if (!firebase.apps.length) {
      app.initializeApp(config);
    }
    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
  }

  async uploadVlogVideo(file, name) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    const vlog = this.storage
      .ref()
      .child(`vlogs/${this.auth.currentUser.uid}/${name}`);
    const upload = await vlog.put(file);
    return upload.ref.getDownloadURL().then(downloadURL => {
      return downloadURL;
    });
  }

  saveVlogPost(values) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let name = new Date().getTime();
    this.storage
      .ref()
      .child(`vlogs/${this.auth.currentUser.uid}/${name}`)
      .put(values.video)
      .then(snapshot => {
        snapshot.ref.getDownloadURL().then(downloadURL => {
          this.addTags(values.tags);
          this.db
            .collection('vlogs')
            .doc(this.auth.currentUser.uid.toString())
            .collection('videos')
            .doc()
            .set({ ...values, video: downloadURL, videoName: name });
        });
      });

    // this.addTags(values.tags);
    // return this.db
    //     .collection('vlogs')
    //     .doc(this.auth.currentUser.uid.toString())
    //     .collection('videos')
    //     .doc()
    //     .set(values);
  }

  getusersVlogs(id) {
    if (this.auth.currentUser.uid === id) {
      return this.db
        .collection('vlogs')
        .doc(`${id}`)
        .collection('videos');
    } else {
      return this.db
        .collection('vlogs')
        .doc(`${id}`)
        .collection('videos')
        .where('published', '==', true);
    }
  }

  getVlogDetail(input) {
    let vRef = this.db
      .collection('vlogs')
      .doc(input.userId)
      .collection('videos');
    return vRef.doc(input.id);
  }

  addTags(tags) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let uniqueTags = [];
    tags.forEach(tag => {
      this.db
        .collection('users')
        .doc(`${this.auth.currentUser.uid}`)
        .collection('tags')
        .where('tag.title', '==', tag.title)
        .get()
        .then(snapshot => {
          if (snapshot.empty) {
            this.db
              .collection('users')
              .doc(`${this.auth.currentUser.uid}`)
              .collection('tags')
              .add({ tag: tag });
            uniqueTags.push(tag);
          } else {
            snapshot.forEach(doc => {
              uniqueTags.push(doc.data().tag);
            });
          }
        })
        .catch(err => {
          console.log('Error getting documents', err);
        });
    });
  }

  updateVlogInfo(values) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    this.addTags(values.tags);
    return this.db
      .collection('vlogs')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('videos')
      .doc(`${values.id}`)
      .update({
        date: new Date().toString(),
        title: values.title,
        description: values.description,
        tags: values.tags,
        published: values.published,
      });
  }

  updatePublishStatus(values) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    this.db
      .collection('vlogs')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('videos')
      .doc(`${values.id}`)
      .update({
        date: new Date().toString(),
        published: values.published === true ? false : true,
      });
  }

  deleteVlog(values) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let batch = this.db.batch();
    let deleteVlog = this.db
      .collection('vlogs')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('videos')
      .doc(`${values.id}`);
    batch.delete(deleteVlog);
    batch.commit();
  }

  likeUnLikeVideo(values) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    this.db
      .collection('vlogs')
      .doc(`${values.userId}`)
      .collection('videos')
      .doc(`${values.id}`)
      .update({
        likes: values.likes,
      });
  }

  getVlogsComments(values) {
    return this.db
      .collection('vlogs')
      .doc(`${values.userId}`)
      .collection('videos')
      .doc(values.id)
      .collection('comments')
      .orderBy('date', 'desc');
  }

  commentVlog(vlog, commentData) {
    let userRef = this.db
      .collection('vlogs')
      .doc(`${vlog.userId}`)
      .collection('videos')
      .doc(vlog.id);
    // Comment
    userRef
      .collection('comments')
      .doc()
      .set(commentData);
    //CommentCount
    userRef.update({ commentCount: firebase.firestore.FieldValue.increment(1) });
  }

  likeUnLikeVideoComment(values) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    this.db
      .collection('vlogs')
      .doc(`${values.userId}`)
      .collection('videos')
      .doc(`${values.docId}`)
      .collection('comments')
      .doc(`${values.id}`)
      .update({
        likes: values.likes,
      });
  }
}

export default new FirebaseVlogs();
