import React from 'react';
import {Button} from 'antd/lib';
import {Link} from 'react-router-dom';
import DisplayDate from 'util/DisplayDate';
import FirebaseGroups from 'firebase/firebaseGroups';
import {useCollectionData} from 'react-firebase-hooks/firestore';

const GroupItemCard = ({
  profile,
  data,
  editGroup,
  onDeleteClick,
  groupJoinRequest,
  manageGroupMembers,
}) => {
  // Get ReqUest of users to join Group.
  const [groupJoinRequests] = useCollectionData(
    FirebaseGroups.getGroupJoinRequests(data.id),
    {
      idField: 'id',
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const approveGroupJoinRequests = () => {
    groupJoinRequest(data, groupJoinRequests);
  };

  const manageMembers = () => {
    manageGroupMembers(data);
  };

  return (
    <div className="gx-media gx-featured-item">
      <div className="gx-featured-thumb">
        <Link
          to={{
            pathname: `/app/group/${profile.vanityURL}/${data.id}`,
            userId: data.userId,
          }}>
          <img
            className="gx-rounded-lg gx-width-175"
            src={data.groupCoverPhoto.downloadURL}
            alt="..."
          />
        </Link>
      </div>
      <div className="gx-media-body gx-featured-content">
        <div className="gx-featured-content-left">
          <h3 className="gx-mb-2">
            <Link
              to={{
                pathname: `/app/group/${profile.vanityURL}/${data.id}`,
                userId: data.userId,
              }}>
              {data.groupName}
            </Link>
          </h3>
          <p className="gx-text-grey gx-mb-1">{data.groupDescription}</p>
          <div className="ant-row-flex">
            <p className="gx-text-grey gx-mb-1">
              <i
                className={`icon icon-search gx-fs-xs gx-mr-2 gx-d-inline-flex gx-vertical-align-middle`}
              />
              {data.groupSearchable === 0
                ? 'Only Member Searchable'
                : 'Public Searchable'}
            </p>
            <p className="gx-text-grey gx-mb-1">
              <i
                className={`icon icon-team gx-fs-xs gx-mr-2 gx-d-inline-flex gx-vertical-align-middle`}
              />
              {data.membersCount
                ? data.membersCount > 1
                  ? `${data.membersCount} Members`
                  : `${data.membersCount} Member`
                : `0 Member`}
            </p>
          </div>
          <div className="ant-row-flex">
            <p className="gx-text-grey gx-mb-1">
              <i
                className={`icon icon-compose gx-fs-xs gx-mr-2 gx-d-inline-flex gx-vertical-align-middle`}
              />
              {data.groupPostsCount
                ? data.groupPostsCount > 1
                  ? `${data.groupPostsCount} Posts`
                  : `${data.groupPostsCount} Post`
                : `0 Post`}
            </p>
            <p className="gx-text-grey gx-mb-1">
              <i
                className={`icon icon-datepicker gx-fs-xs gx-mr-2 gx-d-inline-flex gx-vertical-align-middle`}
              />
              {<DisplayDate date={data.createdOn.toString()} />}
            </p>
          </div>
        </div>
        <div className="gx-featured-content-right">
          <p>
            <Button
              className="gx-ml-auto gx-mb-0 gx-btn-block "
              key={data.id + 'view'}>
              <Link
                to={{
                  pathname: `/app/group/${profile.vanityURL}/${data.id}`,
                  userId: data.userId,
                }}>
                View Detail
              </Link>
            </Button>
          </p>
          <p>
            <Button
              className="gx-ml-auto gx-mb-0 gx-btn-block"
              onClick={() => editGroup(data)}
              key={data.id + 'edit'}>
              Edit Details
            </Button>
          </p>
          <p>
            {groupJoinRequests && groupJoinRequests.length ? (
              <Button
                type="primary"
                className="gx-ml-auto gx-mb-0 gx-btn-block"
                onClick={() => approveGroupJoinRequests()}
                key={data.id + 'approve'}>
                Approve Members ({groupJoinRequests.length})
              </Button>
            ) : (
              <Button
                className="gx-ml-auto gx-mb-0 gx-btn-block "
                key={data.id + 'approve'}>
                Approve Members (0)
              </Button>
            )}
          </p>
          <p>
            <Button
              type="primary"
              className="gx-ml-auto gx-mb-0 gx-btn-block"
              onClick={() => manageMembers()}
              key={data.id + 'Member'}>
              Manage Members
            </Button>
          </p>
          <p>
            <Button
              type="danger"
              className="gx-ml-auto gx-mb-0 gx-btn-block"
              onClick={() => onDeleteClick(data)}
              key={data.id + 'delete'}>
              Delete
            </Button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default GroupItemCard;
