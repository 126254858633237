import React, { useState, useEffect } from 'react';
import Widget from 'components/Widget/index';
import { useSpring, animated } from 'react-spring';
import './widgets.less';
import AnimatedCurrencyDisplay from 'components/common/AnimatedCurrencyDisplay'

const LoansChartCard = ({ chartProperties, children }) => {

  const { prize, title, styleName, desc, bgColor, percent, spring } = chartProperties;
  const [isHide, setIsHide] = useState(false)
  const [opacity, setOpacity] = useState(1)

  useEffect(() => {
    setOpacity(0)
    const timer = setTimeout(() => {
      setOpacity(1)
    }, 600);
    return () => clearTimeout(timer);
  }, [prize]);

  const opacityProps = useSpring({
    opacity: opacity,
    from: { opacity: 0 },
  })

  const handleToggle = () => {
    setIsHide(!isHide)
  }

  return (
    <Widget styleName={`gx-card-full`}>
      <div
        style={{ background: bgColor }}
        className={
          isHide === true
            ? `gx-fillchart gx-bg-${bgColor} gx-fillchart-nocontent`
            : `gx-fillchart gx-bg-${bgColor} gx-overlay-fillchart`
        }>
        {/* <div className="ant-card-head-title">{title}</div> */}
        {children}

        <div className="gx-fillchart-content">
          <div className="ant-card-head-title gx-mb-3">{title}</div>
          <h2 className="gx-mb-2 gx-fs-xxxl gx-font-weight-medium">
            {spring &&
              <AnimatedCurrencyDisplay
                minimum={prize}
                maximum={prize}
              />
            }
            {!spring && (
              <animated.span style={opacityProps}>{prize}</animated.span>
            )
            }
          </h2>
          {percent > 0}
          <p className="gx-mb-0 gx-fs-sm">
            <span
              className={`gx-font-weight-medium gx-fs-md gx-chart-${styleName}`}>
              {percent ? percent + '%' : null}
              {percent > 0 ? (
                <i className="icon icon-menu-up gx-fs-sm" />
              ) : null}
            </span>
            {desc}
          </p>
        </div>
        {
          children &&
          <>
            <div
              className="gx-fillchart-btn-close"
              onClick={handleToggle}>
              <i className="icon icon-close" />
            </div>
            <div
              className="gx-fillchart-btn"
              onClick={handleToggle}>
              <i className={`icon icon-stats gx-fs-xxxl`} />
            </div>
          </>
        }
      </div>

    </Widget>
  );
}

export default LoansChartCard;
