import app from 'firebase/app';
import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import config from './firebaseConfig';

class FirebasePlaid {
  constructor() {
    if (!firebase.apps.length) {
      app.initializeApp(config);
    }
    this.auth = app.auth();
    this.db = app.firestore();
    this.functions = app.functions();
    // For local emulator testing uncomment next line
    // this.functions.useFunctionsEmulator('http://localhost:5001');
    this.analytics = app.analytics();
  }

  getAccounts() {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    return this.db
      .collection('users')
      .doc(this.auth.currentUser.uid)
      .collection('plaid')
      .doc('accounts')
      .collection('active');
  }

  refreshAccounts() {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }

    return this.functions.httpsCallable('plaidSyncOnAccountsRefresh')();
  }

  exchangeToken(public_token, metadata) {
    return this.functions.httpsCallable('plaidAuthOnExchangeLinkToken')({
      public_token,
      metadata,
    });
  }
}

export default new FirebasePlaid();
