import './goals.less';
import React from 'react';
import GoalsList from 'components/goals/GoalsList';
import {useCollectionData} from 'react-firebase-hooks/firestore';
import FirebaseGoals from 'firebase/firebaseGoals';
import {OptimizelyFeature} from '@optimizely/react-sdk';

const Goals = () => {
  const [goals, loading, error] = useCollectionData(FirebaseGoals.getGoals(), {
    idField: 'id',
    snapshotListenOptions: {
      includeMetadataChanges: true,
    },
  });

  if (error) return <strong>Connection error!</strong>;
  if (loading) return <p>Loading...</p>;
  return (
    <OptimizelyFeature feature="goals">
      {isEnabled =>
        isEnabled ? (
          <GoalsList goals={goals} />
        ) : (
          <div className="gx-feature-disabled">
            Feature Flag Disabled by Optimizely
          </div>
        )
      }
    </OptimizelyFeature>
  );
};

export default Goals;
