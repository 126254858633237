import React, {useState} from 'react';
import {useCollectionData} from 'react-firebase-hooks/firestore';
import Widget from 'components/Widget';
import FirebaseCoaches from 'firebase/firebaseCoaches';
import {Col, Spin, Modal, message, Tabs} from 'antd/lib';
import {FaSpinner} from 'react-icons/fa';
import ListDisplayStyle from 'components/app/coaches/ListDisplayStyle';
import DisplayAppointmentList from 'components/app/coaches/userAppointments/DisplayAppointmentList';
import DisplayAppointmentGrid from 'components/app/coaches/userAppointments/DisplayAppointmentGrid';
import UpdateAppointment from 'components/app/coaches/booking/UpdateAppointment';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';
import IntlMessages from 'util/IntlMessages';
const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;
const {confirm} = Modal;
const {TabPane} = Tabs;

const CoachingAppointments = ({profile, coach}) => {
  const [bookingView, setBookingView] = useState('list');
  const [pastBookingView, setPastBookingView] = useState('list');
  const [booking, setBooking] = useState(null);
  const [showUpdateBooking, setShowUpdateBooking] = useState(false);
  const [availability, setAvailability] = useState([]);
  const [loadingavAilability, setLoadingavAilability] = useState(true);
  const [upcomingSearch, setUpcomingSearch] = useState(null);
  const [pastSearch, setPastSearch] = useState(null);
  const intlMessages = useFormatMessage();

  const [
    myAppointmens,
    appointmentsLoading,
    appointmentsError,
  ] = useCollectionData(
    coach
      ? FirebaseCoaches.getAppointmentsOfCoach()
      : FirebaseCoaches.getMyAppointmentWithCoaches({limit: 0}),
    {
      idField: 'id',
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  let allAppointments = myAppointmens;
  let currentDatetime = new Date().setHours(0, 0, 0, 0);
  let upcomingAppointments = [];
  let pastAppointments = [];

  if (allAppointments && allAppointments.length) {
    // Upcomiing
    upcomingAppointments = allAppointments.filter(
      appointment => appointment.date >= currentDatetime,
    );
    upcomingAppointments.sort((a, b) => a['date'] - b['date']);
    // Past
    pastAppointments = allAppointments.filter(
      appointment => appointment.date < currentDatetime,
    );
    pastAppointments.sort((a, b) => b['date'] - a['date']);
  }

  const onChangeDisplay = e => {
    setPastSearch(null);
    setUpcomingSearch(null);
    setBookingView(e.target.value);
  };

  const onChangePastDisplay = e => {
    setPastSearch(null);
    setUpcomingSearch(null);
    setPastBookingView(e.target.value);
  };

  const onUpcomingSearch = e => {
    setPastSearch(null);
    const text = e.target.value;
    if (text.length > 0) {
      setUpcomingSearch(text);
    } else {
      setUpcomingSearch(null);
    }
  };

  const onPastSearch = e => {
    setUpcomingSearch(null);
    const text = e.target.value;
    if (text.length > 0) {
      setPastSearch(text);
    } else {
      setPastSearch(null);
    }
  };

  const cancelAppointment = data => {
    confirm({
      title: 'Are you sure you want to cancel appointment?',
      onOk: () => {
        processCancelAppointment(data);
      },
      onCancel() {},
    });
  };

  const processCancelAppointment = async data => {
    await FirebaseCoaches.cancelAppointment(data);
    message.success(intlMessages('coach.appointment.canceled'));
  };

  const editMyAppoientment = async e => {
    let response = await FirebaseCoaches.geAvailibilityData(e.coach);
    setBooking(e);
    setShowUpdateBooking(true);
    setAvailability(response);
    setLoadingavAilability(false);
  };

  const hideUpdateBookingModal = () => {
    setShowUpdateBooking(false);
    setBooking({});
  };

  return (
    <>
      <Widget
        title={
          coach ? null : (
            <h4 className="h4 gx-text-capitalize gx-mb-0 gx-text-left gx-ml-1">
              <IntlMessages id="coach.appointment.history" />
            </h4>
          )
        }>
        {appointmentsError && (
          <strong>
            {' '}
            Error loading recent appointments. Please try again later{' '}
          </strong>
        )}
        {appointmentsLoading && <Spin indicator={spinnerIcon} />}
        <Tabs tabPosition="top" defaultActiveKey="1" style={{marginTop: '0px'}}>
          <TabPane tab="Upcoming" key="1">
            {upcomingAppointments && upcomingAppointments.length ? (
              <div className="gx-m-2">
                <div className="goals-heading">
                  <ListDisplayStyle
                    profile={profile}
                    key={'upcomingBooking'}
                    onPastSearch={onPastSearch}
                    onUpcomingSearch={onUpcomingSearch}
                    onChangeDisplay={onChangeDisplay}
                    bookingView={bookingView}
                    recent={false}
                    section={'booking'}
                  />
                </div>
                <div className="goals-display">
                  {bookingView === 'list' ? (
                    <div className="goals-display-list">
                      <DisplayAppointmentList
                        profile={profile}
                        data={upcomingAppointments}
                        editMyAppoientment={editMyAppoientment}
                        cancelAppointment={cancelAppointment}
                        upcomingSearch={upcomingSearch}
                        pastSearch={pastSearch}
                        section={coach ? 'coach' : null}
                      />
                    </div>
                  ) : (
                    <div className="goals-display-grid ant-row">
                      <DisplayAppointmentGrid
                        profile={profile}
                        data={upcomingAppointments}
                        editMyAppoientment={editMyAppoientment}
                        cancelAppointment={cancelAppointment}
                        upcomingSearch={upcomingSearch}
                        pastSearch={pastSearch}
                        section={coach ? 'coach' : null}
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              !appointmentsLoading && (
                <Col span={24}>
                  <Widget styleName="gx-card-full">
                    <div className="text-center gx-px-3 gx-pt-3 gx-pb-3 gx-mt-3">
                      <h3>You don't have any appointments!</h3>
                    </div>
                  </Widget>
                </Col>
              )
            )}
          </TabPane>
          <TabPane tab="Past" key="2">
            {pastAppointments && pastAppointments.length ? (
              <div className="gx-m-2">
                <div className="goals-heading">
                  <ListDisplayStyle
                    profile={profile}
                    key={'pasBooking'}
                    onUpcomingSearch={onUpcomingSearch}
                    onPastSearch={onPastSearch}
                    onChangeDisplay={onChangeDisplay}
                    onChangePastDisplay={onChangePastDisplay}
                    pastBookingView={pastBookingView}
                    recent={false}
                    section={'past'}
                  />
                </div>
                <div className="goals-display">
                  {pastBookingView === 'list' ? (
                    <div className="goals-display-list">
                      <DisplayAppointmentList
                        profile={profile}
                        data={pastAppointments}
                        editMyAppoientment={editMyAppoientment}
                        cancelAppointment={cancelAppointment}
                        canUpdate={false}
                        upcomingSearch={upcomingSearch}
                        pastSearch={pastSearch}
                        section={coach ? 'coach' : null}
                      />
                    </div>
                  ) : (
                    <div className="goals-display-grid ant-row">
                      <DisplayAppointmentGrid
                        profile={profile}
                        data={pastAppointments}
                        editMyAppoientment={editMyAppoientment}
                        cancelAppointment={cancelAppointment}
                        canUpdate={false}
                        upcomingSearch={upcomingSearch}
                        pastSearch={pastSearch}
                        section={coach ? 'coach' : null}
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              !appointmentsLoading && (
                <Col span={24}>
                  <Widget styleName="gx-card-full">
                    <div className="text-center gx-px-3 gx-pt-3 gx-pb-3 gx-mt-3">
                      <h3>You don't have any appointments!</h3>
                    </div>
                  </Widget>
                </Col>
              )
            )}
          </TabPane>
        </Tabs>
      </Widget>

      {booking && showUpdateBooking && !loadingavAilability && (
        <UpdateAppointment
          booking={booking}
          showModal={showUpdateBooking}
          onModalClose={hideUpdateBookingModal}
          coachAvailability={availability}
        />
      )}
    </>
  );
};

export default CoachingAppointments;
