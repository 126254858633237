import React, {Fragment} from 'react';
import {Row, Col} from 'antd';

const GoalFormField = ({label, field}) => {
  return (
    <Fragment>
      <Row>
        <Col span={24}>{label}</Col>
      </Row>
      <Row>{field}</Row>
    </Fragment>
  );
};

export default GoalFormField;
