import React from 'react';
import numeral from 'numeral';
import { useSpring, animated } from 'react-spring';
const AnimatedCurrencyDisplay = ({ minimum, maximum }) => {
    const props = useSpring({
        number: maximum,
        from: { number: minimum },
    });
    return (
        <animated.span>
            {props.number.interpolate(val =>
                numeral(Math.floor(val)).format('$0,0'),
            )}
        </animated.span>
    );
};

export default AnimatedCurrencyDisplay;
