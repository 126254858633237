import React, { useState } from 'react';
import { Table, Button, Modal, message, Badge, Row, Col } from 'antd/lib';
// import { FaPlus } from 'react-icons/fa';
import Widget from 'components/Widget/index';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import IntlMessages from 'util/IntlMessages';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import FirebaseFin from 'firebase/firebaseFin';
import { FirebaseDAO } from 'firebase/firebase';
import moment from 'moment';
import numeral from 'numeral';
import { TransactionCategories } from 'constants/staticData';
import TransactionModal from 'components/app/trasactions/TransactionModal';

const { confirm } = Modal;
const Transactions = ({ profile, section }) => {
  const intlMessages = useFormatMessage();
  const [fullData, setFullData] = useState(null);
  const [filterData, setFilterData] = useState(null);
  const [showModalForm, setShowModalForm] = useState(false);
  const [transactionEditData, setTransactionEditData] = useState(null);

  const [data] = useCollectionData(FirebaseFin.getTransactions(), {
    idField: 'id',
    snapshotListenOptions: {
      includeMetadataChanges: true,
    },
  });

  const [userTags] = useCollectionData(
    FirebaseDAO.getFBCollectionData('tags'),
    {
      idField: 'id',
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const [accounts] = useCollectionData(
    FirebaseDAO.getFBCollectionData('accounts'),
    {
      idField: 'id',
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  const setShowModal = state => {
    setShowModalForm(state);
  };

  const editTransactionData = row => {
    setTransactionEditData(row);
  };

  const resetEditTrasaction = () => {
    setTransactionEditData(null);
  };

  const deleteTransaction = row => {
    confirm({
      title: 'Are you sure you want to delete this Transaction?',
      onOk: () => {
        processTransactionDelete(row);
      },
      onCancel() { },
    });
  };

  const processTransactionDelete = async row => {
    await FirebaseDAO.deleteMultipleFBData([row.id], 'transactions');
    message.success(intlMessages('transaction.delete.success'));
  };

  const updateSearch = evt => {
    setFullData(data);
    searchTrasactionData(evt.target.value.trim());
  };

  const searchTrasactionData = searchText => {
    if (searchText === '') {
      setFullData(null);
      setFilterData(null);
    } else {
      if (fullData && fullData.length > 0) {
        let _fullData = fullData;
        const searchTrasactionResult = _fullData.filter(
          row =>
            numeral(row.amount)
              .format('$0,0')
              .indexOf(searchText) > -1 ||
            row.account.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
            row.tags.findIndex(
              tagObject => tagObject.title === searchText.toLowerCase(),
            ) !== -1 ||
            row.description.toLowerCase().indexOf(searchText.toLowerCase()) >
            -1 ||
            row.frequency.toLowerCase().indexOf(searchText.toLowerCase()) >
            -1 ||
            row.category[1].indexOf(searchText.toLowerCase()) > -1 ||
            moment(new Date(row.date * 1000))
              .format('LL')
              .toLowerCase()
              .indexOf(searchText) > -1,
        );
        if (searchTrasactionResult.length > 0) {
          setFilterData(searchTrasactionResult);
        } else {
          setFullData(null);
          setFilterData([]);
        }
      }
    }
  };

  const columns = [
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (text, row) => (
        <span>
          {TransactionCategories[1].children.filter(function (e) {
            return row.category[0] === 'income' && e.value === row.category[1];
          }).length > 0 ? (
              <span style={{ color: '#158715' }}>
                {numeral(row.amount).format('$0,0')}
              </span>
            ) : (
              <span style={{ color: '#ff141d' }}>
                {numeral(row.amount).format('$0,0')}
              </span>
            )}
        </span>
      ),
    },
    {
      title: 'Account',
      dataIndex: 'account',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text, row) => (
        <span>{moment(new Date(row.date * 1000)).format('LL')}</span>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'category',
    },
    {
      title: 'Frequency',
      dataIndex: 'frequency',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      render: (text, row) => (
        <span>
          {row &&
            row.tags &&
            row.tags.map((label, index) => {
              if (userTags && userTags.length) {
                for (var tags of userTags) {
                  if (tags.tag.title === label.title) {
                    label = tags.tag;
                    break;
                  }
                }
              }
              return (
                <Badge
                  key={index}
                  count={label.title}
                  style={{ backgroundColor: label.color }}
                />
              );
            })}
        </span>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, row) => (
        <span>
          <i
            className="icon icon-edit gx-pointer gx-text-primary gx-fs-lg gx-d-inline-flex gx-vertical-align-middle gx-mr-0"
            onClick={() => editTransactionData(row)}
          />
          &emsp;
          <i
            className="icon icon-trash gx-pointer gx-text-danger gx-fs-lg gx-d-inline-flex gx-vertical-align-middle gx-mr-1"
            onClick={() => deleteTransaction(row)}
          />
        </span>
      ),
    },
  ];

  return (
    <div>
      <Widget
        title={
          <h2 className="h4 gx-text-capitalize gx-mb-0 gx-text-left gx-ml-1">
            Transactions
          </h2>
        }
        extra={[
          <p
            className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block gx-mr-1"
            onClick={() => setShowModal(true)}>
            <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle gx-mr-1" />
            <IntlMessages id="transaction.add" />
          </p>,
          // For Mobile screen
          <span
            onClick={() => setShowModal(true)}
            className="gx-text-primary gx-fs-md gx-pointer gx-mb-4 gx-d-block gx-d-sm-none gx-mr-1">
            <i className="icon icon-add-circle gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle" />
            <IntlMessages id="transaction.add" />
          </span>,
        ]}>
        {section === 'setting' ? null : (
          <Row type="flex" justify="end">
            <Col span={8}>
              <input
                className="ant-input ant-input gx-mb-3"
                type="search"
                placeholder="Search Transactions"
                id="search"
                onKeyUp={updateSearch}
                onChange={updateSearch}></input>
            </Col>
          </Row>
        )}
        {data && (
          <Table
            className="gx-table-no-bordered"
            rowClassName="loan-table-row"
            pagination={{ pageSize: 10 }}
            columns={columns}
            dataSource={filterData ? filterData : data}
            size="middle"
            rowKey="id"
            locale={{
              emptyText: (
                <div className="text-center">
                  <h3>You don't have any transactions yet. Let's add one!</h3>
                  <Button
                    variant="raised"
                    className="gx-mt-3"
                    type="primary"
                    onClick={() => setShowModal(true)}>
                    <div className="ant-row-flex">
                      <IntlMessages id="transaction.add" />
                    </div>
                  </Button>
                </div>
              ),
            }}
          />
        )}
      </Widget>

      <TransactionModal
        setShowModal={setShowModal}
        showModal={showModalForm}
        accounts={accounts}
        resetEditTrasaction={resetEditTrasaction}
        editTrasactionData={transactionEditData}></TransactionModal>
    </div>
  );
};

export default Transactions;
