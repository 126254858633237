import React from 'react';
import {Button, Card, Col} from 'antd';
import numeral from 'numeral';
import renderHTML from 'react-render-html';
import '../settingsWizard.less';
import withStepTemplate from './withStepTemplate';
// import withStepTemplateFluid from './withStepTemplateFluid'
import {
  calculatePersonalReturn,
  calculateBusinessReturn,
} from './returnCalculator';
import {FaChevronLeft, FaChevronRight} from 'react-icons/fa';

const recommendations = {
  r1: {
    title: 'Stimulus Check',
    description:
      'You should receive a federal stimulus check in the mail or via direct deposit. Based on <a href="https://www.consumerfinance.gov/about-us/blog/guide-covid-19-economic-stimulus-checks" target="_blank">federal guidelines</a> you should be eligible for <strong>XXXX</strong>',
    cta: 'Connect with a Tax Professional',
  },
  r2: {
    title: 'No Action Required',
    description:
      'Based on the income requirements from the <a href="https://www.consumerfinance.gov/about-us/blog/guide-covid-19-economic-stimulus-checks" target="_blank">federal guidelines</a> you are not eligible for a federal stimulus payment.',
    cta: 'Connect with a Tax Professional',
    action: '',
  },
  r3: {
    title: 'Consider filing your 2019 Taxes as soon as possible',
    description:
      'Since your 2019 AGI is estimated to be below $75,000 and your 2018 AGI was higher than $75,000 you should consider filing your 2019 taxes to receive a larger stimulus check. We recommend consulting a tax professional.',
    cta: 'Connect with a Tax Professional',
    action: '',
  },
  r4: {
    title: 'Speak to an Accountant',
    description:
      'Based on the information you provided, we recommend that you consult a tax professional. There may be additional federal and local resources available to you as well as potential tax benefits such as sick leave and family leave credit.',
    cta: 'Book your Appointment',
  },
  r5: {
    title: 'Consider filing for Unemployment',
    description:
      '<a href="https://www.usa.gov/unemployment" target="_blank">Federal Unemployment</a> rules have been updated to include self-employed workers, independent contractors, and those with limited work history. Based on the information you shared, you may be eligible for unemployment benefits. To receive unemployment benefits, you need to file a claim with the unemployment program in the state where you worked.',
    cta: 'Unemployment Information',
    link: 'https://www.dol.gov/general/topic/unemployment-insurance',
  },
  r6: {
    title: 'Consider the SBA Paycheck Protection Program (PPP)',
    description:
      'You could be eligible for up to <strong>PPPPP</strong>. Loans up to $25,000 of which can be acquired without collateral. A portion of the loan is forgivable if used for payroll costs, mortgage interest, rent, and utilities payments over the 8 weeks after getting the loan. <a href="https://www.sba.gov/funding-programs/loans/coronavirus-relief-options/paycheck-protection-program-ppp#section-header-5" target="_blank">See details here</a>. The <a href="https://www.congress.gov/116/bills/hr748/BILLS-116hr748enr.pdf" target="_blank">CARES Act</a> was passed into law, providing the Paycheck Protection Program (PPP) loan through the <a href="https://www.sba.gov" target="_blank">SBA</a> for business including nonprofits, veterans organizations, sole proprietorships, self-employed individuals, independent contractors, and Tribal business concerns.',
    cta: 'See PPP Loan Details',
    link:
      'https://www.sba.gov/funding-programs/loans/coronavirus-relief-options/paycheck-protection-program-ppp',
  },
  r7: {
    title:
      'Consider the Forgivable Economic Injury Disaster Loan (EIDL) Advance $10,000',
    description:
      'The CARES Act was passed into law, providing the  Economic Injury Disaster Loans and Loan Advance (EIDL) Advance through the SBA for business including nonprofits, veterans organizations, sole proprietorships, self-employed individuals, independent contractors, and Tribal business concerns. The $10,000 advance can be used for working capital and does not need to be paid back.',
    cta: 'See Details for the EIDL Advance',
    link: 'https://covid19relief.sba.gov/#/',
  },
  r8: {
    title: 'Consider a SBA Disaster Loan -  SBA Loan Programs',
    description:
      'You could be eligible for up to [SBA Loan amount calculation]. Loans up to $25,000 of which can be acquired without collateral. Some of the loans are forgivable if used for payroll costs, mortgage interest, rent, and utilities payments over the 8 weeks after getting the loan. See details here. The CARES Act was passed into law, providing the Paycheck Protection Program (PPP) loan through the SBA for business including nonprofits, veterans organizations, sole proprietorships, self-employed individuals, independent contractors, and Tribal business concerns.',
    cta: 'See Loan Details',
    link: 'https://www.sba.gov/funding-programs/loans',
  },
};

const stepRecommendations = props => {
  console.log(`state: ${JSON.stringify(props.state)}`);

  const calculateRecommendations = () => {
    // console.log(`State: ${JSON.stringify(props.state)}`);
    let data = [];
    // Recommendation engine

    // Always push R4 to the top
    if (true) {
      let key = 'r4';
      data.push({
        title: recommendations[key].title,
        description: recommendations[key].description,
        cta: recommendations[key].cta,
        button: true,
      });
    }

    // Show R1 if it is estimated they will receive a payment
    if (calculatePersonalReturn(props.state) > 0) {
      let key = 'r1';
      data.push({
        title: recommendations[key].title,
        description: recommendations[key].description.replace(
          'XXXX',
          numeral(calculatePersonalReturn(props.state)).format('$0,0'),
        ),
        cta: recommendations[key].cta,
      });
    }

    // If 2019 AGI is lower than 75K AND the 2018 AGI is more than 75K then show recommendation R3
    if (props.state.agiEstimated2019 < 75000 && props.state.agi2018 > 75000) {
      let key = 'r3';
      data.push({
        title: recommendations[key].title,
        description: recommendations[key].description,
        cta: recommendations[key].cta,
        link: recommendations[key].link,
      });
    }

    // Has your business been negatively affected by the COVID-19 crisis? [yes/no] [if yes, show R4, R6 AND R7]
    if (
      props.state.covidBusinessImpact ||
      props.state.covidGigImpact ||
      props.state.covidNonProfitImpact
    ) {
      let keys = ['r6', 'r7'];
      keys.forEach(key =>
        data.push({
          title: recommendations[key].title,
          description: recommendations[key].description.replace(
            'PPPPP',
            numeral(calculateBusinessReturn(props.state)).format('$0,0'),
          ),
          cta: recommendations[key].cta,
          link: recommendations[key].link,
        }),
      );
    }

    // Have you been laid off or furloughed due to the COVID-19 Crisis? [yes/no] [if yes, show R5]
    if (props.state.employmentStatus !== 'employed') {
      let key = 'r5';
      data.push({
        title: recommendations[key].title,
        description: recommendations[key].description,
        cta: recommendations[key].cta,
        link: recommendations[key].link,
      });
    }

    // Show R2 if they will not receive a payment
    if (calculatePersonalReturn(props.state) === 0) {
      let key = 'r2';
      data.push({
        title: recommendations[key].title,
        description: recommendations[key].description,
        cta: recommendations[key].cta,
      });
    }

    // For testing only: Add all recommendations

    // let keys = ['r3', 'r4', 'r5', 'r6', 'r7', 'r8'];
    // keys.forEach(key =>
    //   data.push({
    //     title: recommendations[key].title,
    //     description: recommendations[key].description.replace(
    //       'PPPPP',
    //       numeral(calculateBusinessReturn(props.state)).format('$0,0'),
    //     ),
    //     cta: recommendations[key].cta,
    //     link: recommendations[key].link,
    //   }),
    // );

    return data;
  };

  const renderList = () => {
    let data = calculateRecommendations();
    let elements = [];
    data.forEach(item => {
      elements.push(
        <Card bordered={false} style={{marginBottom: '0px !important;'}}>
          <h2>{item.title}</h2>
          {renderHTML(item.description)}
          {item.button && (
            <Button
              type="primary"
              onClick={props.next}
              className="gx-mt-3"
              style={{display: 'block'}}>
              <div className="ant-row-flex">
                {item.cta}
                <FaChevronRight
                  size="12"
                  className="gx-ml-1"
                  style={{margin: 'auto'}}
                />
              </div>
            </Button>
          )}

          {!item.button && item.link && (
            <a
              href={item.link}
              target="_blank"
              className="gx-mt-3"
              style={{display: 'block'}}>
              <div className="ant-row-flex">
                {item.cta}
                <FaChevronRight
                  size="12"
                  className="gx-ml-1"
                  style={{margin: 'auto'}}
                />
              </div>
            </a>
          )}

          {!item.button && !item.link && (
            <a
              onClick={props.next}
              target="_blank"
              className="gx-mt-3"
              style={{display: 'block'}}>
              <div className="ant-row-flex">
                {item.cta}
                <FaChevronRight
                  size="12"
                  className="gx-ml-1"
                  style={{margin: 'auto'}}
                />
              </div>
            </a>
          )}
        </Card>,
      );
    });
    return elements;
  };

  let renderItems = renderList();

  return (
    <Col
      xl={24}
      lg={24}
      md={24}
      sm={24}
      xs={24}
      style={{textAlign: 'left', padding: 0, margin: 0}}>
      {renderItems}
    </Col>
  );
  // }
};

// export default withStepTemplate(stepRecommendations);
export default withStepTemplate(stepRecommendations);
