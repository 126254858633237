import React, {useState} from 'react';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';
import FirebaseAuth from 'firebase/firebaseAuth';
import {Button, Checkbox, Form, Input} from 'antd/lib';
import {Link} from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import {message} from 'antd/lib/index';
import Helmet from 'react-helmet';
import SocialSignIn from './SocialSignIn';
import {history} from 'appRedux/store';

const SignUp = props => {
  const intlMessages = useFormatMessage();
  const [form] = Form.useForm();
  const [agree, setAgree] = useState(false);

  const agreeOnChange = e => {
    setAgree(e.target.checked);
  };

  const onFinish = async values => {
    const {fullName, username, email, password} = values;
    if (!agree) {
      message.warn(intlMessages('appModule.pleaseAgree'));
    } else {
      try {
        await FirebaseAuth.signUp(fullName, username, email, password);
        message.success(intlMessages('appModule.signupSuccessfully'));
        history.push('/app/onboarding');
      } catch (error) {
        // message.error(intlMessages('appModule.signupError'));
        message.error(error.message);
      }
    }
  };

  return (
    <div className="gx-app-login-wrap">
      <Helmet defaultTitle="Sign up | loanpalz" />
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg"></div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="app.userAuth.signUp" />
              </h1>
              <p>
                <IntlMessages id="app.userAuth.bySigningUp" />
              </p>
              <p>
                <IntlMessages id="app.userAuth.getAccount" />
              </p>
            </div>
            <div className="gx-app-logo">
              <img
                alt="example"
                src={require('assets/images/loanpalz-logo.png')}
              />
            </div>
          </div>

          <div className="gx-app-login-content">
            <Form
              form={form}
              onFinish={onFinish}
              className="gx-signup-form gx-form-row0">
              <Form.Item
                name="fullName"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your full name',
                  },
                ]}>
                <Input placeholder="Full name" />
              </Form.Item>

              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a valid username',
                  },
                ]}>
                <Input placeholder="Username" />
              </Form.Item>

              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'The input is not a valid email',
                  },
                ]}>
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a valid password',
                  },
                ]}>
                <Input type="password" placeholder="Password" />
              </Form.Item>

              <Form.Item>
                <Checkbox onChange={agreeOnChange}>
                  <IntlMessages id="appModule.iAccept.signup" />
                </Checkbox>
                <Link to="/terms" style={{marginLeft: -4}}>
                  <IntlMessages id="appModule.termsAndConditions" />
                </Link>
                <span>, </span>
                <Link to="/privacy">Privacy Policy</Link>
                <span>, and </span>
                <Link to="/cookies">Cookie Policy</Link>
              </Form.Item>

              <Form.Item>
                <Checkbox>I'd like to receive emails from Loanpalz</Checkbox>
              </Form.Item>

              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signUp" />
                </Button>
                <span>
                  <IntlMessages id="app.userAuth.or" />
                </span>{' '}
                <Link to="/signin">
                  <IntlMessages id="app.userAuth.signIn" />
                </Link>
              </Form.Item>
            </Form>
            <div className="gx-flex-row gx-justify-content-between gx-app-social-block">
              <span>Or sign up with</span>
              <SocialSignIn />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
