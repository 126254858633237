import React from 'react';
import {
  Input,
  Upload,
  message,
  DatePicker,
  Form,
  InputNumber,
  Row,
  Col,
  Button,
  Card,
} from 'antd';
import { TEMPORARY_GOAL_IMAGE } from 'util/constants';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import GoalFormField from './GoalFormField';
import { FaTrash, FaUpload } from 'react-icons/fa';
import _ from 'lodash';

const { TextArea } = Input

const GoalFormDetails = ({
  goalName,
  tempImage,
  goalAmount,
  setGoalName,
  setTempImage,
  setGoalImage,
  setGoalAmount,
  goalPlannedDate,
  goalDescription,
  setGoalDescription,
  onChangePlannedDate,
}) => {
  const intlMessages = useFormatMessage();
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const uploadSettings = {
    name: 'goal-image',
    multiple: false,
    showUploadList: false,
    listType: 'picture',
    beforeUpload: file => {
      const isJPG = file.type === 'image/jpeg';
      const isJPEG = file.type === 'image/jpeg';
      const isGIF = file.type === 'image/gif';
      const isPNG = file.type === 'image/png';

      if (!(isJPG || isJPEG || isGIF || isPNG)) {
        message.error(intlMessages('goal.type.error'));
        return false;
      }

      const isLt2M = file.size / 10240 / 10240 < 2;
      if (!isLt2M) {
        message.error(intlMessages('goals.size.error'));
        return false;
      }
      return false;
    },
    headers: {
      authorization: 'authorization-text',
    },
    onChange: info => {
      const { originFileObj: lastFileObj } = _.last(info.fileList);
      setGoalImage(info);
      getBase64(lastFileObj, imageUrl => {
        setTempImage(imageUrl);
      });
    },
  };

  return (
    <div className="gx-modal-box-row">
      <div className="gx-modal-box-form-item">
        <Row>
          <Col span={12}>
            <div className="gx-form-group">
              <GoalFormField
                label={<h4>Goal Name</h4>}
                field={
                  <Col span={24}>
                    <Form.Item
                      name="name"
                      rules={[
                        {
                          required: true,
                          type: 'string',
                          message: 'Please enter goal name',
                        },
                      ]}>
                      <Input
                        type="text"
                        onChange={e => setGoalName(e.target.value)}
                        value={goalName}
                      />
                    </Form.Item>
                  </Col>
                }
              />
            </div>

            <div className="gx-form-group">
              <GoalFormField
                label={<h4>Description</h4>}
                field={
                  <Col span={24}>
                    <Form.Item name="description">
                      <TextArea
                        onChange={e => setGoalDescription(e.target.value)}
                        value={goalDescription}
                      />
                    </Form.Item>
                  </Col>
                }
              />
            </div>

            <div className="gx-form-group">
              <GoalFormField
                label={<h4>Goal Amount</h4>}
                field={
                  <Col span={24}>
                    <Form.Item
                      name="goalAmount"
                      rules={[
                        {
                          required: true,
                          type: 'integer',
                          message: 'Please enter amount',
                        },
                      ]}>
                      <InputNumber
                        formatter={value =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        value={goalAmount}
                        onChange={value => setGoalAmount(value)}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>
                }
              />
            </div>

            <div className="gx-form-group">
              <GoalFormField
                label={<h4>Due Date</h4>}
                field={
                  <Col span={24}>
                    <Form.Item
                      name="goalPlannedDate"
                      rules={[
                        {
                          required: true,
                          type: 'object',
                          message: 'Please select date',
                        },
                      ]}>
                      <DatePicker className="gx-mb-3 gx-w-100" placeholder="Planned completion date" margin="normal" onChange={onChangePlannedDate} />
                    </Form.Item>
                  </Col>
                }
              />
            </div>
          </Col>
          <Col span={12} className="border-left">
            <div className="gx-form-group">
              <GoalFormField
                label={<h4>Goal Image</h4>}
                field={
                  <Col span={24}>
                    <Form.Item name="image" className="profile-pic-container">
                      <Upload {...uploadSettings}>
                        <Card
                          style={{ cursor: 'pointer' }}
                          cover={
                            <img
                              src={tempImage || TEMPORARY_GOAL_IMAGE}
                              alt=""
                            />
                          }
                          className="gx-pointer border-0"
                          footer="false"
                        />
                        <div
                          className="edit-profile-pencil gx-pointer"
                          style={{ right: '40%' }}>
                          <i className="icon icon-edit gx-fs-xxxl gx-d-inline-flex gx-vertical-align-center" />
                        </div>
                      </Upload>
                    </Form.Item>
                  </Col>
                }
              />
            </div>

            <div className="gx-form-group">
              <Row style={{ justifyContent: 'left' }}>
                <Col span={24}>
                  <div
                    className="gx-form-group "
                    style={{ verticalAlign: 'bottom' }}>
                    <Upload {...uploadSettings}>
                      <Button type="primary" className="goal-btn-icon gx-mr-2">
                        <FaUpload className="gx-mr-2" />
                        Upload Image
                      </Button>
                    </Upload>
                    <Button
                      type="danger"
                      onClick={() => {
                        setTempImage(null);
                        setGoalImage(null);
                      }}>
                      <FaTrash className="goal-btn-icon gx-mr-8 gx-mr-2" />
                      Remove Image
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default GoalFormDetails;
