import app from 'firebase/app';
import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import config from './firebaseConfig';
import {TEMPORARY_COACH_COVER} from 'util/constants';
class FirebaseCoaches {
  constructor() {
    if (!firebase.apps.length) {
      app.initializeApp(config);
    }
    this.auth = app.auth();
    this.db = app.firestore();
    this.analytics = app.analytics();
    this.storage = app.storage();
    this.stripe_public_key = 'pk_test_vXyGseIAiuaGymmzEJNbjEfh';
  }

  // Coaches functions
  getCoachesDocumentData({ hook = true } = {}) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let userData = this.db.collection('users').where('coach', '==', true);
    if (!hook) {
      let coaches = [];
      userData
        .get()
        .then(snapshot => {
          snapshot.forEach(doc => {
            let dataObj = {
              ...doc.data(),
              id: doc.id,
            };
            coaches.push(dataObj);
          });
        })
        .catch(err => {
          console.log('Error getting tasks', err);
        });
      return coaches;
    }
    return userData;
  }
  // Get users appointments with coach
  getMyAppointmentWithCoaches({limit = 0}) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let bookingRef = this.db
      .collection('coachBookings')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('bookings')
      .where('cancelled', '==', false);

    if (limit > 0) {
      return bookingRef.limit(limit);
    } else {
      return bookingRef;
    }
  }
  // Get booked appointments of Coach
  getAppointmentsOfCoach({ filter = 'all' } = {}) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    let bookingRef = this.db
      .collectionGroup('bookings')
      .where('cancelled', '==', false)
      .where('coach', '==', this.auth.currentUser.uid);

    if (filter === 'upcoming') {
      bookingRef = bookingRef.where('date', '>=', today.getTime());
    }
    if (filter === 'past') {
      bookingRef = bookingRef.where('date', '<', today.getTime());
    }
    return bookingRef;
  }
  // Get booked appointments of Coach without hook
  async getAppointmentsOfCoachData({ coachid = null, filter = 'all' } = {}) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let id = this.auth.currentUser.uid;
    if (coachid) {
      id = coachid;
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    let booking = [];
    let bookingRef = this.db
      .collectionGroup('bookings')
      .where('cancelled', '==', false)
      .where('coach', '==', id);
    if (filter === 'upcoming') {
      bookingRef = bookingRef.where('date', '>=', today.getTime());
    }
    if (filter === 'past') {
      bookingRef = bookingRef.where('date', '<', today.getTime());
    }
    await bookingRef
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          let dataObj = {
            ...doc.data(),
            id: doc.id,
          };
          booking.push(dataObj);
        });
      })
      .catch(err => {
        console.log('Error getting booking', err);
      });
    return booking;
  }

  // Get Services Api
  async getServicesData(coachId) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let serviceRef = this.db
      .collection('users')
      .doc(`${coachId}`)
      .collection('services');
    let services = [];
    await serviceRef
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          let dataObj = {
            ...doc.data(),
            id: doc.id,
          };
          services.push(dataObj);
        });
      })
      .catch(err => {
        console.log('Error getting tasks', err);
      });
    return services;
  }

  async getMyServicesData() {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let serviceRef = this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('services');
    let services = [];
    await serviceRef
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          let dataObj = {
            ...doc.data(),
            id: doc.id,
          };
          services.push(dataObj);
        });
      })
      .catch(err => {
        console.log('Error getting tasks', err);
      });
    return services;
  }

  // Get User Sirvices
  getMyServices() {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    return this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('services');
  }

  // Get Coach Availability
  getCoachAvailability(coachId) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    return this.db
      .collection('users')
      .doc(`${coachId}`)
      .collection('availability');
  }

  async geAvailibilityData(coachId) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let availabilityRef = this.db
      .collection('users')
      .doc(`${coachId}`)
      .collection('availability');
    let availability = [];
    await availabilityRef
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          let dataObj = {
            ...doc.data(),
            id: doc.id,
          };
          availability.push(dataObj);
        });
      })
      .catch(err => {
        console.log('Error getting tasks', err);
      });
    return availability;
  }

  //AddService
  async addService(input) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let date = new Date().getTime();
    let coverPhoto = {name: '', downloadURL: TEMPORARY_COACH_COVER};

    if (input.coverPhoto.length > 0) {
      coverPhoto = await this.uploadfile(input.coverPhoto, date);
    }
    input = {
      ...input,
      coverPhoto: coverPhoto,
    };
    let serviceData = await this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('services')
      .add(input);
    return serviceData;
  }

  // updateService
  async updateService(input) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }

    let coverPhoto = input.old_coverPhoto;
    let date = new Date().getTime();

    if (input.coverPhoto.length > 0) {
      coverPhoto = await this.uploadfile(input.coverPhoto, date);
    }
    let services = await this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('services')
      .doc(`${input.id}`)
      .update({
        serviceName: input.serviceName,
        servicePrice: input.servicePrice,
        coverPhoto: coverPhoto,
      });
    return services;
  }

  // deleteServices
  deleteService(id) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let servcies = this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('services')
      .doc(`${id}`)
      .delete();
    return servcies;
  }

  // AddAvailablity
  async addAvailability(values) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let serviceData = await this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('availability')
      .add(values);
    return serviceData;
  }

  // Update Availablity
  async updateAvailability(input) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let availabilityData = await this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('availability')
      .doc(`${input.id}`)
      .update({
        timeZone: input.timeZone,
        startDate: input.startDate,
        endDate: input.endDate,
        timeSlots: input.timeSlots,
      });
    return availabilityData;
  }

  // deleteAvailibatlity
  deleteAvail(id) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let availibility = this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('availability')
      .doc(`${id}`)
      .delete();
    return availibility;
  }

  // Add Bookings Api
  addMyBookings(booking) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let userId = this.auth.currentUser.uid;
    return this.db
      .collection('coachBookings')
      .doc(`${userId}`)
      .collection('bookings')
      .add(booking)
      .then(res => [null, res])
      .catch(err => [err]);
  }

  // Update booking Slot
  updateBookingsSlot(booking, updaedSlot) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }

    return this.db
      .collection('coachBookings')
      .doc(`${booking.client}`)
      .collection('bookings')
      .doc(booking.id)
      .update(updaedSlot);
  }
  // Add Booking Trasaction
  addPaymentTransaction(trasaction) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }

    let userId = this.auth.currentUser.uid;
    return this.db
      .collection('paymentTransactions')
      .doc(`${userId}`)
      .collection('payments')
      .add(trasaction)
      .then(res => [null, res])
      .catch(err => [err]);
  }

  // Get Bookings api
  async getMyBookings(coachId) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    let bookingRef = this.db
      .collection('users')
      .doc(`${coachId}`)
      .collection('bookings');
    let bookings = [];
    await bookingRef
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          let dataObj = {
            ...doc.data(),
            id: doc.id,
          };
          bookings.push(dataObj);
        });
      })
      .catch(err => {
        console.log('Error getting tasks', err);
      });
    return bookings;
  }

  requestAppointment(values) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    return this.db
      .collection('users')
      .doc(`${this.auth.currentUser.uid}`)
      .collection('services')
      .add(values);
  }

  // Upload group coverphoto
  async uploadfile(fileArray, name) {
    let upload = fileArray[0].originFileObj;
    let img = this.storage
      .ref()
      .child(`coaches/${this.auth.currentUser.uid}/cover/${name}`);
    await img.put(upload).then(() => {
      img.updateMetadata({
        customMetadata: {name: name, uid: this.auth.currentUser.uid},
      });
    });
    let downloadURL = await img.getDownloadURL();

    return {name: name, downloadURL: downloadURL};
  }
  // Get Service Detail
  getServiceDetail(userId, serviceId) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }
    return this.db
      .collection('users')
      .doc(`${userId}`)
      .collection('services')
      .doc(`${serviceId}`);
  }
  // Cancel Booking
  async cancelAppointment(input) {
    if (!this.auth.currentUser) {
      return alert('Unauthorized');
    }

    return await this.db
      .collection('coachBookings')
      .doc(`${input.client}`)
      .collection('bookings')
      .doc(input.id)
      .update({cancelled: true});
  }
}

export default new FirebaseCoaches();
