import React from 'react';
import {Col, Radio, Tooltip} from 'antd';
import '../settingsWizard.less';
import withStepTemplate from './withStepTemplate';

import {InfoCircleOutlined} from '@ant-design/icons';

const StepPreTaxes = props => {
  return (
    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
      <h4 className="gx-mb-2 formLabel">
        <span>
          Have you filed your 2019 taxes yet?
          <Tooltip
            placement="topLeft"
            title="Have you filed your 2019 taxes yet?"
            trigger="hover">
            <InfoCircleOutlined
              className="gx-ml-1 gx-pointer"
              style={{fontSize: '16px', verticalAlign: 'middle'}}
            />
          </Tooltip>
        </span>
      </h4>
      <Radio.Group
        value={props.state.filedFor2019}
        buttonStyle="solid"
        onChange={value => {
          props.dispatch({
            type: 'filedFor2019',
            payload: value.target.value,
          });
          // setTimeout(() => {
          //   // props.push('worker')
          //   props.next();
          // }, 500);
        }}>
        <Radio.Button value={true}>Yes</Radio.Button>
        <Radio.Button value={false}>No</Radio.Button>
      </Radio.Group>
    </Col>
  );
};

export default withStepTemplate(StepPreTaxes);
