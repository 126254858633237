import React, { useState } from 'react';
import { Form, Row, Col, Spin } from 'antd/lib';
import Auxiliary from 'util/Auxiliary';
import Widget from 'components/Widget/index';
import FinancialSnapshotWelcomeCard from '../FinancialSnapshotWelcomeCard';
import { FirebaseDAO } from 'firebase/firebase';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { FaSpinner } from 'react-icons/fa';
import LoanModal from 'components/app/loan/LoanModal';
import ProcessLoans from './ProcessLoans';
const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;

const FinancialChart = ({ profile }) => {
  const [showModal, setShowModal] = useState(false);
  const [form] = Form.useForm();
  const [inlineform] = Form.useForm();
  // const intlMessages = useFormatMessage();

  const onModalOpen = () => setShowModal(true);
  const [editLoan, setEditloan] = useState(null);
  const endCollection = 'loans';

  const [loans, loading] = useCollectionData(
    FirebaseDAO.getFBCollectionData(endCollection),
    {
      idField: 'id',
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );
  // Set form defaults
  form.setFieldsValue({
    amount: 120000,
    term: 120,
    interest: 8,
  });

  if (!profile && loading) {
    return <Spin indicator={spinnerIcon} />;
  } else {
    let loan = {
      amount: profile.financial.snapshot
        ? profile.financial.snapshot.loanamount
        : 0,
      term: profile.financial.snapshot
        ? profile.financial.snapshot.loanterm
        : 0,
      interest: profile.financial.snapshot
        ? profile.financial.snapshot.loaninterest
        : 0,
    };
    inlineform.setFieldsValue(loan);
    const showHideModal = value => {
      setShowModal(value);
      if (value === false) {
        setEditloan(null);
      }
    };

    return (
      <Auxiliary>
        {profile && loans && loans.length ? (
          <Widget>
            <ProcessLoans
              profile={profile}
              loans={loans}
            />
          </Widget>
        ) : loading ? (
          <Spin indicator={spinnerIcon} />
        ) : (
              <>
                <Row type="flex" justify="center">
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <FinancialSnapshotWelcomeCard
                      onModalOpen={onModalOpen}
                      name={profile.username}
                    />
                  </Col>
                </Row>
                <LoanModal
                  showModal={showModal}
                  showHideModal={showHideModal}
                  loan={editLoan}
                />
              </>
            )}
      </Auxiliary>
    );
  }
};

export default FinancialChart;
