import React, { useRef } from 'react';
import GridImage from './GridImage';
import Auxiliary from 'util/Auxiliary';
import { useModal, Modal } from 'react-morphing-modal';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-morphing-modal/dist/ReactMorphingModal.css';
import './slickCustomStyles.less'
import Carousel from 'util/Carousel'

const MediaList = ({ mediaList }) => {
  const triggerRef = useRef(null);
  const { modalProps, open } = useModal({ background: '#000' });
  const handleModalOpen = () => open(triggerRef)

  return (
    <Auxiliary>
      <div ref={triggerRef} onClick={handleModalOpen}>
        <GridImage mediaList={mediaList} numItems="4" />
      </div>
      <Modal {...modalProps}><Carousel mediaList={mediaList} /></Modal>
    </Auxiliary>
  );
};

export default MediaList;
