import React from 'react';
import {Col, Row} from 'antd/lib';
import Widget from 'components/Widget/index';

const WelcomeCard = ({profile}) => {
  return (
    <Widget styleName="gx-pink-purple-gradient-reverse gx-text-white">
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <div className="gx-text-align-center">
            <img
              alt="Welcome to Loanpals"
              width="60%"
              src="https://firebasestorage.googleapis.com/v0/b/loanpalz/o/static%2Fcongrats-bg.png?alt=media"
            />
          </div>
        </Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <h1 className="gx-text-white gx-mb-3 gx-mt-3 gx-text-align-center">
            Welcome, {profile.username}!
          </h1>
          <p className="gx-text-white gx-mb-0 gx-text-align-center">
            Ready to take charge of your <strong>financial future</strong>? We
            are here to support you.
          </p>
        </Col>
      </Row>
    </Widget>
  );
};

export default WelcomeCard;
