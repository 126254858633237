import React from 'react';
import {Col, InputNumber, Radio, Select, Tooltip} from 'antd';
import '../settingsWizard.less';
import withStepTemplate from './withStepTemplate';

import {InfoCircleOutlined} from '@ant-design/icons';

const {Option} = Select;

const StepBusiness = props => {
  // const handleFocus = event => event.target.select();

  return (
    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
      <h4 className="gx-mb-2 formLabel">
        <span>
          Do you own a business (at least a 20% stake)?
          <Tooltip
            placement="topLeft"
            title="Do you own a business (at least a 20% stake)?"
            trigger="hover">
            <InfoCircleOutlined
              className="gx-ml-1 gx-pointer"
              style={{fontSize: '16px', verticalAlign: 'middle'}}
            />
          </Tooltip>
        </span>
      </h4>
      <Radio.Group
        value={props.state.ownBusiness}
        buttonStyle="solid"
        onChange={value => {
          props.dispatch({
            type: 'ownBusiness',
            payload: value.target.value,
          });
        }}>
        <Radio.Button value={true}>Yes</Radio.Button>
        <Radio.Button value={false}>No</Radio.Button>
      </Radio.Group>

      {/* Business */}
      {props.state.ownBusiness && (
        <div>
          <h4 className="gx-mb-2 gx-mt-3 formLabel">
            <span>
              Has your business been negatively affected by the COVID-19 crisis?
              <Tooltip
                placement="topLeft"
                title="Has your business been negatively affected by the COVID-19 crisis?"
                trigger="hover">
                <InfoCircleOutlined
                  className="gx-ml-1 gx-pointer"
                  style={{fontSize: '16px', verticalAlign: 'middle'}}
                />
              </Tooltip>
            </span>
          </h4>

          <Radio.Group
            value={props.state.covidBusinessImpact}
            buttonStyle="solid"
            onChange={value => {
              props.dispatch({
                type: 'covidBusinessImpact',
                payload: value.target.value,
              });
            }}>
            <Radio.Button value={true}>Yes</Radio.Button>
            <Radio.Button value={false}>No</Radio.Button>
          </Radio.Group>

          {props.state.covidBusinessImpact && (
            <div>
              <h4 className="gx-mb-2 gx-mt-3 formLabel">
                <span>
                  How many employees do you have (not including you)?
                  <Tooltip
                    placement="topLeft"
                    title="How many employees do you have (not including you)?"
                    trigger="hover">
                    <InfoCircleOutlined
                      className="gx-ml-1 gx-pointer"
                      style={{fontSize: '16px', verticalAlign: 'middle'}}
                    />
                  </Tooltip>
                </span>
              </h4>

              <InputNumber
                value={props.state.numEmployees}
                onFocus={event => event.target.select()}
                onChange={value => {
                  props.dispatch({
                    type: 'numEmployees',
                    payload: value,
                  });
                  // setTimeout(() => {
                  //   // props.push('marital_status')
                  //   props.next()
                  // }, 500)
                }}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                style={{width: '80px'}}
              />

              <h4 className="gx-mb-2 gx-mt-3 formLabel">
                <span>
                  How much is your average monthly payroll?
                  <Tooltip
                    placement="topLeft"
                    title="Enter your average monthly payroll (excluding costs over $100,000 on an annualized basis for each employee).
Most applicants will use the average monthly payroll for 2019, excluding costs over $100,000 on an annualized basis for each employee."
                    trigger="hover">
                    <InfoCircleOutlined
                      className="gx-ml-1 gx-pointer"
                      style={{fontSize: '16px', verticalAlign: 'middle'}}
                    />
                  </Tooltip>
                </span>
              </h4>

              <InputNumber
                value={props.state.avgMonthlyPayroll}
                onFocus={event => event.target.select()}
                onChange={value => {
                  props.dispatch({
                    type: 'avgMonthlyPayroll',
                    payload: value,
                  });
                  // setTimeout(() => {
                  //   // props.push('marital_status')
                  //   props.next()
                  // }, 500)
                }}
                formatter={value =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                style={{width: '180px'}}
              />

              <h4 className="gx-mb-2 gx-mt-3 formLabel">
                <span>
                  In which state is your business headquartered?
                  <Tooltip
                    placement="topLeft"
                    title="In which state is your business headquartered?"
                    trigger="hover">
                    <InfoCircleOutlined
                      className="gx-ml-1 gx-pointer"
                      style={{fontSize: '16px', verticalAlign: 'middle'}}
                    />
                  </Tooltip>
                </span>
              </h4>
              <Select
                value={props.state.businessState}
                defaultValue="AL"
                style={{width: 200}}
                onChange={value => {
                  props.dispatch({
                    type: 'businessState',
                    payload: value,
                  });
                  // setTimeout(() => {
                  //   props.push('children')
                  // }, 500)
                }}>
                <Option value="AL">Alabama</Option>
                <Option value="AK">Alaska</Option>
                <Option value="AZ">Arizona</Option>
                <Option value="AR">Arkansas</Option>
                <Option value="CA">California</Option>
                <Option value="CO">Colorado</Option>
                <Option value="CT">Connecticut</Option>
                <Option value="DE">Delaware</Option>
                <Option value="DC">District Of Columbia</Option>
                <Option value="FL">Florida</Option>
                <Option value="GA">Georgia</Option>
                <Option value="HI">Hawaii</Option>
                <Option value="ID">Idaho</Option>
                <Option value="IL">Illinois</Option>
                <Option value="IN">Indiana</Option>
                <Option value="IA">Iowa</Option>
                <Option value="KS">Kansas</Option>
                <Option value="KY">Kentucky</Option>
                <Option value="LA">Louisiana</Option>
                <Option value="ME">Maine</Option>
                <Option value="MD">Maryland</Option>
                <Option value="MA">Massachusetts</Option>
                <Option value="MI">Michigan</Option>
                <Option value="MN">Minnesota</Option>
                <Option value="MS">Mississippi</Option>
                <Option value="MO">Missouri</Option>
                <Option value="MT">Montana</Option>
                <Option value="NE">Nebraska</Option>
                <Option value="NV">Nevada</Option>
                <Option value="NH">New Hampshire</Option>
                <Option value="NJ">New Jersey</Option>
                <Option value="NM">New Mexico</Option>
                <Option value="NY">New York</Option>
                <Option value="NC">North Carolina</Option>
                <Option value="ND">North Dakota</Option>
                <Option value="OH">Ohio</Option>
                <Option value="OK">Oklahoma</Option>
                <Option value="OR">Oregon</Option>
                <Option value="PA">Pennsylvania</Option>
                <Option value="RI">Rhode Island</Option>
                <Option value="SC">South Carolina</Option>
                <Option value="SD">South Dakota</Option>
                <Option value="TN">Tennessee</Option>
                <Option value="TX">Texas</Option>
                <Option value="UT">Utah</Option>
                <Option value="VT">Vermont</Option>
                <Option value="VA">Virginia</Option>
                <Option value="WA">Washington</Option>
                <Option value="WV">West Virginia</Option>
                <Option value="WI">Wisconsin</Option>
                <Option value="WY">Wyoming</Option>
              </Select>
            </div>
          )}
        </div>
      )}
    </Col>
  );
};

export default withStepTemplate(StepBusiness);
