import React from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import {Card, List} from 'antd/lib';
import Follow from 'components/app/contact/Follow';
import MessageBubble from 'components/app/contact/MessageBubble';
import FriendRequest from 'components/app/contact/FriendRequest';
import AvatarListItem from './AvatarListItem';
import BodyListItem from './BodyListItem';
import FirebaseSocial from 'firebase/firebaseSocial';

const SearchDisplayList = ({query, results}) => {
  console.log('Results', results);
  if (results.length === 0) {
    return (
      <Card className="gx-card" title={`Search Results: ${query}`}>
        No users matching this query were found.
      </Card>
    );
  } else {
    return (
      <div className="gx-applications-list">
        <CustomScrollbars className="gx-application-content-scroll">
          <Card className="gx-card" title={`Search Results: ${query}`}>
            <List
              itemLayout="horizontal"
              dataSource={results}
              renderItem={item => (
                <List.Item
                  actions={
                    item.id
                      ? item.id === FirebaseSocial.auth.currentUser.uid
                        ? null
                        : [
                            <Follow userId={item.id} />,
                            <FriendRequest userId={item.id} />,
                            <MessageBubble userId={item.id} />,
                          ]
                      : item.objectID === FirebaseSocial.auth.currentUser.uid
                      ? null
                      : [
                          <Follow userId={item.objectID} />,
                          <FriendRequest userId={item.objectID} />,
                          <MessageBubble userId={item.objectID} />,
                        ]
                  }>
                  <List.Item.Meta
                    avatar={<AvatarListItem item={item} />}
                    title={<BodyListItem item={item} />}
                  />
                </List.Item>
              )}
            />
          </Card>
        </CustomScrollbars>
      </div>
    );
  }
};

export default SearchDisplayList;
