import React from 'react';
import Widget from 'components/Widget/index';
import LineIndicator from 'components/common/LineIndicator';
import AnimatedCurrencyDisplay from 'components/common/AnimatedCurrencyDisplay'
import '../widgets.less';

const NetWorthCard = ({ networth, accountBalance, loanBalance }) => {
  return (
    <Widget styleName='gx-card-full gx-py-3 gx-px-2'>
      <div className="gx-flex-row gx-justify-content-center gx-mb-3 gx-mb-sm-4">
        <span
          className={`gx-size-80 gx-border gx-border-primary gx-text-primary gx-flex-row gx-justify-content-center gx-align-items-center gx-rounded-circle`}>
          <i className={`icon icon-revenue-new gx-fs-xlxl`} />
        </span>
      </div>
      <div className="gx-text-center">
        <h2 className={'gx-fs-xxxl gx-font-weight-medium ' + (networth < 0 ? 'text-danger' : 'primary')}>
          <AnimatedCurrencyDisplay
            minimum={networth}
            maximum={networth}
          />
        </h2>
        <p className={`gx-mb-0 gx-mb-sm-3`}>NET WORTH</p>
      </div>

      <div className="gx-site-dash">
        <ul className="gx-line-indicator gx-fs-sm gx-pb-1 gx-pb-sm-0">
          <li>
            <LineIndicator
              width={networth > 0 ? 100 : Math.abs(accountBalance / loanBalance) * 100}
              title="Assets"
              title2="8.74"
              color="primary"
              value={accountBalance}
            />
          </li>
          <li>
            <LineIndicator
              width={networth < 0 ? 100 : Math.abs(loanBalance / accountBalance) * 100}
              title="Liabilities"
              title2="1.23"
              color="pink"
              value={loanBalance} />
          </li>
        </ul>
      </div>
    </Widget>
  )
};

export default NetWorthCard;
