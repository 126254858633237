import React from 'react';
import NotificationItem from './NotificationItem';
import {Popover} from 'antd/lib';
import CustomScrollbars from 'util/CustomScrollbars';
import Auxiliary from 'util/Auxiliary';
import FirebaseMessages from 'firebase/firebaseMessages';
import {useCollectionData} from 'react-firebase-hooks/firestore';

const MailNotification = () => {
  const [
    notifications,
    notificationsLoading,
    notificationsError,
  ] = useCollectionData(FirebaseMessages.getMessageNotifications(), {
    idField: 'id',
    snapshotListenOptions: {
      includeMetadataChanges: true,
    },
  });

  const messageNotification = (
    <Auxiliary>
      <div className="gx-popover-header">
        <h3 className="gx-mb-0">Messages</h3>
        <i className="gx-icon-btn icon icon-charvlet-down" />
      </div>
      {notificationsError && <strong>Connection error!</strong>}
      {notificationsLoading && <p>Loading...</p>}
      {notifications && (
        <CustomScrollbars className="gx-popover-scroll">
          <ul className="gx-sub-popover">
            {notifications &&
              notifications.map((notification, index) => (
                <NotificationItem key={index} notification={notification} />
              ))}
          </ul>
        </CustomScrollbars>
      )}
    </Auxiliary>
  );

  return (
    <Popover
      overlayClassName="gx-popover-horizantal"
      placement="bottomRight"
      content={messageNotification}
      trigger="click">
      <span className="gx-pointer gx-status-pos gx-d-block">
        <i className="icon icon-chat-new" />
        {notifications && notifications.length > 0 && (
          <span className="gx-status gx-status-rtl gx-small gx-orange" />
        )}
      </span>
    </Popover>
  );
};

export default MailNotification;
