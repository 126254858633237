import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Dashboard from './dashboard';
import Loans from './loans';
import Accelerator from './accelerator';
import CoachList from './coaches';
import Messages from './messages';
import Profile from './profile';
import UserProfile from './user';
import Settings from './settings';
import Search from './search';
import Tasks from './tasks';
import Transactions from './transactions';
import Onboarding from './onboarding';
import NetWorth from './networth';
import CashFlow from './cashflow';
import Income from './income';
import CreditCards from './creditcards';
import Accounts from './accounts';
import Budget from './budget';
import {useProfile} from 'auth';
import {FaSpinner} from 'react-icons/fa';
import {Spin} from 'antd/lib';
import Goals from './goals';
import GoalDetailsWrapper from './goals/GoalDetailsWrapper';
import Groups from './groups';
import GroupDetail from './groupDetail';
import Wall from './wall';
import Forum from './forum';
import Plaid from './plaid';
import {withOptimizely} from '@optimizely/react-sdk';
import ProfileGuestView from './user/ProfileGuestView';
import ThreadLink from './forum/ThreadLink';

const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;

const App = ({match, optimizely}) => {
  const profile = useProfile();

  function userReturn(guest, current, vURL) {
    if (guest) {
      return <ProfileGuestView vanityURL={vURL} />;
    } else {
      if (vURL === profile.id || current) {
        return <Profile profile={profile} />;
      } else {
        return <UserProfile vanityURL={vURL} profile={profile} />;
      }
    }
  }

  if (!profile) {
    return <Spin indicator={spinnerIcon} />;
  } else {
    return (
      <Switch>
        <Route
          path={`${match.url}/dashboard`}
          render={props => <Dashboard {...props} profile={profile} />}
        />

        {optimizely.isFeatureEnabled('net_worth') ? (
          <Route
            path={`${match.url}/networth`}
            render={props => <NetWorth {...props} profile={profile} />}
          />
        ) : null}

        {optimizely.isFeatureEnabled('cash_flow') ? (
          <Route
            path={`${match.url}/cashflow`}
            render={props => <CashFlow {...props} profile={profile} />}
          />
        ) : null}

        {optimizely.isFeatureEnabled('income') ? (
          <Route
            path={`${match.url}/income`}
            render={props => <Income {...props} profile={profile} />}
          />
        ) : null}

        {optimizely.isFeatureEnabled('loans') ? (
          <Route
            path={`${match.url}/loans`}
            render={props => <Loans {...props} profile={profile} />}
          />
        ) : null}

        {optimizely.isFeatureEnabled('accounts') ? (
          <Route
            path={`${match.url}/accounts`}
            render={props => <Accounts {...props} profile={profile} />}
          />
        ) : null}

        {optimizely.isFeatureEnabled('budget') ? (
          <Route
            path={`${match.url}/budget`}
            render={props => <Budget {...props} profile={profile} />}
          />
        ) : null}

        {optimizely.isFeatureEnabled('credit_cards') ? (
          <Route
            path={`${match.url}/creditcards`}
            render={props => <CreditCards {...props} profile={profile} />}
          />
        ) : null}

        {optimizely.isFeatureEnabled('loan_accelerator') ? (
          <Route
            path={`${match.url}/accelerator`}
            render={props => <Accelerator {...props} profile={profile} />}
          />
        ) : null}

        <Route
          path={`${match.url}/onboarding`}
          render={props => <Onboarding {...props} profile={profile} />}
        />

        <Route
          path={`${match.url}/coaches`}
          render={props => <CoachList {...props} profile={profile} />}
        />

        <Route
          path={`${match.url}/messages`}
          render={props => <Messages {...props} profile={profile} />}
        />

        {optimizely.isFeatureEnabled('goals') ? (
          <Route
            path={`${match.url}/goals`}
            render={props => <Goals {...props} profile={profile} />}
          />
        ) : null}

        <Route
          path={`${match.url}/goal/:id`}
          render={props => <GoalDetailsWrapper {...props} profile={profile} />}
        />

        <Route
          path={`${match.url}/tasks`}
          render={props => <Tasks {...props} profile={profile} />}
        />

        <Route
          path={`${match.url}/user/:vanityURL`}
          exact
          render={props =>
            userReturn(
              profile.guest,
              props.match.params.vanityURL === profile.vanityURL,
              props.match.params.vanityURL,
            )
          }
        />

        <Route
          path={`${match.url}/user/:vanityURL/:slug`}
          exact
          render={props =>
            userReturn(
              profile.guest,
              props.match.params.vanityURL === profile.vanityURL,
              props.match.params.vanityURL,
            )
          }
        />

        <Route
          path={`${match.url}/settings`}
          render={props => <Settings {...props} profile={profile} />}
        />

        {optimizely.isFeatureEnabled('search_users') ? (
          <Route
            path={`${match.url}/search`}
            render={props => <Search {...props} />}
          />
        ) : null}

        <Route
          path={`${match.url}/transactions`}
          exact
          render={props => <Transactions {...props} profile={profile} />}
        />
        <Route
          path={`${match.url}/groups`}
          render={props => <Groups {...props} profile={profile} />}
        />
        <Route
          path={`${match.url}/group/:vanityURL/:groupId`}
          exact
          render={props => (
            <GroupDetail
              {...props}
              profile={profile}
              userId={props.location.userId}
            />
          )}
        />
        <Route
          path={`${match.url}/wall`}
          render={props => <Wall {...props} profile={profile} />}
        />
        <Route
          path={`${match.url}/forum`}
          exact
          render={props => <Forum {...props} profile={profile} />}
        />
        <Route
          path={`${match.url}/forum/:id`}
          exact
          render={props => (
            <ThreadLink {...props} threadId={props.match.params.id} />
          )}
        />
        {optimizely.isFeatureEnabled('link_account') ? (
          <Route
            path={`${match.url}/link`}
            exact
            render={props => <Plaid {...props} profile={profile} />}
          />
        ) : null}
      </Switch>
    );
  }
};

export default withOptimizely(App);
