import React from 'react';
import {Col, Radio, Select, Tooltip, InputNumber} from 'antd';
import '../settingsWizard.less';
import withStepTemplate from './withStepTemplate';

import {InfoCircleOutlined} from '@ant-design/icons';

const {Option} = Select;

const stepNonProfit = props => {
  return (
    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
      <h4 className="gx-mb-2 formLabel">
        <span>
          Do you operate a 501(c)(3) non-profit entity?
          <Tooltip
            placement="topLeft"
            title="Do you operate a 501(c)(3) non-profit entity?"
            trigger="hover">
            <InfoCircleOutlined
              className="gx-ml-1 gx-pointer"
              style={{fontSize: '16px', verticalAlign: 'middle'}}
            />
          </Tooltip>
        </span>
      </h4>
      <Radio.Group
        value={props.state.ownNonProfit}
        buttonStyle="solid"
        onChange={value => {
          props.dispatch({
            type: 'ownNonProfit',
            payload: value.target.value,
          });
        }}>
        <Radio.Button value={true}>Yes</Radio.Button>
        <Radio.Button value={false}>No</Radio.Button>
      </Radio.Group>

      {/* NonProfit */}
      {props.state.ownNonProfit && (
        <div>
          <h4 className="gx-mb-2 gx-mt-3 formLabel">
            <span>
              Has your 501(c)(3) been negatively affected by the COVID-19
              crisis?
              <Tooltip
                placement="topLeft"
                title="Has your 501(c)(3) been negatively affected by the COVID-19 crisis?"
                trigger="hover">
                <InfoCircleOutlined
                  className="gx-ml-1 gx-pointer"
                  style={{fontSize: '16px', verticalAlign: 'middle'}}
                />
              </Tooltip>
            </span>
          </h4>

          <Radio.Group
            value={props.state.covidNonProfitImpact}
            buttonStyle="solid"
            onChange={value => {
              props.dispatch({
                type: 'covidNonProfitImpact',
                payload: value.target.value,
              });
            }}>
            <Radio.Button value={true}>Yes</Radio.Button>
            <Radio.Button value={false}>No</Radio.Button>
          </Radio.Group>

          <h4 className="gx-mb-2 gx-mt-3 formLabel">
            <span>
              In which state is your 501(c)(3) headquartered?
              <Tooltip
                placement="topLeft"
                title="In which state is your 501(c)(3) headquartered?"
                trigger="hover">
                <InfoCircleOutlined
                  className="gx-ml-1 gx-pointer"
                  style={{fontSize: '16px', verticalAlign: 'middle'}}
                />
              </Tooltip>
            </span>
          </h4>
          <Select
            value={props.state.nonProfitState}
            defaultValue="AL"
            style={{width: 200}}
            onChange={value => {
              props.dispatch({
                type: 'nonProfitState',
                payload: value,
              });
              // setTimeout(() => {
              //   props.next();
              // }, 500);
            }}>
            <Option value="AL">Alabama</Option>
            <Option value="AK">Alaska</Option>
            <Option value="AZ">Arizona</Option>
            <Option value="AR">Arkansas</Option>
            <Option value="CA">California</Option>
            <Option value="CO">Colorado</Option>
            <Option value="CT">Connecticut</Option>
            <Option value="DE">Delaware</Option>
            <Option value="DC">District Of Columbia</Option>
            <Option value="FL">Florida</Option>
            <Option value="GA">Georgia</Option>
            <Option value="HI">Hawaii</Option>
            <Option value="ID">Idaho</Option>
            <Option value="IL">Illinois</Option>
            <Option value="IN">Indiana</Option>
            <Option value="IA">Iowa</Option>
            <Option value="KS">Kansas</Option>
            <Option value="KY">Kentucky</Option>
            <Option value="LA">Louisiana</Option>
            <Option value="ME">Maine</Option>
            <Option value="MD">Maryland</Option>
            <Option value="MA">Massachusetts</Option>
            <Option value="MI">Michigan</Option>
            <Option value="MN">Minnesota</Option>
            <Option value="MS">Mississippi</Option>
            <Option value="MO">Missouri</Option>
            <Option value="MT">Montana</Option>
            <Option value="NE">Nebraska</Option>
            <Option value="NV">Nevada</Option>
            <Option value="NH">New Hampshire</Option>
            <Option value="NJ">New Jersey</Option>
            <Option value="NM">New Mexico</Option>
            <Option value="NY">New York</Option>
            <Option value="NC">North Carolina</Option>
            <Option value="ND">North Dakota</Option>
            <Option value="OH">Ohio</Option>
            <Option value="OK">Oklahoma</Option>
            <Option value="OR">Oregon</Option>
            <Option value="PA">Pennsylvania</Option>
            <Option value="RI">Rhode Island</Option>
            <Option value="SC">South Carolina</Option>
            <Option value="SD">South Dakota</Option>
            <Option value="TN">Tennessee</Option>
            <Option value="TX">Texas</Option>
            <Option value="UT">Utah</Option>
            <Option value="VT">Vermont</Option>
            <Option value="VA">Virginia</Option>
            <Option value="WA">Washington</Option>
            <Option value="WV">West Virginia</Option>
            <Option value="WI">Wisconsin</Option>
            <Option value="WY">Wyoming</Option>
          </Select>

          {props.state.covidNonProfitImpact && (
            <div>
              <h4 className="gx-mb-2 gx-mt-3 formLabel">
                <span>
                  Approximately how much 1099 income did you earn last year?
                  <Tooltip
                    placement="topLeft"
                    title="Non-profit income equals gross income (i.e. 1099 income received) minus ordinary business expenses."
                    trigger="hover">
                    <InfoCircleOutlined
                      className="gx-ml-1 gx-pointer"
                      style={{fontSize: '16px', verticalAlign: 'middle'}}
                    />
                  </Tooltip>
                </span>
              </h4>

              <InputNumber
                value={props.state.avgMonthlyPayrollNonProfit}
                onFocus={event => event.target.select()}
                onChange={value => {
                  props.dispatch({
                    type: 'avgMonthlyPayrollNonProfit',
                    payload: value,
                  });
                }}
                formatter={value =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                style={{width: '180px'}}
              />
            </div>
          )}
        </div>
      )}
    </Col>
  );
};

export default withStepTemplate(stepNonProfit);
