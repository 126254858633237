import React from 'react';
import numeral from 'numeral';
import moment from 'moment'

const calculateSavings = (currentVal, newVal) => {
  if (newVal > 0) return currentVal - newVal;
  return 0;
};

const dayFormat = "MM/DD/YYYY"

export const AccountBalancesLineChartCustomTooltip = ({ payload, label, active }) => {
  if (active) {
    const { networth } = payload[0].payload;
    return (
      <div className="custom-tooltip">
        <p className="label">
          Date: <span className="gx-fs-md gx-font-weight-semi-bold gx-ml-1">{`${moment(new Date(label * 1000), dayFormat).format(dayFormat)}`}</span>
        </p>
        <p className="label">
          Net Worth: <span className={'gx-fs-md gx-font-weight-semi-bold gx-ml-1 ' + (networth < 0 ? 'text-danger' : 'text-success')}>{`${numeral(networth).format('$0,0')}`}</span>
        </p>
      </div>
    );
  }
  return null;
};

export const LoanComparisonCustomTooltip = ({ payload, label, active }) => {
  if (active) {
    return (
      <div className="custom-tooltip">
        <p className="label">
          Date:
          <span className="gx-fs-md gx-font-weight-semi-bold gx-ml-1">{`${label}`}</span>
        </p>
        <p className="label">
          Current balance:
          <span className="gx-fs-md gx-font-weight-semi-bold gx-ml-1">{`${numeral(payload[0].value).format('$0,0')}`}</span>
        </p>
        <p className="label">
          New balance:
          <span className="gx-fs-md gx-font-weight-semi-bold gx-ml-1">
            {`${numeral(payload[1].value).format('$0,0')}`}
          </span>
        </p>
        <p className="label gx-text-success">
          Savings:
          <span className="gx-fs-md gx-font-weight-semi-bold gx-ml-1">
            {`${numeral(calculateSavings(payload[0].value, payload[1].value)).format('$0,0')}`}
          </span>
        </p>
      </div>
    );
  }
  return null;
};

export const NetWorthPieChartCustomTooltip = ({ payload, active }) => {
  if (active) {
    const { name, value } = payload[0];
    return (
      <div className="custom-tooltip" style={{ color: '#000' }}>
        {name}:<span className="gx-fs-md gx-font-weight-semi-bold gx-ml-1">{`${numeral(value).format('$0,0')}`}</span>
      </div>
    );
  }

  return null;
};
