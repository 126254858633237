import React from 'react';
import SearchBox from 'components/SearchBox';
import NotificationsPopover from 'components/app/contact/NotificationsPopover';
import MailNotification from 'components/MailNotification';
import { Popover } from 'antd/lib';

const AppsNavigation = () => {
  const searchText = '';

  return (
    <ul className='gx-app-nav'>
      <li>
        <Popover
          placement='bottom'
          content={
            <div className='gx-d-flex' style={{ width: '100%' }}>
              <SearchBox styleName='gx-popover-search-bar' placeholder='Search users' value={searchText} />
            </div>
          }
          trigger='click'
        >
          <span className='gx-pointer gx-d-block'>
            <i className='icon icon-search-new' />
          </span>
        </Popover>
      </li>

      <li className='gx-notify'>
        <NotificationsPopover iconText={<i className='icon icon-notification' />} />
      </li>
      
      <li>
        <MailNotification iconText={<i className='icon icon-chat-new' />} />
      </li>
    </ul>
  );
};

export default AppsNavigation;
