export const initialState = {
  tasks: [],
  allTasks: [],
  showModal: false,
  formMode: 'add',
  view: 'list',
  searchString: '',
  currentTask: null,
  currentlyEditedTask: null,
  currentlyEditedTags: [],
  render: null,
  drawerState: false,
  optionName: 'None',
  selectedTasks: 0,
};

export function reducer(state, action) {
  switch (action.type) {
    case 'setTasks':
      return {
        ...state,
        tasks: action.payload,
      };
    case 'setAllTasks':
      return {
        ...state,
        allTasks: action.payload,
      };
    case 'addTask':
      return {...state, showModal: true};
    case 'editTask':
      return {
        ...state,
        showModal: true,
        formMode: 'edit',
        currentlyEditedTask: action.task,
      };
    case 'setCurrentTask':
      return {
        ...state,
        currentTask: action.task,
      };
    case 'setSearchString':
      return {
        ...state,
        searchString: action.payload,
      };
    case 'setSelectedTasks':
      return {
        ...state,
        selectedTasks: action.payload,
      };
    case 'showAllTasks':
      return {
        ...state,
        currentTask: null,
        formMode: 'add',
      };
    case 'setViewList':
      return {
        ...state,
        view: 'list',
      };
    case 'setViewGrid':
      return {
        ...state,
        view: 'grid',
      };
    case 'setRender':
      return {
        ...state,
        render: action.payload,
      };
    case 'setOptionName':
      return {
        ...state,
        optionName: action.payload,
      };
    case 'toggleDrawerState':
      return {
        ...state,
        drawerState: !state.drawerState,
      };
    case 'hideModal':
      return {...state, showModal: false};
    default:
      throw new Error();
  }
}
