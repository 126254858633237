import React from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import FirebaseFin from 'firebase/firebaseFin';
import CircularProgress from 'components/CircularProgress/index';
import { AreaChart, Area, Tooltip, ResponsiveContainer } from 'recharts';
import '../widgets.less';
import { convertFirebaseDataIntoNetworthAreaChartData } from 'util/commonUtils'

const NetWorthAreaChart = ({ startDate, endDate }) => {
  const [networth] = useCollectionData(
    FirebaseFin.getNetWorth(startDate.toDate(), endDate.toDate()),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );
  if (!networth) {
    return (
      <div className="networthchart">
        <div className="gx-fillchart-content">
          <CircularProgress />
        </div>
      </div>
    );
  } else {
    return (
      <div className="networthareachart">
        <ResponsiveContainer>
          <AreaChart
            data={convertFirebaseDataIntoNetworthAreaChartData(networth)}
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
            <Tooltip />
            <Area
              dataKey="networth"
              strokeWidth={0}
              stackId="2"
              stroke="#092453"
              fill="#092453"
              fillOpacity={1}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  }
};

export default NetWorthAreaChart;
