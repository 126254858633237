import React, {useState, useEffect} from 'react';
import {useDocumentData} from 'react-firebase-hooks/firestore';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import {FirebasePostsServices} from 'loanpalz-constants';
import FirebaseSetup from 'firebase/firebaseCommon';
import {
  Avatar,
  Card,
  Input,
  Button,
  Spin,
  Dropdown,
  Menu,
  Modal,
  Radio,
  Divider,
  Form,
  message,
  Row,
} from 'antd/lib';
import {FaSpinner} from 'react-icons/fa';
import UploadPicture from 'components/common/UploadPicture';
import MediaList from './MediaList';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';
import DisplayDate from 'util/DisplayDate';
import FirebaseSocial from 'firebase/firebaseSocial';
import FirebasePosts from 'firebase/firebasePosts';
import CommentDisplayDiv from 'components/common/CommentDisplayDiv';

FirebaseSetup();

const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;

const {TextArea} = Input;
const {confirm} = Modal;

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 24},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 24},
  },
};

const PostItem = ({postInfo, readonly, groupData}) => {
  const [form] = Form.useForm();
  const intlMessages = useFormatMessage();

  const groupId = groupData ? groupData.id : null;
  const [current, currentLoading, currentError] = useDocumentData(
    FirebaseSocial.getUserDocumentData(FirebaseSocial.auth.currentUser.uid),
    {
      snapshotListenOptions: {includeMetadataChanges: true},
    },
  );

  const [userProfile, userProfileLoading, userProfileError] = useDocumentData(
    FirebaseSocial.getUserDocumentData(postInfo.user),
    {
      snapshotListenOptions: {includeMetadataChanges: true},
    },
  );

  const [newMessage, setNewMessage] = useState(null);

  const isEnabled = newMessage === '';
  const options = ['Edit', 'Delete'];

  const [uploading, setUploading] = useState(false);
  const [fileInfoList, setFileInfoList] = useState(postInfo.mediaArray);

  const onModalClose = () => setShowModal(false);
  const onModalOpen = () => setShowModal(true);
  const [showModal, setShowModal] = useState(false);

  let menus = () => (
    <Menu
      onClick={e => {
        if (e.key === 'Edit') {
          onModalOpen();
        } else {
          deletePost();
        }
      }}>
      {options.map(option => (
        <Menu.Item key={option}>{option}</Menu.Item>
      ))}
    </Menu>
  );

  useEffect(() => {
    form.setFieldsValue({
      text: postInfo.text,
      files: postInfo.mediaArray,
    });
  }, [form.text]); // eslint-disable-line

  const onFinish = async values => {
    const {text} = values;
    onModalClose();

    let oldPictures = [];
    let newPictures = [];
    let removedPictures = [];

    fileInfoList.forEach(file => {
      if (file.uri) {
        oldPictures.push(file);
      } else {
        newPictures.push(file);
      }
    });

    postInfo.mediaArray.forEach(media => {
      let remove = true;
      fileInfoList.forEach(file => {
        if (media.name === file.name) {
          remove = false;
        }
      });

      if (remove) {
        removedPictures.push(media.name);
      }
    });

    if (removedPictures.length > 0) {
      await FirebasePosts.removePictures(postInfo.id, removedPictures);
    }
    if (newPictures.length > 0) {
      setUploading(true);
      message.info(intlMessages('files.uploading'));
      await FirebasePosts.onMediaUpload(newPictures, groupId).then(
        async result => {
          let pictureArray = _.union(oldPictures, result);
          await FirebasePosts.updatePost(text, pictureArray, postInfo, groupId);
          message.success(intlMessages('files.uploaded'));
        },
      );
      setUploading(false);
    } else {
      await FirebasePosts.updatePost(text, oldPictures, postInfo, groupId);
    }
    message.success(intlMessages('post.updated'));
  };

  const deletePost = () => {
    confirm({
      title: 'Are you sure you want to delete this post?',
      onOk: () => {
        FirebasePosts.deletePost(postInfo, groupData);
      },
      onCancel() {},
    });
  };

  let alreadyLiked = null;
  if (postInfo && postInfo.likeArray) {
    postInfo.likeArray.forEach(likeUser => {
      if (likeUser === FirebaseSocial.auth.currentUser.uid) {
        alreadyLiked = true;
      }
    });
  }

  const handleLikeToggle = async () => {
    if (!alreadyLiked) {
      await FirebasePostsServices.likePost(postInfo, groupId);
    } else {
      await FirebasePostsServices.unlikePost(
        postInfo,
        FirebaseSocial.auth.currentUser.uid,
        groupId,
      );
    }
  };

  const addComment = async () => {
    const commentData = {
      user: FirebaseSocial.auth.currentUser.uid,
      comment: newMessage,
      date: Math.floor(new Date().getTime() / 1000),
      coach: current.coach,
    };

    await FirebasePosts.commentPost(postInfo, commentData, groupId);
    setNewMessage('');
  };

  const updateCommentValue = evt => {
    setNewMessage(evt.target.value);
  };

  const onCancel = () => {
    onModalClose();
    form.resetFields();
  };

  let extra = null;
  if (!readonly) {
    extra = (
      <Dropdown overlay={menus} placement="topRight" trigger={['click']}>
        <i className="gx-icon-btn icon icon-ellipse-v" />
      </Dropdown>
    );
  }

  if (!postInfo) {
    return <Spin indicator={spinnerIcon} />;
  } else {
    return (
      <div>
        {(currentError || userProfileError) && (
          <strong>Connection error!</strong>
        )}
        {(currentLoading || userProfileLoading) && <p>Loading...</p>}
        {current && userProfile && postInfo && (
          <Spin spinning={uploading} delay={500}>
            <Card className="gx-card">
              <div className="gx-wall-content">
                <div className="gx-media gx-wall-user-info gx-flex-nowrap gx-align-items-center">
                  <Link
                    to={{
                      pathname: `/app/user/${userProfile.vanityURL}`,
                      userId: userProfile.id,
                    }}>
                    <Avatar
                      className="gx-mr-3 gx-mb-2 gx-size-50"
                      src={userProfile.avatar}
                    />
                  </Link>
                  <div className="gx-media-body">
                    <Link
                      to={{
                        pathname: `/app/user/${userProfile.vanityURL}`,
                        userId: userProfile.id,
                      }}>
                      {postInfo.coach ? (
                        <h5 className="gx-wall-user-title">
                          <strong> Coach </strong>
                          {userProfile.username}
                        </h5>
                      ) : (
                        <h5 className="gx-wall-user-title">
                          {userProfile.username}
                        </h5>
                      )}
                    </Link>
                    <DisplayDate date={postInfo.date} timestamp />
                  </div>
                  {extra}
                </div>

                <div
                  style={{whiteSpace: 'pre-line'}}
                  className="gx-mb-3 gx-mt-3">
                  {postInfo.text}
                </div>

                <Row justify="center">
                  <div className="gx-wall-medialist">
                    {postInfo.mediaArray.length > 0 ? (
                      <MediaList mediaList={postInfo.mediaArray} />
                    ) : null}
                  </div>
                </Row>
                <Row justify="end">
                  <div className="gx-flex-row gx-mb-2 gx-mb-xl-3">
                    <p className="gx-fs-sm gx-pointer gx-mr-3 gx-text-grey">
                      <span className="gx-d-inline-flex gx-vertical-align-middle">
                        {postInfo.likeArray.length + ' Likes'}
                      </span>
                    </p>
                    <p className="gx-fs-sm gx-pointer gx-mr-3 gx-text-grey">
                      <span className="gx-d-inline-flex gx-vertical-align-middle">
                        {postInfo.commentCount + ' Comments'}
                      </span>
                    </p>
                  </div>
                </Row>

                <Row justify="center">
                  <Radio.Group
                    size="medium"
                    color="primary"
                    buttonStyle="solid">
                    <Radio.Button onClick={handleLikeToggle}>
                      {alreadyLiked ? (
                        <i
                          className="icon icon-like gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle"
                          style={{color: 'primary'}}
                        />
                      ) : (
                        <i className="icon icon-like-o gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle" />
                      )}
                      Like
                    </Radio.Button>
                    <Radio.Button>
                      <i className="icon icon-chat-bubble gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle" />
                      Comment
                    </Radio.Button>
                    <Radio.Button>Share</Radio.Button>
                  </Radio.Group>
                </Row>

                <Divider />

                <div className="gx-wall-comment-box">
                  <div className="gx-media gx-mb-2">
                    <Avatar
                      className="gx-mr-3 gx-size-32"
                      src={current.avatar}
                    />
                    <div className="gx-media-body">
                      <TextArea
                        value={newMessage}
                        rows={2}
                        onChange={event => updateCommentValue(event)}
                        placeholder="Leave a Comment"
                      />
                    </div>
                  </div>
                </div>

                <Row justify="end" align="middle">
                  <Button
                    type="primary"
                    size="small"
                    className="gx-ml-auto gx-mb-0"
                    disabled={isEnabled ? 'disabled' : ''}
                    onClick={addComment}>
                    Add Comment
                  </Button>
                </Row>
              </div>

              <div className="gx-wall-comment-box">
                {postInfo.comments.length > 0 ? (
                  <CommentDisplayDiv
                    postInfo={postInfo}
                    commentArray={postInfo.comments}
                    commentCount={postInfo.commentCount}
                    groupId={groupId}
                  />
                ) : null}
              </div>

              <Modal
                title="Edit Post"
                toggle={onModalClose}
                visible={showModal}
                closable={true}
                onOk={() => form.submit()}
                onCancel={onCancel}>
                <Form
                  layout="vertical"
                  {...formItemLayout}
                  form={form}
                  onFinish={onFinish}>
                  <Form.Item name="text" label="Post Content:">
                    <TextArea rows={10} />
                  </Form.Item>
                  <Form.Item name="files" label="Pictures:">
                    <UploadPicture
                      fList={postInfo.mediaArray}
                      onFileChange={setFileInfoList}
                    />
                  </Form.Item>
                  <Button htmlType="submit" style={{display: 'none'}}></Button>
                </Form>
              </Modal>
            </Card>
          </Spin>
        )}
      </div>
    );
  }
};

export default PostItem;
