import React, { useState, useEffect } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { Button, Form, Input, Checkbox, message } from 'antd';
import FirebaseAuth from 'firebase/firebaseAuth';
import FirebaseSocial from 'firebase/firebaseSocial';
import { Link } from 'react-router-dom';
import { history } from 'appRedux/store';
import IntlMessages from 'util/IntlMessages';
import Helmet from 'react-helmet';
import SocialSignIn from './SocialSignIn';

const SignIn = () => {
  const intlMessages = useFormatMessage();
  const [form] = Form.useForm();
  const [remember, setRemember] = useState(false);
  const [signInFlag, setSignInFlag] = useState(true);

  useEffect(() => {
    if (signInFlag) {
      FirebaseAuth.auth.onAuthStateChanged(async user => {
        if (user && !user.isAnonymous) {
          setSignInFlag(false);
          history.push('/app/dashboard');
        }
      });
    }
  }, [signInFlag]); // eslint-disable-line

  const rememberOnChange = e => {
    setRemember(e.target.checked);
  };

  const onFinish = async values => {
    const { email, password } = values;
    try {
      let authUser = null;

      if (remember) {
        authUser = await FirebaseAuth.signInWithPersistence(
          email,
          password,
          'LOCAL',
        );
      } else {
        authUser = await FirebaseAuth.signInWithPersistence(
          email,
          password,
          'SESSION',
        );
      }

      let user = await FirebaseSocial.getUser(authUser.user.uid);
      if (user && user.disabled === true) {
        message.error(intlMessages('appModule.accountDeleted'));
      } else {
        setSignInFlag(false);
        message.success(intlMessages('appModule.loginSuccessfully'));
        // history.push('/app/dashboard');
      }
    } catch (error) {
      // message.error(intlMessages('appModule.loginError'));
      message.error(error.message);
    }
  };

  return (
    <div className="gx-app-login-wrap">
      <Helmet defaultTitle="Sign in | loanpalz" />
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg"></div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="app.userAuth.signIn" />
              </h1>
              <p>
                <IntlMessages id="app.userAuth.bySigningIn" />
              </p>
            </div>
            <div className="gx-app-logo">
              <img
                alt="example"
                src={require('assets/images/loanpalz-logo.png')}
              />
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              form={form}
              onFinish={onFinish}
              className="gx-signin-form gx-form-row0">
              <Form.Item name="email" rules={[{ required: true, type: 'email' }]}>
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item name="password" rules={[{ required: true }]}>
                <Input type="password" placeholder="Password" />
              </Form.Item>

              <Form.Item>
                <Checkbox onChange={rememberOnChange}>Remember me</Checkbox>
                <Link
                  to={`/forgotpassword`}
                  style={{ right: '0px', position: 'absolute' }}>
                  <IntlMessages id="app.userAuth.forgotPassword" />
                </Link>
              </Form.Item>

              <Form.Item></Form.Item>

              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signIn" />
                </Button>
                <span>
                  <IntlMessages id="app.userAuth.or" />
                </span>{' '}
                <Link to="/signup">
                  <IntlMessages id="app.userAuth.signUp" />
                </Link>
              </Form.Item>
            </Form>
            <div className="gx-flex-row gx-justify-content-between gx-app-social-block">
              <span>Or sign in with</span>
              <SocialSignIn />
              <span>
                Or{' '}
                <Link to="/signinguest">
                  sign in as guest
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
