import React from 'react';
import { Col, Row } from 'antd'

const GridImage = ({ mediaList, numItems }) => {
  let cols = (mediaList && mediaList.length > 1) ? 12 : 24
  return (
    <Row align="top" gutter={[4, 4]}>
      {mediaList.map((media, index) => {
        if (index < numItems) {
          return (
            <Col key={index} xl={cols} lg={cols} md={24} sm={24} xs={24}>
              <img style={{ cursor: 'pointer' }} alt={`Gallery ${media.name}`} src={media.uri} />
              {index === numItems - 1 && (
                <div className="gx-gallery-item-content" style={{ cursor: 'pointer' }}>
                  <h1 className="gx-text-white"> + {mediaList.length - numItems}</h1>
                </div>
              )}
            </Col>
          )
        }
        return null
      })}
    </Row>
  )
};

export default GridImage;
