import React, {useState, useEffect} from 'react';
import {Modal, Button, message, Radio} from 'antd/lib';
import IntlMessages from 'util/IntlMessages';
import {Row, Col} from 'antd/lib';
import FirebaseCoaches from 'firebase/firebaseCoaches';
import {
  covertTimestamp,
  getAvailabilityEntry,
  dateFormatDisplay,
} from 'util/commonUtils';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';
import InfiniteCalendar from 'react-infinite-calendar';
import AvailabilityList from './AvailabilityList';
import Select from 'react-timezone-select';
import '../style.css';
import 'react-infinite-calendar/styles.css';

const UpdateAppointment = ({
  booking,
  showModal,
  onModalClose,
  coachAvailability,
}) => {
  const [checkSlot, setCheckSlot] = useState(false);
  const [availability, setAvailability] = useState([]);
  const [timings, setTimings] = useState([]);
  const [selectedTime, setselectedTime] = useState(null);
  const [renderData, setRenderData] = useState(true);
  const [selectDate, setSelectDate] = useState(null);
  const [selectedDatetime, setselectedDatetime] = useState(0);
  const [maxDate, setMaxDate] = useState(covertTimestamp(new Date()));
  const [calendarView, setCalendarView] = useState(true);
  const [bookedSlots, setBookedSlots] = useState([]);
  const intlMessages = useFormatMessage();
  const [timezone, setTimezone] = useState(
    booking && booking.timeZone
      ? booking.timeZone
      : {value: 'GMT', label: '(GMT) Dublin, Edinburgh, Lisbon, London'},
  );

  async function getOldBooking() {
    let bookingsData = await FirebaseCoaches.getAppointmentsOfCoachData({
      coachid: booking.coach,
      filter: 'upcoming',
    });
    setBookedSlots(bookingsData);
    let slots = timings;
    // Filter Old Booked Slots
    if (bookingsData.length && slots.length) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      let nextDay = new Date(new Date());
      nextDay.setDate(new Date().getDate() + 1);
      let nextDayTimestamp = covertTimestamp(nextDay) * 1000;
      bookingsData.map(book => {
        if (
          book.date >= covertTimestamp(today) * 1000 &&
          book.date <= nextDayTimestamp
        ) {
          slots = slots.filter(slot => slot.time !== book.timeSlot);
        }
        return true;
      });
    }
    // slots.push({
    //     id: 'i',
    //     time: booking.timeSlot,
    //     show: true,
    // });
    setTimings(slots);
  }

  // Effect hooks
  useEffect(() => {
    if (!bookedSlots.length) {
      getOldBooking();
    }
  });

  const selectTime = (data, i) => {
    setselectedTime(data);
  };

  const selectedDate = e => {
    setCheckSlot(true);
    let currentDatetime = covertTimestamp(e);
    setselectedDatetime(currentDatetime * 1000);
    setSelectDate(dateFormatDisplay(currentDatetime * 1000, 'Y, M, D'));
    let slots = [];
    if (availability.length > 0) {
      let availSlots = getAvailabilityEntry({
        availability: availability,
        date: currentDatetime * 1000,
      });
      if (availSlots) {
        let timeSlots = availSlots.timeSlots;
        timeSlots.map((s, i) =>
          slots.push({
            id: i,
            time: s,
            show: true,
          }),
        );
      }
      // Filter Old Booked Slots
      if (bookedSlots.length && slots.length) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        let nextDay = new Date(new Date());
        nextDay.setDate(new Date().getDate() + 1);
        let nextDayTimestamp = covertTimestamp(nextDay) * 1000;
        bookedSlots.map(book => {
          if (
            book.date >= currentDatetime * 1000 &&
            book.date <= nextDayTimestamp
          ) {
            slots = slots.filter(slot => slot.time !== book.timeSlot);
          }
          return true;
        });
      }
      setTimings(slots);
    }
    setCheckSlot(false);
  };

  if (renderData) {
    setSelectDate(dateFormatDisplay(booking.date, 'Y, M, D'));
    setCheckSlot(true);
    setAvailability(coachAvailability);
    let slots = [];
    if (coachAvailability.length > 0) {
      let lastDate = coachAvailability.reduce(
        (max, p) => (p.endDate > max ? p.endDate : max),
        coachAvailability[0].endDate,
      );
      if (Number.isInteger(lastDate)) {
        setMaxDate(lastDate);
      }
      let currentDatetime = booking.date
        ? booking.date
        : covertTimestamp(new Date() * 1000);
      setselectedDatetime(currentDatetime);
      let availSlots = getAvailabilityEntry({
        availability: coachAvailability,
        date: currentDatetime,
      });

      if (availSlots) {
        let timeSlots = availSlots.timeSlots;
        timeSlots.map((s, i) =>
          slots.push({
            id: i,
            time: s,
            show: true,
          }),
        );
      }
    }
    setTimings(slots);
    setselectedTime(booking.timeSlot);
    setRenderData(false);
    setCheckSlot(false);
  }

  const UpdateAppointmentData = async () => {
    let updatedSlot = {
      timeZone: timezone,
      date: selectedDatetime,
      timeSlot: selectedTime,
    };
    onModalClose();
    await FirebaseCoaches.updateBookingsSlot(booking, updatedSlot);
    message.success(intlMessages('coach.update.booking.success'));
  };
  const selectTimezone = e => {
    setTimezone(e);
  };

  // Calendar init Setting
  let yesterday = new Date(new Date());
  yesterday.setDate(new Date().getDate() - 1);
  const minDate = dateFormatDisplay(
    covertTimestamp(yesterday) * 1000,
    'Y, M, D',
  );
  const todayDate = dateFormatDisplay(
    covertTimestamp(new Date()) * 1000,
    'Y, M, D',
  );
  const _maxDate = dateFormatDisplay(maxDate * 1000, 'Y, M, D');

  const changeCalendarView = e => {
    setCalendarView(e);
  };

  const selectDateTime = (date, time, i) => {
    setselectedDatetime(date * 1000);
    setselectedTime(time);
    setSelectDate(new Date(dateFormatDisplay(date * 1000, 'Y, M, D')));
  };

  return (
    <>
      <Modal
        title={<IntlMessages id="coach.update.booking" />}
        toggle={onModalClose}
        visible={showModal}
        closable={true}
        onCancel={onModalClose}
        destroyOnClose={true}
        footer={null}>
        <div>
          <div className="gx-text-align-center">
            <Radio.Group defaultValue={calendarView ? 'calender' : 'list'}>
              <Radio.Button
                value="calender"
                onClick={() => changeCalendarView(true)}>
                Calendar View
              </Radio.Button>
              <Radio.Button
                value="list"
                onClick={() => changeCalendarView(false)}>
                List View
              </Radio.Button>
            </Radio.Group>

            {calendarView ? (
              <InfiniteCalendar
                locale={{
                  weekStartsOn: 1,
                }}
                height={300}
                onSelect={selectedDate}
                selected={new Date(selectDate)}
                min={new Date(minDate)}
                minDate={new Date(todayDate)}
                max={new Date(_maxDate)}
                maxDate={new Date(_maxDate)}
              />
            ) : (
              <AvailabilityList
                availability={availability}
                bookedSlots={bookedSlots}
                maxDate={maxDate}
                selectDateTime={selectDateTime}
                selectedDateSlot={{
                  date: dateFormatDisplay(
                    selectedDatetime ? selectedDatetime : booking.date,
                    'MMDDYYYY',
                  ),
                  time: selectedTime ? selectedTime : booking.timeSlot,
                }}
              />
            )}
          </div>
          {calendarView ? (
            <Row className="time-list">
              {timings && timings.length ? (
                timings.map(
                  el =>
                    el.show === true && (
                      <Col span={8} key={el.id}>
                        {selectedTime === el.time && (
                          <div
                            className="gx-changeboxView"
                            onClick={() => selectTime(el.time)}>
                            {el.time.split('to')[0]}
                          </div>
                        )}
                        {selectedTime !== el.time && (
                          <div
                            className="gx-boxView"
                            onClick={() => selectTime(el.time, el.id)}>
                            {el.time.split('to')[0]}
                          </div>
                        )}
                      </Col>
                    ),
                )
              ) : (
                <Col span={24}>
                  <p className="text-center gx-mt-5 gx-ml-10">
                    {checkSlot
                      ? 'Checking available slots...'
                      : '- No time slot available for this date -'}
                  </p>
                </Col>
              )}
            </Row>
          ) : null}
          <div className="gx-mt-3 gx-mr-5">
            <h5>Time Zone:</h5>
            <div className="select-wrapper">
              <Select
                value={timezone}
                defaultValue={timezone}
                onChange={e => selectTimezone(e)}
              />
            </div>
          </div>
          <div className="ant-modal-footer" style={{marginTop: 20}}>
            <Button onClick={onModalClose}> Cancel </Button>
            {timings.length && selectedTime ? (
              <Button type="primary" onClick={() => UpdateAppointmentData()}>
                Update Appointment
              </Button>
            ) : (
              <Button type="primary" disabled>
                Update Appointment
              </Button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UpdateAppointment;
