import React from 'react';
import {Avatar, Comment, Tooltip} from 'antd/lib';
import {useDocumentData} from 'react-firebase-hooks/firestore';
import FirebaseSocial from 'firebase/firebaseSocial';
import FirebaseVlogs from 'firebase/firebaseVlogs';
import DisplayDate from '../../util/DisplayDate';

const CommentBox = ({commentData, vlog, userId, currentUserId}) => {
  const [user, userLoading, userError] = useDocumentData(
    FirebaseSocial.getUserDocumentData(commentData.user),
    {
      snapshotListenOptions: {includeMetadataChanges: true},
    },
  );

  const likeUnlikeComment = async commentData => {
    let likes = commentData.likes;
    if (likes.includes(currentUserId)) {
      likes = likes.filter(item => item !== currentUserId);
    } else {
      likes.push(currentUserId);
    }
    await FirebaseVlogs.likeUnLikeVideoComment({
      id: commentData.id,
      userId: userId,
      likes: likes,
      docId: vlog.id,
    });
  };

  const actions = [
    <span key="comment-basic-like">
      <Tooltip title="Like">
        {commentData.likes.includes(currentUserId) ? (
          <i
            className="icon icon-like gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle"
            style={{color: 'primary'}}
            onClick={() => likeUnlikeComment(commentData)}
          />
        ) : (
          <i
            className="icon icon-like-o gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle"
            onClick={() => likeUnlikeComment(commentData)}
          />
        )}
      </Tooltip>
      <span style={{paddingLeft: 4, cursor: 'auto'}}>
        {commentData.likes.length > 0
          ? commentData.likes.length + ' Likes'
          : 'Likes'}
      </span>
    </span>,
  ];

  return (
    <div>
      {userError && <strong>Connection error!</strong>}
      {userLoading && <p>Loading...</p>}
      {user && (
        <Comment
          actions={actions}
          author={user.username}
          avatar={<Avatar src={user.avatar} />}
          content={commentData.comment}
          datetime={<DisplayDate date={commentData.date.toString()} />}
        />
      )}
    </div>
  );
};

export default CommentBox;
