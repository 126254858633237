import {
  GET_TEMPLATE_DETAILS_SUCCESS,
  GET_ALL_TEMPLATES_SUCCESS,
  ON_ADD_TEMPLATE_SUCCESS,
  UPDATE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_SUCCESS,
} from '../../constants/ActionTypes'

const INIT_STATE = {
  borrowerList: [],
  selectedBorrower: [],
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_TEMPLATES_SUCCESS: {
      return {
        ...state,
        borrowerList: action.payload,
      }
    }

    case GET_TEMPLATE_DETAILS_SUCCESS: {
      return {
        ...state,
        selectedBorrower: action.payload,
      }
    }

    case UPDATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        borrowerList: state.borrowerList.map(borrower =>
          borrower.id === action.payload.id ? action.payload : borrower
        ),
      }

    case DELETE_TEMPLATE_SUCCESS:
      return {
        ...state,
        borrowerList: state.borrowerList.filter(
          borrower => borrower.id !== action.payload.id
        ),
      }

    case ON_ADD_TEMPLATE_SUCCESS:
      return {
        ...state,
        borrowerList: action.payload.borrower(state.borrowerList),
      }

    default:
      return state
  }
}
