import React, {Component} from 'react';
import {Scrollbars} from 'react-custom-scrollbars';

class CustomScrollbarsScrollToBottom extends Component {
  componentDidMount() {
    this.scrollBar.scrollToBottom();
  }

  render() {
    return (
      <Scrollbars
        {...this.props}
        autoHide
        ref={e => (this.scrollBar = e)}
        renderTrackHorizontal={props => (
          <div
            {...this.props}
            style={{display: 'none'}}
            className="track-horizontal"
          />
        )}
      />
    );
  }
}

export default CustomScrollbarsScrollToBottom;
