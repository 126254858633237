import React, {useState, Fragment, useEffect} from 'react';
import {useCollectionData} from 'react-firebase-hooks/firestore';
import Widget from 'components/Widget/index';
import FirebaseCoaches from 'firebase/firebaseCoaches';
import {Col, Spin, Modal, message} from 'antd/lib';
import {FaSpinner} from 'react-icons/fa';
import ListDisplayStyle from 'components/app/coaches/ListDisplayStyle';
import DisplayAppointmentList from 'components/app/coaches/userAppointments/DisplayAppointmentList';
import DisplayAppointmentGrid from 'components/app/coaches/userAppointments/DisplayAppointmentGrid';
import DispyCoachesList from 'components/app/coaches/AllCoaches/DispyCoachesList';
import DisplayCoachesGrid from 'components/app/coaches/AllCoaches/DisplayCoachesGrid';
import UpdateAppointment from 'components/app/coaches/booking/UpdateAppointment';
import {useFormatMessage} from '@comparaonline/react-intl-hooks';
import IntlMessages from 'util/IntlMessages';
const spinnerIcon = <FaSpinner size="3" className="icon-spin" />;
const {confirm} = Modal;

const CoachList = ({profile}) => {
  const [bookingView, setBookingView] = useState('list');
  const [coachesView, setCoachesView] = useState('list');
  const [booking, setBooking] = useState(null);
  const [showUpdateBooking, setShowUpdateBooking] = useState(false);
  const [availability, setAvailability] = useState([]);
  const [loadingavAilability, setLoadingavAilability] = useState(true);
  const intlMessages = useFormatMessage();
  const [allCoachesData, setAllCoachesData] = useState(null);
  const [allCoaches, setAllCoaches] = useState(null);

  async function getAllCoaches() {
    let coachesResponse = await FirebaseCoaches.getCoachesDocumentData({
      hook: false,
    });
    setAllCoaches(coachesResponse);
    setAllCoachesData(coachesResponse);
  }

  // Effect hooks
  useEffect(() => {
    if (!allCoaches) {
      getAllCoaches();
    }
  }, [allCoaches]);

  const [
    myAppointmens,
    appointmentsLoading,
    appointmentsError,
  ] = useCollectionData(
    FirebaseCoaches.getMyAppointmentWithCoaches({limit: 0}),
    {
      idField: 'id',
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    },
  );

  let allAppointments = myAppointmens;
  let currentDatetime = new Date().setHours(0, 0, 0, 0);

  if (allAppointments && allAppointments.length) {
    allAppointments = allAppointments.filter(
      appointment => appointment.date >= currentDatetime,
    );
    allAppointments.sort((a, b) => a['date'] - b['date']);
  }

  const onChangeDisplay = e => {
    setBookingView(e.target.value);
  };

  const onChageCoachesDisplay = e => {
    setCoachesView(e.target.value);
  };

  const onCoachSearch = e => {
    const searchText = e.target.value;
    if (searchText.length > 0) {
      let filterCoaches = allCoaches.filter(
        coach =>
          coach.username.toLowerCase().indexOf(searchText.toLowerCase()) > -1,
      );
      setAllCoaches(filterCoaches);
    } else {
      setAllCoaches(allCoachesData);
    }
  };

  const cancelAppointment = data => {
    confirm({
      title: 'Are you sure you want to cancel appointment?',
      onOk: () => {
        processCancelAppointment(data);
      },
      onCancel() {},
    });
  };

  const processCancelAppointment = async data => {
    await FirebaseCoaches.cancelAppointment(data);
    message.success(intlMessages('coach.appointment.canceled'));
  };

  const editMyAppoientment = async e => {
    let response = await FirebaseCoaches.geAvailibilityData(e.coach);
    setBooking(e);
    setShowUpdateBooking(true);
    setAvailability(response);
    setLoadingavAilability(false);
  };

  const hideUpdateBookingModal = () => {
    setShowUpdateBooking(false);
    setBooking({});
  };

  return (
    <>
      <Widget>
        {appointmentsError && (
          <strong>
            {' '}
            Error loading recent appointments. Please try again later{' '}
          </strong>
        )}
        {appointmentsLoading && <Spin indicator={spinnerIcon} />}

        <Fragment>
          <div className="goals-heading">
            <h4>
              <IntlMessages id="coach.appointment.upcoming" />
            </h4>
            <ListDisplayStyle
              profile={profile}
              key={'booking'}
              onChangeDisplay={onChangeDisplay}
              bookingView={bookingView}
              recent={true}
              section={'booking'}
            />
          </div>
          {allAppointments && allAppointments.length ? (
            <div className="goals-display">
              {bookingView === 'list' ? (
                <div className="goals-display-list">
                  <DisplayAppointmentList
                    profile={profile}
                    data={allAppointments}
                    editMyAppoientment={editMyAppoientment}
                    cancelAppointment={cancelAppointment}
                  />
                </div>
              ) : (
                <div className="goals-display-grid ant-row">
                  <DisplayAppointmentGrid
                    profile={profile}
                    data={allAppointments}
                    editMyAppoientment={editMyAppoientment}
                    cancelAppointment={cancelAppointment}
                  />
                </div>
              )}
            </div>
          ) : (
            !appointmentsLoading && (
              <Col span={24}>
                <Widget styleName="gx-card-full">
                  <div className="text-center gx-px-3 gx-pt-3 gx-pb-3 gx-mt-3">
                    <h3>You don't have any appointments!</h3>
                  </div>
                </Widget>
              </Col>
            )
          )}
        </Fragment>
      </Widget>

      {/* All Coaches Section */}

      <Widget>
        <Fragment>
          <div className="goals-heading">
            <h4>
              <IntlMessages id="coach.allcoaches" />
            </h4>
            <ListDisplayStyle
              profile={profile}
              key={'coaches'}
              onCoachSearch={onCoachSearch}
              coachesView={coachesView}
              onChageCoachesDisplay={onChageCoachesDisplay}
              section={'coaches'}
            />
          </div>
          {allCoaches && allCoaches.length ? (
            <div className="goals-display">
              {coachesView === 'list' ? (
                <div className="goals-display-list">
                  <DispyCoachesList
                    profile={profile}
                    data={allCoaches}
                    editMyAppoientment={editMyAppoientment}
                    cancelAppointment={cancelAppointment}
                  />
                </div>
              ) : (
                <div className="goals-display-grid ant-row">
                  <DisplayCoachesGrid
                    profile={profile}
                    data={allCoaches}
                    editMyAppoientment={editMyAppoientment}
                    cancelAppointment={cancelAppointment}
                  />
                </div>
              )}
            </div>
          ) : (
            !appointmentsLoading && (
              <Col span={24}>
                <Widget styleName="gx-card-full">
                  <div className="text-center gx-px-3 gx-pt-3 gx-pb-3 gx-mt-3">
                    <h3>Sorry no coach found!</h3>
                  </div>
                </Widget>
              </Col>
            )
          )}
        </Fragment>
      </Widget>
      {booking && showUpdateBooking && !loadingavAilability && (
        <UpdateAppointment
          booking={booking}
          showModal={showUpdateBooking}
          onModalClose={hideUpdateBookingModal}
          coachAvailability={availability}
        />
      )}
    </>
  );
};

export default CoachList;
