import React, { forwardRef, useImperativeHandle } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { Button, Form, Input, InputNumber, message, Cascader, } from 'antd';
import { FirebaseDAO } from 'firebase/firebase';
import { accountTypes } from 'constants/DataConstants';

const formItemLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

const AccountForm = forwardRef(({ account, mode, onClose }, ref) => {
    const [form] = Form.useForm();
    const intlMessages = useFormatMessage();
    useImperativeHandle(ref, () => ({
        submitForm() {
            form.submit();
        },
    }));

    const onSubmit = async formValues => {
        if (onClose) {
            onClose();
        }

        if (mode === 'add') {
            await FirebaseDAO.addFBData(formValues, 'accounts');
            message.success(intlMessages('wizard.financials.account.add.success'));
        } else {
            let id = account.id;
            formValues = {
                ...formValues,
                id,
            };
            await FirebaseDAO.updateFBData(formValues, 'accounts');
            message.success(intlMessages('wizard.financials.account.update.success'));
        }
        form.resetFields();
    };

    const setInitialValues = () => {
        if (account) {
            form.setFieldsValue({
                ...account,
            });
        }
    };

    form.resetFields();
    setInitialValues();

    return (
        <Form {...formItemLayout} form={form} onFinish={onSubmit}>
            <div className="gx-modal-box-row">
                <div className="gx-modal-box-form-item">
                    <div className="gx-form-group">
                        <Form.Item
                            label="Account name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                    message: 'Please enter an Account name',
                                },
                            ]}>
                            <Input required placeholder="Account name" />
                        </Form.Item>
                    </div>
                    <div className="gx-form-group">
                        <Form.Item
                            inline
                            name="balance"
                            label="Initial Balance"
                            rules={[
                                {
                                    required: true,
                                    type: 'integer',
                                    message: 'Please enter a valid number',
                                },
                            ]}>
                            <InputNumber
                                onChange={() => { }}
                                formatter={value =>
                                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                }
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                placeholder="Initial Balance"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </div>
                    <div className="gx-form-group">
                        <Form.Item
                            label="Account Type"
                            name="type"
                            rules={[
                                {
                                    required: true,
                                    type: 'array',
                                    message: 'Please select an Account type',
                                },
                            ]}>
                            <Cascader
                                options={accountTypes}
                                showSearch={(inputValue, path) =>
                                    path.some(
                                        option =>
                                            option.label
                                                .toLowerCase()
                                                .indexOf(inputValue.toLowerCase()) > -1,
                                    )
                                }
                            />
                        </Form.Item>
                    </div>

                    <Button htmlType="submit" style={{ display: 'none' }}></Button>
                </div>
            </div>
        </Form>
    );
});

export default AccountForm;
