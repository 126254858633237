import { history } from 'appRedux/store';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { message } from 'antd';
import FirebaseAuth from 'firebase/firebaseAuth';

const SignOut = () => {
  const intlMessages = useFormatMessage();
  // Sign out
  FirebaseAuth.signOut();

  // Unsucscribe from auth events
  let unsubscribe = FirebaseAuth.auth.onAuthStateChanged(user => { });
  unsubscribe();

  // Display message
  message.success(intlMessages('appModule.logoutSuccessfully'));

  // Forward to /signin
  history.push('/signin');
  return null;
};

export default SignOut;
